<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.myfilesharing" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				File Sharing
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2"
				[routerLink]="['/'+ appConstants.urls.bundles, 'new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Bundle</span>
			</button>

			<button *ngIf="canBasicSend" type="button" class="btn btn-outline-primary me-2"
				[routerLink]="['/'+ appConstants.urls.myfilesharing, 'basic-send']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Send Files</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="getData(true)" [disabled]="loading">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': focusedTab === 'send'}" data-bs-toggle="tab" role="tab"
				[attr.aria-selected]="focusedTab === 'send'" id="nav-send-tab" aria-controls="nav-send" data-bs-target="#nav-send">
				<fa-icon [icon]="['fas', 'file-upload']" size="sm"></fa-icon>
				Send Files
				<span *ngIf="!loading || sendBundles.length > 0 " class="cp-tab-badge">
					{{ sendBundles.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': focusedTab === 'receive'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="focusedTab === 'receive'" id="nav-receive-tab" aria-controls="nav-receive"
				data-bs-target="#nav-receive">
				<fa-icon [icon]="['fas', 'file-download']" size="sm"></fa-icon>
				Receive Files
				<span *ngIf="!loading || receiveBundles.length > 0 " class="cp-tab-badge">
					{{ receiveBundles.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': focusedTab === 'report'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="focusedTab === 'report'" id="nav-report-tab" aria-controls="nav-report"
				data-bs-target="#nav-report">
				<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
				Reports
				<span *ngIf="!loading || reportBundles.length > 0" class="cp-tab-badge">
					{{ reportBundles.length | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane" [ngClass]="{'show active': focusedTab === 'send'}" role="tabpanel" id="nav-send"
			aria-labelledby="nav-send-tab">
			<div *ngIf="!loading && comingSoon" class="cp-general-width-limit mt-5">
				{{ comingSoonMessage }}
			</div>

			<button *ngIf="!loading && sendBundles.length > 1 && sortOrder === 'oldest-to-newest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Newest to Oldest
			</button>

			<button *ngIf="!loading && sendBundles.length > 0  && sortOrder === 'newest-to-oldest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Oldest to Newest
			</button>

			<div *ngIf="!loading && !comingSoon" class="container">
				<div>{{ sendMessage }}</div>

				<div class="row">
					<div *ngFor="let item of sendBundles" [routerLink]="item.id" class="cp-pointer" class="col-4 m-0 p-0">
						<div class="border p-2 m-1 overflow-auto">
							{{ item.added_on | date: appConstants.pageDateTimeFmt}}<br />
							<div *ngIf="item.information && item.information !==''" class="fw-bold cp-pre-text overflow-auto"
								style="max-height: 300px">
								{{ item.information }}<br />
							</div>


							<span *ngIf="item.btype.endsWith('-send')" class="text-nowrap">
								From : <span class="fw-bold">{{ item.staff_name }}</span>
								<br />
								<span *ngIf="item.org_id !== 0">
									To : <span class="fw-bold">{{ item.org_name }}</span>
								</span>
								<span *ngIf="item.org_id === 0">
									To : <span class="fw-bold">{{ item.user_names.join(', ') }}</span>
								</span>
								<br />
							</span>

							<span *ngIf="item.btype.endsWith('-receive')" class="text-nowrap">
								<span *ngIf="item.org_id !== 0">
									From : <span class="fw-bold">{{ item.org_name }}</span>
								</span>
								<span *ngIf="item.org_id === 0">
									From : <span class="fw-bold">{{ item.user_names.join(', ') }}</span>
								</span>
								<br />
								To : <span class="fw-bold">{{ item.staff_name }}</span>
								<br />
							</span>

							<span *ngIf="item.files.length === 0" class="font-italic text-nowrap">
								No Files
							</span>
							<span *ngIf="item.files.length === 1" class="fw-bold text-nowrap">
								One File ({{ item.files[0].name }})
							</span>
							<span *ngIf="item.files.length > 1" class="fw-bold text-nowrap">
								{{ item.files.length }} Files
							</span>
							<br />
							<a [routerLink]="item.id">View Details</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': focusedTab === 'receive'}" role="tabpanel" id="nav-receive"
			aria-labelledby="nav-receive-tab">
			<div *ngIf="!loading && comingSoon" class="cp-general-width-limit mt-5">
				{{ comingSoonMessage }}
			</div>

			<button *ngIf="!loading && receiveBundles.length > 1 && sortOrder === 'oldest-to-newest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Newest to Oldest
			</button>

			<button *ngIf="!loading && receiveBundles.length > 0  && sortOrder === 'newest-to-oldest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Oldest to Newest
			</button>

			<div *ngIf="!loading && !comingSoon" class="container">
				<div>{{ receiveMessage }}</div>

				<div class="row">
					<div *ngFor="let item of receiveBundles" [routerLink]="item.id" class="cp-pointer" class="col-4 m-0 p-0">
						<div class="border p-2 m-1 overflow-auto">
							{{ item.added_on | date: appConstants.pageDateTimeFmt}}<br />
							<div *ngIf="item.information && item.information !==''" class="fw-bold cp-pre-text overflow-auto"
								style="max-height: 300px">
								{{ item.information }}<br />
							</div>

							<span *ngIf="item.btype.endsWith('-send')">
								From : <span class="fw-bold">{{ item.staff_name }}</span>
								<br />
								<span *ngIf="item.org_id !== 0">
									To : <span class="fw-bold">{{ item.org_name }}</span>
								</span>
								<span *ngIf="item.org_id === 0">
									To : <span class="fw-bold">{{ item.user_names.join(', ') }}</span>
								</span>
								<br />
							</span>

							<span *ngIf="item.btype.endsWith('-receive')">
								<span *ngIf="item.org_id !== 0">
									From : <span class="fw-bold">{{ item.org_name }}</span>
								</span>
								<span *ngIf="item.org_id === 0">
									From : <span class="fw-bold">{{ item.user_names.join(', ') }}</span>
								</span>
								<br />
								To : <span class="fw-bold">{{ item.staff_name }}</span>
								<br />
							</span>

							<span *ngIf="item.files.length === 0" class="font-italic text-nowrap">
								No Files
							</span>
							<span *ngIf="item.files.length === 1" class="fw-bold text-nowrap">
								One File ({{ item.files[0].name }})
							</span>
							<span *ngIf="item.files.length > 1" class="fw-bold text-nowrap">
								{{ item.files.length }} Files
							</span>
							<br />
							<a [routerLink]="item.id">View Details</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': focusedTab === 'report'}" role="tabpanel" id="nav-report"
			aria-labelledby="nav-report-tab">
			<div *ngIf="!loading && comingSoon" class="cp-general-width-limit mt-5">
				{{ comingSoonMessage }}
			</div>

			<button *ngIf="!loading && reportBundles.length > 1 && sortOrder === 'oldest-to-newest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Newest to Oldest
			</button>

			<button *ngIf="!loading && reportBundles.length > 0  && sortOrder === 'newest-to-oldest'" class="btn btn-outline-secondary"
				type="button" (click)="changeSortOrder()">
				<fa-icon [icon]="['fas', 'sort-alt']"></fa-icon> Sort Oldest to Newest
			</button>

			<div *ngIf="!loading && !comingSoon" class="container">
				<!-- <div>{{ receiveMessage }}</div> -->

				<div class="row">
					<div *ngFor="let item of reportBundles" class="col-4 m-0 p-0">
						<div class="border p-2 m-1 overflow-auto">
							{{ item.added_on | date: appConstants.pageDateTimeFmt}}<br />
							<div *ngIf="item.information && item.information !==''" class="fw-bold cp-pre-text overflow-auto"
								style="max-height: 300px">
								{{ item.information }}<br />
							</div>

							<span *ngIf="item.files.length === 0" class="font-italic text-nowrap">
								No Files
							</span>
							<span *ngIf="item.files.length === 1" class="fw-bold text-nowrap">
								<button type="button" class="btn btn-sm btn-outline-primary me-1"
									(click)="openDownload(item.id, item.files[0].id)" ngbTooltip="Download this file" placement="end">
									<fa-icon [icon]="['fas', 'cloud-download-alt']"></fa-icon>
									<!-- Download ({{ file.size | bytes: "1.0-0" }}) -->
								</button>
								{{ item.files[0].name }}
							</span>
							<span *ngIf="item.files.length > 1" class="fw-bold text-nowrap">
								{{ item.files.length }} Files
							</span>
							<br />
							<a [routerLink]="item.id">View Details</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>