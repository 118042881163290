import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-notifications-table',
	templateUrl: './notifications-table.component.html',
	styleUrls: ['./notifications-table.component.scss']
})
export class NotificationsTableComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			logs 
			storageKey
			options
			- showUserInfo : true
			- linkUser: false
	
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showUserInfo: boolean = true;
	linkUser: boolean = false;

	items: Models.EmailQueueEntry[] = [];
	itemsToShow: Models.EmailQueueEntry[] = [];

	columnDefs: any[] = [
		{
			field: 'not_before',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Send At',
			toolTip: null,
			icon: null
		}, {
			field: 'sent_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Sent At',
			toolTip: null,
			icon: null
		}, {
			field: '__status',
			type: 'baseText',
			sortType: 'text',
			label: 'Status',
			toolTip: null,
			icon: null
		}, {
			field: '__type',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: 'message_to',
			type: 'baseText',
			sortType: 'text',
			label: 'E-Mail',
			toolTip: null,
			icon: null
		}, {
			field: 'message_subject',
			type: 'baseText',
			sortType: 'text',
			label: 'Subject',
			toolTip: null,
			icon: null
		}, {
			field: 'num_attachments',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Attachments',
			icon: 'file'
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.EmailQueueEntry[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showUserInfo')) this.showUserInfo = options.showUserInfo;
		if (optionKeys.includes('linkUser')) this.linkUser = options.linkUser;

		this.fieldsToShow = [];
		this.fieldsToShow.push('not_before');
		this.fieldsToShow.push('sent_at');
		this.fieldsToShow.push('__status');
		this.fieldsToShow.push('__type');
		if (this.showUserInfo) this.fieldsToShow.push('message_to');
		this.fieldsToShow.push('message_subject');
		this.fieldsToShow.push('num_attachments');

		for (const item of this.items) {
			item['__status'] = MiscTools.fetchLabel(AppConstants.notificationMessageStatuses, item.message_status);
			item['__type'] = MiscTools.fetchLabel(AppConstants.notificationMessageTypes, item.message_type);
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'not_before', 'desc');


		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {

		const tmpList: Models.EmailQueueEntry[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item['__status'].toLowerCase().includes(w)
						|| item['__type'].toLowerCase().includes(w)
						|| item.message_subject.toLowerCase().includes(w)
						|| (this.showUserInfo && item.message_to.toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}
}
