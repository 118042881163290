<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Key Search</a>
			</li>
			<li *ngIf="editMode === 'edit'" class="breadcrumb-item active" aria-current="page">
				Edit License Key
			</li>
			<li *ngIf="editMode === 'setup' || editMode === 'new'" class="breadcrumb-item active" aria-current="page">
				New License Key
			</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading" class="cp-general-width-limit text-center p-4">
	<app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!loading && (editMode === 'setup' || editMode === 'template')" class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-template-tab" aria-controls="nav-template" data-bs-target="#nav-template">
				Template
			</button>
		</li>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-template" aria-labelledby="nav-template-tab">
			<div *ngIf="editMode === 'setup'" class="cp-general-width-limit">
				Choose a template to use to create this new key:

				<div *ngFor="let product of newTemplateProducts" class="fw-bold">
					<span [ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
						container="body">{{ product.label }}</span>
					<ng-template #keyProductPopupContent>
						<div class="cp-popover-banner">{{ product.label }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getKeyProductPopoverLines(product).join('\n')}}</div>
					</ng-template>

					<!-- <fa-icon *ngIf="product.description && product.description !== ''" [icon]="['fas', 'question-circle']"
						[ngbTooltip]="product.description" placement="end">
					</fa-icon> -->

					<div *ngFor="let template of getTemplatesForProduct(validNewTemplates, product.name)" class="ms-4">
						<a href="javascript:void(0)" (click)="onNewKeyTemplateSelection(template.id)" [ngbPopover]="templatePopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							<fa-icon [icon]="appConstants.sectionIcons.licensing" class="me-1"></fa-icon>
							{{ template.name }}
						</a>
						<ng-template #templatePopupContent>
							<div class="cp-popover-banner">{{ template.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div *ngIf="editMode === 'template'" class="cp-general-width-limit">
				Choose a new template for this key:
				<div *ngFor="let template of validChangeTemplates" class="fw-bold">
					<a href="javascript:void(0)" (click)="onChangeTemplateSelection(template.id)" [ngbPopover]="templatePopupContent"
						popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
						<fa-icon [icon]="appConstants.sectionIcons.licensing" class="me-1"></fa-icon>
						{{ template.name }}
					</a>
					<ng-template #templatePopupContent>
						<div class="cp-popover-banner">{{ template.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
					</ng-template>
				</div>

				<div>
					<button *ngIf="showChangeTemplateButton" class="btn btn-outline-primary mt-2" (click)="onChangeTemplateCancel()">
						Don't Change Template
					</button>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="!loading" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div>
	</div>
</div>

<div *ngIf="!loading && (editMode === 'new' || editMode === 'edit')" class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-commercial-tab"
				aria-controls="nav-commercial" data-bs-target="#nav-commercial">
				<fa-icon [icon]="['fas', 'sack-dollar']" size="sm"></fa-icon>
				Commercial
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-expiration-tab"
				aria-controls="nav-expiration" data-bs-target="#nav-expiration">
				<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
				Expiry
				<span *ngIf="showMeters"> &amp; Metering
					<span *ngIf="!loading && activation" class="cp-tab-badge">
						{{ activation.meters.length | number }}
					</span>
				</span>
			</button>
		</li>

		<li *ngIf="booleanProps.length > 0 || numberProps.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-features-tab"
				aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features/Limits
			</button>
		</li>

		<li *ngIf="otherProps.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-other-tab-tab"
				aria-controls="nav-other-tab" data-bs-target="#nav-other-tab">
				<fa-icon [icon]="['fas', 'cog']" size="sm"></fa-icon>
				Other
			</button>
		</li>

		<li *ngIf="editMode === 'new'" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-sharing-tab"
				aria-controls="nav-sharing" data-bs-target="#nav-sharing">
				<fa-icon [icon]="appConstants.sectionIcons.users" size="sm"></fa-icon>
				Sharing
			</button>
		</li>

		<li *ngIf="warnings.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-warnings-tab"
				aria-controls="nav-warnings" data-bs-target="#nav-warnings">
				<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-warning" size="sm"></fa-icon>
				Warnings
				<span class="cp-tab-badge">
					{{ warnings.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="errors.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-errors-tab"
				aria-controls="nav-errors" data-bs-target="#nav-errors">
				<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-danger" size="sm"></fa-icon>
				Errors
				<span class="cp-tab-badge">
					{{ errors.length | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="saving"></app-loading-spinner>
	</ul>

	<form [formGroup]="theKeyForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">

					<div *ngIf="editMode === 'edit'">
						<span class="cp-property-label">Key:</span>
						<span class="cp-property-value">{{ activation.key }}</span>
					</div>

					<div>
						<span class="cp-property-label">Template:</span>
						<span class="cp-property-value">
							<span [ngbPopover]="keyTemplatePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">{{ template.name }}</span>
							<ng-template #keyTemplatePopupContent>
								<div class="cp-popover-banner">{{ template.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
							</ng-template>

							<button *ngIf="showChangeTemplateButton" class="btn btn-outline-primary btn-sm ms-1 mb-1"
								(click)="enterChangeTemplateMode()" type="button" ngbTooltip="Change to a different template">
								Change
							</button>
						</span>
					</div>

					<div>
						<span class="cp-property-label">Product:</span>
						<span class="cp-property-value" [ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body">
							{{ product.label }} ({{ product.name }})
							<ng-template #keyProductPopupContent>
								<div class="cp-popover-banner">{{ product.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyProductPopoverLines(product).join('\n')}}</div>
							</ng-template>
						</span>
					</div>

					<hr />

					<div class="cp-compact-form-group form-check"
						*ngIf="editMode === 'new' || !sharedLicenseTools.isSpecialKey(activation)">
						<input type="checkbox" class="form-check-input" id="enabled" formControlName="enabled" />
						<label class="form-check-label" for="enabled">
							Key is Enabled
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Keys that aren't enabled cannot be used for new activations.">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group">
						<label for="type">
							Type*
							<fa-icon [icon]="['fas', 'question-circle']" placement="end" ngbTooltip="The intended purpose of this key.">
							</fa-icon>
						</label>
						<ng-select [items]="keyTypeSelections" bindValue="value" bindLabel="label" id="type" formControlName="type">
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="zcp_org_id">
							Organization*
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="The portal Organization that this key is associated with.">
							</fa-icon>

							<span *ngIf="editMode === 'new'" class="ms-4">
								<input type="checkbox" class="form-check-input me-1" id="remember_org" formControlName="remember_org" />
								<label class="form-check-label" for="remember_org">
									Remember Selection
									<fa-icon [icon]="['fas', 'question-circle']" placement="end"
										ngbTooltip="Remember the selected Organization and pre-select it again next time you create a key.">
									</fa-icon>
								</label>
							</span>
						</label>
						<ng-select [items]="orgSelections" bindValue="id" bindLabel="name" id="zcp_org_id" formControlName="zcp_org_id"
							(change)="onOrganizationChange()" [placeholder]="getOrgPlaceholder()">
							<ng-template ng-option-tmp let-item="item">
								<div [title]="item.name">{{item.name}}</div>
							</ng-template>
						</ng-select>
					</div>

					<div class="cp-compact-form-group">
						<label for="opportunity_id">
							Salesforce Opportunity
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="The Salesforce Opportunity that this key is tied to.">
							</fa-icon>
							<button *ngIf="showFetchAllOppsButton" [disabled]="loadingOpps" class="btn btn-outline-primary btn-sm ms-1 mb-1"
								(click)="fetchAllOpps()" type="button" ngbTooltip="Get All Salesforce Opportunities">
								<fa-icon [icon]="['fab', 'salesforce']" size="sm" [spin]="loadingOpps"></fa-icon>
							</button>
							<button *ngIf="!allOppsFetched && sfOpps && sfOpps.length > 0" type="button"
								class="btn btm-sm btn-outline-secondary ms-1 mb-1" ngbTooltip="More info on Opportunities"
								data-bs-toggle="modal" data-bs-target="#sfOppsModal">
								<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
							</button>

							<span *ngIf="loadingOpps" class="ms-1 small">Fetching opportunities from Salesforce</span>
						</label>

						<ng-select [items]="oppSelections" bindValue="id" bindLabel="name" id="opportunity_id"
							formControlName="opportunity_id" (change)="onOpportunityChange()" [placeholder]="getOppIDPlaceholder()">
							<ng-template ng-option-tmp let-item="item">
								<div [title]="item.name">{{item.name}}</div>
							</ng-template>
						</ng-select>
					</div>

					<div *ngIf="selectedSfOpp" class="ms-2 mb-2 p-2 border">
						Salesforce Opportunity Info:
						<div class="ms-2">
							<div *ngIf="selectedSfOpp.Id">
								<span class="cp-property-label">ID:</span>
								<span *ngIf="salesForceUrl === ''" class="cp-property-value">
									{{ selectedSfOpp.Id }}
								</span>
								<span *ngIf="salesForceUrl !== ''" class="cp-property-value">
									<a target="_blank" href="{{ salesForceUrl }}/lightning/r/Account/{{ selectedSfOpp.Id }}/view">
										{{ selectedSfOpp.Id }}
									</a>
								</span>
							</div>

							<div *ngIf="selectedSfOpp.CreatedDate">
								<span class="cp-property-label">Created:</span>
								<span class="cp-property-value">
									{{ selectedSfOpp.CreatedDate | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div *ngIf="selectedSfOpp.Account && selectedSfOpp.Account.Name">
								<span class="cp-property-label">Account:</span>
								<span class="cp-property-value">{{ selectedSfOpp.Account.Name }}</span>
							</div>

							<div *ngIf="selectedSfOpp.Name">
								<span class="cp-property-label">Name:</span>
								<span class="cp-property-value">
									<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(selectedSfOpp.Name)"
										[cbContent]="selectedSfOpp.Name" ngbTooltip="Copy to Clipboard" placement="end"></fa-icon>
									{{ selectedSfOpp.Name }}
								</span>
							</div>

							<div *ngIf="selectedSfOpp.Type">
								<span class="cp-property-label">Type:</span>
								<span class="cp-property-value">{{ selectedSfOpp.Type }}</span>
							</div>

							<div *ngIf="selectedSfOpp.StageName">
								<span class="cp-property-label">Stage:</span>
								<span class="cp-property-value">{{ selectedSfOpp.StageName }}</span>
							</div>

							<div *ngIf="sfPocEndDate">
								<span class="cp-property-label">POC Expiration Date:</span>
								<span class="cp-property-value">
									<fa-icon [icon]="['fas', 'copy']" ngxClipboard
										(click)="copyToClipboardAlert(textTools.formatDateUTC(sfPocEndDate))"
										[cbContent]="textTools.formatDateUTC(sfPocEndDate)" ngbTooltip="Copy to Clipboard"
										placement="end"></fa-icon>
									{{ sfPocEndDate | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div *ngIf="this.sfContractEndDate">
								<span class="cp-property-label">Contract End Date:</span>
								<span class="cp-property-value">
									<fa-icon [icon]="['fas', 'copy']" ngxClipboard
										(click)="copyToClipboardAlert(textTools.formatDateUTC(this.sfContractEndDate))"
										[cbContent]="textTools.formatDateUTC(this.sfContractEndDate)" ngbTooltip="Copy to Clipboard"
										placement="end"></fa-icon>
									{{ this.sfContractEndDate | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

						</div>
					</div>

					<div class="cp-compact-form-group">
						<label for="max">
							# Activations
							<fa-icon [icon]="['fas', 'question-circle']" [ngbTooltip]="numActivationsInfo" placement="end"></fa-icon>
						</label>
						<div class="input-group">
							<input type="text" id="max" formControlName="max" class="form-control cp-auto-width text-end" size='8'
								style="max-width: 12ex" />
							<button *ngFor="let activationMathButton of appConstants.activationMathButtons"
								class="btn btm-sm btn-outline-primary" (click)="increaseMaxActivations(activationMathButton.amount)"
								type="button" [ngbTooltip]="'Add ' + activationMathButton.amount">
								{{ activationMathButton.label }}
							</button>
						</div>
					</div>

					<div *ngIf="editMode === 'new'" class="cp-compact-form-group">
						<label for="numKeys">
							Number of Keys to Generate
							<fa-icon [icon]="['fas', 'question-circle']" [ngbTooltip]="numKeysInfo" placement="end">
							</fa-icon>
						</label>
						<input type="text" id="numKeys" formControlName="numKeys" class="form-control cp-auto-width text-end" size="8" />
					</div>

					<div class="cp-compact-form-group">
						<label for="info">
							{{ appConstants.keyInfoFieldLabel }} (Internal)*
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Specific information about what this key is meant to be used for. Only visible to staff">
							</fa-icon>
						</label>
						<input type="text" id="info" formControlName="info" class="form-control"
							placeholder="What is this key being used for?" />
					</div>

					<div class="cp-compact-form-group">
						<label for="notes">
							Notes (Internal)
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Detailed/contextal information about this key. Only visible to staff.">
							</fa-icon>
						</label>
						<textarea id="notes" formControlName="notes" class="form-control" rows="6"
							placeholder="More details about the key"></textarea>
					</div>

					<div class="cp-compact-form-group">
						<label for="ext_label">
							Label (External)
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="A label for the key that you want users with access to the it (customers/partners) to see.">
							</fa-icon>
						</label>
						<input type="text" id="ext_label" formControlName="ext_label" class="form-control"
							placeholder="A short description of the key's purpose" />
					</div>

					<div class="cp-compact-form-group">
						<label for="ext_notes">
							Notes (External)
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Information about the key that you want users with access to the it (customers/partners) to see.">
							</fa-icon>
						</label>
						<textarea id="ext_notes" formControlName="ext_notes" class="form-control" rows="6"
							placeholder="More details about the key you want users with access to see">
						</textarea>
					</div>

				</div>
			</div>

			<div class="tab-pane" id="nav-commercial" role="tabpanel" aria-labelledby="nav-commercial-tab">
				<div class="cp-general-width-limit border mb-1 p-2 pb-0">

					<div>
						<label>Commercial Type</label>
						<button type="button" class="btn btn-outline-secondary btn-sm ms-1" data-bs-toggle="modal"
							data-bs-target="#commercialTypesModal">
							<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
							Info
						</button>
					</div>
					<div class="form-check ms-1 mb-1" *ngFor="let keyCommercialType of commercialTypeSelections; let i = index">
						<input class="form-check-input" type="radio" formControlName="commercial_type" name="commercial_type"
							[id]="'commercial_type_' + keyCommercialType.value" [value]="keyCommercialType.value" />
						<label class="form-check-label" [for]="'commercial_type_' + keyCommercialType.value">
							<span *ngIf="i > 0">{{ i }}.</span> {{ keyCommercialType.label }}
							<fa-icon *ngIf="keyCommercialType.info && keyCommercialType.info !== ''" [icon]="['fas', 'question-circle']"
								[ngbPopover]="commercialTypePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body"></fa-icon>
						</label>

						<ng-template #commercialTypePopupContent>
							<div class="cp-popover-banner">{{ (i + 1) }}. {{ keyCommercialType.label }}</div>
							<ul class="ps-1 ms-1">
								<li *ngFor="let line of keyCommercialType.info.trim().split('\n')"
									[ngClass]="{ 'ms-4': line.startsWith('\t') }">
									{{ line }}
								</li>
							</ul>
						</ng-template>
					</div>

					<div class="cp-compact-form-group border-top mt-2 pt-2">
						<label for="commercial_info">
							Commercial Notes
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Information about commercials.  What is billing based on?  Specific protocols?">
							</fa-icon>
						</label>
						<textarea id="commercial_info" formControlName="commercial_info" class="form-control" rows="10"
							placeholder="Information about commercials"></textarea>
					</div>
				</div>

				<div *ngIf="protocolReady && availableSets.length > 0" class="cp-general-width-limit border mb-1 p-2 pb-0">
					<div class="text-start mb-2">
						<select id="addPsetPicker" class="form-control cp-auto-width small" (change)="addProtocolSetFromPickList()">
							<option value="">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon>
								Add a Protocol Set
							</option>
							<option *ngFor="let pSet of availableSets" [value]="pSet.id">
								{{ pSet.name }}
							</option>
						</select>
					</div>

					<div *ngIf="linkedProtocolSetInForm.length > 0">

						<table class="cp-std-table">
							<thead>
								<th>Linked Protocol Set</th>
								<th colspan="2">Projected Monthly Limit (MB)</th>
								<th class="cp-icon-header"></th>
							</thead>
							<tbody>
								<tr *ngFor="let pSet of linkedProtocolSetInForm">
									<td class="align-middle">
										<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', pSet.id]"
											[ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
											container="body">
											{{ pSet.name }}
										</a>
									</td>
									<td style="max-width:20ch">
										<input type="number" [id]="'pset_projected_' + pSet.id"
											[formControlName]="'pset_projected_' + pSet.id" style="max-width:18ch"
											class="form-control text-end display-inline" [min]="0" [max]="appConstants.maxMeterLimit"
											(keyup)="onProtocolSetProjectedChange(pSet.id)" />
									</td>
									<td class="align-middle text-start">
										{{ nicePsetProjected[pSet.id] }}
									</td>
									<td class="align-middle">
										<button type="button" class="btn btn-outline-danger float-end"
											(click)="removeProtocolSetFromForm(pSet.id)" ngbTooltip="Unlink Protocol Set">
											<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
										</button>
									</td>
									<ng-template #pSetPopupContent>
										<div class="cp-popover-banner">{{ pSet.name }}</div>
										<div class="cp-pre-text">{{
											popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
									</ng-template>
								</tr>
							</tbody>
						</table>
					</div>

					<!-- <div *ngFor="let pSet of linkedProtocolSets" class="cp-property-block mt-1">
						<span class="cp-property-label">Linked Protocol (Data) Sets:</span>
						<span class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', pSet.id]"
								[ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ pSet.name }}
							</a>
						</span>
						<ng-template #pSetPopupContent>
							<div class="cp-popover-banner">{{ pSet.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
						</ng-template>
					</div> -->

					<!-- <div *ngFor="let pSet of linkedChronoSets" class="cp-property-block mt-1">
						<span class="cp-property-label">Linked Protocol (Time) Set:</span>
						<span class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', pSet.id]"
								[ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ pSet.name }}
							</a>
						</span>
						<ng-template #pSetPopupContent>
							<div class="cp-popover-banner">{{ pSet.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
						</ng-template>
					</div>
 -->
				</div>


			</div>

			<div class="tab-pane" id="nav-expiration" role="tabpanel" aria-labelledby="nav-expiration-tab">

				<div class="container">
					<div class="row">
						<div class="col-6">
							<div class="cp-general-width-limit border mb-1 p-2 pb-0">
								<div class="cp-compact-form-group">
									<label>
										Expiry Mode
										<fa-icon [icon]="['fas', 'question-circle']" placement="end"
											ngbTooltip="Method to determine the key and its activation's lifespan.">
										</fa-icon>
									</label>
									<div class="form-check ms-1 mb-1" *ngFor="let expiryOption of expireModeSelections"
										[ngStyle]="{'display': expiryOption.value !== 'meter' && activation.meters.length > 0 ? 'none' : '' }">
										<input class="form-check-input" type="radio" formControlName="expireMode" name="expireMode"
											[id]="'expireMode_' + expiryOption.value" [value]="expiryOption.value"
											(change)="onExpireModeChange($event, expiryOption.value)" />

										<label class="form-check-label" [for]="'expireMode_' + expiryOption.value">
											{{ expiryOption.label }}
											<span *ngIf="expiryOption.value === 'date' && maxExpiryDate" class="ms-1">
												(Max. {{ maxExpiryDate | date: appConstants.shortPageDateFmt: 'UTC' }})
											</span>
										</label>

										<span *ngIf="expiryOption.value === 'date'">
											<div class="input-group">
												<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
													<fa-icon icon="calendar-alt"></fa-icon>
												</button>
												<input class="form-control" placeholder="yyyy-mm-dd" id="expires_at"
													formControlName="expires_at" ngbDatepicker firstDayOfWeek="7" #d1="ngbDatepicker"
													[maxDate]="maxExpiryStruct" style="max-width:14ch" />
												<button *ngFor="let dateMathButton of appConstants.dateMathButtons"
													class="btn btm-sm btn-outline-primary"
													(click)="increaseKeyExpiry(dateMathButton.amount, dateMathButton.unit)" type="button">
													{{ dateMathButton.label }}
												</button>
											</div>
										</span>

										<span *ngIf="expiryOption.value === 'duration'">
											<select id="duration" formControlName="duration" class="form-control cp-auto-width">
												<option *ngFor="let durationOption of durationOptions" [value]="durationOption.value">
													{{ durationOption.label }}
												</option>
											</select>
										</span>
									</div>
								</div>
							</div>

							<div *ngIf="anySpecial" class="cp-general-width-limit border mb-2 p-2">
								<!-- special boolean properties -->
								<div *ngFor="let pp of booleanProps">
									<div *ngIf="pp.property.special_property === 1" class="cp-compact-form-group form-check">
										<input type="checkbox" class="form-check-input" [id]="pp.property.name"
											[formControlName]="pp.property.name" />
										<label class="form-check-label" [for]="pp.property.name">
											{{ pp.property.label }}
											<fa-icon *ngIf="pp.property.description && pp.property.description !== ''"
												[icon]="['fas', 'question-circle']" [ngbTooltip]="pp.property.description"
												placement="end"></fa-icon>
										</label>
									</div>
								</div>

								<!-- special other properties-->
								<div *ngFor="let pp of otherProps">
									<div *ngIf="pp.property.special_property === 1" class="cp-compact-form-group">
										<label [for]="pp.property.name">
											{{ pp.property.label }}
											<fa-icon *ngIf="pp.property.description && pp.property.description !== ''"
												[icon]="['fas', 'question-circle']" [ngbTooltip]="pp.property.description"
												placement="end"></fa-icon>
										</label>
										<div class="form-check"
											*ngFor="let otherPropSelection of otherPropSelections[pp.property.name]; let i = index">
											<input type="checkbox" class="form-check-input" [formControlName]="pp.property.name + i"
												[id]="pp.property.name + i" />
											<label class="form-check-label" [for]="pp.property.name + i">
												{{ otherPropSelection }}
											</label>
										</div>
									</div>
								</div>

								<!-- special number properties-->
								<div *ngFor="let pp of numberProps">
									<div *ngIf="pp.property.special_property === 1" class="cp-compact-form-group">
										<input type="text" [id]="pp.property.name" [formControlName]="pp.property.name"
											class="form-control cp-auto-width text-end me-1" style="display: inline" size='8' />
										<label [for]="pp.property.name">
											{{ pp.property.label }}
											<span *ngIf="pp.property.units">({{pp.property.units }})</span>
											<fa-icon *ngIf="pp.property.description && pp.property.description !== ''"
												[icon]="['fas', 'question-circle']" [ngbTooltip]="pp.property.description"
												placement="end"></fa-icon>
											<span *ngIf="pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1">
												- <input type="checkbox" class="ms-1 me-1" [id]="pp.property.name + '_unlimited'"
													[formControlName]="pp.property.name + '_unlimited'"
													(change)="onUnlimitedChange($event, pp.property.name)" />
												<label class="" [for]="pp.property.name + '_unlimited'">Unlimited</label>
											</span>
										</label>
									</div>
								</div>
							</div>

							<div *ngIf="showMeters" class="cp-general-width-limit">
								<div *ngIf="selectedSfOpp && (sfPocEndDate || sfContractEndDate)" class="mb-2 p-2 border">
									<div *ngIf="sfPocEndDate">
										<span class="cp-property-label">Salesforce Opportunity POC Expiration
											Date:</span>
										<span class="cp-property-value">
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="This date comes from the Saleforce opportunity.  This may or may not be the date you should use for the meter expiration.">
											</fa-icon>
											{{ sfPocEndDate | date: appConstants.shortPageDateFmt:'UTC' }}
											<button *ngIf="!miscTools.hasExpired(sfPocEndDate)" type="button"
												class="btn btn-outline-primary btn-sm mb-1" (click)="setAllMeterExpiry(sfPocEndDate)">
												Set as Meter Expiration
											</button>
											<span *ngIf="miscTools.hasExpired(sfPocEndDate)" class="fw-bold ms-1">
												- In the past</span>
										</span>
									</div>

									<div *ngIf="sfContractEndDate">
										<span class="cp-property-label">Salesforce Opportunity Contract End Date:</span>
										<span class="cp-property-value">
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="This date comes from the Saleforce opportunity.  This may or may not be the date you should use for the meter expiration.">
											</fa-icon>
											{{ sfContractEndDate | date: appConstants.shortPageDateFmt:'UTC' }}
											<button *ngIf="! miscTools.hasExpired(sfContractEndDate)" type="button"
												class="btn btn-outline-primary btn-sm mb-1" (click)="setAllMeterExpiry(sfContractEndDate)">
												Set as Meter Expiration
											</button>
											<span *ngIf="miscTools.hasExpired(sfContractEndDate)" class="fw-bold ms-1">
												- In the past</span>
										</span>
									</div>
								</div>

								<datalist id="suggestedMeterLabels">
									<option *ngFor="let suggestedMeterLabel of appConstants.suggestedMeterLabels"
										[value]="suggestedMeterLabel">
								</datalist>

								<div *ngFor="let meter of activation.meters; let i = index" class="border mb-2 p-2">
									<button type="button" class="btn btn-outline-danger float-end" (click)="removeMeter(meterIDs[i])"
										ngbTooltip="Remove Meter">
										<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
									</button>

									<div *ngIf="meter.id === 0" class="cp-compact-form-group">
										<label>
											Type/Product for New Meter
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="What does the meter measure?">
											</fa-icon>
										</label>
										<div *ngFor="let meterProduct of meterProducts" class="form-check ms-1 me-1">
											<input class="form-check-input" type="radio"
												[id]="'meter_product' + meterIDs[i] + meterProduct.value"
												[formControlName]="'meter_product' + meterIDs[i]" [value]="meterProduct.value" />
											<label class="form-check-label" [for]="'meter_product' + meterIDs[i] + meterProduct.value">{{
												meterProduct.label }}</label>
										</div>
									</div>

									<div *ngIf="meter.id !== 0" class="cp-compact-form-group">
										<label>
											Type/Product
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="What does the meter measure?">
											</fa-icon>
										</label> {{ appConstants.meterTypeObjects[meter.product].label }}
									</div>

									<div class="cp-compact-form-group">
										<label [for]="'meter_starts_at' + meterIDs[i]">
											Label
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="Label to describe this meter.  128 characters maximum.  Non-staff can see this label.">
											</fa-icon>
										</label>
										<div class="input-group">
											<input class="form-control" [id]="'meter_label' + meterIDs[i]"
												[formControlName]="'meter_label' + meterIDs[i]" [attr.list]="'suggestedMeterLabels'" />
										</div>
									</div>

									<div class="cp-compact-form-group">
										<label [for]="'meter_starts_at' + meterIDs[i]">
											Starts
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="When does this meter become active?">
											</fa-icon>
										</label>
										<div class="input-group">
											<button class="btn btn-outline-secondary" (click)="meter_starts_at.toggle()" type="button">
												<fa-icon icon="calendar-alt"></fa-icon>
											</button>
											<input class="form-control" placeholder="yyyy-mm-dd" [id]="'meter_starts_at' + meterIDs[i]"
												[formControlName]="'meter_starts_at' + meterIDs[i]" ngbDatepicker firstDayOfWeek="7"
												#meter_starts_at="ngbDatepicker" style="max-width:15ch" />
										</div>
									</div>
									<div class="cp-compact-form-group">
										<label [for]="'meter_expires_at' + meterIDs[i]">
											Expires
											<fa-icon [icon]="['fas', 'question-circle']" placement="end"
												ngbTooltip="When does the meter expire?">
											</fa-icon>
											<span *ngIf="maxExpiryDate" class="ms-1">
												(Max. {{ maxExpiryDate | date: appConstants.shortPageDateFmt: 'UTC' }})
											</span>
										</label>
										<div class="input-group">
											<button class="btn btn-outline-secondary" (click)="meter_expires_at.toggle()" type="button">
												<fa-icon icon="calendar-alt"></fa-icon>
											</button>

											<input class="form-control" placeholder="yyyy-mm-dd" [id]="'meter_expires_at' + meterIDs[i]"
												[formControlName]="'meter_expires_at' + meterIDs[i]" ngbDatepicker firstDayOfWeek="7"
												#meter_expires_at="ngbDatepicker" [maxDate]="maxExpiryStruct" style="max-width:14ch" />
											<button *ngFor="let dateMathButton of appConstants.dateMathButtons"
												class="btn btm-sm btn-outline-primary"
												(click)="increaseMeterExpiry(i, dateMathButton.amount, dateMathButton.unit)" type="button">
												{{ dateMathButton.label }}
											</button>
										</div>
									</div>

									<div class="cp-compact-form-group">
										<div class="row row g-1">
											<div class="col-auto me-2">
												<label [for]="'meter_limit' + meterIDs[i]">
													Maximum/Limit (MB) {{ niceMeterLimits[i] }}
													<fa-icon [icon]="['fas', 'question-circle']" placement="end"
														ngbTooltip="Maximum Limit for this meter.">
													</fa-icon>
												</label>
												<div class="input-group">
													<input type="text" [id]="'meter_limit' + meterIDs[i]"
														[formControlName]="'meter_limit' + meterIDs[i]" style="max-width:24ch"
														class="form-control cp-auto-width text-end" (keyup)="onMeterLimitChange(i)" />
													<button class="btn btm-sm btn-outline-primary" (click)="setMeterToMax(i)"
														type="button">Max</button>
												</div>
											</div>

											<div class="col-auto">
												<label [for]="'meter_projected' + meterIDs[i]">
													Projected (MB) {{ niceMeterProjecteds[i] }}
													<fa-icon [icon]="['fas', 'question-circle']" placement="end"
														ngbTooltip="(Optional) Projected usage threshold of this meter.  May be used for notifications and/or overage.">
													</fa-icon>
												</label>
												<input type="text" [id]="'meter_projected' + meterIDs[i]"
													[formControlName]="'meter_projected' + meterIDs[i]" style="max-width:24ch"
													class="form-control cp-auto-width text-end" (keyup)="onMeterProjectedChange(i)" />
											</div>
										</div>
									</div>

									<!-- <div class="cp-compact-form-group">
										<div class="input-group">
											<div class="input-group-prepend">
												<button class="btn btm-sm btn-outline-secondary" type="button" placement="end"
													ngbTooltip="Enter # of streams, rate and press month or year button to calculate a meter limit.">
													<fa-icon [icon]="['fas', 'calculator']">
													</fa-icon>
												</button>
											</div>
											<input type="text" [id]="'meter_calc_streams' + meterIDs[i]"
												[formControlName]="'meter_calc_streams' + meterIDs[i]" style="max-width:12ch"
												class="form-control cp-auto-width text-end inline" placeholder="# streams" />
											<input type="text" [id]="'meter_calc_rate' + meterIDs[i]"
												[formControlName]="'meter_calc_rate' + meterIDs[i]" style="max-width:12ch"
												class="form-control cp-auto-width text-end inline" placeholder="Mbits/s" />
											<div class="input-group-text">
												<button class="btn btm-sm btn-outline-secondary" (click)="calculateLimit(i, 31)"
													type="button">Month</button>
												<button class="btn btm-sm btn-outline-secondary" (click)="calculateLimit(i, 365)"
													type="button">Year</button>
											</div>
										</div>
									</div> -->

									<div class="cp-compact-form-group">
										<label class="me-2">
											Resets
											<fa-icon [icon]="['fas', 'question-circle']" placement="end" ngbTooltip="Does the meter reset.">
											</fa-icon>
										</label>
										<div *ngFor="let meterResetOption of meterResetOptions" class="form-check form-check-inline">
											<input class="form-check-input" type="radio"
												[id]="'meter_resets' + meterIDs[i] + meterResetOption.value"
												[formControlName]="'meter_resets' + meterIDs[i]" [value]="meterResetOption.value" />
											<label class="form-check-label" [for]="'meter_resets' + meterIDs[i] + meterResetOption.value">{{
												meterResetOption.label }}</label>
										</div>
									</div>


									<!-- <div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
										<label class="form-check-label" for="inlineRadio1">1</label>
									  </div> -->

								</div>

								<button type="button" class="btn btn-outline-primary" (click)="addMeter()">
									<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
									Add Meter
								</button>
							</div>

						</div>
						<div class="col-6">
							<div *ngIf="commercial_type.value != null && commercial_type.value !== ''">
								<div class="fw-bold">{{ getCommercialTypeLabel(commercial_type.value) }}</div>
								<ul class="ms-2 ps-0">
									<li *ngFor="let line of getCommercialTypeInfo(commercial_type.value).trim().split('\n')"
										[ngClass]="{ 'ms-4': line.startsWith('\t') }">
										{{ line }}
									</li>
								</ul>

								<button *ngIf="anyMetersWithoutLabels" type="button" class="btn btn-outline-primary"
									(click)="setMeterLabels()">
									<fa-icon [icon]="['fas', 'wand-magic']" size="sm"></fa-icon>
									Set Meter Labels Based on Commercial Type Where Blank
								</button>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-features" role="tabpanel" aria-labelledby="nav-features-tab">
				<div class="container">
					<div class="row">
						<div *ngIf="booleanProps.length > 0" class="col-auto">
							<div class="text-center fw-bold">
								Features
							</div>
							<div class="cp-compact-form-group form-check border-bottom">
								<input type="checkbox" class="form-check-input" formControlName="check_all_boolean" id="check_all_boolean"
									value="yes" (change)="checkAllBoolean()">
								<label class="form-check-label" for="check_all_boolean">
									Check/Uncheck All
								</label>
							</div>

							<div *ngFor="let pp of getNonSpecialProps(booleanProps)" class="cp-compact-form-group form-check">
								<input type="checkbox" class="form-check-input" [id]="pp.property.name"
									[formControlName]="pp.property.name" />
								<label class="form-check-label" [for]="pp.property.name">
									{{ pp.property.label }}
									<fa-icon *ngIf="pp.property.description && pp.property.description !== ''"
										[icon]="['fas', 'question-circle']" [ngbTooltip]="pp.property.description"
										placement="end"></fa-icon>

									<sup *ngIf="template && template.settings.nonCheckAllFeatures && template.settings.nonCheckAllFeatures.includes(pp.property_id)"
										ngbTooltip="Excluded from check all">{{ excludedFromCheckAllSymbol }}</sup>
								</label>
							</div>
						</div>

						<div *ngIf="numberProps.length > 0" class="col-auto">
							<div class="text-center fw-bold">
								Limits
							</div>
							<table>
								<tr *ngIf="numUnlimited > 0" class="mb-1 align-middle">
									<td></td>
									<td></td>
									<td>
										<input type="checkbox" class="ms-1 me-1" formControlName="check_all_limits" id="check_all_limits"
											value="yes" (change)="checkAllUnlimited()">
										<label for="check_all_limits">
											Check/Uncheck All
										</label>
									</td>
								</tr>

								<tr *ngFor="let pp of getNonSpecialProps(numberProps)" class="mb-1 align-middle">
									<td class="text-end pe-2">
										{{ pp.property.label }}
										<span *ngIf="pp.property.units">({{pp.property.units }})</span>
										<fa-icon *ngIf="pp.property.description && pp.property.description !== ''"
											[icon]="['fas', 'question-circle']" [ngbTooltip]="pp.property.description"
											placement="end"></fa-icon>
										<span
											*ngIf="template && template.settings.nonAllUnlimitedLimits && template.settings.nonAllUnlimitedLimits.includes(pp.property_id)"
											ngbTooltip="Excluded from check all unlimited">*</span>
										<span *ngIf="pp.min_value > 0">(Min. {{ pp.min_value }})</span>
										<span *ngIf="pp.max_value > 0">(Max. {{ pp.max_value }})</span>
									</td>

									<td>
										<input type="text" [id]="pp.property.name" [formControlName]="pp.property.name"
											class="form-control cp-auto-width text-end me-1" size='8' />
									</td>

									<td>
										<span *ngIf="pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1">
											<input type="checkbox" class="ms-1 me-1" [id]="pp.property.name + '_unlimited'"
												[formControlName]="pp.property.name + '_unlimited'"
												(change)="onUnlimitedChange($event, pp.property.name)" />
											<label class="" [for]="pp.property.name + '_unlimited'">Unlimited</label>
										</span>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
				<div class="cp-general-width-limit">
					<div *ngFor="let pp of getNonSpecialProps(otherProps)" class="cp-compact-form-group">
						<label [for]="pp.property.name">
							{{ pp.property.label }}
							<fa-icon *ngIf="pp.property.description && pp.property.description !== ''" [icon]="['fas', 'question-circle']"
								[ngbTooltip]="pp.property.description" placement="end"></fa-icon>
						</label>
						<div class="form-check" *ngFor="let otherPropSelection of otherPropSelections[pp.property.name]; let i = index">
							<input type="checkbox" class="form-check-input" [formControlName]="pp.property.name + i"
								[id]="pp.property.name + i" />
							<label class="form-check-label" [for]="pp.property.name + i">
								{{ otherPropSelection }}
							</label>
						</div>
					</div>
				</div>

				<!-- <button class="btn btn-outline-secondary" type="button" (click)="dumpFormErrors()">
					Dump
				</button>
				{{ meterIDs }} -->
			</div>

			<div class="tab-pane" id="nav-sharing" role="tabpanel" aria-labelledby="nav-sharing-tab">
				<div class="cp-general-width-limit border mb-1 p-2 pb-0">
					<div class="cp-compact-form-group form-check border-bottom pb-2 mb-2">
						<input type="checkbox" class="form-check-input" id="share_self" formControlName="share_self" />
						<label class="form-check-label" for="share_self">
							Add To Your Own Keys
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Add the key(s) to your own set of keys (a.k.a. My Keys).">
							</fa-icon>
						</label>
					</div>

					<div class="cp-compact-form-group">
						<label *ngIf="shareUserMode === 'org'" for="share_users">
							Organizations User(s) to Share With [{{ shareUserSelections.length | number }}]
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Share the key(s) with one or more users in the Organization tied to the key.">
							</fa-icon>

							<button class="btn btn-outline-primary btn-sm ms-1 mb-1" (click)="toggleShareUserSelectionMode()" type="button"
								ngbTooltip="Select any user">
								Show All Users
							</button>
						</label>

						<label *ngIf="shareUserMode === 'all'" for="share_users">
							Any User(s) to Share With [{{ shareUserSelections.length | number }}]
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Share the key(s) with one or more users from any organization.">
							</fa-icon>

							<button class="btn btn-outline-primary btn-sm ms-1 mb-1" (click)="toggleShareUserSelectionMode()" type="button"
								ngbTooltip="Select from key's organization's users">
								Show Organization's Users
							</button>
						</label>

						<div *ngIf="shareUserSelections.length === 0 && !theKeyForm.value.zcp_org_id">
							Select an Organization
						</div>

						<div *ngIf="shareUserSelections.length === 0 && theKeyForm.value.zcp_org_id && shareUserMode === 'org'">
							This Organization doesn't have any users.
						</div>

						<div *ngIf="shareUserSelections.length > 0 && shareUserSelections.length <= maxUserCheckListSize">
							<div *ngFor="let shareUserSelection of shareUserSelections; let i = index"
								class="form-group form-check mb-0 mt-0">
								<input type="checkbox" class="form-check-input" [id]="'shareUser_' + shareUserSelection.id"
									(change)="onUserSelectionCheckChange($event, shareUserSelection.id)"
									formControlName="share_user_check_list" />
								<label class="form-check-label" [for]="'shareUser_' + shareUserSelection.id">
									{{ shareUserSelection.name }}
								</label>
							</div>
						</div>

						<div *ngIf="shareUserSelections.length > maxUserCheckListSize">
							<ng-select *ngIf="shareUserSelections.length !== 0" [items]="shareUserSelections" bindValue="id"
								bindLabel="name" id="share_users" formControlName="share_users" [multiple]="true" [closeOnSelect]="false">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.name">{{item.name}}</div>
								</ng-template>
							</ng-select>
						</div>
					</div>

					<div class="cp-compact-form-group">
						<label for="share_label">
							Label for Key
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="This label will be added to the key for the user(s) you've shared it with.">
							</fa-icon>
						</label>
						<input type="text" id="share_label" formControlName="share_label" class="form-control" />
					</div>

					<div *ngIf="this.shareUserSelections.length !== 0" class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="share_notify" formControlName="share_notify" />
						<label class="form-check-label" for="share_notify">
							Send Notification
							<fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Inform (via e-mail) the user(s) that a new key has been shared with them.">
							</fa-icon>
						</label>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-errors" role="tabpanel" aria-labelledby="nav-errors-tab">
				<div *ngFor="let err of errors" class="cp-form-error cp-general-width-limit">
					{{ err }}
				</div>
			</div>

			<div class="tab-pane" id="nav-warnings" role="tabpanel" aria-labelledby="nav-warnings-tab">
				<div *ngFor="let warning of warnings" class="cp-form-error cp-general-width-limit">
					{{ warning }}
				</div>
			</div>

		</div>

		<div class="cp-form-padding"></div>

		<div *ngIf="!loading" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="saving">
					<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>

		<!-- <div class="bottom-bar">
			<div class="form-group mb-2 small">* Required</div>
			<div class="form-group mb-0" *ngIf="!saving">
				<button class="btn btn-primary me-1" type="submit">
					<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
					<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
				</button>
			</div>
		</div> -->
	</form>
</div>

<div class="modal" id="sfOppsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Salesforce Opportunities</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeSfOppsModal">

				</button>
			</div>
			<div class="modal-body">
				<table class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th></th>
							<th>ID</th>
							<th>Name</th>
							<th>Created</th>
							<th>Stage</th>
							<th>POC Exp.</th>
							<th>Con. End</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let sfOpp of sfOpps">
							<td class="small">
								<button type=" button" class="btn btn-sm btn-outline-secondary" (click)="selectOpp(sfOpp.Id)">
									<fa-icon [icon]="['fas', 'check']"></fa-icon>
								</button>
							</td>
							<td class="small">{{ sfOpp.Id }}</td>
							<td class="small">{{ sfOpp.Name }}</td>
							<td class="small text-nowrap">
								{{ sfOpp.CreatedDate | date: appConstants.tableDateFmt:'UTC' }}
							</td>
							<td class="small text-nowrap">{{ sfOpp.StageName }}</td>
							<td class="small text-nowrap">
								<span *ngIf="sfOpp.PoC_Expiration_Date__c">
									{{ sfOpp.PoC_Expiration_Date__c | date: appConstants.tableDateFmt:'UTC' }}
								</span>
							</td>
							<td class="small text-nowrap">
								<span *ngIf="sfOpp.Contract_End_Date__c">
									{{ sfOpp.Contract_End_Date__c | date: appConstants.tableDateFmt:'UTC' }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="commercialTypesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Commercial Types</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeCommercialTypesModal">

				</button>
			</div>
			<div class="modal-body">
				<div class="mb-1" *ngFor="let keyCommercialType of appConstants.keyCommercialTypes; let i = index">
					<div class="fw-bold">{{ (i + 1) }}. {{ keyCommercialType.label }}</div>
					<ul class="ms-3 ps-2">
						<li *ngFor="let line of keyCommercialType.info.trim().split('\n')" [ngClass]="{ 'ms-4': line.startsWith('\t') }">
							{{ line }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>