<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Protocol Sets</a></li>
			<li *ngIf="protocolSet" class="breadcrumb-item active" aria-current="page">
				{{ protocolSet.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!protocolSet">
				<button *ngIf="canManage" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canManage && deleteForm" type="button" class="btn btn-outline-danger" data-bs-toggle="modal"
					data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">

	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="keys.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-keys-tab"
				aria-controls="nav-keys" data-bs-target="#nav-keys" ngbTooltip="Keys Using this Template">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Keys
				<span *ngIf="!loading || keys.length > 0" class="cp-tab-badge">
					{{ keys.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>


	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!protocolSet" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ protocolSet.name }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!protocolSet.description && protocolSet.description !== ''">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ protocolSet.description }}</span>
				</div>

				<div *ngFor="let protocolBlock of protocolBlocks" class="cp-property-block">
					<span class="cp-property-label">{{ protocolBlock.label }}: ({{ protocolBlock.protocols.length }})
					</span>
					<div class="row">
						<div *ngFor="let col of [1, 2, 3]">
							<div *ngIf="getColumnSubList(col, protocolBlock.protocols).length > 0">
								<ul class="cp-property-value mb-0">
									<li *ngFor="let p of getColumnSubList(col, protocolBlock.protocols)">
										{{ p }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ protocolSet.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="protocolSet.added_on !== protocolSet.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ protocolSet.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-keys" role="tabpanel" aria-labelledby="nav-keys-tab">
			<div *ngIf="keys.length !== 0" class="text-end mb-2">
				<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
					ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
					<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Warnings
				</button>
				<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
					ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
					<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Warnings
				</button>
			</div>

			<app-keys-table #keysTable1></app-keys-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!protocolSet && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Protocol Set.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Protocol Set</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Protocol Set, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canManage" type="button" [disabled]="loading" (click)="delete()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Protocol Set
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>