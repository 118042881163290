
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { PackagesService } from '../packages.service';
import { UsersService } from '../../users/users.service';
import { ProductsService } from '../../products/products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { BuildsService } from '../../builds/builds.service';
import { AuthService } from 'client/app/services/auth.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { DocumentsService } from '../../documents/documents.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadableBuildsTableComponent } from 'client/app/components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from 'client/app/components/shared/downloadable-documents-table/downloadable-documents-table.component';
import { UsersTableComponent } from 'client/app/components/shared/users-table/users-table.component';

@Component({
	selector: 'app-package',
	templateUrl: './package.component.html',
	styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('dBuildsTable1') dBuildsTable1: DownloadableBuildsTableComponent = null;
	@ViewChild('dDocsTable1') dDocsTable1: DownloadableDocumentsTableComponent = null;
	@ViewChild('usersTable1') usersTable1: UsersTableComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	package: Models.Package;

	// other stuff
	private userSubscription: Subscription;
	users: Models.User[] = [];
	builds: Models.Build[] = [];
	documents: Models.Document[] = [];
	buildDownloads: Models.DownloadableBuild[] = [];
	documentDownloads: Models.DownloadableDocument[] = [];

	templates: Models.KeyTemplate[] = [];
	templatesById: any = {};

	canEdit = false;
	canDelete = false;

	registrationUrl = '';

	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	productTypesToShow = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;
	adminLogs: Models.AdminLog[] = [];

	deleteForm: UntypedFormGroup;
	showDeleteMsg = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private packagesService: PackagesService,
		private usersService: UsersService,
		private buildsService: BuildsService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private licensingAdminService: LicensingAdminService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.package = this.packagesService.getOne(this.id);
			if (!this.package || this.package == null || this.package.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe(authUser => {
					this.canEdit = authUser && ValidationTools.checkAccess(authUser, 'manage-packages');
					this.canDelete = this.canEdit && authUser && ValidationTools.checkAccess(authUser, 'delete-packages');
					this.loadData();
				});

				this.packagesService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		// changes
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();

		for (const productType of AppConstants.productTypes) {
			let countForType = 0;
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.package.product_platforms, product.id, platform.id) !== -1;
					if (this.findProductPlatform(this.package.product_platforms, product.id, platform.id) !== -1) countForType++;
				}
			}
			if (countForType !== 0) this.productTypesToShow.push(productType);
		}

		this.templates = await this.licensingAdminService.getTemplates();

		this.templatesById = {};
		for (const keyTemplate of this.package.key_templates) {
			const idx = MiscTools.findIndex(this.templates, keyTemplate.template_id);
			if (idx !== -1) this.templatesById[keyTemplate.id] = this.templates[idx];
		} // for

		this.registrationUrl = await this.packagesService.getRegistrationUrl(this.id);

		if (this.package.document_ids && this.package.document_ids.length !== 0) {
			for (const did of this.package.document_ids) {
				const d = this.documentsService.getOne(did);
				if (d) {
					this.documents.push(d);
				}
			}
		}

		if (this.package.build_ids && this.package.build_ids.length !== 0) {
			for (const bid of this.package.build_ids) {
				const b = this.buildsService.getOne(bid);
				if (b) {
					this.builds.push(b);
				}
			}
		}

		this.addedByUser = null;
		if (this.package.added_by && this.package.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.package.added_by);

		this.editedByUser = null;
		if (this.package.edited_by && this.package.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.package.edited_by);

		this.buildDownloads = await this.myBuildsService.fetchBuildsForPackageFromDB(this.id);
		if (this.dBuildsTable1) this.dBuildsTable1.updateContent(this.buildDownloads, 'cp-package-view-downloadable-builds');

		this.documentDownloads = await this.myDocumentsService.fetchDocumentsForPackageFromDB(this.id);
		if (this.dDocsTable1) this.dDocsTable1.updateContent(this.documentDownloads, 'cp-package-view-downloadable-documents');

		this.users = this.usersService.getUsersForPackage(this.id);
		if (this.usersTable1) this.usersTable1.updateContent(this.users, 'cp-package-view-users-list', { showOrganization: false });

		this.adminLogs = await this.adminLogsService.getLogs(['package'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-package-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.deleteForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;
	}

	async delete() {
		this.loading = true;

		let confirmation = '';
		if (this.deleteForm.value.confirmation) confirmation = this.deleteForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'delete') {
			this.showDeleteMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDeleteModalButton"))
			document.getElementById("closeDeleteModalButton").click();

		const result = await this.packagesService.deleteOne(this.package.id);
		if (result) {
			this.uiAlertsService.addMsg('The package (' + this.package.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.packages]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Package?')) {
			const result = await this.packagesService.toggleEnabled(this.package.id);
			if (result) {
				this.package = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Package?')) {
			const result = await this.packagesService.toggleEnabled(this.package.id);
			if (result) {
				this.package = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	getProduct(id: number) {
		return this.productsService.getOne(id);
	}

	getPlatform(id: number) {
		return this.platformsService.getOne(id);
	}

	getFileCount(dl: Models.DownloadableBuild): number {
		let count = 0;
		if (dl.build.platform_files) count += dl.build.platform_files.length;
		if (dl.build.release_notes_file_id && dl.build.release_notes_file_id !== 0) count++;
		return count;
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	getPlatformIdsForProduct(productId: number, productPlatforms: Models.ProductPlatform[]): number[] {
		const pids = [];
		for (const pp of productPlatforms)
			if (pp.product_id === productId)
				pids.push(pp.platform_id);
		return pids;
	}

	supportsKeys() {
		return this.package.ptype === AppConstants.basicWithKeysPackageType;
	}

	async deleteActivationRule(id: number) {
		if (confirm('Are you sure you want to delete this activation rule?')) {

			const result = await this.packagesService.deleteOneActivationRule(this.package.id, id);
			if (result) {
				this.package = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async deleteKeyTemplates(id: number) {
		if (confirm('Are you sure you want to delete this key template?')) {
			const result = await this.packagesService.deleteKeyTemplate(this.package.id, id);
			if (result) {
				this.package = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	subListOfUsedProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				for (const pp of this.package.product_platforms) {
					if (pp.product_id === product.id) {
						sublist.push(product);
						break;
					}
				}
			}
		}
		return sublist;
	}

	// subListOfPlatformNames(productID: number) {
	// 	const sublist: string[] = [];
	// 	for (const platform of this.allPlatforms) {
	// 		if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
	// 			for (const pp of this.package.product_platforms) {
	// 				if (pp.product_id === productID && pp.platform_id === platform.id) {
	// 					sublist.push(platform.name);
	// 					break;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	return sublist;
	// }

	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}

	// getTemplateName(id: number): string {
	// 	const idx = MiscTools.findIndex(this.templates, id);
	// 	if (idx !== -1) return this.templates[idx].name;
	// 	return '??';
	// }

	getTemplate(id: number): Models.KeyTemplate {
		const idx = MiscTools.findIndex(this.templates, id);
		if (idx !== -1) return this.templates[idx];
		return null;
	}

	getUserIssues(user: Models.User) {
		return ValidationTools.getUserIssues(user, true).join(' ');
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}
}
