import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingService } from '../licensing.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-commercial-form',
	templateUrl: './commercial-form.component.html',
	styleUrls: ['./commercial-form.component.scss']
})
export class CommercialFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	id: number; // activation ID
	activation: Models.LPActivation;
	guess: string = '';

	// 'standard' edit stuff

	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	staffUserSelections: any[] = [];

	usersWithAccess: Models.User[] = [];

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	commercialTypeSelections: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private licensingService: LicensingService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.guess = params.get('guess');
		});

	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			if (!ValidationTools.checkAccess(this.authUser, 'manage-commercial')) {
				this.onCancel();
				return;
			};
			this.loadData();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {

		this.commercialTypeSelections = [];
		this.commercialTypeSelections.push({
			value: '',
			label: 'Not configured',
			short_label: 'Not configured',
			acronym: '',
			info: ''
		});
		for (const keyCommercialType of AppConstants.keyCommercialTypes)
			this.commercialTypeSelections.push(keyCommercialType);


		this.activation = await this.licensingService.getOne(this.id);
		if (!this.activation || this.activation == null || this.activation.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		if (this.guess != null && this.guess !== '' && (this.activation.commercial_type == null || this.activation.commercial_type === ''))
			this.activation.commercial_type = this.guess;

		this.theForm = new UntypedFormGroup({
			commercial_type: new UntypedFormControl(this.activation.commercial_type),
			commercial_info: new UntypedFormControl(this.activation.commercial_info),
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		const errors: string[] = [];

		let commercialType: string = this.theForm.value.commercial_type;
		if (commercialType == null) commercialType = '';

		let commercialInfo: string = this.theForm.value.commercial_info;
		if (commercialInfo == null) commercialInfo = '';

		if (errors.length > 0) {
			this.uiAlertsService.addMsgs(errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			await this.licensingService.setKeyCommercial(this.id, commercialType, commercialInfo);

			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id], { queryParams: { tabID: 'nav-commercial-tab' } });
	}

}
