import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-notification-users-table',
	templateUrl: './notification-users-table.component.html',
	styleUrls: ['./notification-users-table.component.scss']
})
export class NotificationUsersTableComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			users 
			storageKey
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	items: Models.User[] = [];
	itemsToShow: Models.User[] = [];

	columnDefs: any[] = [
		{
			field: '__will_send',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Will Send',
			icon: 'check'
		}, {
			field: '__reason',
			type: 'baseText',
			sortType: 'text',
			label: 'Info',
			toolTip: null,
			icon: null
		}, {
			field: '__send_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Send At',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'org_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Organization',
			toolTip: null,
			icon: null
		}, {
			field: 'email',
			type: 'baseText',
			sortType: 'text',
			label: 'E-Mail',
			toolTip: null,
			icon: null
		}, {
			field: 'last_login',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Last Login',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.User[], storageKey: string) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		this.fieldsToShow = [];

		this.fieldsToShow.push('__will_send');
		this.fieldsToShow.push('__reason');
		this.fieldsToShow.push('__send_at');
		this.fieldsToShow.push('name');
		this.fieldsToShow.push('org_name');
		this.fieldsToShow.push('email');
		this.fieldsToShow.push('last_login');

		for (const item of this.items) {
			if (item['__reason'] && item['__reason'] !== '')
				item['__will_send'] = 'n';
			else
				item['__will_send'] = 'y';
			item['__send_at'] = ValidationTools.workoutNotificationSendTime(item);
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');


		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {

		const tmpList: Models.User[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| item.email.toLowerCase().includes(w)
						|| item.role.toLowerCase().includes(w)
						|| item['__reason'].toLowerCase().includes(w)
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
