import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class OrganizationsService {
	organizations: Observable<Models.Organization[]>;
	loaded = false;
	private debug = false;
	private _organizations: ReplaySubject<Models.Organization[]>;
	private dataStore: {
		organizations: Models.Organization[];
	};

	constructor(private http: HttpClient,
		private datePipe: DatePipe,
		private dashboardService: DashboardService
	) {
		this.dataStore = {
			organizations: []
		};
		this._organizations = new ReplaySubject(1) as ReplaySubject<Models.Organization[]>;
		this.organizations = this._organizations.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newOrganization: Models.Organization): void {
		const idx = this.dataStore.organizations.findIndex(organization => organization.id === newOrganization.id);
		if (idx === -1) {
			this.dataStore.organizations.push(newOrganization);
			return;
		} else {
			this.dataStore.organizations[idx] = newOrganization;
		}
	}

	// get a fresh copy of the entire set
	async justFetch() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations;
			const result = await this.http.get<Models.Organization[]>(url).toPromise();
			const arr: Models.Organization[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		} // try
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.Organization[]> {
		this.dashboardService.checkVersion();

		const organizations$ = this.http.get<Models.Organization[]>(AppConstants.apiUrl + AppConstants.apiUrls.organizations).pipe(share());
		organizations$.subscribe(
			data => {
				const organizations: Models.Organization[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.organizations.forEach((existingOrganization, existingIndex) => {
					const newIndex = organizations.findIndex(organization => organization.id === existingOrganization.id);
					if (newIndex === -1) this.dataStore.organizations.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				organizations.forEach(organization => this.updateStore(organization));
				this._organizations.next(Object.assign({}, this.dataStore).organizations);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return organizations$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.Organization> {
		this.dashboardService.checkVersion();

		const organization$ = this.http.get<Models.Organization>(AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id).pipe(share());
		organization$.subscribe(
			data => {
				const organization: Models.Organization = data;
				// add/update the one that came back
				this.updateStore(organization);
				this._organizations.next(Object.assign({}, this.dataStore).organizations);
			},
			error => { /* console.log(error) */ }
		);
		return organization$;
	}

	// return the whole list
	getAll(): Models.Organization[] {
		return this.dataStore.organizations.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.Organization {
		return this.dataStore.organizations.find(organization => organization.id === id);
	}

	// call back-end to add an item
	async addOne(organization: Models.Organization) {
		try {
			const result = await this.http.post<Models.Organization>(AppConstants.apiUrl + AppConstants.apiUrls.organizations, organization).toPromise();
			const returnedOrganization: Models.Organization = result;
			this.updateStore(returnedOrganization);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return returnedOrganization;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update an item
	async updateOne(organization: Models.Organization) {
		try {
			const result = await this.http.put<Models.Organization>(AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + organization.id, organization).toPromise();
			const returnedOrganization: Models.Organization = result;
			this.updateStore(returnedOrganization);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return returnedOrganization;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete an item
	async deleteOne(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id).toPromise();
			const idx = this.dataStore.organizations.findIndex(organization => organization.id === id);
			if (idx !== -1) this.dataStore.organizations.splice(idx, 1);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// ********************************************************
	// other non-standard methods
	// ********************************************************

	// call back-end to enable/disable
	async toggleEnabled(id: number) {
		try {
			const result = await this.http.put<Models.Organization>(AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id + '/toggle-enabled', {}).toPromise();
			const returnedOrganization: Models.Organization = result;
			this.updateStore(returnedOrganization);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return returnedOrganization;
		} catch (error) {
			// MiscTools.handleBackendError(error);
		}
	}

	async fetchSalesforceAccounts() {
		try {
			const result = await this.http.get<any[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/salesforce/accounts').toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchSalesforceOpportunities() {
		try {
			const result = await this.http.get<any[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/salesforce/opportunities').toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchSalesforceContacts(acctID: string) {
		try {
			const result = await this.http.get<Models.User[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/salesforce/accounts/' + acctID + '/contacts').toPromise();
			const arr: Models.User[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchOrganizationOpportunities(id: number, selectedOppID: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id + '/opportunities';
			if (selectedOppID && selectedOppID !== '')
				url += '/' + encodeURIComponent(selectedOppID);

			const result = await this.http.get<any[]>(url).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async makeSalesForceObjectBlock(objectType: string, objectID: string) {
		let block = '';

		const obj = await this.fetchSalesforceObject(objectType, objectID);
		if (!obj) {
			block += 'A problem occurred.  The object may have been deleted.';
		} else {
			let objectLabel = '';
			if (objectType === 'accounts') {
				objectLabel = 'Account';

				if (obj.Name) block += 'Name: ' + obj.Name + '\n';
				if (obj.Type) block += 'Type: ' + obj.Type + '\n';
				if (obj.Customer_Type__c) block += 'Type: ' + obj.Customer_Type__c + '\n';
				if (obj.AccountSource) block += 'Source: ' + obj.AccountSource + '\n';
				if (obj.OwnerId) {
					const owner = await this.fetchSalesforceObject('users', obj.OwnerId);
					if (owner.Name) block += 'Owner: ' + owner.Name + '\n';
				}

				if (obj.Sales_Engineer__c) {
					const se = await this.fetchSalesforceObject('users', obj.Sales_Engineer__c);
					if (se.Name) block += 'Sales Engineer: ' + se.Name + '\n';
				}

			} else if (objectType === 'contacts') {
				objectLabel = 'Contact';

				if (obj.AccountId) {
					const acct = await this.fetchSalesforceObject('accounts', obj.AccountId);
					if (acct.Name) block += 'Account: ' + acct.Name + '\n';
				}

				if (obj.Name) block += 'Name: ' + obj.Name + '\n';
				if (obj.Company) block += 'Company: ' + obj.Company + '\n';
				if (obj.Title) block += 'Title: ' + obj.Title + '\n';
				if (obj.LeadSource) block += 'Source: ' + obj.LeadSource + '\n';
				if (obj.Email) block += 'E-Mail: ' + obj.Email + '\n';
				if (obj.Phone) block += 'Phone: ' + obj.Phone + '\n';
				if (obj.Country) block += 'Country: ' + obj.Country + '\n';

			} else if (objectType === 'leads') {
				objectLabel = 'Lead';
				if (obj.Status) block += 'Status: ' + obj.Status + '\n';
				if (obj.Name) block += 'Name: ' + obj.Name + '\n';
				if (obj.Company) block += 'Company: ' + obj.Company + '\n';
				if (obj.Title) block += 'Title: ' + obj.Title + '\n';
				if (obj.LeadSource) block += 'Source: ' + obj.LeadSource + '\n';
				if (obj.Email) block += 'E-Mail: ' + obj.Email + '\n';
				if (obj.Phone) block += 'Phone: ' + obj.Phone + '\n';
				if (obj.Country) block += 'Country: ' + obj.Country + '\n';
				if (obj.State) block += 'State: ' + obj.State + '\n';
				if (obj.Description) block += 'Description: ' + obj.Description + '\n';
				if (obj.OwnerId) {
					const owner = await this.fetchSalesforceObject('users', obj.OwnerId);
					if (owner.Name) block += 'Owner: ' + owner.Name + '\n';
				}
				// ConvertedContactId
				// IsConverted

			} else if (objectType === 'opportunities') {
				objectLabel = 'Opportunity';

				if (obj.AccountId) {
					const account = await this.fetchSalesforceObject('accounts', obj.AccountId);
					if (account.Name) block += 'Account: ' + account.Name + ' (' + obj.AccountId + ')\n';
				}
				if (obj.Name) block += 'Name: ' + obj.Name + '\n';
				if (obj.Type) block += 'Type: ' + obj.Type + '\n';
				if (obj.StageName) block += 'Stage: ' + obj.StageName + '\n';

				if (obj.PoC_Expiration_Date__c) {
					const ced = new Date(obj.PoC_Expiration_Date__c);
					if (ced && !isNaN(ced.getTime()))
						block += 'POC Expiration Date: ' + this.datePipe.transform(ced, AppConstants.shortPageDateFmt, 'UTC') + '\n';
				}  // if

				if (obj.Contract_End_Date__c) {
					const ced = new Date(obj.Contract_End_Date__c);
					if (ced && !isNaN(ced.getTime()))
						block += 'Contract End Date: ' + this.datePipe.transform(ced, AppConstants.shortPageDateFmt, 'UTC') + '\n';
				}  // if

			} else if (objectType === 'users') {
				objectLabel = 'User';

				if (obj.Name) block += 'User Name: ' + obj.Name + '\n';
				if (obj.Email) block += 'User E-Mail: ' + obj.Email + '\n';
			}

			if (obj.CreatedDate) {
				const theDate = new Date(obj.CreatedDate);
				if (theDate && !isNaN(theDate.getTime()))
					block = 'Created: ' + this.datePipe.transform(theDate) + '\n' + block;
			}
			block = objectLabel + ' Information from Salesforce\n' + block;
		}

		return block.trim();
	}

	async fetchSalesforceObject(objectType: string, objectID: string) {
		try {
			// accounts, contacts, leads, opportunities, users
			const result: any = await this.http.get<any>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/salesforce/' + objectType + '/' + objectID).toPromise();
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to toggle favorite
	async toggeleFavorite(id: number) {
		try {
			const result = await this.http.put<Models.Organization>(AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id + '/toggle-favorite', {}).toPromise();
			const returnedOrganization: Models.Organization = result;
			this.updateStore(returnedOrganization);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return returnedOrganization;
		} catch (error) {
		}
	}

	async fetchKeys(id: number) {
		try {
			const result = await this.http.get<Models.LPActivation[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + id + '/license-keys').toPromise();
			const arr: Models.LPActivation[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get each of the orgs's key's active host count
	async getActiveHostCounts(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + id + '/active-host-counts';
			const result = await this.http.get<any>(url).toPromise();
			const arr: any = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a billing code
	async addBillingCode(orgID: number, billingCode: Models.BillingCode) {
		try {
			const result = await this.http.post<Models.BillingCode>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + orgID + '/billing-codes', billingCode).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update add a billing code
	async updateBillingCode(billingCode: Models.BillingCode) {
		try {
			const result = await this.http.put<Models.BillingCode>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + billingCode.zcp_org_id + '/billing-codes/' + billingCode.id, billingCode).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to reset a billing code's auth code
	async resetBillCodeAuthCode(orgID: number, billingCodeID: number) {
		try {
			const result = await this.http.put<Models.BillingCode>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + orgID + '/billing-codes/' + billingCodeID + '/reset', {}).toPromise();
			const returnedBC: Models.BillingCode = result;
			return returnedBC;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchBillingCodes(orgID: number) {
		try {
			const result = await this.http.get<Models.BillingCode[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + orgID + '/billing-codes').toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to kick of org meter usage report
	async kickOffOrgMeterReport(orgID: number, startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing
				+ '/notifications/staff-org-meter-usage/' + encodeURIComponent(orgID) + '?a=b';

			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			let body = { async: 'yes' };
			const result = await this.http.post<any>(url, body).toPromise();
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to kick of org meter usage report
	async kickOffOrgProtocolReport(orgID: number, startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing
				+ '/notifications/staff-org-protocol-usage/' + encodeURIComponent(orgID) + '?a=b';

			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			let body = { async: 'yes' };
			const result = await this.http.post<any>(url, body).toPromise();
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async fetchZenBuilds(prefix: string, skipPlatformCheck: boolean) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/zen-builds/' + prefix;
			if (skipPlatformCheck) url += '?skipPlatformCheck=yes';

			const result = await this.http.get<Models.ZenBuild[]>(url).toPromise();
			const arr: Models.ZenBuild[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get an organizations's pen tests
	async getOrganizationPenTests(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + encodeURIComponent(id) + '/pen-tests';
			const result = await this.http.get<Models.BroadcasterPenTest[]>(url).toPromise();
			const arr: Models.BroadcasterPenTest[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to billing code admins for an org
	async setBillingCodeAdmins(orgID: number, userIds: number[]) {
		try {
			const result = await this.http.put<Models.Organization>(AppConstants.apiUrl
				+ AppConstants.apiUrls.organizations + '/' + orgID + '/billing-code-admins', { userIds }).toPromise();
			const returnedOrganization: Models.Organization = result;
			this.updateStore(returnedOrganization);
			this._organizations.next(Object.assign({}, this.dataStore).organizations);
			return returnedOrganization;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}
