import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { UsersService } from '../users.service';
import { UserGroupsService } from '../../users/user-groups.service';

@Component({
	selector: 'app-access-summary',
	templateUrl: './access-summary.component.html',
	styleUrls: ['./access-summary.component.scss']
})

export class AccessSummaryComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;

	assignedTagGroupings = {};
	roleTagGroupings = {};
	userGroupTagGroupings = {};

	users: Models.User[] = [];
	userGroups: Models.UserGroup[] = [];

	selectedTag = '';
	usersToShow: Models.User[] = [];
	userGroupsToShow: Models.UserGroup[] = [];

	adminUsers: Models.User[] = [];

	showUsersInGroup = {};
	usersInGroup = {};

	showAdminUsers = false;

	adminRoleLabel = AppConstants.userRoleLabels[AppConstants.adminUserRole];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService
	) {
	}

	ngOnInit(): void {
		this.loadOthers();
	}

	ngOnDestroy() {
	}

	async loadOthers() {
		this.users = this.usersService.getAll();
		this.userGroups = this.userGroupsService.getAll();

		for (const group of this.userGroups) {
			this.showUsersInGroup[group.id] = 'no';
			this.usersInGroup[group.id] = [];
			for (const uid of group.user_ids) {
				const idx = MiscTools.findIndex(this.users, uid);
				if (idx !== -1) this.usersInGroup[group.id].push(this.users[idx]);
			}
		}

		this.adminUsers = [];
		for (const user of this.users)
			if (user.role === AppConstants.adminUserRole)
				this.adminUsers.push(user);

	}

	selectTag(tag: string) {
		this.selectedTag = tag;
		this.usersToShow = [];
		this.userGroupsToShow = [];

		for (const user of this.users)
			if (user.directAccessTags.includes(tag))
				this.usersToShow.push(user);

		for (const group of this.userGroups)
			if (group.accessTags.includes(tag))
				this.userGroupsToShow.push(group);
	}

	getGroupings(tagGroupings: any): string[] {
		return Object.keys(tagGroupings);
	}

	findUser(id: number): Models.User {
		const idx = MiscTools.findIndex(this.users, id);
		if (idx !== -1) return this.users[idx];
		return null;
	}

	toggleUsersInGroup(id: number) {
		if (!this.showUsersInGroup[id] || this.showUsersInGroup[id] === 'no')
			this.showUsersInGroup[id] = 'yes';
		else
			this.showUsersInGroup[id] = 'no';
	}

	toggleAdminUsers() {
		this.showAdminUsers = !this.showAdminUsers;
	}

}
