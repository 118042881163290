import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { OrganizationsService } from '../organizations.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-organization-billing-code-form',
	templateUrl: './organization-billing-code-form.component.html',
	styleUrls: ['./organization-billing-code-form.component.scss']
})
export class OrganizationBillingCodeFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	id2: number;
	organization: Models.Organization;
	billingCode: Models.BillingCode;

	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;

	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private authService: AuthService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.id2 = +params.get('id2');
			if (this.id && this.id !== 0) {
				this.organization = this.organizationsService.getOne(this.id);
				if (!this.organization || this.organization == null || this.organization.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {

		});

		this.setup();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async setup() {
		if (this.id2 && this.id2 !== 0) {
			const orgBillingCodes = await this.organizationsService.fetchBillingCodes(this.id);
			const idx = MiscTools.findIndex(orgBillingCodes, this.id2);
			if (idx !== -1) {
				this.billingCode = orgBillingCodes[idx];
				this.editMode = true;
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			}
		} else {
			this.billingCode = new Models.BillingCode(this.id, 0, '', '', '', 1);
			this.editMode = false;
		}

		this.initForm();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			label: new UntypedFormControl(this.billingCode.label, [Validators.required])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.billingCode.label = this.theForm.value.label;

		try {
			let retBc = null;
			if (this.editMode)
				retBc = await this.organizationsService.updateBillingCode(this.billingCode);
			else
				retBc = await this.organizationsService.addBillingCode(this.id, this.billingCode);

			if (retBc) {
				this.router.navigate([AppConstants.urls.organizations, this.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.organizations, this.id]);
	}

	get label() { return this.theForm.get('label'); }
}
