<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)"
						class="text-start cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="!loading && itemsToShow?.length > 0">
				<tr *ngFor="let item of itemsToShow; let i = index">
					<td>{{ item.document.prod_name }}</td>
					<td>
						<fa-icon *ngIf="item.document.is_private === 1" [icon]="['fas', 'lock']"
							class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
						<fa-icon *ngIf="item.document.is_private === 0" [icon]="['fas', 'globe']"
							class="text-success me-1" ngbTooltip="Public - Generally Available"></fa-icon>
						<a [routerLink]="['/' + appConstants.urls.documents, item.document.id]">{{
							item.document.label }}</a>

					</td>
					<td class="text-nowrap">
						{{ item.document.added_on | date: appConstants.tableDateTimeFmt }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>