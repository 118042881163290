import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { OrganizationsService } from '../organizations.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { ProductsService } from '../../products/products.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-organization-form',
	templateUrl: './organization-form.component.html',
	styleUrls: ['./organization-form.component.scss']
})
export class OrganizationFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	organization: Models.Organization;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	otypes = [];
	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	availableProductTypes = [];
	counter = 0;

	sfAccounts = [];
	sfAccountSelections = [];

	salesforceAccountIDArg = '';

	flagSelections: any[] = [];

	parentOrgSelections: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private organizationsService: OrganizationsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private usersService: UsersService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.organization = this.organizationsService.getOne(this.id);
				if (!this.organization || this.organization == null || this.organization.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.organizationsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.organization = new Models.Organization(0, AppConstants.defaultOrganizationType, '', '', null, 1);
				this.salesforceAccountIDArg = params.get('salesforce-account-id');
			}
			// this.loadExtras();
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;

				this.otypes = [];
				for (const organizationType of AppConstants.organizationTypes)
					if (ValidationTools.checkAccess(authUser, 'manage-organizations-' + organizationType))
						this.otypes.push(organizationType);

				if (!authUser || this.otypes.indexOf(this.organization.otype) === -1)
					this.onCancel();

				if (this.editMode) {
					this.listSubscription = this.organizationsService.organizations.subscribe(organizations => {
						this.organization = organizations.find((organization: Models.Organization) => organization.id === this.id);
						this.loadAndSetup();
					});
				} else {
					this.loadAndSetup();
				}

			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadAndSetup() {
		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();

		this.sfAccounts = await this.organizationsService.fetchSalesforceAccounts();
		// sfAccounts = [];
		this.sfAccountSelections = [];
		for (const account of this.sfAccounts) {
			let acctOwner = '';
			if (!account.OwnerName || account.OwnerName === '')
				acctOwner = 'No Acct. Owner';
			else
				acctOwner = account.OwnerName;

			let se = '';
			if (!account.SEName || account.SEName === '')
				se = 'No SE';
			else
				se = account.SEName;

			this.sfAccountSelections.push({
				value: account.Id,
				label: account.Name + ' (' + acctOwner + '/' + se + ') ' + account.Id
			});
		} // for

		if (this.salesforceAccountIDArg && this.salesforceAccountIDArg !== '') {
			// check to make sure the account exists and is selectable
			const idx = MiscTools.findIndexGeneric(this.sfAccounts, 'Id', this.salesforceAccountIDArg);
			if (idx !== -1) {
				this.organization.salesforce_account_id = this.salesforceAccountIDArg;
				if (this.organization.name === '') this.organization.name = this.sfAccounts[idx].Name;
			} // 
		}

		this.parentOrgSelections = [];
		this.parentOrgSelections.push({ id: 0, name: 'None' });
		const allOrgs = this.organizationsService.getAll();
		for (const org of allOrgs)
			if (org.id !== this.id)
				this.parentOrgSelections.push({ id: org.id, name: org.name });

		this.flagSelections = [];
		for (const flag of AppConstants.organizationFlags)
			this.flagSelections.push(flag);

		if (ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole))
			for (const flag of AppConstants.adminOnlyOrganizationFlags)
				this.flagSelections.push(flag);

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.organization.is_enabled),
			otype: new UntypedFormControl(this.organization.otype, [Validators.required]),
			name: new UntypedFormControl(this.organization.name, [Validators.required]),
			information: new UntypedFormControl(this.organization.information),
			email_domains: new UntypedFormControl(this.organization.email_domains),
			salesforce_account_id: new UntypedFormControl(this.organization.salesforce_account_id),
			parent_org_id: new UntypedFormControl(this.organization.parent_org_id)
		});

		for (const flag of this.flagSelections) {
			let checked = (this.organization.flags && this.organization.flags![flag.key]
				&& +this.organization.flags[flag.key] === 1);
			this.theForm.addControl('flag_' + flag.key, new UntypedFormControl(checked));
		} // for

		// add form controls for each of the check boxes...
		this.counter = 0;
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.organization.product_platforms, product.id, platform.id) !== -1;
					if (checked) this.counter++;
					this.theForm.addControl('pp_' + product.id + '_' + platform.id, new UntypedFormControl(checked));
				} // for
			} // for
		} // for

		this.setUpChecks(this.organization.otype);
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.organization.is_enabled = this.theForm.value.is_enabled;
		this.organization.otype = this.theForm.value.otype;
		this.organization.name = this.theForm.value.name;
		this.organization.information = this.theForm.value.information;
		this.organization.email_domains = this.theForm.value.email_domains;
		this.organization.parent_org_id = +this.theForm.value.parent_org_id;

		if (this.organization.parent_org_id == null || isNaN(this.organization.parent_org_id))
			this.organization.parent_org_id = 0;

		let sfAccountId = this.theForm.value.salesforce_account_id;
		if (!this.editMode && sfAccountId && sfAccountId !== '') {
			const idx = MiscTools.findIndexGeneric(this.sfAccounts, 'Id', sfAccountId);
			let acctOwner = '';
			let se = '';
			if (idx !== -1) {
				acctOwner = this.sfAccounts[idx].OwnerName;
				se = this.sfAccounts[idx].SEName;
			} // if

			if (acctOwner === '') {
				sfAccountId = '';
				this.errors.push('The Salesforce account linked to new organizations must have an Account Owner.');
			} // if

			if (se === '') {
				sfAccountId = '';
				this.errors.push('The Salesforce account linked to new organizations must have an SE.');
			} // if
		} // if
		this.organization.salesforce_account_id = sfAccountId;

		this.organization.flags = {};
		for (const flag of this.flagSelections) {
			const val = this.theForm.value['flag_' + flag.key];
			if (val) this.organization.flags[flag.key] = 1;
		} // for

		if (AppConstants.strictOrganizationTypes.includes(this.organization.otype)
			&& this.organization.is_enabled && (!sfAccountId || sfAccountId === ''))
			this.errors.push('An enabled Organization must be linked to a Salesforce Account.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		const productPlatforms: Models.ProductPlatform[] = [];
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const val = this.theForm.value['pp_' + product.id + '_' + platform.id];
					if (val) {
						productPlatforms.push({ product_id: product.id, platform_id: platform.id });
					}
				}
			}
		}
		this.organization.product_platforms = productPlatforms;

		try {
			let retOrg: Models.Organization;
			if (this.editMode)
				retOrg = await this.organizationsService.updateOne(this.organization);
			else
				retOrg = await this.organizationsService.addOne(this.organization);

			if (retOrg) {
				this.checkForSalesforceDupes(retOrg);
				this.router.navigate([AppConstants.urls.organizations + '/' + retOrg.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	checkForSalesforceDupes(theOrg: Models.Organization) {
		if (!theOrg.salesforce_account_id || theOrg.salesforce_account_id === '')
			return;

		const allOrgs = this.organizationsService.getAll();
		const otherSfOrgs = [];
		for (const org of allOrgs)
			if (org.id !== theOrg.id && org.salesforce_account_id === theOrg.salesforce_account_id)
				otherSfOrgs.push(org.name);

		if (otherSfOrgs.length === 1)
			this.uiAlertsService.addMsg('FYI: There is another Organization (' + otherSfOrgs.join('; ') + ') that is linked to the same Salesforce account.', 'info');
		else if (otherSfOrgs.length > 1)
			this.uiAlertsService.addMsg('FYI: There are other Organizations (' + otherSfOrgs.join('; ') + ') that are linked to the same Salesforce account.', 'info');
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	onTypeChange(e, otype) {
		this.setUpChecks(otype);
	}

	setUpChecks(otype) {
		this.availableProductTypes = AppConstants.organizationTypeProductTypes['**all**'];
		if (AppConstants.organizationTypeProductTypes[otype])
			this.availableProductTypes = this.availableProductTypes.concat(AppConstants.organizationTypeProductTypes[otype]);

		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const controlName = 'pp_' + product.id + '_' + platform.id;
					const val = this.theForm.value[controlName];
					// console.log(controlName + ' - ' + val);

					// uncheck and disable ones that aren't available...
					const idx = this.availableProductTypes.indexOf(productType);
					if (idx === -1) {
						this.theForm.controls[controlName].setValue(false);
						this.theForm.controls[controlName].disable();
					} else {
						this.theForm.controls[controlName].enable();
					}
				}
			}
		}
	}

	onAccountSelect(e) {
		// console.log('sf account selected....');
		// console.log(e);
		if (!this.editMode && e && e.value && e.value !== '') {
			const idx = MiscTools.findIndexGeneric(this.sfAccounts, 'Id', e.value);
			if (idx !== -1) this.theForm.patchValue({ name: this.sfAccounts[idx].Name });
		}
	}

	isChecked(productID: number, platformID: number): boolean {
		// this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
		const val = this.theForm.value['pp_' + productID + '_' + platformID];
		return val;
	}

	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (!ValidationTools.hasFlag(product, 'private_only') && product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}

	adjustCounter(e) {
		if (e.target.checked) {
			this.counter++;
		} else {
			this.counter--;
		}
	}

	checkAllForProductType(e, productType) {
		// console.log('productType=' + productType);
		// console.log(e.target.checked);
		for (const product of this.subListOfProducts(productType)) {
			this.setValueForProduct(product.id, e.target.checked);
		}
	}

	checkAllForProduct(e, productID) {
		this.setValueForProduct(productID, e.target.checked);
	}

	setValueForProduct(productID: number, newValue: boolean) {
		// console.log('productID=' + productID);
		// console.log(newValue);
		for (const platform of this.subListOfPlatforms(productID)) {
			const curVal = this.theForm.value['pp_' + productID + '_' + platform.id];
			if (curVal !== newValue) {
				if (newValue) {
					this.counter++;
				} else {
					this.counter--;
				}
				this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
			} // if
		}
	}

	get otype() { return this.theForm.get('otype'); }
	get name() { return this.theForm.get('name'); }
}
