<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a routerLink="..">
					<fa-icon [icon]="appConstants.sectionIcons.emailqueue" class="cp-admin-nav-item me-1" size="sm">
					</fa-icon>
					Notifications
				</a></li>
			<li class="breadcrumb-item active" aria-current="page">E-Mail Failures</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<table *ngIf="logs && logs.length > 0" class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>Date/Time</th>
				<th>Recipient</th>
				<th>Subject</th>
				<th>Failure</th>
			</tr>
		</thead>
		<tbody *ngIf="logs && !loading">
			<tr *ngFor="let item of logs">
				<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.timestamp)">
					{{ item.timestamp | date: appConstants.tableDateTimeFmt }}
				</td>
				<td class="text-nowrap">{{ item.recipient }}</td>
				<td>{{ item.subject }}</td>
				<td>{{ item.message }} {{ item.description }}</td>
			</tr>
		</tbody>
	</table>
</div>