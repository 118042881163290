// tslint:disable: no-string-literal
import * as Models from './shared-models';
import AppConstants from './app-constants';
import TextTools from './text-tools';
import MiscTools from './misc-tools';

class ValidationTools {
	// ************************************************************************************************
	static checkRole = (userRole: string, minRole: string): boolean => {
		const uIdx = AppConstants.userRoles.indexOf(userRole);
		const mIdx = AppConstants.userRoles.indexOf(minRole);

		if (uIdx < mIdx || uIdx === -1 || mIdx === -1)
			return false;
		else
			return true;
	};

	// ************************************************************************************************
	static checkAccess = (user: Models.User | Models.AuthUser, tag: string): boolean => {
		return user && user != null && user.mergedAccessTags && user.mergedAccessTags.includes(tag);
	};

	// ************************************************************************************************
	static hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return (obj && obj != null && obj.flags && obj.flags[flag] && +obj.flags[flag] === 1);
	};

	// ************************************************************************************************
	static getOrganizationIssues = (organization: Models.Organization, shortInfo: boolean = false): string[] => {
		const onboardingStatus: string[] = [];

		if (!AppConstants.strictOrganizationTypes.includes(organization.otype)) return ([]);

		let flags: string[] = [];

		if (!organization.salesforce_se || organization.salesforce_se === '')
			if (shortInfo)
				onboardingStatus.push('No SE/Tech-Rep.');
			else
				onboardingStatus.push('Organization does not have an SE/Tech-Rep.');

		if (!organization.salesforce_account_id || organization.salesforce_account_id === '')
			if (shortInfo)
				onboardingStatus.push('No Salesforce Account.');
			else
				onboardingStatus.push('Organization is not linked to a Salesforce Account.');

		if (organization.is_enabled === 0)
			if (shortInfo) {
				onboardingStatus.push('Not enabled.');
			} else {
				onboardingStatus.push('Organization is not enabled.');
				onboardingStatus.push('Users will not have access to Organization\'s Builds and Documents.');
			}

		if (organization.product_platforms.length === 0
			&& organization.build_ids.length === 0 && organization.document_ids.length === 0)
			if (shortInfo)
				onboardingStatus.push('No Products.');
			else
				onboardingStatus.push('Organization does not have access to any Products/Platforms or specific Builds/Documents.');

		if (!ValidationTools.hasFlag(organization, 'no_users'))
			if (organization.num_users === 0)
				if (shortInfo)
					onboardingStatus.push('No Users.');
				else
					onboardingStatus.push('Organization does not have at least one User.');

			else if (organization.num_welcomed === 0)
				if (shortInfo)
					onboardingStatus.push('No welcomed Users.');
				else
					onboardingStatus.push('Organization does not have at least one User that has been sent a \"Welcome Message\".');

		return onboardingStatus;
	};

	// ************************************************************************************************
	static getUserIssues = (user: Models.User, shortInfo: boolean = false): string[] => {
		const onboardingStatus: string[] = [];

		if (user.is_enabled === 0)
			if (shortInfo)
				onboardingStatus.push('Not enabled.');
			else
				onboardingStatus.push('User is not enabled. User will not be allowed to login.');

		let welcomed = false;
		if (user.use_sso === 1 || user.last_login != null || user.reset_password_expires != null
			|| !user.added_by || user.added_by === user.id || user.edited_by === user.id)
			welcomed = true;

		if (!welcomed)
			if (shortInfo)
				onboardingStatus.push('Not welcomed.');
			else
				onboardingStatus.push('User has not been sent a \"Welcome Message\".');
		else if (user.last_login == null)
			if (shortInfo)
				onboardingStatus.push('Never logged in.');
			else
				onboardingStatus.push('User has not logged in.');

		// const domain = TextTools.getEmailDomain(user.email).toLowerCase().trim();
		// let badDomain = false;
		// for (const fd of AppConstants.forbiddenSelfRegistrationDomains)
		// 	if (domain === fd || domain.endsWith('.' + fd))
		// 		badDomain = true;
		// if (badDomain)
		// 	onboardingStatus.push('E-mail address uses a non-business domain.');

		// if ((!user.org_id || user.org_id === 0) && (!user.package_ids || user.package_ids.length === 0))
		// 	onboardingStatus.push('User is not part of an Organization and not subscribed to any Packages.  They won\'t be able to download anthing.');

		return onboardingStatus;
	};

	// ************************************************************************************************
	static getPossibleUserIssues = (user: Models.User, shortInfo: boolean = false): string[] => {
		const issues: string[] = [];

		const domain = TextTools.getEmailDomain(user.email).toLowerCase().trim();
		let badDomain = false;
		for (const fd of AppConstants.forbiddenSelfRegistrationDomains)
			if (domain === fd || domain.endsWith('.' + fd))
				badDomain = true;
		if (badDomain)
			issues.push('E-mail address uses a non-business domain.');

		if (user.name.trim().toLowerCase() === user.email.trim().toLowerCase()) {
			issues.push('User\'s name is the same as their e-mail address.');
		} else {
			const splitName: string[] = user.name.trim().replace(/\s\s/g, ' ').split(' ');
			if (splitName.length === 1)
				issues.push('User does not appear to have a first and last name.');

			if (user.name.trim().toLowerCase() === user.name.trim()) {
				issues.push('User\'s entire name is all lower case.');
			} else if (user.name.trim().toUpperCase() === user.name.trim()) {
				issues.push('User\'s entire name is all upper case.');
			} else {
				for (const namePart of splitName) {
					if (namePart.trim().toLowerCase() === namePart.trim())
						issues.push('Part of user\'s name is all lower case.');
					else if (namePart.trim().toUpperCase() === namePart.trim())
						issues.push('Part of user\'s name is all upper case.');
				} // for
			} // if
		} // if


		return issues;
	};

	// ************************************************************************************************
	static getBuildProductNotificationState = (user: Models.User, productID: number): string => {
		let reason = '';

		if (user.is_enabled === 0) {
			reason = 'not enabled';
		} else if (!user.last_login) {
			reason = 'never logged in';
		} else if (!user.notifications) {
			reason = 'not setup';
		} else if (!user.notifications.receiveBuildMessages) {
			reason = 'not subscribed';
		} else if (user.notifications.excludedBuildProductIDs
			&& user.notifications.excludedBuildProductIDs.includes(productID)) {
			reason = 'excluded';
		} else if (user.notifications.includedBuildProductIDs
			&& user.notifications.includedBuildProductIDs.length > 0
			&& !user.notifications.includedBuildProductIDs.includes(productID)) {
			reason = 'not included';
		}
		return reason;
	};

	// ************************************************************************************************
	static getDocumentProductNotificationState = (user: Models.User, productID: number): string => {
		let reason = '';
		if (user.is_enabled === 0) {
			reason = 'not enabled';
		} else if (!user.last_login) {
			reason = 'never logged in';
		} else if (!user.notifications) {
			reason = 'not setup';
		} else if (!user.notifications.receiveDocumentMessages) {
			reason = 'not subscribed';
		} else if (user.notifications.excludedDocumentProductIDs
			&& user.notifications.excludedDocumentProductIDs.includes(productID)) {
			reason = 'excluded';
		} else if (user.notifications.includedDocumentProductIDs
			&& user.notifications.includedDocumentProductIDs.length > 0
			&& !user.notifications.includedDocumentProductIDs.includes(productID)) {
			reason = 'not included';
		}
		return reason;
	};

	// ************************************************************************************************
	static getGeneralNotificationState = (user: Models.User): string => {
		let reason = '';
		if (user.is_enabled === 0) {
			reason = 'not enabled';
		} else if (!user.last_login) {
			reason = 'never logged in';
		} else if (!user.notifications) {
			reason = 'not setup';
		} else if (!user.notifications.receiveGenerallMessages) {
			reason = 'not subscribed';
		}
		return reason;
	};

	// ************************************************************************************************
	static workoutNotificationSendTime = (user: Models.User, checkDayOfWeek = false): Date => {
		let theTime = new Date();

		if (user.notifications && user.notifications.deliveryMode === 'window') {
			const now = new Date();
			// const utcHour = now.getUTCHours();
			// console.log('Current hour in UTC ' + utcHour);

			const localeHour = ValidationTools.getCurrentHourInTimezone(user.notifications.timezone);
			// console.log('Current hour in ' + user.notifications.timezone + ' = ' + localeHour);

			// const utcDiff = utcHour - localeHour;
			// console.log('utcDiff = ' + utcDiff);

			let jumpHours = 0;
			if (user.notifications.startHour <= user.notifications.endHour) {
				if (localeHour < user.notifications.startHour) {
					// console.log('a1');
					jumpHours = user.notifications.startHour - localeHour;
				} else if (localeHour > user.notifications.endHour) {
					// console.log('a2');
					jumpHours = 24 - localeHour + user.notifications.startHour;
				}

			} else {
				if (localeHour < user.notifications.startHour && localeHour > user.notifications.endHour) {
					// console.log('b1');
					jumpHours = user.notifications.startHour - localeHour;
				} else if (localeHour > user.notifications.endHour && localeHour < user.notifications.startHour) {
					// console.log('b2');
					jumpHours = 24 - localeHour + user.notifications.startHour;
				}

			}
			if (jumpHours !== 0) {
				// console.log('jumpHours=' + jumpHours);
				theTime.setTime(theTime.getTime() + (jumpHours * 1000 * 60 * 60));
				// console.log('theTime=' + theTime);
				theTime.setUTCMinutes(0);
				theTime.setUTCSeconds(0);
				// console.log('theTime=' + theTime);
			}
		} // if

		if (checkDayOfWeek && user.notifications && user.notifications.timezone
			&& user.notifications.daysOfWeek && user.notifications.daysOfWeek.length > 0) {
			// need to get the dow in the user's timezone
			let tz = 'UTC';
			if (user.notifications.timezone) tz = user.notifications.timezone;

			let dow = '';
			try {
				const opts: any = {
					timeZone: tz,
					weekday: 'long'
				};
				dow = theTime.toLocaleString('en-US', opts);
			} catch (e) {
				const opts: any = {
					timeZone: 'UTC',
					weekday: 'long'
				};
				dow = theTime.toLocaleString('en-US', opts);
			}

			if (!user.notifications.daysOfWeek.includes(dow)) theTime = null;
			// console.log(theTime);
		}

		return theTime;
	};

	// ************************************************************************************************
	static getCurrentHourInTimezone = (tz: string): number => {
		const d = new Date();
		try {
			const opts: any = {
				timeZone: tz,
				hourCycle: 'h23',
				hour: '2-digit'
			};
			return parseInt(d.toLocaleTimeString('en-US', opts));
		} catch (e) {
			const opts: any = {
				timeZone: 'UTC',
				hourCycle: 'h23',
				hour: '2-digit'

			};
			return parseInt(d.toLocaleTimeString('en-US', opts));
		}
	};

	// ************************************************************************************************
	static getZenUserSupportLevel = (user: Models.ZenMasterUser): string => {
		if (user == null) return 'no-account';

		if (user.is_zixi_support === 1)
			if (user.is_zixi_support_write === 1)
				return 'support-write';
			else
				return 'support-readonly';
		return 'not-support';
	};

	// ************************************************************************************************
	static getZenUserSupportLabel = (user: Models.ZenMasterUser, shortVersion: boolean = false): string => {
		const level = ValidationTools.getZenUserSupportLevel(user);
		const idx = MiscTools.findIndexGeneric(AppConstants.zenStaffAccessLevels, 'level', level);
		if (idx !== -1) {
			if (shortVersion)
				return AppConstants.zenStaffAccessLevels[idx].short;
			else
				return AppConstants.zenStaffAccessLevels[idx].label;
		} // if

		return '';
	};

	// ************************************************************************************************
	static getZenUserNotificationState = (zcpUsers: Models.User[], zenOrgIDs: number[], zenUser: Models.ZenMasterUser): string => {
		let reason = '';

		const idx = MiscTools.findIndexGeneric(zcpUsers, 'email', zenUser.email.trim().toLowerCase());
		if (idx !== -1) {
			if (!zcpUsers[idx].org_id || zcpUsers[idx].org_id === 0) {
				reason = 'ZCP User Not Part of an Organization';
			} else {
				if (!zenOrgIDs.includes(zcpUsers[idx].org_id)) {
					reason = 'ZEN Master Product Not Selected for User\'s Organization';
				} else {
					reason = TextTools.capitalizeFirstLetter(ValidationTools.getGeneralNotificationState(zcpUsers[idx]));
					if (reason === '') reason = 'Subscribed';
				} // if
			} // if
		} else {
			reason = 'No ZCP Account';
		} // if

		return reason;
	};

	// ************************************************************************************************
	static isNotificationSnoozed = (userSnoozes: Models.UserLicenseKeySnooze[], userKey: Models.UserLicenseKey, notificationType: string): boolean => {
		if (!userSnoozes || userSnoozes.length === 0) return false;
		if (!userKey) return false;

		const now = new Date();
		for (const snooze of userSnoozes) {
			const expDate = new Date(snooze.expires_at);
			// console.log('-----------');
			// console.log('userKey.id ' + userKey.id);
			// console.log('snooze.user_key_id ' + snooze.user_key_id);
			// console.log('snooze.snooze_type ' + snooze.snooze_type);
			// console.log('snooze.expires_at ' + snooze.expires_at);
			// console.log('snooze.expires_at ' + (expDate.getTime() < now.getTime()));

			if (snooze.user_key_id === userKey.id && expDate.getTime() > now.getTime() && ['all', notificationType].includes(snooze.snooze_type))
				return true;
		} // for

		return false;
	};

	// ************************************************************************************************
	static workOutUserCleanUp = (user: Models.User): any => {
		let cleanupDate: Date = null;
		let reason: string = '';
		let maxDays: number = -1;
		let baseDate: Date = null;
		let baseDateType: string = '';

		const disabledUserMaxTouchedDays: number = 90;

		const noLoginUserSelfRegMaxDays: number = 30;
		const noLoginUserStaffAddedMaxDays: number = 60;

		const dormantNoOrgUserNoKeysNoNotificationsMaxDays: number = 365;
		const dormantOrgUserNoKeysNoNotificationsMaxDays: number = 730;

		if (user && user.role === 'basic') {
			if (user.is_enabled === 1) { // enabled users
				if (user.last_login == null) { // users that have never logged in
					reason = 'User account is enabled, but the user has never logged in.';
					baseDate = new Date(user.added_on);
					baseDateType = 'added';
					if (user.reset_password_expires) {
						baseDate = new Date(user.reset_password_expires);
						baseDateType = 'password-reset';
					} // if

					if ((user.org_id && user.org_id !== 0) || (user.added_by && user.added_by !== 0))
						maxDays = noLoginUserStaffAddedMaxDays;
					else
						maxDays = noLoginUserSelfRegMaxDays;
				} else { // user logged in at least once...
					// users with no keys linked to them and haven't setup notifications
					// in other words, probably don't need access to the portal
					// TBD - for users with keys, maybe dive into the keys to see if any
					// of them are still valid (not disabled/expired) and possibly ever used
					if (user.num_keys === 0 && user.notifications == null) {
						reason = 'User account is enabled and they\'ve logged in, but they don\'t have any licenses and they haven\'t setup notifications.';
						baseDate = new Date(user.last_login);
						baseDateType = 'last-login';
						if (!user.org_id || user.org_id === 0)  // non-org user
							maxDays = dormantNoOrgUserNoKeysNoNotificationsMaxDays;
						else
							maxDays = dormantOrgUserNoKeysNoNotificationsMaxDays;
					} // if
				} // if

			} else { // disabled users
				baseDate = new Date(user.added_on);
				baseDateType = 'added';
				if (user.reset_password_expires) {
					baseDate = new Date(user.reset_password_expires);
					baseDateType = 'password-reset';
				} // if

				if (user.last_login && (new Date(user.last_login)).getTime() > baseDate.getTime()) {
					baseDate = new Date(user.last_login);
					baseDateType = 'last-login';
				} // if

				if (user.edited_on && (new Date(user.edited_on)).getTime() > baseDate.getTime()) {
					baseDate = new Date(user.edited_on);
					baseDateType = 'edited';
				} // if

				reason = 'User account is disabled.';
				maxDays = disabledUserMaxTouchedDays;
			} // if
		} // if

		if (maxDays > 0 && baseDate != null)
			cleanupDate = MiscTools.dateIntervalAdd(baseDate, maxDays, 'day');

		return { cleanupDate, reason, maxDays, baseDate, baseDateType };
	};

	// ------------------------------------------------------------------------
	static compressDisplayOptions = (displayOptions: Models.TableDisplayOptions): any => {
		if (!displayOptions) return displayOptions;

		const baseCopy: Models.TableDisplayOptions = new Models.TableDisplayOptions();
		const compressedCopy: any = MiscTools.deepClone(displayOptions);

		const baseProperties: string[] = Object.keys(displayOptions);
		for (const prop of baseProperties) {
			if (JSON.stringify(baseCopy[prop]) === JSON.stringify(displayOptions[prop])) {
				delete compressedCopy[prop];
			} // if
		} // for

		return compressedCopy;
	} // 

	// ------------------------------------------------------------------------
	static rebuildDisplayOptions = (displayOptions: any): Models.TableDisplayOptions => {
		if (!displayOptions) return displayOptions;

		const baseCopy: Models.TableDisplayOptions = new Models.TableDisplayOptions();
		const uncompressedCopy: any = MiscTools.deepClone(displayOptions);

		const baseProperties: string[] = Object.keys(baseCopy);
		const uncompressedProps: string[] = Object.keys(uncompressedCopy);
		for (const prop of baseProperties)
			if (prop !== 'filters' && !uncompressedProps.includes(prop))
				uncompressedCopy[prop] = baseCopy[prop];

		return uncompressedCopy;
	} // 
} // ValidationTools

export default ValidationTools;
