import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { SelfRegistrationService } from './self-registration.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-self-registration',
	templateUrl: './self-registration.component.html',
	styleUrls: ['./self-registration.component.scss']
})
export class SelfRegistrationComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	codeEditable = true;

	code = '';
	gclid = '';

	packageName = '';
	packageDescription = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private selfRegistrationService: SelfRegistrationService,
		private uiAlertsService: UiAlertsService
	) {

		this.route.paramMap.subscribe(params => {
			this.code = params.get('code');
			this.codeEditable = !this.code || this.code === '';
		});

		this.route.queryParams.subscribe(params => {
			if (params['gclid'] && params['gclid'] !== '')
				this.gclid = params['gclid'];
		});
	}

	ngOnInit(): void {
		this.getPackageInfo(this.code);
		this.initForm();
	}

	ngOnDestroy() {
	}

	async getPackageInfo(code: string) {
		this.packageName = '';
		this.packageDescription = '';

		if (code && code !== '') {
			const pkgInfo = await this.selfRegistrationService.getPackageInfo(code);
			if (pkgInfo) {
				this.packageName = pkgInfo.name;
				this.packageDescription = pkgInfo.description;
			}
		}
	}

	async onCodeChange() {
		if (this.theForm && this.theForm.value.code)
			this.getPackageInfo(this.theForm.value.code.trim());
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			code: new UntypedFormControl(this.code, [Validators.maxLength(120)]),
			firstname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(120)]),
			lastname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(120)]),
			email: new UntypedFormControl(null, [Validators.required, Validators.email, Validators.maxLength(255)]),
			phone: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
			company: new UntypedFormControl(null, [Validators.required]),
			title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),

			country: new UntypedFormControl(null, [Validators.required]),
			state: new UntypedFormControl(null),

			information: new UntypedFormControl(null),

			agree: new UntypedFormControl(null, [Validators.requiredTrue])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		let email = '';
		let firstname = '';
		let lastname = '';
		let title = '';
		let phone = '';
		let company = '';
		let code = '';

		let country = '';
		let state = '';

		let information = '';


		if (this.theForm.value.code) code = this.theForm.value.code.trim();
		if (this.theForm.value.email) email = this.theForm.value.email.trim();
		if (this.theForm.value.firstname) firstname = this.theForm.value.firstname.trim();
		if (this.theForm.value.lastname) lastname = this.theForm.value.lastname.trim();
		if (this.theForm.value.title) title = this.theForm.value.title.trim();
		if (this.theForm.value.phone) phone = this.theForm.value.phone.trim();
		if (this.theForm.value.company) company = this.theForm.value.company.trim();
		if (this.theForm.value.country) country = this.theForm.value.country.trim();
		if (this.theForm.value.state) state = this.theForm.value.state.trim();
		if (this.theForm.value.information) information = this.theForm.value.information.trim();

		if (email === '') this.errors.push('Missing E-Mail.');
		if (firstname === '') this.errors.push('Missing First Name.');
		if (lastname === '') this.errors.push('Missing Last Name.');
		if (title === '') this.errors.push('Missing Title.');
		if (phone === '') this.errors.push('Missing Phone.');
		if (company === '') this.errors.push('Missing Company.');
		if (country === '') this.errors.push('Missing Country.');

		// double check that the state selected is a US state
		if (country === 'US' && state && state !== '' && MiscTools.findIndexGeneric(AppConstants.usStates, 'code', state) === -1)
			state = '';

		// double check that the state selected is a CDN province
		if (country === 'CA' && state && state !== '' && MiscTools.findIndexGeneric(AppConstants.cdnProvinces, 'code', state) === -1)
			state = '';

		if (country === 'US' && (!state || state === ''))
			this.errors.push('Please choose a US State.');

		if (country === 'CA' && (!state || state === ''))
			this.errors.push('Please choose a Canadian Province.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		const newUser = new Models.User(0, 0, email, '', '', '', firstname + ' ' + lastname, title, '');

		newUser.settings = [];
		newUser.settings.push(new Models.UserSetting(0, 'user_reg_first_name', firstname));
		newUser.settings.push(new Models.UserSetting(0, 'user_reg_last_name', lastname));
		newUser.settings.push(new Models.UserSetting(0, 'user_reg_phone', phone));
		newUser.settings.push(new Models.UserSetting(0, 'user_reg_company', company));

		newUser.settings.push(new Models.UserSetting(0, 'user_reg_country', country));
		newUser.settings.push(new Models.UserSetting(0, 'user_reg_state', state));

		newUser.settings.push(new Models.UserSetting(0, 'user_reg_information', information));

		if (document.referrer && document.referrer !== '')
			newUser.settings.push(new Models.UserSetting(0, 'user_reg_referer', document.referrer));

		if (this.gclid !== '')
			newUser.settings.push(new Models.UserSetting(0, 'user_reg_gclid', this.gclid));

		try {
			const retUser = await this.selfRegistrationService.addOne(newUser, code);
			if (retUser) {
				this.uiAlertsService.addMsg('Your account has been created.  You\'ve been sent an e-mail with information about your account and a link to set your password.',
					'info', 'NEW_ACCOUNT', false, AppConstants.standardMessageAutoCloseTimeSecs * 6);

				this.router.navigate([AppConstants.urls.login]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	onCancel() {
		this.router.navigate(['/login']);
	}

	get firstname() { return this.theForm.get('firstname'); }
	get lastname() { return this.theForm.get('lastname'); }
	get email() { return this.theForm.get('email'); }
	get phone() { return this.theForm.get('phone'); }
	get company() { return this.theForm.get('company'); }
	get title() { return this.theForm.get('title'); }
	get country() { return this.theForm.get('country'); }
	get state() { return this.theForm.get('state'); }
	get agree() { return this.theForm.get('agree'); }

}
