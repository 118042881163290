<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.files" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Files</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Upload Files
			</li>
		</ol>
	</nav>
</div>

<div class="page-wrapper">
	<!-- <form *ngIf="theForm" [formGroup]="theForm" (ngSubmit)="uploadAll()"> -->
	<div class="cp-general-width-limit">
		<div *ngIf="showClassic" [ngStyle]="{'display': anyUploading() ? 'none' : '' }" class="form-group">
			<label for="filesToUpload">Files:</label>
			<input type="file" id="filesToUpload" class="form-control" multiple (change)="classicUpload($event)"
				class="ms-1 p-2" />
		</div>

		<ngx-dropzone *ngIf="!showClassic" (change)="dragDropUpload($event)" [maxFileSize]="appConstants.maxFileSize"
			[disabled]="anyUploading()">
			<ngx-dropzone-label>
				Drop files here or click to browse<br />
				<span *ngIf="files.length > 0">Click the upload button beside each file to upload</span>
			</ngx-dropzone-label>
		</ngx-dropzone>

		<div class="text-center">Maximum file size: {{ appConstants.maxFileSize | bytes: "1.0-0" }}</div>

		<div class="form-group mt-2">
			<button class="btn btn-primary me-2" type="button" (click)="uploadAll()"
				[disabled]="files.length == 0 || !anyNotComplete()">
				<fa-icon [icon]="['fas', 'file-upload']" [spin]="anyUploading()"></fa-icon> Upload All
			</button>
			<button [disabled]="anyUploading()" class="btn btn-outline-secondary" type="button" (click)="finishUp()">
				Finished
			</button>
		</div>

		<div *ngIf="!!files && files.length > 0">
			<div *ngFor="let file of files; let i = index">
				<hr />
				<button [disabled]="file.complete || anyUploading()" (click)="removeItem(i)" type="button"
					class="btn btn-outline-danger btn-sm float-end">
					<fa-icon [icon]="['fas', 'times']" ngbTooltip="Remove file from upload list." placement="bottom">
					</fa-icon>
				</button>

				<button *ngIf="!file.complete" [disabled]="file.uploading" (click)="!uploadFile(i)" type="button"
					class="btn btn-outline-primary btn-sm me-1">
					<fa-icon [icon]="['fas', 'file-upload']" [spin]="file.uploading"></fa-icon> Upload
				</button>

				<button *ngIf="file.complete" [disabled]="true" type="button"
					class="btn btn-outline-success btn-sm me-1">
					<fa-icon [icon]="['fas', 'check']"></fa-icon> Complete
				</button>

				<span class="fw-bold">{{ file.obj.name }}</span>
				- <span title="{{file.obj.size | number }} bytes">{{ +file.obj.size | bytes: "1.0-0" }}</span>
				<div *ngIf="file.note !== ''" class="cp-warning-message cp-pre-text p-1 m-1">
					{{ file.note }}
				</div>

				<div *ngIf="file.uploading" class="mt-1">
					<ngb-progressbar type="primary" [value]="file.progress" [showValue]="true"></ngb-progressbar>
				</div>

				<div *ngIf="file.complete" class="mt-1">
					<ngb-progressbar type="success" [value]="file.progress" [showValue]="true"></ngb-progressbar>
				</div>
			</div>
		</div>
	</div>
	<!-- </form> -->
</div>