<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.platforms" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Platforms</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ platform.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="page-wrapper">
	<div *ngIf="!!platform" class="cp-property-list">
		<div class="cp-property-block">
			<span class="cp-property-label">Name:</span>
			<span class="cp-property-value">{{ platform.name }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Type:</span>
			<span class="cp-property-value">{{ appConstants.platformTypeLabels[platform.ptype] }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Filename Keyword (for auto-builds):</span>
			<span class="cp-property-value">{{ platform.filename_keyword }}</span>
		</div>

		<div class="cp-property-block" *ngIf="!!platform.information && platform.information !== ''">
			<span class="cp-property-label">Information:</span>
			<span class="cp-property-value cp-pre-text">{{ platform.information }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Added:</span>
			<span class="cp-property-value">
				{{ platform.added_on | date: appConstants.pageDateTimeFmt }}
				<span *ngIf="addedByUser"> by
					<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
						popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
						{{ addedByUser.name }}</a>
					<ng-template #userPopupContent>
						<div class="cp-popover-banner">{{ addedByUser.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
					</ng-template>
				</span>
			</span>
		</div>

		<div class="cp-property-block" *ngIf="platform.added_on !== platform.edited_on">
			<span class="cp-property-label">Edited:</span>
			<span class="cp-property-value">
				{{ platform.edited_on | date: appConstants.pageDateTimeFmt }}
				<span *ngIf="editedByUser"> by
					<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
						popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
						{{ editedByUser.name }}</a>
					<ng-template #userPopupContent>
						<div class="cp-popover-banner">{{ editedByUser.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
					</ng-template>
				</span>
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Products</span>
			<ul class="mb-0">
				<li *ngFor="let item of products">
					<a [routerLink]="[getProductsURL(), item.id]">{{ item.name }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Platform</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Platform, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control"
							required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Platform
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>