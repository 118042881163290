<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="../..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Reports</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				Channels
			</li>

		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
		</div>
	</div>
</div>

<div class="list-wrapper">
	<form [formGroup]="theForm" class="cp-general-width-limit mx-auto">
		<div class="form-group">
			<div class="input-group">
				<input type="text" id="searchTerm" formControlName="searchTerm" class="form-control" placeholder="Search Term" minlength="2"
					size="10" (keyup.enter)="onFormChange()" />
				<button class="btn btn-primary" type="button" [disabled]="!theForm.valid || loading" (click)="onFormChange()">
					<fa-icon [icon]="['fas', 'search']"></fa-icon>
					<span class="cp-action-button-text">Search</span>
				</button>
			</div>
		</div>
	</form>

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!loading && searchTerm === ''" class="cp-warning-message mx-auto">
		You must supply a search term.
	</div>

	<div *ngIf="!loading && searchTerm !== '' && itemsToShow.length === 0" class="cp-warning-message mx-auto">
		No search results.
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table cp-auto-width mx-auto">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [routerLink]="getSiteRoute(item)" class="cp-pointer">
				<td>
					<a [routerLink]="getSiteRoute(item)">{{ item['__prefix'] }}</a>
				</td>
				<td>{{ item.name }}</td>
				<td>{{ item.channel_type }}</td>
			</tr>
		</tbody>
	</table>
</div>