import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';

@Component({
	selector: 'app-licensing-property-list',
	templateUrl: './licensing-property-list.component.html',
	styleUrls: ['./licensing-property-list.component.scss']
})
export class LicensingPropertyListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	properties: Models.LicenseProperty[] = [];
	products: Models.LicenseProduct[] = [];
	productProperties: Models.LicenseProductProperty[] = [];

	canManage = false;

	constructor(
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-license-key-setup'));
			this.getData();
			// this.loadTables();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData() {
		this.properties = await this.licensingAdminService.getProperties();
		this.products = await this.licensingAdminService.getProducts();

		this.productProperties = await this.licensingAdminService.getProductProperties();

		this.loading = false;
	}

	getPropertiesByType(type: string) {
		const sublist: Models.LicenseProperty[] = [];
		for (const prop of this.properties)
			if (prop.ptype === type) sublist.push(prop);
		return sublist;
	}

	getProductsForProperty(propId: number): string {
		const prods: string[] = [];
		for (const pp of this.productProperties) {
			if (pp.property_id === propId) {
				if (pp.product)
					prods.push(pp.product.label);
			} // if
		} // for

		return prods.join(', ');
	}

	niceBoolean(value: number): string {
		if (value === 0)
			return 'No';
		else if (value === 1)
			return 'Yes';
		else
			return '???-' + value;
	}

	openPropertiesReport() {
		const url = AppConstants.apiUrl + AppConstants.apiUrls.licensingadmin + '/license-properties/report';
		window.open(url, '_blank');
	}


}
