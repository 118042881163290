<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.documents" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Documents</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page" [title]="product.name + ' - ' + document.label">
				{{ product.name + ' - ' + document.label }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="showDownloadPageLink" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + appConstants.urls.mydocuments, document.id]"
					ngbTooltip="View the document in the same way a non-staff user sees it." placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					User View
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canSendNotifications && document.is_enabled === 1" type="button"
					class="btn btn-outline-primary" [routerLink]="['notify']" ngbTooltip="Notify"
					placement="bottom-end">
					<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
				</button>

				<button *ngIf="canEdit && document.is_enabled === 1" type="button" class="btn btn-outline-warning"
					(click)="disable()" ngbTooltip="Disabling a document will hide it from download lists"
					placement="bottom-end">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && document.is_enabled === 0" type="button" class="btn btn-outline-success"
					(click)="enable()" ngbTooltip="Enable" placement="bottom-end">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="document.file_id && document.file_id !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log">
				<fa-icon [icon]="['fas', 'download']" size="sm"></fa-icon>
				<!-- Downloads -->
			</button>
		</li>

		<li *ngIf="document.link && document.link !== ''" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-link-log-tab" aria-controls="nav-link-log" data-bs-target="#nav-link-log">
				<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
				<!-- Clicks -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-journal-tab" aria-controls="nav-journal" data-bs-target="#nav-journal">
				<!-- Journal -->
				<fa-icon [icon]="appConstants.sectionIcons.journals" size="sm"></fa-icon>
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-admin-log-tab" aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<div *ngIf="!!document" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="document.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled - downloads
						are available
					</span>
					<span *ngIf="document.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Inactive - downloads are
						NOT available
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Visibility:</span>
					<span *ngIf="document.is_private === 1" class="badge bg-warning">
						<fa-icon [icon]="['fas', 'lock']"></fa-icon>
						This is a private/limited document
					</span>
					<span *ngIf="document.is_private === 0" class="badge bg-success">
						<fa-icon [icon]="['fas', 'globe']"></fa-icon>This Document is generally
						available to Organizations with access to the Product.
					</span>
				</div>

				<div *ngIf="document.can_open === 1" class="cp-property-block">
					<span class="cp-property-label">Can Open:</span>
					<span class="cp-property-value">
						Yes - users will be shown an open button along with a download button
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Product:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.products, product.id]">{{ product.name }}</a>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Label:</span>
					<span class="cp-property-value">{{ document.label }}</span>
				</div>

				<div class="cp-property-block" *ngIf="file">
					<span class="cp-property-label">File:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.files, file.id]" [ngbPopover]="filePopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ file.name }}
						</a>
						({{ file.size | bytes: "1.0-0" }})
						<ng-template #filePopupContent>
							<div class="cp-popover-banner">{{ file.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getFilePopoverLines(file).join('\n')}}</div>
						</ng-template>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!!document.link && document.link !== ''">
					<span class="cp-property-label">Link/URL:</span>
					<span class="cp-property-value">
						{{ document.link }}
					</span>
				</div>

				<div class="cp-property-block" *ngIf="document.last_download">
					<span class="cp-property-label">Last Download:</span>
					<span class="cp-property-value">
						{{ document.last_download | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ document.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="document.added_on !== document.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ document.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>


				<div class="cp-property-block" *ngIf="!!organizations && organizations.length !== 0">
					<span class="cp-property-label">Organizations with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let org of organizations" class="cp-property-value">
							<fa-icon *ngIf="org.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Organization is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.organizations, org.id]"
								[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ org.name }}</a>
							<ng-template #organizationPopupContent>
								<div class="cp-popover-banner">{{ org.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getOrganizationPopoverLines(org).join('\n')}}</div>
							</ng-template>

						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!!groups && groups.length !== 0">
					<span class="cp-property-label">Organization Groups with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let group of groups" class="cp-property-value">
							<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.organizationgroups, group.id]">
								{{ group.name }}</a>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!!packages && packages.length !== 0">
					<span class="cp-property-label">Packages with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let pkg of packages" class="cp-property-value">
							<fa-icon *ngIf="pkg.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Package is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.packages, pkg.id]">{{ pkg.name }}</a>
						</li>
					</ul>
				</div>
			</div>

		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">

			<div *ngIf="!loading && downloadLogs.length === 0" class="cp-light-message">
				No download logs for files that are part of this Document.
			</div>

			<app-downloads-table #downloadsTable1></app-downloads-table>

		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!document && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Document.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-link-log" role="tabpanel" aria-labelledby="nav-link-log-tab">

			<div *ngIf="!loading && linkLogs.length === 0" class="cp-light-message">
				No logs for this Document's link.
			</div>

			<app-clicks-table #clicksTable1></app-clicks-table>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this Organization.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + appConstants.urls.adminlogs, 'journal', 'document', id]">
				<fa-icon [icon]="appConstants.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>
	</div>

</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Document</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Document, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control"
							required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Document
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>