<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.partneradmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Partners</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ partnership.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Partnership
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="is_listed" formControlName="is_listed" />
			<label class="form-check-label" for="is_listed">Listed in the Partners Directory</label>
		</div>

		<div *ngIf="organizations.length !== 0" class="form-group">
			<label for="org_id">Organization*</label>
			<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="org_id" formControlName="org_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" minlength="1" />
		</div>

		<div class="form-group">
			<label for="information">Information/Features</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"></textarea>
		</div>

		<div class="form-group">
			<label for="sdk_features">Zixi Features</label>
			<textarea id="sdk_features" formControlName="sdk_features" class="form-control" rows="4"></textarea>
		</div>

		<div class="form-group">
			<label for="link">Product/Company Link</label>
			<input type="text" id="link" formControlName="link" class="form-control" />
		</div>

		<div class="form-group">
			<label for="link">More Info Link</label>
			<input type="text" id="document_link" formControlName="document_link" class="form-control" />
		</div>

		<div class="form-group">
			<label for="version">Version</label>
			<input type="text" id="version" formControlName="version" class="form-control" />
		</div>

		<div class="form-group">
			<label for="build_version">Build</label>
			<input type="text" id="build_version" formControlName="build_version" class="form-control" />
		</div>

		<div class="form-group">
			<button class="btn btn-outline-primary btn-sm" type="button" (click)="toggleListMode()">
				Toggle List/Text Mode
			</button>
		</div>

		<div class="form-group">
			<label for="status">Status</label>
			<ng-select *ngIf="listMode" [items]="statuses" id="status" formControlName="status" [multiple]="false">
			</ng-select>
			<input *ngIf="!listMode" type="text" id="status" formControlName="status" class="form-control" />
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="zm_certified" formControlName="zm_certified" />
			<label class="form-check-label" for="zm_certified">ZEN Master Certified.</label>
		</div>

		<div class="form-group">
			<label for="itype">SDK Type</label>
			<ng-select *ngIf="listMode" [items]="itypes" id="itype" formControlName="itype" [multiple]="false">
			</ng-select>
			<input *ngIf="!listMode" type="text" id="itype" formControlName="itype" class="form-control" />
		</div>

		<div class="form-group">
			<label for="dtype">Device/Integration/Partnership Type</label>
			<ng-select *ngIf="listMode" [items]="dtypes" id="dtype" formControlName="dtype" [multiple]="false">
			</ng-select>
			<input *ngIf="!listMode" type="text" id="dtype" formControlName="dtype" class="form-control" />
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
				[disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>