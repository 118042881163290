import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { AdminLogsService } from '../admin-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-add-journal-form',
	templateUrl: './add-journal-form.component.html',
	styleUrls: ['./add-journal-form.component.scss']
})
export class AddJournalFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	id: number;
	objectType: string;
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private adminLogsService: AdminLogsService) {

		this.route.paramMap.subscribe(params => {
			this.objectType = params.get('objecttype');
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;

				if (!AppConstants.journalObjectTypes.includes(this.objectType)) {
					this.router.navigate([AppConstants.urls.notfound]);
					return;
				}

				if (!ValidationTools.checkAccess(this.authUser, 'add-journals')) {
					this.onCancel();
					return;
				}


				this.initForm();
			}
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		// get the object name....


		this.theForm = new UntypedFormGroup({
			entry: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const entry = this.theForm.value.entry;

		if (entry.trim() === '') this.errors.push('You must supply an entry.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			await this.adminLogsService.addJournal(this.objectType, this.id, entry);
			this.onCancel();
			this.saving = false;
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		if (this.objectType === 'activation')
			this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id]);
		else if (this.objectType === 'user')
			this.router.navigate(['/' + AppConstants.urls.users, this.id]);
		else if (this.objectType === 'organization')
			this.router.navigate(['/' + AppConstants.urls.organizations, this.id]);
		else if (this.objectType === 'partnership')
			this.router.navigate(['/' + AppConstants.urls.partneradmin, this.id]);
		else if (this.objectType === 'zenmaster')
			this.router.navigate(['/' + AppConstants.urls.zencustomers, this.id]);
		else if (this.objectType === 'build')
			this.router.navigate(['/' + AppConstants.urls.builds, this.id]);
		else if (this.objectType === 'document')
			this.router.navigate(['/' + AppConstants.urls.documents, this.id]);

	}

	get entry() { return this.theForm.get('entry'); }
}

