import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { QuizzesService } from '../quizzes.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-quiz-form',
	templateUrl: './quiz-form.component.html',
	styleUrls: ['./quiz-form.component.scss']
})
export class QuizFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;

	quiz: Models.Quiz;
	errors: string[] = [];
	pageMode = 'new';
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	bulkQuestionsForm: UntypedFormGroup;
	bulkChoicesForm: UntypedFormGroup;
	bulkAnswersForm: UntypedFormGroup;

	allUserGroups: Models.UserGroup[] = [];
	staffUserSelections: any[] = [];

	currentQuestion: number = -1
	showAllQuestions: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private quizzesService: QuizzesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		this.initForm();
	}

	// ************************************************************************************
	ngOnDestroy() {
	}

	// ************************************************************************************
	async initForm() {
		if (this.id && this.id !== 0) {
			this.quiz = MiscTools.deepClone(await this.quizzesService.getOne(this.id));
			if (!this.quiz || this.quiz == null || this.quiz.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if

			if (this.router.url.endsWith("copy")) {
				this.pageMode = 'new';
				this.quiz.id = 0;
				this.quiz.status = 'private';
				this.quiz.name += ' (copy)';
			} else {
				this.pageMode = 'edit';
			}
		} else {
			this.pageMode = 'new';
			this.quiz = new Models.Quiz(0, '', '', 'private', null);
			this.quiz.config = new Models.QuizConfig();
		} // if

		this.allUserGroups = this.userGroupsService.getAll();

		const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		this.staffUserSelections = [];
		for (const user of staff) {
			if (user.is_enabled === 1 || this.quiz.config.userIds.includes(user.id)) {
				let nameToShow = user.name;
				if (user.is_enabled === 0) nameToShow += ' (disabled)';
				this.staffUserSelections.push({ id: user.id, name: nameToShow });
			} // if
		} // staff

		// set up some presets for new templates
		if (this.pageMode === 'new') {
			// for (const keyExpiryMode of this.expiryModeSelections)
			// 	this.quiz.settings.expiryModes.push(keyExpiryMode.value);

		} // if

		this.theForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.quiz.name, [Validators.required]),
			description: new UntypedFormControl(this.quiz.description),
			status: new UntypedFormControl(this.quiz.status, [Validators.required]),
			adminIds: new UntypedFormControl(this.quiz.config.adminIds),
			allStaff: new UntypedFormControl(this.quiz.config.allStaff),
			userIds: new UntypedFormControl(this.quiz.config.userIds),
			groupIds: new UntypedFormControl(this.quiz.config.groupIds),
			quizType: new UntypedFormControl(this.quiz.config.quizType),
		});

		this.bulkQuestionsForm = new UntypedFormGroup({
			answerType: new UntypedFormControl('text', [Validators.required]),
			questions: new UntypedFormControl(null, [Validators.required])
		});

		this.bulkChoicesForm = new UntypedFormGroup({
			choices: new UntypedFormControl(null, [Validators.required])
		});

		this.bulkAnswersForm = new UntypedFormGroup({
			answers: new UntypedFormControl(null, [Validators.required])
		});

		for (const question of this.quiz.config.questions) {
			this.addFormElementsForQuestion(question);
		} // for

		if (this.quiz.config.questions && this.quiz.config.questions.length > 0)
			this.currentQuestion = 0;

		this.loading = false;
	}

	// ************************************************************************************
	bulkAddQuestions() {
		let answerType: string = this.bulkQuestionsForm.value.answerType;
		if (!answerType || answerType === '') answerType = 'text';
		const questions: string = this.bulkQuestionsForm.value.questions;
		const questionsArray = questions.match(/[^\r\n]+/g);
		let added: number = 0;
		let firstOne: number = -1;
		for (const qText of questionsArray) {
			if (qText.trim() !== '') {
				const q = new Models.QuizQuestion();
				let q_id: string = '';
				let isUnique: boolean = false;
				while (!isUnique) {
					q_id = TextTools.randomString(20, '0123456789ABCDE')
					isUnique = true;
					for (const question of this.quiz.config.questions)
						if (q_id === question.id) isUnique = false;
				} // while
				q.id = q_id;
				q.answerType = answerType;
				q.text = qText;
				this.quiz.config.questions.push(q);
				this.addFormElementsForQuestion(q);
				if (firstOne === -1) firstOne = this.quiz.config.questions.length - 1;
				added++;
			} // if
		} // for

		if (firstOne !== -1) this.currentQuestion = firstOne;

		if (added > 0 && document.getElementById("closeBulkQuestionsModalButton"))
			document.getElementById("closeBulkQuestionsModalButton").click();

		if (added > 0) this.bulkQuestionsForm.controls['questions'].setValue('');
	} //

	// ************************************************************************************
	bulkAddChoices() {
		// in case no questions
		if (!this.quiz.config.questions || this.quiz.config.questions.length === 0
			|| this.currentQuestion <= 0 || this.currentQuestion >= this.quiz.config.questions.length)
			return;

		// get the current question
		const q: Models.QuizQuestion = this.quiz.config.questions[this.currentQuestion];

		const choices: string = this.bulkChoicesForm.value.choices;
		const choicesArray = choices.match(/[^\r\n]+/g);
		let added: number = 0;
		for (const cText of choicesArray) {
			if (cText.trim() !== '') {
				this.addFormElementsForChoice(q.id, q.choices.length, cText);
				q.choices.push(cText);
				added++;
			} // if
		} // for

		if (added > 0 && document.getElementById("closeBulkChoicesModalButton"))
			document.getElementById("closeBulkChoicesModalButton").click();

		if (added > 0) this.bulkChoicesForm.controls['choices'].setValue('');
	}

	// ************************************************************************************
	bulkAddAnswers() {
		// in case no questions
		if (!this.quiz.config.questions || this.quiz.config.questions.length === 0
			|| this.currentQuestion <= 0 || this.currentQuestion >= this.quiz.config.questions.length)
			return;

		// get the current question
		const q: Models.QuizQuestion = this.quiz.config.questions[this.currentQuestion];

		const answers: string = this.bulkAnswersForm.value.answers;
		const answerssArray = answers.match(/[^\r\n]+/g);
		let added: number = 0;
		for (const aText of answerssArray) {
			if (aText.trim() !== '') {
				this.addFormElementsForAnswer(q.id, q.answers.length, aText);
				q.answers.push(aText);
				added++;
			} // if


		} // for

		if (added > 0 && document.getElementById("closeBulkAnswersModalButton"))
			document.getElementById("closeBulkAnswersModalButton").click();

		if (added > 0) this.bulkAnswersForm.controls['answers'].setValue('');
	}

	// ************************************************************************************
	addNewQuestion() {
		const q = new Models.QuizQuestion();
		let q_id: string = '';
		let isUnique: boolean = false;
		while (!isUnique) {
			q_id = TextTools.randomString(20, '0123456789ABCDE')
			isUnique = true;
			for (const question of this.quiz.config.questions)
				if (q_id === question.id) isUnique = false;
		} // while
		q.id = q_id;
		q.answerType = 'text';
		this.quiz.config.questions.push(q);
		this.addFormElementsForQuestion(q);
		this.currentQuestion = this.quiz.config.questions.length - 1;
	}

	// ************************************************************************************
	addNewChoice(q: Models.QuizQuestion) {
		this.addFormElementsForChoice(q.id, q.choices.length, '');
		q.choices.push('');
	}

	// ************************************************************************************
	addNewAnswer(q: Models.QuizQuestion) {
		this.addFormElementsForAnswer(q.id, q.answers.length, '');
		q.answers.push('');
	}

	// ************************************************************************************
	addFormElementsForQuestion(question: Models.QuizQuestion) {
		this.theForm.addControl('q_answerType' + question.id, new UntypedFormControl(question.answerType, [Validators.required]));
		this.theForm.addControl('q_text' + question.id, new UntypedFormControl(question.text, [Validators.required]));
		this.theForm.addControl('q_moreInfo' + question.id, new UntypedFormControl(question.moreInfo));
		this.theForm.addControl('q_explanation' + question.id, new UntypedFormControl(question.explanation));

		let count = 0;
		for (const choice of question.choices) {
			this.addFormElementsForChoice(question.id, count, choice);
			count++;
		} // for

		count = 0;
		for (const answer of question.answers) {
			this.addFormElementsForAnswer(question.id, count, answer);
			count++;
		} // for
	}

	// ************************************************************************************
	addFormElementsForChoice(id: string, count: number, choice: string) {
		this.theForm.addControl('q_choice' + id + '_' + count, new UntypedFormControl(choice));
	}

	// ************************************************************************************
	addFormElementsForAnswer(id: string, count: number, answer: string) {
		this.theForm.addControl('q_answer' + id + '_' + count, new UntypedFormControl(answer));
	}

	// ************************************************************************************
	removeQuestion(question: Models.QuizQuestion) {
		if (confirm('Are you sure?')) {
			const idx = MiscTools.findIndexGeneric(this.quiz.config.questions, 'id', question.id);
			if (idx !== -1) {
				this.theForm.removeControl('q_answerType' + question.id);
				this.theForm.removeControl('q_text' + question.id);
				this.theForm.removeControl('q_moreInfo' + question.id);
				this.theForm.removeControl('q_explanation' + question.id);

				let count = 0;
				for (const choice of question.choices) {
					this.theForm.removeControl('q_choice' + question.id + '_' + count);
					count++;
				} // for

				count = 0;
				for (const answer of question.answers) {
					this.theForm.removeControl('q_answer' + question.id + '_' + count);
					count++;
				} // for

				this.quiz.config.questions.splice(idx, 1);

				if (this.quiz.config.questions.length === 0)
					this.currentQuestion = -1;
				else if (idx === 0)
					this.currentQuestion = -1;
				else
					this.currentQuestion = idx - 1;
			} // if
		} // if
	}

	// ************************************************************************************
	getAnswerType(question: Models.QuizQuestion) {
		const answerType: string = this.theForm.value['q_answerType' + question.id];
		return (answerType);
	} // for

	// ************************************************************************************
	captureForm() {
		this.quiz.name = this.theForm.value.name;
		this.quiz.description = this.theForm.value.description;
		this.quiz.status = this.theForm.value.status;
		this.quiz.config.adminIds = this.theForm.value.adminIds;
		this.quiz.config.allStaff = this.theForm.value.allStaff;
		this.quiz.config.userIds = this.theForm.value.userIds;
		this.quiz.config.groupIds = this.theForm.value.groupIds;
		this.quiz.config.quizType = this.theForm.value.quizType;

		for (const question of this.quiz.config.questions) {
			question.answerType = this.theForm.value['q_answerType' + question.id];
			question.text = this.theForm.value['q_text' + question.id];
			question.moreInfo = this.theForm.value['q_moreInfo' + question.id];

			let count = 0;
			for (const choice of question.choices) {
				question.choices[count] = this.theForm.value['q_choice' + question.id + '_' + count];
				count++;
			} // for

			question.explanation = this.theForm.value['q_explanation' + question.id];
			count = 0;
			for (const answer of question.answers) {
				question.answers[count] = this.theForm.value['q_answer' + question.id + '_' + count];
				count++;
			} // for
		} // for

		// console.log(this.quiz);
	}

	// ************************************************************************************
	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.captureForm();

		// TBD
		// check for name, status, at least one question

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		// if (this.theForm) {
		// 	return;
		// }

		// if not a quiz, wipe answers...
		if (this.quiz.config.quizType !== 'quiz') {
			for (const question of this.quiz.config.questions) {
				question.explanation = '';
				question.answers = [];
			} // for
		} // if

		try {
			let retQuiz: Models.Quiz;
			if (this.pageMode === 'edit')
				retQuiz = await this.quizzesService.updateOne(this.quiz);
			else
				retQuiz = await this.quizzesService.addOne(this.quiz);

			if (retQuiz) {
				this.uiAlertsService.addMsg('The quiz has been saved.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate([AppConstants.urls.quizzes + '/' + retQuiz.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			console.log(e);
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// ************************************************************************************
	onCancel() {
		if (this.pageMode === 'new')
			this.router.navigate([AppConstants.urls.quizzes]);
		else
			this.router.navigate([AppConstants.urls.quizzes + '/' + this.id]);
	}

	// ************************************************************************************
	get quizType() { return this.theForm.get('quizType'); }

}
