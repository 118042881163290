<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Organizations</a>
			</li>
			<li class="breadcrumb-item">
				{{ organization.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Salesforce Contacts
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button type="button" class="btn btn-outline-secondary" [routerLink]="['..']">
					<fa-icon [icon]="['fas', 'hand-point-left']" class="text-info"></fa-icon>
					Back
				</button>
			</div>
		</div>
	</div>

</div>

<div class="page-wrapper">

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!!organization && onboardingStatus && onboardingStatus.length !== 0" class="cp-warning-message">
		<div *ngFor="let item of onboardingStatus">
			<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
			{{ item }}
		</div>
	</div>

	<div *ngIf="!loading && contacts.length === 0" class="cp-light-message">
		There aren't any contacts associated with this Salesforce account.
	</div>


	<div *ngIf="!loading && contacts.length !== 0" class="form-group form-check">
		<form *ngIf="theForm" [formGroup]="theForm">
			<input type="checkbox" class="form-check-input" id="welcomeUser" formControlName="welcomeUser" />
			<label class="form-check-label" for="welcomeUser">Automatically send a welcome message to new users so they
				can establish their password.</label>
		</form>
	</div>

	<table class="cp-std-table cp-auto-width">

		<thead>
			<tr>
				<th>Status</th>
				<th>Name</th>
				<th>E-Mail</th>
				<th>Title</th>
			</tr>
		</thead>
		<tbody *ngIf="contacts.length != 0">
			<tr *ngFor="let contact of contacts">
				<td class="text-center text-nowrap">
					<span *ngIf="contact['import-status'] === 'no-user'">
						<button type="button" class="btn btn-outline-info btn-sm" [disabled]="importing"
							(click)="createAccount(contact)"
							ngbTooltip="Create a new portal user account for this person">
							<fa-icon *ngIf="!importing" [icon]="['fas', 'user-plus']"></fa-icon>
							<span *ngIf="importing" class="spinner-border spinner-border-sm"></span>
							Create User Account
						</button>
					</span>

					<span *ngIf="contact['import-status'] === 'imported-not-welcomed'">
						<button type="button" class="btn btn-outline-secondary btn-sm"
							(click)="sendWelcomeMessage(contact)"
							ngbTooltip="Send this person a welcome e-mail so they can setup their portal account">
							<fa-icon [icon]="['fas', 'envelope']" class=''></fa-icon> Send Welcome E-Mail
						</button>
					</span>

					<span *ngIf="contact['import-status'] === 'imported-ready'"
						ngbTooltip="This person has an account linked to this organization">
						<fa-icon [icon]="['fas', 'check-circle']"></fa-icon> Imported
					</span>

					<span *ngIf="contact['import-status'] === 'different-organization'"
						ngbTooltip="This person has an account linked to a different organization">
						<fa-icon [icon]="['fas', 'times']"></fa-icon> Linked to a Different Organization
					</span>

					<span *ngIf="contact['import-status'] === 'no-organization'">
						<button type="button" class="btn btn-outline-warning btn-sm" [disabled]="importing"
							(click)="linkAccount(contact)"
							ngbTooltip="This person has an account that isn't linked to an organization.  Link it to this organization.">
							<fa-icon *ngIf="!importing" [icon]="['fas', 'link']"></fa-icon>
							<span *ngIf="importing" class="spinner-border spinner-border-sm"></span>
							Link User Account
						</button>
					</span>
				</td>
				<td>
					<span *ngIf="!contact['existing-user']">
						{{ contact.name }}
					</span>
					<span *ngIf="contact['existing-user']">
						<a [routerLink]="['/' + appConstants.urls.users, contact['existing-user'].id]"
							[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body">
							{{ contact.name }}
						</a>
						<ng-template #userPopupContent>
							<div class="cp-popover-banner">{{ contact['existing-user'].name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getUserPopoverLines(contact['existing-user']).join('\n')}}</div>
						</ng-template>
					</span>
				</td>
				<td>{{ contact.email }}</td>
				<td>{{ contact.title }}</td>
			</tr>
		</tbody>
	</table>
</div>