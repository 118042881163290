import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import PopOverTools from 'appshared/popover-tools';

import { LicensingAdminService } from 'client/app/components/app-sections/licensing-admin/licensing-admin.service';

@Component({
	selector: 'app-key-features',
	templateUrl: './key-features.component.html',
	styleUrls: ['./key-features.component.scss']
})
export class KeyFeaturesComponent {
	appConstants = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;
	sharedLicenseTools = SharedLicenseTools;
	miscTools = MiscTools;

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	// key: Models.LPActivation = null;
	keyProperties: any = {};
	product: string = '';

	staffMode: boolean = false;
	hostMode: boolean = false;

	hasFeaturesAndLimits: boolean = false;

	keyProduct: Models.LicenseProduct;
	keyProductProperties: Models.LicenseProductProperty[];

	keyProductPropertiesToShowByType = {};
	undefinedPropNames = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private licensingAdminService: LicensingAdminService) {
	}

	// ------------------------------------------------------------------------
	async updateContent(keyProperties: any, product: string, options: any = {}) {
		this.loading = true;

		this.keyProperties = keyProperties;
		this.product = product;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('staffMode')) this.staffMode = options.staffMode;
		if (optionKeys.includes('hostMode')) this.hostMode = options.hostMode;

		this.keyProduct = await this.licensingAdminService.getProductByName(this.product);

		this.keyProductPropertiesToShowByType['boolean'] = [];
		this.keyProductPropertiesToShowByType['number'] = [];
		this.keyProductPropertiesToShowByType['other'] = [];

		const prodPropNames = [];
		if (this.keyProduct) {
			this.keyProductProperties = await this.licensingAdminService.getProductProperties(false, this.keyProduct.id);
			this.keyProductProperties.sort((a, b) => (a.property.sort_order > b.property.sort_order
				|| (a.property.sort_order === b.property.sort_order && a.property.label > b.property.label)) ? 1 : -1);

			for (const pp of this.keyProductProperties) {
				const textValue = this.stringPropValue(pp.property.name);
				const numericValue = this.numericPropValue(pp.property.name);

				if (this.staffMode) {
					if (pp.property.ptype !== 'other' || (textValue && textValue !== ''))
						this.keyProductPropertiesToShowByType[pp.property.ptype].push(pp);

					prodPropNames.push(pp.property.name);
				} else {
					const hasValue = (pp.property.ptype === 'boolean' && numericValue && numericValue !== 0)
						|| (pp.property.ptype === 'number' && ((textValue && textValue === 'unlimited') || (numericValue && numericValue !== 0)))
						|| (pp.property.ptype === 'other' && textValue && textValue !== '');

					// console.log(pp.property.name + ' su=' + pp.show_user + ' hasValue=' + hasValue + ' textValue=' + textValue + ' numericValue=' + numericValue);

					// only show it (add it to the sub-arrays), if show_user is 2 and it has a value or show_user is 3
					if (pp.show_user && ((pp.show_user === 1 && hasValue) || pp.show_user === 2)) {
						this.keyProductPropertiesToShowByType[pp.property.ptype].push(pp);
						prodPropNames.push(pp.property.name);
					} // if
				} // if
			} // for
		} // if

		const keyPropNames = Object.keys(keyProperties);
		this.undefinedPropNames = [];
		for (const prop of keyPropNames)
			if (!prodPropNames.includes(prop))
				this.undefinedPropNames.push(prop);

		if (this.staffMode)
			this.hasFeaturesAndLimits = (this.keyProductProperties && this.keyProductProperties.length > 0)
				|| (this.staffMode && this.undefinedPropNames.length > 0);
		else
			this.hasFeaturesAndLimits = prodPropNames.length > 0;

		this.loading = false;
	} // updateContent

	// *********************************************************
	numericPropValue(keyPropName: string) {
		let value = 0;
		if (this.keyProperties[keyPropName])
			value = +this.keyProperties[keyPropName];
		return value;
	}

	// *********************************************************
	stringPropValue(keyPropName: string) {
		let value = '';
		if (this.keyProperties[keyPropName])
			value = this.keyProperties[keyPropName] + '';
		return value;
	}

}
