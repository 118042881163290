<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
				<div class="card-body">
					<form *ngIf="theForm" [formGroup]="theForm" (ngSubmit)="onSubmit()">
						<div>
							<h3>Forgot Password</h3>
							<div *ngIf="saving" class="text-center">
								<app-loading-spinner></app-loading-spinner>
							</div>
							<div class="form-group">
								<label for="email">E-Mail*</label>
								<input type="email" id="email" formControlName="email" class="form-control" placeholder="E-Mail"
									minlength="1" />
							</div>

							<div class="text-center mt-2">
								<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid || saving">
									<fa-icon [icon]="['fas', 'envelope']"></fa-icon>&nbsp;Request
									Reset
								</button>
								<button [disabled]="saving" class="btn btn-outline-secondary" type="button" (click)="onCancel()">
									<fa-icon [icon]="['fas', 'times']"></fa-icon>&nbsp;Cancel
								</button>
							</div>

							<div class="form-group mb-2 small text-center">* Required</div>

							<div class="form-group mb-0 small text-center">
								<a [href]="appConstants.supportURL" target='_blank'>
									<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>