<div class="title-bar" id="top">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.mydocuments" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Documentation
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="showIsPrivate && !hidePrivate" type="button" class="btn btn-outline-secondary me-2"
				(click)="toggleHidePrivate()">
				<fa-icon icon="lock" size="sm"></fa-icon>
				<span class="cp-action-button-text">Hide Private Documents</span>
			</button>

			<button *ngIf="showIsPrivate && hidePrivate" type="button" class="btn btn-outline-secondary me-2" (click)="toggleHidePrivate()">
				<fa-icon icon="lock" size="sm"></fa-icon>
				<span class="cp-action-button-text">Show Private Documents</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="getData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div *ngIf="!loading && authUser && authUser.role === appConstants.basicUserRole" class="cp-info-message ms-2 mt-2">
	Can't find what you're looking for? Perhaps you need to subscribe to a Package?<br />
	They can be found under <a [routerLink]="['/' + appConstants.urls.mypackages]">Packages</a>.
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-by-product-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-by-product-tab'" id="nav-by-product-tab" aria-controls="nav-by-product"
				data-bs-target="#nav-by-product" (click)="saveLastTab('nav-by-product-tab')">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				By Type
			</button>
		</li>

		<li *ngIf="recentDocuments && recentDocuments.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-recent-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-recent-tab'" id="nav-recent-tab" aria-controls="nav-recent"
				data-bs-target="#nav-recent" (click)="saveLastTab('nav-recent-tab')">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				New Documents
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-table-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-table-tab'" id="nav-table-tab" aria-controls="nav-table"
				data-bs-target="#nav-table" (click)="saveLastTab('nav-table-tab')">
				<fa-icon [icon]="['fas', 'table']" size="sm"></fa-icon>
				Table
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-by-product-tab'}" id="nav-by-product" role="tabpanel"
			aria-labelledby="nav-by-product-tab">

			<div *ngIf="!loading && items.length === 0" class="cp-warning-message">
				Nothing available.
			</div>

			<div class="cp-width-limit-1000">
				<div *ngIf="!loading && items && items.length !== 0 && products && products.length > 1">
					<!-- <span *ngFor="let product of products">
						<button type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
							(click)="scrollToSpot('prod_' + product.id)" [ngbTooltip]="'Jump to ' + product.name">{{
							product.name}}</button>
					</span> -->

					<button *ngFor="let product of products" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
						[ngClass]="{'btn-primary': byProductFilter === product.id, 'btn-outline-primary': byProductFilter !== product.id }"
						(click)="setByProductFilter(product.id)">
						{{ product.name }}
					</button>

					<hr />
				</div>

				<div *ngFor="let product of products" id="recent_prod_{{ product.id }}" class="mb-3"
					[hidden]="byProductFilter !== 0 && byProductFilter !== product.id">
					<div class="text-center h2">
						{{ product.name }}
						<button *ngIf="products.length > 1" type="button" class="btn btn-outline-primary btn-sm"
							(click)="scrollToSpot('top')" ngbTooltip="Back to the Top" [hidden]="byProductFilter !== 0">
							<fa-icon icon="arrow-alt-to-top" size="sm"></fa-icon>
						</button>
					</div>

					<div *ngIf="product.download_document_description && product.download_document_description !== ''"
						class="cp-pre-text border p-2 mb-2">
						{{ product.download_document_description }}
					</div>

					<div *ngFor="let item of items">
						<div *ngIf="item.document.product_id === product.id" class="card mb-2">
							<h5 class="card-header p-2">
								<div class="float-end small">
									<span class="text-muted">Added/Updated</span>
									{{ getDateToShow(item) | date: appConstants.pageDateTimeFmt }}
								</div>

								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end"></fa-icon>

								<a [routerLink]="[item.document.id]">{{ item.document.label }}</a>
							</h5>
							<div class="card-body p-2">
								<div *ngIf="item.document.file_id && item.document.file_id !== 0">
									<button type="button" class="cp-download-document-btn me-1"
										(click)="openDownload(item.document.file_id, 'document', item.document.id)">
										<fa-icon [icon]="['fas', 'file-download']"></fa-icon> Download
										({{ getFileSize(item) | bytes:'1.0-0' }})
									</button>

									<button
										*ngIf="item.document.file_id && item.document.file_id !== 0 && item.document.can_open === 1 && item.file"
										type="button" class="cp-download-document-btn me-1"
										(click)="openFile(item.document.file_id, item.file.name)">
										<fa-icon [icon]="['fas', 'file']"></fa-icon> Open
									</button>

									{{ getFileName(item) }}
								</div>
								<div *ngIf="item.document.link && item.document.link !== ''">
									<button type="button" class="cp-link-btn me-1" (click)="openLink(item)">
										<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
										Link
									</button>
									<span title="{{ item.document.link }}">{{ chopString(item.document.link) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-recent-tab'}" id="nav-recent" role="tabpanel"
			aria-labelledby="nav-recent-tab">

			<div class="cp-info-message">
				New documents added in the last {{ appConstants.dashboardRecentItemsDays }} days.<br />
				You will find all documents that you have access to under the 'By Type' and 'Table' tabs.
			</div>

			<div class="cp-width-limit-1000">
				<div *ngIf="!loading && items && items.length !== 0 && recentProducts && recentProducts.length > 1">
					<!-- <span *ngFor="let product of recentProducts">
						<button type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
							(click)="scrollToSpot('recent_prod_' + product.id)"
							[ngbTooltip]="'Jump to ' + product.name">{{
							product.name}}</button>
					</span> -->

					<button *ngFor="let product of recentProducts" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
						[ngClass]="{'btn-primary': byNewProductFilter === product.id, 'btn-outline-primary': byNewProductFilter !== product.id }"
						(click)="setByNewProductFilter(product.id)">
						{{ product.name }}
					</button>

					<hr />
				</div>

				<div *ngFor="let product of recentProducts" id="prod_{{ product.id }}" class="mb-3"
					[hidden]="byNewProductFilter !== 0 && byNewProductFilter !== product.id">
					<div class="text-center h2">
						{{ product.name }}
						<button *ngIf="recentProducts.length > 1" type="button" class="btn btn-outline-primary btn-sm"
							(click)="scrollToSpot('top')" ngbTooltip="Back to the Top" [hidden]="byNewProductFilter !== 0">
							<fa-icon icon="arrow-alt-to-top" size="sm"></fa-icon>
						</button>
					</div>

					<div *ngIf="product.download_document_description && product.download_document_description !== ''"
						class="cp-pre-text border p-2 mb-2">
						{{ product.download_document_description }}
					</div>

					<div *ngFor="let item of recentDocuments">
						<div *ngIf="item.document.product_id === product.id" class="card mb-2">
							<h5 class="card-header p-2">
								<div class="float-end small">
									<span class="text-muted">Added/Updated</span>
									{{ getDateToShow(item) | date: appConstants.pageDateTimeFmt }}
								</div>

								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end"></fa-icon>

								<a [routerLink]="[item.document.id]">{{ item.document.label }}</a>
							</h5>
							<div class="card-body p-2">
								<div *ngIf="item.document.file_id && item.document.file_id !== 0">
									<button type="button" class="cp-download-document-btn me-1"
										(click)="openDownload(item.document.file_id, 'document', item.document.id)">
										<fa-icon [icon]="['fas', 'file-download']"></fa-icon> Download
										({{ getFileSize(item) | bytes:'1.0-0' }})
									</button>

									<button
										*ngIf="item.document.file_id && item.document.file_id !== 0 && item.document.can_open === 1 && item.file"
										type="button" class="cp-download-document-btn me-1"
										(click)="openFile(item.document.file_id, item.file.name)">
										<fa-icon [icon]="['fas', 'file']"></fa-icon> Open
									</button>

									{{ getFileName(item) }}
								</div>
								<div *ngIf="item.document.link && item.document.link !== ''">
									<button type="button" class="cp-link-btn me-1" (click)="openLink(item)">
										<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
										Link
									</button>
									<span title="{{ item.document.link }}">{{ chopString(item.document.link) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-table-tab'}" id="nav-table" role="tabpanel"
			aria-labelledby="nav-table-tab">

			<div *ngIf="!loading && (!items || items.length === 0)" class="cp-warning-message">
				Nothing available.
			</div>

			<div class="container">
				<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

				<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
					<thead>
						<tr>
							<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
								[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
								[hidden]="!fieldsToShow.includes(cd.field)">
								<span *ngIf="cd.label" [innerHTML]="cd.label"
									[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
									[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
								<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
							</th>
						</tr>
					</thead>

					<tbody *ngIf="itemsToShow && !loading">
						<tr *ngFor="let item of itemsToShow">
							<td><a [routerLink]="[item.document.id]">{{ item.document.prod_name }}</a></td>
							<td>
								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.document.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end"></fa-icon>
								{{ item.document.label }}
							</td>
							<td>
								<button *ngIf="item.document.file_id && item.document.file_id !== 0" type="button"
									class="cp-download-document-btn"
									(click)="openDownload(item.document.file_id, 'document', item.document.id)">
									<fa-icon [icon]="['fas', 'file-download']"></fa-icon> Download
									<!-- ({{ getFileSize(item.file_id) | bytes:'1.0-0' }}) -->
								</button>

								<button
									*ngIf="item.document.file_id && item.document.file_id !== 0 && item.document.can_open === 1 && item.file"
									type="button" class="cp-download-document-btn me-1"
									(click)="openFile(item.document.file_id, item.file.name)">
									<fa-icon [icon]="['fas', 'file']"></fa-icon> Open
								</button>

								<button *ngIf="item.document.link && item.document.link !== ''" type="button" class="cp-link-btn"
									(click)="openLink(item)">
									<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
									Link
								</button>
							</td>
							<td class="text-nowrap">
								{{ item['__updated'] | date: appConstants.tableDateFmt }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>