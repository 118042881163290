import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { MyQuizzesService } from '../my-quizzes.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-take-quiz',
	templateUrl: './take-quiz.component.html',
	styleUrls: ['./take-quiz.component.scss']
})
export class TakeQuizComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	miscTools = MiscTools;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	quiz: Models.Quiz;
	private userSubscription: Subscription;
	theForm: UntypedFormGroup;

	loading = true;
	canManageThisQuiz = false;

	currentQuestion: number = -1;

	pageMode: string = 'full'; // abbrev
	showAllQuestions: boolean = false;

	answerCount: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myQuizzesService: MyQuizzesService) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.loadData();
		});
	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		try {
			this.quiz = MiscTools.deepClone(await this.myQuizzesService.getOne(this.id));
		} catch (e) {
			this.router.navigate([AppConstants.urls.myquizzes]);
			return;
		}
		if (!this.quiz || this.quiz == null || this.quiz.id === 0) {
			this.router.navigate([AppConstants.urls.myquizzes]);
			return;
		} // if

		this.canManageThisQuiz = ValidationTools.checkAccess(this.authUser, 'manage-quizzes')
			|| this.quiz.added_by === this.authUser.id
			|| (this.quiz.config.adminIds && this.quiz.config.adminIds.includes(this.authUser.id));

		if (!this.canManageThisQuiz && this.quiz.status !== 'active') {
			this.router.navigate([AppConstants.urls.myquizzes, this.id]);
			return;
		} // if

		// get this person's answers (if not admin, should be the only ones there...)
		let response: Models.QuizResponse = null;
		const idx = MiscTools.findIndexGeneric(this.quiz.responses, 'user_id', this.authUser.id);
		if (idx !== -1) response = this.quiz.responses[idx];

		const formControls: any = {};
		for (const question of this.quiz.config.questions) {
			if (question.answerType === 'multi-choice-multi') {
				let answers: string[] = [];
				if (response && response.answers && response.answers[question.id])
					answers = response.answers[question.id];

				let counter: number = 0;
				for (const c of question.choices) {
					formControls['answer' + question.id + '_' + counter] = new UntypedFormControl(answers.includes(c));
					counter++;
				} // for

			} else {
				let answer: string = null;
				if (response && response.answers && response.answers[question.id])
					answer = response.answers[question.id];
				formControls['answer' + question.id] = new UntypedFormControl(answer)
			} // if
		} // for

		this.theForm = new UntypedFormGroup(formControls);

		if (this.quiz.config.questions.length > 0)
			this.currentQuestion = 0;

		this.loading = false;
	}

	// ************************************************************************************
	async saveResponses() {
		const quizAnswers = new Models.QuizResponse(0, 0, '', {});
		quizAnswers.quiz_id = this.quiz.id;
		quizAnswers.user_id = this.authUser.id;

		let newAnswerCount: number = 0;
		for (const question of this.quiz.config.questions) {
			if (question.answerType === 'multi-choice-multi') {
				const answers: string[] = [];
				let counter: number = 0;
				for (const c of question.choices) {
					if (this.theForm.value['answer' + question.id + '_' + counter]) answers.push(c);;
					counter++;
				} // for
				if (answers.length > 0) newAnswerCount++;
				quizAnswers.answers[question.id] = answers;
			} else {
				const answer: string = this.theForm.value['answer' + question.id];
				if (answer && answer !== '') newAnswerCount++;
				quizAnswers.answers[question.id] = answer;
			} // if
		} // for

		this.answerCount = newAnswerCount;
		try {
			await this.myQuizzesService.setAnswers(quizAnswers);
		} catch (e) {
			console.log(e);
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// ************************************************************************************
	async shiftToQuestion(amt: number) {
		await this.saveResponses()
		if ((this.currentQuestion + amt) >= 0 && (this.currentQuestion + amt) < this.quiz.config.questions.length)
			this.currentQuestion = this.currentQuestion + amt;
	}

	// ************************************************************************************
	async jumpToQuestion(num: number) {
		await this.saveResponses()
		this.currentQuestion = num;
	}

	// ************************************************************************************
	async finished() {
		await this.saveResponses();
		this.router.navigate([AppConstants.urls.myquizzes, this.id]);
	}

	// ************************************************************************************
	getButtonClass(qNum: number): string {
		let className: string = '';

		if (this.currentQuestion === qNum)
			className = 'btn-primary';
		else
			className = 'btn-outline-primary';

		return className;
	}

}
