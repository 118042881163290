import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { MyKeysService } from '../my-keys.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-add-hostids-form',
	templateUrl: './add-hostids-form.component.html',
	styleUrls: ['./add-hostids-form.component.scss']
})
export class AddHostidsFormComponent implements OnInit {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;

	key: string;
	hostids: Models.UserHostId[] = [];

	user: Models.User;

	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private uiAlertsService: UiAlertsService
	) {

		this.route.paramMap.subscribe(params => {
			this.key = params.get('key');
		});


	}

	ngOnInit(): void {
		this.setup();
	}

	async setup() {
		this.loading = false;
		this.hostids = await this.myKeysService.getUserHostids(true);

		this.theForm = new UntypedFormGroup({
			pairsList: new UntypedFormControl('', [Validators.required]),
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const pairsList = this.theForm.value.pairsList;

		const registerErrors = [];

		let numProcessed = 0;
		let numAdded = 0;
		let numUpdated = 0;
		let numSkipped = 0;
		const array = pairsList.match(/[^\r\n]+/g);

		const warnings = [];

		for (const pair of array) {
			if (pair.trim() !== '') {
				numProcessed++;

				let justHostID = '';
				let justLabel = '';
				if (pair.includes(':')) {
					justHostID = TextTools.getLabel(pair.trim()); // everything before the :
					justLabel = TextTools.getValue(pair.trim()); // everything after the :
				} else if (pair.includes(' ')) {
					justHostID = TextTools.getLabel(pair.trim(), ' '); // everything before the space
					justLabel = TextTools.getValue(pair.trim(), ' '); // everything after the space
				} // if

				if (justHostID === '' || justLabel === '') {
					warnings.push('Skipping line "' + pair.trim() + '" need a host ID and label.');
				} else {
					const idx = MiscTools.findIndexGeneric(this.hostids, 'hostid', justHostID);
					try {
						if (idx !== -1) {
							numUpdated++;
						} else {
							numAdded++;
						}

						await this.myKeysService.registerHostid(justHostID, justLabel);

					} catch (e) {
						registerErrors.push(e.message);
					}

				}

			} // if
		} // for

		if (warnings.length !== 0)
			this.uiAlertsService.addMsg(warnings.join('\n'), 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (registerErrors.length > 0)
			this.uiAlertsService.addMsg(registerErrors.join('\n'), 'error');

		if (numAdded === 1)
			this.uiAlertsService.addMsg('Registered one host ID.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numAdded > 1)
			this.uiAlertsService.addMsg('Registered ' + numAdded + ' host IDs.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (numUpdated === 1)
			this.uiAlertsService.addMsg('Updated label for one host ID.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numUpdated > 1)
			this.uiAlertsService.addMsg('Updated label for ' + numUpdated + ' host IDs.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.saving = false;
		if (this.errors.length === 0 && (numAdded > 0 || numUpdated > 0)) {
			await this.myKeysService.getUserHostids(true);
			this.onCancel();
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.mykeys, this.key]);
	}

	// get keyList() { return this.theForm.get('keyList'); }
}
