<div class="title-bar" id="top">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.mybuilds" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Software
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="showIsPrivate && !hidePrivate" type="button" class="btn btn-outline-secondary me-2"
				(click)="toggleHidePrivate()">
				<fa-icon icon="lock" size="sm"></fa-icon>
				<span class="cp-action-button-text">Hide Private Builds</span>
			</button>

			<button *ngIf="showIsPrivate && hidePrivate" type="button" class="btn btn-outline-secondary me-2" (click)="toggleHidePrivate()">
				<fa-icon icon="lock" size="sm"></fa-icon>
				<span class="cp-action-button-text">Show Private Builds</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="getData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div *ngIf="!loading && authUser && authUser.role === appConstants.basicUserRole" class="cp-info-message ms-2 mt-2">
	Can't find what you're looking for? Perhaps you need to subscribe to a Package?<br />
	They can be found under <a [routerLink]="['/' + appConstants.urls.mypackages]">Packages</a>.
</div>

<div class="tab-container">

	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-by-product-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-by-product-tab'" id="nav-by-product-tab" aria-controls="nav-by-product"
				data-bs-target="#nav-by-product" (click)="saveLastTab('nav-by-product-tab')">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				By Type/Product
			</button>
		</li>

		<li *ngIf="recentBuilds && recentBuilds.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-recent-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-recent-tab'" id="nav-recent-tab" aria-controls="nav-recent"
				data-bs-target="#nav-recent" (click)="saveLastTab('nav-recent-tab')">
				<fa-icon [icon]="['fas', 'list']" size="sm"></fa-icon>
				New Builds
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" [ngClass]="{'active': lastTab === 'nav-table-tab'}" data-bs-toggle="tab"
				role="tab" [attr.aria-selected]="lastTab === 'nav-table-tab'" id="nav-table-tab" aria-controls="nav-table"
				data-bs-target="#nav-table" (click)="saveLastTab('nav-table-tab')">
				<fa-icon [icon]="['fas', 'table']" size="sm"></fa-icon>
				Table
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-by-product-tab'}" id="nav-by-product" role="tabpanel"
			aria-labelledby="nav-by-product-tab">

			<div *ngIf="!loading && items.length === 0" class="cp-warning-message">
				Nothing available.
			</div>

			<div class="cp-width-limit-1000">
				<div *ngIf="!loading && items && items.length !== 0 && products && products.length > 1">
					<!-- <span *ngFor="let product of products">
						<button type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
							(click)="scrollToSpot('recent_prod_' + product.id)"
							[ngbTooltip]="'Jump to ' + product.name">
							{{product.name }}
						</button>
					</span> -->

					<button *ngFor="let product of products" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
						[ngClass]="{'btn-primary': byProductFilter === product.id, 'btn-outline-primary': byProductFilter !== product.id }"
						(click)="setByProductFilter(product.id)">
						{{ product.name }}
					</button>
					<hr />
				</div>

				<div *ngFor="let product of products" id="recent_prod_{{ product.id }}" class="mb-3"
					[hidden]="byProductFilter !== 0 && byProductFilter !== product.id">
					<div class="text-center h2">
						{{ product.name }}
						<button *ngIf="products.length > 1" type="button" class="btn btn-outline-primary btn-sm"
							(click)="scrollToSpot('top')" ngbTooltip="Back to the Top" [hidden]="byProductFilter !== 0">
							<fa-icon icon="arrow-alt-to-top" size="sm"></fa-icon>
						</button>
					</div>

					<div *ngIf="product.download_build_description && product.download_build_description !== ''"
						class="cp-pre-text border p-2 mb-2">
						{{ product.download_build_description }}
					</div>

					<div *ngFor="let item of items">
						<div *ngIf="item.build.product_id === product.id" [routerLink]="[item.build.id]" class="card mb-2 cp-pointer">
							<h5 class="card-header p-2">
								<div class="float-end small">
									<span class="text-muted">Added</span> {{ item.build.added_on | date:
									appConstants.pageDateTimeFmt }}
								</div>

								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end"></fa-icon>

								<a [routerLink]="[item.build.id]">V. {{ item.build.version }}
									<span *ngIf="item.build.label && item.build.label !== ''">({{ item.build.label
										}})</span>
								</a>

								<fa-icon *ngIf="item.build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info ms-1"
									ngbTooltip="Archived Build" placement="end"></fa-icon>
							</h5>
							<div class="card-body p-2">
								<p *ngIf="item.build.release_notes !== ''" class="cp-inline-release-notes-short">
									{{ item.build.release_notes }}
								</p>
								<div *ngIf="item.build.release_notes_file_id !== 0">
									<fa-icon [icon]="['fas', 'file-download']"></fa-icon>
									Release Notes (File)
								</div>

								<div
									*ngFor="let pl of miscTools.sortPlatforms(item.platforms, getPlatformIDsFromPlatformFiles(item.build.platform_files))">
									<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
									{{ pl.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-recent-tab'}" id="nav-recent" role="tabpanel"
			aria-labelledby="nav-recent-tab">

			<div class="cp-info-message">
				New builds added in the last {{ appConstants.dashboardRecentItemsDays }} days.<br />
				You will find all builds that you have access to under the 'By Type' and 'Table' tabs.
			</div>

			<div class="cp-width-limit-1000">
				<div *ngIf="!loading && items && items.length !== 0 && recentProducts && recentProducts.length > 1">
					<!-- <span *ngFor="let product of recentProducts">
						<button type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
							(click)="scrollToSpot('prod_' + product.id)" [ngbTooltip]="'Jump to ' + product.name">{{
							product.name}}</button>
					</span>
 -->
					<button *ngFor="let product of recentProducts" type="button" class="btn btn-outline-primary btn-sm mb-1 me-1"
						[ngClass]="{'btn-primary': byNewProductFilter === product.id, 'btn-outline-primary': byNewProductFilter !== product.id }"
						(click)="setByNewProductFilter(product.id)">
						{{ product.name }}
					</button>
					<hr />
				</div>

				<div *ngFor="let product of recentProducts" id="prod_{{ product.id }}" class="mb-3"
					[hidden]="byNewProductFilter !== 0 && byNewProductFilter !== product.id">
					<div class="text-center h2">
						{{ product.name }}
						<button *ngIf="recentProducts.length > 1" type="button" class="btn btn-outline-primary btn-sm"
							(click)="scrollToSpot('top')" ngbTooltip="Back to the Top" [hidden]="byNewProductFilter !== 0">
							<fa-icon icon="arrow-alt-to-top" size="sm"></fa-icon>
						</button>
					</div>

					<div *ngIf="product.download_build_description && product.download_build_description !== ''"
						class="cp-pre-text border p-2 mb-2">
						{{ product.download_build_description }}
					</div>

					<div *ngFor="let item of recentBuilds">
						<div *ngIf="item.build.product_id === product.id" [routerLink]="[item.build.id]" class="card mb-2 cp-pointer">
							<h5 class="card-header p-2">
								<div class="float-end small">
									<span class="text-muted">Added</span> {{ item.build.added_on | date:
									appConstants.pageDateTimeFmt }}
								</div>
								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end"></fa-icon>

								<a [routerLink]="[item.build.id]">V. {{ item.build.version }}
									<span *ngIf="item.build.label && item.build.label !== ''">
										({{ item.build.label }})
									</span>
								</a>

								<fa-icon *ngIf="item.build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info ms-1"
									ngbTooltip="Archived Build" placement="end"></fa-icon>
							</h5>
							<div class="card-body p-2">
								<p *ngIf="item.build.release_notes !== ''" class="cp-inline-release-notes-short">
									{{ item.build.release_notes }}
								</p>
								<div *ngIf="item.build.release_notes_file_id !== 0">
									<fa-icon [icon]="['fas', 'file-download']"></fa-icon>
									Release Notes (File)
								</div>

								<div
									*ngFor="let pl of miscTools.sortPlatforms(item.platforms, getPlatformIDsFromPlatformFiles(item.build.platform_files))">
									<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
									{{ pl.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" [ngClass]="{'show active': lastTab === 'nav-table-tab'}" id="nav-table" role="tabpanel"
			aria-labelledby="nav-table-tab">

			<div *ngIf="!loading && (!items || items.length === 0)" class="cp-warning-message">
				Nothing available.
			</div>

			<div class="container">
				<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

				<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
					<thead>
						<tr>
							<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
								[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
								[hidden]="!fieldsToShow.includes(cd.field)">
								<span *ngIf="cd.label" [innerHTML]="cd.label"
									[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
									[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
								<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
							</th>
						</tr>
					</thead>

					<tbody *ngIf="itemsToShow && !loading">
						<tr *ngFor="let item of itemsToShow" [routerLink]="[item.build.id]" class="cp-pointer">
							<td>{{ item.build.prod_name }}</td>
							<td>
								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 1" [icon]="['fas', 'lock']"
									class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
								<fa-icon *ngIf="showIsPrivate && item.build.is_private === 0" [icon]="['fas', 'globe']"
									class="text-success me-1" ngbTooltip="Public - Generally Available" placement="end">
								</fa-icon>
								<a [routerLink]="[item.build.id]">{{ item.build.version }}</a>
								<span *ngIf="item.build.label && item.build.label !== ''">({{ item.build.label
									}})</span>
								<fa-icon *ngIf="item.build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info ms-1"
									ngbTooltip="Archived Build" placement="end"></fa-icon>
							</td>
							<td>
								<span
									*ngFor="let pl of miscTools.sortPlatforms(item.platforms, getPlatformIDsFromPlatformFiles(item.build.platform_files))">
									<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
									{{ pl.name }}
								</span>
							</td>
							<td class="text-nowrap">
								{{ item.build.added_on | date: appConstants.tableDateFmt }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>