<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Properties</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Sort Properties
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-boolean-tab" aria-controls="nav-boolean" data-bs-target="#nav-boolean">
				Boolean
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-number-tab" aria-controls="nav-number" data-bs-target="#nav-number">
				Number
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-other-tab" aria-controls="nav-other" data-bs-target="#nav-other">
				Other
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-boolean" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!loading" cdkDropList (cdkDropListDropped)="drop('boolean', $event)"
				class="cp-general-width-limit">
				<div *ngFor="let property of propertiesByType['boolean']" cdkDrag class="mb-1 p-2 border">
					<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon> {{ property.label }}
					({{ property.name }})
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-number" role="tabpanel" aria-labelledby="nav-number-tab">
			<div *ngIf="!loading" cdkDropList (cdkDropListDropped)="drop('number', $event)"
				class="cp-general-width-limit">
				<div *ngFor="let property of propertiesByType['number']" cdkDrag class="mb-1 p-2 border">
					<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon> {{ property.label }}
					({{ property.name }})
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
			<div *ngIf="!loading" cdkDropList (cdkDropListDropped)="drop('other', $event)"
				class="cp-general-width-limit">
				<div *ngFor="let property of propertiesByType['other']" cdkDrag class="mb-1 p-2 border">
					<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon> {{ property.label }}
					({{ property.name }})
				</div>
			</div>
		</div>
	</div>
	<div class="cp-form-padding"></div>

	<!-- @extend .fixed-bottom, .py-2, .text-start, .bg-transparent;
	z-index: 30;
	padding-left: 180px -->


	<div *ngIf="!loading" class="cp-form-buttons">
		<div class="mt-1 py-1 bg-body">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>
</div>