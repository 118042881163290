import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { MyKeysService } from '../my-keys.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-add-license-keys-form',
	templateUrl: './add-license-keys-form.component.html',
	styleUrls: ['./add-license-keys-form.component.scss']
})
export class AddLicenseKeysFormComponent implements OnInit {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;

	preloaded: string = '';

	user: Models.User;
	userKeys: Models.UserLicenseKey[] = [];

	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private uiAlertsService: UiAlertsService
	) {

		this.route.paramMap.subscribe(params => {
			if (params.get('keys')) {
				const keysParam = params.get('keys');
				if (keysParam && keysParam !== '')
					this.preloaded = keysParam.split(',').join('\n');
			}
			this.loadData();
		});


	}

	ngOnInit(): void {
		this.initForm();
	}

	async loadData() {
		this.user = await this.mySettingsService.getUser();
		this.userKeys = await this.myKeysService.getUserKeys();
	}

	async initForm() {
		this.loading = false;

		this.theForm = new UntypedFormGroup({
			keyList: new UntypedFormControl(this.preloaded, [Validators.required]),
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const keys = this.theForm.value.keyList;

		const registerErrors = [];

		let numProcessed = 0;
		let numAdded = 0;
		let numUpdated = 0;
		let numSkipped = 0;
		const array = keys.match(/[^\r\n]+/g);

		const warnings = [];

		for (const key of array) {
			if (key.trim() !== '') {
				if (numProcessed >= AppConstants.maxKeysToRegisterPerSubmit) {
					numSkipped++;
				} else {
					numProcessed++;

					let justKey = '';
					let justLabel = '';
					if (key.includes(':')) {
						justKey = TextTools.getLabel(key.trim()); // everything before the :
						justLabel = TextTools.getValue(key.trim()); // everything after the :
					} else if (key.includes(' ')) {
						justKey = TextTools.getLabel(key.trim(), ' '); // everything before the space
						justLabel = TextTools.getValue(key.trim(), ' '); // everything after the space
					} else {
						justKey = key.trim();
					}

					try {
						const idx = MiscTools.findIndexGeneric(this.userKeys, 'activation_key', justKey);
						if (idx !== -1) {
							const theKey = this.userKeys[idx];
							if (justLabel === '' || theKey.label === justLabel) {
								if (justLabel === '')
									warnings.push(justKey + ' has already been registered.');
								else
									warnings.push(justKey + ' has already been registered with that label.');
							} else {
								numUpdated++;
								theKey.label = justLabel;
								await this.myKeysService.updateKey(theKey, false);
							}

						} else {
							// console.log('try to add ' + key.trim());
							const userKeyObj = await this.myKeysService.registerKey(justKey, justLabel, false);
							this.userKeys.push(userKeyObj);
							numAdded++;
						}

					} catch (e) {
						registerErrors.push(e.message);
					}
				}
			}
		}

		if (warnings.length !== 0)
			this.uiAlertsService.addMsg(warnings.join('\n'), 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (registerErrors.length > 0)
			this.uiAlertsService.addMsg(registerErrors.join('\n'), 'error');

		if (numAdded === 1)
			this.uiAlertsService.addMsg('Registered one key.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numAdded > 1)
			this.uiAlertsService.addMsg('Registered ' + numAdded + ' keys.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (numUpdated === 1)
			this.uiAlertsService.addMsg('Updated label for one key.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numUpdated > 1)
			this.uiAlertsService.addMsg('Updated label for ' + numUpdated + ' keys.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (numSkipped === 1)
			this.uiAlertsService.addMsg('Ignoring one key. Maximum # of keys reached.', 'warning');
		else if (numSkipped > 1)
			this.uiAlertsService.addMsg('Ignoring ' + numSkipped + ' keys. Maximum # of keys reached.', 'warning');

		this.saving = false;
		if (this.errors.length === 0 && (numAdded > 0 || numUpdated > 0)) {
			await this.myKeysService.getUserKeys(false, true);
			await this.myKeysService.getUserKeys(true, true);
			this.onCancel();
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.mykeys], { relativeTo: this.route });
	}

	get keyList() { return this.theForm.get('keyList'); }
}
