<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.quizzes" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Quizzes + Polls
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary" (click)="loadData()" [disabled]="loading">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!loading && quizzesToShow.length === 0" class="cp-warning-message">
		<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm"></fa-icon>
		There aren't any quizzes or polls for you to see.
	</div>

	<ul *ngIf="quizzesToShow && quizzesToShow.length !== 0">
		<li *ngFor="let item of quizzesToShow">
			<a [routerLink]="[item.id]">{{ item.name }}</a>
			({{ miscTools.fetchLabel(appConstants.quizStatusSelections, item.status, 'value', 'shortLabel') }}
			/ {{ miscTools.fetchLabel(appConstants.quizTypeSelections, item.config.quizType, 'value', 'shortLabel') }})
		</li>
	</ul>
</div>