import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { DocumentsService } from '../documents.service';
import { AuthService } from 'client/app/services/auth.service';

import { DocumentsTableComponent } from 'client/app/components/shared/documents-table/documents-table.component';

@Component({
	selector: 'app-document-list',
	templateUrl: './document-list.component.html',
	styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	now = new Date();

	@ViewChild('documentsTable1') documentsTable1: DocumentsTableComponent = null;

	// 'standard' list stuff
	private userSubscription: Subscription;

	items: Models.Document[] = [];

	loading = true;
	refreshing = false;

	canAdd = false;

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-documents-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__has_url',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Link',
			icon: 'external-link-square-alt'
		}, {
			field: '__has_file',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'File',
			icon: 'book'
		}, {
			field: 'is_private',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Access',
			icon: 'lock'
		}, {
			field: 'prod_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: 'label',
			type: 'baseText',
			sortType: 'text',
			label: 'Label',
			toolTip: null,
			icon: null
		}, {
			field: '__num_orgs',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Organizations with Direct Access',
			icon: AppConstants.sectionIcons.organizations
		}, {
			field: '__num_org_groups',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Groups with Direct Access',
			icon: AppConstants.sectionIcons.files
		}, {
			field: '__num_packages',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Packages with Direct Access',
			icon: AppConstants.sectionIcons.packages
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_download',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since last download',
			icon: 'file-download'
		}
	];

	constructor(
		private documentsService: DocumentsService,
		private authService: AuthService
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-documents');
			this.items = this.documentsService.getAll();

			this.setupItems();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async refresh() {
		this.refreshing = true;
		try {
			await this.documentsService.refreshAll().toPromise();
			this.items = this.documentsService.getAll();
			this.setupItems();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}

	// ------------------------------------------------------------------------
	async setupItems() {
		for (const item of this.items) {
			item['__has_url'] = item.link && item.link !== '' ? 1 : 0;
			item['__has_file'] = item.file_id !== 0 ? 1 : 0;
			item['__num_orgs'] = item.org_ids.length;
			item['__num_org_groups'] = item.group_ids.length;
			item['__num_packages'] = item.package_ids.length;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
			if (item.last_download)
				item['__days_since_download'] = MiscTools.diffDays(this.now, item.last_download, true);
			else
				item['__days_since_download'] = 99999999;
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.documentsTable1) this.documentsTable1.updateContent(this.items, 'cp-documents-list', { showProduct: true });
	}
}

