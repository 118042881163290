<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Organizations
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Groups</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ group.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Group
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-products-platforms-tab" aria-controls="nav-products-platforms"
				data-bs-target="#nav-products-platforms">
				Products &amp; Platforms
				<span class="cp-tab-badge">
					{{ counter | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">
					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
						<label class="form-check-label" for="is_enabled">This group is enabled/active (its member
							Organizations can download files granted by membership).</label>
					</div>

					<div class="form-group">
						<label for="name">Name*</label>
						<input type="text" id="name" formControlName="name" class="form-control" />
					</div>

					<div class="form-group">
						<label for="information">Information</label>
						<textarea id="information" formControlName="information" class="form-control"
							rows="4"></textarea>
					</div>

					<div *ngIf="canSetManagers" class="form-group">
						<label for="manager_ids">Managers</label>
						<ng-select [items]="staffUserSelections" bindValue="id" bindLabel="name" id="manager_ids"
							formControlName="manager_ids" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="form-group">
						<label for="org_ids">Organizations</label>
						<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="org_ids"
							formControlName="org_ids" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

				</div>
			</div>

			<div class="tab-pane" id="nav-products-platforms" role="tabpanel"
				aria-labelledby="nav-products-platforms-tab">
				<ul class="list-unstyled">
					<li *ngFor="let productType of appConstants.productTypes" class="mb-3"
						[ngClass]="{'font-italic': availableProductTypes.indexOf(productType) === -1}">
						<strong><input [disabled]="availableProductTypes.indexOf(productType) === -1" type="checkbox"
								[id]="'ptype_' + productType" (click)="checkAllForProductType($event, productType)"
								class="align-middle mb-1 me-1" />
							<label [for]="'ptype_' + productType" class="mb-0">{{
								appConstants.productTypeLabels[productType] }}
								Products</label></strong>
						<ul class="list-unstyled ms-3">
							<li *ngFor="let product of subListOfProducts(productType)" class="">
								<input [disabled]="availableProductTypes.indexOf(productType) === -1" type="checkbox"
									[id]="'p_' + product.id" (click)="checkAllForProduct($event, product.id)"
									class="align-middle mb-1 me-1" />
								<label [for]="'p_' + product.id" class="mb-0">{{product.name}}</label>
								<ul class="list-inline ms-3">
									<li *ngFor="let platform of subListOfPlatforms(product.id)"
										class="list-inline-item">
										<label class="form-check-label ps-2 pe-2" [for]="product.id + '_' + platform.id"
											[ngClass]="{'cp-selected-primary': isChecked(product.id,platform.id)}">
											<input type="checkbox" [id]="product.id + '_' + platform.id"
												[formControlName]="'pp_' + product.id + '_' + platform.id"
												class="align-middle mb-1" (change)="adjustCounter($event)" />
											{{ platform.name }}
										</label>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>

		</div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
					[disabled]="!theForm.valid || saving">
					<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>
	</form>
</div>