import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { MySettingsService } from '../my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-my-settings-form',
	templateUrl: './my-settings-form.component.html',
	styleUrls: ['./my-settings-form.component.scss']
})
export class MySettingsFormComponent implements OnInit {
	appConstants = AppConstants;

	// private userSubscription: Subscription;
	user: Models.User;
	loading = true;
	saving = false;
	errors: string[] = [];
	theForm: UntypedFormGroup;

	showFavorite = false;
	starPref = 'star';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private mySettingsService: MySettingsService) { }

	ngOnInit(): void {
		this.initForm();
	}

	async initForm() {
		this.user = await this.mySettingsService.getUser();
		this.starPref = TextTools.getUserPropValue(this.user, AppConstants.starIconKey).trim();
		this.showFavorite = ValidationTools.checkRole(this.user.role, AppConstants.staffUserRole);

		this.loading = false;

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.user.name, [Validators.required]), // , Validators.maxLength(255)
			title: new UntypedFormControl(this.user.title, [])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		// this.user.name = this.theForm.value.name;
		// this.user.title = this.theForm.value.title;
		const name = this.theForm.value.name;
		const title = this.theForm.value.title;
		const settings = [];
		if (this.showFavorite)
			settings.push(new Models.UserSetting(0, AppConstants.starIconKey, this.starPref));

		try {
			const retUser = await this.mySettingsService.updateSettings(name, title, settings);
			if (retUser) {
				this.router.navigate(['..'], { relativeTo: this.route });
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	selectFavorite(newFav) {
		this.starPref = newFav;
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get name() { return this.theForm.get('name'); }

}
