// tslint:disable: no-string-literal
import * as yaml from 'js-yaml';

import * as Models from './shared-models';
import AppConstants from './app-constants';

import MiscTools from './misc-tools';
import TextTools from './text-tools';
import SharedLicenseTools from './shared-license-tools';
import LicenseValidationTools from './license-validation-tools';

class PopOverTools {

	// ************************************************************************************************
	static getKeyPopoverLines = (activation: Models.LPActivation, keyProducts: Models.LicenseProduct[],
		organizations: Models.Organization[], users: Models.User[], forBasic: boolean, protocolSets: Models.ProtocolSet[] = []): string[] => {
		const lines: string[] = [];

		if (!activation) return [];

		if (!forBasic) {
			let createdByName = '';
			if (activation.zcp_created_by && +activation.zcp_created_by > 0 && users && users.length > 0) {
				const idx = MiscTools.findIndex(users, +activation.zcp_created_by);
				if (idx !== -1)
					createdByName = ' by ' + users[idx].name;
			} // if
			lines.push('Created: ' + TextTools.formatDateNiceUTC(activation.created_at) + createdByName);

			if (activation.zcp_updated_at != null && activation.zcp_updated_at !== activation.created_at) {
				let updatedByName = '';
				if (activation.zcp_updated_by && +activation.zcp_updated_by > 0 && users && users.length > 0) {
					const idx = MiscTools.findIndex(users, +activation.zcp_updated_by);
					if (idx !== -1)
						updatedByName = ' by ' + users[idx].name;
				} // if
				lines.push('Edited: ' + TextTools.formatDateNiceUTC(activation.zcp_updated_at) + updatedByName);
			} // if
		} // if

		const expiryMode = SharedLicenseTools.getExpiryMode(activation);
		const keyExpiry = SharedLicenseTools.getKeyExpiration(activation, '', true);

		let keyWarnings: string[] = [];
		if (forBasic)
			keyWarnings = LicenseValidationTools.getKeyWarnings(activation, 'user');
		else
			keyWarnings = LicenseValidationTools.getKeyWarnings(activation, 'staff-all');

		if (keyWarnings.length > 0) {
			lines.push('Warnings:');
			for (const warning of keyWarnings)
				lines.push('-' + warning);
		} // if

		if (!activation.parsedParameters)
			activation.parsedParameters = SharedLicenseTools.parseRubyHash(activation.parameters);

		const enforcedMeters = !(activation.parsedParameters.dont_enforce_meters && +activation.parsedParameters.dont_enforce_meters === 1);

		if (expiryMode === 'meter')
			lines.push('Expiry: ' + TextTools.formatDateNiceUTC(keyExpiry) + ' - from meter(s)');
		else if (expiryMode === 'duration')
			lines.push('Duration: ' + SharedLicenseTools.niceKeyDuration(activation.duration));
		else if (expiryMode === 'date')
			lines.push('Expiry: ' + TextTools.formatDateNiceUTC(keyExpiry));
		else if (expiryMode === 'never')
			lines.push('Expiry: never');

		// enabled

		if (!SharedLicenseTools.isSpecialKey(activation)) {
			if (activation.enabled === 1)
				lines.push('Enabled: Yes');
			else
				lines.push('Enabled: No');
		} // if

		if (!forBasic) {
			if (activation.org_name)
				lines.push('Organization: ' + activation.org_name);
			else
				lines.push('Organization: not linked to an organization');

			if (activation.zcp_org_id && +activation.zcp_org_id !== 0 && organizations) {
				const idx = MiscTools.findIndex(organizations, +activation.zcp_org_id);
				if (idx !== -1) {
					if (organizations[idx].salesforce_account_owner && organizations[idx].salesforce_account_owner !== '')
						lines.push('Account Owner: ' + organizations[idx].salesforce_account_owner);

					if (organizations[idx].salesforce_se && organizations[idx].salesforce_se !== '')
						lines.push('SE/Tech-Rep: ' + organizations[idx].salesforce_se);
				} // if
			} // if

			lines.push(AppConstants.keyInfoFieldLabel + ': ' + activation.info);

			if (activation.ext_label && activation.ext_label !== '')
				lines.push('Label (External): ' + activation.ext_label);
		} else {
			if (activation.ext_label && activation.ext_label !== '')
				lines.push('Label: ' + activation.ext_label);
		} // if

		let keyProduct: Models.LicenseProduct = null;
		if (keyProducts) {
			const idx = MiscTools.findIndexGeneric(keyProducts, 'name', activation.product);
			if (idx !== -1) keyProduct = keyProducts[idx];
		} // if

		let niceProduct = TextTools.capitalizeFirstLetter(activation.product);
		if (keyProduct)
			if (forBasic)
				niceProduct = keyProduct.basic_label;
			else
				niceProduct = keyProduct.label;

		lines.push('Product: ' + niceProduct);

		lines.push('Type: ' + TextTools.capitalizeFirstLetter(activation.type));
		lines.push('Activations: ' + TextTools.formatNumber(activation.count) + ' of ' + TextTools.formatNumber(activation.max));
		if (activation.discard_licenses === 1)
			lines.push('Activated licenses for this key are deleted ' + AppConstants.maxStaleLicenseDays
				+ ' days after creation or last update.')

		if (activation.billingCodes && activation.billingCodes.length !== 0) {
			const listToShow: string[] = [];
			for (const bc of activation.billingCodes)
				listToShow.push(bc.billing_code);
			lines.push('Billing Codes Reported: ' + listToShow.join(', '));
		} // if

		if (!forBasic) {
			if (activation.commercial_type && activation.commercial_type !== '') {
				const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', activation.commercial_type);
				if (cIdx !== -1)
					lines.push('Commercial Type: ' + (cIdx + 1) + '. ' + AppConstants.keyCommercialTypes[cIdx].short_label);
				else
					lines.push('Commercial Type: ' + activation.commercial_type);
			} else {
				lines.push('Commercial Type: NOT SET');
			} // if

			if (activation.commercial_info && activation.commercial_info !== '') {
				lines.push('Commercial Notes:')
				const noteLines = activation.commercial_info.trim().split('\n');
				const cleanNoteLines = [];
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						cleanNoteLines.push(noteLine);

				for (let i = 0; i < 8 && i < cleanNoteLines.length; i++)
					lines.push(cleanNoteLines[i]);
				if (cleanNoteLines.length > 8) lines.push('...');
				// lines.push(activation.notes);
			} // if

			if (activation.protocolSets && activation.protocolSets.length !== 0) {

				lines.push('# Linked Protocol Sets: ' + activation.protocolSets.length);

			} // if

			if (activation.marketplace != null && activation.marketplace.marketplace !== ''
				&& activation.marketplace.accountIdentifier && activation.marketplace.accountIdentifier !== ''
				&& activation.marketplace.productIdentifiers && activation.marketplace.productIdentifiers.length > 0) {

				let marketplaceProducts: string[] = [];
				let lastMarketplaceUsageReported: Date = null;
				const marketplaceAccountIdentifier = activation.marketplace.accountIdentifier;
				let marketplaceLabel = activation.marketplace.marketplace;
				let accountIdLabel = 'ID';
				let productSelections: any[] = [];
				const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', activation.marketplace.marketplace);
				if (idx1 !== -1) {
					marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + activation.marketplace.marketplace + ')';
					productSelections = AppConstants.marketPlaceSelections[idx1].selections;
					accountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
				} // if

				for (const prodCode of activation.marketplace.productIdentifiers) {
					const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
					if (idx === -1)
						marketplaceProducts.push('Unknown product code (' + prodCode + ')');
					else
						marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
				} // for

				if (activation.marketplace.latestReport != null)
					lastMarketplaceUsageReported = new Date(activation.marketplace.latestReport);
				if (lastMarketplaceUsageReported != null && isNaN(lastMarketplaceUsageReported.getTime())) lastMarketplaceUsageReported = null;

				lines.push('Marketplace: ' + marketplaceLabel);
				lines.push(accountIdLabel + ': ' + marketplaceAccountIdentifier);
				lines.push('Marketplace Product(s): ' + marketplaceProducts.join(', '));
				if (lastMarketplaceUsageReported)
					lines.push('Marketplace Last Reported: ' + TextTools.formatDateNiceUTC(lastMarketplaceUsageReported));
			} // if
		} // if

		let showMeters = !forBasic;
		if (!showMeters && keyProduct.show_meters && keyProduct.show_meters === 2)
			showMeters = true;

		let showProtocols = !forBasic;
		// if (!showProtocols && keyProduct.show_protocols && keyProduct.show_protocols > 0
		// 	&& keyProduct.name.startsWith('broadcaster')
		// 	&& TextTools.getUserPropValue(this.authUser, AppConstants.showBxProtocolStatsOverride) === 'yes') {
		// 	this.protocolReady = true;
		// } // if

		if (showMeters && activation.first_meter_report && activation.last_meter_report)
			lines.push('Meter Reporting: ' + TextTools.formatDateNiceUTC(activation.first_meter_report) + ' to ' + TextTools.formatDateNiceUTC(activation.last_meter_report));

		if (showMeters && activation.first_meter_usage && activation.last_meter_usage)
			lines.push('Meter Usage: ' + TextTools.formatDateNiceUTC(activation.first_meter_usage) + ' to ' + TextTools.formatDateNiceUTC(activation.last_meter_usage));

		if (showProtocols && activation.first_protocol_report && activation.first_protocol_report)
			lines.push('Protocol Reporting: ' + TextTools.formatDateNiceUTC(activation.first_protocol_report) + ' to ' + TextTools.formatDateNiceUTC(activation.last_protocol_report));

		if (showProtocols && activation.first_protocol_usage && activation.last_protocol_usage)
			lines.push('Protocol Usage: ' + TextTools.formatDateNiceUTC(activation.first_protocol_usage) + ' to ' + TextTools.formatDateNiceUTC(activation.last_protocol_usage));

		// put info about meters...
		if (showMeters && activation.meters && activation.meters.length > 0) {
			if (enforcedMeters)
				lines.push('Meter limits are enforced.');
			else
				lines.push('Meter limits are NOT enforced.');

			const now = new Date();
			const meterSummaryInfo: any = SharedLicenseTools.buildMeterSummary(activation.meters);
			let firstOne: boolean = true;
			for (const product of AppConstants.meterProducts) {
				if (meterSummaryInfo[product + '.never'] || meterSummaryInfo[product + '.monthly']) {
					if (firstOne) {
						lines.push('Summary of Active Meters');
						firstOne = false;
					} // if

					if (meterSummaryInfo[product + '.monthly'] && +meterSummaryInfo[product + '.monthly'].limit > 0) {
						let meterExtra: string = '';
						if (+meterSummaryInfo[product + '.monthly'].count === 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.monthly'].count + ' active meter)';
						else if (+meterSummaryInfo[product + '.monthly'].count > 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.monthly'].count + ' active meters)';
						lines.push('- ' + SharedLicenseTools.meterDescription(meterSummaryInfo[product + '.monthly'], true) + meterExtra);
					} // if

					if (meterSummaryInfo[product + '.never'] && +meterSummaryInfo[product + '.never'].limit > 0) {
						let meterExtra: string = '';
						if (+meterSummaryInfo[product + '.never'].count === 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.never'].count + ' active meter)';
						else if (+meterSummaryInfo[product + '.never'].count > 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.never'].count + ' active meters)';
						lines.push('- ' + SharedLicenseTools.meterDescription(meterSummaryInfo[product + '.never'], true) + meterExtra);
					} // if
				} // if
			} // for

			// const metersByProduct: any = {};
			// for (const meter of activation.meters) {
			// 	if (!metersByProduct[meter.product]) metersByProduct[meter.product] = [];
			// 	metersByProduct[meter.product].push(meter);
			// } // for

			// const meterTypes: string[] = Object.keys(metersByProduct);
			// for (const product of meterTypes) {
			// 	lines.push('Meter Info - ' + SharedLicenseTools.niceProtocol(product, false) + ':');

			// 	const monthlyMeters: Models.LPMeter[] = [];
			// 	const neverMeters: Models.LPMeter[] = [];
			// 	for (const meter of metersByProduct[product]) {
			// 		if (meter.resets === 'monthly') monthlyMeters.push(meter);
			// 		if (meter.resets === 'never') neverMeters.push(meter);
			// 	} // for

			// 	let orderedMeters: Models.LPMeter[] = monthlyMeters;
			// 	orderedMeters = orderedMeters.concat(neverMeters);

			// 	for (const meter of orderedMeters) {
			// 		lines.push('- ' + SharedLicenseTools.meterDescription(meter, false));
			// 	} // for
			// } // for
		} // if

		if (!forBasic && activation.notes && activation.notes !== '') {
			lines.push('Notes:')
			const noteLines = activation.notes.split('\n');
			const cleanNoteLines = [];
			for (const noteLine of noteLines)
				if (noteLine.trim() !== '')
					cleanNoteLines.push(noteLine);

			for (let i = 0; i < 8 && i < cleanNoteLines.length; i++)
				lines.push(cleanNoteLines[i]);
			if (cleanNoteLines.length > 8) lines.push('...');
		} // if

		if (!forBasic) {
			if (activation.notes && activation.notes !== '') {
				lines.push('Notes (Internal):')
				const noteLines = TextTools.chopString(activation.notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						lines.push(noteLine);
			} // if
			if (activation.ext_notes && activation.ext_notes !== '') {
				lines.push('Notes (External):')
				const noteLines = TextTools.chopString(activation.ext_notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						lines.push(noteLine);
			} // if
		} else {
			if (activation.ext_notes && activation.ext_notes !== '') {
				lines.push('Notee:')
				const noteLines = TextTools.chopString(activation.ext_notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						lines.push(noteLine);
			} // if
		} // if




		// warnings
		// gaps

		return lines;
	};

	// ************************************************************************************************
	static getLicensePopoverLines = (license: Models.LPLicense): string[] => {
		if (!license) return [];

		const lines: string[] = [];

		lines.push('Issued: ' + TextTools.formatDateNiceUTC(license.created_at));
		if (license.updated_at)
			lines.push('Updated: ' + TextTools.formatDateNiceUTC(license.updated_at));

		if (!license.expires_at) {
			lines.push('Expires: Never - Permanent License');
		} else if (!MiscTools.hasExpired(license.expires_at)) {
			lines.push('Expires: ' + TextTools.formatDateNiceUTC(license.expires_at));
		} else {
			lines.push('Expired: ' + TextTools.formatDateNiceUTC(license.expires_at));
		} // if

		if (license.ip) lines.push('IP: ' + license.ip);

		const parsedParameters = SharedLicenseTools.parseRubyHash(license.parameters);

		const licenseMeterTypes: string[] = [];
		for (const meterType of AppConstants.meterProducts)
			if (parsedParameters[meterType])
				licenseMeterTypes.push(meterType);

		const meterNames = [];
		for (const licenseMeterType of licenseMeterTypes) {
			const niceName = SharedLicenseTools.niceProtocol(licenseMeterType, true);
			if (!meterNames.includes(niceName)) meterNames.push(niceName);
		} // for
		meterNames.sort();

		if (meterNames.length > 0)
			lines.push('Meter Type(s) Active in License: ' + meterNames.join('; '));

		if (license.first_meter_report && license.last_meter_report)
			lines.push('Meter Reporting: ' + TextTools.formatDateNiceUTC(license.first_meter_report) + ' to ' + TextTools.formatDateNiceUTC(license.last_meter_report));

		if (license.first_meter_usage && license.last_meter_usage)
			lines.push('Meter Usage: ' + TextTools.formatDateNiceUTC(license.first_meter_usage) + ' to ' + TextTools.formatDateNiceUTC(license.last_meter_usage));

		if (license.first_protocol_report && license.first_protocol_report)
			lines.push('Protocol Reporting: ' + TextTools.formatDateNiceUTC(license.first_protocol_report) + ' to ' + TextTools.formatDateNiceUTC(license.last_protocol_report));

		if (license.first_protocol_usage && license.last_protocol_usage)
			lines.push('Protocol Usage: ' + TextTools.formatDateNiceUTC(license.first_protocol_usage) + ' to ' + TextTools.formatDateNiceUTC(license.last_protocol_usage));

		if (license.last_protocol_bx_version && license.last_protocol_bx_version !== '')
			lines.push('Last Reported Version: ' + TextTools.cleanVersion(license.last_protocol_bx_version));

		if (license.extraProperties) {
			const niceExtras: string = SharedLicenseTools.niceLicenseExtraProperties(license.extraProperties);
			if (niceExtras && niceExtras !== '') {
				lines.push('Extra Properties');
				const splitExtras: string[] = niceExtras.split('\n');
				for (const e of splitExtras)
					lines.push(AppConstants.bullet + ' ' + e);
			} // if
		} // if


		return lines;
	};

	// ************************************************************************************************
	static getUserPopoverLines = (user: Models.User, organizations: Models.Organization[] = []): string[] => {
		if (!user) return [];

		const lines: string[] = [];
		lines.push('Added: ' + TextTools.formatDateNiceUTC(user.added_on));

		// enabled
		if (user.is_enabled === 1)
			lines.push('Enabled: Yes');
		else
			lines.push('Enabled: No');

		lines.push('Role: ' + AppConstants.userRoleLabels[user.role]);

		if (user.org_name)
			lines.push('Organization: ' + user.org_name);
		else
			lines.push('Organization: not linked to an organization');

		lines.push('E-Mail: ' + user.email);

		if (user.last_login) lines.push('Last Login: ' + TextTools.formatDateNiceUTC(user.last_login));
		if (user.last_download) lines.push('Last Download: ' + TextTools.formatDateNiceUTC(user.last_download));


		return lines;
	};

	// ************************************************************************************************
	static getOrganizationPopoverLines = (organization: Models.Organization, users: Models.User[] = []): string[] => {
		if (!organization) return [];

		const lines: string[] = [];

		let addedByName = '';
		if (organization.added_by && +organization.added_by > 0 && users && users.length > 0) {
			const idx = MiscTools.findIndex(users, +organization.added_by);
			if (idx !== -1)
				addedByName = ' by ' + users[idx].name;
		} // if
		lines.push('Added: ' + TextTools.formatDateNiceUTC(organization.added_on) + addedByName);

		if (organization.is_enabled === 1)
			lines.push('Enabled: Yes');
		else
			lines.push('Enabled: No');

		lines.push('Type: ' + AppConstants.organizationTypeLabels[organization.otype]);

		if (!!organization.salesforce_account_id && organization.salesforce_account_id !== '')
			lines.push('Salesforce Account: ' + organization.salesforce_account_id);

		if (!!organization.salesforce_account_owner && organization.salesforce_account_owner !== '')
			lines.push('Account Owner: ' + organization.salesforce_account_owner);

		if (!!organization.salesforce_se && organization.salesforce_se !== '')
			lines.push('SE/Tech-Rep: ' + organization.salesforce_se);

		if (organization.last_download)
			lines.push('Last Download: ' + TextTools.formatDateNiceUTC(organization.last_download));

		if (organization.email_domains && organization.email_domains !== '')
			lines.push('E-Mail Domain(s): ' + organization.email_domains);

		if (organization.num_users && +organization.num_users !== 0) lines.push('# Users: ' + +organization.num_users);
		if (organization.num_keys && +organization.num_keys !== 0) lines.push('# Keys: ' + +organization.num_keys);
		if (organization.num_billing_codes && +organization.num_billing_codes !== 0) lines.push('# Billing Codes: ' + +organization.num_billing_codes);
		if (organization.num_zen_domains && +organization.num_zen_domains !== 0) lines.push('# ZEN Sites: ' + +organization.num_zen_domains);
		if (organization.num_partnerships && +organization.num_partnerships !== 0) lines.push('# Partnerships: ' + +organization.num_partnerships);

		if (!!organization.information && organization.information !== '')
			lines.push('Information: ' + organization.information);

		return lines;
	};

	// ************************************************************************************************
	static getZenMasterPopoverLines = (zenCustomer: Models.ZenMasterCustomer, organizations: Models.Organization[] = []): string[] => {
		if (!zenCustomer) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(zenCustomer.created_at));

		if (zenCustomer.is_enabled === 1)
			lines.push('Enabled: Yes');
		else
			lines.push('Enabled: No');

		lines.push('Environment: ' + zenCustomer.environment);
		lines.push('Name: ' + zenCustomer.name);
		lines.push('DNS Prefix: ' + zenCustomer.dns_prefix);
		lines.push('Type: ' + zenCustomer.zcp_type);
		lines.push('Assessment: ' + zenCustomer.assessment);

		if (zenCustomer.zcp_org_id && zenCustomer.zcp_org_id !== 0) {
			const idx = MiscTools.findIndex(organizations, zenCustomer.zcp_org_id);
			if (idx !== -1) {
				lines.push('Organization: ' + organizations[idx].name);
				if (organizations[idx].salesforce_account_owner && organizations[idx].salesforce_account_owner !== '')
					lines.push('Account Owner: ' + organizations[idx].salesforce_account_owner);

				if (organizations[idx].salesforce_se && organizations[idx].salesforce_se !== '')
					lines.push('SE/Tech-Rep: ' + organizations[idx].salesforce_se);

			} // if
		} // if

		if (zenCustomer.marketplace != null && zenCustomer.marketplace.marketplace !== ''
			&& zenCustomer.marketplace.accountIdentifier && zenCustomer.marketplace.accountIdentifier !== ''
			&& zenCustomer.marketplace.productIdentifiers && zenCustomer.marketplace.productIdentifiers.length > 0) {

			let marketplaceProducts: string[] = [];
			let lastMarketplaceUsageReported: Date = null;
			const marketplaceAccountIdentifier = zenCustomer.marketplace.accountIdentifier;
			let marketplaceLabel = zenCustomer.marketplace.marketplace;
			let productSelections: any[] = [];
			let accountIdLabel = 'ID';
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', zenCustomer.marketplace.marketplace);
			if (idx1 !== -1) {
				marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + zenCustomer.marketplace.marketplace + ')';
				productSelections = AppConstants.marketPlaceSelections[idx1].selections;
				accountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
			} // if

			for (const prodCode of zenCustomer.marketplace.productIdentifiers) {
				const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
				if (idx === -1)
					marketplaceProducts.push('Unknown product code (' + prodCode + ')');
				else
					marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
			} // for

			if (zenCustomer.marketplace.latestReport != null)
				lastMarketplaceUsageReported = new Date(zenCustomer.marketplace.latestReport);
			if (lastMarketplaceUsageReported != null && isNaN(lastMarketplaceUsageReported.getTime())) lastMarketplaceUsageReported = null;

			lines.push('Marketplace: ' + marketplaceLabel);
			lines.push(accountIdLabel + ': ' + marketplaceAccountIdentifier);
			lines.push('Marketplace Product(s): ' + marketplaceProducts.join(', '));
			if (lastMarketplaceUsageReported)
				lines.push('Marketplace Last Reported: ' + TextTools.formatDateNiceUTC(lastMarketplaceUsageReported));
		} // if

		return lines;
	};

	// ************************************************************************************************
	static getBuildDownloadPopoverLines = (dBuild: Models.DownloadableBuild): string[] => {
		if (!dBuild) return [];

		const lines: string[] = [];
		lines.push('Added: ' + TextTools.formatDateNiceUTC(dBuild.build.added_on));
		lines.push('Product: ' + dBuild.product.name);
		if (dBuild.build.label && dBuild.build.label !== '')
			lines.push('Version: ' + dBuild.build.version + ' (' + dBuild.build.label + ')');
		else
			lines.push('Version: ' + dBuild.build.version);

		const platformIds = [];
		for (const pf of dBuild.build.platform_files)
			platformIds.push(pf.platform_id);

		const sortedPlatforms: Models.Platform[] = MiscTools.sortPlatforms(dBuild.platforms, platformIds)

		if (sortedPlatforms.length > 0) {
			lines.push('Platforms:');
			for (const platform of sortedPlatforms)
				lines.push(' - ' + platform.name);
		} // if

		return lines;
	};

	// ************************************************************************************************
	static getDocumentDownloadPopoverLines = (dDocument: Models.DownloadableDocument): string[] => {
		if (!dDocument) return [];

		const lines: string[] = [];
		lines.push('Added: ' + TextTools.formatDateNiceUTC(dDocument.document.added_on));
		lines.push('Product: ' + dDocument.product.name);
		lines.push('Label/Name: ' + dDocument.document.label);

		return lines;
	};

	// ************************************************************************************************
	static getPartnershipPopoverLines = (partnership: Models.Partnership, organizations: Models.Organization[] = []): string[] => {
		if (!partnership) return [];

		const lines: string[] = [];

		if (partnership.added_on) lines.push('Added: ' + TextTools.formatDateNiceUTC(partnership.added_on));
		if (partnership.edited_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(partnership.edited_on));

		if (partnership.is_listed === 1)
			lines.push('Listed in Partners Directory: Yes');
		else
			lines.push('Listed in Partners Directory: No');


		const idx = MiscTools.findIndex(organizations, partnership.org_id);
		if (idx !== -1) {
			lines.push('Organization: ' + organizations[idx].name);
		} // if

		lines.push('Name: ' + partnership.name);

		if (!!partnership.information && partnership.information !== '') lines.push('Information/Features: ' + partnership.information);

		if (partnership.extras != null) {
			if (partnership.extras.sdk_features && partnership.extras.sdk_features !== '') lines.push('SDK Features: ' + partnership.extras.sdk_features);
			if (partnership.extras.link && partnership.extras.link !== '') lines.push('Product/Company Link: ' + partnership.extras.link);
			if (partnership.extras.document_link && partnership.extras.document_link !== '') lines.push('More Info Link: ' + partnership.extras.document_link);
			if (partnership.extras.version && partnership.extras.version !== '') lines.push('Version: ' + partnership.extras.version);
			if (partnership.extras.status && partnership.extras.status !== '') lines.push('Status: ' + partnership.extras.status);
			if (partnership.extras.zm_certified && partnership.extras.zm_certified === 1) lines.push('ZEN Master Certified: yes');
			if (partnership.extras.build_version && partnership.extras.build_version !== '') lines.push('Build: ' + partnership.extras.build_version);
			if (partnership.extras.itype && partnership.extras.itype !== '') lines.push('SDK Type: ' + partnership.extras.itype);
			if (partnership.extras.dtype && partnership.extras.dtype !== '') lines.push('Partnership Type: ' + partnership.extras.dtype);
		} // if

		return lines;
	};

	// ************************************************************************************************
	static getBillingCodePopoverLines = (billingCode: Models.BillingCode): string[] => {
		if (!billingCode) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(billingCode.added_on));
		if (billingCode.edited_on && billingCode.edited_on !== billingCode.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(billingCode.edited_on));

		lines.push('Organization: ' + billingCode.org_name);
		lines.push('Name/Label: ' + billingCode.label);

		return lines;
	};

	// ************************************************************************************************
	static getProtocolSetPopoverLines = (protocolSet: Models.ProtocolSet, forStaff: boolean = true): string[] => {
		if (!protocolSet) return [];

		const lines: string[] = [];

		if (forStaff) {
			lines.push('Added: ' + TextTools.formatDateNiceUTC(protocolSet.added_on));
			if (protocolSet.edited_on && protocolSet.edited_on !== protocolSet.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(protocolSet.edited_on));
		} // if

		lines.push('Name: ' + protocolSet.name);
		if (protocolSet.description && protocolSet.description !== '')
			lines.push('Description: ' + protocolSet.description);
		// if (forStaff) lines.push('Sharing Mode: ' + protocolSet.share_mode);

		const protocols: string[] = MiscTools.deepClone(protocolSet.protocolsArr);
		protocols.sort(SharedLicenseTools.protocolSort);

		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of protocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('MediaConnect ', ''));
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('ZM ', ''));
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else if (protocol.startsWith('private_'))
					privProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else
					bxProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} // if
		} // for

		const protocolBlocks: any[] = [];
		if (bxProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Non-Private)',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Private)',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Transcode)',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - MediaConnect)',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - Non-MediaConnect)',
				protocols: zmProtocols
			});

		for (const protocolBlock of protocolBlocks) {
			lines.push(protocolBlock.label + ' [' + protocolBlock.protocols.length + ']:');
			lines.push(AppConstants.bullet + ' ' + protocolBlock.protocols.join('; '));
			// for (const p of protocolBlock.protocols)
			// 	lines.push(AppConstants.bullet + ' ' + p);
		} // for

		if (lines.length > 15) {
			lines.splice(15);
			lines.push('...');
		} // if

		return lines;
	};

	// ************************************************************************************************
	static getKeyTemplatePopoverLines = (template: Models.KeyTemplate): string[] => {
		if (!template) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(template.added_on));
		if (template.edited_on && template.edited_on !== template.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(template.edited_on));

		lines.push('Name: ' + template.name);
		lines.push('Product: ' + template.product);
		if (template.settings && template.settings.keyTypes && template.settings.keyTypes)
			lines.push('Key Type(s): ' + template.settings.keyTypes.join(', '));

		if (template.description && template.description !== '')
			lines.push('Description: ' + template.description);

		return lines;
	};

	// ************************************************************************************************
	static getKeyProductPopoverLines = (keyProduct: Models.LicenseProduct): string[] => {
		if (!keyProduct) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(keyProduct.added_on));
		if (keyProduct.edited_on && keyProduct.edited_on !== keyProduct.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(keyProduct.edited_on));

		lines.push('Name: ' + keyProduct.name);
		lines.push('Label (Staff Users): ' + keyProduct.label);
		lines.push('Label (Basic Users): ' + keyProduct.basic_label);
		if (keyProduct.description && keyProduct.description !== '')
			lines.push('Description: ' + keyProduct.description);

		if (keyProduct.show_meters >= 0 && keyProduct.show_meters < AppConstants.keyProductStatsValues.length)
			lines.push('Meter Stats: ' + AppConstants.keyProductStatsValues[keyProduct.show_meters]);

		if (keyProduct.show_protocols >= 0 && keyProduct.show_protocols < AppConstants.keyProductStatsValues.length)
			lines.push('Protocol Stats: ' + AppConstants.keyProductStatsValues[keyProduct.show_protocols]);

		if (keyProduct.meterProductsArr && keyProduct.meterProductsArr.length > 0)
			lines.push('Meter Products: ' + keyProduct.meterProductsArr.join(', '));

		lines.push('Name for License File: ' + keyProduct.license_filename);
		lines.push('Minimum Version: ' + keyProduct.min_version);
		lines.push('License Version: ' + keyProduct.fulfillment_version);
		lines.push('License Product: ' + keyProduct.fulfillment_product);

		return lines;
	};

	// ************************************************************************************************
	static getBuildPopoverLines = (build: Models.Build): string[] => {
		if (!build) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(build.added_on));
		if (build.edited_on && build.edited_on !== build.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(build.edited_on));

		if (build.last_download) lines.push('Last Download: ' + TextTools.formatDateNiceUTC(build.last_download));

		if (build.prod_name && build.prod_name !== '') lines.push('Product: ' + build.prod_name);
		if (build.version && build.version !== '') lines.push('Version: ' + build.version);
		if (build.label && build.label !== '') lines.push('Label: ' + build.label);
		if (build.information && build.information !== '') lines.push('Information: ' + build.information);
		if (build.release_notes && build.release_notes !== '') lines.push('Release Notes: ' + build.release_notes);

		return lines;
	};

	// ************************************************************************************************
	static getDocumentPopoverLines = (document: Models.Document): string[] => {
		if (!document) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(document.added_on));
		if (document.edited_on && document.edited_on !== document.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(document.edited_on));
		if (document.last_download) lines.push('Last Download: ' + TextTools.formatDateNiceUTC(document.last_download));
		if (document.prod_name && document.prod_name !== '') lines.push('Product: ' + document.prod_name);
		if (document.label && document.label !== '') lines.push('Label: ' + document.label);

		return lines;
	};

	// ************************************************************************************************
	static getFilePopoverLines = (file: Models.File): string[] => {
		if (!file) return [];

		const lines: string[] = [];

		lines.push('Added: ' + TextTools.formatDateNiceUTC(file.added_on));
		if (file.edited_on && file.edited_on !== file.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(file.edited_on));
		if (file.last_download) lines.push('Last Download: ' + TextTools.formatDateNiceUTC(file.last_download));
		if (file.downloads > 0) lines.push('# Downloads: ' + TextTools.formatCount(file.downloads));
		if (file.size > 0) lines.push('Size: ' + TextTools.formatNumber(file.size) + ' bytes');

		return lines;
	};


	// export class File {
	// 	constructor(
	// 		public id: number,
	// 		public name: string,
	// 		public location_info: string,
	// 		public size: number,
	// 		public is_deleted: number = 0,
	// 		public md5: string = '',
	// 		public free_access_token: string = '',
	// 		public disable_autobuild: number = 0,
	// 		public added_by: number = 0,
	// 		public added_on: Date = null,
	// 		public edited_by: number = 0,
	// 		public edited_on: Date = null,
	// 		public last_download: Date = null,
	// 		public downloads: number = 0,
	// 		public product: string = '',
	// 		public platform: string = '',
	// 		public version: string = '',
	// 		public uses: string[] = []
	// 	) { }
	// }

	// ************************************************************************************************
	static playSound = (fileToPlay: string, audioLevel: number) => {
		if (fileToPlay && fileToPlay !== '' && audioLevel > 0) {
			// console.log('playing ' + fileToPlay);

			let url: string = 'https://customer-portal-sounds.s3.us-east-2.amazonaws.com/' + fileToPlay;
			const audioElement = document.querySelector("audio");
			if (audioElement) {
				audioElement.src = url;
				audioElement.volume = audioLevel / 10;
				audioElement.play();
			} // if
		} // if
	};
}

export default PopOverTools;


