import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { UsersService } from '../users.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	user: Models.User;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;
	constants = AppConstants;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	roles = [];

	organizations = [];
	otypes = [];
	canManageBasicNoOrg = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private organizationsService: OrganizationsService,
		private authService: AuthService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.user = this.usersService.getOne(this.id);
				if (!this.user || this.user == null || this.user.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.usersService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.user = new Models.User(0, 0, '', '', '', AppConstants.userRoles[0], '', '', '');
				if (params.get('orgid'))
					this.user.org_id = +params.get('orgid');
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {

			// get the roles that the auth'd user is allowed to manage
			this.roles = AppConstants.manageableRoles[authUser.role];

			// if no roles...
			if (!authUser || authUser.id === this.user.id || this.roles.indexOf(this.user.role) === -1) {
				this.onCancel();
				return;
			}

			this.canManageBasicNoOrg = ValidationTools.checkAccess(authUser, 'manage-basic-users-no-org');

			this.otypes = [];
			for (const organizationType of AppConstants.organizationTypes)
				if (ValidationTools.checkAccess(authUser, 'manage-basic-users-' + organizationType))
					this.otypes.push(organizationType);

			// if they don't have manage rights to any org types and can't manage no-org users, bail
			if (this.otypes.length === 0 && !this.canManageBasicNoOrg) {
				this.onCancel();
				return;
			}

			this.loadExtras();

			if (this.editMode) {
				this.listSubscription = this.usersService.users.subscribe((users) => {
					this.user = users.find((user: Models.User) => user.id === this.id);
					this.initForm();
				});
			} else {
				this.initForm();
			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	loadExtras() {
		const allOrgs = this.organizationsService.getAll();
		this.organizations = [];
		for (const org of allOrgs)
			if (this.otypes.includes(org.otype) && (this.user.org_id === org.id || !ValidationTools.hasFlag(org, 'no_users')))
				// this.organizations.push({ id: org.id, name: org.name + ' [' + org.otype + ']' });
				this.organizations.push({
					id: org.id,
					name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.user.is_enabled),
			// use_sso: new FormControl(this.user.use_sso),
			// org_id: new FormControl(this.user.org_id),
			role: new UntypedFormControl(this.user.role, [Validators.required]),
			name: new UntypedFormControl(this.user.name, [Validators.required]), // , Validators.maxLength(255)
			title: new UntypedFormControl(this.user.title, []),
			email: new UntypedFormControl(this.user.email, [Validators.required, Validators.email]),
			information: new UntypedFormControl(this.user.information),
		});

		if (+this.user.org_id === 0) this.user.org_id = null;

		if (this.organizations.length > 0)
			if (this.canManageBasicNoOrg)
				this.theForm.addControl('org_id', new UntypedFormControl(this.user.org_id));
			else
				this.theForm.addControl('org_id', new UntypedFormControl(this.user.org_id, [Validators.required]));

		for (const flag of AppConstants.userFlags) {
			let checked = (this.user.flags && this.user.flags![flag.key]
				&& +this.user.flags[flag.key] === 1);
			this.theForm.addControl('flag_' + flag.key, new UntypedFormControl(checked));
		}

		if (!this.editMode)
			this.theForm.addControl('welcomeUser', new UntypedFormControl(true));
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.user.is_enabled = this.theForm.value.is_enabled;
		// this.user.use_sso = this.theForm.value.use_sso;
		this.user.role = this.theForm.value.role;
		if (this.organizations.length > 0)
			this.user.org_id = this.theForm.value.org_id;
		this.user.name = this.theForm.value.name;
		this.user.email = this.theForm.value.email;
		this.user.title = this.theForm.value.title;
		this.user.information = this.theForm.value.information;

		const welcomeUser = this.theForm.value.welcomeUser;

		this.user.flags = {};
		for (const flag of AppConstants.userFlags) {
			const val = this.theForm.value['flag_' + flag.key];
			if (val) this.user.flags[flag.key] = 1;
		}

		if (!this.canManageBasicNoOrg && +this.user.org_id === 0)
			this.errors.push('You must choose an Organization.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		// check the user's email address to see if it uses a bad domain...
		const domain = TextTools.getEmailDomain(this.user.email).toLowerCase();
		let badDomain = false;
		for (const fd of AppConstants.forbiddenSelfRegistrationDomains)
			if (domain === fd || domain.endsWith('.' + fd))
				badDomain = true;

		if (badDomain)
			this.uiAlertsService.addMsg('We generally discourage using personal e-mail accounts for portal accounts.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		try {
			let retUser: Models.User;
			if (this.editMode)
				retUser = await this.usersService.updateOne(this.user);
			else
				retUser = await this.usersService.addOne(this.user, 'Form', welcomeUser);

			if (retUser) {
				this.router.navigate([AppConstants.urls.users + '/' + retUser.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		if (this.editMode)
			this.router.navigate(['/' + AppConstants.urls.users, this.id], { relativeTo: this.route });
		else
			this.router.navigate(['/' + AppConstants.urls.users], { relativeTo: this.route });
	}

	get role() { return this.theForm.get('role'); }
	get name() { return this.theForm.get('name'); }
	get email() { return this.theForm.get('email'); }
}
