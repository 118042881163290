import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { MyFileSharingService } from '../my-file-sharing.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-file-sharing-list',
	templateUrl: './file-sharing-list.component.html',
	styleUrls: ['./file-sharing-list.component.scss']
})
export class FileSharingListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;
	isStaff = false;
	canAdd = false;
	canBasicSend = false;

	sendBundles: Models.Bundle[] = [];
	receiveBundles: Models.Bundle[] = [];
	reportBundles: Models.Bundle[] = [];

	focusedTab: string = 'send';

	comingSoon = false;
	comingSoonMessage = 'File sharing is coming soon.';

	sendMessage = 'When a staff member has arranged for you to send them files, you will see those transactions here.';
	receiveMessage = 'When a staff member sends you files, you will see them here.';

	sortOrder: string = 'oldest-to-newest';

	constructor(
		private myFileSharingService: MyFileSharingService,
		private mySettingsService: MySettingsService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) { }

	ngOnInit(): void {
		if (localStorage.getItem('cp-fileSharing.sortOrder'))
			this.sortOrder = localStorage.getItem('cp-fileSharing.sortOrder');

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				if (authUser) {
					this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
					if (this.isStaff)
						for (const btype of AppConstants.bundleTypes)
							if (ValidationTools.checkAccess(authUser, 'create-' + btype.value + '-bundle'))
								this.canAdd = true;
					this.getData();
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData(forceRefresh = false) {
		this.loading = true;

		this.sendBundles = [];
		this.receiveBundles = [];
		this.reportBundles = [];

		const user = await this.mySettingsService.getUser();

		if (!this.isStaff && user.org_id && user.org_id !== 0) {
			this.canBasicSend = true;
			this.sendMessage = 'When you send files or a staff member has arranged for you to send them files, you will see those transactions here.'
		} // if

		const bundles: Models.Bundle[] = await this.myFileSharingService.getUserBundles(forceRefresh);

		for (const bundle of bundles) {
			if (!bundle.deleted_at) {
				if (bundle.sub_type === 'report') {
					this.reportBundles.push(bundle);
				} else {
					if (bundle.btype.endsWith('-send')) {
						// is this user doing the sending or are they on the receiving end???
						if (bundle.user_ids.includes(user.id) || (bundle.org_id && bundle.org_id !== 0 && user.org_id && user.org_id === bundle.org_id)) {
							this.receiveBundles.push(bundle);
						} else {
							this.sendBundles.push(bundle);
						}
					} else {
						// is this user doing the receiving or are they on the sending end???
						if (bundle.user_ids.includes(user.id) || (bundle.org_id && bundle.org_id !== 0 && user.org_id && user.org_id === bundle.org_id)) {
							this.sendBundles.push(bundle);
						} else {
							this.receiveBundles.push(bundle);
						}
					} // if
				} // if
			} // if
		} // for

		this.sortBundles();

		if (this.sendBundles.length !== 0)
			this.focusedTab = 'send';
		else if (this.receiveBundles.length !== 0)
			this.focusedTab = 'receive';
		else if (this.reportBundles.length !== 0)
			this.focusedTab = 'report';

		// temp step to stop basic users from setting this up...
		if (AppConstants.filesharingStaffOnly && !ValidationTools.checkRole(user.role, AppConstants.staffUserRole)
			&& this.sendBundles.length === 0 && this.receiveBundles.length === 0) {
			this.comingSoon = true;
			this.loading = false;
			return;
		} else {
			this.comingSoon = false;
		} // if

		this.loading = false;
	}

	sortBundles() {
		if (this.sortOrder === 'oldest-to-newest') {
			this.sendBundles.sort((a, b) => (a.id > b.id) ? 1 : -1);
			this.receiveBundles.sort((a, b) => (a.id > b.id) ? 1 : -1);
			this.reportBundles.sort((a, b) => (a.id > b.id) ? 1 : -1);
		} else {
			this.sendBundles.sort((a, b) => (a.id < b.id) ? 1 : -1);
			this.receiveBundles.sort((a, b) => (a.id < b.id) ? 1 : -1);
			this.reportBundles.sort((a, b) => (a.id < b.id) ? 1 : -1);
		} // if
	}

	changeSortOrder() {
		if (this.sortOrder === 'oldest-to-newest')
			this.sortOrder = 'newest-to-oldest';
		else
			this.sortOrder = 'oldest-to-newest'
		localStorage.setItem('cp-fileSharing.sortOrder', this.sortOrder);
		this.sortBundles();
	}

	async openDownload(bundleID: number, fileID: number) {
		try {
			const dlObj = await this.myFileSharingService.getDownloadLink(bundleID, fileID);
			if (dlObj) window.open(dlObj.url, '_blank');
		} catch (error) {
			this.uiAlertsService.addMsg(error.message,
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

}
