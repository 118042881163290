<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				New ZEN Master
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="reason">Reason for Adding (Who requested it? Opportunity URL, etc.)</label>
			<textarea id="reason" formControlName="reason" class="form-control" rows="4"></textarea>
		</div>

		<div class="form-group">
			<label for="zcp_org_id">Organization*</label>
			<ng-select [items]="orgSelections" bindValue="id" bindLabel="name" id="zcp_org_id"
				formControlName="zcp_org_id" (change)="selectOrg()">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="zcp_type">Type*</label>
			<div class="form-check" *ngFor="let zcp_type of appConstants.zenMasterTypes">
				<input class="form-check-input" type="radio" formControlName="zcp_type" name="zcp_type"
					[id]="'zcp_type_' + zcp_type" [value]="zcp_type" />
				<label class="form-check-label"
					[for]="'zcp_type_' + zcp_type">{{appConstants.zenMasterTypeLabels[zcp_type]}}</label>
			</div>
		</div>

		<div class="form-group">
			<label for="name">Name*</label>
			<input type="text" id="name" formControlName="name" class="form-control" minlength="3" maxlength="100" />
		</div>

		<div class="form-group">
			<label for="dns_prefix">DNS Prefix*</label>
			<input type="text" id="dns_prefix" formControlName="dns_prefix" class="form-control" minlength="3"
				maxlength="20" />
		</div>

		<div class="form-group">
			<label for="region">Region*</label>
			<ng-select [items]="regions" bindValue="value" bindLabel="value" id="region" formControlName="region">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="org_users">Organization's Users (select admin user name for new site)</label>
			<ng-select [items]="orgUserSelections" bindValue="id" bindLabel="name" id="org_users"
				formControlName="org_users">
			</ng-select>
		</div>

		<!-- <div class="form-group">
			<label for="admin_user_name">Admin User Name*</label>
			<input type="text" id="admin_user_name" formControlName="admin_user_name" class="form-control" minlength="3"
				maxlength="255" />
		</div>

		<div class="form-group">
			<label for="admin_user_email">Admin User E-Mail*</label>
			<input type="email" id="admin_user_email" formControlName="admin_user_email" class="form-control"
				minlength="3" maxlength="255" />
		</div>

		<div class="form-group">
			<label for="admin_user_password">Admin User Password*</label>
			<div>
				<span *ngIf="showPassword">{{ randomPassword }}</span>
				<span *ngIf="!showPassword">{{ '*'.repeat(randomPassword.length) }}</span>
				<fa-icon *ngIf="!showPassword" [icon]="['fas', 'eye']" ngbTooltip="Show Password" placement="end"
					(click)="showPassword=true" class="ms-1"></fa-icon>
				<fa-icon *ngIf="showPassword" [icon]="['fas', 'eye-slash']" ngbTooltip="Show Password" placement="end"
					(click)="showPassword=false" class="ms-1"></fa-icon>
				<fa-icon [icon]="['fas', 'copy']" ngxClipboard [cbContent]="randomPassword"
					ngbTooltip="Copy to Clipboard" placement="end" class="ms-1"></fa-icon>
			</div>
		</div> -->

		<div class="form-group">
			<label for="admin_user_password">Admin User Password</label>
			<div>The password will be generated and e-mailed to the admin user.</div>
		</div>
		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="content_analysis" formControlName="content_analysis" />
			<label class="form-check-label" for="content_analysis">Enable Content Analysis</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="automation" formControlName="automation" />
			<label class="form-check-label" for="automation">Enable Automation</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="traceroute_history"
				formControlName="traceroute_history" />
			<label class="form-check-label" for="traceroute_history">Enable Traceroute History</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="live_events" formControlName="live_events" />
			<label class="form-check-label" for="live_events">Enable Live Events</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_agentz" formControlName="allow_agentz" />
			<label class="form-check-label" for="allow_agentz">Allow Agent Z</label>
		</div>

		<div class="form-group form-check">
			<input type="checkbox" class="form-check-input" id="allow_non_sso_login"
				formControlName="allow_non_sso_login" />
			<label class="form-check-label" for="allow_non_sso_login">Allow Non-SSO Login</label>
		</div>

		<div class="form-group">
			<label for="eip">Elastic IP Allocation ID</label>
			<input type="text" id="eip" formControlName="eip" class="form-control" />
		</div>
		<!-- 
		<div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div>
 -->
	</div>


	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
				[disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>