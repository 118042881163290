<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" routerLink="..">
					<fa-icon [icon]="appConstants.sectionIcons.mysettings" class="cp-user-nav-item me-1" size="sm">
					</fa-icon>
					My Settings
				</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Change Password
			</li>
		</ol>
	</nav>
</div>

<form [formGroup]="theForm" autocomplete="false" *ngIf="!!user">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="currentpassword">Current Password*</label>
			<input type="password" id="currentpassword" formControlName="currentpassword" class="form-control" />
		</div>

		<div class="form-group">
			<label for="password">Password*</label>
			<input type="password" id="password" formControlName="password" class="form-control" />
		</div>

		<div class="form-group">
			<label for="password2">Password (again)*</label>
			<input type="password" id="password2" formControlName="password2" class="form-control" />
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
				[disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>