<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				System Logs
			</li>
			<li class="breadcrumb-item active" aria-current="page">Journal Entry</li>
		</ol>
	</nav>
</div>

<form *ngIf="!loading && theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<textarea id="entry" formControlName="entry" class="form-control" rows="8"
				placeholder="Journal Entry*"></textarea>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Add Entry
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Add Entry
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>