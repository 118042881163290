<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.mynotifications" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				E-Mail Notifications
				<app-loading-spinner *ngIf="loading || saving"></app-loading-spinner>
			</li>
		</ol>
	</nav>

	<span *ngIf="showSaved" class="ms-2">Your changes have been saved.</span>
</div>

<div *ngIf="user && !loading && !user.notifications" class="cp-general-width-limit text-center mt-5">

	<div class="fw-bold mb-2">You haven't configured e-mail notifications yet.</div>

	<div class="fw-bold">E-mail notifications from this portal include:</div>

	<div class="mb-2">
		Messages about new software versions.<br />
		Messages about new documentation.<br />
		Alerts about your license keys expiring and/or reaching their meter's limit.<br />
		General Notifications (System-Wide Changes, End-of-Life Notices, etc.)
	</div>

	<button type="button" class="btn btn-primary" (click)="setToDefaults()">Configure E-Mail
		Notifications</button>
</div>

<form *ngIf="user && !loading && user.notifications && theForm" [formGroup]="theForm" autocomplete="false">
	<div class="tab-container">

		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
					id="nav-user-tab" aria-controls="nav-user" data-bs-target="#nav-user">
					<fa-icon [icon]="['fas', 'check-square']">
					</fa-icon>
					Types/Options
				</button>
			</li>

			<li class="nav-item" role="presentation">
				<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-time-tab"
					aria-controls="nav-time" data-bs-target="#nav-time">
					<fa-icon [icon]="['fas', 'clock']">
					</fa-icon>
					Timing
				</button>
			</li>

			<li class="nav-item" role="presentation">
				<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-staff-tab"
					aria-controls="nav-staff" data-bs-target="#nav-staff">
					<fa-icon [icon]="['fas', 'user-graduate']">
					</fa-icon>
					Staff Settings
				</button>
			</li>

			<app-loading-spinner *ngIf="loading"></app-loading-spinner>
		</ul>

		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" role="tabpanel" id="nav-user" aria-labelledby="nav-user-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group form-check pb-2 border-bottom">
						<input type="checkbox" class="form-check-input" id="autoSubscribe" formControlName="autoSubscribe"
							(change)="saveSettings()" />
						<label class="form-check-label" for="autoSubscribe">
							{{ appConstants.notificationPropLabels.autoSubscribe }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="autoSubscribePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #autoSubscribePopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.autoSubscribe }}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.autoSubscribe }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveGenerallMessages"
							formControlName="receiveGenerallMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveGenerallMessages">
							{{ appConstants.notificationPropLabels.receiveGenerallMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveGenerallMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveGenerallMessagesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.receiveGenerallMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveGenerallMessages }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveBuildMessages" formControlName="receiveBuildMessages"
							(change)="saveSettings()" />
						<label class="form-check-label" for="receiveBuildMessages">
							{{ appConstants.notificationPropLabels.receiveBuildMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveBuildMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="buildProductSelections.length > 0 && !showBuildFilters" [disabled]="!receiveBuildMessages.value"
							type="button" class="btn btn-outline-secondary btn-sm ms-1" (click)="showBuildFilters = true"
							placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific products to receive notifications for or to exclude specific products">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveBuildMessagesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.receiveBuildMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveBuildMessages }}</div>
					</ng-template>

					<div *ngIf="showBuildFilters && receiveBuildMessages.value && buildProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedBuildProductIDs">
							{{ appConstants.notificationPropLabels.includedBuildProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedBuildProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="buildProductSelections" bindValue="value" bindLabel="label" id="includedBuildProductIDs"
							formControlName="includedBuildProductIDs" [multiple]="true" [closeOnSelect]="false" (change)="saveSettings()"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #includedBuildProductIDsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.includedBuildProductIDs }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.includedBuildProductIDs }}</div>
					</ng-template>

					<div *ngIf="showBuildFilters && receiveBuildMessages.value && buildProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="excludedBuildProductIDs">
							{{ appConstants.notificationPropLabels.excludedBuildProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="excludedBuildProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="buildProductSelections" bindValue="value" bindLabel="label" id="excludedBuildProductIDs"
							formControlName="excludedBuildProductIDs" [multiple]="true" [closeOnSelect]="false" (change)="saveSettings()"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #excludedBuildProductIDsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.excludedBuildProductIDs }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.excludedBuildProductIDs }}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveDocumentMessages"
							formControlName="receiveDocumentMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveDocumentMessages">
							{{ appConstants.notificationPropLabels.receiveDocumentMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveDocumentMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="docProductSelections.length > 0 && !showDocumentFilters" [disabled]="!receiveDocumentMessages.value"
							type="button" class="btn btn-outline-secondary btn-sm ms-1" (click)="showDocumentFilters = true"
							placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific products to receive notifications for or to exclude specific products">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveDocumentMessagesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.receiveDocumentMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveDocumentMessages }}</div>
					</ng-template>

					<div *ngIf="showDocumentFilters && receiveDocumentMessages.value && docProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedDocumentProductIDs">
							{{ appConstants.notificationPropLabels.includedDocumentProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedDocumentProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="docProductSelections" bindValue="value" bindLabel="label" id="includedDocumentProductIDs"
							formControlName="includedDocumentProductIDs" [multiple]="true" [closeOnSelect]="false" (change)="saveSettings()"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #includedDocumentProductIDsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.includedDocumentProductIDs
							}}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.includedDocumentProductIDs }}
						</div>
					</ng-template>

					<div *ngIf="showDocumentFilters && receiveDocumentMessages.value && docProductSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="excludedDocumentProductIDs">
							{{ appConstants.notificationPropLabels.excludedDocumentProductIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="excludedDocumentProductIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="docProductSelections" bindValue="value" bindLabel="label" id="excludedDocumentProductIDs"
							formControlName="excludedDocumentProductIDs" [multiple]="true" [closeOnSelect]="false" (change)="saveSettings()"
							placeholder="Optional - otherwise get notified for all products">
						</ng-select>
					</div>

					<ng-template #excludedDocumentProductIDsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.excludedDocumentProductIDs
							}}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.excludedDocumentProductIDs }}
						</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeyExpirationMessages"
							formControlName="receiveKeyExpirationMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveKeyExpirationMessages">
							{{ appConstants.notificationPropLabels.receiveKeyExpirationMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyExpirationMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeyExpirationMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveKeyExpirationMessages
							}}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveKeyExpirationMessages }}
						</div>
					</ng-template>

					<div *ngIf="receiveKeyExpirationMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="expirySelections" bindValue="value" bindLabel="label" id="expirationDays"
							formControlName="expirationDays" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeyUsageMessages"
							formControlName="receiveKeyUsageMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveKeyUsageMessages">
							{{ appConstants.notificationPropLabels.receiveKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.receiveKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveKeyUsageMessages }}</div>
					</ng-template>

					<div *ngIf="receiveKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="usagePercentage"
							formControlName="usagePercentage" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveProjectedKeyUsageMessages"
							formControlName="receiveProjectedKeyUsageMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveProjectedKeyUsageMessages">
							{{ appConstants.notificationPropLabels.receiveProjectedKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProjectedKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveProjectedKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveProjectedKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveProjectedKeyUsageMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveProjectedKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="projectedPercentage"
							formControlName="projectedPercentage" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveProtocolKeyUsageMessages"
							formControlName="receiveProtocolKeyUsageMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveProtocolKeyUsageMessages">
							{{ appConstants.notificationPropLabels.receiveProtocolKeyUsageMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProtocolKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveProtocolKeyUsageMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveProtocolKeyUsageMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveProtocolKeyUsageMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveProtocolKeyUsageMessages.value" class="cp-compact-form-group ms-4">
						<ng-select [items]="usageSelections" bindValue="value" bindLabel="label" id="protocolPercentage"
							formControlName="protocolPercentage" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveOfflineHostIDsMessages"
							formControlName="receiveOfflineHostIDsMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveOfflineHostIDsMessages">
							{{ appConstants.notificationPropLabels.receiveOfflineHostIDsMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveOfflineHostIDsMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveOfflineHostIDsMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveOfflineHostIDsMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveOfflineHostIDsMessages }}
						</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveKeysReportMessages"
							formControlName="receiveKeysReportMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveKeysReportMessages">
							{{ appConstants.notificationPropLabels.receiveKeysReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeysReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveKeysReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveKeysReportMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveKeysReportMessages
							}}</div>
					</ng-template>

					<div *ngIf="receiveKeysReportMessages.value" class="cp-compact-form-group ms-4">
						<label class="form-check-label" for="keysReportFrequency">
							{{ appConstants.notificationPropLabels.keysReportFrequency }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="keysReportFrequencyPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<ng-select [items]="appConstants.keysReportFrequencySelections" bindValue="value" bindLabel="label"
							id="keysReportFrequency" formControlName="keysReportFrequency" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<ng-template #keysReportFrequencyPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.keysReportFrequency }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.keysReportFrequency
							}}</div>
					</ng-template>

					<!-- ************************************************ -->

					<div class="text-center border-top mt-3 pt-2">
						<button type="button" class="btn btn-primary me-1" (click)="setToDefaults()">
							<fa-icon icon="undo"></fa-icon>
							Reset to Defaults
						</button>
						<button type="button" class="btn btn-primary" (click)="unsubscribeFromAll()">
							<fa-icon icon="trash-alt"></fa-icon>
							Unsubscribe From All
						</button>
					</div>
				</div>
				<div class="cp-form-padding"></div>

			</div>

			<div class="tab-pane" role="tabpanel" id="nav-time" aria-labelledby="nav-time-tab">

				<div class="cp-general-width-limit">
					<div class="cp-compact-form-group">
						<label for="name">
							{{ appConstants.notificationPropLabels.deliveryMode }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="deliveryModePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<div class="form-check" *ngFor="let notificationDeliveryMode of appConstants.notificationDeliveryModes">
							<input class="form-check-input" type="radio" formControlName="deliveryMode" name="deliveryMode"
								[id]="'deliveryMode_' + notificationDeliveryMode.value" [value]="notificationDeliveryMode.value"
								(change)="saveSettings()" />
							<label class="form-check-label" [for]="'deliveryMode_' + notificationDeliveryMode.value">
								{{ notificationDeliveryMode.label }}
							</label>
						</div>
					</div>

					<div *ngIf="deliveryMode.value === 'window'" class="cp-compact-form-group ms-4">
						<ng-select [items]="startHourSelections" bindValue="value" bindLabel="label" id="startHour"
							formControlName="startHour" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<div *ngIf="deliveryMode.value === 'window'" class="cp-compact-form-group ms-4">
						<ng-select [items]="endHourSelections" bindValue="value" bindLabel="label" id="endHour" formControlName="endHour"
							[clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<ng-template #deliveryModePopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.deliveryMode }}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.deliveryMode }}</div>
					</ng-template>

					<div class="cp-compact-form-group">
						<label for="timezone">
							{{ appConstants.notificationPropLabels.timezone }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="timezonePopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
							<a href="https://www.iana.org/time-zones" target="_blank">
								<fa-icon [icon]="['fas', 'clock']" class="ms-1" placement="end"
									ngbTooltip="Learn more about the IANA Time Zone Database">
								</fa-icon>
							</a>
						</label>
						<ng-select [items]="tzSelections" bindValue="value" bindLabel="label" id="timezone" class="ms-4"
							formControlName="timezone" [clearable]="false" (change)="saveSettings()">
						</ng-select>
					</div>

					<ng-template #timezonePopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.timezone }}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.timezone }}</div>
					</ng-template>

					<div class="cp-compact-form-group">
						<label>
							{{ appConstants.notificationPropLabels.daysOfWeek }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="daysOfWeekPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<div *ngFor="let dow of appConstants.daysOfWeek">
							<label class="form-check-label ms-4" [for]="'dow_' + dow">
								<input type="checkbox" [id]="'dow_' + dow" [formControlName]="'dow_' + dow" class="align-middle mb-1"
									(change)="saveSettings()" />
								{{ dow }}
							</label>
						</div>

						<!-- <ul class="list-inline">
						<li *ngFor="let dow of appConstants.daysOfWeek" class="list-inline-item">
							<label class="form-check-label ps-0 pe-0" [for]="'dow_' + dow">
								<input type="checkbox" [id]="'dow_' + dow" [formControlName]="'dow_' + dow"
									class="align-middle mb-1" (change)="saveSettings()" />
								{{ dow }}
							</label>
						</li>
					</ul> -->


					</div>

					<ng-template #daysOfWeekPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.daysOfWeek }}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.daysOfWeek }}</div>
					</ng-template>
				</div>
				<div class="cp-form-padding"></div>
			</div>

			<div *ngIf="showStaffSettings" class="tab-pane" role="tabpanel" id="nav-staff" aria-labelledby="nav-staff-tab">

				<div class="cp-general-width-limit">
					<!-- skip empty -->
					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="skipEmptyReports" formControlName="skipEmptyReports"
							(change)="saveSettings()" />
						<label class="form-check-label" for="skipEmptyReports">
							{{ appConstants.notificationPropLabels.skipEmptyReports }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="skipEmptyReportsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #skipEmptyReportsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.skipEmptyReports }}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.skipEmptyReports }}</div>
					</ng-template>

					<!-- saved searches -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffKeyReportMessages"
							formControlName="receiveStaffKeyReportMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveStaffKeyReportMessages">
							{{ appConstants.notificationPropLabels.receiveStaffKeyReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffKeyReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveStaffKeyReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveStaffKeyReportMessages
							}}</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveStaffKeyReportMessages }}
						</div>
					</ng-template>

					<div *ngIf="receiveStaffKeyReportMessages.value" class="cp-compact-form-group ms-4">
						<label for="keyReportSavedSearches">
							{{ appConstants.notificationPropLabels.keyReportSavedSearches }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="keyReportSavedSearchesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<div *ngIf="savedSearchSelections.length == 0">
							To use this feature, you must create at least one saved search via <a
								[routerLink]="['/' + appConstants.urls.licensing]">Licensing / Search</a>
						</div>

						<ng-select *ngIf="savedSearchSelections.length > 0" [items]="savedSearchSelections" bindValue="value"
							bindLabel="label" id="keyReportSavedSearches" formControlName="keyReportSavedSearches" [multiple]="true"
							[closeOnSelect]="false" (change)="saveSettings()" placeholder="Select one or more saved searches">
						</ng-select>
					</div>

					<ng-template #keyReportSavedSearchesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.keyReportSavedSearches }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.keyReportSavedSearches }}</div>
					</ng-template>

					<!-- zen master reports -->

					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffZENMasterReportMessages"
							formControlName="receiveStaffZENMasterReportMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveStaffZENMasterReportMessages">
							{{ appConstants.notificationPropLabels.receiveStaffZENMasterReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffZENMasterReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<button *ngIf="!showZenTypeFilters" [disabled]="!receiveStaffZENMasterReportMessages.value" type="button"
							class="btn btn-outline-secondary btn-sm ms-1" (click)="showZenTypeFilters = true" placement="bottom-start"
							ngbTooltip="Optional - if you want to choose specific ZEN Master site types">
							<fa-icon icon="filter" size="sm"></fa-icon>
							<!-- Set Filters -->
						</button>
					</div>

					<ng-template #receiveStaffZENMasterReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveStaffZENMasterReportMessages }}
						</div>
						<div class="cp-pre-text">{{
							appConstants.notificationPropInfo.receiveStaffZENMasterReportMessages }}
						</div>
					</ng-template>

					<div *ngIf="showZenTypeFilters && receiveStaffZENMasterReportMessages.value && zenTypeSelections.length > 0"
						class="cp-compact-form-group ms-4">
						<label for="includedZenMasterTypes">
							{{ appConstants.notificationPropLabels.includedZenMasterTypes }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="includedZenMasterTypesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
						<ng-select [items]="zenTypeSelections" bindValue="value" bindLabel="label" id="includedZenMasterTypes"
							formControlName="includedZenMasterTypes" [multiple]="true" [closeOnSelect]="false" (change)="saveSettings()"
							placeholder="Optional - otherwise get notified for all ZEN Master sites">
						</ng-select>
					</div>

					<ng-template #includedZenMasterTypesPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.includedZenMasterTypes }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.includedZenMasterTypes }}</div>
					</ng-template>

					<!-- org meter reports -->
					<div class="cp-compact-form-group form-check">
						<input type="checkbox" class="form-check-input" id="receiveStaffOrgMeterReportMessages"
							formControlName="receiveStaffOrgMeterReportMessages" (change)="saveSettings()" />
						<label class="form-check-label" for="receiveStaffOrgMeterReportMessages">
							{{ appConstants.notificationPropLabels.receiveStaffOrgMeterReportMessages }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveStaffOrgMeterReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>
					</div>

					<ng-template #receiveStaffOrgMeterReportMessagesPopupContent>
						<div class="cp-popover-banner">{{
							appConstants.notificationPropLabels.receiveStaffOrgMeterReportMessages }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.receiveStaffOrgMeterReportMessages
							}}
						</div>
					</ng-template>

					<div *ngIf="receiveStaffOrgMeterReportMessages.value" class="cp-compact-form-group ms-4">
						<label for="orgMeterReportOrgIDs">
							{{ appConstants.notificationPropLabels.orgMeterReportOrgIDs }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="orgMeterReportOrgIDsPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</label>

						<ng-select *ngIf="orgSelections.length > 0" [items]="orgSelections" bindValue="id" bindLabel="name"
							id="orgMeterReportOrgIDs" formControlName="orgMeterReportOrgIDs" [multiple]="true" [closeOnSelect]="false"
							(change)="saveSettings()" placeholder="Select one or more organizations">
						</ng-select>

					</div>

					<ng-template #orgMeterReportOrgIDsPopupContent>
						<div class="cp-popover-banner">{{ appConstants.notificationPropLabels.orgMeterReportOrgIDs }}
						</div>
						<div class="cp-pre-text">{{ appConstants.notificationPropInfo.orgMeterReportOrgIDs }}</div>
					</ng-template>
				</div>
				<div class="cp-form-padding"></div>

			</div>
		</div>
	</div>
</form>