import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MyKeysService } from '../my-keys.service';

@Component({
	selector: 'app-my-org-billing-codes-form',
	templateUrl: './my-org-billing-codes-form.component.html',
	styleUrls: ['./my-org-billing-codes-form.component.scss']
})
export class MyOrgBillingCodesFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	orgId: number;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	billingCodeOrgs: Models.OrganizationBillingCodes[] = [];
	billingCodeOrg: Models.OrganizationBillingCodes = null;

	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
		this.route.paramMap.subscribe(params => {
			this.orgId = +params.get('orgId');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.setup();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async setup() {
		this.loading = false;

		this.billingCodeOrgs = await this.myKeysService.getBillingCodesOrganizations();

		if (!this.orgId || isNaN(this.orgId) || this.orgId === 0) {
			this.loading = false;
			this.onCancel();
			this.uiAlertsService.addMsg('No ID', 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			return;
		} // if

		this.billingCodeOrg = null;
		for (const item of this.billingCodeOrgs)
			if (item.org && item.org.id === this.orgId)
				this.billingCodeOrg = item;

		if (!this.billingCodeOrg) {
			this.uiAlertsService.addMsg('No matching organization', 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			this.loading = false;
			return;
		} // if

		this.theForm = new UntypedFormGroup({
			labels: new UntypedFormControl('', [Validators.required])
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		// const orgBillingCodes = await this.organizationsService.fetchBillingCodes(this.id);

		const labels: string = this.theForm.value.labels;

		const registerErrors: string[] = [];

		let numProcessed: number = 0;
		let numAdded: number = 0;
		const array: string[] = labels.match(/[^\r\n]+/g);
		const array2: string[] = MiscTools.removeDuplicates(array);

		if (array.length !== array2.length)
			this.uiAlertsService.addMsg('Skipping ' + (array.length - array2.length) + ' duplicates.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		for (const label of array2) {
			if (label.trim() !== '') {
				numProcessed++;
				try {
					const idx = MiscTools.findIndexGeneric(this.billingCodeOrg.billingCodes, 'label', label.trim());
					if (idx !== -1) {
						this.uiAlertsService.addMsg('There\'s already a billing code with the Name/Label "' + label.trim() + '"', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
					} else {
						const newBC: Models.BillingCode = new Models.BillingCode(0, this.orgId, '', '', label.trim(), 1);

						await this.myKeysService.addBillingCode(newBC);
						numAdded++;
					} // if
				} catch (e) {
					registerErrors.push(e.message);
				}
			}
		}

		for (const err of registerErrors)
			this.uiAlertsService.addMsg(err, 'error');


		if (numAdded === 1)
			this.uiAlertsService.addMsg('Created one billing code.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		else if (numAdded > 1)
			this.uiAlertsService.addMsg('Created ' + numAdded + ' billing code.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.saving = false;
		if (registerErrors.length === 0 && numAdded > 0) this.onCancel();
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.mykeys]);
	}
}
