<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.marketplace" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Marketplace Info
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="btn-group">
			<button type="button" class="btn btn-outline-secondary" (click)="loadData()" [disabled]="loading">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-azure-tab" aria-controls="nav-azure" data-bs-target="#nav-azure">
				<fa-icon [icon]="['fab', 'microsoft']"></fa-icon>
				Azure
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-gcp-tab" aria-controls="nav-gcp" data-bs-target="#nav-gcp">
				<fa-icon [icon]="['fab', 'google']"></fa-icon>
				GCP
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-aws-tab" aria-controls="nav-aws" data-bs-target="#nav-aws">
				<fa-icon [icon]="['fab', 'aws']"></fa-icon>
				AWS
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane" role="tabpanel" id="nav-aws" aria-labelledby="nav-aws-tab">
			See AWS Marketplace Management Portal
		</div>

		<div class="tab-pane show active" role="tabpanel" id="nav-azure" aria-labelledby="nav-azure-tab">

			<table *ngIf="!loading && azureSubscriptions" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Subscription ID</th>
						<th>Created</th>
						<th>Offer ID</th>
						<th>Name</th>
						<th>Status</th>
						<th>Purchaser</th>
						<th>Purchaser ID</th>
						<th>Plan ID</th>
						<th>-</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of azureSubscriptions">
						<td class="border-end" [attr.data-order]="item.id">
							<span [ngbTooltip]="item.id" ngxClipboard [cbContent]="item.id"
								(click)="copyToClipboardAlert(item.id)">
								{{ textTools.chopString(item.id, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.created">
							<span [ngbTooltip]="item.created">
								{{ item.created | date : appConstants.tableDateFmt }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.offerId">
							<span [ngbTooltip]="item.offerId" ngxClipboard [cbContent]="item.offerId"
								(click)="copyToClipboardAlert(item.offerId)">
								{{ textTools.chopString(item.offerId, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.name">
							<span [ngbTooltip]="item.name" ngxClipboard [cbContent]="item.name"
								(click)="copyToClipboardAlert(item.name)">
								{{ textTools.chopString(item.name, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.saasSubscriptionStatus">
							<span [ngbTooltip]="item.saasSubscriptionStatus" ngxClipboard
								[cbContent]="item.saasSubscriptionStatus"
								(click)="copyToClipboardAlert(item.saasSubscriptionStatus)">
								{{ textTools.chopString(item.saasSubscriptionStatus, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.purchaser.emailId">
							<span [ngbTooltip]="item.purchaser.emailId" ngxClipboard
								[cbContent]="item.purchaser.emailId"
								(click)="copyToClipboardAlert(item.purchaser.emailId)">
								{{ textTools.chopString(item.purchaser.emailId, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.purchaser.objectId">
							<span [ngbTooltip]="item.purchaser.objectId" ngxClipboard
								[cbContent]="item.purchaser.objectId"
								(click)="copyToClipboardAlert(item.purchaser.objectId)">
								{{ textTools.chopString(item.purchaser.objectId, chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.planId">
							<span [ngbTooltip]="item.planId" ngxClipboard [cbContent]="item.planId"
								(click)="copyToClipboardAlert(item.planId)">
								{{ textTools.chopString(item.planId, chopLength, '...') }}
							</span>
						</td>
						<td>
							<span *ngIf="item.matches">
								<div *ngFor="let match of item.matches">
									<a [routerLink]="match.route">
										<fa-icon *ngIf="match.type==='zen-master'"
											[icon]="appConstants.sectionIcons.zencustomers"></fa-icon>
										<fa-icon *ngIf="match.type==='key'"
											[icon]="appConstants.sectionIcons.licensing"></fa-icon>
										{{ match.name }}
									</a>
								</div>
							</span>
						</td>
					</tr>
				</tbody>
			</table>

			<a href="javascript:void(0)" (click)="showRawAzure = !showRawAzure">Show/Hide Raw Data</a>
			<pre *ngIf="showRawAzure">{{ azureSubscriptions | json }}</pre>

		</div>

		<div class="tab-pane" role="tabpanel" id="nav-gcp" aria-labelledby="nav-gcp-tab">
			<table *ngIf="!loading && gcpEntitlements" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Entitlement ID</th>
						<th>Created</th>
						<th>Entitlement State</th>
						<th>Account ID</th>
						<th>Account State</th>
						<th>Plan</th>
						<th>Offer</th>
						<th>-</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of gcpEntitlements">
						<td class="border-end" [attr.data-order]="grabLastPart(item.name)">
							<span [ngbTooltip]="grabLastPart(item.name)" ngxClipboard
								[cbContent]="grabLastPart(item.name)"
								(click)="copyToClipboardAlert(grabLastPart(item.name))">
								{{ textTools.chopString(grabLastPart(item.name), chopLength, '...') }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.createTime">
							<span [ngbTooltip]="item.createTime">
								{{ item.createTime | date : appConstants.tableDateFmt }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="item.state">
							<span [ngbTooltip]="item.state" ngxClipboard [cbContent]="item.state"
								(click)="copyToClipboardAlert(item.state)">
								{{ item.state }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="grabLastPart(item.account)">
							<span [ngbTooltip]="grabLastPart(item.account)" ngxClipboard
								[cbContent]="grabLastPart(item.account)"
								(click)="copyToClipboardAlert(grabLastPart(item.account))">
								{{ textTools.chopString(grabLastPart(item.account), chopLength, '...') }}
							</span>
						</td>

						<td class="border-end">
							{{ getAccountState(item.account) }}
						</td>

						<td class="border-end" [attr.data-order]="item.plan">
							<span [ngbTooltip]="item.plan" ngxClipboard [cbContent]="item.plan"
								(click)="copyToClipboardAlert(item.plan)">
								{{ item.plan }}
							</span>
						</td>

						<td class="border-end" [attr.data-order]="grabLastPart(item.offer)">
							<span [ngbTooltip]="grabLastPart(item.offer)" ngxClipboard
								[cbContent]="grabLastPart(item.offer)"
								(click)="copyToClipboardAlert(grabLastPart(item.offer))">
								{{ textTools.chopString(grabLastPart(item.offer), chopLength, '...') }}
							</span>
						</td>
						<td>
							<span *ngIf="item.matches">
								<div *ngFor="let match of item.matches">
									<a [routerLink]="match.route">
										<fa-icon *ngIf="match.type==='zen-master'"
											[icon]="appConstants.sectionIcons.zencustomers"></fa-icon>
										<fa-icon *ngIf="match.type==='key'"
											[icon]="appConstants.sectionIcons.licensing"></fa-icon>
										{{ match.name }}
									</a>
								</div>
							</span>
						</td>
					</tr>
				</tbody>
			</table>


			<a href="javascript:void(0)" (click)="showRawGCP = !showRawGCP">Show/Hide Raw Data</a>
			<pre *ngIf="showRawGCP">{{ gcpAccounts | json }}</pre>
			<pre *ngIf="showRawGCP">{{ gcpEntitlements | json }}</pre>

			<!-- <table *ngIf="!loading"class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Label</th>
						<th>Name</th>
						<th>Description</th>
						<th>Special</th>
						<th ngbTooltip="Number of Products">#</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let property of getPropertiesByType('other')" [routerLink]="[property.id]">
						<td class="border-end text-nowrap"><a [routerLink]="[property.id]">
								{{ property.label }}
							</a></td>
						<td class="border-end">{{ property.name }}</td>
						<td class="border-end">{{ property.description }}</td>
						<td class="border-end text-nowrap">{{ niceBoolean(property.special_property) }}</td>
						<td>{{ getProductsForProperty(property.id) }}</td>
					</tr>
				</tbody>
			</table> -->

		</div>
	</div>
</div>