import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { UserGroupsService } from '../user-groups.service';
import { AuthService } from 'client/app/services/auth.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-user-group-list',
	templateUrl: './user-group-list.component.html',
	styleUrls: ['./user-group-list.component.scss']
})

export class UserGroupListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	// 'standard' list stuff
	loading = true;
	refreshing = false;

	items: Models.UserGroup[] = [];
	itemsToShow: Models.UserGroup[] = [];

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	now = new Date();

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-user-groups-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: '__num_managers',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Managers',
			icon: 'user-ninja'
		}, {
			field: '_num_users',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Users',
			icon: 'user'
		}, {
			field: '__num_access',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Access',
			icon: 'user-lock'
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}
	];

	constructor(
		private userGroupsService: UserGroupsService,
		private authService: AuthService,
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-user-groups');
			this.loadData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(refresh: boolean = false) {
		this.loading = true;
		if (refresh)
			await this.userGroupsService.refreshAll().toPromise();

		this.items = this.userGroupsService.getAll();

		for (const item of this.items) {
			item['__num_managers'] = item.manager_ids.length;
			item['_num_users'] = item.user_ids.length;
			item['__num_access'] = item.accessTags.length;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.UserGroup[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
