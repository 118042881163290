<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<fa-icon [icon]="appConstants.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
			</fa-icon>
			<li class="breadcrumb-item active" aria-current="page">Users</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">

			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'user-plus']" size="sm" ngbTooltip="New User" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">New User</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary me-2"
				[routerLink]="['/' + appConstants.urls.usergroups]">
				<fa-icon [icon]="appConstants.sectionIcons.usergroups" size="sm"></fa-icon>
				<span class="cp-action-button-text">Groups</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="openBigReport()">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Report</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" routerLink="access-summary">
				<fa-icon icon="user-lock" size="sm"></fa-icon>
				<span class="cp-action-button-text">Access Summary</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-2">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter"
					formControlName="enabledFilter" [multiple]="false" (change)="onFormChange()"
					placeholder="Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="roleChoices" bindValue="value" bindLabel="label" id="roleFilters"
					formControlName="roleFilters" [multiple]="true" (change)="onFormChange()" placeholder="Role(s)">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="packageChoices" bindValue="value" bindLabel="label" id="packageFilters"
					formControlName="packageFilters" [multiple]="true" (change)="onFormChange()"
					placeholder="Package(s)">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="specialChoices" bindValue="value" bindLabel="label" id="specialFilters"
					formControlName="specialFilters" [multiple]="true" (change)="onFormChange()" placeholder="Special">
				</ng-select>
			</div>

		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="recentUsers && recentUsers.length > 0" class="cp-light-message">
		Recently Viewed:
		<span *ngFor="let item of recentUsers" class="me-1">
			<a [routerLink]="[item.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
				triggers="mouseenter:mouseleave" container="body">
				{{ item.name }}</a>;
			<ng-template #userPopupContent>
				<div class="cp-popover-banner">{{ item.name }}</div>
				<div class="cp-pre-text">{{
					popOverTools.getUserPopoverLines(item).join('\n')}}</div>
			</ng-template>
		</span>
	</div>

	<app-users-table #usersTable1></app-users-table>
</div>