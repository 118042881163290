import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';

import { MySettingsService } from '../my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-change-email-form',
	templateUrl: './change-email-form.component.html',
	styleUrls: ['./change-email-form.component.scss']
})
export class ChangeEmailFormComponent implements OnInit {
	appConstants = AppConstants;

	// private userSubscription: Subscription;
	user: Models.User;
	loading = true;
	saving = false;
	errors: string[] = [];
	theForm: UntypedFormGroup;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mySettingsService: MySettingsService,
		private uiAlertsService: UiAlertsService) { }

	ngOnInit(): void {
		this.initForm();
	}

	async initForm() {
		this.user = await this.mySettingsService.getUser();
		if (this.user.use_sso === 1)
			this.onCancel();

		this.loading = false;

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			email: new UntypedFormControl(this.user.email, [Validators.required, Validators.email])
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];


		const originalDomain = TextTools.getEmailDomain(this.user.email).toLowerCase().trim();

		// check the user's email address to see if it uses a bad domain...
		const newDomain = TextTools.getEmailDomain(this.theForm.value.email).toLowerCase().trim();
		if (originalDomain !== newDomain) {
			let badDomain = false;
			for (const fd of AppConstants.forbiddenSelfRegistrationDomains)
				if (newDomain === fd || newDomain.endsWith('.' + fd))
					badDomain = true;
			if (badDomain)
				this.errors.push('You are not allowed to use an e-mail address from a non-business domain.');
		} // if

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			const retUser = await this.mySettingsService.stageEmail(this.theForm.value.email);
			if (retUser) {
				this.uiAlertsService.addMsg('An e-mail has been sent to your new e-mail address.  You will need to click the link in the e-mail to complete this change.',
					'info', 'email_stage', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate(['..'], { relativeTo: this.route });
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get email() { return this.theForm.get('email'); }
}
