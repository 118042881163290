import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-key-template-list',
	templateUrl: './key-template-list.component.html',
	styleUrls: ['./key-template-list.component.scss']
})
export class KeyTemplateListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	products: Models.LicenseProduct[] = [];
	userTemplateIDs: number[] = [];

	items: Models.KeyTemplate[] = [];
	itemsToShow: Models.KeyTemplate[] = [];

	canManageTemplates = false;

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-key-templates-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: '__template_type',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Type of Template',
			icon: 'cog'
		}, {
			field: 'num_keys',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '="# Keys Linked to this Template',
			icon: AppConstants.sectionIcons.licensing
		}, {
			field: '__can_use',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'You can use this template',
			icon: 'check'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: '__niceProduct',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: '__types_full',
			type: 'baseText',
			sortType: 'text',
			label: 'Type(s)',
			toolTip: null,
			icon: null
		}, {
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}, {
			field: 'edited_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Edited',
			toolTip: null,
			icon: null
		}
	];

	constructor(
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.canManageTemplates = (authUser && ValidationTools.checkAccess(authUser, 'manage-key-templates'));
			this.getData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async getData(forceRefresh = false) {
		this.loading = true;

		this.products = await this.licensingAdminService.getProducts(forceRefresh);
		this.userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();

		this.items = await this.licensingAdminService.getTemplates();

		for (const item of this.items) {
			item['__niceProduct'] = this.niceKeyProduct(item.product);

			if (item.settings.editOnly)
				item['__template_type'] = 1;
			else
				item['__template_type'] = 0;

			item['__can_use'] = this.userTemplateIDs.includes(item.id) ? 1 : 0;

			item['__types'] = [];
			item['__types_full'] = [];

			for (const keyType of item.settings.keyTypes) {
				item['__types_full'].push(keyType);
				item['__types'].push(TextTools.acronym((keyType)));
			} // for

		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'prod_name', 'asc');
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.KeyTemplate[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| (item['__niceProduct'] && item['__niceProduct'].toLowerCase().includes(w))
						|| (item['__types_full'] && item['__types_full'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

	// ------------------------------------------------------------------------
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.products, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.products[idx].label;
	}
}
