<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.bundles" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Shared Files</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				Update Bundle
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Bundle
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="ptype">What are you doing?</label>
			<div class="form-check" *ngFor="let btype of btypeSelections">
				<input class="form-check-input" type="radio" formControlName="btype" name="btype"
					[id]="'btype_' + btype.value" [value]="btype.value" />
				<label class="form-check-label" [for]="'btype_' + btype.value">
					{{ btype.label }}
					<fa-icon *ngIf="appConstants.bundleTypeDescriptions[btype.value] !== ''"
						[icon]="['fas', 'question-circle']" class="ms-1"
						[ngbTooltip]="appConstants.bundleTypeDescriptions[btype.value]" placement="end"></fa-icon>
				</label>
			</div>
		</div>

		<div *ngIf="btype.value && btype.value !== ''" class="form-group">
			<label for="information">Information</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"
				placeholder="Optional information"></textarea>
		</div>

		<div *ngIf="btype.value && btype.value.startsWith('group')" class="form-group">
			<label *ngIf="btype.value && btype.value.endsWith('send')" for="org_id">
				User Group Sending the Files
			</label>
			<label *ngIf="btype.value && btype.value.endsWith('receive')" for="org_id">
				User Group Receiving the Files
			</label>

			<span *ngIf="userGroupSelections.length === 0">
				You are not a member of any user groups.
			</span>

			<ng-select *ngIf="userGroupSelections.length > 0" [items]="userGroupSelections" bindValue="id"
				bindLabel="name" id="staff_id" formControlName="staff_id" [multiple]="false">
			</ng-select>
		</div>

		<div *ngIf="btype.value && btype.value.endsWith('send')" class="form-group">
			Who should receive the files you want to send? Choose an Organization to let everyone in that
			Organization receive the files or choose one or more individual users.
		</div>

		<div *ngIf="btype.value && btype.value.endsWith('receive')" class="form-group">
			Who should be able to send your or your group files? Choose an Organization to let anyone in that
			Organization send the files or choose one or more individual users.
		</div>

		<div *ngIf="btype.value && btype.value !== ''" class="form-group">
			<label for="org_id">Organization</label>
			<ng-select [items]="orgSelections" bindValue="id" bindLabel="name" id="org_id" formControlName="org_id"
				[multiple]="false">
			</ng-select>
		</div>

		<div *ngIf="btype.value && btype.value !== ''" class="form-group">
			<label for="user_ids">User(s)</label>
			<ng-select [items]="userSelections" bindValue="id" bindLabel="name" id="user_ids" formControlName="user_ids"
				[multiple]="true">
			</ng-select>
		</div>
		<!-- 

		<div class="form-group mb-0" *ngIf="btype.value && btype.value !== '' && !saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div> -->
	</div>
	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
				[disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>