import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { ZenCustomersService } from '../zen-customers.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { ProductsService } from '../../products/products.service';
import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-zen-customers-report-users',
	templateUrl: './zen-customers-report-users.component.html',
	styleUrls: ['./zen-customers-report-users.component.scss']
})
export class ZenCustomersReportUsersComponent implements OnInit {
	appConstants = AppConstants;
	validationTools = ValidationTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	items: Models.ZenMasterUser[] = [];
	itemsToShow: Models.ZenMasterUser[] = [];

	zenCustomers: Models.ZenMasterCustomer[] = [];

	loading = true;

	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices = [];
	zmTypeChoices = [];
	specialChoices = [
		{ value: 'basic_user', label: 'Regular User' },
		{ value: 'admin_user', label: 'Site Admin' },
		{ value: 'support_readonly', label: 'Zixi Support (read-only)' },
		{ value: 'support_write', label: 'Zixi Support (read/write)' },
		{ value: 'zixi_admin', label: 'Zixi Admin' },

		{ value: 'some_logins', label: 'Has Logged In' },
		{ value: 'no_logins', label: 'Has Never Logged In' },

		{ value: 'sso_user', label: 'Uses Single Sign On' },
		{ value: 'non_sso_user', label: 'Does Not User Single Sign On' },
	];

	// is/isn't a portal user
	// is/isn't linked to an organization
	// is/isn't linked to same org as site


	// filters
	enabledFilter: number = null;
	zmTypeFilters = [];
	specialFilters = [];

	users: Models.User[] = [];

	matchedPortalUsers: any = {};
	noOrgUsers: any = {};
	diffOrgUsers: any = {};

	zenPrefixes: any = {};
	zenTypes: any = {};
	zenEnabled: any = {};

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-zen-users-report';

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'User Account Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: 'sso_id',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Single Sign On',
			icon: 'sign-in-alt'
		}, {
			field: 'is_admin',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Site Admin',
			icon: 'hat-wizard'
		}, {
			field: '__supportShort',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Zixi Support',
			icon: 'hat-wizard'
		}, {
			field: 'is_zixi_admin',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Zixi Admin',
			icon: 'hat-wizard'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'email',
			type: 'baseText',
			sortType: 'text',
			label: 'E-Mail',
			toolTip: null,
			icon: null
		}, {
			field: '__sinceAdd',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since added',
			icon: 'clock'
		}, {
			field: '__sinceLogin',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since last login',
			icon: 'sign-in-alt'
		}, {
			field: '__notificatioState',
			type: 'baseText',
			sortType: 'text',
			label: 'ZCP Notify',
			toolTip: null,
			icon: null
		}, {
			field: '__prefix',
			type: 'baseText',
			sortType: 'text',
			label: 'Prefix',
			toolTip: null,
			icon: null
		}, {
			field: '__type',
			type: 'baseText',
			sortType: 'text',
			label: 'Site Type',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	constructor(
		private router: Router,
		private usersService: UsersService,
		private productsService: ProductsService,
		private organizationsService: OrganizationsService,
		private zenCustomersService: ZenCustomersService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-zenUsers.settings'))
				settings = JSON.parse(localStorage.getItem('cp-zenUsers.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('enabledFilter')) this.enabledFilter = settings.enabledFilter;
		if (settingKeys.includes('zmTypeFilters')) this.zmTypeFilters = settings.zmTypeFilters;
		if (settingKeys.includes('specialFilters')) this.specialFilters = settings.specialFilters;

		this.showFilters = (
			this.enabledFilter != null
			|| this.zmTypeFilters.length !== 0
			|| this.specialFilters.length !== 0
		);

		this.initForm();
		this.loadZenObjects();
	}

	// ------------------------------------------------------------------------
	async loadZenObjects(forceReload = false) {
		this.loading = true;
		this.users = this.usersService.getAll();
		this.zenCustomers = this.zenCustomersService.getAll();

		const allProducts = this.productsService.getAll();
		let zenID = -1;
		for (const prod of allProducts)
			if (prod.name === 'ZEN Master')
				zenID = prod.id;

		let zenOrgIDs: number[] = [];
		if (zenID !== -1) {
			const allOrgs = this.organizationsService.getAll();
			for (const org of allOrgs)
				if (MiscTools.findIndexGeneric(org.product_platforms, 'product_id', zenID) !== -1)
					zenOrgIDs.push(org.id);
		} // if

		this.zenPrefixes = {};
		this.zenTypes = {};
		this.zenEnabled = {};
		for (const zenCustomer of this.zenCustomers) {
			this.zenPrefixes[zenCustomer.id] = zenCustomer.dns_prefix;
			this.zenTypes[zenCustomer.id] = zenCustomer.zcp_type;
			this.zenEnabled[zenCustomer.id] = zenCustomer.is_enabled;

		} // for


		this.matchedPortalUsers = {};
		this.noOrgUsers = {};
		this.diffOrgUsers = {};

		this.items = await this.zenCustomersService.getZenUsers();
		for (const item of this.items) {
			const idx = MiscTools.findIndexGeneric(this.zenCustomers, 'id', item.customer_id);
			// if (idx !== -1)
			// 	user.zen_customer = this.zenCustomers[idx];

			item['__sinceAdd'] = null;
			if (item.created_at) item['__sinceAdd'] = MiscTools.daysSince(item.created_at, true);

			item['__sinceLogin'] = null;
			if (item.last_login_at) item['__sinceLogin'] = MiscTools.daysSince(item.last_login_at, true);

			this.noOrgUsers[item.id] = false;
			this.diffOrgUsers[item.id] = false;

			const idx2 = MiscTools.findIndexGeneric(this.users, 'email', item.email.trim().toLowerCase());
			if (idx2 !== -1) {
				this.matchedPortalUsers[item.id] = this.users[idx2];
				if (this.users[idx2].org_id && this.users[idx2].org_id !== 0) {
					if (idx !== -1 && this.users[idx2].org_id !== this.zenCustomers[idx].zcp_org_id)
						this.diffOrgUsers[item.id] = true;
				} else {
					this.noOrgUsers[item.id] = true;
				} // if

				// item.zcp_user_id = this.users[idx2].id;
				// item.zcp_user_org_id = this.users[idx2].org_id;

				item['__notificatioState'] = ValidationTools.getZenUserNotificationState(this.users, zenOrgIDs, item);
			} else {
				this.matchedPortalUsers[item.id] = null;
				// item.zcp_user_id = 0;
				// item.zcp_user_org_id = 0;
				item['__notificatioState'] = 'No ZCP Account';
			} // if

			item['__supportShort'] = ValidationTools.getZenUserSupportLabel(item, true);
			item['__supportLong'] = ValidationTools.getZenUserSupportLabel(item, false);

			item['__prefix'] = this.zenPrefixes[item.customer_id];
			item['__type'] = AppConstants.zenMasterTypeLabels[this.zenTypes[item.customer_id]];
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, '__prefix', 'asc');
	}

	// ------------------------------------------------------------------------
	initForm() {
		this.zmTypeChoices = [];
		this.zmTypeChoices.push({ value: '', label: 'Unknown/Not Set' });
		for (const zenMasterType of AppConstants.zenMasterTypes)
			this.zmTypeChoices.push({ value: zenMasterType, label: AppConstants.zenMasterTypeLabels[zenMasterType] });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online (site)' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline (site)' });

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			zmTypeFilters: new UntypedFormControl(this.zmTypeFilters),
			specialFilters: new UntypedFormControl(this.specialFilters)
		});
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.zmTypeFilters = this.theForm.value.zmTypeFilters;
		this.specialFilters = this.theForm.value.specialFilters;

		let settings: any = {
			enabledFilter: this.enabledFilter,
			zmTypeFilters: this.zmTypeFilters,
			specialFilters: this.specialFilters
		};
		localStorage.setItem('cp-zenUsers.settings', JSON.stringify(settings));

		this.setSortBy(null);
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZenMasterUser[] = [];

		for (const item of this.items) {
			let passedEnabledFilter = false;
			let passedTypeFilter = false;
			let passedSpecialFilters = false;

			if (this.enabledFilter == null || this.enabledFilter === this.zenEnabled[item.customer_id])
				passedEnabledFilter = true;

			if (!this.zmTypeFilters || this.zmTypeFilters.length === 0
				|| (this.zmTypeFilters.length !== 0 && this.zenTypes[item.customer_id] && this.zmTypeFilters.includes(this.zenTypes[item.customer_id])))
				passedTypeFilter = true;

			passedSpecialFilters = true;
			if (!this.specialFilters || this.specialFilters.length === 0) {
				passedSpecialFilters = true;
			} else {
				passedSpecialFilters = true;
				if (this.specialFilters.includes('basic_user') && (item.is_admin && item.is_admin === 1))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('admin_user') && (!item.is_admin || item.is_admin === 0))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('zixi_admin') && (!item.is_zixi_admin || item.is_zixi_admin === 0))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('support_readonly') && ValidationTools.getZenUserSupportLabel(item, true) !== 'r')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('support_write') && ValidationTools.getZenUserSupportLabel(item, true) !== 'w')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('some_logins') && item.last_login_at == null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_logins') && item.last_login_at != null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('sso_user') && (!item.sso_id || item.sso_id === ''))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('non_sso_user') && (item.sso_id && item.sso_id !== ''))
					passedSpecialFilters = false;
			} // if


			let match: boolean = passedEnabledFilter && passedTypeFilter && passedSpecialFilters;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| (item.email && item.email.toLowerCase().includes(w))
						|| (item['__notificatioState'] && item['__notificatioState'].toLowerCase().includes(w))
						|| (item['__prefix'] && item['__prefix'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}

