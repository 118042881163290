import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { FilesService } from '../files.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-file-form',
	templateUrl: './file-form.component.html',
	styleUrls: ['./file-form.component.scss']
})
export class FileFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	file: Models.File;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private filesService: FilesService,
		private uiAlertsService: UiAlertsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.file = this.filesService.getOne(this.id);
				if (!this.file || this.file == null || this.file.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.filesService.refreshOne(this.id);
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.listSubscription = this.filesService.files.subscribe(files => {
			this.file = files.find((file: Models.File) => file.id === this.id);
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	initForm() {
		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			free_access_token: new UntypedFormControl(this.file.free_access_token)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		let freeAccessToken = '';
		if (this.theForm.value.free_access_token) freeAccessToken = this.theForm.value.free_access_token.trim();

		try {
			let currentFileTokenID = 0;
			if (freeAccessToken !== '') {
				const currentFileWithToken = await this.filesService.findByToken(freeAccessToken);
				if (currentFileWithToken && currentFileWithToken.id !== this.id) {
					currentFileTokenID = currentFileWithToken.id;
					this.uiAlertsService.addMsg('That token is already being used with another file('
						+ currentFileWithToken.name + '). It has been removed from the other file'
						+ ' and set for this one.', 'info');
				}
			}

			const ret = await this.filesService.setToken(this.file.id, freeAccessToken);
			if (ret) {
				if (currentFileTokenID !== 0) {
					this.filesService.refreshOne(currentFileTokenID).subscribe();
				}

				this.router.navigate([AppConstants.urls.files + '/' + this.file.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

}
