<div class="login-container">
	<div class="row">
		<div class="col text-center">
			<img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" />
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'max-width.px': 600 }">
				<div class="card-body">
					<form *ngIf="theForm" [formGroup]="theForm" (ngSubmit)="onSubmit()">
						<div>
							<h3>Create Account</h3>
							<div *ngIf="saving" class="text-center">
								<app-loading-spinner></app-loading-spinner>
							</div>

							<div class="form-group mb-1">
								<label for="code">Registration Code</label>
								<input type="text" id="code" formControlName="code" class="form-control"
									placeholder="Registration Code (optional)" [readOnly]="!codeEditable" (blur)="onCodeChange()" />
							</div>

							<div *ngIf="packageName && packageName !== ''" class="cp-pre-text p-2 border mb-2">
								The Registration Code provided will subscribe you to the following package:<br />
								<span class="fw-bold">
									{{ packageName }}<br />
									{{ packageDescription }}
								</span>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="firstname">First Name*</label>
									<input type="text" id="firstname" formControlName="firstname" class="form-control"
										placeholder="First Name" minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="lastname">Last Name*</label>
									<input type="text" id="lastname" formControlName="lastname" class="form-control" placeholder="Last Name"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="email">E-Mail*</label>
									<input type="email" id="email" formControlName="email" class="form-control" placeholder="E-Mail address"
										minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="phone">Phone*</label>
									<input type="tel" id="phone" formControlName="phone" class="form-control" placeholder="Phone"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="company">Company*</label>
									<input type="text" id="company" formControlName="company" class="form-control" placeholder="Company"
										minlength="1" />
								</div>

								<div class="form-group mb-1 col-sm">
									<label for="title">Title/Position*</label>
									<input type="text" id="title" formControlName="title" class="form-control" placeholder="Title/Position"
										minlength="1" />
								</div>
							</div>

							<div class="row">
								<div class="form-group mb-1 col-sm">
									<label for="country">Country*</label>
									<ng-select [items]="appConstants.countries" bindValue="code" bindLabel="label" id="country"
										formControlName="country" [multiple]="false" placeholder="Country">
									</ng-select>
								</div>

								<div class="col-sm">
									<div *ngIf="country && country.value === 'US'" class="form-group mb-1">
										<label for="title">State*</label>
										<ng-select [items]="appConstants.usStates" bindValue="code" bindLabel="label" id="state"
											formControlName="state" [multiple]="false" placeholder="US State">
										</ng-select>
									</div>

									<div *ngIf="country && country.value === 'CA'" class="form-group mb-1">
										<label for="title">Province*</label>
										<ng-select [items]="appConstants.cdnProvinces" bindValue="code" bindLabel="label" id="state"
											formControlName="state" [multiple]="false" placeholder="Canadian Province">
										</ng-select>
									</div>
								</div>
							</div>

							<div class="form-group mb-1">
								<label for="information">Information</label>
								<textarea id="information" formControlName="information" class="form-control" rows="3"
									placeholder="Information you would like to share about how you plan to use Zixi's Software"></textarea>
							</div>

							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" id="agree" formControlName="agree" />
								<label class="form-check-label" for="agree">
									By checking this box and clicking on the "Create Account" button, I acknowledge
									that I have read and agree to the
									<a [href]="appConstants.termsURL" target="_blank">Zixi Software Terms &
										Conditions</a>.</label>
							</div>

							<div class="mt-1 mb-1 p-2 border">
								After you click the 'Create Account' button, you will be sent a confirmation
								e-mail with a link that will let you set your password.
							</div>

							<div class="mt-1 mb-1 p-2 border fw-bold">
								You must be able to receive messages at the e-mail address you've provided.
								Please verify that it is correct before proceeding.
							</div>

							<div class="mb-2 text-center">
								<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid || saving">
									<fa-icon [icon]="['fas', 'check']"></fa-icon> Create Account
								</button>
								<button [disabled]="saving" class="btn btn-outline-secondary" type="button" (click)="onCancel()">
									<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
								</button>
							</div>

							<div class="mb-2 small text-center">* Required</div>

							<div class="mb-2 text-center border-top mt-1 pt-1">
								<button [disabled]="saving" class="btn btn-outline-primary" type="button" routerLink="/login">
									Already Have an Account? <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
									&nbsp;Login Here
								</button>
							</div>

							<div class="mb-2 text-center">
								<button [disabled]="saving" type="button" class="btn btn-outline-primary" routerLink="/forgot-password">
									<fa-icon [icon]="['fas', 'passport']"></fa-icon>&nbsp;Forgot your password?
								</button>
							</div>

							<div class="mb-2 small text-center">
								<a [href]="appConstants.supportURL" target='_blank'>
									<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
								</a>
							</div>

							<!-- <div class="form-group mb-0 p-2 border">
								If you setup an account with the previous MediaConnect registration site
								(<strong>www.mediaconnect.zixi.com</strong>) and activated the software, your
								account
								has been migrated to this new portal, but you need to create a new password.<br />
								Click on the <strong>Forgot your password?</strong> button to do so.
							</div> -->

						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>