import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { MyKeysService } from '../my-keys.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-license-key-form',
	templateUrl: './license-key-form.component.html',
	styleUrls: ['./license-key-form.component.scss']
})
export class LicenseKeyFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// 'standard' edit stuff
	id: number;
	licenseKey: Models.UserLicenseKey;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.licenseKey = await this.myKeysService.fetchKey(this.id);
		this.licenseKey.activation.meters = [];
		this.licenseKey.activation.licenses = [];

		if (!this.licenseKey || this.licenseKey == null || this.licenseKey.id === 0 || !this.licenseKey.activation) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			label: new UntypedFormControl(this.licenseKey.label, [Validators.maxLength(100)])
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.licenseKey.label = this.theForm.value.label.trim();

		try {
			const ret = await this.myKeysService.updateKey(this.licenseKey);
			if (ret) {
				// this.router.navigate([AppConstants.urls.mykeys + '/' + this.licenseKey.id]);
				this.onCancel();
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	get label() { return this.theForm.get('label'); }

}
