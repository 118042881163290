<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Organizations</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item active" aria-current="page">
				{{ organization.name }}
			</li>
			<li *ngIf="!editMode" class="breadcrumb-item active" aria-current="page">
				New Organization
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-products-platforms-tab" aria-controls="nav-products-platforms"
				data-bs-target="#nav-products-platforms">
				Products &amp; Platforms
				<span class="cp-tab-badge">
					{{ counter | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="theForm && !loading" [formGroup]="theForm" class="tab-form">
		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">
					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
						<label class="form-check-label" for="is_enabled">This organization is enabled/active (its users
							can download files).</label>
					</div>

					<div class="form-group">
						<label for="otype">Type*</label>
						<div class="form-check" *ngFor="let otype of otypes">
							<input class="form-check-input" type="radio" formControlName="otype" name="otype"
								[id]="'otype_' + otype" [value]="otype" (click)="onTypeChange($event, otype)" />
							<label class="form-check-label" [for]="'otype_' + otype">
								{{ appConstants.organizationTypeLabels[otype] }}
							</label>
						</div>
					</div>

					<div *ngIf="sfAccountSelections && sfAccountSelections.length !== 0" class="form-group">
						<label for="salesforce_account_id">Salesforce Account</label>
						<ng-select [items]="sfAccountSelections" bindValue="value" bindLabel="label"
							id="salesforce_account_id" formControlName="salesforce_account_id"
							(change)="onAccountSelect($event)"></ng-select>
					</div>

					<div class="form-group">
						<label for="name">Name*</label>
						<input type="text" id="name" formControlName="name" class="form-control" />
					</div>

					<div class="form-group">
						<label for="information">Information</label>
						<textarea id="information" formControlName="information" class="form-control"
							rows="4"></textarea>
					</div>

					<div class="form-group">
						<label for="email_domains">E-Mail Domain(s)
							<fa-icon [icon]="['fas', 'question-circle']"
								ngbTooltip="You can specify one or more (comma separated) e-mail domains.  If set, the SE/tech-rep for this organization will receive an e-mail alert when a new user with an e-mail address from that domain registers."
								placement="bottom-start"> </fa-icon>
						</label>
						<input type="text" id="email_domains" formControlName="email_domains" class="form-control" />
					</div>

					<div *ngIf="parentOrgSelections && parentOrgSelections.length !== 0" class="form-group">
						<label for="salesforce_account_id">Parent Organization</label>
						<ng-select [items]="parentOrgSelections" bindValue="id" bindLabel="name" id="parent_org_id"
							formControlName="parent_org_id"></ng-select>
					</div>

					<div *ngFor="let flag of flagSelections" class="form-group form-check mb-1">
						<input type="checkbox" class="form-check-input" [id]="'flag_' + flag.key"
							[formControlName]="'flag_' + flag.key" />
						<label class="form-check-label" [for]="'flag_' + flag.key">
							{{ flag.label }}
							<fa-icon [icon]="['fas', 'question-circle']" [ngbTooltip]="flag.info"
								placement="bottom-start">
							</fa-icon>
						</label>
					</div>

				</div>
			</div>

			<div class="tab-pane" id="nav-products-platforms" role="tabpanel"
				aria-labelledby="nav-products-platforms-tab">
				<ul class="list-unstyled">
					<li *ngFor="let productType of appConstants.productTypes" class="mb-3"
						[ngClass]="{'font-italic': availableProductTypes.indexOf(productType) === -1}">
						<!-- <strong><input [disabled]="availableProductTypes.indexOf(productType) === -1" type="checkbox"
								[id]="'ptype_' + productType" (click)="checkAllForProductType($event, productType)"
								class="align-middle mb-1 me-1" />
							<label [for]="'ptype_' + productType" class="mb-0">{{ productTypeLabels[productType] }}
								Products</label></strong> -->

						<strong>{{ appConstants.productTypeLabels[productType] }} Products</strong>
						<ul class="list-unstyled ms-3">
							<li *ngFor="let product of subListOfProducts(productType)" class="">
								<input [disabled]="availableProductTypes.indexOf(productType) === -1" type="checkbox"
									[id]="'p_' + product.id" (click)="checkAllForProduct($event, product.id)"
									class="align-middle mb-1 me-1" />
								<label [for]="'p_' + product.id" class="mb-0">{{product.name}}</label>
								<ul class="list-inline ms-3">
									<li *ngFor="let platform of subListOfPlatforms(product.id)"
										class="list-inline-item">
										<label class="form-check-label ps-2 pe-2" [for]="product.id + '_' + platform.id"
											[ngClass]="{'cp-selected-primary': isChecked(product.id,platform.id)}">
											<input type="checkbox" [id]="product.id + '_' + platform.id"
												[formControlName]="'pp_' + product.id + '_' + platform.id"
												class="align-middle mb-1" (change)="adjustCounter($event)" />
											{{ platform.name }}
										</label>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>

		</div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
					[disabled]="!theForm.valid || saving">
					<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>
	</form>
</div>