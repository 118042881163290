import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-activation-marketplace-form',
	templateUrl: './activation-marketplace-form.component.html',
	styleUrls: ['./activation-marketplace-form.component.scss']
})
export class ActivationMarketplaceFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	activation: Models.LPActivation;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	marketPlaceAccountIdLabel: string = 'ID';
	marketPlaceProductSelections: any = [];
	protocolSetSelections: any = [];

	private userSubscription: Subscription;

	organizations: any[] = [];
	// other stuff
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.initForm();
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.activation = await this.licensingService.getOne(this.id);
		if (!this.activation || this.activation == null || this.activation.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		let marketplace: string = '';
		let accountIdentifier: string = '';
		let productIdentifiers: string[] = [];
		let protocolSetId: number = 0;

		if (this.activation.marketplace != null && this.activation.marketplace.marketplace && this.activation.marketplace.marketplace !== '') {
			marketplace = this.activation.marketplace.marketplace;
			accountIdentifier = this.activation.marketplace.accountIdentifier;
			productIdentifiers = this.activation.marketplace.productIdentifiers;
			protocolSetId = +this.activation.marketplace.protocolSetId;
			if (isNaN(protocolSetId)) protocolSetId = 0;
		} // if

		this.protocolSetSelections = [{
			id: 0,
			name: 'Don\'t use a subset of protocols, use all usage data',
			description: '',
			protocols: []
		}];
		const allSets: Models.ProtocolSet[] = await this.licensingAdminService.getProtocolSets();
		for (const pSet of allSets) {
			this.protocolSetSelections.push(pSet);
		} // for

		this.theForm = new UntypedFormGroup({
			marketplace: new UntypedFormControl(marketplace),
			accountIdentifier: new UntypedFormControl(accountIdentifier),
			productIdentifiers: new UntypedFormControl(productIdentifiers),
			protocolSetId: new UntypedFormControl(protocolSetId),
		});

		if (this.activation.marketplace != null)
			this.onMarketplaceChange(false);

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const marketplace: string = this.theForm.value.marketplace;
		const accountIdentifier: string = this.theForm.value.accountIdentifier;
		const productIdentifiers: string[] = this.theForm.value.productIdentifiers;
		let protocolSetId: number = +this.theForm.value.protocolSetId;
		if (isNaN(protocolSetId)) protocolSetId = 0;

		if (marketplace != null && marketplace !== '') {
			if (accountIdentifier == null || accountIdentifier === '') {
				this.errors.push('You must provide an customer/account identifier');
			} else if (productIdentifiers == null || productIdentifiers.length === 0) {
				this.errors.push('You must select one or more products');
			} else {
				const newMarketplaceSettings = new Models.MarketPlaceConfiguration(marketplace, accountIdentifier, productIdentifiers, null, null, protocolSetId);
				// if (this.zenmaster.marketplace){
				// 	newMarketplaceSettings.firstReport = this.zenmaster.marketplace.firstReport;
				// 	newMarketplaceSettings.latestReport = this.zenmaster.marketplace.latestReport;
				// } // if
				this.activation.marketplace = newMarketplaceSettings;
			} // if
		} else {
			this.activation.marketplace = null;
		} // if

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			const retKey = await this.licensingService.marketplaceUpdate(this.activation);
			if (retKey) {
				this.onCancel();
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.licensing, 'activation', this.id]);
	}

	onMarketplaceChange(wipeSelections: boolean) {
		this.marketPlaceProductSelections = [];

		if (wipeSelections) {
			this.theForm.controls['accountIdentifier'].setValue(null);
			this.theForm.controls['productIdentifiers'].setValue([]);
			this.theForm.controls['protocolSetId'].setValue(0);
		} // if

		const selectedMarketplace = this.theForm.value.marketplace;
		if (selectedMarketplace != null && selectedMarketplace !== '') {
			let productSelections: any[] = [];
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', selectedMarketplace);
			if (idx1 !== -1) {
				productSelections = AppConstants.marketPlaceSelections[idx1].selections;
				this.marketPlaceAccountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
			} // if

			for (const awsMarketPlaceProduct of productSelections) {
				if (awsMarketPlaceProduct.object_type === 'activation') {
					this.marketPlaceProductSelections.push({
						value: awsMarketPlaceProduct.productCode,
						label: awsMarketPlaceProduct.label + '(' + awsMarketPlaceProduct.productCode + ')'
					});
				} // if
			} // for

		} // if
	}
}
