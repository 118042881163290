import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { MyKeysService } from '../../my-keys/my-keys.service';

@Component({
	selector: 'app-activation-form',
	templateUrl: './activation-form.component.html',
	styleUrls: ['./activation-form.component.scss']
})
export class ActivationFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	sharedLicenseTools = SharedLicenseTools;
	popOverTools = PopOverTools;

	// 'standard' edit stuff
	id: number;
	orgIDParam: number;
	templateIDParam: number;

	editMode = ''; // setup, new, edit, template
	// theSetupForm: FormGroup;
	theKeyForm: UntypedFormGroup;
	loading: boolean = true;
	saving: boolean = false;

	activation: Models.LPActivation;
	keyProperties: any = {};

	originalType: string = '';

	products: Models.LicenseProduct[] = [];
	template: Models.KeyTemplate;
	// products: Models.LicenseProduct[] = [];
	templates: Models.KeyTemplate[] = [];

	newTemplateProducts: Models.LicenseProduct[] = [];
	validNewTemplates: Models.KeyTemplate[] = [];

	validChangeTemplates: Models.KeyTemplate[] = [];

	product: Models.LicenseProduct;
	baseProductProperties: Models.LicenseProductProperty[] = [];
	orgSelections = [];
	oppSelections = [];

	sfOpps: any[] = [];
	selectedSfOpp: any;
	salesForceUrl = '';

	sfPocEndDate: Date = null;
	sfContractEndDate: Date = null;

	showChangeTemplateButton: boolean = false;
	loadingOpps: boolean = false;
	allOppsFetched: boolean = false;
	showFetchAllOppsButton: boolean = false;
	showMeters: boolean = false;
	anyMetersWithoutLabels: boolean = false;

	rememberOrg: boolean = false;

	meterIDs: string[] = [];
	niceMeterLimits: string[] = [];
	niceMeterProjecteds: string[] = [];

	errors: string[] = [];
	warnings: string[] = [];

	// showFeatures: boolean = false;
	// showLimits: boolean = false;

	booleanProps: Models.LicenseProductProperty[] = [];
	otherProps: Models.LicenseProductProperty[] = [];
	numberProps: Models.LicenseProductProperty[] = [];
	anySpecial: boolean = false;

	otherPropSelections = {};

	numUnlimited = 0;
	numUnlimitedChecked = 0;
	numBooleanChecked = 0;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	keyTypeSelections: any[] = [];
	expireModeSelections: any[] = [];
	meterResetOptions: any[] = [];
	expireMode: string = null;
	durationOptions = AppConstants.keyDurationOptions;
	defaultExpiryDate: Date;
	maxExpiryDate: Date;
	maxExpiryStruct: NgbDateStruct = null;
	meterProducts = [];

	// for sharing
	allUsers: Models.User[] = [];
	shareUserMode: string = 'org';
	shareUserSelections: any[] = [];
	selectedUserIds: number[] = [];
	maxUserCheckListSize: number = 100;

	numActivationsInfo = 'Maximum number of devices that can be activated using this key.';
	numKeysInfo = 'Number of unique keys to generate. Generating more than 1 key at a time is not common.  Maximum is ' + AppConstants.maxKeysToCreate;

	excludedFromCheckAllSymbol: string = 'ex';

	commercialTypeSelections: any[] = [];

	protocolReady: boolean = false;
	availableSets: Models.ProtocolSet[] = [];
	linkedProtocolSets: Models.ProtocolSet[] = [];
	linkedProtocolSetInForm: Models.ProtocolSet[] = [];
	nicePsetProjected: any = {};

	// *************************************************************************************
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private appSettingsService: AppSettingsService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private myKeysService: MyKeysService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService) {

		this.route.queryParams.subscribe(params => {
			this.orgIDParam = null;
			if (params['orgID']) this.orgIDParam = +params['orgID'];

			this.templateIDParam = null;
			if (params['templateID']) this.templateIDParam = +params['templateID'];
		});

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && !isNaN(this.id) && this.id !== 0) {
				this.editMode = 'edit';
			}
		});

	}

	// *************************************************************************************
	ngOnInit(): void {
		if (localStorage.getItem('cp-keyForm.rememberOrg') && localStorage.getItem('cp-keyForm.rememberOrg') === 'yes')
			this.rememberOrg = true;

		if (this.rememberOrg && localStorage.getItem('cp-keyForm.lastOrg') !== '' && this.orgIDParam === 0)
			this.orgIDParam = +localStorage.getItem('cp-keyForm.lastOrg');

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			if (!ValidationTools.checkAccess(authUser, 'manage-keys')) {
				this.onCancel();
				return;
			};

			this.loadData();
		});
	}

	// *************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// *************************************************************************************
	async loadData() {
		this.salesForceUrl = await this.appSettingsService.getSalesForceUrl();

		this.products = await this.licensingAdminService.getProducts(false);
		const allTemplates = await this.licensingAdminService.getTemplates();
		const userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();

		this.templates = [];
		for (const template of allTemplates)
			if (userTemplateIDs.includes(template.id))
				this.templates.push(template);

		this.templates.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.validNewTemplates = [];
		for (const template of this.templates)
			if (!template.settings.editOnly)
				this.validNewTemplates.push(template);

		for (const template of this.validNewTemplates) {
			const idx = MiscTools.findIndexGeneric(this.newTemplateProducts, 'name', template.product);
			if (idx === -1) {
				const idx2 = MiscTools.findIndexGeneric(this.products, 'name', template.product);
				if (idx2 !== -1) this.newTemplateProducts.push(this.products[idx2]);
			} // if
		} // for

		this.newTemplateProducts.sort((a, b) => (a.label > b.label) ? 1 : -1);

		const allSets: Models.ProtocolSet[] = await this.licensingAdminService.getProtocolSets();
		this.availableSets = [];
		this.linkedProtocolSets = [];
		this.linkedProtocolSetInForm = [];

		let linkedSets: Models.ActivationProtocolSet[] = [];
		if (this.id > 0)
			linkedSets = await this.licensingService.getActivationProtocolSets(this.id);


		for (const pSet of allSets) {
			const idx = MiscTools.findIndexGeneric(linkedSets, 'set_id', pSet.id);
			if (idx !== -1) {
				pSet['projected'] = linkedSets[idx].projected;
				this.linkedProtocolSets.push(pSet);
			} else {
				pSet['projected'] = 0;
				this.availableSets.push(pSet);
			} // for
		} // for

		if (this.editMode === 'edit') {
			// get the key
			this.activation = await this.licensingService.getOne(this.id);
			if (!this.activation) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			}

			// wipe licenses...don't need them...
			this.activation.licenses = [];

			this.originalType = this.activation.type;

			this.validChangeTemplates = [];
			for (const template of this.templates)
				if (template.product === this.activation.product && template.id !== this.activation.zcp_template_id && !template.settings.editOnly)
					this.validChangeTemplates.push(template);

			let writeAccess = userTemplateIDs && userTemplateIDs.includes(this.activation.zcp_template_id);
			if (!writeAccess) {
				const writeAccessArr: Models.KeyWriteAccess[] = await this.licensingService.getKeyWriteAccess(this.id);
				writeAccess = MiscTools.findIndexGeneric(writeAccessArr, 'user_id', this.authUser.id) !== -1;
			} // if

			// check to see if the key has a template...if not - NO!!!
			if (!this.activation.zcp_template_id || this.activation.zcp_template_id === 0) {
				this.uiAlertsService.addMsg('This key has not been linked to a template.  It cannot be edited.',
					'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.onCancel();
				return;
			} else if (!writeAccess) {
				this.uiAlertsService.addMsg('You don\'t have access to the template this key is based on and haven\'t been granted write access.',
					'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.onCancel();
				return;
			}

			if (this.router.url.endsWith("clone")) {
				this.editMode = 'new';
				// this.activation = Object.create(this.activation);
				this.activation.created_at = null;
				this.activation.updated_at = null;
				this.activation.count = 0;
				this.activation.user_id = 0;
				this.activation.key = '';

				this.activation.id = 0;
				if (this.activation.meters)
					for (const meter of this.activation.meters)
						meter.id = 0;

				this.showChangeTemplateButton = !this.activation.type.startsWith(AppConstants.offlinePrefix);
			} else {
				this.showChangeTemplateButton = ValidationTools.checkAccess(this.authUser, 'change-key-template')
					&& !this.activation.type.startsWith(AppConstants.offlinePrefix);
			}

			await this.initForm();

		} else {
			this.validChangeTemplates = this.validNewTemplates;

			// create an empty key
			this.activation = new Models.LPActivation(0, '', '', 0, '', '', '', 1, 0, null,
				null, null, null, null, null, null, null, null, null, '', this.orgIDParam);
			this.activation.enabled = 1;

			this.showChangeTemplateButton = true;

			this.editMode = 'setup';
			this.loading = false;

			if (this.templateIDParam && this.templateIDParam !== 0)
				await this.onNewKeyTemplateSelection(this.templateIDParam);
		}
	}

	// *************************************************************************************
	async onNewKeyTemplateSelection(templateID: number) {
		this.loading = true;
		this.activation.zcp_template_id = templateID;
		this.editMode = 'new';
		await this.initForm();
	}

	// *************************************************************************************
	async enterChangeTemplateMode() {
		this.loading = true;

		if (this.validChangeTemplates.length === 0) {
			this.uiAlertsService.addMsg('You don\'t have access to any other templates with the same key product.',
				'error', 'no-templates', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} else {
			this.editMode = 'template';
		}
		this.loading = false;
	}

	// *************************************************************************************
	async onChangeTemplateSelection(templateID: number) {
		this.loading = true;
		this.activation.zcp_template_id = templateID;
		if (this.activation.id === 0)
			this.editMode = 'new';
		else
			this.editMode = 'edit';
		await this.initForm();
	}

	// *************************************************************************************
	async onChangeTemplateCancel() {
		this.loading = true;
		if (this.activation.id === 0)
			this.editMode = 'new';
		else
			this.editMode = 'edit';
		await this.initForm();
	}

	// *************************************************************************************
	async initForm() {
		this.template = await this.licensingAdminService.getTemplate(this.activation.zcp_template_id);
		if (!this.template || this.template.id === 0) {
			this.uiAlertsService.addMsg('Cannot find this template (' + this.activation.zcp_template_id + ').'
				+ ' Keys cannot be added or edited without a template.',
				'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		}

		if (this.editMode === 'new') {
			this.activation.product = this.template.product;
		} else if (this.template.product !== this.activation.product) {
			this.uiAlertsService.addMsg('The template\'s product (' + this.template.product
				+ ') is not the same as the key\'s product(' + this.activation.product + ').  It cannot be edited.',
				'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		}

		// get the product
		this.product = await this.licensingAdminService.getProductByName(this.activation.product);
		if (!this.product) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		this.protocolReady = this.product.show_protocols > 0;

		// get the properties for this product
		this.baseProductProperties = await this.licensingAdminService.getProductProperties(false, this.product.id);

		if (this.editMode !== 'new') {
			let org: Models.Organization;
			if (this.activation.zcp_org_id && this.activation.zcp_org_id !== 0)
				org = this.organizationsService.getOne(this.activation.zcp_org_id);
			this.warnings = LicenseValidationTools.checkActivation(this.activation, this.template, this.product, this.baseProductProperties, org, false, this.editMode === 'edit');
			if (this.warnings.length > 0)
				this.uiAlertsService.addMsg('\u2022 ' + this.warnings.join('\n\u2022 '), 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			// if (this.warnings.length > 0)
			// this.uiAlertsService.addMsg('This key does not currently comply with the template.  See the \'Warnings\' tab for more details,',
			// 	'warning', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} // if

		// **********************************************************************
		// General Mins/Maxs
		// **********************************************************************
		if (this.editMode === 'new' && this.template.settings.defaultActivations > 0
			&& (!this.activation.max || this.activation.max === 0))
			this.activation.max = this.template.settings.defaultActivations;

		let minActivations = 1;
		if (this.template.settings.minActivations > 1) minActivations = this.template.settings.minActivations;

		let maxActivations = AppConstants.maxActivationMax;
		if (this.template.settings.maxActivations > 0) maxActivations = this.template.settings.maxActivations;
		this.numActivationsInfo = 'Maximum number of devices that can be activated using this key.'
			+ ' Minumum is ' + minActivations + '.  Maximum is ' + maxActivations + '.';

		let maxKeysToCreate = AppConstants.maxKeysToCreate;
		if (this.template.settings.maxKeys > 0) maxKeysToCreate = this.template.settings.maxKeys;
		this.numKeysInfo = 'Number of unique keys to generate. Generating more than 1 key at a time is not common.  Maximum is ' + maxKeysToCreate;

		// **********************************************************************
		// Commercial Type/Info
		// **********************************************************************
		if (this.editMode === 'new' && this.template.settings.defaultCommercialType && this.template.settings.defaultCommercialType !== '')
			this.activation.commercial_type = this.template.settings.defaultCommercialType;

		if (this.editMode === 'new' && this.template.settings.defaultCommercialInfo && this.template.settings.defaultCommercialInfo !== '')
			this.activation.commercial_info = this.template.settings.defaultCommercialInfo;

		// **********************************************************************
		// Deal with product properties both from the baseline and template
		// **********************************************************************
		// parse the props
		this.keyProperties = SharedLicenseTools.parseRubyHash(this.activation.parameters);

		const allProperties: Models.LicenseProperty[] = await this.licensingAdminService.getProperties();

		// attach product and property to the pp objects if they aren't there
		for (const pp of this.template.settings.productProperties) {
			if (!pp.product) pp.product = this.product;
			if (!pp.property) {
				const idx = MiscTools.findIndex(allProperties, pp.property_id);
				if (idx !== -1)
					pp.property = allProperties[idx];
				else
					console.log('cannot find property ' + pp.property_id);
			} // if
		} // for

		this.template.settings.productProperties.sort((a, b) => (a.property.sort_order > b.property.sort_order
			|| (a.property.sort_order === b.property.sort_order && a.property.label > b.property.label)) ? 1 : -1);

		if (this.editMode === 'new' && this.id === 0) {
			// prime up all the properties/limits with default values from
			// the products's based props
			for (const pp of this.baseProductProperties) {
				if (pp.property.ptype === 'boolean') {
					this.keyProperties[pp.property.name] = pp.default_value_num;
				} else if (pp.property.ptype === 'number') {
					if (pp.default_value_text === 'unlimited' && pp.property.allow_unlimited === 1)
						this.keyProperties[pp.property.name] = pp.default_value_text;
					else
						this.keyProperties[pp.property.name] = pp.default_value_num;
				} else if (pp.property.ptype === 'other') {
					this.keyProperties[pp.property.name] = pp.default_value_text;
				} // if
			} // for

			// now do a pass with the props attached to the template
			for (const pp of this.template.settings.productProperties) {
				if (pp.property.ptype === 'boolean') {
					this.keyProperties[pp.property.name] = pp.default_value_num;
				} else if (pp.property.ptype === 'number') {
					if (pp.default_value_text === 'unlimited' && pp.property.allow_unlimited === 1)
						this.keyProperties[pp.property.name] = pp.default_value_text;
					else
						this.keyProperties[pp.property.name] = pp.default_value_num;
				} else if (pp.property.ptype === 'other') {
					this.keyProperties[pp.property.name] = pp.default_value_text;
				} // if
			} // for
		} //if

		// **********************************************************************
		// Key Type selections
		// **********************************************************************
		this.keyTypeSelections = [];
		for (const keyType of this.template.settings.keyTypes)
			this.keyTypeSelections.push({ value: keyType, label: TextTools.capitalizeFirstLetter(keyType) });

		if (this.editMode === 'edit' && this.activation.type !== '' && !this.template.settings.keyTypes.includes(this.activation.type))
			this.uiAlertsService.addMsg('This template does not support this key\'s type (' + this.activation.type + ').'
				+ ' You must select another type.', 'error', 'keytype', false);

		if (this.activation.type !== '' && !this.template.settings.keyTypes.includes(this.activation.type))
			this.activation.type = '';

		if ((!this.activation.type || this.activation.type === '')
			&& this.template.settings.defaultKeyType && this.template.settings.defaultKeyType !== ''
			&& this.template.settings.keyTypes.includes(this.template.settings.defaultKeyType))
			this.activation.type = this.template.settings.defaultKeyType;

		// if (this.activation.type === '' && this.keyTypeSelections.length > 0)
		// 	this.activation.type = this.keyTypeSelections[0].value;

		// **********************************************************************
		// Selectable Organizations
		// **********************************************************************
		const allOrgs = this.organizationsService.getAll();
		this.orgSelections = [];
		for (const org of allOrgs) {
			let goodOrg = LicenseValidationTools.isOrganizationOkForTemplate(this.template, org);
			if (!goodOrg && this.activation.zcp_org_id && this.activation.zcp_org_id === org.id) {
				this.uiAlertsService.addMsg('This template does not allow for the Organization that\'s currently linked to this key.'
					+ ' You must select a different Organization or a different Template.', 'error', 'keytype', false);
				goodOrg = true;
			} // if
			if (goodOrg) {
				let name = org.name + ' [' + org.otype + '] - ';
				if (org.salesforce_account_owner && org.salesforce_account_owner !== '')
					name += org.salesforce_account_owner;
				else
					name += 'Unknown';

				if (org['salesforce_se'] && org['salesforce_se'] !== '')
					name += ' + ' + org['salesforce_se'];

				if (org.is_enabled === 0) name += ' (disabled)';

				this.orgSelections.push({
					id: org.id,
					name: name
				});
			}
		}

		// can't proceed without at least one Organization to choose
		if (this.template.settings.requiresOrganization && this.orgSelections.length === 0) {
			this.uiAlertsService.addMsg('This template does not resolve to any Organizations.  It cannot be used.',
				'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		} else if (this.orgSelections.length === 1 && (!this.activation.zcp_org_id || this.activation.zcp_org_id === 0)) {
			// if there's only one and this key doesn't have one (new or old), pick the one
			this.activation.zcp_org_id = this.orgSelections[0].id;
		}

		// if ((org.is_enabled === 1 && org.salesforce_account_owner
		// 	&& org.salesforce_account_owner !== ''
		// 	&& !ValidationTools.hasFlag(org, 'no_keys'))
		// 	|| this.activation.zcp_org_id === org.id)

		// **********************************************************************
		// Selectable Opportunities
		// **********************************************************************
		// let sfOpps: any[] = [];
		this.sfOpps = [];
		this.loadingOpps = true;
		this.showFetchAllOppsButton = !this.template.settings.salesforceOpportunityMustMatch;
		if (this.activation.zcp_org_id && this.activation.zcp_org_id !== 0) {
			this.sfOpps = await this.organizationsService.fetchOrganizationOpportunities(
				this.activation.zcp_org_id, this.activation.opportunity_id);
		} // if
		this.setupOppSelections();

		// org users...
		this.allUsers = this.usersService.getAll();
		if (this.activation.zcp_org_id && this.activation.zcp_org_id !== 0)
			this.setupOrgUserSelections(this.activation.zcp_org_id);

		// **********************************************************************
		// Expiry Modes
		// **********************************************************************
		if (!this.template.settings.expiryModes || this.template.settings.expiryModes.length === 0) {
			this.uiAlertsService.addMsg('This template does not have any expiry modes.  It cannot be used.',
				'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.onCancel();
			return;
		}

		this.expireModeSelections = [];
		for (const expireMode of AppConstants.keyExpiryModes) {
			if (this.template.settings.expiryModes.includes(expireMode.value)) {
				this.expireModeSelections.push({ value: expireMode.value, label: expireMode.label });
			}
		}

		if (this.id === 0)
			if (this.template.settings.defaultExpiryMode && this.template.settings.defaultExpiryMode !== '')
				this.expireMode = this.template.settings.defaultExpiryMode;
			else
				this.expireMode = this.expireModeSelections[0].value;
		else
			this.expireMode = SharedLicenseTools.getExpiryMode(this.activation);

		if (this.expireMode === '') this.expireMode = this.template.settings.expiryModes[0];

		// Deal with the case where the expiry mode for a legacy key isn't available
		// with this template
		if (!this.template.settings.expiryModes.includes(this.expireMode)) {
			this.uiAlertsService.addMsg('The expiry mode of this key (' + this.expireMode + ') is not available with this template.  You must select one that it.',
				'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		// dateIntervalAdd(startDate: Date, amount: number, unit = 'day') {
		if (this.editMode === 'new' && this.template.settings.defaultExpirationCount
			&& this.template.settings.defaultExpirationCount > 0
			&& this.template.settings.defaultExpirationUnit
			&& this.template.settings.defaultExpirationUnit !== '')
			this.defaultExpiryDate = MiscTools.dateIntervalAdd(new Date(),
				this.template.settings.defaultExpirationCount, this.template.settings.defaultExpirationUnit);
		else
			this.defaultExpiryDate = null;

		this.maxExpiryDate = null;
		if (this.template.settings.maxExpirationCount
			&& this.template.settings.maxExpirationCount > 0
			&& this.template.settings.maxExpirationUnit
			&& this.template.settings.maxExpirationUnit !== '')
			if (this.editMode === 'new')
				this.maxExpiryDate = MiscTools.dateIntervalAdd(new Date(),
					this.template.settings.maxExpirationCount, this.template.settings.maxExpirationUnit);
			else
				this.maxExpiryDate = MiscTools.dateIntervalAdd(this.activation.created_at,
					this.template.settings.maxExpirationCount, this.template.settings.maxExpirationUnit);

		this.maxExpiryStruct = null;
		if (this.maxExpiryDate)
			this.maxExpiryStruct = { day: this.maxExpiryDate.getUTCDate(), month: this.maxExpiryDate.getUTCMonth() + 1, year: this.maxExpiryDate.getUTCFullYear() };

		if (this.editMode === 'new' && this.defaultExpiryDate && !this.activation.expires_at
			&& this.template.settings.expiryModes.includes('date'))
			this.activation.expires_at = this.defaultExpiryDate;

		// **********************************************************************
		// Meters
		// **********************************************************************
		this.meterIDs = [];

		if (this.editMode === 'new' && this.activation.meters.length > 0 && !this.template.settings.expiryModes.includes('meter'))
			this.activation.meters = [];

		this.showMeters = (this.template.settings.expiryModes.includes('meter') || this.activation.meters.length > 0);

		if (this.showMeters) {
			this.meterProducts = [];

			if (this.template.settings.limitMeterProducts && this.template.settings.limitMeterProducts.length > 0) {
				for (const mp of this.template.settings.limitMeterProducts)
					if (AppConstants.meterTypeObjects[mp])
						this.meterProducts.push({
							value: mp,
							label: AppConstants.meterTypeObjects[mp].label + ' (' + mp + ')'
						});
			} else {
				for (const mp of this.product.meterProductsArr)
					if (AppConstants.meterTypeObjects[mp])
						this.meterProducts.push({
							value: mp,
							label: AppConstants.meterTypeObjects[mp].label + ' (' + mp + ')'
						});
			}

			this.meterResetOptions = [];
			if (this.template.settings.limitMeterReset && this.template.settings.limitMeterReset.length > 0) {
				for (const mr of this.template.settings.limitMeterReset)
					this.meterResetOptions.push({
						value: mr,
						label: MiscTools.fetchLabel(AppConstants.meterResetOptions, mr)
					});
			} else {
				this.meterResetOptions = AppConstants.meterResetOptions;
			}
		} // if

		// **********************************************************************
		// Last stuff before building the form's elements
		// **********************************************************************
		for (const pp of this.template.settings.productProperties)
			if (pp.can_be_changed === 1 && pp.property.special_property === 1)
				this.anySpecial = true;

		this.booleanProps = this.getProductPropertiesByType('boolean', true);
		this.otherProps = this.getProductPropertiesByType('other', true);
		this.numberProps = this.getProductPropertiesByType('number', true);

		this.commercialTypeSelections = [];
		this.commercialTypeSelections.push({
			value: '',
			label: 'Not configured',
			short_label: 'Not configured',
			acronym: '',
			info: ''
		});
		for (const keyCommercialType of AppConstants.keyCommercialTypes)
			this.commercialTypeSelections.push(keyCommercialType);

		// **********************************************************************
		// Building the form's elements
		// **********************************************************************
		let expiresAtStruct: NgbDateStruct = null;
		if (this.activation.expires_at) {
			const expiresAtDate = new Date(this.activation.expires_at);
			expiresAtStruct = { day: expiresAtDate.getUTCDate(), month: expiresAtDate.getUTCMonth() + 1, year: expiresAtDate.getUTCFullYear() };
		}

		// handle weird case with JVC keys
		if (this.activation.duration && this.activation.duration === -1)
			this.durationOptions = AppConstants.keyDurationOptionsExtras;

		// set as null so the ngbselect isn't preset
		if (+this.activation.zcp_org_id === 0) this.activation.zcp_org_id = null;


		const numKeys = 1; // at some point, this could come from template...
		this.theKeyForm = new UntypedFormGroup({
			type: new UntypedFormControl(this.activation.type, [Validators.required]),
			numKeys: new UntypedFormControl(numKeys, [Validators.min(1), Validators.max(maxKeysToCreate)]),
			info: new UntypedFormControl(this.activation.info, [Validators.required]),
			notes: new UntypedFormControl(this.activation.notes),
			max: new UntypedFormControl(this.activation.max, [Validators.required, Validators.min(1), Validators.max(maxActivations)]),
			expireMode: new UntypedFormControl(this.expireMode),
			expires_at: new UntypedFormControl(expiresAtStruct),
			duration: new UntypedFormControl(this.activation.duration),
			enabled: new UntypedFormControl(this.activation.enabled),
			remember_org: new UntypedFormControl(this.rememberOrg),
			share_self: new UntypedFormControl(this.template.settings.defaultShareWithSelf),
			share_users: new UntypedFormControl([]),
			share_user_check_list: new UntypedFormControl(null),
			share_label: new UntypedFormControl(),
			share_notify: new UntypedFormControl(false),
			check_all_boolean: new UntypedFormControl(false),
			check_all_limits: new UntypedFormControl(false),
			commercial_type: new UntypedFormControl(this.activation.commercial_type),
			commercial_info: new UntypedFormControl(this.activation.commercial_info),
			ext_label: new UntypedFormControl(this.activation.ext_label),
			ext_notes: new UntypedFormControl(this.activation.ext_notes),
		});

		if (this.template.settings.requiresSalesforceOpportunity)
			this.theKeyForm.addControl('opportunity_id', new UntypedFormControl(this.activation.opportunity_id, [Validators.required]));
		else
			this.theKeyForm.addControl('opportunity_id', new UntypedFormControl(this.activation.opportunity_id));

		if (this.activation.opportunity_id && this.activation.opportunity_id !== '')
			this.onOpportunityChange();

		if (this.template.settings.requiresOrganization)
			this.theKeyForm.addControl('zcp_org_id', new UntypedFormControl(this.activation.zcp_org_id, [Validators.required]));
		else
			this.theKeyForm.addControl('zcp_org_id', new UntypedFormControl(this.activation.zcp_org_id));

		this.onExpireModeChange(null, this.expireMode);

		// set up the elements related to product properties
		this.numBooleanChecked = 0;
		for (const pp of this.booleanProps) {
			let val = false;
			if (this.keyProperties[pp.property.name]) val = +this.keyProperties[pp.property.name] === 1;
			if (val) this.numBooleanChecked++;
			this.theKeyForm.addControl(pp.property.name, new UntypedFormControl(val));
		} // for

		for (const pp of this.otherProps) {
			let vals: string[] = [];
			if (this.keyProperties[pp.property.name])
				vals = this.keyProperties[pp.property.name].split(',');

			this.otherPropSelections[pp.property.name] = pp.selections.split(',');
			let i = 0;
			for (const otherPropSelection of this.otherPropSelections[pp.property.name]) {
				const val = vals.includes(otherPropSelection);
				this.theKeyForm.addControl(pp.property.name + i, new UntypedFormControl(val));
				i++;
			} // for
		} // for

		this.numUnlimited = 0;
		this.numUnlimitedChecked = 0;
		for (const pp of this.numberProps) {
			let formValue = null;
			let unlimitedChecked = false;
			if (this.keyProperties[pp.property.name]) {
				if (pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1) {
					if (this.keyProperties[pp.property.name] === 'unlimited') {
						unlimitedChecked = true;
						this.numUnlimitedChecked++;
					} else if (+this.keyProperties[pp.property.name] > 0) {
						formValue = +this.keyProperties[pp.property.name];
					}
				} else {
					if (+this.keyProperties[pp.property.name] > 0)
						formValue = +this.keyProperties[pp.property.name];
				} // if
			} // if

			this.theKeyForm.addControl(pp.property.name, new UntypedFormControl(formValue, Validators.max(AppConstants.maxPropertyField)));
			if (pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1) {
				this.numUnlimited++;
				this.theKeyForm.addControl(pp.property.name + '_unlimited', new UntypedFormControl(unlimitedChecked));
				if (unlimitedChecked) this.theKeyForm.controls[pp.property.name].disable();
			} // if
		} // for

		// add form elements for the meters
		let counter = 0;
		for (const meter of this.activation.meters) {
			const id = this.newMeterID();
			this.meterIDs.push(id);
			this.addMeterFormElements(meter, id);
			if (!meter.label || meter.label === '')
				this.anyMetersWithoutLabels = true;
			counter++;
		} // for

		// add a meter if the initial (or only) expireMode is meter
		if (this.editMode === 'new' && this.expireMode === 'meter' && (!this.activation.meters || this.activation.meters.length === 0)) {
			if (this.template.settings.requiredMeterProducts && this.template.settings.requiredMeterProducts.length !== 0) {
				for (const requiredMeterProduct of this.template.settings.requiredMeterProducts)
					this.addMeter(requiredMeterProduct);
			} else {
				this.addMeter();
			}
		}

		for (const pset of this.linkedProtocolSets) {
			this.addProtocolSetToForm(pset);
		}

		this.loading = false;
	}

	// *************************************************************************************
	async onSubmit() {
		this.saving = true;
		this.errors = [];
		this.warnings = [];

		this.rememberOrg = this.theKeyForm.value.remember_org;
		if (this.rememberOrg)
			localStorage.setItem('cp-keyForm.rememberOrg', 'yes');
		else
			localStorage.setItem('cp-keyForm.rememberOrg', 'no');

		let maxKeysToCreate = AppConstants.maxKeysToCreate;
		if (this.template.settings.maxKeys > 0) maxKeysToCreate = this.template.settings.maxKeys;

		let numKeys = +this.theKeyForm.value.numKeys;
		if (!numKeys || isNaN(numKeys) || numKeys <= 0) numKeys = 1;

		if (numKeys > maxKeysToCreate)
			this.errors.push('This template does not let you create that many keys.  The maximum is ' + maxKeysToCreate + '.');

		if (this.activation.id !== 0) numKeys = 1;

		this.activation.type = this.theKeyForm.value.type;
		this.activation.zcp_org_id = +this.theKeyForm.value.zcp_org_id;
		this.activation.info = this.theKeyForm.value.info;
		this.activation.notes = this.theKeyForm.value.notes;
		this.activation.max = +this.theKeyForm.value.max;
		this.activation.opportunity_id = this.theKeyForm.value.opportunity_id;
		this.activation.enabled = +this.theKeyForm.value.enabled;

		this.activation.commercial_type = this.theKeyForm.value.commercial_type;
		if (this.activation.commercial_type == null) this.activation.commercial_type = '';
		this.activation.commercial_info = this.theKeyForm.value.commercial_info;
		if (this.activation.commercial_info == null) this.activation.commercial_info = '';

		this.activation.ext_label = this.theKeyForm.value.ext_label;
		if (this.activation.ext_label == null) this.activation.ext_label = '';
		this.activation.ext_notes = this.theKeyForm.value.ext_notes;
		if (this.activation.ext_notes == null) this.activation.ext_notes = '';

		if (this.originalType !== '') {
			if (this.originalType.startsWith(AppConstants.offlinePrefix) && !this.activation.type.startsWith(AppConstants.offlinePrefix))
				this.errors.push('Keys cannot be changed from an offline to type to a non-offline type.');

			if (!this.originalType.startsWith(AppConstants.offlinePrefix) && this.activation.type.startsWith(AppConstants.offlinePrefix))
				this.errors.push('Keys cannot be changed from a non-offline to type to an offlinee type.');
		} // if

		const expireMode = this.theKeyForm.value.expireMode;
		if (expireMode === 'meter' || this.activation.meters.length > 0) {
			this.activation.duration = 0;
			this.activation.expires_at = null;
			if (this.activation.meters.length === 0)
				this.errors.push('An expiry mode of \'Meter Expiration\' requires at least one Meter.');
		} else if (expireMode === 'date') {
			let expiresDate: Date = null;
			const expiresStruct: NgbDateStruct = this.theKeyForm.value.expires_at;
			if (expiresStruct) {
				expiresDate = new Date(expiresStruct.year + '/' + expiresStruct.month + '/' + expiresStruct.day + ' 00:00:00 UTC');
				if (isNaN(expiresDate.getTime())) expiresDate = null;
				if (expiresDate) {
					this.activation.duration = null;
					this.activation.expires_at = expiresDate;
				}
			} else {
				this.errors.push('Invalid fixed expiration date.');
			}
		} else if (expireMode === 'duration') {
			if (this.theKeyForm.value.duration) {
				this.activation.duration = +this.theKeyForm.value.duration;
				this.activation.expires_at = null;
			} else {
				this.activation.duration = null;
			}
			if (!this.activation.duration || isNaN(this.activation.duration) || this.activation.duration === 0)
				this.errors.push('Invalid duration.');

		} else if (expireMode === 'never') {
			this.activation.duration = 0;
			this.activation.expires_at = null;
		}

		for (const pp of this.booleanProps) {
			const val = +this.theKeyForm.value[pp.property.name];
			if (val && !isNaN(val) && val === 1)
				this.keyProperties[pp.property.name] = 1;
			else
				this.keyProperties[pp.property.name] = 0;
		}

		for (const pp of this.otherProps) {
			const vals: string[] = [];
			let i = 0;
			for (const otherPropSelection of this.otherPropSelections[pp.property.name]) {
				if (this.theKeyForm.value[pp.property.name + i])
					vals.push(otherPropSelection);
				i++;
			}
			this.keyProperties[pp.property.name] = vals.join(',');
		}

		for (const pp of this.numberProps) {
			const formValue = +this.theKeyForm.value[pp.property.name];
			const unlimitedChecked = +this.theKeyForm.value[pp.property.name + '_unlimited'];
			let saveValue = null;
			if (pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1) {
				if (unlimitedChecked && !isNaN(unlimitedChecked) && unlimitedChecked === 1)
					saveValue = 'unlimited';
				else if (formValue && !isNaN(formValue) && formValue >= 0)
					saveValue = formValue;
				else
					saveValue = '';
			} else {
				if (formValue && !isNaN(formValue) && formValue >= 0)
					saveValue = formValue;
				else
					saveValue = '';
			}

			if (!saveValue)
				this.keyProperties[pp.property.name] = '';
			else
				this.keyProperties[pp.property.name] = saveValue;
		}

		this.activation.parameters = SharedLicenseTools.encodeRubyHash(this.baseProductProperties, this.keyProperties);

		let counter = 0;
		for (const meter of this.activation.meters) {
			const id = this.meterIDs[counter];
			if (this.editMode === 'new') meter.id = 0;

			let meterStarts: Date = null;
			let meterExpires: Date = null;
			const meterStartStruct: NgbDateStruct = this.theKeyForm.value['meter_starts_at' + id];
			const meterExpiresStruct: NgbDateStruct = this.theKeyForm.value['meter_expires_at' + id];
			if (meterStartStruct) {
				meterStarts = new Date(meterStartStruct.year + '/' + meterStartStruct.month + '/' + meterStartStruct.day + ' 00:00:00 UTC');
				if (isNaN(meterStarts.getTime())) meterStarts = null;
			}
			if (meterExpiresStruct) {
				meterExpires = new Date(meterExpiresStruct.year + '/' + meterExpiresStruct.month + '/' + meterExpiresStruct.day + ' 00:00:00 UTC');
				if (isNaN(meterExpires.getTime())) meterExpires = null;
			}

			// meter.enabled = +this.theKeyForm.value['meter_enabled' + counter];
			meter.label = this.theKeyForm.value['meter_label' + id];
			meter.product = this.theKeyForm.value['meter_product' + id];
			meter.starts_at = meterStarts;
			meter.expires_at = meterExpires;
			meter.limit = +this.theKeyForm.value['meter_limit' + id];
			meter.projected = this.theKeyForm.value['meter_projected' + id];
			meter.resets = this.theKeyForm.value['meter_resets' + id];

			counter++;
		}

		let org: Models.Organization;
		if (this.activation.zcp_org_id && this.activation.zcp_org_id !== 0) {
			org = this.organizationsService.getOne(this.activation.zcp_org_id);
			if (this.rememberOrg)
				localStorage.setItem('cp-keyForm.lastOrg', this.activation.zcp_org_id.toString());
			else
				localStorage.setItem('cp-keyForm.lastOrg', '');
		}

		this.activation.customer = SharedLicenseTools.makeSafeCustomer(this.activation.customer, this.activation.info, org);

		// don't send parsed props to back-end, just send parameters
		this.activation.parsedParameters = null;

		// skip user check
		const keyErrors = LicenseValidationTools.checkActivation(this.activation, this.template, this.product, this.baseProductProperties, org, true, this.editMode === 'edit');

		for (const error of keyErrors)
			this.errors.push(error);

		// put on the brakes...
		// this.errors.push('TBD');
		if (this.editMode === 'new' && this.template.settings.requiresLinkedUsers && this.activation.enabled === 1) {
			const shareSelf: boolean = this.theKeyForm.value.share_self;
			let shareUsers: number[] = this.theKeyForm.value.share_users;
			if ((!shareUsers || shareUsers.length === 0) && this.selectedUserIds.length > 0)
				shareUsers = this.selectedUserIds;

			if (shareUsers.length === 0 && !shareSelf)
				this.errors.push('The key must be shared with at least one user.');
		} // if

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		// if (this.errors.length > 0) {
		// 	this.uiAlertsService.addMsg('Problems were found with this key.  See the \'Errors\' tab for more details,',
		// 		'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
		// 	this.saving = false;
		// 	return;
		// }

		// add warning if any expiries fall on "special" days of the week
		if (this.template.settings.warningDaysOfWeek && this.template.settings.warningDaysOfWeek.length > 0) {
			// 0 for Sunday, 1 for Monday, 2 for Tuesday
			if (expireMode === 'date') {
				if (this.activation.expires_at && !isNaN(this.activation.expires_at.getTime())) {
					const day = AppConstants.daysOfWeek[this.activation.expires_at.getUTCDay()];
					if (this.template.settings.warningDaysOfWeek.includes(day))
						this.uiAlertsService.addMsg('FYI - This key expires on a ' + day + '.', 'warning', 'dow', false);
				}
			} else if (expireMode === 'meter') {
				let counter = 0;
				for (const meter of this.activation.meters) {
					counter++;
					if (meter.expires_at && !isNaN(meter.expires_at.getTime())) {
						const day = AppConstants.daysOfWeek[meter.expires_at.getUTCDay()];
						if (this.template.settings.warningDaysOfWeek.includes(day))
							this.uiAlertsService.addMsg('FYI - Meter #' + counter + ' expires on a ' + day + '.', 'warning', 'dow', false);
					}
				}
			}
		}

		if (numKeys > 1 && !confirm('Are you sure you want to create ' + numKeys + ' new unique keys?')) {
			this.saving = false;
			return;
		}

		try {
			if (this.editMode === 'edit') {
				const returnedActivation = await this.licensingService.updateActivation(this.activation);

				for (const pSet of this.linkedProtocolSetInForm) {
					let projected: number = +this.theKeyForm.value['pset_projected_' + pSet.id];
					if (isNaN(projected)) projected = 0;
					await this.licensingService.addActivationProtocolSet(returnedActivation.id, pSet.id, '', projected);
				} // for

				// get a fresh copy of linked sets...
				const linkedSets: Models.ActivationProtocolSet[] = await this.licensingService.getActivationProtocolSets(returnedActivation.id);
				// delete any that weren't in the form...
				for (const lSet of linkedSets) {
					const idx = MiscTools.findIndex(this.linkedProtocolSetInForm, lSet.set_id);
					if (idx === -1)
						await this.licensingService.deleteActivationProtocolSet(returnedActivation.id, lSet.set_id, lSet.data_type)
				} // for

				this.onCancel(); // go back to the key's page
			} else {
				const ids: number[] = await this.licensingService.addActivations(this.activation, numKeys);
				for (const id of ids) {
					for (const pSet of this.linkedProtocolSetInForm) {
						let projected = +this.theKeyForm.value['pset_projected_' + pSet.id];
						if (isNaN(projected)) projected = 0;
						await this.licensingService.addActivationProtocolSet(id, pSet.id, '', projected);
					} // for
				} // for

				const shareSelf: boolean = this.theKeyForm.value.share_self;
				const shareNotify: boolean = this.theKeyForm.value.share_notify;
				const shareLabel: string = this.theKeyForm.value.share_label;

				let shareUsers: number[] = this.theKeyForm.value.share_users;
				if ((!shareUsers || shareUsers.length === 0) && this.selectedUserIds.length > 0)
					shareUsers = this.selectedUserIds;

				if (shareSelf || (shareUsers && shareUsers.length > 0)) {
					// if the key(s) need to be shared, get them
					const newKeysToShare: any[] = [];
					let counter = 0;
					for (const newID of ids) {
						counter++;
						const newKey: Models.LPActivation = await this.licensingService.getOne(newID);
						if (newKey) {
							let labelToUse = '';
							if (shareLabel) labelToUse = shareLabel;
							if (ids.length > 1) labelToUse += ' #' + counter;
							newKeysToShare.push({
								key: newKey.key,
								label: labelToUse.trim()
							});
						}
					} // for

					if (newKeysToShare.length !== 0) {
						if (shareSelf) {
							for (const newKeyToShare of newKeysToShare) {
								await this.myKeysService.registerKey(newKeyToShare.key, newKeyToShare.label, false);
							} // for
							await this.myKeysService.getUserKeys(false, true);
						} // if

						if (shareUsers && shareUsers.length !== 0)
							for (const userID of shareUsers)
								await this.usersService.registerKeys(userID, newKeysToShare, shareNotify, 'zcp-staff-from-key-add');
					} // if
				} // if

				// if no keys...???
				if (!ids || ids.length === 0) {
					this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

				} else if (ids.length === 1) { // if one key, set ID then call onCancel...
					this.id = ids[0];
					this.onCancel();

				} else { // if multiple keys, set message and then route to the Organization's page...
					this.uiAlertsService.addMsg(ids.length + ' Keys Added.', 'info', 'add_keys', false, AppConstants.standardMessageAutoCloseTimeSecs);
					this.router.navigate(['/' + AppConstants.urls.organizations, this.activation.zcp_org_id]);
				} // if
			}

			this.saving = false;
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// *************************************************************************************
	onCancel(forceList = false) {
		if (this.id && this.id !== 0 && !forceList)
			this.router.navigate(['/' + AppConstants.urls.licensing, 'activation', this.id]);
		else if (this.orgIDParam && this.orgIDParam !== 0)
			this.router.navigate(['/' + AppConstants.urls.organizations, this.orgIDParam]);
		else if (this.templateIDParam && this.templateIDParam !== 0)
			this.router.navigate(['/' + AppConstants.urls.licensingadmin, 'templates', this.templateIDParam]);
		else
			this.router.navigate(['/' + AppConstants.urls.licensing]);
	}

	// *************************************************************************************
	getProductPropertiesByType(type: string, onlyEditable: boolean = false) {
		const sublist: Models.LicenseProductProperty[] = [];
		for (const pp of this.template.settings.productProperties)
			if ((!onlyEditable || pp.can_be_changed === 1) && pp.property.ptype === type)
				sublist.push(pp);
		return sublist;
	}

	// *************************************************************************************
	addMeterFormElements(meter: Models.LPMeter, id: string) {
		let meterStartsAt: NgbDateStruct = null;
		if (meter.starts_at) {
			const startsAtDate = new Date(meter.starts_at);
			meterStartsAt = { day: startsAtDate.getUTCDate(), month: startsAtDate.getUTCMonth() + 1, year: startsAtDate.getUTCFullYear() };
		}

		let meterExpiresAt: NgbDateStruct = null;
		if (meter.expires_at) {
			const expiresAtDate = new Date(meter.expires_at);
			meterExpiresAt = { day: expiresAtDate.getUTCDate(), month: expiresAtDate.getUTCMonth() + 1, year: expiresAtDate.getUTCFullYear() };
		}

		// this.theKeyForm.addControl('meter_enabled' + counter, new FormControl(meter.enabled === 1));
		this.theKeyForm.addControl('meter_product' + id, new UntypedFormControl(meter.product, [Validators.required]));
		if (this.template.settings.requiresMeterLabels)
			this.theKeyForm.addControl('meter_label' + id, new UntypedFormControl(meter.label, [Validators.required, Validators.maxLength(128)]));
		else
			this.theKeyForm.addControl('meter_label' + id, new UntypedFormControl(meter.label, [Validators.maxLength(128)]));

		this.theKeyForm.addControl('meter_starts_at' + id, new UntypedFormControl(meterStartsAt));
		this.theKeyForm.addControl('meter_expires_at' + id, new UntypedFormControl(meterExpiresAt, [Validators.required]));
		this.theKeyForm.addControl('meter_limit' + id, new UntypedFormControl(meter.limit,
			[Validators.required, Validators.min(1), Validators.max(AppConstants.maxMeterLimit)]));
		this.theKeyForm.addControl('meter_projected' + id, new UntypedFormControl(meter.projected, [Validators.max(AppConstants.maxMeterLimit)]));
		this.theKeyForm.addControl('meter_resets' + id, new UntypedFormControl(meter.resets, [Validators.required]));

		// this.theKeyForm.addControl('meter_calc_streams' + id, new FormControl(null));
		// this.theKeyForm.addControl('meter_calc_rate' + id, new FormControl(null));

		if (meter.limit && meter.limit !== 0)
			this.niceMeterLimits.push('[' + TextTools.formattedMB(meter.limit) + ']');
		else
			this.niceMeterLimits.push('');

		if (meter.projected && meter.projected !== 0)
			this.niceMeterProjecteds.push('[' + TextTools.formattedMB(meter.projected) + ']');
		else
			this.niceMeterProjecteds.push('');
	}

	// *************************************************************************************
	addMeter(meterProduct: string = '') {
		if (!meterProduct || meterProduct === '') meterProduct = this.meterProducts[0].value;

		const meter = new Models.LPMeter(0, meterProduct, 1, this.activation.id,
			null, null, 0, null, null, null, null, null, this.meterResetOptions[0].value, null, null, 0);

		// set start date to be yesterday
		meter.starts_at = new Date((new Date()).getTime() - (1000 * 60 * 60 * 24));

		if (this.defaultExpiryDate) {
			meter.expires_at = this.defaultExpiryDate;
		} else {
			let maxMeterDate: Date = null;
			for (const meter of this.activation.meters) {
				let mExp = new Date(meter.expires_at);
				if (isNaN(mExp.getTime()) || mExp.getTime() === 0) mExp = null;
				if (mExp && (maxMeterDate == null || mExp.getTime() > maxMeterDate.getTime()))
					maxMeterDate = mExp;
			} // for
			if (maxMeterDate != null) {
				meter.expires_at = maxMeterDate;
				this.uiAlertsService.addMsg('Meter added.  Setting expiration to latest expiration from other meter(s)(' + TextTools.formatDateUTC(maxMeterDate) + ').',
					'info', 'meter-add', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} // if
		} // if

		if (this.template.settings.defaultMeterLimit && this.template.settings.defaultMeterLimit > 0)
			meter.limit = this.template.settings.defaultMeterLimit;

		if (this.template.settings.defaultMeterLabel && this.template.settings.defaultMeterLabel !== '')
			meter.label = this.template.settings.defaultMeterLabel;

		const id = this.newMeterID();
		this.addMeterFormElements(meter, id);
		this.meterIDs.push(id);
		this.activation.meters.push(meter);

		this.anyMetersWithoutLabels = true;

		const expireMode = this.theKeyForm.value.expireMode;
		if (expireMode !== 'meter') {
			// this.uiAlertsService.addMsg(
			// 	'Keys with one or more meters must use meter expiration. Setting Expiry Mode to \"Meter Expiration\"',
			// 	'info', 'force_meter_mode', true, AppConstants.standardMessageAutoCloseTimeSecs);
			this.theKeyForm.controls['expireMode' + ''].setValue('meter');
			this.onExpireModeChange(null, 'meter');
		}
	}

	// *************************************************************************************
	removeMeter(id: string) {
		if (confirm('Are you sure?')) {
			// console.log('pre' + this.activation.meters.length);
			const idx = this.meterIDs.indexOf(id);
			if (idx !== -1) {
				// remove from meters array
				this.activation.meters.splice(idx, 1);
				this.meterIDs.splice(idx, 1);
				this.niceMeterLimits.splice(idx, 1);

				// console.log('post' + this.activation.meters.length);

				// remove the form elements
				this.theKeyForm.removeControl('meter_product' + id);
				// this.theKeyForm.removeControl('meter_enabled' + id);
				this.theKeyForm.removeControl('meter_label' + id);
				this.theKeyForm.removeControl('meter_starts_at' + id);
				this.theKeyForm.removeControl('meter_expires_at' + id);
				this.theKeyForm.removeControl('meter_limit' + id);
				this.theKeyForm.removeControl('meter_projected' + id);
				this.theKeyForm.removeControl('meter_resets' + id);

				// this.theKeyForm.removeControl('meter_calc_streams' + id);
				// this.theKeyForm.removeControl('meter_calc_rate' + id);
			} // if
		}
	}

	// *************************************************************************************
	setMeterToMax(idx: number) {
		this.theKeyForm.controls['meter_limit' + this.meterIDs[idx]].setValue(AppConstants.maxMeterLimit);
		this.onMeterLimitChange(idx);
	}

	// *************************************************************************************
	setMeterLabels() {
		const commercialType: string = this.theKeyForm.value.commercial_type;
		const commTypeObj: any = MiscTools.pickItem(AppConstants.keyCommercialTypes, 'value', commercialType);

		if (commTypeObj) {
			let counter = 0;
			for (const meter of this.activation.meters) {
				const id = this.meterIDs[counter];
				const currentLabel: string = this.theKeyForm.value['meter_label' + id];
				if (!currentLabel || currentLabel === '') {
					let newLabel: string = '';
					if (commTypeObj.meterTypes && commTypeObj.meterTypes.includes(meter.product))
						newLabel = 'Used for billing';
					else if (!commTypeObj.meterTypes || commTypeObj.meterTypes.length === 0 || !commTypeObj.meterTypes.includes(meter.product))
						newLabel = 'Not used for billing';
					if (newLabel !== '') {
						this.theKeyForm.controls['meter_label' + id].setValue(newLabel);
					} // if
				} // if
				counter++;
			} // for
			this.anyMetersWithoutLabels = false;
		} // if
	}

	// *************************************************************************************
	setKeyExpiry(amount: number, unit = 'day') {
		let theDate: Date = MiscTools.dateFromNow(amount, unit);
		if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
			theDate = new Date(this.maxExpiryDate);

		if (theDate && !isNaN(theDate.getTime())) {
			const expiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
			this.theKeyForm.controls['expires_at' + ''].setValue(expiresAt);
			this.theKeyForm.controls['expireMode' + ''].setValue('date');
			this.onExpireModeChange(null, 'date');
		}
	}

	// *************************************************************************************
	increaseMaxActivations(amount: number) {
		const max = +this.theKeyForm.value.max;
		this.theKeyForm.controls['max'].setValue(+max + +amount);
	}

	// *************************************************************************************
	increaseKeyExpiry(amount: number, unit = 'day') {
		let expiresDate: Date = null;
		const expiresStruct: NgbDateStruct = this.theKeyForm.value.expires_at;
		if (expiresStruct) {
			expiresDate = new Date(expiresStruct.year + '/' + expiresStruct.month + '/' + expiresStruct.day + ' 00:00:00 UTC');
			if (isNaN(expiresDate.getTime())) expiresDate = null;
		}
		if (expiresDate === null) expiresDate = new Date();

		let theDate: Date = MiscTools.dateIntervalAdd(expiresDate, amount, unit);
		if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
			theDate = new Date(this.maxExpiryDate);

		if (theDate && !isNaN(theDate.getTime())) {
			const expiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
			this.theKeyForm.controls['expires_at' + ''].setValue(expiresAt);
			this.theKeyForm.controls['expireMode' + ''].setValue('date');
			this.onExpireModeChange(null, 'date');
		}
	}

	// *************************************************************************************
	setMeterExpiry(idx: number, amount: number, unit = 'day') {
		let theDate: Date = MiscTools.dateFromNow(amount, unit);
		if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
			theDate = new Date(this.maxExpiryDate);

		if (theDate && !isNaN(theDate.getTime())) {
			const meterExpiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
			this.theKeyForm.controls['meter_expires_at' + this.meterIDs[idx]].setValue(meterExpiresAt);
		}
	}

	// *************************************************************************************
	increaseMeterExpiry(idx: number, amount: number, unit = 'day') {
		let meterExpires: Date = null;
		const meterExpiresStruct: NgbDateStruct = this.theKeyForm.value['meter_expires_at' + this.meterIDs[idx]];
		if (meterExpiresStruct) {
			meterExpires = new Date(meterExpiresStruct.year + '/' + meterExpiresStruct.month + '/' + meterExpiresStruct.day + ' 00:00:00 UTC');
			if (isNaN(meterExpires.getTime())) meterExpires = null;
		}
		if (meterExpires === null) meterExpires = new Date();

		let theDate: Date = MiscTools.dateIntervalAdd(meterExpires, amount, unit);
		if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
			theDate = new Date(this.maxExpiryDate);

		if (theDate && !isNaN(theDate.getTime())) {
			const meterExpiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
			this.theKeyForm.controls['meter_expires_at' + this.meterIDs[idx]].setValue(meterExpiresAt);
		}
	}

	// *************************************************************************************
	onExpireModeChange(e, value: string) {
		this.theKeyForm.controls['expires_at' + ''].disable();
		this.theKeyForm.controls['duration' + ''].disable();
		this.theKeyForm.controls['expires_at' + ''].setValidators([]);
		this.theKeyForm.controls['duration' + ''].setValidators([]);

		if (value !== 'meter' && this.activation.meters.length > 0) {
			this.uiAlertsService.addMsg(
				'Keys with one or more meters must use meter expiration. Setting Expiry Mode to \"Meter Expiration\"',
				'info', 'force_meter_mode', true, AppConstants.standardMessageAutoCloseTimeSecs);
			this.theKeyForm.controls['expireMode' + ''].setValue('meter');
		} else {
			if (value === 'date') {
				this.theKeyForm.controls['expires_at' + ''].setValidators([Validators.required]);
				this.theKeyForm.controls['expires_at' + ''].enable();
			} else if (value === 'duration') {
				this.theKeyForm.controls['duration' + ''].setValidators([Validators.required]);
				this.theKeyForm.controls['duration' + ''].enable();
			}
		}
	}

	// *************************************************************************************
	onUnlimitedChange(e, textName) {
		if (e.target.checked) {
			this.theKeyForm.controls[textName].disable();
			this.theKeyForm.controls[textName].setValue('');
		} else {
			this.theKeyForm.controls[textName].enable();
		}
	}

	// *************************************************************************************
	async onOrganizationChange() {
		if (!this.allOppsFetched) {
			this.loadingOpps = true;
			// let sfOpps: any[] = [];
			this.selectedSfOpp = null;
			this.sfPocEndDate = null;
			this.sfContractEndDate = null;
			this.sfOpps = []
			if (this.theKeyForm.value.zcp_org_id && +this.theKeyForm.value.zcp_org_id !== 0) {
				this.sfOpps = await this.organizationsService.fetchOrganizationOpportunities(
					+this.theKeyForm.value.zcp_org_id, this.activation.opportunity_id);
			}
			this.setupOppSelections();
		}
		this.setupOrgUserSelections(+this.theKeyForm.value.zcp_org_id);
	}

	// *************************************************************************************
	toggleShareUserSelectionMode() {
		if (this.shareUserMode === 'org')
			this.setupAllUserSelections();
		else
			this.setupOrgUserSelections(+this.theKeyForm.value.zcp_org_id);
	}

	// *************************************************************************************
	setupOrgUserSelections(orgID: number) {
		this.shareUserMode = 'org';
		this.shareUserSelections = [];

		if (orgID && orgID !== 0) {
			// 1st check to see if user can manage the users in the selected org
			const theOrg = this.organizationsService.getOne(orgID);

			// for each user, also check their role and whether or not the this user can manage that role
			this.shareUserSelections = [];
			for (const user of this.allUsers) {
				if (user.is_enabled === 1 && user.org_id && user.org_id === orgID) {
					let name = user.name + ' (' + user.email + ')';
					this.shareUserSelections.push({
						id: user.id,
						name
					});
				} // if
			} // for

			if (theOrg && this.shareUserSelections.length === 0 && theOrg.parent_org_id && theOrg.parent_org_id !== 0) {
				for (const user of this.allUsers) {
					if (user.is_enabled === 1 && user.org_id && user.org_id === theOrg.parent_org_id) {
						let name = user.name + ' (' + user.email + ')';
						this.shareUserSelections.push({
							id: user.id,
							name
						});
					} // if
				} // for
			} // if
		} // if
	}

	// *************************************************************************************
	setupAllUserSelections() {
		this.shareUserMode = 'all';
		this.shareUserSelections = [];

		// 1st check to see if user can manage the users in the selected org

		// for each user, also check their role and whether or not the this user can manage that role
		this.shareUserSelections = [];
		for (const user of this.allUsers) {
			let theOrg: Models.Organization = null;
			if (user.is_enabled === 1 && AppConstants.manageableRoles[this.authUser.role].includes(user.role)) {
				let name = user.name + ' (' + user.email + ')';
				if ((theOrg)) name += ' - ' + theOrg.name;
				this.shareUserSelections.push({
					id: user.id,
					name
				});
			} // if
		} // for
	}

	// *************************************************************************************
	async fetchAllOpps() {
		if (!this.allOppsFetched) {
			this.loadingOpps = true;
			this.sfOpps = await this.organizationsService.fetchSalesforceOpportunities();
			this.sfOpps.sort((a, b) => (a.CreatedDate > b.CreatedDate) ? 1 : -1);

			this.allOppsFetched = true;
			this.setupOppSelections();
		}
		this.showFetchAllOppsButton = false;
	}

	// *************************************************************************************
	onOpportunityChange() {
		this.selectedSfOpp = null;
		this.sfPocEndDate = null;
		this.sfContractEndDate = null;
		if (this.sfOpps && this.theKeyForm.value.opportunity_id && this.theKeyForm.value.opportunity_id !== '') {
			const idx = MiscTools.findIndexGeneric(this.sfOpps, 'Id', this.theKeyForm.value.opportunity_id);
			if (idx !== -1) {
				this.selectedSfOpp = this.sfOpps[idx];

				if (this.selectedSfOpp.PoC_Expiration_Date__c) {
					this.sfPocEndDate = new Date(this.selectedSfOpp.PoC_Expiration_Date__c);
					if (isNaN(this.sfPocEndDate.getTime())) this.sfPocEndDate = null;
				} // if

				if (this.selectedSfOpp.Contract_End_Date__c) {
					this.sfContractEndDate = new Date(this.selectedSfOpp.Contract_End_Date__c);
					if (isNaN(this.sfContractEndDate.getTime())) this.sfContractEndDate = null;
				} // if

				// if (this.selectedSfOpp.Name) {
				// 	if (!this.theKeyForm.value.info || this.theKeyForm.value.info === '')
				// 	this.theKeyForm.controls['info' + ''].setValue(this.selectedSfOpp.Name);

				// 	if (!this.theKeyForm.value.notes || this.theKeyForm.value.notes === '')
				// 		this.theKeyForm.controls['notes' + ''].setValue(this.selectedSfOpp.Name);
				// }
			} // if
		} // if
	}

	// *************************************************************************************
	setupOppSelections() {
		this.sfOpps.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);

		this.oppSelections = [];
		for (const sfOpp of this.sfOpps) {
			let name = '';
			if (this.allOppsFetched && sfOpp.Account && sfOpp.Account.Name)
				name = sfOpp.Account.Name + ' - ' + sfOpp.Name;
			else
				name = sfOpp.Name;
			this.oppSelections.push({ id: sfOpp.Id, name: name });
		}

		// if the opp is for whatever reason not in the list
		if (this.activation.opportunity_id && this.activation.opportunity_id !== ''
			&& MiscTools.findIndexGeneric(this.sfOpps, 'Id', this.activation.opportunity_id) === -1)
			this.oppSelections.push({ id: this.activation.opportunity_id, name: this.activation.opportunity_id });

		this.oppSelections.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

		this.loadingOpps = false;
	}

	// *************************************************************************************
	onMeterLimitChange(idx: number) {
		if (idx < this.niceMeterLimits.length) {
			const limit = +this.theKeyForm.value['meter_limit' + this.meterIDs[idx]];
			if (limit && !isNaN(limit))
				this.niceMeterLimits[idx] = '[' + TextTools.formattedMB(limit) + ']';
			else
				this.niceMeterLimits[idx] = '';
		}
	}

	// *************************************************************************************
	onMeterProjectedChange(idx: number) {
		if (idx < this.niceMeterProjecteds.length) {
			const projected = +this.theKeyForm.value['meter_projected' + this.meterIDs[idx]];
			if (projected && !isNaN(projected))
				this.niceMeterProjecteds[idx] = '[' + TextTools.formattedMB(projected) + ']';
			else
				this.niceMeterProjecteds[idx] = '';
		}
	}

	// *************************************************************************************
	checkAllUnlimited() {
		const currCheckAllValue = this.theKeyForm.value['check_all_limits'];

		let idsToSkip: number[] = [];
		if (this.template && this.template.settings.nonAllUnlimitedLimits && currCheckAllValue)
			idsToSkip = this.template.settings.nonAllUnlimitedLimits;

		this.numUnlimitedChecked = 0;
		for (const pp of this.numberProps) {
			if (!idsToSkip.includes(pp.property_id) && pp.property.allow_unlimited === 1 && pp.allow_unlimited === 1 && pp.property.special_property === 0) {
				this.theKeyForm.controls[pp.property.name + '_unlimited'].setValue(currCheckAllValue);
				if (currCheckAllValue) {
					this.numUnlimitedChecked++;
					this.theKeyForm.controls[pp.property.name].disable();
					this.theKeyForm.controls[pp.property.name].setValue('');
				} else {
					this.theKeyForm.controls[pp.property.name].enable();
					// this.theKeyForm.controls[pp.property.name].setValue('');
				} // if
			}
		} //for
		// this.numUnlimitedChecked = this.numUnlimited;
	}

	// *************************************************************************************
	checkAllBoolean() {
		const currCheckAllValue = this.theKeyForm.value['check_all_boolean'];

		let idsToSkip: number[] = [];
		if (this.template && this.template.settings.nonCheckAllFeatures && currCheckAllValue)
			idsToSkip = this.template.settings.nonCheckAllFeatures;

		for (const pp of this.booleanProps)
			if (!idsToSkip.includes(pp.property_id) && pp.property.special_property === 0)
				this.theKeyForm.controls[pp.property.name].setValue(currCheckAllValue);
	}

	// *************************************************************************************
	newMeterID() {
		let counter = 0;
		const max = 100;
		while (counter < max) {
			counter++;
			const id = TextTools.randomString(8);
			if (!this.meterIDs.includes(id))
				return id;
		}
		return '';
	}

	// *************************************************************************************
	dumpFormErrors() {
		Object.keys(this.theKeyForm.controls).forEach(key => {
			const controlErrors: ValidationErrors = this.theKeyForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	// calculateLimit(idx: number, days: number) {
	// 	const streams = +this.theKeyForm.value['meter_calc_streams' + this.meterIDs[idx]];
	// 	const rate = +this.theKeyForm.value['meter_calc_rate' + this.meterIDs[idx]];
	// 	if (streams > 0 && rate > 0 && days > 0) {
	// 		let mb = Math.floor(streams * rate * days * 24 * 60 * 60 / 8);
	// 		if (mb > AppConstants.maxMeterLimit) mb = AppConstants.maxMeterLimit;
	// 		this.theKeyForm.controls['meter_limit' + this.meterIDs[idx]].setValue(mb);
	// 		this.onMeterLimitChange(idx);
	// 	}
	// }

	// *************************************************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.products, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.products[idx].label;
	}

	// *************************************************************************************
	getOrgPlaceholder() {
		if (!this.loading) {
			if (this.template.settings.requiresOrganization)
				return 'Select an organization (required)';
			else
				return 'Select an organization (optional)';
		}
		return '';
	}

	// *************************************************************************************
	getOppIDPlaceholder() {
		if (!this.loading) {
			if (this.oppSelections.length > 0) {
				if (this.template.settings.requiresSalesforceOpportunity)
					return 'Select an opportunity (required) ' + this.oppSelections.length + ' choice(s)';
				else
					return 'Select an opportunity (optional) ' + this.oppSelections.length + ' choice(s)';
			} else {
				const orgID = +this.theKeyForm.value.zcp_org_id;
				if (orgID && orgID !== 0) {
					return 'The selected organization doesn\'t have any opportunities';
				} else {
					if (this.template.settings.salesforceOpportunityMustMatch)
						return 'Select an organization first';
					else
						return 'Select an organization or click the Salesforce button to view all opportunities';
				}
			}
		}
		return '';
	}

	// *************************************************************************************
	setAllMeterExpiry(theDate: Date) {
		let idx = 0;
		for (const meter of this.activation.meters) {
			if (theDate && !isNaN(theDate.getTime())) {
				const meterExpiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
				this.theKeyForm.controls['meter_expires_at' + this.meterIDs[idx]].setValue(meterExpiresAt);
			}
			idx++;
		} // for
	}

	// *************************************************************************************
	getTemplatesForProduct(templates: Models.KeyTemplate[], product: string): Models.KeyTemplate[] {
		const sublist: Models.KeyTemplate[] = [];
		for (const template of templates)
			if (template.product === product)
				sublist.push(template);
		return sublist;
	}

	// *************************************************************************************
	selectOpp(oppId: string) {
		this.theKeyForm.controls['opportunity_id'].setValue(oppId);
		if (document.getElementById("closeSfOppsModal"))
			document.getElementById("closeSfOppsModal").click();
	}

	// *************************************************************************************
	getNonSpecialProps(props: Models.LicenseProductProperty[]) {
		const subList: Models.LicenseProductProperty[] = [];
		for (const prop of props)
			if (prop.property.special_property === 0)
				subList.push(prop);
		return subList;
	}

	// *************************************************************************************
	onUserSelectionCheckChange(event: any, id: number) {
		const idx = this.selectedUserIds.indexOf(id);
		/* Selected */
		if (event.target.checked) {
			if (idx === -1)
				this.selectedUserIds.push(id);
		} else {
			this.selectedUserIds.splice(idx, 1);
		}
	} //
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// --------------------------------------------------------------------
	getCommercialTypeLabel(commercialType: string = '') {
		if (commercialType && commercialType !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', commercialType);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].label;
		} // if
		return '';
	}

	// --------------------------------------------------------------------
	getCommercialTypeInfo(commercialType: string = '') {
		if (commercialType && commercialType !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', commercialType);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].info;
		} // if
	}

	// *************************************************************************************
	addProtocolSetFromPickList() {
		const picker = document.getElementById('addPsetPicker');
		if (picker) {
			let id: number = +picker['value'];
			if (isNaN(id)) id = 0;
			if (id !== 0) {
				const idx = MiscTools.findIndexGeneric(this.availableSets, 'id', id);
				if (idx !== -1) {
					const toAdd = MiscTools.deepClone(this.availableSets[idx]);
					this.availableSets.splice(idx, 1);
					this.addProtocolSetToForm(toAdd);
				} // if
			} // if
		}
	}

	// *************************************************************************************
	addProtocolSetToForm(pSet: Models.ProtocolSet) {
		if (pSet) {
			let projected: number = 0;
			if (pSet['projected']) projected = +pSet['projected'];
			if (isNaN(projected)) projected = 0;
			this.theKeyForm.addControl('pset_projected_' + pSet.id, new UntypedFormControl(projected, [Validators.max(AppConstants.maxMeterLimit)]));
			this.linkedProtocolSetInForm.push(pSet);
			this.onProtocolSetProjectedChange(pSet.id);
		} // if
	}
	// *************************************************************************************
	removeProtocolSetFromForm(id: number) {
		const idx = MiscTools.findIndexGeneric(this.linkedProtocolSetInForm, 'id', id);
		if (idx !== -1 && confirm('Are you sure?')) {
			this.theKeyForm.removeControl('pset_projected' + this.linkedProtocolSetInForm[idx].id);
			this.linkedProtocolSetInForm.splice(idx, 1);
		} // if
	}
	// *************************************************************************************
	onProtocolSetProjectedChange(id: number) {
		let projected = +this.theKeyForm.value['pset_projected_' + id];
		if (isNaN(projected)) projected = 0;
		if (projected > AppConstants.maxMeterLimit) {
			projected = AppConstants.maxMeterLimit;
			this.theKeyForm.controls['pset_projected_' + id].setValue(AppConstants.maxMeterLimit);
		} // if

		if (projected > 0)
			this.nicePsetProjected[id] = TextTools.formattedMB(projected);
		else
			this.nicePsetProjected[id] = '';
	}

	get commercial_type() { return this.theKeyForm.get('commercial_type'); }

}
