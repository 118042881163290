
import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { EmailQueueService } from '../email-queue.service';
import { UsersService } from '../../users/users.service';

import { NotificationsTableComponent } from 'client/app/components/shared/notifications-table/notifications-table.component';

@Component({
	selector: 'app-view-queue-logs',
	templateUrl: './view-queue-logs.component.html',
	styleUrls: ['./view-queue-logs.component.scss']
})
export class ViewQueueLogsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('notificationsTable1') notificationsTable1: NotificationsTableComponent = null;

	queue: Models.EmailQueueEntry[];
	loading = true;
	refreshing = false;
	processing = false;

	// other stuff...
	maxMessage = '';
	showFilters = false;
	theForm: UntypedFormGroup;

	messageStatuses: string[] = [];
	messageTypes: string[] = [];
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;
	// readyToSend = false;

	private userSubscription: Subscription;
	users: Models.User[] = [];

	canManageQueue = false;

	constructor(
		private router: Router,
		private emailQueueService: EmailQueueService,
		private usersService: UsersService,
		private authService: AuthService) { }

	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-emailQueue.settings'))
				settings = JSON.parse(localStorage.getItem('cp-emailQueue.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('messageStatuses')) this.messageStatuses = settings.messageStatuses;
		if (settingKeys.includes('messageTypes')) this.messageTypes = settings.messageTypes;
		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.messageStatuses.length !== 0 || this.messageTypes.length !== 0
			|| this.notBefore != null || this.notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.canManageQueue = ValidationTools.checkAccess(authUser, 'manage-email-queue');
			}
		});

		// this.loadUsers();
		this.initForm();
		this.loadLogs();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			messageStatuses: new UntypedFormControl(this.messageStatuses),
			messageTypes: new UntypedFormControl(this.messageTypes),
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.messageStatuses);
		this.messageStatuses = this.theForm.value.messageStatuses;
		this.messageTypes = this.theForm.value.messageTypes;
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			messageStatuses: this.messageStatuses,
			messageTypes: this.messageTypes,
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-emailQueue.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		this.queue = await this.emailQueueService.getQueue(
			this.messageStatuses,
			this.messageTypes,
			[],
			[],
			startDate,
			endDate,
			false);

		if (this.queue && this.queue.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		await MiscTools.delay(100);
		if (this.notificationsTable1)
			this.notificationsTable1.updateContent(this.queue, 'cp-notification-logs', { showUserInfo: true, linkUser: true });

		this.loading = false;
	}

	async loadUsers() {
		const users = this.usersService.getAll();
		for (const user of users)
			if (user.role !== AppConstants.basicUserRole)
				this.users.push(user);
	}

	async processQueue() {
		this.processing = true;
		await this.emailQueueService.processQueue();
		this.processing = false;
		this.loadLogs();
	}

	async updateStatuses() {
		this.processing = true;
		await this.emailQueueService.updateStatuses();
		this.processing = false;
		this.loadLogs();
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

}
