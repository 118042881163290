import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { UserGroupsService } from '../user-groups.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-user-group-form',
	templateUrl: './user-group-form.component.html',
	styleUrls: ['./user-group-form.component.scss']
})
export class UserGroupFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	group: Models.UserGroup;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	staffUserSelections: any[] = [];

	canSetManagers = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private userGroupsService: UserGroupsService,
		private usersService: UsersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.group = this.userGroupsService.getOne(this.id);
				if (!this.group || this.group == null || this.group.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.userGroupsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.group = new Models.UserGroup(0, '', '', '', 1);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.canSetManagers = authUser && ValidationTools.checkAccess(authUser, 'manage-user-groups');

			if (this.editMode) {
				this.listSubscription = this.userGroupsService.groups.subscribe(groups => {
					this.group = groups.find((group: Models.UserGroup) => group.id === this.id);
					this.initForm();
				});
			} else {
				this.initForm();
			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		this.staffUserSelections = [];
		const sortedMemberIds: number[] = [];
		const sortedManagerIds: number[] = [];

		for (const user of staff) {
			if (user.is_enabled === 1 || this.group.manager_ids.includes(user.id) || this.group.user_ids.includes(user.id)) {
				let nameToShow = user.name;
				if (user.is_enabled === 0) nameToShow += ' (disabled)';
				this.staffUserSelections.push({ id: user.id, name: nameToShow });

				if (this.group.manager_ids.includes(user.id)) sortedManagerIds.push(user.id);
				if (this.group.user_ids.includes(user.id)) sortedMemberIds.push(user.id);
			} // if
		} // staff

		// const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		// this.staffUserSelections = [];

		// const members: Models.User[] = [];
		// const managers: Models.User[] = [];

		// for (const user of allUsers) {
		// 	if (user.is_enabled === 1 && ValidationTools.checkRole(user.role, AppConstants.staffUserRole)) {
		// 		let nameToShow = user.name;;
		// 		this.staffUserSelections.push({ id: user.id, name: nameToShow });
		// 		if (this.group.user_ids.includes(user.id)) members.push(user);
		// 		if (this.group.manager_ids.includes(user.id)) managers.push(user);
		// 	}
		// } // for

		// for (const user of allUsers) {
		// 	if (user.is_enabled === 0 && ValidationTools.checkRole(user.role, AppConstants.staffUserRole)) {
		// 		let nameToShow = user.name + ' (disabled)';
		// 		this.staffUserSelections.push({ id: user.id, name: nameToShow });
		// 		if (this.group.user_ids.includes(user.id)) members.push(user);
		// 		if (this.group.manager_ids.includes(user.id)) managers.push(user);
		// 	}
		// } // for

		// members.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
		// const sortedMemberIds: number[] = [];
		// for (const member of members)
		// 	sortedMemberIds.push(member.id);

		// managers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
		// const sortedManageIds: number[] = [];
		// for (const manager of managers)
		// 	sortedManageIds.push(manager.id);

		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.group.is_enabled),
			name: new UntypedFormControl(this.group.name, [Validators.required]),
			information: new UntypedFormControl(this.group.information),
			user_ids: new UntypedFormControl(sortedMemberIds),
			manager_ids: new UntypedFormControl(sortedManagerIds)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const preSaveUserIds: number[] = this.group.user_ids.slice();

		this.group.is_enabled = this.theForm.value.is_enabled;
		this.group.gtype = '';
		this.group.name = this.theForm.value.name;
		this.group.information = this.theForm.value.information;
		this.group.user_ids = this.theForm.value.user_ids;
		if (this.canSetManagers)
			this.group.manager_ids = this.theForm.value.manager_ids;

		try {
			let retUserGroup: Models.UserGroup;
			if (this.editMode)
				retUserGroup = await this.userGroupsService.updateOne(this.group);
			else
				retUserGroup = await this.userGroupsService.addOne(this.group);

			if (retUserGroup) {
				const userIDsToUpdate = [];
				// find orgs that have been added
				for (const orgID of this.group.user_ids)
					if (preSaveUserIds.indexOf(orgID) === -1)
						userIDsToUpdate.push(orgID);

				// find orgs that have been removed
				for (const orgID of preSaveUserIds)
					if (this.group.user_ids.indexOf(orgID) === -1)
						userIDsToUpdate.push(orgID);

				for (const userID of userIDsToUpdate)
					this.usersService.refreshOne(userID);

				// ******************************************
				this.router.navigate([AppConstants.urls.usergroups + '/' + retUserGroup.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// get gtype() { return this.theForm.get('gtype'); }
	get name() { return this.theForm.get('name'); }

}
