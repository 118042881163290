import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { MyKeysService } from '../my-keys.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { MyPackagesService } from '../../my-packages/my-packages.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

import { KeysTableComponent } from 'client/app/components/shared/keys-table/keys-table.component';

@Component({
	selector: 'app-license-key-list',
	templateUrl: './license-key-list.component.html',
	styleUrls: ['./license-key-list.component.scss']
})
export class LicenseKeyListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('keysTable1') keysTable1: KeysTableComponent = null;
	@ViewChild('keysTable2') keysTable2: KeysTableComponent = null;
	@ViewChild('keysTable3') keysTable3: KeysTableComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	packages: Models.Package[] = [];

	userKeys: Models.UserLicenseKey[] = [];
	deletedKeys: Models.UserLicenseKey[] = [];

	// activeKeys: Models.UserLicenseKey[] = [];
	// inactiveKeys: Models.UserLicenseKey[] = [];
	// deletedKeys: Models.UserLicenseKey[] = [];

	activeKeysToShow: Models.LPActivation[] = [];
	inactiveKeysToShow: Models.LPActivation[] = [];
	deletedKeysToShow: Models.LPActivation[] = [];

	hostids: Models.UserHostId[] = [];

	keyWarnings = {};
	keyExpiries = {};

	keyActiveHostCounts = {};
	recentHostIconPopup = '# Host IDs eporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';

	availableSelfServicePackageTemplates: Models.PackageKeyTemplate[] = [];

	orgUsers: Models.User[] = [];

	loading = true;
	showKeyCreator = false;

	// showBxProtocolStats = false;
	showBxProtocolStats = true;

	expandWarnings = false;

	keyProducts: Models.LicenseProduct[] = [];

	editMode: boolean = false;
	theForm: UntypedFormGroup;

	userSnoozes: Models.UserLicenseKeySnooze[] = [];

	billingCodeOrgs: Models.OrganizationBillingCodes[] = [];
	showBillingCodeTab: boolean = false;
	hideBillingAuths: boolean = true;

	resetBillingCodeForm: UntypedFormGroup;
	showResetCodeMsg = false;
	billingCodeToReset: Models.BillingCode = null;
	numBillingCodes: number = 0;

	updateBillingCodeForm: UntypedFormGroup;
	updateBillingCodeErrors: string[] = [];
	billingCodeToUpdate: Models.BillingCode = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private myPackagesService: MyPackagesService,
		private licensingAdminService: LicensingAdminService,
		private uiAlertsService: UiAlertsService
	) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			if (authUser) {
				this.loadTables();
			}
		});

		const expandWarnings = localStorage.getItem('cp-myKeys.expandWarnings');
		this.expandWarnings = (expandWarnings && expandWarnings === 'true');
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadTables(forceRefresh = false) {
		this.loading = true;

		this.availableSelfServicePackageTemplates = [];
		this.activeKeysToShow = [];
		this.inactiveKeysToShow = [];
		this.deletedKeysToShow = [];

		// this.deletedKeys = [];
		// this.keysToShow = [];

		this.packages = await this.myPackagesService.getMyPackages(forceRefresh);
		this.keyProducts = await this.licensingAdminService.getProducts();

		this.userKeys = await this.myKeysService.getUserKeys(false, forceRefresh);
		this.deletedKeys = await this.myKeysService.getUserKeys(true, forceRefresh);

		this.keyActiveHostCounts = await this.myKeysService.getActiveHostCounts();

		this.userSnoozes = await this.myKeysService.getUserSnoozes();
		this.userSnoozes.sort((a, b) => (a.expires_at > b.expires_at) ? 1 : -1);

		this.billingCodeOrgs = await this.myKeysService.getBillingCodesOrganizations();
		this.showBillingCodeTab = this.billingCodeOrgs.length > 0;

		this.numBillingCodes = 0;
		if (this.billingCodeOrgs) {
			for (const item of this.billingCodeOrgs)
				if (item.billingCodes)
					this.numBillingCodes += item.billingCodes.length;
		} // if

		this.hostids = await this.myKeysService.getUserHostids(forceRefresh);

		// this.showBxProtocolStats = TextTools.getUserPropValue(this.authUser, AppConstants.showBxProtocolStatsOverride) === 'yes';

		for (const userKey of this.userKeys) {
			let item: Models.LPActivation = userKey.activation;

			item['__niceProduct'] = this.niceKeyProduct(item.product);
			item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, [], [], false).join('\n');

			item['__user_id'] = userKey.id;
			item['__user_label'] = this.getLabel(userKey);
			item['__raw_label'] = userKey.label;

			if (this.keyActiveHostCounts[item.id])
				item['__active_hostids'] = this.keyActiveHostCounts[item.id];
			else
				item['__active_hostids'] = '';

			const exp = SharedLicenseTools.getKeyExpiration(item, '', true);
			if ((item.enabled === 0 && !SharedLicenseTools.isSpecialKey(item)) || (exp != null && MiscTools.hasExpired(exp)))
				this.inactiveKeysToShow.push(item);
			else
				this.activeKeysToShow.push(item);
		} // for

		for (const userKey of this.deletedKeys) {
			let item: Models.LPActivation = userKey.activation;

			item['__niceProduct'] = this.niceKeyProduct(item.product);
			item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, [], [], false).join('\n');

			item['__user_id'] = userKey.id;
			item['__user_label'] = this.getLabel(userKey);
			item['__raw_label'] = userKey.label;

			if (this.keyActiveHostCounts[item.id])
				item['__active_hostids'] = this.keyActiveHostCounts[item.id];
			else
				item['__active_hostids'] = '';

			this.deletedKeysToShow.push(item);
		} // for

		this.updateKeyTables();

		this.workoutAvailableSelfServiceKeys();

		this.orgUsers = await this.mySettingsService.getOrgUsers();

		const formControls: any = {};
		for (const item of this.activeKeysToShow)
			formControls['label_' + item['__user_id']] = new UntypedFormControl(item['__raw_label'], [])
		this.theForm = new UntypedFormGroup(formControls);

		this.loading = false;
	}

	// --------------------------------------------------------------------
	updateKeyTables() {
		if (this.keysTable1) {
			if (this.expandWarnings && !this.keysTable1.expandWarnings) this.keysTable1.toggleWarnings();

			this.keysTable1.updateContent(this.activeKeysToShow, 'cp-my-keys-active',
				{
					staffMode: false,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: true,
					showInfo: false,
					showOrganization: false,
					showFullProduct: true,
					showFullType: true,
					showExpandedActivations: true,
					showNumUsers: false,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: false,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: false,
					showMeterIrregularities: false
				});
		} // if

		if (this.keysTable2) {
			if (this.expandWarnings && !this.keysTable2.expandWarnings) this.keysTable2.toggleWarnings();

			this.keysTable2.updateContent(this.inactiveKeysToShow, 'cp-my-keys-old',
				{
					staffMode: false,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: true,
					showInfo: false,
					showOrganization: false,
					showFullProduct: true,
					showFullType: true,
					showExpandedActivations: true,
					showNumUsers: false,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: false,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: false,
					showMeterIrregularities: false
				});
		} // if

		if (this.keysTable3) {
			if (this.expandWarnings && !this.keysTable3.expandWarnings) this.keysTable3.toggleWarnings();

			this.keysTable3.updateContent(this.deletedKeysToShow, 'cp-my-keys-deleted',
				{
					staffMode: false,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: true,
					showInfo: false,
					showOrganization: false,
					showFullProduct: true,
					showFullType: true,
					showExpandedActivations: true,
					showNumUsers: false,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: false,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: false,
					showMeterIrregularities: false
				});
		} // if
	}

	// --------------------------------------------------------------------
	async workoutAvailableSelfServiceKeys() {
		// work out what keys they can generate...
		this.availableSelfServicePackageTemplates = [];
		for (const pkg of this.packages) {
			if (pkg.is_enabled === 1) {
				for (const pkgTemplate of pkg.key_templates) {
					let foundKey = false;
					for (const userKey of this.userKeys)
						if (userKey.activation && userKey.activation.zcp_template_id === pkgTemplate.template_id)
							foundKey = true;

					for (const userKey of this.deletedKeys)
						if (userKey.activation && userKey.activation.zcp_template_id === pkgTemplate.template_id)
							foundKey = true;

					if (!foundKey) this.availableSelfServicePackageTemplates.push(pkgTemplate);
				} // for
			} // if
		} //for
	}

	// --------------------------------------------------------------------
	async generateKeyViaTemplate(packageID: number, templateID: number) {
		try {
			this.loading = true;
			const ret = await this.myKeysService.generateKeyViaTemplate(packageID, templateID);
			if (ret) {
				this.uiAlertsService.addMsg('Your new key is available.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate(['/' + AppConstants.urls.mykeys, ret.id]);
			} else {
				this.loading = false;
				this.uiAlertsService.addMsg('There was an error adding your key.', 'error');
			}
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}

	// --------------------------------------------------------------------
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].basic_label;
	}

	// --------------------------------------------------------------------
	getPackage(id: number) {
		const idx = MiscTools.findIndex(this.packages, id);
		if (idx !== -1) return this.packages[idx];
		return null;
	}

	// --------------------------------------------------------------------
	getKey(id: number) {
		const idx = MiscTools.findIndex(this.userKeys, id);
		if (idx !== -1) {
			return this.userKeys[idx].activation.key;
		} else {
			const idx2 = MiscTools.findIndex(this.deletedKeys, id);
			if (idx2 !== -1)
				return this.userKeys[idx].activation.key;
		}
		return '';
	}

	// --------------------------------------------------------------------
	getLabelFromId(id: number) {
		const idx = MiscTools.findIndex(this.userKeys, id);
		if (idx !== -1) {
			return this.getLabel(this.userKeys[idx]);
		} else {
			const idx2 = MiscTools.findIndex(this.deletedKeys, id);
			if (idx2 !== -1)
				return this.getLabel(this.userKeys[idx2]);;
		}
		return '';
	}

	// --------------------------------------------------------------------
	getLabel(userKey: Models.UserLicenseKey) {
		if (userKey.label && userKey.label !== '')
			return userKey.label;
		else if (userKey.activation && userKey.activation.ext_label && userKey.activation.ext_label !== '')
			return userKey.activation.ext_label;
		else
			return 'Not Set';
	}

	// --------------------------------------------------------------------
	getLabelMode(userKey: Models.UserLicenseKey) {
		if (userKey.label && userKey.label !== '')
			return 'user-label';
		else if (userKey.activation && userKey.activation.ext_label && userKey.activation.ext_label !== '')
			return 'key-label';
		else
			return 'no-label';
	}

	// --------------------------------------------------------------------
	handleClick(id: number) {
		this.router.navigate([id], { relativeTo: this.route });
	}

	// --------------------------------------------------------------------
	async emailUserKeysReport() {
		await this.myKeysService.emailUserKeysReport();
	}

	// --------------------------------------------------------------------
	openUserKeysReport(metricType: string) {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/report'
			+ '?metricType=' + encodeURIComponent(metricType);
		window.open(url, '_blank');
	}

	// --------------------------------------------------------------------
	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;

		if (this.keysTable1) this.keysTable1.toggleWarnings();
		if (this.keysTable2) this.keysTable2.toggleWarnings();
		if (this.keysTable3) this.keysTable3.toggleWarnings();

		localStorage.setItem('cp-myKeys.expandWarnings', this.expandWarnings.toString());
	}

	// --------------------------------------------------------------------
	async emptyTrash() {
		if (!confirm('Are you sure you want to purge these ' + this.deletedKeysToShow.length + ' key(s)? - You will NOT be able to recover them.'))
			return;

		for (const deletedKey of this.deletedKeys)
			await this.myKeysService.purgeKey(deletedKey, false);

		this.deletedKeys = await this.myKeysService.getUserKeys(true, true);
		this.deletedKeysToShow = [];

		this.uiAlertsService.addMsg('The trash has been emptied.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.updateKeyTables();

		if (document.getElementById('nav-active-keys-tab'))
			document.getElementById('nav-active-keys-tab').click();

	}

	// --------------------------------------------------------------------
	async saveLabels() {
		this.loading = true;
		this.editMode = false;

		const updatedIdx: number[] = [];
		let counter = 0;
		for (const item of this.activeKeysToShow) {
			const newLabel = this.theForm.value['label_' + item['__user_id']].trim();
			if (newLabel !== item['__raw_label']) {
				const userKey: Models.UserLicenseKey = MiscTools.pickItem(this.userKeys, 'id', +item['__user_id']);
				if (userKey) {
					userKey.label = newLabel;
					await this.myKeysService.updateKey(userKey, false);
					counter++;
				} // if
			} // if
		} // for

		if (counter > 0) {
			this.uiAlertsService.addMsg('Your changes have been saved.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.userKeys = await this.myKeysService.getUserKeys(false, true);
			await this.loadTables(true);
		} // if

		this.loading = false;
	}

	// *********************************************************
	async resetBillCodeAuthCodePrep(billingCode: Models.BillingCode) {
		if (!billingCode) return;
		this.resetBillingCodeForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});
		this.billingCodeToReset = MiscTools.deepClone(billingCode);
		this.clickButton('openResetBillingCodeModal');
	}

	// *********************************************************
	async resetBillCodeAuthCode() {
		if (!this.billingCodeToReset) return;

		let confirmation = '';
		if (this.resetBillingCodeForm.value.confirmation) confirmation = this.resetBillingCodeForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'reset') {
			this.showResetCodeMsg = true;
			this.loading = false;
			return;
		} // if

		this.clickButton('closeResetBillingCodeModal');

		const result = await this.myKeysService.resetBillCodeAuthCode(this.billingCodeToReset);
		if (result) {

			this.uiAlertsService.addMsg(this.billingCodeToReset.billing_code + '\'s authorization code has been reset.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.billingCodeToReset = null;
			this.billingCodeOrgs = await this.myKeysService.getBillingCodesOrganizations();
			this.loading = false;
		} else {
			return false;
		}
	}

	// *********************************************************
	async updateBillingCodePrep(billingCode: Models.BillingCode) {
		if (!billingCode) return;
		this.updateBillingCodeErrors = [];
		this.updateBillingCodeForm = new UntypedFormGroup({
			label: new UntypedFormControl(billingCode.label, [Validators.required])
		});
		this.billingCodeToUpdate = MiscTools.deepClone(billingCode);
		this.clickButton('openUpdateBillingCodeModal');
	}

	// *********************************************************
	async updateBillingCode() {
		this.loading = true;
		this.updateBillingCodeErrors = [];
		this.billingCodeToUpdate.label = this.updateBillingCodeForm.value.label;
		if (!this.billingCodeToUpdate.label || this.billingCodeToUpdate.label === '')
			this.updateBillingCodeErrors.push('You must provide a Name/Label');

		if (this.updateBillingCodeErrors.length > 0) {
			this.loading = false;
			return;
		}

		try {
			const returnedBC: Models.BillingCode = await this.myKeysService.updateBillingCode(this.billingCodeToUpdate);

			this.uiAlertsService.addMsg(returnedBC.billing_code + '\'s label has been updated.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.loading = false;
			this.updateBillingCodeErrors = [];
			this.billingCodeToUpdate = null;
			this.clickButton('closeUpdateBlllingCodeModal');

			this.billingCodeOrgs = await this.myKeysService.getBillingCodesOrganizations();
		} catch (ex) {
			this.updateBillingCodeErrors.push(ex.toString());
			this.loading = false;
		}
	}

	// --------------------------------------------------------------------
	openBillingCodeReport(orgId: number) {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/org-billing-codes/' + orgId + '/report';
		window.open(url, '_blank');
	}

	// ------------------------------------------------------------------------
	clickButton(id: string) {
		if (document.getElementById(id))
			document.getElementById(id).click();
	} // clickButton

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

}
