import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';

import { ReportsService } from '../../reports/reports.service';


@Component({
	selector: 'app-zen-customers-reports',
	templateUrl: './zen-customers-reports.component.html',
	styleUrls: ['./zen-customers-reports.component.scss']
})
export class ZenCustomersReportsComponent implements OnInit {
	appConstants = AppConstants;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private reportsService: ReportsService) {
	}

	ngOnInit(): void {
	}

	async openReport(withCounts = false) {
		// let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/report';
		// if (withCounts) url += '?counts=yes';
		// else url += '?counts=no';
		// window.open(url, '_blank');

		let args = '';
		if (withCounts) args = 'counts=yes';
		await this.reportsService.runReport('ZenMasterSitesReport', args);
	}

}
