import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { SystemMessagesService } from '../system-messages.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { NotificationUsersTableComponent } from 'client/app/components/shared/notification-users-table/notification-users-table.component';

@Component({
	selector: 'app-general-notification-form',
	templateUrl: './general-notification-form.component.html',
	styleUrls: ['./general-notification-form.component.scss']
})
export class GeneralNotificationFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild('notificationUsersTable1') notificationUsersTable1: NotificationUsersTableComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	users: Models.User[] = [];
	numSubscribed: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private systemMessagesService: SystemMessagesService,
		private authService: AuthService,
		private usersService: UsersService) {
		this.route.paramMap.subscribe(params => {
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				if (authUser) {

					// this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-build-notifications');
				}
			});
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		const allUsers = this.usersService.getAll();
		this.users = [];
		this.numSubscribed = 0;
		for (const user of allUsers)
			if (user.is_deleted === 0) {
				user['__reason'] = ValidationTools.getGeneralNotificationState(user);
				if (user['__reason'] === '') this.numSubscribed++;
				this.users.push(user);
			}

		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			subject: new UntypedFormControl(null),
			message: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;
		await MiscTools.delay(100);
		if (this.notificationUsersTable1)
			this.notificationUsersTable1.updateContent(this.users, 'cp-general-notification-users');
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const message = this.theForm.value.message;
		const subject = this.theForm.value.subject;
		if (message && message !== '' && confirm('Are you sure you want to send a notification to ' + this.numSubscribed + ' users(?)')) {
			try {
				await this.systemMessagesService.sendNotification(subject, message);

				this.uiAlertsService.addMsg('The notification has been queued.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

				this.onCancel();
			} catch (e) {
				this.saving = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} else {
			this.saving = false;
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return ValidationTools.hasFlag(obj, flag);
	};
}
