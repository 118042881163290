<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Products</a></li>
			<li *ngIf="product" class="breadcrumb-item active" aria-current="page">
				{{ product.label }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!product">

				<!-- <button *ngIf="showAddPropButton" type="button" class="btn btn-outline-primary"
					routerLink="property/new">
					<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Add Property</span>
				</button> -->

				<div *ngIf="canManage && showAddPropButton" class="btn-group dropstart">
					<button class="btn btn-outline-primary dropdown-toggle" type="button" id="addPropButton"
						data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
						<span class="cp-action-button-text">Add Property</span>
					</button>
					<div class="dropdown-menu cp-scrollable-menu" aria-labelledby="addPropButton">
						<a *ngFor="let prop of addableProperties" class="dropdown-item" href="#"
							[routerLink]="['property', prop.id, 'edit']">
							{{ appConstants.keyPropertyTypeLabels[prop.ptype] }}:
							{{ prop.label }} ({{ prop.name }})

						</a>
					</div>
				</div>

				<button *ngIf="canManage" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canManage" type="button" class="btn btn-outline-primary" (click)="clone()"
					ngbTooltip="Copy/Clone" placement="start">
					<fa-icon icon="clone" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Clone</span> -->
				</button>

				<button *ngIf="canManage && deleteForm" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="booleanProps.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-features-tab" aria-controls="nav-features" data-bs-target="#nav-features">
				Features
				<span class="cp-tab-badge">
					{{ booleanProps.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="numberProps.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-limits-tab" aria-controls="nav-limits" data-bs-target="#nav-limits">
				Limits
				<span class="cp-tab-badge">
					{{ numberProps.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="otherProps.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-other-tab" aria-controls="nav-other" data-bs-target="#nav-other">
				Other
				<span class="cp-tab-badge">
					{{ otherProps.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-admin-log-tab" aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>



	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!product" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ product.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Label (Staff Users):</span>
					<span class="cp-property-value">{{ product.label }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Label (Basic Users):</span>
					<span class="cp-property-value">{{ product.basic_label }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!product.description && product.description !== ''">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ product.description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Meter Stats:</span>
					<span class="cp-property-value">{{ niceStatRule(product.show_meters) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Protocol Stats:</span>
					<span class="cp-property-value">{{ niceStatRule(product.show_protocols) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Meter Products:</span>
					<span class="cp-property-value">{{ product.meterProductsArr.join(', ') }}</span>
				</div>

				<div *ngIf="buildProduct" class="cp-property-block">
					<span class="cp-property-label">Build Product:</span>
					<span class="cp-property-value">{{ buildProduct.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name for License File:</span>
					<span class="cp-property-value">{{ product.license_filename }}</span>
				</div>
				<div class="cp-property-block">
					<span class="cp-property-label">Minimum Version:</span>
					<span class="cp-property-value">{{ product.min_version }}</span>
				</div>
				<div class="cp-property-block">
					<span class="cp-property-label">License Version:</span>
					<span class="cp-property-value">{{ product.fulfillment_version }}</span>
				</div>
				<div class="cp-property-block">
					<span class="cp-property-label">License Product:</span>
					<span class="cp-property-value">{{ product.fulfillment_product }}</span>
				</div>
				<div class="cp-property-block">
					<span class="cp-property-label">License Includes 'refresh_key':</span>
					<span *ngIf="product.include_refresh === 1" class="cp-property-value">Yes</span>
					<span *ngIf="product.include_refresh === 0" class="cp-property-value">No</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ product.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="product.added_on !== product.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ product.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!!productTemplates && productTemplates.length !== 0">
					<span class="cp-property-label">Key Templates for this Product:</span>
					<ul class="mb-0">
						<li *ngFor="let template of productTemplates" class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'templates', template.id]"
								[ngbPopover]="templatePopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ template.name }}
							</a>

							<ng-template #templatePopupContent>
								<div class="cp-popover-banner">{{ template.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!!otherProducts && otherProducts.length !== 0">
					<span class="cp-property-label">Other Products Using Same License Base ({{
						product.fulfillment_product }}):</span>
					<ul class="mb-0">
						<li *ngFor="let otherProduct of otherProducts" class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'products', otherProduct.id]"
								[ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ otherProduct.label }} ({{ otherProduct.name }})
							</a>
							<ng-template #keyProductPopupContent>
								<div class="cp-popover-banner">{{ otherProduct.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyProductPopoverLines(otherProduct).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

			</div>
		</div>
		<div class="tab-pane" id="nav-features" role="tabpanel" aria-labelledby="nav-features-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Label (Name)</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
						<th *ngIf="canManage">-</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of booleanProps">
						<!-- <td class="border-end">{{ pp.property.sort_order }}</td> -->
						<td class="border-end" [title]="pp.property.label">
							<a [routerLink]="['../../properties', pp.property_id]">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
						<td *ngIf="canManage" class="border-end text-nowrap">
							<button type="button" class="btn btn-outline-primary btn-sm me-1"
								[routerLink]="['property', pp.property.id, 'edit']">
								<fa-icon icon="pencil" size="sm"></fa-icon>
							</button>
							<button type="button" class="btn btn-outline-danger btn-sm"
								(click)="deleteProperty(pp.property.id)">
								<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-limits" role="tabpanel" aria-labelledby="nav-limits-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Label (Name)</th>
						<th>Units</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th class='text-end'>Def</th>
						<th class='text-end'>Min.</th>
						<th class='text-end'>Max.</th>
						<th *ngIf="canManage">-</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of numberProps">
						<!-- <td class="border-end">{{ pp.property.sort_order }}</td> -->
						<td class="border-end" [title]="pp.property.label">
							<a [routerLink]="['../../properties', pp.property_id]">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</a>
						</td>
						<td class="border-end">{{ pp.property.units }}</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end text-end">{{ niceDefault(pp) }}</td>
						<td class="border-end text-end"> {{ pp.min_value }} </td>
						<td class="border-end text-end"> {{ pp.max_value }} </td>
						<td *ngIf="canManage" class="border-end text-nowrap">
							<button type="button" class="btn btn-outline-primary btn-sm me-1"
								[routerLink]="['property', pp.property.id, 'edit']">
								<fa-icon icon="pencil" size="sm"></fa-icon>
							</button>
							<button type="button" class="btn btn-outline-danger btn-sm"
								(click)="deleteProperty(pp.property.id)">
								<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Label (Name)</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
						<th>Selections</th>
						<th *ngIf="canManage">-</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of otherProps">
						<!-- <td class="border-end">{{ pp.property.sort_order }}</td> -->
						<td class="border-end" [title]="pp.property.label">
							<a [routerLink]="['../../properties', pp.property_id]">
								{{ chopString(pp.property.label) }} ({{ pp.property.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
						<td class="border-end">
							{{ pp.selections }}
						</td>
						<td *ngIf="canManage" class="border-end text-nowrap">
							<button type="button" class="btn btn-outline-primary btn-sm me-1"
								[routerLink]="['property', pp.property.id, 'edit']">
								<fa-icon icon="pencil" size="sm"></fa-icon>
							</button>
							<button type="button" class="btn btn-outline-danger btn-sm"
								(click)="deleteProperty(pp.property.id)">
								<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!product && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this License Product.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this License Product</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this License Product, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control"
							required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canManage" type="button" [disabled]="loading" (click)="delete()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this License Product
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>