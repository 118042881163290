<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				ZEN Master Sites
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" routerLink="incidents">
				<fa-icon [icon]="['fas', 'dumpster-fire']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Incidents</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" routerLink="reports">
				<fa-icon icon="table" size="sm"></fa-icon>
				<span class="cp-action-button-text">Reports</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="loadData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>

		</div>
	</div>
</div>

<div class="list-wrapper">
	<form [formGroup]="theForm" *ngIf="theForm && showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-2">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter" formControlName="enabledFilter"
					[multiple]="false" (change)="onFormChange()" placeholder="Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="zmTypeChoices" bindValue="value" bindLabel="label" id="zmTypeFilters" formControlName="zmTypeFilters"
					[multiple]="true" [closeOnSelect]="false" (change)="onFormChange()" placeholder="Filter by Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="assessmentChoices" bindValue="value" bindLabel="value" id="assessmentFilters"
					formControlName="assessmentFilters" [multiple]="true" [closeOnSelect]="false" (change)="onFormChange()"
					placeholder="Filter by Assessment">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="recentTrafficChoices" bindValue="value" bindLabel="label" id="recentTrafficFilters"
					formControlName="recentTrafficFilters" [multiple]="true" (change)="onFormChange()"
					placeholder="Filter by Recent Traffic">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="activeBxChoices" bindValue="value" bindLabel="label" id="activeBxFilters"
					formControlName="activeBxFilters" [multiple]="true" (change)="onFormChange()" placeholder="Filter by Active Bx">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="accountOwnerChoices" bindValue="value" bindLabel="label" id="accountOwnerFilters"
					formControlName="accountOwnerFilters" [multiple]="true" (change)="onFormChange()" placeholder="Account Owner"
					[closeOnSelect]="false">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="techRepChoices" bindValue="value" bindLabel="label" id="techRepFilters" formControlName="techRepFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="SE/Tech-Rep" [closeOnSelect]="false">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="specialChoices" bindValue="value" bindLabel="label" id="specialFilters" formControlName="specialFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Special">
				</ng-select>
			</div>

		</div>
	</form>

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="recentZenCustomers && recentZenCustomers.length > 0" class="cp-light-message">
		Recently Viewed:
		<span *ngFor="let item of recentZenCustomers" class="me-1">
			<a [routerLink]="[item.id]">{{ item.name }}</a>;
		</span>
	</div>

	<div *ngIf="cachingUsageInfo && cachingUsageInfo !== ''" class="cp-light-message">
		<fa-icon icon="cog" size="sm" [spin]="true"></fa-icon>
		{{ cachingUsageInfo }}
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [routerLink]="[item.id]" class="cp-pointer"
				[ngClass]="{'text-danger': item.is_enabled === 0}">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success" ngbTooltip="Enabled"
						placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger" ngbTooltip="Disabled"
						placement="end"></fa-icon>
				</td>
				<td>
					<a [routerLink]="[item.id]">{{ item.dns_prefix }}</a>
				</td>
				<td><a [routerLink]="[item.id]">{{ item.name}}</a></td>
				<td class="text-nowrap">{{ item['__zenType'] }}</td>
				<td class="text-nowrap">{{ item.assessment }}</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item['__notifyWarning']" [icon]="['fas', 'exclamation-triangle']" size="sm" class="text-warning"
						ngbTooltip="None of this site's users are setup to receive ZEN Master notifications from the portal">
					</fa-icon>
					<fa-icon *ngIf="!item['__notifyWarning']" [icon]="['fas', 'check']" size="sm" class="text-success"
						ngbTooltip="At least one of this site's users is setup to receive ZEN Master notifications from the portal">
					</fa-icon>
				</td>

				<td>{{ item['__orgName'] }}</td>

				<td class="cp-icon-cell text-center" [title]="item['__accountOwner']">
					{{ item['__accountOwnerShort'] }}
				</td>
				<td class="cp-icon-cell text-center" [title]="item['__se']">
					{{ item['__seShort'] }}
				</td>

				<td class="text-nowrap text-end">
					{{ displayDays(item.created_days) }}
				</td>
				<td class="text-nowrap text-end">
					{{ displayDays(item.last_login_days) }}
				</td>

				<td *ngIf="item.is_enabled === 1" class="text-nowrap text-end">
					<fa-icon *ngIf="cachingUsageInfo && cachingUsageInfo !== ''" icon="cog" size="sm" [spin]="true"></fa-icon>
					<span *ngIf="!cachingUsageInfo || cachingUsageInfo === ''">
						{{ textTools.formattedMB(item['__zenUsage']) }}
					</span>
				</td>
				<td *ngIf="item.is_enabled === 1" class="text-nowrap text-end">
					<fa-icon *ngIf="cachingUsageInfo && cachingUsageInfo !== ''" icon="cog" size="sm" [spin]="true"></fa-icon>
					<span *ngIf="!cachingUsageInfo || cachingUsageInfo === ''">
						{{ textTools.formattedMB(item['__mcUsage']) }}
					</span>
				</td>

				<td *ngIf="item.is_enabled === 0" class="text-nowrap text-end">
					n/a
				</td>
				<td *ngIf="item.is_enabled === 0" class="text-nowrap text-end">
					n/a
				</td>

				<td class="text-nowrap text-end">{{ item.num_active_bx }}</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item['__marketplace'] !== ''" [icon]="appConstants.sectionIcons.marketplace" size="sm" placement="start"
						[ngbTooltip]="item['__marketplace']">
					</fa-icon>
				</td>
			</tr>
		</tbody>
	</table>
</div>