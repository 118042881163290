<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.bundles" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Shared Files
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" [routerLink]="['new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Bundle</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="getData(true)" [disabled]="loading">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-sharing-tab" aria-controls="nav-sharing" data-bs-target="#nav-sharing">
				<fa-icon [icon]="appConstants.sectionIcons.myfilesharing" size="sm">
				</fa-icon>
				Sharing Bundles
				<span *ngIf="!loading || items1.length > 0 " class="cp-tab-badge">
					{{ items1.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-reports-tab"
				aria-controls="nav-reports" data-bs-target="#nav-reports">
				Report Bundles
				<span *ngIf="!loading || items2.length > 0 " class="cp-tab-badge">
					{{ items2.length | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-sharing" role="tabpanel" aria-labelledby="nav-sharing-tab">
			<app-paging-search-bar #pagingSearchBar1 [parentApi]="getParentMethod1()"></app-paging-search-bar>

			<table *ngIf="!loading && itemsToShow1.length > 0" class="cp-std-table">
				<thead>
					<tr>
						<th *ngFor="let cd of columnDefs1" (click)="setSortBy1(cd.field)" class="text-center cp-pre-text cp-pointer"
							[ngClass]="{ 'text-success fw-bolder': displayOptions1.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
							[hidden]="!fieldsToShow1.includes(cd.field)">
							<span *ngIf="cd.label" [innerHTML]="cd.label"
								[ngClass]="{'fst-italic': displayOptions1.sortBy === cd.field && displayOptions1.sortDir === 'desc'}"
								[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
							<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
						</th>
					</tr>
				</thead>

				<tbody *ngIf="itemsToShow1 && !loading">
					<tr *ngFor="let item of itemsToShow1" [routerLink]="[item.id]" class="cp-pointer">
						<td class="text-center"><a [routerLink]="[item.id]">{{ item.id }}</a></td>
						<td class="text-nowrap">{{ item['__from'] }}</td>
						<td class="text-nowrap">{{ item['__to'] }}</td>
						<td>{{ item.information }}</td>
						<td class="text-center">{{ item['__num_files'] | number }}</td>
						<td class="text-nowrap">
							{{ item.added_on | date: appConstants.tableDateFmt }}
						</td>
						<td class="text-nowrap">
							{{ item.lock_at | date: appConstants.tableDateFmt }}
						</td>
						<td class="text-nowrap">
							{{ item.delete_at | date: appConstants.tableDateFmt }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-reports" role="tabpanel" aria-labelledby="nav-reports-tab">

			<app-paging-search-bar #pagingSearchBar2 [parentApi]="getParentMethod2()"></app-paging-search-bar>

			<table *ngIf="!loading && itemsToShow2.length > 0" class="cp-std-table">
				<thead>
					<tr>
						<th *ngFor="let cd of columnDefs2" (click)="setSortBy2(cd.field)" class="text-center cp-pre-text cp-pointer"
							[ngClass]="{ 'text-success fw-bolder': displayOptions2.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
							[hidden]="!fieldsToShow2.includes(cd.field)">
							<span *ngIf="cd.label" [innerHTML]="cd.label"
								[ngClass]="{'fst-italic': displayOptions2.sortBy === cd.field && displayOptions2.sortDir === 'desc'}"
								[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
							<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
						</th>
					</tr>
				</thead>

				<tbody *ngIf="itemsToShow2 && !loading">
					<tr *ngFor="let item of itemsToShow2" [routerLink]="[item.id]" class="cp-pointer">
						<td class="text-center"><a [routerLink]="[item.id]">{{ item.id }}</a></td>
						<td class="text-nowrap">{{ item['__to'] }}</td>
						<td>{{ item.information }}</td>
						<td class="text-center">{{ item['__num_files'] | number }}</td>
						<td class="text-nowrap">
							{{ item.added_on | date: appConstants.tableDateFmt }}
						</td>
						<td class="text-nowrap">
							{{ item.lock_at | date: appConstants.tableDateFmt }}
						</td>
						<td class="text-nowrap">
							{{ item.delete_at | date: appConstants.tableDateFmt }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>


	</div>
</div>