<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.files" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Files
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'cloud-upload-alt']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Upload Files</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div *ngIf="loading || refreshing" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<div class="list-wrapper">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow">
				<td class="cp-chopped-column-long"><a [routerLink]="[item.id]">{{ item.name }}</a></td>
				<td>{{ item.free_access_token }}</td>
				<td class="text-center" [attr.data-order]="item.uses.join('; ')">
					<fa-icon *ngIf="item.uses.length > 0" [icon]="['fas', 'check']" size="sm" [ngbTooltip]="item.uses.join('; ')">
					</fa-icon>
				</td>
				<td class="text-nowrap text-end" [attr.data-order]="item.size">
					{{ item.size | bytes: "1.0-0" }}
				</td>
				<td class="text-nowrap text-end" [attr.data-order]="item.downloads">
					{{ textTools.formatCount(item.downloads) }}
				</td>
				<td class="text-center" [title]="textTools.formatDateNiceUTC(item.added_on)">
					{{ miscTools.diffDays(now, item.added_on, true) | number }}
				</td>
				<td class="text-center">
					<span *ngIf="item.last_download">
						{{ miscTools.diffDays(now, item.last_download, true) | number }}
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>