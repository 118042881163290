<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item" aria-current="page">
				{{ zenmaster.name }}
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="zcp_org_id">Organization*</label>
			<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="zcp_org_id"
				formControlName="zcp_org_id">
			</ng-select>
		</div>

		<div class="form-group">
			<label for="zcp_type">Type*</label>
			<div class="form-check" *ngFor="let zcp_type of appConstants.zenMasterTypes">
				<input class="form-check-input" type="radio" formControlName="zcp_type" name="zcp_type"
					[id]="'zcp_type_' + zcp_type" [value]="zcp_type" />
				<label class="form-check-label"
					[for]="'zcp_type_' + zcp_type">{{appConstants.zenMasterTypeLabels[zcp_type]}}</label>
			</div>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div>
 -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()"
				[disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>