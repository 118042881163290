import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import AppConstants from "appshared/app-constants";
import * as Models from "appshared/shared-models";
import { AuthService } from "client/app/services/auth.service";
import { LoginService } from "../login/login.service";
import PopOverTools from 'appshared/popover-tools';

@Component({
	selector: "app-top-bar",
	templateUrl: "./top-bar.component.html",
	styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loggedIn = false;

	audioLevel: number = 5;

	constructor(
		private router: Router,
		private authService: AuthService,
		private loginService: LoginService
	) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((user) => {
			// console.log('in top bar - new user');
			this.loggedIn =
				!!user && user.id && user.role && +user.id !== 0 && user.role !== "";
			this.authUser = user;

			if (localStorage.getItem(AppConstants.volumeStorageKey)) {
				this.audioLevel = +localStorage.getItem(AppConstants.volumeStorageKey);
				if (isNaN(this.audioLevel)) this.audioLevel = 0;
			} // if
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	logout() {
		this.loginService.logout().subscribe(() => {
			this.loggedIn = false; // just to be sure
			this.authUser = null; // just to be sure
			this.router.navigate([AppConstants.urls.login]);
		});
	}

	toggleNav() {
		this.authService.toggle$.next();
	}

	// --------------------------------------------------------------------
	changeVolume(amount: number) {
		let newVolume: number = this.audioLevel + amount;
		this.setVolume(newVolume);
	} // changeVolume

	// --------------------------------------------------------------------
	setVolume(newVolume: number) {
		if (newVolume < 0) newVolume = 0;
		if (newVolume > 10) newVolume = 10;
		this.audioLevel = newVolume;
		localStorage.setItem(AppConstants.volumeStorageKey, this.audioLevel.toString());
		if (this.audioLevel > 0) this.playSound('Air Plane Ding-SoundBible.com-496729130.mp3');
	} // setVolume

	// --------------------------------------------------------------------
	setVolumeFromRange() {
		let newVolume: number = 0;
		if (document.getElementById('volumeSlider') && document.getElementById('volumeSlider')['value'])
			newVolume = +document.getElementById('volumeSlider')['value'];
		if (isNaN(newVolume)) newVolume = 0;
		this.setVolume(newVolume);
	} // setVolume

	// --------------------------------------------------------------------
	playSound(fileToPlay: string) {
		const volume: number = 5;
		// this.uiAlertsService.addMsg('Play sound.' + fileToPlay, 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		PopOverTools.playSound(fileToPlay, this.audioLevel);
	}

}
