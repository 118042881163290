import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { BuildsService } from '../builds.service';
import { ProductsService } from '../../products/products.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { PlatformsService } from '../../platforms/platforms.service';

import { NotificationUsersTableComponent } from 'client/app/components/shared/notification-users-table/notification-users-table.component';

@Component({
	selector: 'app-build-notification-form',
	templateUrl: './build-notification-form.component.html',
	styleUrls: ['./build-notification-form.component.scss']
})
export class BuildNotificationFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild('notificationUsersTable1') notificationUsersTable1: NotificationUsersTableComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// 'standard' edit stuff
	id: number;
	build: Models.Build;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	product: Models.Product;
	platformNames: string[] = [];

	users: Models.User[] = [];
	numSubscribed: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private buildsService: BuildsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private usersService: UsersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.build = this.buildsService.getOne(this.id);
				if (!this.build || this.build == null || this.build.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.userSubscription = this.authService.user.subscribe((authUser) => {
						this.authUser = authUser;
						if (authUser) {

							// this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-build-notifications');
						}
					});
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.product = this.productsService.getOne(this.build.product_id);

		const platformIDs = [];
		for (const pp of this.build.platform_files)
			platformIDs.push(pp.platform_id);

		const allPlatforms = this.platformsService.getAll();
		const platforms: Models.Platform[] = MiscTools.sortPlatforms(allPlatforms, platformIDs);
		this.platformNames = [];
		for (const platform of platforms)
			this.platformNames.push(platform.name);

		const userIDs = await this.buildsService.fetchUserIDs(this.id);
		const allUsers = this.usersService.getAll();
		this.users = [];
		this.numSubscribed = 0;

		for (const user of allUsers) {
			if (userIDs.includes(user.id) && user.is_deleted === 0) {
				user['__reason'] = ValidationTools.getBuildProductNotificationState(user, this.build.product_id);
				if (user['__reason'] === '') this.numSubscribed++;
				this.users.push(user);
			} // if
		} // for

		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			message: new UntypedFormControl(null)
		});

		this.loading = false;
		await MiscTools.delay(100);
		if (this.notificationUsersTable1)
			this.notificationUsersTable1.updateContent(this.users, 'cp-build-notification-users');
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const message = this.theForm.value.message;
		if (confirm('Are you sure you want to send a notification for this build to ' + this.numSubscribed + ' users(?)')) {
			try {
				await this.buildsService.sendNotification(this.id, message);
				this.uiAlertsService.addMsg('The notification has been queued.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.onCancel();
			} catch (e) {
				this.saving = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} else {
			this.saving = false;
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return ValidationTools.hasFlag(obj, flag);
	};

	workoutNotificationSendTime(user: Models.User): Date {
		return ValidationTools.workoutNotificationSendTime(user);
	}
}
