import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { MyKeysService } from '../my-keys.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { LicensingService } from '../../licensing/licensing.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { ReportsService } from '../../reports/reports.service';
import { LicensesTableComponent } from 'client/app/components/shared/licenses-table/licenses-table.component';
import { KeyFeaturesComponent } from 'client/app/components/shared/key-features/key-features.component';
import { KeyMetersComponent } from 'client/app/components/shared/key-meters/key-meters.component';

@Component({
	selector: 'app-license-multi',
	templateUrl: './license-multi.component.html',
	styleUrls: ['./license-multi.component.scss']
})
export class LicenseMultiComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;
	sharedLicenseTools = SharedLicenseTools;
	miscTools = MiscTools;

	@ViewChild('allLicensesTable') allLicensesTable: LicensesTableComponent = null;
	@ViewChild('activeLicensesTable') activeLicensesTable: LicensesTableComponent = null;
	@ViewChild('searchLicensesTable') searchLicensesTable: LicensesTableComponent = null;
	@ViewChild('keyFeatures') keyFeatures: KeyFeaturesComponent = null;
	@ViewChild('hostFeatures') hostFeatures: KeyFeaturesComponent = null;
	@ViewChild(KeyMetersComponent) keyMeters: KeyMetersComponent = null;

	staffMode: boolean = false; // determimes if certain things are shown in UI/code that's identical between staff and non-staff pages

	pageMode: string = 'activation'; // or host

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	hostids: Models.UserHostId[] = [];
	hostidLabels: {} = {};

	key: string;
	id: number;
	registeredKey = false;

	userKey: Models.UserLicenseKey;
	activation: Models.LPActivation;

	loading = true;

	activationIdAndHostIdsCombos: any[] = [];

	// *********************************************************
	// for activation page mode
	// *********************************************************

	licensesToShow: Models.LPLicense[] = [];

	selfServicePackageId: number = 0;
	selfServiceTemplateId: number = 0;
	showSelfServiceExtendButton: boolean = false;
	showSelfServiceCreateButton: boolean = false;

	expiryMode = '';
	keyExpiryCalc: Date;

	orgUsers: Models.User[] = [];
	sharedWithUserIds: number[] = [];
	sharedWithUsers: Models.User[] = [];

	searchForm: UntypedFormGroup;

	searching = false;
	showSearchResults = false;
	searchResults: Models.LPLicense[] = [];
	hostidFilter = '';

	activeLicenses: Models.LPLicense[] = [];

	showAdminButton = false;
	showPurge = false;
	showSpecial = false;
	showExpiry = false;

	showLastVersion = false;

	showMeters = false;

	// metersEnforced = true;
	// showMeterSummary = false;

	canShowMeterProtocolEquiv: boolean = false;
	showMeterProtocolEquiv: boolean = false;

	keyWarnings = [];
	// gapWarnings = [];
	meterWarnings = [];
	nonEnforcedWarnings = [];

	recentUsedHosts: string[] = [];
	recentHostsPopup = 'Number of Activations (host IDs) reporting non-zero traffic (either via meter or protocol) over the last '
		+ AppConstants.recentUsedHostsDays + ' days';
	recentHostIconPopup = 'Reporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';
	offlineHostIconPopup = 'Might be offline or unable to communicate with license server.  Hasn\'t reported in last day, but did report in within the last ' + AppConstants.hostOfflineMaxDays + ' days.';

	// upgradeIconPopup = 'Reporting in over the last ' + AppConstants.recentUsedHostsDays + ' days via meter, but not protocols.  Needs v13+ upgrade.';

	showMaxHostsExtra = false;
	maxHostsMsg = 'Limited to showing ' + AppConstants.maxLicenseRowsToFetch.toLocaleString() + ' recent activations.';

	keyProduct: Models.LicenseProduct;
	keyProductProperties: Models.LicenseProductProperty[];

	anyMeterLabels: boolean = false;
	meterLabelsByType: any = {};

	keySnoozes: Models.UserLicenseKeySnooze[] = [];

	prevId: number = -1;
	nextId: number = -1;
	prevNote: string = '';
	nextNote: string = '';

	// *********************************************************
	// for host page mode
	// *********************************************************

	hostid = '';
	label = '';

	licenses: Models.LPLicense[] = [];

	versionHistory: Models.LPVersionHistory[] = [];
	keyProducts: Models.LicenseProduct[] = [];
	expiryExtraActivation: string = '';
	meterTypes: string[] = [];

	selectedLicenseLoading = false;
	selectedLicenseID = 0;

	showSelectedKeyLicFeatures = false;

	// props from staff version that aren't really used in this one (no host id filtering)
	// but here so the UI bits can be copied over
	activationIDsWithUsage: number[] = [];
	filterID: number = 0;
	filterKey: string = '';

	// *********************************************************
	// common usage stuff
	// *********************************************************

	rateNumFmt = '1.1-1';
	percFmt = '1.1-1';

	showNoUseDays: boolean = true;
	showRawData: boolean = false;

	timeModes: any[] = [
		{ value: 'month', label: 'Month' },
		{ value: 'quarter', label: 'Quarter' },
		{ value: 'year', label: 'Year' }
	];
	timeMode: string = 'month';
	timeModeLabel: string = 'Month';

	showSetsButton: boolean = false;

	// all defined sets
	availableSets: Models.ProtocolSet[] = [];

	// combined linked + session sets
	selectedProtocolSets: Models.ProtocolSet[] = [];
	selectedChronoSets: Models.ProtocolSet[] = [];

	// phantom sets for doing meter equiv.
	phantomSets: Models.ProtocolSet[] = [];
	phantomSetIds: any = {};

	// just session sets
	supportsSessionSet: boolean = false;
	sessionSetIdentifier: string = '';
	sessionProtocolSets: Models.ProtocolSet[] = [];
	sessionChronoSets: Models.ProtocolSet[] = [];

	// just linked sets
	supportsLinkedSet: boolean = false;
	linkedProtocolSets: Models.ProtocolSet[] = [];
	linkedChronoSets: Models.ProtocolSet[] = [];

	protocolSetsPosition: string = 'top';
	canManageSets: boolean = false;

	protocolShowAllMonths: boolean = false;

	// *********************************************************
	// meter usage for key
	showMeterTraffic = false;
	byDayMeterBuffer = 200;
	byMonthMeterBuffer = 200;

	monthlyMeterUsage: Models.LPMeterReportByMonth[] = [];
	chunkedMeterUsage: Models.LPMeterReportByMonth[] = [];
	meterProductsToShow: string[] = [];

	byDayMeterLoading = false;
	byDayMeterProduct = '';
	byDayMeterMonth = 0;
	byDayMeterYear = 0;
	byDayMeterUsage: Models.LPMeterReportByDay[] = [];
	byDayMeterTotal = 0;
	byDayMeterAvgAbsChg = 0;

	showMeterTypeExtra = false;

	showOverageCounts: any = {};
	monthlyProjectedAmounts: any = {};

	// *********************************************************
	// protocol usage for key
	rotateAxis = true;
	protocolReady = false;

	showProtocolTraffic = false;
	byDayProtocolBuffer = 210;
	byMonthProtocolBuffer = 210;

	monthlyProtocolUsage: Models.LPMeterReportByMonth[] = [];
	chunkedProtocolUsage: Models.LPMeterReportByMonth[] = [];
	protocolProducts: string[] = [];
	protocolProductsToShow: string[] = [];
	protocolProductsVisible: string[] = [];
	onlyShowTotals: boolean = false;

	protocolTimeChunks: any[] = [];
	protocolTimeChunksToShow: any[] = [];

	byDayProtocolLoading = false;
	byDayProtocolProduct = '';
	byDayProtocolMonth = 0;
	byDayProtocolYear = 0;
	byDayProtocolUsage: Models.LPMeterReportByDay[] = [];
	byDayProtocolTotal = 0;
	byDayProtocolAvgAbsChg = 0;

	hasIncomingProtocolData: boolean = false;
	hasTranscodeProtocolData: boolean = false;
	hasOutgoingProtocolData: boolean = false;

	// *********************************************************
	// protocol (chrono) usage for key
	showChronoTraffic = false;
	byDayChronoBuffer = 210;
	byMonthChronoBuffer = 210;

	monthlyChronoUsage: Models.LPMeterReportByMonth[] = [];
	chunkedChronoUsage: Models.LPMeterReportByMonth[] = [];
	chronoProducts: string[] = [];
	chronoProductsToShow: string[] = [];

	chronoTimeChunks: any[] = [];
	chronoTimeChunksToShow: any[] = [];
	chronoMonthTotals = {};

	byDayChronoLoading = false;
	byDayChronoProduct = '';
	byDayChronoMonth = 0;
	byDayChronoYear = 0;
	byDayChronoUsage: Models.LPMeterReportByDay[] = [];
	byDayChronoTotal = 0;
	byDayChronoAvgAbsChg = 0;

	hasIncomingChronoData: boolean = false;
	hasTranscodeChronoData: boolean = false;
	hasOutgoingChronoData: boolean = false;

	// *********************************************************
	// billing code related
	billingCodesByCode: any = {};

	billingCodeDisplay: string = 'code'; // or label

	// 0 - means show all usage together, -1 - means show no-code usage, # - show usage for that b-code
	usedProtocolBillingCodes: Models.BillingCode[] = [];
	activeProtocolBillingCode: number = -1;
	activeProtocolBillingCodeLabel = 'All Usage Data';
	activeProtocolBillingCodeShortLabel = '';
	protocolBillingCodeToSearch: string = '';

	usedChronoBillingCodes: Models.BillingCode[] = [];
	activeChronoBillingCode: number = -1;
	activeChronoBillingCodeLabel = 'All Usage Data';
	activeChronoBillingCodeShortLabel = '';
	chronoBillingCodeToSearch: string = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private organizationsService: OrganizationsService,
		private reportsService: ReportsService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService) {

		this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (isNaN(this.id) || this.id === 0)
				this.key = params.get('id');
			else
				this.registeredKey = true;

			this.hostid = params.get('hostid');

			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.showAdminButton = authUser && ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
				this.loadData();
			});
		});
	}

	// *********************************************************
	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-myKeyView.settings'))
				settings = JSON.parse(localStorage.getItem('cp-myKeyView.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('rotateAxis')) this.rotateAxis = settings.rotateAxis;
		if (settingKeys.includes('showRawData')) this.showRawData = settings.showRawData;
		if (settingKeys.includes('protocolSetsPosition')) this.protocolSetsPosition = settings.protocolSetsPosition;
		if (settingKeys.includes('billingCodeDisplay')) this.billingCodeDisplay = settings.billingCodeDisplay;
		if (settingKeys.includes('onlyShowTotals')) this.onlyShowTotals = settings.onlyShowTotals;
		if (settingKeys.includes('showNoUseDays')) this.showNoUseDays = settings.showNoUseDays;
		if (settingKeys.includes('showMeterProtocolEquiv')) this.showMeterProtocolEquiv = settings.showMeterProtocolEquiv;
	}

	// *********************************************************
	saveSettings() {
		let settings: any = {
			rotateAxis: this.rotateAxis,
			showRawData: this.showRawData,
			protocolSetsPosition: this.protocolSetsPosition,
			billingCodeDisplay: this.billingCodeDisplay,
			onlyShowTotals: this.onlyShowTotals,
			showNoUseDays: this.showNoUseDays,
			showMeterProtocolEquiv: this.showMeterProtocolEquiv
		};
		localStorage.setItem('cp-myKeyView.settings', JSON.stringify(settings));
	}

	// *********************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// *********************************************************
	async loadData(forceRefresh = false) {
		this.loading = true;

		if (this.allLicensesTable)
			this.allLicensesTable.updateContent([], 'cp-user-key-all-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });

		if (this.activeLicensesTable)
			this.activeLicensesTable.updateContent([], 'cp-user-key-active-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });

		if (this.searchLicensesTable)
			this.searchLicensesTable.updateContent([], 'cp-user-key-search-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });

		if (!this.registeredKey) {
			// console.log('looks like a pass in by key ' + this.key);
			// call service to check to see if it is a user key
			// if not, return stub with activation...

			this.userKey = await this.myKeysService.resolveKey(this.key);
			if (this.userKey) this.id = this.userKey.id;
			this.registeredKey = this.userKey.id !== 0;
		} else {
			const retKey = await this.myKeysService.fetchKey(this.id);
			this.userKey = retKey;
		}

		if (!this.userKey || this.userKey == null || (this.registeredKey && this.userKey.id === 0) || !this.userKey.activation) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		// figure out page mode...
		this.pageMode = 'activation';
		if (this.hostid && this.hostid !== '')
			this.pageMode = 'hostid';

		this.availableSets = await this.licensingAdminService.getProtocolSets();
		this.linkedProtocolSets = [];
		this.linkedChronoSets = [];
		this.showSetsButton = false;

		if (this.pageMode === 'activation' || this.pageMode === 'hostid') {
			this.sessionSetIdentifier = this.userKey.activation_id.toString();

			// uncomment the following block to enable protocol sets for non-staff
			const linkedSets: Models.ActivationProtocolSet[] = await this.myKeysService.getActivationProtocolSets(this.userKey);
			for (const linkedSet of linkedSets) {
				const idx = MiscTools.findIndex(this.availableSets, linkedSet.set_id);
				if (idx !== -1) {
					if (!isNaN(linkedSet.projected)) this.availableSets[idx]['projected'] = linkedSet.projected;
					if (!isNaN(linkedSet.current_usage)) this.availableSets[idx]['current_usage'] = linkedSet.current_usage;

					if (linkedSet.data_type === 'protocol-data')
						this.linkedProtocolSets.push(this.availableSets[idx]);
					else if (linkedSet.data_type === 'protocol-time')
						this.linkedChronoSets.push(this.availableSets[idx]);
				} // if
			} // for
			this.supportsLinkedSet = true;
			this.showSetsButton = linkedSets.length > 0;
		} // if

		this.selectedProtocolSets = this.linkedProtocolSets;
		this.selectedChronoSets = this.linkedChronoSets;

		this.activation = this.userKey.activation;

		this.hostids = await this.myKeysService.getUserHostids(forceRefresh);
		this.hostidLabels = {};
		for (const hi of this.hostids)
			this.hostidLabels[hi.hostid] = hi.label;

		if (this.pageMode === 'activation') {
			// is this a package key, is it out of activations and can it be extended
			await this.workoutSelfService();

			this.activation.meters.sort(SharedLicenseTools.meterSort);

			this.searchForm = new UntypedFormGroup({
				hostidFilter: new UntypedFormControl(this.hostidFilter)
			});

			this.expiryMode = SharedLicenseTools.getExpiryMode(this.activation);
			this.keyExpiryCalc = SharedLicenseTools.getKeyExpiration(this.activation);

			this.keyWarnings = LicenseValidationTools.getKeyWarnings(this.activation, 'user');
			// this.gapWarnings = LicenseValidationTools.getKeyGapWarnings(this.activation);
			// this.meterWarnings = LicenseValidationTools.getMeterCommTypeWarnings(this.activation);

			this.showPurge = (this.userKey.is_deleted === 1 && this.userKey.add_method !== 'zcp-package-generated' && this.userKey.add_method !== 'wix');

			const now = new Date();

			if (this.expiryMode === 'meter') {
				this.showOverageCounts = {};
				this.meterLabelsByType = {};
				for (const meter of this.activation.meters) {
					if (meter.enabled === 1 && meter.resets === 'monthly' && +meter.projected > 0)
						this.showOverageCounts[meter.product]++;

					let starts = new Date(meter.created_at);
					if (meter.starts_at) starts = new Date(meter.starts_at);
					const exp = new Date(meter.expires_at);
					if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime() && meter.label && meter.label !== '') {
						if (!this.meterLabelsByType[meter.product]) this.meterLabelsByType[meter.product] = [];
						if (!this.meterLabelsByType[meter.product].includes(meter.label)) this.meterLabelsByType[meter.product].push(meter.label);
					} // if
				} // for
			} // if

			if (this.registeredKey) {
				this.keySnoozes = [];
				const userSnoozes = await this.myKeysService.getUserSnoozes();
				for (const snooze of userSnoozes)
					if (snooze.user_key_id === this.userKey.id)
						this.keySnoozes.push(snooze);

				this.keySnoozes.sort((a, b) => (a.expires_at > b.expires_at) ? 1 : -1);

				this.prevId = -1;
				this.nextId = -1;

				const allUsersKeys = await this.myKeysService.getUserKeys(false, forceRefresh);

				const activeKeyIds: number[] = [];
				const inactiveKeyIds: number[] = [];
				for (const key of allUsersKeys) {
					const exp = SharedLicenseTools.getKeyExpiration(key.activation, '', true);
					if ((key.activation.enabled === 0 && !SharedLicenseTools.isSpecialKey(key.activation)) || (exp != null && MiscTools.hasExpired(exp)))
						activeKeyIds.push(key.id);
					else
						inactiveKeyIds.push(key.id);
				} // for

				const allKeyIds: number[] = activeKeyIds.concat(inactiveKeyIds);
				if (allKeyIds && allKeyIds.length > 1) {
					const idx = allKeyIds.indexOf(this.id);
					if (idx !== -1) {
						if (idx > 0)
							this.prevId = allKeyIds[idx - 1];
						else
							this.prevId = allKeyIds[allKeyIds.length - 1];

						if (idx === allKeyIds.length - 1)
							this.nextId = allKeyIds[0];
						else
							this.nextId = allKeyIds[idx + 1];

						this.prevNote = 'Go to your previous key.';
						this.nextNote = 'Go to your next key.';

						this.prevNote += ' Current key is # ' + (idx + 1) + ' of ' + allKeyIds.length;
						this.nextNote += ' Current key is # ' + (idx + 1) + ' of ' + allKeyIds.length;
					} // if
				} // if

			} // if

			if (!this.activation.parsedParameters)
				this.activation.parsedParameters = SharedLicenseTools.parseRubyHash(this.activation.parameters);

			this.keyProduct = await this.licensingAdminService.getProductByName(this.activation.product);
			if (this.keyProduct) {
				this.keyProductProperties = await this.licensingAdminService.getProductProperties(false, this.keyProduct.id);

				this.showMeters = this.keyProduct.show_meters && this.keyProduct.show_meters === 2;
				this.protocolReady = this.keyProduct.show_protocols && this.keyProduct.show_protocols === 2; // show to basic user
				this.showLastVersion = this.keyProduct.show_protocols && this.keyProduct.show_protocols > 0;
				// // "temporary" hack!!!!!!
				// if (!this.protocolReady && this.keyProduct.show_protocols && this.keyProduct.show_protocols > 0
				// 	&& this.keyProduct.name.startsWith('broadcaster')
				// 	&& TextTools.getUserPropValue(this.authUser, AppConstants.showBxProtocolStatsOverride) === 'yes') {
				// 	this.protocolReady = true;
				// } // if
			} // if

			if (this.keyMeters)
				this.keyMeters.updateContent(this.activation, this.linkedProtocolSets, this.linkedChronoSets, { staffMode: false });

			await this.workoutHostIDProperties();

			await MiscTools.delay(50);

			if (this.keyFeatures)
				this.keyFeatures.updateContent(this.activation.parsedParameters, this.activation.product, { staffMode: false });

			if (this.allLicensesTable)
				this.allLicensesTable.updateContent(this.activation.licenses, 'cp-user-key-all-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });

			if (this.activeLicensesTable)
				this.activeLicensesTable.updateContent(this.activeLicenses, 'cp-user-key-active-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });

			this.showExpiry = this.expiryMode && this.expiryMode !== 'meter' || this.showMeters;

			if (this.showMeters || this.protocolReady)
				this.recentUsedHosts = await this.myKeysService.getActiveHostIDs(this.userKey);

			if (this.showMeters) {
				this.monthlyMeterUsage = await this.myKeysService.getActivationMonthlyUsage(this.userKey, 'meter-data');
				await this.workoutMeterUsage();
				this.showMeterTraffic = this.meterProductsToShow.length !== 0;
			}

			if (this.protocolReady) {
				this.monthlyProtocolUsage = await this.myKeysService.getActivationMonthlyUsage(this.userKey, 'protocol-data', AppConstants.groupByBillingCodeToken);
				await this.workoutProtocolUsage();
				this.showProtocolTraffic = this.protocolProducts.length !== 0;
			}

			if (this.registeredKey) {
				this.orgUsers = await this.mySettingsService.getOrgUsers();
				this.sharedWithUserIds = await this.myKeysService.getSharedWithUsers(this.userKey);

				for (const userID of this.sharedWithUserIds) {
					const idx = MiscTools.findIndex(this.orgUsers, userID);
					if (idx !== -1) this.sharedWithUsers.push(this.orgUsers[idx]);
				}
				this.sharedWithUsers.sort((a, b) => a.name.localeCompare(b.name));
			} // if

		} else if (this.pageMode === 'hostid') {

			this.keyProducts = await this.licensingAdminService.getProducts(forceRefresh);

			const idx = MiscTools.findIndexGeneric(this.hostids, 'hostid', this.hostid);
			this.label = '';
			if (idx !== -1) this.label = this.hostids[idx].label;

			this.licenses = [];
			for (const license of this.activation.licenses)
				if (license.hostid === this.hostid)
					this.licenses.push(license);

			// newest first
			this.licenses.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);

			const keyProduct = await this.licensingAdminService.getProductByName(this.activation.product);
			this.meterTypes = [];

			this.expiryExtraActivation = '';
			const expiryMode = SharedLicenseTools.getExpiryMode(this.activation);
			if (expiryMode === 'meter')
				this.expiryExtraActivation = 'Licenses issued for keys with meters will regularly update their expiration date as they report meter usage.';

			if (keyProduct && keyProduct.show_meters && keyProduct.show_meters === 2) {
				for (const meter of this.activation.meters) {
					const niceName = SharedLicenseTools.niceProtocol(meter.product, true);
					if (!this.meterTypes.includes(niceName)) this.meterTypes.push(niceName);
				}
				this.meterTypes.sort();

				this.monthlyMeterUsage = await this.myKeysService.getHostMonthlyUsage(this.userKey, this.hostid, 'meter-data');
				await this.workoutMeterUsage();

				this.showMeterTraffic = this.meterProductsToShow.length !== 0;
			}

			this.protocolReady = keyProduct.show_protocols && keyProduct.show_protocols === 2; // show to basic user

			// // "temporary" hack!!!!!!
			// if (!this.protocolReady && keyProduct.show_protocols && keyProduct.show_protocols > 0
			// 	&& keyProduct.name.startsWith('broadcaster')
			// 	&& TextTools.getUserPropValue(this.authUser, AppConstants.showBxProtocolStatsOverride) === 'yes') {
			// 	this.protocolReady = true;
			// } // if

			if (this.protocolReady) {
				this.monthlyProtocolUsage = await this.myKeysService.getHostMonthlyUsage(this.userKey, this.hostid, 'protocol-data', AppConstants.groupByBillingCodeToken);
				await this.workoutProtocolUsage();
				this.showProtocolTraffic = this.protocolProducts.length !== 0;
			}

			this.versionHistory = await this.myKeysService.getVersionHistory(this.userKey, this.hostid);
		} // if

		this.loading = false;
	}

	// *********************************************************
	async doSearch() {
		this.hostidFilter = this.searchForm.value.hostidFilter;

		if (!this.hostidFilter || this.hostidFilter === '')
			return;

		this.searching = true;
		this.showSearchResults = false;

		this.searchResults = await this.myKeysService.searchHosts(this.userKey, this.hostidFilter);

		if (this.searchResults && this.searchResults.length > AppConstants.maxLicenseRowsToFetch) {
			this.searchResults.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);
			this.searchResults.pop(); // remove the last one..
			// this.maxMessage = 'Maximum keys (' + AppConstants.maxLicenseRowsToFetch + ') retrieved.';
		} else {
			// this.maxMessage = '';
		}

		for (const item of this.searchResults) {
			if (this.hostidLabels[item.hostid])
				item['__label'] = this.hostidLabels[item.hostid]
		} // for

		this.searching = false;
		this.showSearchResults = true;

		await MiscTools.delay(100);
		if (this.searchLicensesTable)
			this.searchLicensesTable.updateContent(this.searchResults, 'cp-user-key-search-licenses', { staffMode: false, showMeters: this.showMeters, protocolReady: this.protocolReady, key: this.activation.key });
	}

	// *********************************************************
	// getUsageForProtocolForMonth(product: string, year: number, month: number) {
	// 	for (const record of this.monthlyProtocolUsage)
	// 		if (record.product === product && record.year === year && record.month === month)
	// 			return record.used;
	// 	return 0;
	// }

	// *********************************************************
	async recover() {
		this.loading = true;
		this.userKey.is_deleted = 0;
		this.showPurge = false;
		this.uiAlertsService.addMsg('The key has been recovered from the trash.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		this.userKey = await this.myKeysService.updateKey(this.userKey);
		await this.myKeysService.getUserKeys(false, true);
		await this.myKeysService.getUserKeys(true, true);
		await this.loadData(true);
	}

	// *********************************************************
	async delete() {
		if (confirm('Are you sure you want to move this key to the trash?')) {
			this.loading = true;
			this.userKey.is_deleted = 1;
			this.uiAlertsService.addMsg('The key has been moved to the trash.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			const result = await this.myKeysService.updateKey(this.userKey);
			await this.myKeysService.getUserKeys(false, true);
			await this.myKeysService.getUserKeys(true, true);
			await this.loadData(true);
		}
	}

	// *********************************************************
	async purge() {
		if (confirm('Are you sure you want to permanently remove this keys from your account?')) {
			const result = await this.myKeysService.purgeKey(this.userKey);
			if (result) {
				this.uiAlertsService.addMsg('The key has removed from your account.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate(['..'], { relativeTo: this.route });
			} else {
				return false;
			}
		}
	}

	// *********************************************************
	async registerKey() {
		const result = await this.myKeysService.registerKey(this.userKey.activation_key, '');
		if (result) {
			this.id = result.id;
			this.registeredKey = true;
			this.loadData();
		} // if
	}

	// *********************************************************
	async workoutSelfService() {
		this.selfServicePackageId = 0;
		this.selfServiceTemplateId = 0;
		this.showSelfServiceExtendButton = false;
		this.showSelfServiceCreateButton = false;

		// only allow for extending or creating new keys if this key is registered to the user
		if (this.registeredKey) {
			const selfServiceInfo: any = await this.myKeysService.getSelfServiceInfo(this.userKey);

			// console.log(selfServiceInfo);

			if (selfServiceInfo) {
				this.selfServicePackageId = +selfServiceInfo.packageId;
				this.selfServiceTemplateId = +selfServiceInfo.templateId;
				this.showSelfServiceExtendButton = selfServiceInfo.canExtendActivatons;
				this.showSelfServiceCreateButton = selfServiceInfo.canCreateAnother;
			} // if
		} // if
	}

	// *********************************************************
	async generateKeyViaTemplate() {
		try {
			this.loading = true;
			const ret = await this.myKeysService.generateKeyViaTemplate(this.selfServicePackageId, this.selfServiceTemplateId);
			if (ret) {
				this.uiAlertsService.addMsg('Your new key is available.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate(['/' + AppConstants.urls.mykeys, ret.id]);
			} else {
				this.loading = false;
				this.uiAlertsService.addMsg('There was an error adding your key.', 'error');
			}
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}

	// *********************************************************
	async addActivationsToKey() {
		try {
			this.loading = true;

			const ret = await this.myKeysService.addActivationsToKey(this.userKey);
			if (ret) {
				await this.myKeysService.getUserKeys(false, true);
				await this.myKeysService.getUserKeys(true, true);
				await this.loadData(true);
				this.uiAlertsService.addMsg('The key\'s # of activations has been increased.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} else {
				this.loading = false;
				this.uiAlertsService.addMsg('There was an error adding your key.', 'error');
			}
		} catch (e) {
			this.loading = false;
			this.uiAlertsService.addMsg(e.message, 'error');
		}
	}

	// *********************************************************
	async showLicenseFeatures(id: number) {
		this.showSelectedKeyLicFeatures = false;

		if (this.selectedLicenseID === id) {
			this.selectedLicenseID = 0;
			return;
		}

		this.selectedLicenseLoading = true;
		this.selectedLicenseID = id;

		// get this license...
		const licIdx = MiscTools.findIndex(this.licenses, id);
		if (licIdx !== -1) {
			const selectedKeyLicParsedParameters: any = SharedLicenseTools.parseRubyHash(this.licenses[licIdx].parameters);

			this.showSelectedKeyLicFeatures = true;
			this.selectedLicenseLoading = false;

			await MiscTools.delay(200);

			if (this.hostFeatures)
				this.hostFeatures.updateContent(selectedKeyLicParsedParameters, this.licenses[licIdx].product, { staffMode: true, hostMode: true });
		} // if
	}

	// *********************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].basic_label;
	}

	// *********************************************************
	async workoutHostIDProperties() {
		this.licensesToShow = [];
		if (!this.activation || !this.activation.licenses || this.activation.licenses.length === 0)
			return;

		// sort licenses by created date (newest to oldest)
		this.activation.licenses.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);

		this.showMaxHostsExtra = false;
		if (this.activation.licenses.length > AppConstants.maxLicenseRowsToFetch) {
			this.showMaxHostsExtra = true;
			while (this.activation.licenses.length > AppConstants.maxLicenseRowsToFetch) {
				this.activation.licenses.pop();
			}
		} // if

		let tmpActive: Models.LPLicense[] = [];
		// go through all of them and count each hostid
		const hostCounts: any = {};
		for (const item of this.activation.licenses) {
			// console.log(license.hostid + ' ' + license.created_at);
			if (!hostCounts[item.hostid]) hostCounts[item.hostid] = 0;
			hostCounts[item.hostid]++;

			if (this.hostidLabels[item.hostid])
				item['__label'] = this.hostidLabels[item.hostid]

			if (!this.showMaxHostsExtra && SharedLicenseTools.licenseRecentlyReported(item, AppConstants.maxActiveLicenseDays))
				tmpActive.push(item);
		} // for

		if (this.showMaxHostsExtra && this.activation.discard_licenses === 0) {
			// call back end to get active licenses
			this.activeLicenses = await this.myKeysService.getKeysActiveLicenses(this.userKey, AppConstants.maxActiveLicenseDays);
		} else {
			this.activeLicenses = tmpActive;
		} // if

		// sort oldest to newest
		this.activation.licenses.sort((a, b) => ((new Date(a.created_at)).getTime() > (new Date(b.created_at)).getTime()) ? 1 : -1);

		this.licensesToShow = this.activation.licenses;
	}

	// *********************************************************
	async filterTraffic(activationID: number) {
		// removed from this version - just a stub to support code in UI
	}

	// *********************************************************
	async deleteSnooze(snooze: Models.UserLicenseKeySnooze) {
		if (confirm('Are you sure you want to cancel this?')) {
			try {
				await this.myKeysService.deleteUserSnooze(snooze);
				const idx = MiscTools.findIndex(this.keySnoozes, snooze.id);
				if (idx !== -1)
					this.keySnoozes.splice(idx, 1);

			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	}

	// *********************************************************
	// everything after this point should be identical between the staff and non-staff versions
	// *********************************************************

	// *********************************************************
	async selectMeterDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		if (this.byDayMeterProduct !== product || this.byDayMeterMonth !== month || this.byDayMeterYear !== year) {
			this.byDayMeterLoading = true;
			this.byDayMeterProduct = product;
			this.byDayMeterMonth = month;
			this.byDayMeterYear = year;
			this.byDayMeterTotal = 0;
			this.byDayMeterAvgAbsChg = 0;
			this.byDayMeterUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayMeterUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, product, year, month);
				else if (this.pageMode === 'hostid')
					this.byDayMeterUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, product, year, month, '', this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayMeterUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, product, year, month, '');
			} else {
				if (this.pageMode === 'activation')
					this.byDayMeterUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, product, year, month);
				else if (this.pageMode === 'hostid')
					this.byDayMeterUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, product, year, month);
			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_meter_report) {
					const lastReport = new Date(this.activation.last_meter_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayMeterUsage, 'day', i) === -1)
						this.byDayMeterUsage.push(new Models.LPMeterReportByDay(0, '', product, year, month, i, 0));
			} // if

			this.byDayMeterUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayMeterUsage)
				this.byDayMeterTotal += +dayRec.used;

			if (this.byDayMeterUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayMeterUsage.length; i++)
					if (this.byDayMeterUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayMeterUsage[i].used - this.byDayMeterUsage[i - 1].used)
							/ this.byDayMeterUsage[i - 1].used);
				this.byDayMeterAvgAbsChg = tempTotal / (this.byDayMeterUsage.length - 1);
			} // if

			this.byDayMeterLoading = false;
		}
	}

	// *********************************************************
	async selectProtocolDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		let productToFetch = product;
		if (product.startsWith('total')) {
			productToFetch = 'calculated_' + product;
		} // if

		if (this.byDayProtocolProduct !== productToFetch || this.byDayProtocolMonth !== month || this.byDayProtocolYear !== year) {
			this.byDayProtocolLoading = true;
			this.byDayProtocolProduct = product;
			this.byDayProtocolMonth = month;
			this.byDayProtocolYear = year;
			this.byDayProtocolTotal = 0;
			this.byDayProtocolAvgAbsChg = 0;
			this.byDayProtocolUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayProtocolUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, productToFetch, year, month, this.protocolBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayProtocolUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, productToFetch, year, month, this.protocolBillingCodeToSearch, this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayProtocolUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, productToFetch, year, month, this.protocolBillingCodeToSearch);
			} else {
				if (this.pageMode === 'activation')
					this.byDayProtocolUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, productToFetch, year, month, this.protocolBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayProtocolUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, productToFetch, year, month, this.protocolBillingCodeToSearch);
			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_protocol_report) {
					const lastReport = new Date(this.activation.last_protocol_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayProtocolUsage, 'day', i) === -1)
						this.byDayProtocolUsage.push(new Models.LPMeterReportByDay(0, '', productToFetch, year, month, i, 0));
			} // if

			this.byDayProtocolUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayProtocolUsage)
				this.byDayProtocolTotal += +dayRec.used;

			if (this.byDayProtocolUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayProtocolUsage.length; i++)
					if (this.byDayProtocolUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayProtocolUsage[i].used - this.byDayProtocolUsage[i - 1].used)
							/ this.byDayProtocolUsage[i - 1].used);
				this.byDayProtocolAvgAbsChg = tempTotal / (this.byDayProtocolUsage.length - 1);
			}

			this.byDayProtocolLoading = false;
		}
	}

	// *********************************************************
	async selectChronoDayToFocus(product: string, year: number, month: number) {
		if (this.timeMode !== 'month') return;

		let productToFetch = product;
		if (product.startsWith('total')) {
			productToFetch = 'calculated_' + product;
		} // if

		if (this.byDayChronoProduct !== productToFetch || this.byDayChronoMonth !== month || this.byDayChronoYear !== year) {
			this.byDayChronoLoading = true;
			this.byDayChronoProduct = product;
			this.byDayChronoMonth = month;
			this.byDayChronoYear = year;
			this.byDayChronoTotal = 0;
			this.byDayChronoAvgAbsChg = 0;
			this.byDayChronoUsage = [];

			if (this.staffMode) {
				if (this.pageMode === 'activation')
					this.byDayChronoUsage = await this.licensingService.getActivationDailyUsageForMonth(this.id, productToFetch, year, month, this.chronoBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayChronoUsage = await this.licensingService.getHostDailyUsageForMonth(this.hostid, productToFetch, year, month, this.chronoBillingCodeToSearch, this.filterID);
				else if (this.pageMode === 'aggregate')
					this.byDayChronoUsage = await this.licensingService.getAggregateDailyUsage(this.activationIdAndHostIdsCombos, productToFetch, year, month, this.chronoBillingCodeToSearch);

			} else {
				if (this.pageMode === 'activation')
					this.byDayChronoUsage = await this.myKeysService.getActivationDailyUsageForMonth(this.userKey, productToFetch, year, month, this.chronoBillingCodeToSearch);
				else if (this.pageMode === 'hostid')
					this.byDayChronoUsage = await this.myKeysService.getHostDailyUsageForMonth(this.userKey, this.hostid, productToFetch, year, month, this.chronoBillingCodeToSearch);

			} // if

			if (this.showNoUseDays) {
				const now = new Date();
				let firstDayOfMonth = 1;
				let lastDayOfMonth = MiscTools.daysInMonth(month, year);

				// adjust if month when key was created...
				if (this.pageMode === 'activation') {
					const createdDate: Date = new Date(this.activation.created_at);
					if (year === createdDate.getUTCFullYear() && month === (createdDate.getUTCMonth() + 1))
						firstDayOfMonth = createdDate.getUTCDate();
				} // if

				// adjust if current month...
				if (year === now.getUTCFullYear() && month === (now.getUTCMonth() + 1))
					lastDayOfMonth = now.getUTCDate() - 1;

				// adjust if last month of reported data...
				if (this.pageMode === 'activation' && this.activation.last_protocol_report) {
					const lastReport = new Date(this.activation.last_protocol_report);
					if (lastReport && !isNaN(lastReport.getTime())
						&& year === lastReport.getUTCFullYear()
						&& month === (lastReport.getUTCMonth() + 1))
						lastDayOfMonth = lastReport.getUTCDate();
				} // if

				for (let i = firstDayOfMonth; i <= lastDayOfMonth; i++)
					if (MiscTools.findIndexGeneric(this.byDayChronoUsage, 'day', i) === -1)
						this.byDayChronoUsage.push(new Models.LPMeterReportByDay(0, '', productToFetch, year, month, i, 0));
			} // if

			this.byDayChronoUsage.sort((a, b) => (a.day > b.day) ? 1 : -1);

			for (const dayRec of this.byDayChronoUsage)
				this.byDayChronoTotal += +dayRec.used;

			if (this.byDayChronoUsage.length > 1) {
				let tempTotal = 0;
				for (let i = 1; i < this.byDayChronoUsage.length; i++)
					if (this.byDayChronoUsage[i - 1].used !== 0)
						tempTotal += Math.abs((this.byDayChronoUsage[i].used - this.byDayChronoUsage[i - 1].used)
							/ this.byDayChronoUsage[i - 1].used);
				this.byDayChronoAvgAbsChg = tempTotal / (this.byDayChronoUsage.length - 1);
			}

			this.byDayChronoLoading = false;
		}
	}

	// *********************************************************
	async selectProtocolBillingCodeFilter(id: number) {
		this.protocolBillingCodeToSearch = '';
		if (id === -1) {
			this.activeProtocolBillingCodeLabel = 'All usage data';
			this.activeProtocolBillingCodeShortLabel = '';
		} else if (id === 0) {
			this.activeProtocolBillingCodeLabel = 'Usage data with no billing code set';
			this.activeProtocolBillingCodeShortLabel = 'No billing code';
			this.protocolBillingCodeToSearch = AppConstants.noBillingCodeToken;
		} else {
			const idx = MiscTools.findIndex(this.usedProtocolBillingCodes, id)
			if (idx === -1)
				return this.selectProtocolBillingCodeFilter(-1);

			this.protocolBillingCodeToSearch = this.usedProtocolBillingCodes[idx].billing_code;
			this.activeProtocolBillingCodeShortLabel = this.usedProtocolBillingCodes[idx].billing_code;
			this.activeProtocolBillingCodeLabel = 'Usage data for '
				+ this.makeBillingCodeLabel(this.usedProtocolBillingCodes[idx]);
		} // if

		this.activeProtocolBillingCode = id;

		this.loading = true;

		// new way - do b.c. filtering in front end
		this.byDayMeterProduct = '';
		this.byDayMeterUsage = [];

		this.byDayProtocolProduct = '';
		this.byDayProtocolUsage = [];

		this.byDayChronoProduct = '';
		this.byDayChronoUsage = [];

		await this.workoutProtocolUsage();
		this.loading = false;
	}

	// *********************************************************
	async selectChronoBillingCodeFilter(id: number) {
		this.chronoBillingCodeToSearch = '';
		if (id === -1) {
			this.activeChronoBillingCodeLabel = 'All usage data';
			this.activeChronoBillingCodeShortLabel = '';
		} else if (id === 0) {
			this.activeChronoBillingCodeLabel = 'Usage data with no billing code set';
			this.activeChronoBillingCodeShortLabel = 'No billing code';
			this.chronoBillingCodeToSearch = AppConstants.noBillingCodeToken;
		} else {
			const idx = MiscTools.findIndex(this.usedChronoBillingCodes, id)
			if (idx === -1)
				return this.selectChronoBillingCodeFilter(-1);

			this.chronoBillingCodeToSearch = this.usedChronoBillingCodes[idx].billing_code;
			this.activeChronoBillingCodeShortLabel = this.usedChronoBillingCodes[idx].billing_code;
			this.activeChronoBillingCodeLabel = 'Usage data for '
				+ this.makeBillingCodeLabel(this.usedChronoBillingCodes[idx]);
		}

		this.activeChronoBillingCode = id;
		// this.billingCodeSelectionMode = false;

		this.loading = true;

		// new way - do b.c. filtering in front end
		this.byDayMeterProduct = '';
		this.byDayMeterUsage = [];

		this.byDayProtocolProduct = '';
		this.byDayProtocolUsage = [];

		this.byDayChronoProduct = '';
		this.byDayChronoUsage = [];

		await this.workoutChronoUsage();
		this.loading = false;
	}

	// *********************************************************
	makeBillingCodeLabel(bc: Models.BillingCode, everything: boolean = true) {
		let label = bc.billing_code + ': ' + bc.label;
		if (!everything) label = bc.label;

		if (everything && this.staffMode && ['activation', 'aggregate', 'hostid'].includes(this.pageMode)) {
			const bcOrg = this.organizationsService.getOne(bc.zcp_org_id);
			if (bcOrg) {
				label += ' (' + bcOrg.name;
				// if (this.pageMode === 'activation') {
				// 	if (bcOrg.id === this.activation.zcp_org_id)
				// 		label += ' - Same as key\'s';
				// 	else
				// 		label += ' - Different from key\'s';
				// } // if
				label += ')';
			} else {
				label += ' (unknown organization - deleted? - ' + bc.zcp_org_id + ')';
			} // if
		} // if
		return label;
	}

	// *********************************************************
	resetMeterUsage() {
		this.monthlyMeterUsage = [];
		this.chunkedMeterUsage = [];

		this.byDayMeterProduct = '';
		this.byDayMeterMonth = 0;
		this.byDayMeterYear = 0;
		this.byDayMeterUsage = [];
		this.byDayMeterTotal = 0;
		this.byDayMeterAvgAbsChg = 0;
	}

	// *********************************************************
	resetProtocolUsage() {
		this.monthlyProtocolUsage = [];
		this.chunkedProtocolUsage = [];
		this.protocolProducts = [];
		this.protocolProductsToShow = [];
		this.protocolProductsVisible = [];
		this.protocolTimeChunks = [];
		this.protocolTimeChunksToShow = [];

		this.hasIncomingProtocolData = false;
		this.hasTranscodeProtocolData = false;
		this.hasOutgoingProtocolData = false;

		this.byDayProtocolProduct = '';
		this.byDayProtocolMonth = 0;
		this.byDayProtocolYear = 0;
		this.byDayProtocolUsage = [];
		this.byDayProtocolTotal = 0
		this.byDayProtocolAvgAbsChg = 0;
	}

	// *********************************************************
	resetTimeUsage() {
		this.monthlyChronoUsage = [];
		this.chunkedChronoUsage = [];
		this.chronoProducts = [];
		this.chronoProductsToShow = [];
		this.chronoTimeChunks = [];
		this.chronoTimeChunksToShow = [];
		this.chronoMonthTotals = {};

		this.hasIncomingChronoData = false;
		this.hasTranscodeChronoData = false;
		this.hasOutgoingChronoData = false;

		this.byDayChronoProduct = '';
		this.byDayChronoMonth = 0;
		this.byDayChronoYear = 0;
		this.byDayChronoUsage = [];
		this.byDayChronoTotal = 0
		this.byDayChronoAvgAbsChg = 0;
	}

	// *********************************************************
	async workoutMeterUsage() {
		this.meterProductsToShow = [];
		this.chunkedMeterUsage = [];

		for (const record of this.monthlyMeterUsage) {
			if (record.used > 0) {
				if (!this.meterProductsToShow.includes(record.product))
					this.meterProductsToShow.push(record.product);

				const my = {
					month: +record.month,
					year: +record.year
				};

				if (['quarter', 'year'].includes(this.timeMode)) {
					if (this.timeMode === 'quarter')
						my.month = Math.ceil(+record.month / 3);
					else if (this.timeMode === 'year')
						my.month = -1;

					const idx = MiscTools.findIndexGenericTriple(this.chunkedMeterUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedMeterUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
					else
						this.chunkedMeterUsage[idx].used += +record.used;
				} else {
					this.chunkedMeterUsage.push(record);
				} // if
			} // if
		} // for

		this.meterProductsToShow.sort();
		if (this.pageMode === 'activation')
			this.meterProductsToShow = SharedLicenseTools.sortMeterTypesBasedOnCommercialType(this.activation.commercial_type, this.meterProductsToShow);

		const labelCounts: any = {};
		for (const product of this.meterProductsToShow) {
			const label = SharedLicenseTools.niceProtocol(product, false);
			if (!labelCounts[label]) labelCounts[label] = 0;
			labelCounts[label]++;
			if (labelCounts[label] > 1) this.showMeterTypeExtra = true;
		} // for

		if (this.timeMode === 'month' && this.pageMode === 'activation') {
			for (const record of this.chunkedMeterUsage) {
				this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month] = SharedLicenseTools.getMeterProjectedForMonth(this.activation, record.product, record.month, record.year);
			} // for
		} // if
	}

	// *********************************************************
	getUniqueProtocolsWithUsage(usage: Models.LPMeterReportByMonth[], timeChunks: any[], allProtocolsUsed: string[]): string[] {
		const protocols: string[] = [];
		for (const p of allProtocolsUsed) {
			let pTotal: number = 0;

			for (const record of usage) {
				const idx = MiscTools.findIndexGenericDouble(timeChunks, 'year', +record.year, 'month', +record.month);
				if (idx !== -1 && record.product === p && record.used > 0)
					pTotal += +record.used;
			} // for
			if (pTotal > 0)
				protocols.push(p);
		} // for
		return protocols;
	}

	// *********************************************************
	async workoutProtocolUsage() {
		this.protocolProducts = [];
		this.protocolProductsToShow = [];
		this.protocolProductsVisible = [];
		this.protocolTimeChunks = [];
		this.protocolTimeChunksToShow = [];

		this.hasIncomingProtocolData = false;
		this.hasTranscodeProtocolData = false;
		this.hasOutgoingProtocolData = false;

		this.chunkedProtocolUsage = [];

		if (this.usedProtocolBillingCodes.length === 0 && this.activeProtocolBillingCode === -1) {
			const uniqueCodes: string[] = [];
			this.usedProtocolBillingCodes = [];

			for (const record of this.monthlyProtocolUsage)
				if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
					uniqueCodes.push(record.billing_code);

			// for (const record of this.monthlyChronoUsage)
			// 	if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
			// 		uniqueCodes.push(record.billing_code);

			// console.log('uniqueCodes');
			// console.log(uniqueCodes);
			if (uniqueCodes.length > 0) {
				if (this.staffMode)
					this.usedProtocolBillingCodes = await this.licensingService.getBillingCodes(uniqueCodes)
				else
					this.usedProtocolBillingCodes = await this.myKeysService.getBillingCodes(uniqueCodes)
			} // if

			// console.log('this.usedBillingCodes');
			// console.log(this.usedBillingCodes);

			this.billingCodesByCode = {};
			for (const bc of this.usedProtocolBillingCodes)
				this.billingCodesByCode[bc.billing_code] = bc;
		} // if

		const usedSets: number[] = [];
		for (const record of this.monthlyProtocolUsage) {
			let passedFilter = true;
			if (this.activeProtocolBillingCode >= 0) {
				if (this.activeProtocolBillingCode === 0 && record.billing_code != null && record.billing_code !== '')
					passedFilter = false;
				if (this.activeProtocolBillingCode > 0 && this.protocolBillingCodeToSearch !== record.billing_code)
					passedFilter = false;
			} // if

			if (passedFilter && record.used > 0) {
				// based on view-mode : monthly, quarterly, annually
				// setup the my
				const my = {
					month: +record.month,
					year: +record.year
				};

				// if (['quarter', 'year'].includes(this.timeMode)) {
				if (this.timeMode === 'quarter')
					my.month = Math.ceil(+record.month / 3);
				else if (this.timeMode === 'year')
					my.month = -1;

				// do the sub-total for the protocol
				const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
				if (idx === -1)
					this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
				else
					this.chunkedProtocolUsage[idx].used += +record.used;

				// track used protocols
				if (!this.protocolProducts.includes(record.product))
					this.protocolProducts.push(record.product);

				if (MiscTools.findIndexGenericDouble(this.protocolTimeChunks, 'month', +my.month, 'year', +my.year) === -1)
					this.protocolTimeChunks.push(my);

				// do overall total
				const idxT = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total', 'year', +my.year, 'month', +my.month);
				if (idxT === -1)
					this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total', +my.year, +my.month, +record.used));
				else
					this.chunkedProtocolUsage[idxT].used += +record.used;

				// sub total for all in & out
				if (!record.product.includes('transcoded') && (record.product.endsWith('_in') || record.product.endsWith('_out'))) {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_inout', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_inout', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if

				// do sub-totals broken down by in/transcode/out
				if (record.product.includes('transcoded')) {
					this.hasTranscodeProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_transcoded', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_transcoded', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;

				} else if (record.product.endsWith('_in')) {
					this.hasIncomingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_in', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_in', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} else if (record.product.endsWith('_out')) {
					this.hasOutgoingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'total_out', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_out', +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if

				// do protocol set totals...
				for (const protocolSet of this.selectedProtocolSets) {
					if (protocolSet.protocolsArr.includes(record.product)) {
						if (!usedSets.includes(protocolSet.id)) usedSets.push(protocolSet.id);
						const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							this.chunkedProtocolUsage[idx].used += +record.used;
					} // if
				} // for

				// do billing-code based totals...
				if (this.activeProtocolBillingCode === -1 && record.billing_code != null && record.billing_code !== '') {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'billing-code:' + record.billing_code, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + record.billing_code, +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} else {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'billing-code:' + AppConstants.noBillingCodeToken, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + AppConstants.noBillingCodeToken, +my.year, +my.month, +record.used));
					else
						this.chunkedProtocolUsage[idx].used += +record.used;
				} // if
			} // if
		} // for
		this.protocolProducts.sort(SharedLicenseTools.protocolSort);

		if (this.hasIncomingProtocolData) this.protocolProducts.push('total_in');
		if (this.hasTranscodeProtocolData) this.protocolProducts.push('total_transcoded');
		if (this.hasOutgoingProtocolData) this.protocolProducts.push('total_out');
		if (this.hasIncomingProtocolData || this.hasOutgoingProtocolData) this.protocolProducts.push('total_inout');
		if (this.protocolProducts.length > 0) this.protocolProducts.push('total');

		if (this.activeProtocolBillingCode === -1) {
			if (this.usedProtocolBillingCodes.length > 0)
				this.protocolProducts.push('billing-code:' + AppConstants.noBillingCodeToken);

			for (const bc of this.usedProtocolBillingCodes)
				this.protocolProducts.push('billing-code:' + bc.billing_code);
		} // if

		if (this.protocolSetsPosition === 'bottom') {
			for (const protocolSet of this.selectedProtocolSets)
				if (usedSets.includes(protocolSet.id))
					this.protocolProducts.push('protocol-set:' + protocolSet.id);
		} else {
			for (let i = this.selectedProtocolSets.length - 1; i >= 0; i--)
				if (usedSets.includes(this.selectedProtocolSets[i].id))
					this.protocolProducts.unshift('protocol-set:' + this.selectedProtocolSets[i].id);
		} // if

		if (this.pageMode === 'activation' && this.timeMode === 'month') {
			for (const linkedSet of this.activation.protocolSets) {
				if (linkedSet.projected && linkedSet.projected !== 0) {
					let totalAmount: number = 0;
					for (const timeChunk of this.protocolTimeChunks) {
						// get the amount for this month
						let amount: number = 0;
						const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', 'protocol-set:' + linkedSet.set_id, 'year', +timeChunk.year, 'month', +timeChunk.month);
						if (idx !== -1) amount = this.chunkedProtocolUsage[idx].used;

						totalAmount += +amount;

						let percentOfProjected: number = (+(amount / +linkedSet.projected * 100).toFixed(0));
						let overage: number = 0;
						if (amount > linkedSet.projected) overage = amount - +linkedSet.projected;

						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'pset-percent-used:' + linkedSet.set_id, +timeChunk.year, +timeChunk.month, percentOfProjected));
						this.chunkedProtocolUsage.push(new Models.LPMeterReportByMonth(0, '', 'pset-overage:' + linkedSet.set_id, +timeChunk.year, +timeChunk.month, overage));
					} // for

					if (totalAmount > 0 && linkedSet.projected && linkedSet.projected > 0) {
						if (this.protocolSetsPosition === 'bottom') {
							this.protocolProducts.push('pset-percent-used:' + linkedSet.set_id);
							this.protocolProducts.push('pset-overage:' + linkedSet.set_id);
						} else {
							this.protocolProducts.unshift('pset-overage:' + linkedSet.set_id);
							this.protocolProducts.unshift('pset-percent-used:' + linkedSet.set_id);
						} // if
					} // if
				} // if
			} // for
		} // if

		if (this.timeMode === 'month') {
			if (this.protocolShowAllMonths) {
				this.protocolTimeChunksToShow = this.protocolTimeChunks;
				this.protocolProductsToShow = this.protocolProducts;
			} else {
				if (this.protocolTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
					this.protocolTimeChunksToShow = this.protocolTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
					this.protocolProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.protocolTimeChunksToShow, this.protocolProducts);
				} else {
					this.protocolTimeChunksToShow = this.protocolTimeChunks;
					this.protocolProductsToShow = this.protocolProducts;
				} // if
			} // if
		} else {
			this.protocolTimeChunksToShow = this.protocolTimeChunks;
			this.protocolProductsToShow = this.protocolProducts;
		} // if

		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;
	}

	// *********************************************************
	async workoutChronoUsage() {
		this.chronoProducts = [];
		this.chronoProductsToShow = [];
		this.chronoTimeChunks = [];
		this.chronoTimeChunksToShow = [];
		this.chronoMonthTotals = {};

		this.hasIncomingChronoData = false;
		this.hasTranscodeChronoData = false;
		this.hasOutgoingChronoData = false;

		this.chunkedChronoUsage = [];

		if (this.usedChronoBillingCodes.length === 0 && this.activeChronoBillingCode === -1) {
			const uniqueCodes: string[] = [];
			this.usedChronoBillingCodes = [];

			for (const record of this.monthlyChronoUsage)
				if (record.billing_code != null && record.billing_code !== '' && !uniqueCodes.includes(record.billing_code))
					uniqueCodes.push(record.billing_code);

			// console.log('uniqueCodes');
			// console.log(uniqueCodes);
			if (uniqueCodes.length > 0) {
				if (this.staffMode)
					this.usedChronoBillingCodes = await this.licensingService.getBillingCodes(uniqueCodes)
				else
					this.usedChronoBillingCodes = await this.myKeysService.getBillingCodes(uniqueCodes)
			} // if

			// console.log('this.usedBillingCodes');
			// console.log(this.usedBillingCodes);

			this.billingCodesByCode = {};
			for (const bc of this.usedChronoBillingCodes)
				this.billingCodesByCode[bc.billing_code] = bc;
		} // if

		const usedSets: number[] = [];
		for (const record of this.monthlyChronoUsage) {
			let passedFilter = true;
			if (this.activeChronoBillingCode >= 0) {
				if (this.activeChronoBillingCode === 0 && record.billing_code != null && record.billing_code !== '')
					passedFilter = false;
				if (this.activeChronoBillingCode > 0 && this.chronoBillingCodeToSearch !== record.billing_code)
					passedFilter = false;
			} // if

			if (passedFilter && record.used > 0) {
				const my = {
					month: record.month,
					year: record.year
				};

				// if (['quarter', 'year'].includes(this.timeMode)) {
				if (this.timeMode === 'quarter')
					my.month = Math.ceil(+record.month / 3);
				else if (this.timeMode === 'year')
					my.month = -1;

				const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
				if (idx === -1)
					this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
				else
					this.chunkedChronoUsage[idx].used += +record.used;
				// } else {
				// 	this.chunkedChronoUsage.push(record);
				// } // if

				if (!this.chronoProducts.includes(record.product))
					this.chronoProducts.push(record.product);

				if (MiscTools.findIndexGenericDouble(this.chronoTimeChunks, 'month', +my.month, 'year', +my.year) === -1) this.chronoTimeChunks.push(my);

				// do overall total
				const idxT = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
				if (idxT === -1)
					this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
				else
					this.chunkedChronoUsage[idxT].used += +record.used;

				// sub total for all in & out
				if (!record.product.includes('transcoded') && (record.product.endsWith('_in') || record.product.endsWith('_out'))) {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_inout' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_inout' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if

				// do sub-totals broken down by in/transcode/out
				if (record.product.includes('transcoded' + AppConstants.timeProtocolSuffix)) {
					this.hasTranscodeChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_transcoded' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_transcoded' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;

				} else if (record.product.endsWith('_in' + AppConstants.timeProtocolSuffix)) {
					this.hasIncomingChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_in' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_in' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} else if (record.product.endsWith('_out' + AppConstants.timeProtocolSuffix)) {
					this.hasOutgoingChronoData = true;
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'total_out' + AppConstants.timeProtocolSuffix, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_out' + AppConstants.timeProtocolSuffix, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if

				// do protocol set totals...
				for (const protocolSet of this.selectedChronoSets) {
					if (protocolSet.protocolsArr.includes(record.product)) {
						if (!usedSets.includes(protocolSet.id)) usedSets.push(protocolSet.id);
						const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							this.chunkedChronoUsage[idx].used += +record.used;
					} // if
				} // for

				// do billing-code based totals...
				if (this.activeChronoBillingCode === -1 && record.billing_code != null && record.billing_code !== '') {
					const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', 'billing-code:' + record.billing_code, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						this.chunkedChronoUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + record.billing_code, +my.year, +my.month, +record.used));
					else
						this.chunkedChronoUsage[idx].used += +record.used;
				} // if


			} // if
		} // for

		this.chronoProducts.sort(SharedLicenseTools.protocolSort);

		if (this.hasIncomingChronoData) this.chronoProducts.push('total_in' + AppConstants.timeProtocolSuffix);
		if (this.hasTranscodeChronoData) this.chronoProducts.push('total_transcoded' + AppConstants.timeProtocolSuffix);
		if (this.hasOutgoingChronoData) this.chronoProducts.push('total_out' + AppConstants.timeProtocolSuffix);
		if (this.hasIncomingChronoData || this.hasOutgoingChronoData) this.chronoProducts.push('total_inout' + AppConstants.timeProtocolSuffix);
		if (this.chronoProducts.length > 0) this.chronoProducts.push('total' + AppConstants.timeProtocolSuffix);

		if (this.activeChronoBillingCode === -1)
			for (const bc of this.usedChronoBillingCodes)
				this.chronoProducts.push('billing-code:' + bc.billing_code);

		if (this.protocolSetsPosition === 'bottom') {
			for (const protocolSet of this.selectedChronoSets)
				this.chronoProducts.push('protocol-set:' + protocolSet.id);
		} else {
			for (let i = this.selectedChronoSets.length - 1; i >= 0; i--)
				this.chronoProducts.unshift('protocol-set:' + this.selectedChronoSets[i].id);
		} // if

		if (this.timeMode === 'month') {
			if (this.protocolShowAllMonths) {
				this.chronoTimeChunksToShow = this.chronoTimeChunks;
				this.chronoProductsToShow = this.chronoProducts;
			} else {
				if (this.chronoTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
					this.chronoTimeChunksToShow = this.chronoTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
					this.chronoProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.chronoTimeChunksToShow, this.chronoProducts);
				} else {
					this.chronoTimeChunksToShow = this.chronoTimeChunks;
					this.chronoProductsToShow = this.chronoProducts;
				} // if
			} // if
		} else {
			this.chronoTimeChunksToShow = this.chronoTimeChunks;
			this.chronoProductsToShow = this.chronoProducts;
		} // if
	}

	// *********************************************************
	pullUsageForMeterProduct(product: string) {
		const usage: Models.LPMeterReportByMonth[] = [];
		for (const record of this.chunkedMeterUsage)
			if (record.product === product && record.used > 0)
				usage.push(record);
		return usage;
	}

	// *********************************************************
	getUsageForProtocolForTimeChunk(product: string, year: number, month: number) {
		const idx = MiscTools.findIndexGenericTriple(this.chunkedProtocolUsage, 'product', product, 'year', year, 'month', month);
		if (idx !== -1)
			return this.chunkedProtocolUsage[idx].used;
		return 0;
	}

	// *********************************************************
	getUsageForChronoForTimeChunk(product: string, year: number, month: number) {
		const idx = MiscTools.findIndexGenericTriple(this.chunkedChronoUsage, 'product', product, 'year', year, 'month', month);
		if (idx !== -1)
			return this.chunkedChronoUsage[idx].used;
		return 0;
	}

	// *********************************************************
	niceKeyType(activation: Models.LPActivation) {
		return (activation.type);
	}

	// *********************************************************
	toggleAxis() {
		this.rotateAxis = !this.rotateAxis;
		this.saveSettings();
	}

	// *********************************************************
	toggleShowNoUseDays() {
		this.showNoUseDays = !this.showNoUseDays;
		this.saveSettings();
	}

	// *********************************************************
	getDayToDayRatio(metricType: string, idx: number): number {
		if (idx > 0)
			if (metricType === 'meter-data' && this.byDayMeterUsage[idx - 1].used !== 0)
				return (this.byDayMeterUsage[idx].used - this.byDayMeterUsage[idx - 1].used)
					/ this.byDayMeterUsage[idx - 1].used;
			else if (metricType === 'protocol-data' && this.byDayProtocolUsage[idx - 1].used !== 0)
				return (this.byDayProtocolUsage[idx].used - this.byDayProtocolUsage[idx - 1].used)
					/ this.byDayProtocolUsage[idx - 1].used;
			else if (metricType === 'protocol-time' && this.byDayChronoUsage[idx - 1].used !== 0)
				return (this.byDayChronoUsage[idx].used - this.byDayChronoUsage[idx - 1].used)
					/ this.byDayChronoUsage[idx - 1].used;
		return 0;
	}

	// *********************************************************
	dailyRate(sizeMB: number) {
		if (sizeMB == null) return 0;
		const dailyRate = (sizeMB * 8) / (24 * 60 * 60);
		return dailyRate;
		// return Math.max(dailyRate, 0.1).toFixed(1);
	}

	// *********************************************************
	dayLabel(record: Models.LPMeterReportByDay) {
		const theDate: Date = new Date(record.year + '/' + record.month + '/' + record.day + ' 00:00:00 UTC');
		return record.day + ' - ' + TextTools.acronym(AppConstants.daysOfWeek[theDate.getUTCDay()]);
	}

	// *********************************************************
	productClass(product: string): string {
		let classes = 'border';
		// if (product.startsWith('total'))
		// 	classes += ' fw-bold';

		if (product.startsWith('billing-code:'))
			classes += ' cp-table-orange';
		else if (product.startsWith('protocol-set:') || product.startsWith('pset-percent-used:') || product.startsWith('pset-overage:'))
			classes += ' table-warning';
		else if (product.includes('transcoded'))
			classes += ' table-secondary';
		else if (product.endsWith('_in') || product.endsWith('_in' + AppConstants.timeProtocolSuffix))
			classes += ' table-primary';
		else if (product.endsWith('_out') || product.endsWith('_out' + AppConstants.timeProtocolSuffix))
			classes += ' table-info';
		else if (['total', 'total' + AppConstants.timeProtocolSuffix, 'total_inout', 'total_inout' + AppConstants.timeProtocolSuffix].includes(product))
			classes += ' table-success';

		return classes;
	}

	// *********************************************************
	hasMeterOfCertainType(meterProduct: string, resets: string) {
		const now = new Date();

		for (const meter of this.activation.meters) {
			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.product === meterProduct && meter.resets === resets
				&& meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
				return true;
			} // if
		} // for

		return false;
	}

	// *********************************************************
	niceProtocol(protocol: string, useShort: boolean = true) {
		let name = protocol;

		if (protocol.startsWith('billing-code:')) {
			if (protocol === 'billing-code:' + AppConstants.noBillingCodeToken) {
				name = 'No Code';

			} else if (useShort) {
				if (this.billingCodeDisplay === 'label')
					name = this.makeBillingCodeLabel(this.billingCodesByCode[TextTools.getValue(protocol)], false);
				else
					name = TextTools.getValue(protocol);
			} else {
				name = 'Billing Code: ' + TextTools.getValue(protocol);
			} // if

		} else if (protocol.startsWith('protocol-set:') || protocol.startsWith('pset-percent-used:') || protocol.startsWith('pset-overage:')) {
			const setId = +TextTools.getValue(protocol);
			const idx = MiscTools.findIndex(this.availableSets, setId);
			if (idx !== -1)
				name = this.availableSets[idx].name;

			if (!useShort) name = 'Protocol Set: ' + name;

			if (protocol.startsWith('pset-percent-used:'))
				name = '% of Projected for ' + name;
			else if (protocol.startsWith('pset-overage:'))
				name = 'Overage for ' + name;

			if (useShort) name = TextTools.chopString(name, 30, '...');

		} else {
			name = SharedLicenseTools.niceProtocol(protocol, useShort);
		} // if

		if (useShort)
			return name.replace(/\s/g, '\n');
		else
			return name;
	} // 

	// // *********************************************************
	// niceMeterType(product: string) {
	// 	let name = product;

	// 	if (product.startsWith('billing-code:')) {
	// 		return 'Billing Code: ' + TextTools.getValue(product);
	// 	} else if (product.startsWith('protocol-set:')) {
	// 		const setId = +TextTools.getValue(product);
	// 		const idx = MiscTools.findIndex(this.availableSets, setId);
	// 		if (idx !== -1) {
	// 			name = 'Protocol Set: ' + this.availableSets[idx].name;
	// 		} // if
	// 		return name;
	// 	} else {
	// 		return SharedLicenseTools.niceProtocol(product, false);
	// 	} // if
	// }

	// // *********************************************************
	// niceShortMeterType(product: string) {
	// 	let name = product;
	// 	if (product.startsWith('billing-code:')) {
	// 		if (this.billingCodeDisplay === 'label')
	// 			name = this.makeBillingCodeLabel(this.billingCodesByCode[TextTools.getValue(product)], false);
	// 		else
	// 			name = TextTools.getValue(product);
	// 	} else if (product.startsWith('protocol-set:')) {
	// 		const setId = +TextTools.getValue(product);
	// 		const idx = MiscTools.findIndex(this.availableSets, setId);
	// 		if (idx !== -1)
	// 			name = TextTools.chopString(this.availableSets[idx].name, 20, '...');
	// 	} else {
	// 		name = SharedLicenseTools.niceProtocol(product, true);
	// 	} // if

	// 	return name.replace(/\s/g, '\n');
	// }

	// *********************************************************
	showDataValue(amt: number, product: string = '') {
		if (!amt || Math.abs(amt) < 0.1) return '0';

		const isNeg: boolean = amt < 0;
		const absAmt: number = Math.abs(amt);

		let ret: string = '';
		// limit decimal places
		if (this.showRawData)
			ret = TextTools.formatNumber(+Math.max(absAmt, 0.1).toFixed(1));
		else
			ret = TextTools.formattedMB(absAmt);

		if (isNeg)
			return ('-' + ret);
		else
			return (ret);

	}

	// *********************************************************
	makeExpDataTip(product: string, ym: any, amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = this.niceProtocol(product, false)
			+ ' - ' + this.makeTimeChunkHeader(ym.month, ym.year, false)
			+ ' : ';

		if (!product.startsWith('pset-percent-used:'))
			toolTip += this.makeDataTip(amt, canCopy, canClick);

		return toolTip;
	}

	// *********************************************************
	makeDataTip(amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = TextTools.formatNumber(Math.floor(amt)) + ' megabytes';

		if (canCopy) toolTip += ' - click to copy'

		if (canClick && this.timeMode === 'month')
			if (canCopy)
				toolTip += ' and expand'
			else
				toolTip += ' - click to expand'

		return toolTip;
	}

	// *********************************************************
	showChronoValue(amt: number) {
		if (!amt || amt < 0.1) return '0';

		// limit decimal places
		if (this.showRawData)
			return TextTools.formatNumber(+Math.max(amt, 0.1).toFixed(1)) + ' s';
		else
			return TextTools.formattedHours(amt) + ' h';
	}

	// *********************************************************
	makeExpChronoTip(product: string, ym: any, amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = this.niceProtocol(product, true)
			+ ' - ' + this.makeTimeChunkHeader(ym.month, ym.year, false)
			+ ' : ';
		toolTip += this.makeChronoTip(amt, canCopy, canClick);

		return toolTip;
	}

	// *********************************************************
	makeChronoTip(amt: number, canCopy: boolean = true, canClick: boolean = false) {
		if (amt == null || isNaN(amt) || amt === 0) return '';
		let toolTip = TextTools.formattedHours(amt) + ' hours (' + TextTools.formatNumber(Math.floor(amt)) + ' s)';

		if (canCopy) toolTip += ' - click to copy'

		if (canClick && this.timeMode === 'month')
			if (canCopy)
				toolTip += ' and expand'
			else
				toolTip += ' - click to expand'

		return toolTip;
	}

	// *********************************************************
	toggleShowRaw() {
		this.showRawData = !this.showRawData;
		this.saveSettings();
	}

	// *********************************************************
	toggleTotalsOnly() {
		this.onlyShowTotals = !this.onlyShowTotals;
		this.saveSettings();

		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;
	}

	// *********************************************************
	getTotalProtcols(protocols: string[]) {
		const justTotals: string[] = [];
		for (const protocol of protocols)
			if (['total', 'total_inout', 'total_in', 'total_transcoded', 'total_out',].includes(protocol)
				|| protocol.startsWith('billing-code:') || protocol.startsWith('protocol-set:')
				|| protocol.startsWith('pset-percent-used:') || protocol.startsWith('pset-overage:')
			)
				justTotals.push(protocol);
		return justTotals;
	}

	// *********************************************************
	changeTimeMode(mode: string) {
		const idx = MiscTools.findIndexGeneric(this.timeModes, 'value', mode);
		if (idx !== -1) {
			this.byDayMeterProduct = '';
			this.byDayMeterUsage = [];

			this.byDayProtocolProduct = '';
			this.byDayProtocolUsage = [];

			this.byDayChronoProduct = '';
			this.byDayChronoUsage = [];

			this.timeMode = mode;
			this.timeModeLabel = this.timeModes[idx].label;

			this.workoutMeterUsage();
			this.workoutProtocolUsage();
			this.workoutChronoUsage();
		} // if
	}

	// *********************************************************
	makeTimeChunkHeader(m: number, y: number, withBreak: boolean) {
		let header = '';

		if (this.timeMode === 'month')
			header = TextTools.getMonthName(m, true) + ' ' + y;
		else if (this.timeMode === 'quarter')
			header = y + ' Q' + m;
		else if (this.timeMode === 'year')
			header = y + '';

		if (withBreak) header = header.replace(/\s/g, '\n');

		return header;
	}

	// *********************************************************
	getProtocolSuperScripts(product: string, dataType: string) {
		if (product.startsWith('protocol-set:')) {
			const setId = +TextTools.getValue(product);

			if (dataType === 'protocol-data') {
				const idx = MiscTools.findIndex(this.selectedProtocolSets, setId);
				if (idx !== -1)
					return '#' + (idx + 1);
			} else if (dataType === 'protocol-time') {
				const idx = MiscTools.findIndex(this.selectedChronoSets, setId);
				if (idx !== -1)
					return '#' + (idx + 1);
			} // if
		} else if (!product.startsWith('total')) {
			let setsToCheck: Models.ProtocolSet[] = [];
			if (dataType === 'protocol-data')
				setsToCheck = this.selectedProtocolSets;
			else if (dataType === 'protocol-time')
				setsToCheck = this.selectedChronoSets;

			const nums: number[] = [];
			let counter = 0;
			for (const protocolSet of setsToCheck) {
				counter++;
				if (protocolSet.protocolsArr.includes(product))
					nums.push(counter);
			} // for

			if (nums.length > 0)
				return '#' + nums.join(' #');
		} // if
		return '';
	}

	// --------------------------------------------------------------------
	workoutMonthsProjected(record: Models.LPMeterReportByMonth) {
		let amt: number = 0;
		if (this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month])
			amt = +this.monthlyProjectedAmounts[record.product + ':' + record.year + ':' + record.month];
		return amt
	} // 
	// --------------------------------------------------------------------
	workoutMonthsOverage(record: Models.LPMeterReportByMonth) {
		const proj: number = this.workoutMonthsProjected(record);
		if (proj !== 0 && record.used > proj) return record.used - proj;
		return 0;
	} // 

	// *********************************************************
	getProtocolPopoverLines(product: string, dataType: string): string[] {
		let lines: string[] = [];

		let setsToCheck: Models.ProtocolSet[] = [];
		if (dataType === 'protocol-data')
			setsToCheck = this.selectedProtocolSets;
		else if (dataType === 'protocol-time')
			setsToCheck = this.selectedChronoSets;

		if (product.startsWith('billing-code:')) {
			if (product === 'billing-code:' + AppConstants.noBillingCodeToken) {
				lines.push('Data reported that either has no billing code set OR the billing code\'s authentication code is invalid.');
			} else {
				lines.push('All data reported against this billing code.');
				if (this.billingCodesByCode[TextTools.getValue(product)])
					lines.push(this.makeBillingCodeLabel(this.billingCodesByCode[TextTools.getValue(product)]));
			}

		} else if (product.startsWith('protocol-set:')) {
			const setId = +TextTools.getValue(product);
			const idx = MiscTools.findIndex(setsToCheck, setId);
			if (idx !== -1) {
				lines.push('Protocol Set #' + (idx + 1));
				lines = lines.concat(PopOverTools.getProtocolSetPopoverLines(setsToCheck[idx], false));
			} // if

		} else if (product.startsWith('pset-percent-used:') || product.startsWith('pset-overage:')) {
			const setId = +TextTools.getValue(product);
			const linkedSet: Models.ActivationProtocolSet = MiscTools.pickItem(this.activation.protocolSets, 'set_id', setId);
			if (linkedSet.projected && linkedSet.projected > 0)
				lines.push('Projected Limit: ' + TextTools.formattedMB(linkedSet.projected));

		} else if (product.startsWith('total')) {
			lines.push('Calculated Total');

		} else {
			if (product.endsWith(AppConstants.timeProtocolSuffix))
				if (product.startsWith('mediaconnect_'))
					lines.push('MediaConnect related usage amount (time based) reported by ZEN Master');
				else if (product.startsWith('zm_'))
					lines.push('Non-MediaConnect related usage amount (time based) reported by ZEN Master');
				else
					lines.push('Usage amount (time based) reported by Zixi Broadcaster/ZEC');
			else
				if (product.startsWith('mediaconnect_'))
					lines.push('MediaConnect related usage amount (data based) reported by ZEN Master');
				else if (product.startsWith('zm_'))
					lines.push('Non-MediaConnect related usage amount (data based) reported by ZEN Master');
				else
					lines.push('Usage amount (data based) reported by Zixi Broadcaster/ZEC');

			let counter = 0;
			for (const protocolSet of setsToCheck) {
				counter++;
				if (protocolSet.protocolsArr.includes(product))
					lines.push('Included in Protocol Set # ' + counter + ': ' + protocolSet.name);
			} // for
		} // if

		return lines;
	}

	// *********************************************************
	async toggleSetsPosition() {
		if (this.protocolSetsPosition === 'top')
			this.protocolSetsPosition = 'bottom';
		else
			this.protocolSetsPosition = 'top';
		this.saveSettings();
		await this.workoutProtocolUsage();
		await this.workoutChronoUsage();
	}

	// *********************************************************
	async toggleBillingCodeDisplay() {
		if (this.billingCodeDisplay === 'code')
			this.billingCodeDisplay = 'label';
		else
			this.billingCodeDisplay = 'code';
		this.saveSettings();
	}

	// *********************************************************
	filterOutSelected(selectedSets: Models.ProtocolSet[]) {
		const filtered: Models.ProtocolSet[] = [];
		for (const protocolSet of this.availableSets)
			if (MiscTools.findIndex(selectedSets, protocolSet.id) === -1)
				filtered.push(protocolSet);
		return filtered;
	}

	// *********************************************************
	async toggleSessionSet(setId: number, dataType: string) {
		const idx1 = MiscTools.findIndex(this.availableSets, setId);
		if (idx1 !== -1) {
			if (dataType === 'protocol-data') {
				const idx2 = MiscTools.findIndex(this.sessionProtocolSets, setId);
				if (idx2 !== -1)
					this.sessionProtocolSets.splice(idx2, 1);
				else
					this.sessionProtocolSets.push(this.availableSets[idx1]);

				// save session protocol sets for this key
				localStorage.setItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionProtocolSets)));

				this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);

				await this.workoutProtocolUsage();

			} else if (dataType === 'protocol-time') {
				const idx2 = MiscTools.findIndex(this.sessionChronoSets, setId);
				if (idx2 !== -1)
					this.sessionChronoSets.splice(idx2, 1);
				else
					this.sessionChronoSets.push(this.availableSets[idx1]);

				// save session chrono sets for this key
				localStorage.setItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionChronoSets)));

				this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);

				await this.workoutChronoUsage();
			} // if
		} // if
	} //

	// *********************************************************
	async pushToLockedSets(dataType: string) {
		if (dataType === 'protocol-data') {
			if (this.sessionProtocolSets.length > 0) {
				this.loading = true;
				for (const set of this.sessionProtocolSets) {
					await this.licensingService.addActivationProtocolSet(this.id, set.id, dataType);
					this.linkedProtocolSets.push(set);
				} // for
				this.sessionProtocolSets = [];
				localStorage.setItem('CLEAN.activationView.sessionProtocolSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionProtocolSets)));
				this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);
				this.loading = false;
				await this.workoutProtocolUsage();
			} // if
		} else if (dataType === 'protocol-time') {
			if (this.sessionChronoSets.length > 0) {
				this.loading = true;
				for (const set of this.sessionChronoSets) {
					await this.licensingService.addActivationProtocolSet(this.id, set.id, dataType);
					this.linkedProtocolSets.push(set);
				} // for
				this.sessionChronoSets = [];
				localStorage.setItem('CLEAN.activationView.sessionChronoSets.' + this.sessionSetIdentifier, JSON.stringify(this.pullSetIds(this.sessionChronoSets)));
				this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);
				this.loading = false;
				await this.workoutChronoUsage();
			} // if
		} // if
	} //

	// *********************************************************
	async wipeLockedSets(dataType: string) {
		if (dataType === 'protocol-data') {
			if (this.linkedProtocolSets.length > 0) {
				this.loading = true;
				for (const set of this.linkedProtocolSets) {
					await this.licensingService.deleteActivationProtocolSet(this.id, set.id, dataType);
				} // for
				this.linkedProtocolSets = [];
				this.selectedProtocolSets = this.linkedProtocolSets.concat(this.sessionProtocolSets);
				this.loading = false;
				await this.workoutProtocolUsage();
			} // if
		} else if (dataType === 'protocol-time') {
			if (this.linkedChronoSets.length > 0) {
				this.loading = true;
				for (const set of this.linkedChronoSets) {
					await this.licensingService.deleteActivationProtocolSet(this.id, set.id, dataType);
				} // for
				this.linkedChronoSets = [];
				this.selectedChronoSets = this.linkedChronoSets.concat(this.sessionChronoSets);
				this.loading = false;
				await this.workoutChronoUsage();
			} // if
		} // if
	} //

	// *********************************************************
	pullSetIds(sets: Models.ProtocolSet[]) {
		const ids: number[] = [];
		for (const protocolSet of sets)
			ids.push(protocolSet.id);
		return ids;
	}

	// *********************************************************
	async runLicenseReport() {
		let args = 'key=' + encodeURIComponent(this.userKey.activation_key);
		await this.reportsService.runReport('KeyLicensesReport', args);
	}

	// *********************************************************
	async runKeyUsageSummaryReport() {
		// TBD
		// const dataTypes: string[] = this.usageSummaryForm.value.dataTypes;

		// if (dataTypes && dataTypes.length > 0) {
		// 	await this.reportsService.runReport('KeyUsageSummaryReport', 'id=' + this.id
		// 		+ '&dataTypes=' + encodeURIComponent(dataTypes.join(',')));
		// 	if (document.getElementById("closeUsageSummaryModalButton"))
		// 		document.getElementById("closeUsageSummaryModalButton").click();
		// } // if
	}

	// *********************************************************
	// openUsageReport(metricType: string) {
	// 	if (this.pageMode === 'activation') {
	// 		let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys + '/'
	// 			+ encodeURIComponent(this.userKey.activation_key) + '/report';
	// 		url += '?metricType=' + encodeURIComponent(metricType);
	// 		window.open(url, '_blank');
	// 	} else if (this.pageMode === 'hostid') {
	// 		let url = AppConstants.apiUrl + AppConstants.apiUrls.mykeys
	// 			+ '/' + encodeURIComponent(this.userKey.activation_key) + '/report'
	// 			+ '?hostid=' + encodeURIComponent(this.hostid)
	// 			+ '&metricType=' + encodeURIComponent(metricType);
	// 		window.open(url, '_blank');
	// 	} // if
	// }

	// *********************************************************
	async openUsageReport(metricType: string) {
		let args = 'key=' + encodeURIComponent(this.userKey.activation_key);
		if (this.pageMode === 'hostid')
			args += '&hostid=' + encodeURIComponent(this.hostid);
		args += '&metricType=' + encodeURIComponent(metricType);
		await this.reportsService.runReport('LicenseUsageReport', args);
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// --------------------------------------------------------------------
	routeToKey(keyId: number) {
		// const tabs = document.getElementsByClassName("nav-item nav-link active");
		// let currentTab: string = '';
		// if (tabs && tabs[0]) currentTab = tabs[0].id;

		// if (currentTab && currentTab !== '')
		// 	this.router.navigate(['/' + AppConstants.urls.mykeys, keyId], { queryParams: { tabID: currentTab } });
		// else
		this.router.navigate(['/' + AppConstants.urls.mykeys, keyId]);
	}

	// --------------------------------------------------------------------
	toggleShowPhantom() {
		this.showMeterProtocolEquiv = !this.showMeterProtocolEquiv;
		this.saveSettings();
	}

	// --------------------------------------------------------------------
	getPhantomProduct(meterType: string) {
		if (this.phantomSetIds[meterType])
			return 'protocol-set:' + this.phantomSetIds[meterType];
		return '';
	}

	// --------------------------------------------------------------------
	phantomPercent(meterValue: number, protocolValue: number) {
		let percDiff: number = 0;
		if (meterValue === 0 && protocolValue === 0)
			percDiff = 0;
		else if (meterValue === 0 || protocolValue === 0)
			percDiff = 100;
		else
			percDiff = (meterValue - protocolValue) / meterValue * 100;

		if (percDiff !== 0 && percDiff !== 100)
			return percDiff.toFixed(1) + '%';
		else
			return percDiff.toFixed(0) + '%';
	}

	// --------------------------------------------------------------------
	toggleShowAllProtocolMonths() {
		this.protocolShowAllMonths = !this.protocolShowAllMonths;

		if (this.protocolShowAllMonths) {
			this.protocolTimeChunksToShow = this.protocolTimeChunks;
			this.protocolProductsToShow = this.protocolProducts;

			this.chronoTimeChunksToShow = this.chronoTimeChunks;
			this.chronoProductsToShow = this.chronoProducts;
		} else {
			if (this.protocolTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
				this.protocolTimeChunksToShow = this.protocolTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
				this.protocolProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedProtocolUsage, this.protocolTimeChunksToShow, this.protocolProducts);
			} else {
				this.protocolTimeChunksToShow = this.protocolTimeChunks;
				this.protocolProductsToShow = this.protocolProducts;
			} // if

			if (this.chronoTimeChunks.length > AppConstants.protocolTrafficMonthsToShow) {
				this.chronoTimeChunksToShow = this.chronoTimeChunks.slice(0, AppConstants.protocolTrafficMonthsToShow);
				this.chronoProductsToShow = this.getUniqueProtocolsWithUsage(this.chunkedChronoUsage, this.chronoTimeChunksToShow, this.chronoProducts);
			} else {
				this.chronoTimeChunksToShow = this.chronoTimeChunks;
				this.chronoProductsToShow = this.chronoProducts;
			} // if
		} // if

		if (this.onlyShowTotals)
			this.protocolProductsVisible = this.getTotalProtcols(this.protocolProductsToShow);
		else
			this.protocolProductsVisible = this.protocolProductsToShow;
	}
}
