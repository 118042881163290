import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { OrganizationGroupsService } from '../organization-groups.service';
import { UsersService } from '../../users/users.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { ProductsService } from '../../products/products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { BuildsService } from '../../builds/builds.service';
import { DocumentsService } from '../../documents/documents.service';
import { AuthService } from 'client/app/services/auth.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadableBuildsTableComponent } from 'client/app/components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from 'client/app/components/shared/downloadable-documents-table/downloadable-documents-table.component';

@Component({
	selector: 'app-organization-group',
	templateUrl: './organization-group.component.html',
	styleUrls: ['./organization-group.component.scss']
})
export class OrganizationGroupComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('dBuildsTable1') dBuildsTable1: DownloadableBuildsTableComponent = null;
	@ViewChild('dDocsTable1') dDocsTable1: DownloadableDocumentsTableComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	group: Models.OrganizationGroup;

	// other stuff

	private userSubscription: Subscription;
	organizations: Models.Organization[] = [];
	builds: Models.Build[] = [];
	documents: Models.Document[] = [];
	buildDownloads: Models.DownloadableBuild[] = [];
	documentDownloads: Models.DownloadableDocument[] = [];
	managers: Models.User[] = [];

	canEdit = false;
	canDelete = false;

	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	productTypesToShow = [];

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	adminLogs: Models.AdminLog[] = [];

	deleteForm: UntypedFormGroup;
	showDeleteMsg = false;

	showDirectTab = false;
	minDirectForTab = 10;

	canSendNotifications = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private organizationGroupsService: OrganizationGroupsService,
		private usersService: UsersService,
		private buildsService: BuildsService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private organizationsService: OrganizationsService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.group = this.organizationGroupsService.getOne(this.id);
			if (!this.group || this.group == null || this.group.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe(authUser => {
					this.canEdit = authUser
						&& (this.group.manager_ids.includes(authUser.id)
							|| ValidationTools.checkAccess(authUser, 'manage-organization-groups'));

					this.canDelete = this.canEdit && authUser && ValidationTools.checkAccess(authUser, 'delete-organization-groups');
					this.canSendNotifications = (authUser && ValidationTools.checkAccess(authUser, 'send-general-notifications'));
					this.loadData();
				});

				this.organizationGroupsService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		// builds

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(skipUsers = false) {
		this.loading = true;

		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();

		this.productTypesToShow = [];
		for (const productType of AppConstants.productTypes) {
			let countForType = 0;
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.group.product_platforms, product.id, platform.id) !== -1;
					if (this.findProductPlatform(this.group.product_platforms, product.id, platform.id) !== -1) countForType++;
				}
			}
			if (countForType !== 0) this.productTypesToShow.push(productType);
		}

		this.documents = [];
		if (this.group.document_ids && this.group.document_ids.length !== 0) {
			for (const did of this.group.document_ids) {
				const d = this.documentsService.getOne(did);
				if (d) {
					this.documents.push(d);
				}
			}
			// this.documents.sort((a, b) => (a.label > b.label) ? 1 : -1);
		}

		this.builds = [];
		if (this.group.build_ids && this.group.build_ids.length !== 0) {
			for (const bid of this.group.build_ids) {
				const b = this.buildsService.getOne(bid);
				if (b) {
					this.builds.push(b);
				}
			}
		}

		this.showDirectTab = (this.builds.length + this.documents.length >= this.minDirectForTab);

		this.organizations = [];
		if (this.group.org_ids && this.group.org_ids.length !== 0) {
			for (const oid of this.group.org_ids) {
				const o = this.organizationsService.getOne(oid);
				if (o) {
					this.organizations.push(o);
				}
			}
			this.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		if (this.group.manager_ids && this.group.manager_ids.length !== 0) {
			this.managers = [];
			for (const uid of this.group.manager_ids) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.managers.push(u);
				}
			}
			this.managers.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.addedByUser = null;
		if (this.group.added_by && this.group.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.group.added_by);

		this.editedByUser = null;
		if (this.group.edited_by && this.group.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.group.edited_by);

		this.buildDownloads = await this.myBuildsService.fetchBuildsForGroupFromDB(this.id);
		if (this.dBuildsTable1) this.dBuildsTable1.updateContent(this.buildDownloads, 'cp-organization-group-view-downloadable-builds');

		this.documentDownloads = await this.myDocumentsService.fetchDocumentsForGroupFromDB(this.id);
		if (this.dDocsTable1) this.dDocsTable1.updateContent(this.documentDownloads, 'cp-organization-group-view-downloadable-documents');

		this.adminLogs = await this.adminLogsService.getLogs(['organization-group'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-organization-group-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.deleteForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;
	}

	async delete() {
		this.loading = true;

		let confirmation = '';
		if (this.deleteForm.value.confirmation) confirmation = this.deleteForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'delete') {
			this.showDeleteMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDeleteModalButton"))
			document.getElementById("closeDeleteModalButton").click();

		const result = await this.organizationGroupsService.deleteOne(this.group.id);
		if (result) {
			this.uiAlertsService.addMsg('The group (' + this.group.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.organizationgroups]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Group?')) {
			const result = await this.organizationGroupsService.toggleEnabled(this.group.id);
			if (result) {
				this.group = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Group?')) {
			const result = await this.organizationGroupsService.toggleEnabled(this.group.id);
			if (result) {
				this.group = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	getProduct(id: number) {
		return this.productsService.getOne(id);
	}

	getPlatform(id: number) {
		return this.platformsService.getOne(id);
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	subListOfUsedProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				for (const pp of this.group.product_platforms) {
					if (pp.product_id === product.id) {
						sublist.push(product);
						break;
					}
				}
			}
		}
		return sublist;
	}

	getPlatformIdsForProduct(productId: number, productPlatforms: Models.ProductPlatform[]): number[] {
		const pids = [];
		for (const pp of productPlatforms)
			if (pp.product_id === productId)
				pids.push(pp.platform_id);
		return pids;
	}

	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}
}
