import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { ZenCustomersService } from '../zen-customers.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { BuildsService } from '../../builds/builds.service';
import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-zen-customers-report-clusters',
	templateUrl: './zen-customers-report-clusters.component.html',
	styleUrls: ['./zen-customers-report-clusters.component.scss']
})
export class ZenCustomersReportClustersComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	items: Models.ZenMasterCluster[] = [];
	itemsToShow: Models.ZenMasterCluster[] = [];

	zenCustomers: Models.ZenMasterCustomer[] = [];
	zenBuilds: Models.Build[] = [];

	availableBuilds = {};

	loading = true;
	organizations: Models.Organization[] = [];

	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices = [];
	assessmentChoices = [];
	zmTypeChoices = [];

	// filters
	enabledFilter: number = null;
	assessmentFilters = [];
	zmTypeFilters = [];

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-zen-clusters-report';

	columnDefs: any[] = [
		{
			field: '__prefix',
			type: 'baseText',
			sortType: 'text',
			label: 'Site',
			toolTip: null,
			icon: null
		}, {
			field: '__type',
			type: 'baseText',
			sortType: 'text',
			label: 'Site Type',
			toolTip: null,
			icon: null
		}, {
			field: '__assessment',
			type: 'baseText',
			sortType: 'text',
			label: 'Assessment',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'cloud_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Cloud',
			toolTip: null,
			icon: null
		}, {
			field: 'config_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Config',
			toolTip: null,
			icon: null
		}, {
			field: 'instance_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Instance',
			toolTip: null,
			icon: null
		}, {
			field: '__version',
			type: 'baseText',
			sortType: 'text',
			label: 'Version',
			toolTip: null,
			icon: null
		}, {
			field: '__numBx',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Broadcasters',
			icon: 'hashtag'
		}, {
			field: 'has_build',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'ZCP Build Exists',
			icon: 'check'
		}, {
			field: '__enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__private',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Private - Limited Access',
			icon: 'lock'
		}
	];

	fieldsToShow: string[] = [];

	constructor(
		private router: Router,
		private zenCustomersService: ZenCustomersService,
		private organizationsService: OrganizationsService,
		private buildsService: BuildsService
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-zenClusters.settings'))
				settings = JSON.parse(localStorage.getItem('cp-zenClusters.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('enabledFilter')) this.enabledFilter = settings.enabledFilter;
		if (settingKeys.includes('assessmentFilters')) this.assessmentFilters = settings.assessmentFilters;
		if (settingKeys.includes('zmTypeFilters')) this.zmTypeFilters = settings.zmTypeFilters;

		this.showFilters = (
			this.enabledFilter != null
			|| this.assessmentFilters.length !== 0
			|| this.zmTypeFilters.length !== 0
		);

		this.initForm();
		this.loadZenObjects();
	}

	// ------------------------------------------------------------------------
	async loadZenObjects(forceReload = false) {
		this.loading = true;
		this.zenCustomers = this.zenCustomersService.getAll();
		this.items = await this.zenCustomersService.getZenClusters();
		this.zenBuilds = await this.buildsService.fetchZenBuilds();

		this.organizations = this.organizationsService.getAll();

		for (const zc of this.zenCustomers) {
			if (zc.zcp_org_id && zc.zcp_org_id !== 0) {
				const org = this.organizationsService.getOne(zc.zcp_org_id);
				zc['' + 'organization'] = org;
			}
		}

		for (const item of this.items) {
			const idx = MiscTools.findIndexGeneric(this.zenCustomers, 'id', item.customer_id);
			if (idx !== -1)
				item.zen_customer = this.zenCustomers[idx];

			let altVersion = '';
			if (item.version.startsWith('1.'))
				altVersion = item.version.substring(2);

			let idx2 = MiscTools.findIndexGeneric(this.zenBuilds, 'version', item.version);
			if (idx2 === -1 && altVersion !== '')
				idx2 = MiscTools.findIndexGeneric(this.zenBuilds, 'version', altVersion);

			if (idx2 !== -1) {
				item['' + 'has_build'] = true;
				item['' + 'build_id'] = this.zenBuilds[idx2].id;
				if (this.zenBuilds[idx2].is_enabled && this.zenBuilds[idx2].is_enabled === 1)
					item['' + 'build_enabled'] = true;
				else
					item['' + 'build_enabled'] = false;

				if (this.zenBuilds[idx2].is_private && this.zenBuilds[idx2].is_private === 1)
					item['' + 'build_private'] = true;
				else
					item['' + 'build_private'] = false;

			} else {
				item['' + 'has_build'] = false;
				item['' + 'build_enabled'] = false;
				item['' + 'build_private'] = false;
			}

			item['__prefix'] = item.zen_customer.dns_prefix
			item['__type'] = AppConstants.zenMasterTypeLabels[item.zen_customer.zcp_type]
			item['__assessment'] = item.zen_customer.assessment
			item['__version'] = TextTools.cleanVersion(item.version)
			item['__numBx'] = item.primary_size + item.backup_size
			item['__enabled'] = item['has_build'] && item['build_enabled']
			item['__private'] = item['has_build'] && item['build_private']
		}

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, '__prefix', 'asc');
	}

	// ------------------------------------------------------------------------
	initForm() {
		this.zmTypeChoices = [];
		this.zmTypeChoices.push({ value: '', label: 'Unknown/Not Set' });
		for (const zenMasterType of AppConstants.zenMasterTypes)
			this.zmTypeChoices.push({ value: zenMasterType, label: AppConstants.zenMasterTypeLabels[zenMasterType] });

		this.assessmentChoices = [];
		for (const zenMasterAssessment of AppConstants.zenMasterAssessments)
			this.assessmentChoices.push({ value: zenMasterAssessment });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			assessmentFilters: new UntypedFormControl(this.assessmentFilters),
			zmTypeFilters: new UntypedFormControl(this.zmTypeFilters)
		});
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.assessmentFilters = this.theForm.value.assessmentFilters;
		this.zmTypeFilters = this.theForm.value.zmTypeFilters;

		let settings: any = {
			enabledFilter: this.enabledFilter,
			assessmentFilters: this.assessmentFilters,
			zmTypeFilters: this.zmTypeFilters
		};
		localStorage.setItem('cp-zenClusters.settings', JSON.stringify(settings));

		this.setSortBy(null);
	}

	// ------------------------------------------------------------------------
	getSiteRoute(cluster: Models.ZenMasterCluster) {
		if (cluster.zen_customer)
			return ['/' + AppConstants.urls.zencustomers, cluster.zen_customer.id];
		return null;
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZenMasterCluster[] = [];
		for (const item of this.items) {

			let passedEnabledFilter = false;
			let passedTypeFilter = false;
			let passedAssessmentFilter = false;

			if (this.enabledFilter == null || this.enabledFilter === item.zen_customer.is_enabled)
				passedEnabledFilter = true;

			if (!this.zmTypeFilters || this.zmTypeFilters.length === 0
				|| (this.zmTypeFilters.length !== 0 && item.zen_customer.zcp_type && this.zmTypeFilters.includes(item.zen_customer.zcp_type)))
				passedTypeFilter = true;

			if (this.zmTypeFilters.length !== 0 && this.zmTypeFilters.includes('') && (!item.zen_customer.zcp_type || item.zen_customer.zcp_type === ''))
				passedTypeFilter = true;

			if (!this.assessmentFilters || this.assessmentFilters.length === 0 || this.assessmentFilters.includes(item.zen_customer.assessment))
				passedAssessmentFilter = true;

			let match: boolean = passedEnabledFilter && passedTypeFilter && passedAssessmentFilter;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if ((item.zen_customer.dns_prefix && item.zen_customer.dns_prefix.toLowerCase().includes(w))
						|| (item.name && item.name.toLowerCase().includes(w))
						|| (item.zen_customer.assessment && item.zen_customer.assessment.toLowerCase().includes(w))
						|| (item.name && item.name.toLowerCase().includes(w))
						|| (item.cloud_type && item.cloud_type.toLowerCase().includes(w))
						|| (item.config_name && item.config_name.toLowerCase().includes(w))
						|| (item.instance_type && item.instance_type.toLowerCase().includes(w))
						|| (item['__type'] && item['__type'].toLowerCase().includes(w))
						|| (item['__version'] && item['__version'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}

