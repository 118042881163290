import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { ProductsService } from '../products.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	// 'standard' list stuff
	products: Models.Product[];
	loading = true;
	refreshing = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	canAdd = false;

	constructor(private productsService: ProductsService, private authService: AuthService) { }

	ngOnInit(): void {
		this.loading = true;

		this.listSubscription = this.productsService.products.subscribe(
			(products) => {
				this.products = products;
				if (this.products) {
					this.products.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
					this.loading = false;
				}
			}
		);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-products');
		});

		// Start List Auto Refresh
		// this.startListRefresh();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	async refresh() {
		this.refreshing = true;
		try {
			await this.productsService.refreshAll().toPromise();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}

	hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return ValidationTools.hasFlag(obj, flag);
	};

}
