<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.myfilesharing" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">File Sharing</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Send Files
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label for="role">Who would you like to send files to?*</label>
			<div class="form-check" *ngFor="let destinationSelection of destinationSelections">
				<input class="form-check-input" type="radio" formControlName="destination" name="destination"
					[id]="'destination_' + destinationSelection.value" [value]="destinationSelection.value" />
				<label class="form-check-label" [for]="'destination_' + destinationSelection.value">
					<strong>{{ destinationSelection.label }}</strong>
				</label>
			</div>
		</div>

		<div class="form-group">
			<label for="information">Information</label>
			<textarea id="information" formControlName="information" class="form-control" rows="4"
				placeholder="Optional information"></textarea>
		</div>

		<!-- <div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon> Next Step: Upload File(s)
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div> -->

		<div class="cp-form-padding"></div>
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon> Next Step: Upload File(s)
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>