import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { LinkLogsService } from './link-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../users/users.service';

import { ClicksTableComponent } from 'client/app/components/shared/clicks-table/clicks-table.component';

@Component({
	selector: 'app-link-logs',
	templateUrl: './link-logs.component.html',
	styleUrls: ['./link-logs.component.scss']
})
export class LinkLogsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	logs: Models.LinkLog[];
	loading = true;
	refreshing = false;

	@ViewChild('clicksTable1') clicksTable1: ClicksTableComponent = null;

	// other stuff...
	maxMessage = '';
	showFilters = true;
	theForm: UntypedFormGroup;
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	role = '';
	users: Models.User[] = [];

	constructor(
		private router: Router,
		private linkLogsService: LinkLogsService,
		private usersService: UsersService,
		private authService: AuthService) { }

	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-linklogs.settings'))
				settings = JSON.parse(localStorage.getItem('cp-linklogs.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.notBefore != null || this.notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser)
				this.role = authUser.role;
		});

		this.loadUsers();
		this.initForm();
		this.loadLogs();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-linklogs.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		this.logs = await this.linkLogsService.getLogs(startDate, endDate);

		if (this.logs && this.logs.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		await MiscTools.delay(100);
		if (this.clicksTable1)
			this.clicksTable1.updateContent(this.logs, 'cp-click-logs', { showUserInfo: true, linkUser: true });

		this.loading = false;
	}

	async loadUsers() {
		this.users = this.usersService.getAll();
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}
}
