import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { MyKeysService } from '../my-keys.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-snooze-form',
	templateUrl: './snooze-form.component.html',
	styleUrls: ['./snooze-form.component.scss']
})
export class SnoozeFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	id: number;
	snoozeId: number;
	snoozeType: string;

	licenseKey: Models.UserLicenseKey = null;
	theSnooze: Models.UserLicenseKeySnooze = null;

	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.snoozeId = +params.get('snoozeid');
			this.snoozeType = params.get('type');
		});
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.licenseKey = await this.myKeysService.fetchKey(this.id);

		if (!this.licenseKey || this.licenseKey == null || this.licenseKey.id === 0 || !this.licenseKey.activation) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		if (this.snoozeId && this.snoozeId !== 0) {
			const userSnoozes = await this.myKeysService.getUserSnoozes();
			const idx = MiscTools.findIndex(userSnoozes, this.snoozeId);
			if (idx !== -1) {
				this.theSnooze = userSnoozes[idx];
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if
		} else {
			this.theSnooze = new Models.UserLicenseKeySnooze(0, this.authUser.id, this.id, null, '');

			if (this.snoozeType && this.snoozeType !== ''
				&& MiscTools.findIndexGeneric(AppConstants.keySnoozeTypes, 'value', this.snoozeType) !== -1)
				this.theSnooze.snooze_type = this.snoozeType;

			this.theSnooze.expires_at = MiscTools.dateFromNow(AppConstants.defaultSnoozeDays, 'day');
		} // if

		if (this.theSnooze.snooze_type === '') this.theSnooze.snooze_type = 'all';

		let expiresAtStruct: NgbDateStruct = null;
		if (this.theSnooze.expires_at) {
			const expiresAtDate = new Date(this.theSnooze.expires_at);
			expiresAtStruct = { day: expiresAtDate.getUTCDate(), month: expiresAtDate.getUTCMonth() + 1, year: expiresAtDate.getUTCFullYear() };
		}

		this.theForm = new UntypedFormGroup({
			snooze_type: new UntypedFormControl(this.theSnooze.snooze_type, [Validators.required]),
			expires_at: new UntypedFormControl(expiresAtStruct, [Validators.required])
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const expiresStruct: NgbDateStruct = this.theForm.value.expires_at;
		if (expiresStruct) {
			let expiresDate: Date = new Date(expiresStruct.year + '/' + expiresStruct.month + '/' + expiresStruct.day + ' 00:00:00 UTC');
			if (isNaN(expiresDate.getTime())) expiresDate = null;
			if (expiresDate)
				this.theSnooze.expires_at = expiresDate;
		} // if
		this.theSnooze.snooze_type = this.theForm.value.snooze_type.trim();

		const now = new Date();
		if (!this.theSnooze.expires_at)
			this.errors.push('You must supply a date.');
		else if (this.theSnooze.expires_at.getTime() < now.getTime())
			this.errors.push('The date must be in the future.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let ret: Models.UserLicenseKeySnooze = null

			if (this.snoozeId && this.snoozeId !== 0)
				ret = await this.myKeysService.updateUserSnooze(this.theSnooze);
			else
				ret = await this.myKeysService.addUserSnooze(this.theSnooze);

			if (ret) {
				this.onCancel();
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.mykeys + '/' + this.licenseKey.id]);
	}

	// setEpiry(amount: number, unit = 'day') {
	// 	let theDate: Date = MiscTools.dateFromNow(amount, unit);
	// 	// if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
	// 	// 	theDate = new Date(this.maxExpiryDate);

	// 	if (theDate && !isNaN(theDate.getTime())) {
	// 		const expiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
	// 		this.theForm.controls['expires_at' + ''].setValue(expiresAt);
	// 	}
	// }

	increaseExpiry(amount: number, unit = 'day') {
		let theDate: Date = null;

		const now = new Date();

		if (amount === -1 && unit === 'next-month') {
			const daysInMonth = MiscTools.daysInMonth(now.getMonth() + 1, now.getFullYear());
			theDate = new Date(now.getFullYear().toString().padStart(4, '0')
				+ '-' + (now.getMonth() + 1).toString().padStart(2, '0')
				+ '-' + daysInMonth.toString().padStart(2, '0') + ' 00:00:00 UTC');
			theDate = MiscTools.dateIntervalAdd(theDate, 1, 'day');
		} else {
			let expiresDate: Date = null;
			const expiresStruct: NgbDateStruct = this.theForm.value.expires_at;
			if (expiresStruct) {
				expiresDate = new Date(expiresStruct.year + '/' + expiresStruct.month + '/' + expiresStruct.day + ' 00:00:00 UTC');
				if (isNaN(expiresDate.getTime())) expiresDate = null;
			}
			if (expiresDate === null) expiresDate = new Date();

			theDate = MiscTools.dateIntervalAdd(expiresDate, amount, unit);
			// if (this.maxExpiryDate != null && theDate && !isNaN(theDate.getTime()) && theDate.getTime() > this.maxExpiryDate.getTime())
			// 	theDate = new Date(this.maxExpiryDate);
		} // if

		if (theDate && !isNaN(theDate.getTime())) {
			const expiresAt: NgbDateStruct = { day: theDate.getUTCDate(), month: theDate.getUTCMonth() + 1, year: theDate.getUTCFullYear() };
			this.theForm.controls['expires_at' + ''].setValue(expiresAt);
		}
	}

}
