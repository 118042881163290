import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';

import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { SCREEN_SIZE } from 'client/app/models/screen-size.enum';
import { ResizeService } from 'client/app/services/resize.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// userItems = AppConstants.baseUserItems;
	// adminItems = AppConstants.baseAdminItems;

	navItems = AppConstants.basicMenuItems;
	authUser: Models.AuthUser;
	loggedIn = false;

	size: SCREEN_SIZE;
	isStaff: boolean = false;
	isMobile: boolean;
	isOpen = false;

	itemsSetup = false;

	// menuMode = 'user';
	// canToggleMode = false;

	private resizeSubscription: Subscription;
	private userSubscription: Subscription;
	private navSubscription: Subscription;

	constructor(private authService: AuthService, private rs: ResizeService) {
	}

	ngOnInit(): void {
		// Nav Toggle
		this.navSubscription = this.authService.toggle$.subscribe(() =>
			this.toggle()
		);

		// Resize
		this.resizeSubscription = this.rs.getCurrentSize.subscribe((x) => {
			this.size = x;
			if (this.size >= 4) {
				this.isMobile = false;
			} else {
				this.isMobile = true;
			}
		});

		this.userSubscription = this.authService.user.subscribe((user) => {
			this.loggedIn =
				!!user && user.id && user.role && +user.id !== 0 && user.role !== '';

			if (this.loggedIn) {
				this.authUser = user;

				if (!this.itemsSetup) {
					this.itemsSetup = true;
					this.isStaff = ValidationTools.checkRole(user.role, AppConstants.staffUserRole);
					this.navItems = [];
					if (this.isStaff) {
						this.navItems = AppConstants.staffMenuItems;
						const idxA = MiscTools.findIndexGeneric(this.navItems, 'id', 'portalSubMenu');
						if (idxA === -1) {
							this.navItems.push(
								{
									link: {
										name: 'Portal',
										icon: ['fas', 'portal-enter'],
										router: '',
										iconClass: 'cp-user-nav-item'
									},
									isCollapsed: true,
									dropDownMode: true,
									id: 'portalSubMenu',
									sublinks: []
								}
							);

							for (const item of AppConstants.basicMenuItems)
								this.navItems[this.navItems.length - 1].sublinks.push(item.link);

							for (const item of AppConstants.staffExtraMenuItems)
								this.navItems[this.navItems.length - 1].sublinks.push(item.link);

						}

					} else {
						this.navItems = AppConstants.basicMenuItems;
					} // if
				} // if

				for (const navItem of this.navItems)
					if (navItem.id && navItem.id !== '' && localStorage.getItem('cp-navigation.collapsed.' + navItem.id))
						if (localStorage.getItem('cp-navigation.collapsed.' + navItem.id) === 'false')
							navItem.isCollapsed = false;
						else
							navItem.isCollapsed = true;
			}
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
		if (this.navSubscription) this.navSubscription.unsubscribe();
	}

	buildRoute(route: string) {
		if (route && route !== '') return '/' + route;
		return null;
	}

	toggle() {
		this.isOpen = !this.isOpen;
	}

	toggleCollapse(item: Models.NavItem) {
		item.isCollapsed = !item.isCollapsed;
		if (item.id && item.id !== '')
			localStorage.setItem('cp-navigation.collapsed.' + item.id, item.isCollapsed + '');
	}

}
