<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="/zen-customers">ZEN Master Sites</a>
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				Incidents
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadIncidents()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || refreshing" ngbTooltip="Refresh" placement="start">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<!-- <div>states={{ states }}</div>
	<div>actions={{ actions }}</div> -->

	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-3">
				<ng-select [items]="stateSelections" bindValue="value" bindLabel="label" id="states" formControlName="states"
					[multiple]="true" (change)="onFormChange()" placeholder="Filter by State">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="seSelections" bindValue="id" bindLabel="name" id="seIds" formControlName="seIds" [multiple]="true"
					(change)="onFormChange()" placeholder="Filter by SE">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not Before yyyy-mm-dd" id="notBefore" formControlName="notBefore"
						firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>

			<div class="cp-filter-block-3">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>

					<input class="form-control" placeholder="Not After yyyy-mm-dd" id="notAfter" formControlName="notAfter" ngbDatepicker
						firstDayOfWeek="7" #d2="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="maxMessage !== ''" class="cp-warning-message">{{ maxMessage }}</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow">
				<td class="cp-chopped-column-no-hover" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body">
					{{ item['__zenname'] }}
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{ item['__se'] }}
				</td>
				<td class="cp-chopped-column-no-hover" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body">
					<a [href]="item['__url']" target="_blank">
						{{ item.name }}
					</a>
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{ item.state | titlecase}}
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{ item.start_time | date: appConstants.tableDateTimeFmt }}
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{ item['__duration_str'] }}
					<fa-icon *ngIf="item.end_time == null" icon="plus" size="sm" spin="true"></fa-icon>
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{item['__runtime_state'] }}
				</td>
				<td class="text-nowrap" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
					container="body">
					{{ item.likely_cause }}
				</td>
				<td class="cp-chopped-column-long-no-hover" [ngbPopover]="incidentPopover" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body">
					{{ item.triggering_error_message }}
				</td>
				<td class="text-center">{{ item.num_objects }}</td>

				<ng-template #incidentPopover>
					<div class="cp-popover-banner">{{ item.name }}</div>
					<div class="cp-pre-text">{{
						incidentPopoverLines(item).join('\n')}}</div>
				</ng-template>

			</tr>
		</tbody>
	</table>
</div>