<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Organizations
			</li>
			<li *ngIf="organization" class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">{{ organization.name }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Billing Code Admins</li>
		</ol>
	</nav>
</div>

<div *ngIf="loading || saving" style="text-align: center;">
	<app-loading-spinner></app-loading-spinner>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">

		<div class="form-group">
			<label for="userIDs">Users that Can Manage Billing Codes for this Organization</label>
			<ng-select [items]="orgUserSelections" bindValue="id" bindLabel="name" id="userIDs" formControlName="userIDs" [multiple]="true"
				[closeOnSelect]="false">
			</ng-select>
		</div>
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>

</form>