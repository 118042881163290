<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item active" aria-current="page">Properties</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="openPropertiesReport()">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Properties Report</span>
			</button>

			<button *ngIf="canManage" type="button" class="btn btn-outline-primary me-2" [routerLink]="['new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Property</span>
			</button>

			<button *ngIf="canManage" type="button" class="btn btn-outline-primary me-2"
				[routerLink]="['sort-properties']">
				<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Property Sort-Order</span>
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-features-tab" aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-limits-tab" aria-controls="nav-limits" data-bs-target="#nav-limits">
				<fa-icon [icon]="['fas', 'abacus']" size="sm"></fa-icon>
				Limits
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-other-tab" aria-controls="nav-other" data-bs-target="#nav-other">
				<fa-icon [icon]="['fas', 'cog']" size="sm"></fa-icon>
				Other
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-features" aria-labelledby="nav-features-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Label</th>
						<th>Name</th>
						<th>Description</th>
						<th>Special</th>
						<th ngbTooltip="Number of Products">#</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let property of getPropertiesByType('boolean')" [routerLink]="[property.id]">
						<td class="border-end text-nowrap"><a [routerLink]="[property.id]">
								{{ property.label }}
							</a></td>
						<td class="border-end">{{ property.name }}</td>
						<td class="border-end">{{ property.description }}</td>
						<td class="border-end text-nowrap">{{ niceBoolean(property.special_property) }}</td>
						<td>{{ getProductsForProperty(property.id) }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-limits" aria-labelledby="nav-limits-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Label</th>
						<th>Name</th>
						<th>Description</th>
						<th>Units</th>
						<th>Allow<br />Unlim.</th>
						<th>Special</th>
						<th ngbTooltip="Number of Products">#</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let property of getPropertiesByType('number')" [routerLink]="[property.id]">
						<td class="border-end text-nowrap"><a [routerLink]="[property.id]">
								{{ property.label }}
							</a></td>
						<td class="border-end">{{ property.name }}</td>
						<td class="border-end">{{ property.description }}</td>
						<td class="border-end text-nowrap">{{ property.units }}</td>
						<td class="border-end text-nowrap">{{ niceBoolean(property.allow_unlimited) }}</td>
						<td class="border-end text-nowrap">{{ niceBoolean(property.special_property) }}</td>
						<td>{{ getProductsForProperty(property.id) }}</td>
					</tr>
				</tbody>
			</table>

		</div>

		<div class="tab-pane" role="tabpanel" id="nav-other" aria-labelledby="nav-other-tab">
			<table *ngIf="!loading" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Label</th>
						<th>Name</th>
						<th>Description</th>
						<th>Special</th>
						<th ngbTooltip="Number of Products">#</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let property of getPropertiesByType('other')" [routerLink]="[property.id]">
						<td class="border-end text-nowrap"><a [routerLink]="[property.id]">
								{{ property.label }}
							</a></td>
						<td class="border-end">{{ property.name }}</td>
						<td class="border-end">{{ property.description }}</td>
						<td class="border-end text-nowrap">{{ niceBoolean(property.special_property) }}</td>
						<td>{{ getProductsForProperty(property.id) }}</td>
					</tr>
				</tbody>
			</table>

		</div>
	</div>
</div>