import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { AzureMarketplaceRegistrationService } from './azure-marketplace-registration.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-azure-marketplace-registration',
	templateUrl: './azure-marketplace-registration.component.html',
	styleUrls: ['./azure-marketplace-registration.component.scss']
})
export class AzureMarketplaceRegistrationComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	finished = false;

	token: string = null;
	subscription: any = null;
	productName: string = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private aureMarketplaceRegistrationService: AzureMarketplaceRegistrationService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.queryParams.subscribe(params => {
			if (params['token'] && params['token'] !== '')
				this.token = params['token'];
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {

		if (this.token && this.token !== '')
			this.subscription = await this.aureMarketplaceRegistrationService.getSubcriptionFromToken(this.token);

		let email: string = '';
		if (this.subscription) {
			if (this.subscription.beneficiary && this.subscription.beneficiary.emailId)
				email = this.subscription.beneficiary.emailId;

			const idx = MiscTools.findIndexGeneric(AppConstants.azureMarketPlaceProducts, 'productCode', this.subscription.planId);
			if (idx !== -1)
				this.productName = AppConstants.azureMarketPlaceProducts[idx].label;
		} // if

		this.theForm = new UntypedFormGroup({
			firstname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(120)]),
			lastname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(120)]),
			email: new UntypedFormControl(email, [Validators.required, Validators.email, Validators.maxLength(255)]),
			phone: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
			company: new UntypedFormControl(null, [Validators.required]),
			title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),

			country: new UntypedFormControl(null, [Validators.required]),
			state: new UntypedFormControl(null)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		let email = '';
		let firstname = '';
		let lastname = '';
		let title = '';
		let phone = '';
		let company = '';

		let country = '';
		let state = '';



		if (this.theForm.value.email) email = this.theForm.value.email.trim();
		if (this.theForm.value.firstname) firstname = this.theForm.value.firstname.trim();
		if (this.theForm.value.lastname) lastname = this.theForm.value.lastname.trim();
		if (this.theForm.value.title) title = this.theForm.value.title.trim();
		if (this.theForm.value.phone) phone = this.theForm.value.phone.trim();
		if (this.theForm.value.company) company = this.theForm.value.company.trim();
		if (this.theForm.value.country) country = this.theForm.value.country.trim();
		if (this.theForm.value.state) state = this.theForm.value.state.trim();

		if (email === '') this.errors.push('Missing E-Mail.');
		if (firstname === '') this.errors.push('Missing First Name.');
		if (lastname === '') this.errors.push('Missing Last Name.');
		if (title === '') this.errors.push('Missing Title.');
		if (phone === '') this.errors.push('Missing Phone.');
		if (company === '') this.errors.push('Missing Company.');
		if (country === '') this.errors.push('Missing Country.');

		// double check that the state selected is a US state
		if (country === 'US' && state && state !== '' && MiscTools.findIndexGeneric(AppConstants.usStates, 'code', state) === -1)
			state = '';

		// double check that the state selected is a CDN province
		if (country === 'CA' && state && state !== '' && MiscTools.findIndexGeneric(AppConstants.cdnProvinces, 'code', state) === -1)
			state = '';

		if (country === 'US' && (!state || state === ''))
			this.errors.push('Please choose a US State.');

		if (country === 'CA' && (!state || state === ''))
			this.errors.push('Please choose a Canadian Province.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		const props = {
			email,
			firstname,
			lastname,
			title,
			phone,
			company,
			country,
			state
		};

		try {
			const retUser = await this.aureMarketplaceRegistrationService.submitRegistration(props);
			if (retUser) {
				this.saving = false;
				this.finished = true;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	get firstname() { return this.theForm.get('firstname'); }
	get lastname() { return this.theForm.get('lastname'); }
	get email() { return this.theForm.get('email'); }
	get phone() { return this.theForm.get('phone'); }
	get company() { return this.theForm.get('company'); }
	get title() { return this.theForm.get('title'); }
	get country() { return this.theForm.get('country'); }
	get state() { return this.theForm.get('state'); }

}
