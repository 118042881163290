<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol *ngIf="pageMode === 'activation'" class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a [routerLink]="['/' + appConstants.urls.licensing]">Key Search</a>
			</li>
			<li *ngIf="activation" class="breadcrumb-item active" aria-current="page">
				{{ activation.key }}
			</li>
		</ol>

		<ol *ngIf="pageMode === 'hostid'" class="breadcrumb">
			<li *ngIf="!showLinkOfflineLicense" class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" [routerLink]="['/' + appConstants.urls.licensing]">Licensing</a>
			</li>

			<li *ngIf="!showLinkOfflineLicense && filterID !== 0 && filterKey !== ''" class="breadcrumb-item">
				<a href="javascript:void(0)" [routerLink]="['/' + appConstants.urls.licensing, filterID]">
					{{ filterKey }}
				</a>
			</li>

			<li *ngIf="showLinkOfflineLicense" class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" [routerLink]="['/' + appConstants.urls.licensing, 'offline']">Licensing</a>
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				{{ hostid }}
			</li>
		</ol>

		<ol *ngIf="pageMode === 'ip'" class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" [routerLink]="['/' + appConstants.urls.licensing]">Licensing</a>
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				{{ ip }}
			</li>
		</ol>

		<ol *ngIf="pageMode === 'aggregate'" class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" [routerLink]="['/' + appConstants.urls.licensing]">Licensing</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Aggregate
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">

			<div *ngIf="pageMode === 'activation'" class="btn-group">
				<button *ngIf="activation" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + appConstants.urls.mykeys, activation.key]"
					ngbTooltip="View the key in the same way a non-staff user sees it." placement="bottom-end">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					<span class="cp-action-button-text">User View</span>
				</button>

				<button *ngIf="!loading && showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Compact Data Sizes" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && !showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Expanded Data Sizes in Base Unit (megabytes)" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && activation && canManageKey" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + appConstants.urls.licensing, this.id, 'edit']" ngbTooltip="Edit" placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canConfigureMarketplace" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + appConstants.urls.licensing, this.id, 'marketplace']" ngbTooltip="Set/Unset Marketplace Reporting"
					placement="bottom-end">
					<fa-icon [icon]="appConstants.sectionIcons.marketplace" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && activation && canManageKeyViaTemplate" type="button" class="btn btn-outline-primary"
					(click)="clone()" ngbTooltip="Copy/Clone" placement="bottom-end">
					<fa-icon icon="clone" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Clone</span> -->
				</button>

				<button *ngIf="!loading && activation && canSetWriteAccess" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + appConstants.urls.licensing, this.id, 'write-access']" ngbTooltip="Grant Write Access"
					placement="bottom-end">
					<fa-icon icon="lock" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="!loading && activation && canSetTemplate" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + appConstants.urls.licensing, this.id, 'link-template']"
					ngbTooltip="Set the Template that this key uses." placement="bottom-end">
					<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Set Template</span>
				</button>

				<button type="button" class="btn btn-outline-secondary" (click)="loadData()" [disabled]="loading">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="bottom-end">
					</fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>

				<button *ngIf="prevId > 0" type="button" class="btn btn-outline-secondary" (click)="routeToKey(prevId)"
					[ngbTooltip]="prevNote" placement="bottom-end">
					<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
					<!-- Prev -->
				</button>

				<button *ngIf="nextId > 0" type="button" class="btn btn-outline-secondary" (click)="routeToKey(nextId)"
					[ngbTooltip]="nextNote" placement="bottom-end">
					<fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
					<!-- Next -->
				</button>
			</div>

			<div *ngIf="pageMode === 'hostid'" class="btn-group">
				<button *ngIf="filterID !== 0 && filterKey !== ''" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + appConstants.urls.mykeys, filterKey, 'host', hostid]"
					ngbTooltip="View the host ID in the same way a non-staff user sees it." placement="bottom-end">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					<span class="cp-action-button-text">User View</span>
				</button>

				<button *ngIf="!loading && showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Compact Data Sizes" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && !showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Expanded Data Sizes in Base Unit (megabytes)" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button type="button" class="btn btn-outline-secondary" [disabled]="loading" (click)="loadData()">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="bottom-end">
					</fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>
			</div>

			<div *ngIf="pageMode === 'aggregate'" class="btn-group">
				<button *ngIf="!loading && showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Compact Data Sizes" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && !showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Expanded Data Sizes in Base Unit (megabytes)" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

			</div>

		</div>
	</div>
</div>

<div class="clearfix"></div>

<div class="tab-container">

	<ul *ngIf="pageMode === 'activation'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-commercial-tab" aria-controls="nav-commercial" data-bs-target="#nav-commercial">
				<fa-icon [icon]="['fas', 'sack-dollar']" size="sm"></fa-icon>
				Commercial
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-activations-tab" aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'server']" size="sm"></fa-icon>
				Activations
				<span *ngIf="activation && (!loading || activation.licenses.length) " class="cp-tab-badge">
					{{ activation.licenses.length | number }}<span *ngIf="showMaxHostsExtra">+</span>
				</span>
			</button>
		</li>

		<li *ngIf="activeLicenses.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-active-tab"
				aria-controls="nav-active" data-bs-target="#nav-active">
				<fa-icon [icon]="['fas', 'bolt']" size="sm"></fa-icon>
				Active
				<span class="cp-tab-badge">
					{{ activeLicenses.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMaxHostsExtra" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-search-tab"
				aria-controls="nav-search" data-bs-target="#nav-search">
				<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
				Search
			</button>
		</li>

		<li *ngIf="failedPenTests && failedPenTests.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-pen-test-tab" aria-controls="nav-pen-test" data-bs-target="#nav-pen-test">
				<fa-icon [icon]="['fas', 'lock-open']" size="sm"></fa-icon>
				Pen Tests
				<span class="cp-tab-badge">
					{{ failedPenTests.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-features-tab" aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features/Limits
			</button>
		</li>

		<li *ngIf="showMeters" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-meters-tab"
				aria-controls="nav-meters" data-bs-target="#nav-meters">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Meters
				<span *ngIf="activation && (!loading || activation.meters.length > 0) " class="cp-tab-badge">
					{{ activation.meters.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMeterTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-meter-traffic-tab" aria-controls="nav-meter-traffic" data-bs-target="#nav-meter-traffic" ngbTooltip="Meter Traffic">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Traffic<sup>m</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-protocol-traffic-tab" aria-controls="nav-protocol-traffic" data-bs-target="#nav-protocol-traffic"
				ngbTooltip="Protocol Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>p</sup>
			</button>
		</li>

		<li *ngIf="showChronoTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-time-traffic-tab" aria-controls="nav-time-traffic" data-bs-target="#nav-time-traffic"
				ngbTooltip="Time-based Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>t</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic && (bcSummaryByHostId.length > 0 || invalidBillingCodeEntries.length > 0)" class="nav-item"
			role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-bc-summary-tab" aria-controls="nav-bc-summary" data-bs-target="#nav-bc-summary"
				ngbTooltip="Billing Code Summary by Host ID">
				<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
				</fa-icon>
				B.C. Summary
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users-tab"
				aria-controls="nav-users" data-bs-target="#nav-users">
				<fa-icon [icon]="appConstants.sectionIcons.users" size="sm"></fa-icon>
				Users
				<span *ngIf="!loading && userKeys && userKeys.length > 0" class="cp-tab-badge">
					{{ userKeys.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal" ngbTooltip="Journal Entries">
				<fa-icon [icon]="appConstants.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="marketplaceLabel !== '' || marketplaceLogs.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-marketplace-log-tab" aria-controls="nav-marketplace-log" data-bs-target="#nav-marketplace-log"
				ngbTooltip="Marketplace Log">
				<fa-icon [icon]="appConstants.sectionIcons.marketplace" size="sm"></fa-icon>
				<!-- marketplace -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-admin-log-tab" aria-controls="nav-admin-log" data-bs-target="#nav-admin-log" ngbTooltip="History/Change Log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<ul *ngIf="pageMode === 'hostid'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-activations-tab" aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'key']" size="sm"></fa-icon>
				Activations
				<span *ngIf="licenses && licenses.length > 1" class="cp-tab-badge">
					{{ licenses.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="labels.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-host-labels-tab"
				aria-controls="nav-host-labels" data-bs-target="#nav-host-labels">
				<fa-icon [icon]="['fas', 'pencil']" size="sm"></fa-icon>
				Labels
				<span class="cp-tab-badge">
					{{ labels.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMeterTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-meter-traffic-tab" aria-controls="nav-meter-traffic" data-bs-target="#nav-meter-traffic" ngbTooltip="Meter Traffic">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Traffic<sup>m</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-protocol-traffic-tab" aria-controls="nav-protocol-traffic" data-bs-target="#nav-protocol-traffic"
				ngbTooltip="Protocol Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>p</sup>
			</button>
		</li>

		<li *ngIf="showChronoTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-time-traffic-tab" aria-controls="nav-time-traffic" data-bs-target="#nav-time-traffic"
				ngbTooltip="Time-based Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>t</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic && (bcSummaryByHostId.length > 0 || invalidBillingCodeEntries.length > 0)" class="nav-item"
			role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-bc-summary-tab" aria-controls="nav-bc-summary" data-bs-target="#nav-bc-summary"
				ngbTooltip="Billing Code Summary by Host ID">
				<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
				</fa-icon>
				B.C. Summary
			</button>
		</li>

		<li *ngIf="versionHistory.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-versions-tab"
				aria-controls="nav-versions" data-bs-target="#nav-versions">
				<fa-icon [icon]="['fas', 'history']" size="sm"></fa-icon>
				Version History
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<ul *ngIf="pageMode === 'ip'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-activations-tab" aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'key']" size="sm"></fa-icon>
				Activations
				<span *ngIf="licenses && licenses.length > 1" class="cp-tab-badge">
					{{ licenses.length | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<ul *ngIf="pageMode === 'aggregate'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-aggregate-items-tab" aria-controls="nav-aggregate-items" data-bs-target="#nav-aggregate-items">
				<fa-icon [icon]="['fas', 'key']" size="sm"></fa-icon>
				Keys &amp; Host IDs
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-item-details-tab" aria-controls="nav-item-details" data-bs-target="#nav-item-details">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="showMeterTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-meter-traffic-tab" aria-controls="nav-meter-traffic" data-bs-target="#nav-meter-traffic" ngbTooltip="Meter Traffic">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Traffic<sup>m</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-protocol-traffic-tab" aria-controls="nav-protocol-traffic" data-bs-target="#nav-protocol-traffic"
				ngbTooltip="Protocol Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>p</sup>
			</button>
		</li>

		<li *ngIf="showChronoTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-time-traffic-tab" aria-controls="nav-time-traffic" data-bs-target="#nav-time-traffic"
				ngbTooltip="Time-based Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>t</sup>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">

		<!-- activation tab panels -->

		<div *ngIf="pageMode === 'activation'" class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">

			<div *ngIf="failedPenTestIPs && failedPenTestIPs.length !== 0" class="cp-danger-message mb-2">
				<fa-icon [icon]="['fas', 'lock-open']" size="sm" class="me-1">
				</fa-icon>
				This key has one or more activations from {{ failedPenTestIPs.length }} IP Address{{
				failedPenTestIPs.length > 1 ? 'es' : '' }} that are open to the internet and use the default username
				and password. See the Failed Pen Test tab.
			</div>

			<div *ngIf="!!activation && keyWarnings && keyWarnings.length !== 0" class="cp-warning-message mb-2">
				<div *ngFor="let item of keyWarnings">
					<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
					{{ item }}
				</div>
			</div>

			<div *ngIf="!!activation && (meterWarnings.length !== 0 || nonEnforcedWarnings.length !== 0)" class="cp-warning-message mb-2">
				<div *ngFor="let item of meterWarnings">
					<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					{{ item }}
				</div>
				<div *ngFor="let item of nonEnforcedWarnings">
					<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					{{ item }}
				</div>
			</div>

			<div *ngIf="activation" class="cp-width-limit-1400">
				<div class="row justify-content-start g-1">
					<div class="col-6 px-0">
						<div class="bg-light border m-1 p-1">
							<div class="cp-property-block" *ngIf="!loading && !organization">
								<span class="cp-property-label">Organization:</span>
								<span class="cp-property-value">Not linked
									<button *ngIf="canManageKeyViaTemplate && showOrgLinkButton" type="button"
										class="btn btn-outline-primary btn-sm ms-1" (click)="findPotentialOrganizations()">
										<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm" [spin]="orgSearching">
										</fa-icon>
										Find Possible Matches
									</button>
								</span>

								<div *ngIf="canManageKeyViaTemplate && orgLinkMatches.length > 0" class="ms-2">
									<div *ngFor="let item of orgLinkMatches" class="mt-1 p-1 border">
										<button type="button" class="btn btn-outline-primary btn-sm me-1"
											(click)="linkOrganization(item.organization.id)">
											<fa-icon [icon]="['fas', 'link']" size="sm" ngbTooltip="Link to this Organization"
												placement="end">
											</fa-icon>
										</button>
										<span class="fw-bold">{{ item.organization.name }}</span>
										{{ item.message }}
									</div>
								</div>

								<div *ngIf="canManageKeyViaTemplate && showNoMatches" class="ms-2">
									No matches found.
								</div>
							</div>

							<div class="cp-property-block" *ngIf="organization">
								<span class="cp-property-label">Organization:</span>
								<span class="cp-property-value">
									<a [routerLink]="['/' + appConstants.urls.organizations, organization.id]"
										[ngbPopover]="orgPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ organization.name }}
									</a>
									({{ appConstants.organizationTypeLabels[organization.otype] }})
									<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
										ngbTooltip="Organization is not enabled"></fa-icon>
								</span>

								<ng-template #orgPopupContent>
									<div class="cp-popover-banner">{{ organization.name }}</div>
									<div class="cp-pre-text">{{
										popOverTools.getOrganizationPopoverLines(organization).join('\n')}}</div>
								</ng-template>
							</div>

							<div class="cp-property-block" *ngIf="organization && (organization.salesforce_account_owner || accountOwner)">
								<span class=" cp-property-label">Account Owner:</span>
								<span class="cp-property-value">
									<span *ngIf="!accountOwner">
										{{ organization.salesforce_account_owner }}
									</span>
									<a *ngIf="accountOwner" [routerLink]="['/' + appConstants.urls.users, accountOwner.id]"
										[ngbPopover]="userPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ accountOwner.name }}

										<ng-template #userPopupContent>
											<div class="cp-popover-banner">{{ accountOwner.name }}</div>
											<div class="cp-pre-text">{{
												popOverTools.getUserPopoverLines(accountOwner).join('\n')}}</div>
										</ng-template>
									</a>
								</span>
							</div>

							<div class="cp-property-block" *ngIf="organization && (organization.salesforce_se || accountSE)">
								<span class=" cp-property-label">SE/Tech-Rep:</span>
								<span class="cp-property-value">
									<span *ngIf="!accountSE">
										{{ organization.salesforce_se }}
									</span>
									<a *ngIf="accountSE" [routerLink]="['/' + appConstants.urls.users, accountSE.id]"
										[ngbPopover]="userPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ accountSE.name }}

										<ng-template #userPopupContent>
											<div class="cp-popover-banner">{{ accountSE.name }}</div>
											<div class="cp-pre-text">{{
												popOverTools.getUserPopoverLines(accountSE).join('\n')}}</div>
										</ng-template>
									</a>
								</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">{{ appConstants.keyInfoFieldLabel }} (Internal):</span>
								<span class="cp-property-value">{{ activation.info }}</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Key:</span>
								<span class="cp-property-value">
									<span class="cp-key">{{ activation.key }}</span>
									<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(activation.key)"
										[cbContent]="activation.key" ngbTooltip="Copy to Clipboard" placement="end" class="ms-1"></fa-icon>
								</span>
							</div>

							<div *ngIf="!sharedLicenseTools.isSpecialKey(activation)" class="cp-property-block">
								<span class="cp-property-label">Enabled:</span>
								<span *ngIf="activation.enabled === 1" class="cp-property-value">
									Yes
									<button *ngIf="canManageKey" type="button" class="btn btn-outline-primary btn-sm ms-1"
										data-bs-toggle="modal" data-bs-target="#disableModal">
										<fa-icon [icon]="['fas', 'ban']" size="sm" class="text-danger"></fa-icon>
										Disable Key
									</button>
								</span>
								<span *ngIf="activation.enabled === 0" class="cp-property-value text-danger">
									No
									<button *ngIf="canManageKey" type="button" class="btn btn-outline-primary btn-sm ms-1"
										(click)="enableKey()">
										<fa-icon [icon]="['fas', 'power-off']" size="sm" class="text-success"></fa-icon>
										Enable Key
									</button>
								</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Product:</span>
								<span *ngIf="keyProduct" class="cp-property-value">
									<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'products', keyProduct.id]"
										[ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ keyProduct.label }}
									</a> ({{ keyProduct.name }})
								</span>
								<span *ngIf="!keyProduct" class="cp-property-value">
									{{ activation.product }}
								</span>
								<ng-template #keyProductPopupContent>
									<div class="cp-popover-banner">{{ keyProduct.label }}</div>
									<div class="cp-pre-text">{{
										popOverTools.getKeyProductPopoverLines(keyProduct).join('\n')}}</div>
								</ng-template>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Type:</span>
								<span class="cp-property-value">
									{{ niceKeyType(activation) | titlecase }}
								</span>
							</div>

							<div class="cp-property-block"
								*ngIf="activation.contacts && activation.contacts !== '' && activation.contacts.trim() !== '--- []'">
								<span class="cp-property-label">Contacts:</span>
								<span class="cp-property-value">{{ activation.contacts }}</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Activations:</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': +activation.count >= +activation.max }">
									{{ activation.count | number }} of {{ activation.max | number }} used
									<button *ngIf="showOfflineButton" type="button" class="btn btn-outline-primary btn-sm ms-1"
										[routerLink]="['/' + appConstants.urls.licensing, this.id, 'offline']">
										<fa-icon [icon]="['fas', 'lightbulb-slash']" size="sm"></fa-icon>
										Create Offline License(s)
									</button>
								</span>
							</div>

							<div *ngIf="expiryMode && expiryMode !== ''" class="cp-property-block">
								<span class="cp-property-label">Expiration Mode:</span>
								<span class="cp-property-value">
									{{ sharedLicenseTools.niceKeyExpiryMode(expiryMode, true) }}
									<fa-icon [icon]="['fas', 'question-circle']"
										[ngbTooltip]="sharedLicenseTools.niceKeyExpiryMode(expiryMode, false)" placement="bottom-start">
									</fa-icon>
									<span *ngIf="activation.enabled === 0 && !sharedLicenseTools.isSpecialKey(activation)">
										- Key is not enabled</span>
								</span>
							</div>

							<div *ngIf="activation.enabled === 1 && expiryMode && expiryMode === 'meter'" class="cp-property-block">
								<!-- <span class="cp-property-label">Expiry:</span>
								<span class="cp-property-value">See meters</span> -->
								<span *ngIf="miscTools.hasExpired(keyExpiryCalc)" class="cp-property-label">Expired
									(meter):</span>
								<span *ngIf="!miscTools.hasExpired(keyExpiryCalc)" class="cp-property-label">Expires
									(meter):</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(keyExpiryCalc)}">
									{{ keyExpiryCalc | date: appConstants.shortPageDateFmt:'UTC' }}
									- {{ textTools.niceExpireText(keyExpiryCalc) }}
								</span>
							</div>

							<div *ngIf="expiryMode && expiryMode === 'date' && activation.expires_at" class="cp-property-block">
								<span *ngIf="miscTools.hasExpired(activation.expires_at)" class="cp-property-label">Expired:</span>
								<span *ngIf="!miscTools.hasExpired(activation.expires_at)" class="cp-property-label">Expires:</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(activation.expires_at)}">
									{{ activation.expires_at | date: appConstants.shortPageDateFmt:'UTC' }}
									- {{ textTools.niceExpireText(activation.expires_at) }}
								</span>
							</div>

							<div *ngIf="expiryMode && expiryMode === 'duration' && activation.duration && activation.duration !== 0"
								class="cp-property-block">
								<span class="cp-property-label">
									Duration
									<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Lifespan of licenses from date of issue"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">{{
									sharedLicenseTools.niceKeyDuration(activation.duration)
									}}</span>
							</div>

							<div class="cp-property-block" *ngIf="recentUsedHosts.length !== 0">
								<span class="cp-property-label">
									# Recent Host IDs
									<fa-icon [icon]="['fas', 'question-circle']" [ngbTooltip]="recentHostsPopup" placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ recentUsedHosts.length | number }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="showMeters && activation.first_meter_report">
								<span class="cp-property-label">
									Meter Reporting
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in against a meter"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_meter_report | date: appConstants.shortPageDateFmt:'UTC' }} to
									{{ activation.last_meter_report | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="showMeters && activation.first_meter_usage">
								<span class="cp-property-label">
									Meter Usage
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in non-zero traffic against a meter"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_meter_usage | date: appConstants.shortPageDateFmt:'UTC' }} to
									{{ activation.last_meter_usage | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="protocolReady && activation.first_protocol_report">
								<span class="cp-property-label">
									Protocol Reporting
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in protocol traffic"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_protocol_report | date: appConstants.shortPageDateFmt:'UTC' }}
									to
									{{ activation.last_protocol_report | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="protocolReady && activation.first_protocol_usage">
								<span class="cp-property-label">
									Protocol Usage
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in non-zero protocol traffic"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_protocol_usage | date: appConstants.shortPageDateFmt:'UTC' }} to
									{{ activation.last_protocol_usage | date: appConstants.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="lpUser && lpUser !== ''">
								<span class="cp-property-label">
									Last Touched By:
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="If the key was created using the old/legacy license portal, what user created or last updated it"
										placement="bottom-start">
									</fa-icon>
								</span>
								<span class="cp-property-value">{{ lpUser }}</span> [via License Portal]
							</div>

							<div *ngIf="activation.created_at" class="cp-property-block">
								<span class="cp-property-label">Added:</span>
								<span class="cp-property-value">
									{{ activation.created_at | date: appConstants.shortPageDateTimeFmt }}
									<span *ngIf="addedByUser"> by
										<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
											popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
											{{ addedByUser.name }}</a>

										<ng-template #userPopupContent>
											<div class="cp-popover-banner">{{ addedByUser.name }}</div>
											<div class="cp-pre-text">{{
												popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
										</ng-template>
									</span>
								</span>
							</div>

							<div class="cp-property-block" *ngIf="activation.created_at !== updatedDateToShow">
								<span class="cp-property-label">Edited:</span>
								<span class="cp-property-value">
									{{ updatedDateToShow | date: appConstants.shortPageDateTimeFmt }}
									<span *ngIf="editedByUser"> by
										<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
											popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
											{{ editedByUser.name }}</a>
										<ng-template #userPopupContent>
											<div class="cp-popover-banner">{{ editedByUser.name }}</div>
											<div class="cp-pre-text">{{
												popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
										</ng-template>
									</span>
								</span>
							</div>

							<div class="cp-property-block"
								*ngIf="organization && organization.salesforce_account_id && organization.salesforce_account_id !== ''">
								<span class="cp-property-label">Salesforce Account:</span>
								<span class="cp-property-value"><a *ngIf="salesForceUrl !== ''" target="_blank"
										href="{{ salesForceUrl }}/lightning/r/Account/{{organization.salesforce_account_id}}/view">
										{{ organization.salesforce_account_id }}
									</a>
									<span *ngIf="salesforceAccountInfoBlock === ''" class="text-success cp-pointer"
										(click)="getSalesforceInfoBlocks()" ngbTooltip="Get Info From Salesforce">
										<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
									</span>

									<div *ngIf="salesforceAccountInfoBlock !== ''"
										class="border p-2 ms-4 me-4 mb-2 cp-pre-text cp-general-width-limit">
										<span class="float-end cp-pointer">
											&nbsp; <fa-icon (click)="salesforceAccountInfoBlock = ''"
												[icon]="['fas', 'times-circle']"></fa-icon>
										</span>
										{{ salesforceAccountInfoBlock }}
									</div>
								</span>
							</div>

							<div class="cp-property-block"
								*ngIf="activation.opportunity_id && activation.opportunity_id !== '' && activation.opportunity_id.length > 5">
								<span class="cp-property-label">Salesforce Opportunity:</span>
								<span class="cp-property-value">
									<a *ngIf="salesForceUrl !== ''" target="_blank"
										href="{{ salesForceUrl }}/lightning/r/Opportunity/{{ activation.opportunity_id }}/view">
										{{ activation.opportunity_id }}
									</a>
									<span *ngIf="salesforceOpportunityInfoBlock === ''" class="text-success cp-pointer"
										(click)="getSalesforceInfoBlocks()" ngbTooltip="Get Info From Salesforce">
										<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
									</span>

									<div *ngIf="salesforceOpportunityInfoBlock !== ''"
										class="border p-2 ms-4 me-4 mb-2 cp-pre-text cp-general-width-limit">
										<span class="float-end cp-pointer">
											&nbsp; <fa-icon (click)="salesforceOpportunityInfoBlock = ''"
												[icon]="['fas', 'times-circle']"></fa-icon>
										</span>
										{{ salesforceOpportunityInfoBlock }}
									</div>
								</span>
							</div>

							<hr *ngIf="template || writeAccessUsers.length > 0" />

							<div class="cp-property-block" *ngIf="template">
								<span class="cp-property-label">Template:</span>
								<span class="cp-property-value">
									<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'templates', template.id]"
										[ngbPopover]="templatePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ template.name }}
									</a>
									<ng-template #templatePopupContent>
										<div class="cp-popover-banner">{{ template.name }}</div>
										<div class="cp-pre-text">{{
											popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
									</ng-template>

									<button *ngIf="this.isCompliant" type="button" (click)="checkCompliance(-1, true)"
										class="btn btn-outline-primary btn-sm ms-1" [disabled]="checkingCompliance || loading"
										ngbTooltip="This key is compliant with its assigned template.">
										<fa-icon *ngIf="checkingCompliance" [icon]="['fas', 'cog']" [spin]="true">
										</fa-icon>
										<fa-icon *ngIf="!checkingCompliance" [icon]="['fas', 'check']" class="text-success"
											[spin]="checkingCompliance">
										</fa-icon>
									</button>

									<button *ngIf="!this.isCompliant" type="button" (click)="checkCompliance(-1, true)"
										class="btn btn-outline-primary btn-sm ms-1" [disabled]="checkingCompliance || loading"
										ngbTooltip="This key is not compliant with its assigned template. Click to show issue(s).">
										<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-warning"></fa-icon>
									</button>

									<!-- otherTemplates -->

									<div *ngIf="otherTemplates.length > 0" class="btn-group ms-1 dropend">
										<button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button"
											id="dropDownOtherTemplate" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
											[disabled]="checkingCompliance || loading"
											ngbTooltip="Check if this key is compliant with another template.">
											<fa-icon [icon]="['fas', 'cog']" [spin]="checkingCompliance">
											</fa-icon>
											Check Other
										</button>
										<div class="dropdown-menu cp-scrollable-menu" aria-labelledby="dropDownOtherTemplate">
											<a *ngFor="let otherTemplate of otherTemplates" class="dropdown-item"
												(click)="checkCompliance(otherTemplate.id, true)">
												{{ otherTemplate.name }}
											</a>
										</div>
									</div>
								</span>
							</div>

							<div class="cp-property-block" *ngIf="writeAccessUsers.length > 0">
								<span class="cp-property-label">Users Granted Write Access:</span>
								<span class="cp-property-value">
									<ul class="mb-0">
										<li *ngFor="let writeAccessUser of writeAccessUsers">
											<a [routerLink]="['/' + appConstants.urls.users, writeAccessUser.id]"
												[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
												triggers="mouseenter:mouseleave" container="body">
												{{ writeAccessUser.name }}</a>
											<fa-icon *ngIf="writeAccessUser.is_enabled === 0" [icon]="['fas', 'ban']"
												class="text-danger ms-1" ngbTooltip="User is not enabled"></fa-icon>

											<ng-template #userPopupContent>
												<div class="cp-popover-banner">{{ writeAccessUser.name }}</div>
												<div class="cp-pre-text">{{
													popOverTools.getUserPopoverLines(writeAccessUser).join('\n')}}</div>
											</ng-template>
										</li>
									</ul>
								</span>
							</div>

							<!-- <div *ngIf="activation.enabled === 1">
								<hr />
								<div class="cp-property-block">
									<span class="cp-property-label">
										Key Expiry (Evaluated)
										<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="What is the "
										placement="bottom-start">
									</fa-icon>							
									</span>
									<span *ngIf="keyExpiryCalc" class="cp-property-value">{{ keyExpiryCalc | date:
										appConstants.shortPageDateFmt:'UTC' }}</span>
									<span *ngIf="!keyExpiryCalc" class="cp-property-value">Never</span>
								</div>
	
								<div class="cp-property-block">
									<span class="cp-property-label">
										License Expiry (Evaluated)
										<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="If the key was created using the old/legacy license portal, what user created or last updated it"
										placement="bottom-start">
									</fa-icon>							
									</span>
									<span *ngIf="licExpiryCalc" class="cp-property-value">{{ licExpiryCalc | date:
										appConstants.shortPageDateFmt:'UTC' }}</span>
									<span *ngIf="!licExpiryCalc" class="cp-property-value">Never</span>
								</div>
							</div>
							 -->

						</div>

						<div *ngIf="marketplaceLabel !== ''" class="bg-light border m-1 p-1">
							<div class="cp-property-block">
								<span class="cp-property-label">Marketplace:</span>
								<span class="cp-property-value">{{ marketplaceLabel }}</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">{{ marketPlaceAccountIdLabel }}:</span>
								<span class="cp-property-value">{{ marketplaceAccountIdentifier }}</span>
							</div>

							<div *ngIf="marketplaceProducts && marketplaceProducts.length > 0" class="cp-property-block">
								<span class="cp-property-label">Marketplace Product(s):</span>
								<span class="cp-property-value">
									<ul class="mb-0">
										<li *ngFor="let marketplaceProduct of marketplaceProducts">{{
											marketplaceProduct}}
										</li>
									</ul>
								</span>
							</div>

							<div *ngIf="selectedMarketPlaceProtocolSet != null" class="cp-property-block">
								<span class="cp-property-label">Protocol Set to Report to Marketplace:</span>
								<span class="cp-property-value" [ngbPopover]="pSetPopupContent" popoverClass="cp-popover-std"
									triggers="mouseenter:mouseleave" container="body">
									<a
										[routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', selectedMarketPlaceProtocolSet.id]">
										{{ selectedMarketPlaceProtocolSet.name }}
									</a>
								</span>
								<ng-template #pSetPopupContent>
									<div class="cp-popover-banner">{{ selectedMarketPlaceProtocolSet.name }}</div>
									<div class="cp-pre-text">{{
										popOverTools.getProtocolSetPopoverLines(selectedMarketPlaceProtocolSet,
										true).join('\n')}}</div>
								</ng-template>
							</div>

							<div *ngIf="lastMarketplaceUsageReported" class="cp-property-block">
								<span class="cp-property-label">Marketplace Last Reported:</span>
								<span class="cp-property-value">
									{{ lastMarketplaceUsageReported | date: appConstants.pageDateTimeFmt }}
								</span>
							</div>

							<button *ngIf="canReportToMarketplace" type="button" class="btn btn-outline-primary btn-sm"
								data-bs-toggle="modal" data-bs-target="#manualMarketplaceReportModal">
								<fa-icon [icon]="appConstants.sectionIcons.marketplace" size="sm"></fa-icon>
								Manually Submit Marketplace Usage
							</button>
						</div>
					</div>

					<div class="col-6 px-0">
						<div *ngIf="activation.notes && activation.notes.trim() !== ''" class="bg-light border m-1 p-1 overflow-auto"
							style="max-height: 480px">
							<div class="cp-property-block">
								<span class="cp-property-label">Notes (Internal):</span>
								<div class="cp-property-value cp-pre-text">
									{{ activation.notes.trim() }}
								</div>
							</div>
						</div>

						<div *ngIf="(activation.ext_label && activation.ext_label.trim() !== '') || (activation.ext_notes && activation.ext_notes.trim() !== '')"
							class="bg-light border m-1 p-1 overflow-auto" style="max-height: 480px">
							<div *ngIf="activation.ext_label && activation.ext_label.trim() !== ''" class="cp-property-block">
								<span class="cp-property-label">Label (External):</span>
								<span class="cp-property-value">{{ activation.ext_label }}</span>
							</div>

							<div *ngIf="activation.ext_label && activation.ext_label.trim() !== ''" class="cp-property-block">
								<span class="cp-property-label">Notes (External):</span>
								<div class="cp-property-value cp-pre-text">
									{{ activation.ext_notes.trim() }}
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-commercial" aria-labelledby="nav-commercial-tab">

			<div *ngIf="staffMode && !!activation && (meterWarnings.length !== 0 || nonEnforcedWarnings.length !== 0)"
				class="cp-warning-message mb-2">
				<div *ngFor="let item of meterWarnings">
					<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
					{{ item }}
				</div>
			</div>

			<div class="cp-info-message mb-2" *ngIf="canManageCommercial && !commercialTypeInfo && commercialGuesses != null &&
				commercialGuesses.commericialGuesses.length === 1">
				Based on this key's properties, the commercial type is likely:<br />
				<span class="fw-bold">
					{{ getCommercialTypeLabel(commercialGuesses.commericialGuesses[0]) }}
				</span><br />
				The reason is:<br />
				<span class="fw-bold">
					{{ commercialGuesses.commericialExtras }}
				</span><br />
				<button type="button" class="btn btn-outline-primary btn-sm mb-1"
					[routerLink]="['/' + appConstants.urls.licensing, this.id, 'commercial', commercialGuesses.commericialGuesses[0]]">
					Use this Recommendation
				</button>
			</div>

			<button *ngIf="!loading && activation && canManageCommercial" type="button" class="btn btn-outline-primary btn-sm mb-1"
				[routerLink]="['/' + appConstants.urls.licensing, this.id, 'commercial']">
				<!-- <fa-icon [icon]="['fas', 'sack-dollar']" size="sm"></fa-icon> -->
				Set/Update Commercial Information
			</button>

			<div *ngIf="activation" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Commercial Type:</span>
					<span *ngIf="!commercialTypeInfo" class="cp-property-value">Not Set</span>
					<span *ngIf="commercialTypeInfo" class="cp-property-value">
						{{ commercialTypeInfo.label }}<br />
					</span>
					<div *ngIf="commercialTypeInfo">
						<ul>
							<li *ngFor="let line of commercialTypeInfo.info.trim().split('\n')"
								[ngClass]="{ 'ms-4': line.startsWith('\t') }">
								{{ line }}
							</li>
						</ul>
					</div>
				</div>

				<!-- <div *ngFor="let pSet of linkedProtocolSets" class="cp-property-block">
					<span class="cp-property-label">Linked Protocol (Data) Set:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', pSet.id]" [ngbPopover]="pSetPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ pSet.name }}
						</a>
						<span *ngIf="pSet['projected'] && pSet['projected'] !== 0">
							(Monthly Proj.: {{ textTools.formattedMB(pSet['projected']) }})
						</span>
					</span>
					<ng-template #pSetPopupContent>
						<div class="cp-popover-banner">{{ pSet.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
					</ng-template>
				</div>

				<div *ngFor="let pSet of linkedChronoSets" class="cp-property-block">
					<span class="cp-property-label">Linked Protocol (Time) Set:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'protocol-sets', pSet.id]" [ngbPopover]="pSetPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ pSet.name }}
						</a>
						<span *ngIf="pSet['projected'] && pSet['projected'] !== 0">
							(Monthly Proj.: {{ textTools.formattedMB(pSet['projected']) }})
						</span>
					</span>
					<ng-template #pSetPopupContent>
						<div class="cp-popover-banner">{{ pSet.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getProtocolSetPopoverLines(pSet, true).join('\n')}}</div>
					</ng-template>
				</div> -->

				<div class="cp-property-block">
					<span class="cp-property-label">Commercial Notes:</span>
					<div class="cp-property-value cp-pre-text">{{ activation.commercial_info }}</div>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-features" aria-labelledby="nav-features-tab">
			<app-key-features #keyFeatures></app-key-features>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-activations" aria-labelledby="nav-activations-tab">
			<div *ngIf="!loading && activation && activation.licenses.length === 0" class="cp-light-message mb-1">
				This key does not have any activated licenses.
			</div>

			<div *ngIf="activation && activation.discard_licenses === 1" class="cp-light-message mb-1">
				Activated licenses for this key are deleted after {{ appConstants.maxStaleLicenseDays }} days if they no
				longer appear to be in use.
			</div>

			<div *ngIf="showMaxHostsExtra" class="cp-warning-message mb-1">
				Limited to showing {{ appConstants.maxLicenseRowsToFetch | number }} recent activations.
				<span *ngIf="fullLicenseCount !== 0">There's a total of {{ fullLicenseCount | number }} licenses in the
					database for this key.</span>
				<span *ngIf="activeLicenses.length > 0">Use the Active tab to find active licenses or use Search tab to
					find a specific license.</span>
				<span *ngIf="activeLicenses.length === 0">Use the Search tab to find a specific license.</span>
			</div>

			<button *ngIf="showDownloadRecentButton" type="button" class="btn btn-outline-secondary btn-sm mb-2 me-1"
				(click)="getNewLicenses()" ngbTooltip="Download Zip file with licenses issued over the last couple of days.">
				<fa-icon icon="file-archive" size="sm"></fa-icon>
				Download New Licenses
			</button>

			<button *ngIf="activation && activation.licenses.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 me-1"
				(click)="runLicenseReport()" ngbTooltip="Create a spreadsheet with information about all licenses issued from this key.">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate License Report
			</button>

			<button *ngIf="usageSummaryForm && activation && activation.licenses.length !== 0" type="button"
				class="btn btn-outline-secondary btn-sm mb-2 me-1" data-bs-toggle="modal" data-bs-target="#usageSummaryModal"
				ngbTooltip="Create a spreadsheet with a usage breakdown by month and for each activated host ID and by meter type and/or total protocol usage">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate Usage Summary Report By Host ID
			</button>

			<app-licenses-table #allLicensesTable></app-licenses-table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-active" aria-labelledby="nav-active-tab">
			<div class="cp-light-message mb-1">
				Below are systems that have reported in the last {{ appConstants.maxActiveLicenseDays }} days.
			</div>

			<app-licenses-table #activeLicensesTable></app-licenses-table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-pen-test" aria-labelledby="nav-pen-test-tab">

			<table *ngIf="failedPenTests && failedPenTests.length !== 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class="border-start">Host ID</th>
						<th class="border-start">IP</th>
						<th class="border-start">User</th>
						<th class="border-start">First Check</th>
						<th class="border-start">Latest Check</th>
						<th class="border-start">Details</th>
						<th class="border-start"></th>
						<th class="border-start"></th>
						<th class="border-start"></th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let p of failedPenTests" class="cp-pointer">
						<td class="border-start text-nowrap cp-key">
							<a [routerLink]="['/' + appConstants.urls.licensing, 'hostid', p.hostid, this.id]">
								{{ p.hostid }}</a>
						</td>
						<td class="border-start text-nowrap">
							<a [routerLink]="['/' + appConstants.urls.licensing, 'ip', p.ip]">
								{{ p.ip }}
							</a>
						</td>
						<td class="border-start text-nowrap">
							{{ p.username }}
						</td>
						<td class="border-start text-nowrap">
							{{ p.added_on | date: appConstants.tableDateTimeFmt }}
						</td>
						<td class="border-start text-nowrap">
							{{ p.last_checked_on | date: appConstants.tableDateTimeFmt }}
						</td>
						<td class="border-start">
							{{ p.test_msg }}
						</td>
						<td class="border-start text-nowrap">
							<button type="button" class="btn btn-outline-primary btn-sm" (click)="runPenTestOnIPs([p.ip], p.username)"
								[disabled]="penTestRunning">
								<fa-icon [icon]="['fas', 'bolt']" size="sm" [spin]="penTestRunning"></fa-icon>
								Re-run Pen Test
							</button>
						</td>
						<td class="border-start text-nowrap">
							<a [href]="'http://' + p.ip + ':4444/'" target="_blank" class="ms-1" ngbTooltip="Login Page">
								<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm"></fa-icon>
							</a>
						</td>
						<td class="border-start text-nowrap">
							<a [href]="'https://ipinfo.io/' + p.ip" target="_blank" class="ms-1" ngbTooltip="IP Info">
								<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-users" aria-labelledby="nav-users-tab">
			<div *ngIf="!loading && userKeys && userKeys.length === 0" class="cp-light-message">
				This key has not been registered by/shared with any users.
			</div>

			<div *ngIf="!loading && userKeys && userKeys.length > 0" class="cp-light-message">
				This key has been registered by/shared with the following users.
			</div>

			<table *ngIf="userKeys && userKeys.length !== 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class="border-start">Name</th>
						<th class="border-start">E-Mail</th>
						<th class="border-start">Organization</th>
						<th class="border-start">Label</th>
						<th class="border-start">Protocol Usage</th>
						<th class="border-start">Key Related Notifications</th>
						<th class="border-start cp-icon-header">
							<fa-icon [icon]="['fas', 'snooze']" size="sm" placement="start" ngbTooltip="Snoozed/Paused Notifications">
							</fa-icon>
						</th>
						<th class="border-start"></th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let item of userKeys" [routerLink]="['/' + appConstants.urls.users, item.user.id]" class="cp-pointer">
						<!-- <td class="text-nowrap">{{ item.created_at }}</td> -->
						<td class="border-start text-nowrap">
							<a [routerLink]="['/' + appConstants.urls.users, item.user.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ item.user.name }}</a>
						</td>
						<td class="border-start text-nowrap">{{ item.user.email }}</td>
						<td class="border-start text-nowrap">{{ item.user.org_name }}</td>
						<td class="border-start">
							<span *ngIf="!item.label || item.label === ''" class="font-italic"
								title="This User has not set a label for this key">
								Not Set
							</span>
							<span *ngIf="item.label && item.label !== ''">
								{{ item.label }}
							</span>
						</td>
						<td class="border-start">
							<span ngbTooltip="This user is able to see protocol usage in the portal.">
								Yes
							</span>

							<!-- <span *ngIf="textTools.getUserPropValue(item.user, appConstants.showBxProtocolStatsOverride) === 'yes'"
								ngbTooltip="This user is able to see protocol usage in the portal.">
								Yes
							</span>
							<span *ngIf="textTools.getUserPropValue(item.user, appConstants.showBxProtocolStatsOverride) !== 'yes'"
								ngbTooltip="This user is NOT able to see protocol usage in the portal.">
								No
							</span> -->
						</td>
						<td class="border-start cp-pre-text" [innerHTML]="sharedLicenseTools.makeUserNotificationSummary(item.user)"></td>
						<td class="border-start cp-icon-cell"
							[attr.data-order]="userSnoozes[item.user_id] ? userSnoozes[item.user_id].length : 0">
							<fa-icon *ngIf="userSnoozes[item.user_id] && userSnoozes[item.user_id].length > 0" [icon]="['fas', 'snooze']"
								size="sm" placement="start" [ngbTooltip]="makeSnoozeTooltip(item.user_id)">
							</fa-icon>
						</td>
						<td class="border-start">
							<fa-icon *ngIf="item.is_deleted === 1" [icon]="['fas', 'trash-alt']"
								ngbTooltip="The user has placed this key in the trash" placement="bottom-end">
							</fa-icon>
						</td>

						<ng-template #userPopupContent>
							<div class="cp-popover-banner">{{ item.user.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getUserPopoverLines(item.user).join('\n')}}</div>
						</ng-template>
					</tr>
				</tbody>
			</table>

			<div *ngIf="organization && otherOrgUsers.length > 0">
				Share Key with <span *ngIf="userKeys && userKeys.length !== 0">other</span>
				users from {{ organizationForUsers.name }}:
				<fa-icon [icon]="['fas', 'question-circle']" size="sm"
					ngbTooltip="The following users are part of the same organization as this key (or from its parent organization) and the key has not been shared with them.">
				</fa-icon>

				<div *ngFor="let u of otherOrgUsers" class="mt-1 ms-2">
					<button type="button" class="btn btn-outline-info btn-sm" (click)="shareKeyWithUser(u)"
						ngbTooltip="Share key with this user so they can view information about it" [disabled]="loading">
						<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
					</button>
					<a [routerLink]="['/' + appConstants.urls.users, u.id  ]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ u.name }}
					</a> ({{ u.email }})
					<ng-template #userPopupContent>
						<div class="cp-popover-banner">{{ u.name }}</div>
						<div class="cp-pre-text">{{
							popOverTools.getUserPopoverLines(u).join('\n')}}</div>
					</ng-template>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-search" aria-labelledby="nav-search-tab">
			<form *ngIf="searchForm" [formGroup]="searchForm" class="ms-4">
				<div class="row">
					<div class="form-group">
						<div class="input-group">
							<input type="text" id="hostidFilter" formControlName="hostidFilter" class="form-control"
								placeholder="Host ID(s)/IP(s)" />
							<button class="btn btn-primary btn-sm" type="button" (click)="doSearch()">
								<fa-icon [icon]="['fas', 'search']"></fa-icon> Search
							</button>
						</div>
					</div>
				</div>
			</form>

			<div [ngStyle]="{'display': !showSearchResults ? 'none' : '' }">
				<app-licenses-table #searchLicensesTable></app-licenses-table>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-admin-log" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && adminLogs.length === 0" class="cp-light-message">
				No system/change Logs for this License Key.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-journal" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this License Key.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + appConstants.urls.adminlogs, 'journal', 'activation', id]">
				<fa-icon [icon]="appConstants.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<div *ngFor="let item of journals" class="cp-property-list">
				<div *ngIf="canDeleteJournalEntry(item)" class="float-end">
					<fa-icon [icon]="['fas', 'trash-alt']" class="text-danger" size="sm" placement="start"
						ngbTooltip="Delete this journal entry" (click)="deleteJournalEntry(item)"></fa-icon>
				</div>
				<div class="fw-bold">
					{{ item.added_on | date: appConstants.shortPageDateTimeFmt }} by {{ getUsersName(item.added_by) }}
				</div>
				<div class="cp-pre-text">{{ item.information.trim() }}</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-marketplace-log"
			aria-labelledby="nav-marketplace-log-tab">
			<div *ngIf="!loading && marketplaceLogs.length === 0" class="cp-light-message">
				No marketplace logs for this key.
			</div>

			<app-marketplace-logs-table></app-marketplace-logs-table>
		</div>

		<!-- hostid tab panels -->

		<div *ngIf="['hostid', 'ip'].includes(pageMode)" class="tab-pane show active" role="tabpanel" id="nav-activations"
			aria-labelledby="nav-activations-tab">

			<div class="cp-width-limit-1400">
				<div *ngIf="filterID !== 0 && filterKey !== '' && activationIDsForObject.length > 1" class="cp-info-message mb-1">
					Showing activations for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{
						filterKey }}</span>
					<div>There are activations from {{ (activationIDsForObject.length - 1) }} other key(s).</div>
					<button (click)="filterTraffic(0)" type="button" class="btn btn-sm btn-outline-primary"
						ngbTooltip="Remove filter and show all usage" placement="start">
						<fa-icon icon="times-circle"></fa-icon>
						Show All Activations
					</button>
				</div>

				<div class="row gx-1">
					<div class="col-6">
						<div class="overflow-auto" style="max-height: calc(100vh - 250px);">
							<div *ngFor="let item of licenses" class="border p-2 mb-1"
								[hidden]="filterID !== 0 && filterID !== item.activation_id">
								<span class="text-end" style="float:right">
									<button (click)="showLicenseFeatures(item.id)" type="button" class="btn btn-sm me-1"
										[ngClass]="{'btn-outline-primary': selectedLicenseID !== item.id, 'btn-primary': selectedLicenseID === item.id}"
										ngbTooltip="Show features/limits set with this license" placement="start">
										<fa-icon [icon]="['fas', 'clipboard-list-check']" [fixedWidth]="true">
										</fa-icon>
									</button>

									<button *ngIf="item.fulfillment && item.fulfillment !== ''" (click)="downloadLicenseFiles(item.id)"
										type="button" class="btn btn-outline-primary btn-sm me-1"
										ngbTooltip="Download the license file from this activation with standard name" placement="start">
										<fa-icon [icon]="['fas', 'cloud-download-alt']" [fixedWidth]="true"></fa-icon>
										<sup>1</sup>
									</button>

									<button *ngIf="item.fulfillment && item.fulfillment !== ''"
										(click)="downloadLicenseFiles(item.id, true)" type="button"
										class="btn btn-outline-primary btn-sm me-1"
										ngbTooltip="Download the license file from this activation with filename that includes host ID"
										placement="start">
										<fa-icon [icon]="['fas', 'cloud-download-alt']" [fixedWidth]="true"></fa-icon>
										<sup>2</sup>
									</button>

								</span>

								<button *ngIf="linkOfflineForm && showLinkOfflineLicense && item.id === latestID" type="button"
									class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#linkOfflineModal">
									<fa-icon icon="link" size="sm"></fa-icon>
									Link to an Offline Key
								</button>

								<div class="cp-property-block" *ngIf="hostKeys[item.id]">
									<span class="cp-property-label">Key:</span>
									<span class="cp-property-value cp-key">
										<a [routerLink]="['/' + appConstants.urls.licensing, 'activation', item.activation_id]"
											[ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
											container="body">{{ hostKeys[item.id].key
											}}</a>
										<button
											*ngIf="!loading && filterID === 0 && item.activation_id && item.activation_id !== 0 && activationIDsWithUsage.length > 1"
											(click)="filterTraffic(item.activation_id)" type="button"
											class="btn btn-sm ms-1 btn-outline-primary"
											ngbTooltip="Filter usage to only show traffic using this key" placement="start">
											<fa-icon [icon]="['fas', 'filter']" [fixedWidth]="true"></fa-icon>
										</button>
									</span>
								</div>

								<div class="cp-property-block" *ngIf="!hostKeys[item.id]">
									<span class="cp-property-label">Key:</span>
									<span class="cp-property-value">No Key - Offline License
									</span>
								</div>

								<div class="cp-property-block"
									*ngIf="hostKeys[item.id] && hostKeys[item.id].zcp_org_id && hostKeys[item.id].zcp_org_id !== 0 && hostKeys[item.id].org_name && hostKeys[item.id].org_name !== ''">
									<span class="cp-property-label">Organization:</span>
									<span class="cp-property-value">
										<a [routerLink]="['/' + appConstants.urls.organizations, hostKeys[item.id].zcp_org_id ]">
											{{ hostKeys[item.id].org_name }}
										</a>
									</span>
								</div>

								<div class="cp-property-block" *ngIf="pageMode === 'ip'">
									<span class="cp-property-label">
										Host ID:
									</span>
									<span class="cp-property-value">
										<a [routerLink]="['/' + appConstants.urls.licensing, 'hostid', item.hostid, item.activation_id]">
											{{ item.hostid }}
										</a>
									</span>
								</div>

								<div class="cp-property-block">
									<span class="cp-property-label">Issued:</span>
									<span class="cp-property-value">
										{{ item.created_at | date: appConstants.shortPageDateTimeFmt }}
									</span>
								</div>

								<div *ngIf="item.updated_at" class="cp-property-block">
									<span class="cp-property-label">Updated:</span>
									<span class="cp-property-value">
										{{ item.updated_at | date: appConstants.shortPageDateTimeFmt }}
									</span>
								</div>

								<div *ngIf="!item.expires_at" class="cp-property-block">
									<span class="cp-property-label">Expires:</span>
									<span class="cp-property-value">Never - Permanent License</span>
								</div>

								<div *ngIf="item.expires_at" class="cp-property-block">
									<span *ngIf="miscTools.hasExpired(item.expires_at)" class="cp-property-label">Expired:</span>
									<span *ngIf="!miscTools.hasExpired(item.expires_at)" class="cp-property-label">
										Expires
										<fa-icon *ngIf="expiryExtraActivation[item.id] && expiryExtraActivation[item.id] !== ''"
											[icon]="['fas', 'question-circle']" size="sm" [ngbTooltip]="expiryExtraActivation[item.id]"
											placement="bottom-start"></fa-icon>
										:
									</span>
									<span class="cp-property-value">
										{{ item.expires_at | date: appConstants.shortPageDateTimeFmt }}
									</span>
								</div>

								<div class="cp-property-block">
									<span class="cp-property-label">Customer:</span>
									<span class="cp-property-value">{{ item.customer }}</span>
								</div>

								<div class="cp-property-block">
									<span class="cp-property-label">Product:</span>
									<span class="cp-property-value" [title]="item.product">
										{{ niceKeyProduct(item.product) }}
									</span>
								</div>

								<div class="cp-property-block">
									<span class="cp-property-label">Type:</span>
									<span class="cp-property-value">{{ item.type | titlecase }}</span>
								</div>

								<div class="cp-property-block" *ngIf="pageMode === 'hostid' && item.ip && item.ip !== ''">
									<span class="cp-property-label">
										IP
										<fa-icon [icon]="['fas', 'question-circle']" placement="end"
											[ngbTooltip]="appConstants.hostIDIPNote">
										</fa-icon>
										:
									</span>
									<span class="cp-property-value" ngxClipboard (click)="copyToClipboardAlert(item.ip)"
										[cbContent]="item.ip" ngbTooltip="Copy to Clipboard">
										<a [routerLink]="['/' + appConstants.urls.licensing, 'ip', item.ip]">
											{{ item.ip }}
										</a>
										&nbsp;<fa-icon [icon]="['fas', 'copy']"></fa-icon>
									</span>

									<a [href]="'https://ipinfo.io/' + item.ip" target="_blank" class="ms-1" ngbTooltip="IP Info">
										<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
									</a>
								</div>

								<div class="cp-property-block" *ngIf="pageMode === 'ip'">
									<span class="cp-property-label">
										IP
										<fa-icon [icon]="['fas', 'question-circle']" placement="end"
											[ngbTooltip]="appConstants.hostIDIPNote">
										</fa-icon>
										:
									</span>
									<span class="cp-property-value">
										{{ ip }}
									</span>
									<a [href]="'https://ipinfo.io/' + ip" target="_blank" class="ms-1" ngbTooltip="IP Info">
										<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
									</a>
								</div>

								<div class="cp-property-block" *ngIf="item.extraProperties">
									<span class="cp-property-label">
										Extra Properties:
									</span>
									<div class="cp-property-value cp-pre-text cp-75 ms-2">
										{{ sharedLicenseTools.niceLicenseExtraProperties(item.extraProperties) }}
									</div>
								</div>

								<div *ngIf="meterTypesPerActivation[item.id] && meterTypesPerActivation[item.id] !== ''"
									class="cp-property-block">
									<span class="cp-property-label">
										Meter Types (key):
									</span>
									<span class="cp-property-value">
										{{ meterTypesPerActivation[item.id] }}
									</span>
								</div>

								<div *ngIf="licenseMeterNames[item.id] && licenseMeterNames[item.id] !== ''" class="cp-property-block">
									<span class="cp-property-label">
										Meter Types (license):
									</span>
									<span class="cp-property-value">
										{{ licenseMeterNames[item.id] }}
									</span>
								</div>

								<div *ngIf="licenseFormats[item.id] && licenseFormats[item.id] !== ''" class="cp-property-block">
									<span class="cp-property-label">
										License File Format:
									</span>
									<span class="cp-property-value">
										{{ licenseFormats[item.id] }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item.first_meter_report">
									<span class="cp-property-label" ngbTooltip="Timeframe this activation reports in against a meter">
										Meter Reporting:
									</span>
									<span class="cp-property-value">
										{{ item.first_meter_report | date: appConstants.shortPageDateFmt:'UTC' }} to
										{{ item.last_meter_report | date: appConstants.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item.first_meter_usage">
									<span class="cp-property-label"
										ngbTooltip="Timeframe this activation reports in non-zero traffic against a meter">
										Meter Usage:
									</span>
									<span class="cp-property-value">
										{{ item.first_meter_usage | date: appConstants.shortPageDateFmt:'UTC' }} to
										{{ item.last_meter_usage | date: appConstants.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item.first_protocol_report">
									<span class="cp-property-label" ngbTooltip="Timeframe this activation reports in protocol traffic">
										Protocol Reporting:
									</span>
									<span class="cp-property-value">
										{{ item.first_protocol_report | date: appConstants.shortPageDateFmt:'UTC' }} to
										{{ item.last_protocol_report | date: appConstants.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item.first_protocol_usage">
									<span class="cp-property-label"
										ngbTooltip="Timeframe this activation reports in non-zero protocol traffic">
										Protocol Usage:
									</span>
									<span class="cp-property-value">
										{{ item.first_protocol_usage | date: appConstants.shortPageDateFmt:'UTC' }} to
										{{ item.last_protocol_usage | date: appConstants.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block"
									*ngIf="item.last_protocol_bx_version && item.last_protocol_bx_version !== ''">
									<span class="cp-property-label">Last Reported Version:</span>
									<span class="cp-property-value">
										{{ textTools.cleanVersion(item.last_protocol_bx_version) }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item['__penTest']">
									<span class="cp-property-label">
										Pen Test Results:
										<a [href]="'http://' + item.ip + ':4444/'" target="_blank" class="ms-1" ngbTooltip="Login Page">
											<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm"></fa-icon>
										</a>
									</span>
									<span class="cp-property-value">
										<table class="cp-std-table cp-auto-width">
											<thead>
												<tr class="cp-75">
													<th class="border-start"></th>
													<th class="border-start">User</th>
													<th class="border-start">Latest Check</th>
													<th class="border-start">Details</th>
													<th class="border-start"></th>
												</tr>
											</thead>

											<tbody>
												<tr *ngFor="let p of item['__penTest']" class="cp-75">
													<td class="border-start text-nowrap">
														<fa-icon *ngIf="appConstants.penTestCodes[p.test_code]" size="sm"
															[icon]="appConstants.penTestCodes[p.test_code].icon"
															[class]="appConstants.penTestCodes[p.test_code].css">
														</fa-icon>
													</td>
													<td class="border-start text-nowrap">
														{{ p.username }}
													</td>
													<td class="border-start text-nowrap">
														{{ p.last_checked_on | date: appConstants.tableDateTimeFmt }}
													</td>
													<td class="border-start">
														<span [ngbTooltip]="p.test_msg">
															{{ p.test_msg }}
														</span>
													</td>
													<td class="border-start text-nowrap">
														<button *ngIf="p.test_code === 0 || p.test_code === 3" type="button"
															class="btn btn-outline-primary btn-sm"
															(click)="runPenTestOnIPs([p.ip], p.username)" [disabled]="penTestRunning"
															ngbTooltip="Re-run Pen Test">
															<fa-icon [icon]="['fas', 'bolt']" size="sm" [spin]="penTestRunning"></fa-icon>
															Re-run
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</span>
								</div>

								<ng-template #popupContent>
									<div *ngIf="hostKeys[item.id]" class="cp-popover-banner">{{ hostKeys[item.id].key }}
									</div>
									<div *ngIf="hostKeys[item.id]" class="cp-pre-text">
										{{ popOverTools.getKeyPopoverLines(hostKeys[item.id], keyProducts,
										allOrgs, staffUsers, false).join('\n')}}
									</div>
								</ng-template>

							</div>
						</div>
					</div>

					<div class="col-6" *ngIf="showSelectedKeyLicNotesCol">
						Notes
						<div class="cp-property-value cp-inline-release-notes-long border p-2">
							{{ selectedKeyLicNotes.trim() }}
						</div>
					</div>

					<div class="col-6" *ngIf="selectedLicenseLoading">
						<app-loading-spinner></app-loading-spinner>
					</div>

					<div class="col-6" *ngIf="showSelectedKeyLicFeatures">
						<app-key-features #hostFeatures></app-key-features>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'hostid'" class="tab-pane" role="tabpanel" id="nav-versions" aria-labelledby="nav-versions-tab">
			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class='text-start border-start'>Earliest</th>
						<th class='text-start border-start'>Latest</th>
						<th class='text-start border-start'>Version</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let vh of versionHistory">
						<td class="text-start border-start text-nowrap">
							{{ vh.first_reported | date: appConstants.pageDateFmt:'UTC'}}
						</td>
						<td class="text-start border-start text-nowrap">
							{{ vh.last_reported | date: appConstants.pageDateFmt:'UTC'}}
						</td>
						<td class="text-start border-start text-nowrap">
							{{ textTools.cleanVersion(vh.version) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="pageMode === 'hostid'" class="tab-pane" role="tabpanel" id="nav-host-labels" aria-labelledby="nav-host-labels-tab">

			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class='text-start border-start'>User</th>
						<th class='text-start border-start'>Label</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let label of labels">
						<td class="text-nowrap border-start">
							<a [routerLink]="['/' + appConstants.urls.users, label.user_id]">
								{{ getUsersName(label.user_id) }}</a>
						</td>
						<td class="text-start border-start">
							{{ label.label }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- aggregate tab panels -->

		<div *ngIf="pageMode === 'aggregate'" class="tab-pane show active" role="tabpanel" id="nav-aggregate-items"
			aria-labelledby="nav-aggregate-items-tab">

			<form *ngIf="aggregateForm" [formGroup]="aggregateForm" (ngSubmit)="processKeysAndHostIds()">
				<div class="cp-general-width-limit">
					<div class="form-group">
						<label for="keysAndHostIds">License Keys and Host IDs</label>
						<textarea id="keysAndHostIds" formControlName="keysAndHostIds" class="form-control" rows="8"></textarea>
					</div>

					<div class="form-group">
						Enter/paste (line separated) either: license keys and/or license keys and host IDs separated by
						a colon (:)
					</div>

					<div *ngFor="let resolveProblem of resolveProblems" class="cp-form-error">
						{{ resolveProblem }}
					</div>

					<div class="form-group mb-0" *ngIf="!resolving">
						<button class="btn btn-primary me-1" type="submit" [disabled]="!aggregateForm.valid">
							Get Usage Info
						</button>
					</div>
				</div>
			</form>
		</div>

		<div *ngIf="pageMode === 'aggregate'" class="tab-pane" role="tabpanel" id="nav-item-details" aria-labelledby="nav-item-details-tab">

			<div>
				<form *ngIf="aggregateSelectionForm" [formGroup]="aggregateSelectionForm" (ngSubmit)="updateKeysAndHostIds()">

					<button *ngIf="!loading && !resolving" class="btn btn-primary mb-1" type="submit">
						Update Usage Info
					</button>

					<table *ngIf="activationIdAndHostIdsCombos && activationIdAndHostIdsCombos.length > 0"
						class="cp-std-table cp-auto-width">
						<thead>
							<tr>
								<th class="border-start">
									<input type="checkbox" formControlName="select_all" id="select_all" value="yes"
										(change)="checkAllToggle()">
								</th>
								<th class="border-start">Organization</th>
								<th class="border-start">{{ appConstants.keyInfoFieldLabel }}</th>
								<th class="border-start text-center" title="Key Product">P</th>
								<th class="border-start text-center" title="Key Type">T</th>
								<th class="border-start">Key</th>
								<th class="border-start">Host ID</th>
							</tr>
						</thead>

						<tbody>
							<tr *ngFor="let activationIdAndHostIdsCombo of activationIdAndHostIdsCombos; let i = index">
								<td class="border-start">
									<input type="checkbox" [formControlName]="'select_' + i" [id]="'select_' + i" value="yes">
								</td>
								<td class="border-start">
									<label [for]="'select_' + i">
										{{
										getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'],
										'organization')
										}}
									</label>
								</td>
								<td class="border-start">
									{{ getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'info') }}
								</td>
								<td class="border-start text-nowrap">
									{{ getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'product')
									}}
								</td>
								<td class="border-start text-nowrap">
									{{ getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'type') }}
								</td>
								<td class="border-start text-nowrap">
									<a [routerLink]="['/' + appConstants.urls.licensing, 'activation', activationIdAndHostIdsCombo['activation_id']]"
										[ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'key')
										}}
									</a>
								</td>
								<td class="border-start text-nowrap">
									{{ activationIdAndHostIdsCombo['hostid'] }}
								</td>

								<ng-template #popupContent>
									<div class="cp-popover-banner">{{
										getFieldFromResolveData(activationIdAndHostIdsCombo['activation_id'], 'key') }}
									</div>

									<div class="cp-pre-text">
										{{
										popOverTools.getKeyPopoverLines(getActivationFromResolveData(+activationIdAndHostIdsCombo['activation_id']),
										keyProducts, allOrgs, staffUsers, false).join('\n') }}
									</div>
								</ng-template>
							</tr>
						</tbody>
					</table>
				</form>
			</div>

		</div>

		<div *ngIf="['activation', 'hostid'].includes(pageMode)" class="tab-pane" role="tabpanel" id="nav-bc-summary"
			aria-labelledby="nav-bc-summary-tab">
			<div *ngIf="invalidBillingCodeEntries.length > 0" class="mb-2">
				Invalid Billing Code Use (authentication code is incorrect)

				<table class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th class='text-start border-start'>Host ID</th>
							<th class='text-start border-start'>Billing Code</th>
							<th class='text-start border-start'>
								Total Traffic
								<span *ngIf="showRawData">(MB)</span>
							</th>
							<th class='text-start border-start'>Earliest Invalid Use</th>
							<th class='text-start border-start'>Latest Invalid Use</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of invalidBillingCodeEntries">
							<td class="text-start border-start text-nowrap cp-key">
								<a *ngIf="hostid !== item.hostid"
									[routerLink]="['/' + appConstants.urls.licensing, 'hostid', item.hostid, this.id]">
									{{ item.hostid }}
								</a>
								<span *ngIf="hostid === item.hostid">
									{{ item.hostid }}
								</span>
							</td>
							<td class="text-start border-start text-nowrap" [ngbPopover]="bcPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ item.billing_code }}
							</td>
							<td class="text-end border-start text-nowrap">
								{{ showDataValue(item.total_used) }}
							</td>
							<td class="text-start border-start text-nowrap">
								{{ item.min_day | date: appConstants.pageDateFmt:'UTC'}}
							</td>
							<td class="text-start border-start text-nowrap"
								[ngClass]="miscTools.daysSince(item.max_day) < 2 ?'text-danger':''">
								{{ item.max_day | date: appConstants.pageDateFmt:'UTC'}}
								<fa-icon *ngIf="item['_active']" [icon]="['fas', 'bolt']"
									ngbTooltip="This Host ID appears still be using this billing code with an invalid auth code"></fa-icon>
							</td>
							<ng-template #bcPopupContent>
								<div class="cp-popover-banner">{{ item.billing_code }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBillingCodePopoverLines(billingCodesByCode[item.billing_code]).join('\n')}}
								</div>
							</ng-template>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="bcSummaryByHostId.length > 0" class="mb-2">
				Breakdown of Billing Code Use by Host ID
				<table class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th class='text-start border-start'>Host ID</th>
							<th class='text-start border-start'>Billing Code</th>
							<th class='text-start border-start'>
								Total Traffic
								<span *ngIf="showRawData">(MB)</span>
							</th>
							<th class='text-start border-start'>Earliest</th>
							<th class='text-start border-start'>Latest</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let bcSummary of bcSummaryByHostId">
							<td class="text-start border-start text-nowrap cp-key">
								<a [routerLink]="['/' + appConstants.urls.licensing, 'hostid', bcSummary.hostid, this.id]">
									{{ bcSummary.hostid }}</a>
							</td>
							<td class="text-start border-start text-nowrap" [ngbPopover]="bcPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ bcSummary.billing_code }}
							</td>
							<td class="text-end border-start text-nowrap">
								{{ showDataValue(bcSummary.total) }}
							</td>
							<td class="text-start border-start text-nowrap">
								{{ bcSummary.earliest | date: appConstants.pageDateFmt:'UTC'}}
							</td>
							<td class="text-start border-start text-nowrap">
								{{ bcSummary.latest | date: appConstants.pageDateFmt:'UTC'}}
							</td>
							<ng-template #bcPopupContent>
								<div class="cp-popover-banner">{{ bcSummary.billing_code }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBillingCodePopoverLines(billingCodesByCode[bcSummary.billing_code]).join('\n')}}
								</div>
							</ng-template>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!-- stuff after this point should be identical between the staff and non-staff versions of this page -->
		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-meters" aria-labelledby="nav-meters-tab">

			<app-key-meters [showRawData]="showRawData"></app-key-meters>

		</div>

		<div class="tab-pane" role="tabpanel" id="nav-meter-traffic" aria-labelledby="nav-meter-traffic-tab">
			<div *ngIf="meterProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<div>
						<button *ngIf="showMeterTraffic" type="button" class="btn btn-outline-secondary btn-sm me-1"
							(click)="openUsageReport('meter-data')" placement="bottom-start"
							ngbTooltip="Get a spreadsheet with all monthly and daily meter usage data for this host id">
							<fa-icon icon="file-excel" size="sm"></fa-icon>
							Detailed Meter Traffic Report
						</button>

						<div class="btn-group me-1" *ngIf="chunkedMeterUsage.length > 0">
							<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
								[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
								(click)="changeTimeMode(mode.value)">
								View by {{ mode.label }}
							</button>
						</div>

						<button *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv" type="button"
							class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowPhantom()">
							<fa-icon icon="minus" size="sm"></fa-icon>
							Hide Meter/Protocol Comparison
						</button>

						<button *ngIf="canShowMeterProtocolEquiv && !showMeterProtocolEquiv" type="button"
							class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowPhantom()">
							<fa-icon icon="plus" size="sm"></fa-icon>
							Show Meter/Protocol Comparison
						</button>
					</div>
					<div *ngIf="timeMode === 'month'" class="fw-bold mt-1">
						Click on a month's usage to get a day by day breakdown.
					</div>
				</div>

				<div class="row justify-content-start flex-nowrap">
					<div class="col-auto overflow-auto" [ngClass]="{ 'cp-75p-max-width': (byDayMeterLoading || byDayMeterProduct !== '') }">

						<div class="row justify-content-start">
							<div *ngFor="let product of meterProductsToShow" class="col-auto">
								<div class="fw-bold">
									{{ appConstants.meterTypeObjects[product].label }}
									<fa-icon [icon]="['fas', 'info-circle']"
										[ngbTooltip]="appConstants.meterTypeObjects[product].description" placement="bottom">
									</fa-icon>

									<div *ngIf="meterLabelsByType[product] && meterLabelsByType[product].length === 1"
										style="max-width:200px">
										{{ meterLabelsByType[product].join(', ') }}
									</div>
									<div *ngIf="meterLabelsByType[product] && meterLabelsByType[product].length > 1"
										style="max-width:200px">
										Multiple Labels
										<fa-icon [icon]="['fas', 'info-circle']" [ngbTooltip]="meterLabelsByType[product].join(', ')"
											placement="bottom">
										</fa-icon>
									</div>

									<div *ngIf="anyMeterLabels && (!meterLabelsByType[product] || meterLabelsByType[product].length === 0)"
										style="max-width:200px" class="font-italic">
										No Label Set
									</div>

								</div>
								<table class="cp-std-table cp-auto-width">
									<thead>
										<tr>
											<th class='cp-usage-center'>{{ timeModeLabel }}</th>
											<th class='cp-usage-center'>
												Used
												<span *ngIf="showRawData">(MB)</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Equivalent Amount Using Protocol Usage">
													P.E.
												</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Difference Between Meter Usage and Equivalent Protocol Usage">
													P.E. &Delta;
												</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Difference Between Meter Usage and Equivalent Protocol Usage">
													P.E. &Delta; %
												</span>
											</th>
											<th *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class='cp-usage-center'>
												<span
													ngbTooltip="Projected Amount of Traffic for this Month - based on monthly reset meter(s)">
													Projected
													<span *ngIf="showRawData">(MB)</span>
												</span>
											</th>
											<th *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Amount of traffic over projected amount for the month">
													Over
													<span *ngIf="showRawData">(MB)</span>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let record of pullUsageForMeterProduct(product)">
											<td class="cp-usage-left">
												{{ makeTimeChunkHeader(record.month, record.year, false) }}
											</td>
											<td class="cp-usage-right cp-pointer"
												(click)="selectMeterDayToFocus(product, record.year, record.month)">
												<span [ngbTooltip]="makeDataTip(record.used, true, true)" ngxClipboard
													[cbContent]="record.used">
													{{ showDataValue(record.used) }}
												</span>
											</td>
											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span ngxClipboard
													[cbContent]="getUsageForProtocolForTimeChunk(getPhantomProduct(product), record.year, record.month)">
													{{
													showDataValue(getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year,
													record.month)) }}
												</span>
											</td>

											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span>
													{{ showDataValue(record.used -
													getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year, record.month)) }}
												</span>
											</td>
											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span>
													{{ phantomPercent(record.used,
													getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year, record.month)) }}
												</span>
											</td>

											<td *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class="cp-usage-right">
												<span [ngbTooltip]="makeDataTip(workoutMonthsProjected(record), true, false)" ngxClipboard
													[cbContent]="workoutMonthsProjected(record)">
													{{ showDataValue(workoutMonthsProjected(record)) }}
												</span>
											</td>
											<td *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class="cp-usage-right">
												<span [ngbTooltip]="makeDataTip(workoutMonthsOverage(record), true, false)" ngxClipboard
													[cbContent]="workoutMonthsOverage(record)">
													{{ showDataValue(workoutMonthsOverage(record)) }}
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="col-auto overflow-auto">
						<div *ngIf="!byDayMeterLoading && byDayMeterProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayMeterProduct, true) }}
							{{ textTools.getMonthName(byDayMeterMonth, true) }} {{ byDayMeterYear }}
						</div>

						<!-- <button *ngIf="!byDayMeterLoading && byDayMeterProduct !== ''" type="button"
								class="btn btn-outline-secondary btn-sm me-2 mb-1" (click)="toggleShowNoUseDays()">
								{{ showNoUseDays ? 'Hide Days With No Usage' : 'Show Days With No Usage' }}
							</button> -->

						<app-loading-spinner *ngIf="byDayMeterLoading"></app-loading-spinner>

						<table *ngIf="byDayMeterUsage.length > 0" class="cp-std-table cp-auto-width">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
										<span *ngIf="showRawData">(MB)</span>
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
									<th class="cp-usage-center">
										Avg.<br />Rate<br />Mbits/s
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayMeterUsage; let i = index">
									<td class="border text-center text-nowrap">{{ dayLabel(record) }}
									</td>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showDataValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" class="cp-usage-right">-</td>
									<td *ngIf="i !== 0" class="cp-usage-right">
										{{ getDayToDayRatio('meter-data', i) | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(record.used) | number:rateNumFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayMeterUsage.length > 1">
									<th class="cp-usage-left">Total</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayMeterTotal)" ngxClipboard [cbContent]="byDayMeterTotal">
											{{ showDataValue(byDayMeterTotal) }}
										</span>
									</td>
									<td class="cp-usage-right"></td>
									<td class="cp-usage-right"></td>
								</tr>

								<tr *ngIf="byDayMeterUsage.length > 1">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayMeterTotal / byDayMeterUsage.length, false)">
											{{ showDataValue(byDayMeterTotal / byDayMeterUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayMeterAvgAbsChg | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(byDayMeterTotal / byDayMeterUsage.length) | number:rateNumFmt
										}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-protocol-traffic" aria-labelledby="nav-protocol-traffic-tab">
			<div *ngIf="protocolProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<div>
						<button *ngIf="showProtocolTraffic" type="button" class="btn btn-outline-secondary btn-sm me-1"
							(click)="openUsageReport('protocol-data')" placement="bottom-start"
							ngbTooltip="Get a spreadsheet with all monthly and daily protocol usage data for this host id">
							<fa-icon icon="file-excel" size="sm"></fa-icon>
							Detailed Protocol Traffic Report
						</button>

						<button type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleAxis()"
							ngbTooltip="Swap Axis/Rotate" placement="bottom-start">
							<fa-icon [icon]="['fas', 'ruler-triangle']" size="sm" [rotate]="rotateAxis ? 90 : 0">
							</fa-icon>
						</button>

						<div class="btn-group me-1">
							<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
								[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
								(click)="changeTimeMode(mode.value)">
								View by {{ mode.label }}
							</button>
						</div>

						<button *ngIf="showSetsButton" type="button" class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal"
							data-bs-target="#protocolSetsModal">
							<fa-icon [icon]="['fas', 'tasks']" size="sm">
							</fa-icon>
							Protocol Sets
						</button>

						<button *ngIf="usedProtocolBillingCodes && usedProtocolBillingCodes.length > 0" type="button"
							class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal"
							data-bs-target="#protocolBillingCodesModal">
							<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
							</fa-icon>
							Billing Codes
						</button>

						<button *ngIf="onlyShowTotals" type="button" class="btn btn-outline-secondary btn-sm me-1"
							(click)="toggleTotalsOnly()">
							<fa-icon [icon]="['fas', 'abacus']" size="sm"></fa-icon>
							Show All Protocols
						</button>

						<button *ngIf="!onlyShowTotals" type="button" class="btn btn-outline-secondary btn-sm me-1"
							(click)="toggleTotalsOnly()">
							<fa-icon [icon]="['fas', 'calculator']" size="sm"></fa-icon>
							Just Show Totals
						</button>

						<button
							*ngIf="timeMode === 'month' && !protocolShowAllMonths && protocolTimeChunks.length > appConstants.protocolTrafficMonthsToShow"
							type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
							<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
							Show All {{ protocolTimeChunks.length }} Months
						</button>

						<button
							*ngIf="timeMode === 'month' && protocolShowAllMonths && protocolTimeChunks.length > appConstants.protocolTrafficMonthsToShow"
							type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
							<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
							Just Show Last {{ appConstants.protocolTrafficMonthsToShow }} Months
						</button>
					</div>
				</div>

				<div class="row justify-content-start flex-nowrap g-1">
					<div class="col-auto overflow-auto"
						[ngClass]="{ 'cp-75p-max-width': (byDayProtocolLoading || byDayProtocolProduct !== '') }">
						<!-- if there are billing codes being used with this Bx -->
						<div *ngIf="usedProtocolBillingCodes && usedProtocolBillingCodes.length > 0 && activeProtocolBillingCode !== -1"
							class="my-3">
							<span class="cp-table-orange p-2">
								<fa-icon (click)="selectProtocolBillingCodeFilter(-2)" [icon]="['fas', 'times-circle']"
									ngbTooltip="Remove this filter">
								</fa-icon>
								Filter:
								<span class="fw-bold">
									{{ activeProtocolBillingCodeLabel }}
								</span>
							</span>
						</div>

						<div *ngIf="timeMode === 'month'" class="fw-bold">
							Click on a month's usage to get a day by day breakdown.
							<!-- <hr />
							Traffic information is for the last {{ appConstants.keyUsageMonthsBack }} months. -->
						</div>

						<table *ngIf="protocolProductsVisible.length > 0 && rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										Protocol
									</th>
									<th *ngFor="let ym of protocolTimeChunksToShow" class="border text-center cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, true) }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let product of protocolProductsVisible">
									<td [class]="productClass(product) + ' text-start text-nowrap fw-bold'"
										[ngbPopover]="protocolPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ niceProtocol(product, true) }}
										<span *ngIf="showRawData">(MB)</span>
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-data') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-data') }}
										</sup>
									</td>
									<td *ngFor="let ym of protocolTimeChunksToShow"
										[class]="productClass(product) + ' text-end text-nowrap'">
										<span *ngIf="product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)">
											{{ getUsageForProtocolForTimeChunk(product, ym.year, ym.month) }}%
										</span>
										<span *ngIf="!product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											(click)="selectProtocolDayToFocus(product, ym.year, ym.month)" ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>

									<ng-template #protocolPopupContent>
										<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
										<div class="cp-pre-text">
											{{ getProtocolPopoverLines(product, 'protocol-data').join('\n') }}
										</div>
									</ng-template>
								</tr>
							</tbody>
						</table>

						<table *ngIf="protocolProductsVisible.length > 0 && !rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										{{ timeModeLabel }}
									</th>
									<th *ngFor="let product of protocolProductsVisible"
										[class]="productClass(product) + ' text-center cp-pre-text'" [ngbPopover]="protocolPopupContent"
										popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
										{{ niceProtocol(product, true) }}
										<span *ngIf="showRawData">(MB)</span>
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-data') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-data') }}
										</sup>

										<ng-template #protocolPopupContent>
											<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
											<div class="cp-pre-text">
												{{ getProtocolPopoverLines(product, 'protocol-data').join('\n')
												}}
											</div>
										</ng-template>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let ym of protocolTimeChunksToShow">
									<td class="cp-usage-left fw-bold cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, false) }}
									</td>
									<td *ngFor="let product of protocolProductsVisible"
										[class]="productClass(product) + ' text-end text-nowrap'">
										<span *ngIf="product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)">
											{{ getUsageForProtocolForTimeChunk(product, ym.year, ym.month) }}%
										</span>
										<span *ngIf="!product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											(click)="selectProtocolDayToFocus(product, ym.year, ym.month)" ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-auto">
						<app-loading-spinner *ngIf="byDayProtocolLoading"></app-loading-spinner>

						<div *ngIf="!byDayProtocolLoading && byDayProtocolProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayProtocolProduct, true) }} -
							{{ textTools.getMonthName(byDayProtocolMonth, true) }} {{ byDayProtocolYear }}
							<div *ngIf="activeProtocolBillingCodeShortLabel !== ''">
								{{ activeProtocolBillingCodeShortLabel }}
							</div>
						</div>

						<table *ngIf="!byDayProtocolLoading && byDayProtocolUsage.length > 0" class="table table-sm table-bordered">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
										<span *ngIf="showRawData">(MB)</span>
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
									<th class="cp-usage-center">
										Avg.<br />Rate<br />Mbits/s
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayProtocolUsage; let i = index">
									<td [class]="productClass(byDayProtocolProduct) + ' text-center text-nowrap'">
										{{ dayLabel(record) }}
									</td>
									<td [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										<span [ngbTooltip]="makeDataTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showDataValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">-
									</td>
									<td *ngIf="i !== 0" [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										{{ getDayToDayRatio('protocol-data', i) | percent:percFmt }}
									</td>
									<td [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										{{ dailyRate(record.used) | number:rateNumFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayProtocolUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">
										Total
									</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayProtocolTotal)" ngxClipboard [cbContent]="byDayProtocolTotal">
											{{ showDataValue(byDayProtocolTotal) }}
										</span>
									</td>
									<td class="cp-usage-left"></td>
									<td class="cp-usage-left"></td>
								</tr>

								<tr *ngIf="byDayProtocolUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayProtocolTotal/byDayProtocolUsage.length, false)">
											{{ showDataValue(byDayProtocolTotal/byDayProtocolUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayProtocolAvgAbsChg | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(byDayProtocolTotal / byDayProtocolUsage.length) |
										number:rateNumFmt }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-time-traffic" aria-labelledby="nav-time-traffic-tab">
			<div *ngIf="chronoProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<div>
						<button *ngIf="showChronoTraffic" type="button" class="btn btn-outline-secondary btn-sm me-1"
							(click)="openUsageReport('protocol-time')" placement="bottom-start"
							ngbTooltip="Get a spreadsheet with all monthly and daily time-based protocol usage data for this host id">
							<fa-icon icon="file-excel" size="sm"></fa-icon>
							Detailed Time-based Traffic Report
						</button>

						<button type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleAxis()"
							ngbTooltip="Swap Axis/Rotate" placement="bottom-start">
							<fa-icon [icon]="['fas', 'ruler-triangle']" size="sm" [rotate]="rotateAxis ? 90 : 0">
							</fa-icon>
						</button>

						<div class="btn-group me-1">
							<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
								[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
								(click)="changeTimeMode(mode.value)">
								View by {{ mode.label }}
							</button>
						</div>

						<!-- <button *ngIf="showSetsButton" type="button" class="btn btn-outline-secondary btn-sm me-1"
							data-bs-toggle="modal" data-bs-target="#chronoSetsModal">
							<fa-icon [icon]="['fas', 'tasks']" size="sm">
							</fa-icon>
							Protocol Sets
						</button> -->

						<button *ngIf="usedChronoBillingCodes && usedChronoBillingCodes.length > 0" type="button"
							class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#chronoBillingCodesModal">
							<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
							</fa-icon>
							Billing Codes
						</button>

						<button
							*ngIf="timeMode === 'month' && !protocolShowAllMonths && chronoTimeChunks.length > appConstants.protocolTrafficMonthsToShow"
							type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
							<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
							Show All {{ chronoTimeChunks.length }} Months
						</button>

						<button
							*ngIf="timeMode === 'month' && protocolShowAllMonths && chronoTimeChunks.length > appConstants.protocolTrafficMonthsToShow"
							type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
							<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
							Just Show Last {{ appConstants.protocolTrafficMonthsToShow }} Months
						</button>
					</div>
				</div>

				<div class="row justify-content-start flex-nowrap">
					<div class="col-auto overflow-auto"
						[ngClass]="{ 'cp-75p-max-width': (byDayProtocolLoading || byDayProtocolProduct !== '') }">
						<!-- if there are billing codes being used with this Bx -->
						<div *ngIf="usedChronoBillingCodes && usedChronoBillingCodes.length > 0 && activeChronoBillingCode !== -1"
							class="my-3">
							<span class="cp-table-orange p-2">
								<fa-icon (click)="selectChronoBillingCodeFilter(-2)" [icon]="['fas', 'times-circle']"
									ngbTooltip="Remove this filter">
								</fa-icon>
								Filter:
								<span class="fw-bold">
									{{ activeChronoBillingCodeLabel }}
								</span>
							</span>
						</div>

						<div *ngIf="timeMode === 'month'" class="fw-bold">
							Click on a month's usage to get a day by day breakdown.
							<!-- <hr />
							Traffic information is for the last {{ appConstants.keyUsageMonthsBack }} months. -->
						</div>

						<table *ngIf="chronoProductsToShow.length > 0 && rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										Protocol
									</th>
									<th *ngFor="let ym of chronoTimeChunksToShow" class="border text-center cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, true) }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let product of chronoProductsToShow">
									<td [class]="productClass(product) + ' text-start text-nowrap fw-bold'"
										[ngbPopover]="protocolPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ niceProtocol(product, true) }}
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-time') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-time') }}
										</sup>
									</td>
									<td *ngFor="let ym of chronoTimeChunksToShow" [class]="productClass(product) + ' text-end text-nowrap'"
										(click)="selectChronoDayToFocus(product, ym.year, ym.month)">
										<span
											[ngbTooltip]="makeExpChronoTip(product, ym, getUsageForChronoForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard [cbContent]="getUsageForChronoForTimeChunk(product, ym.year, ym.month)">
											{{ showChronoValue(getUsageForChronoForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>
									<ng-template #protocolPopupContent>
										<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
										<div class="cp-pre-text">
											{{ getProtocolPopoverLines(product, 'protocol-time').join('\n') }}
										</div>
									</ng-template>
								</tr>
							</tbody>
						</table>

						<table *ngIf="chronoProductsToShow.length > 0 && !rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										{{ timeModeLabel }}
									</th>
									<th *ngFor="let product of chronoProductsToShow"
										[class]="productClass(product) + ' text-center cp-pre-text'" [ngbPopover]="protocolPopupContent"
										popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
										{{ niceProtocol(product, true) }}
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-time') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-time') }}
										</sup>
										<ng-template #protocolPopupContent>
											<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
											<div class="cp-pre-text">
												{{ getProtocolPopoverLines(product, 'protocol-data').join('\n')
												}}
											</div>
										</ng-template>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let ym of chronoTimeChunksToShow">
									<td class="cp-usage-left fw-bold cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, false) }}
									</td>
									<td *ngFor="let product of chronoProductsToShow"
										[class]="productClass(product) + ' text-end text-nowrap'"
										(click)="selectChronoDayToFocus(product, ym.year, ym.month)">
										<span
											[ngbTooltip]="makeExpChronoTip(product, ym, getUsageForChronoForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard [cbContent]="getUsageForChronoForTimeChunk(product, ym.year, ym.month)">
											{{ showChronoValue(getUsageForChronoForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-auto">

						<app-loading-spinner *ngIf="byDayChronoLoading"></app-loading-spinner>

						<div *ngIf="!byDayChronoLoading && byDayChronoProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayChronoProduct, true) }} -
							{{ textTools.getMonthName(byDayChronoMonth, true) }} {{ byDayChronoYear }}
							<div *ngIf="activeChronoBillingCodeShortLabel !== ''">
								{{ activeChronoBillingCodeShortLabel }}
							</div>
						</div>

						<table *ngIf="!byDayChronoLoading && byDayChronoUsage.length > 0" class="table table-sm table-bordered">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayChronoUsage; let i = index">
									<td [class]="productClass(byDayChronoProduct) + ' text-center text-nowrap'">
										{{ dayLabel(record) }}
									</td>
									<td [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">
										<span [ngbTooltip]="makeChronoTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showChronoValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">-
									</td>
									<td *ngIf="i !== 0" [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">
										{{ getDayToDayRatio('protocol-time', i) | percent:percFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayChronoUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">
										Total
									</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeChronoTip(byDayChronoTotal)" ngxClipboard [cbContent]="byDayChronoTotal">
											{{ showChronoValue(byDayChronoTotal) }}
										</span>
									</td>
									<td class="cp-usage-left"></td>
								</tr>

								<tr *ngIf="byDayChronoUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeChronoTip(byDayChronoTotal/byDayChronoUsage.length, false)">
											{{ showChronoValue(byDayChronoTotal/byDayChronoUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayChronoAvgAbsChg | percent:percFmt }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div> <!-- tabset -->
</div>

<div class="modal" id="disableModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Disable Key</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDisableModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="disableForm" [formGroup]="disableForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you disable this Key, new activations will not be possible and systems already activated and
						running may be affected.
						<br /><br />

						If you are sure, type <span class="fw-bold">disable</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDisableMsg" class="gj-warning-message">
						You must type <span class="fw-bold">disable</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'disable' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canManageKey" type="button" [disabled]="loading" (click)="disableKey()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Disable this Key
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="protocolSetsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Protocol Sets (Data Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeProtocolSetsModalButton">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleSetsPosition()">
						<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
						Place Protocol Sets {{ protocolSetsPosition === 'bottom' ? 'Before' : 'After' }} Other Data
					</button>
				</div>

				<div *ngIf="supportsLinkedSet && (canLinkProtocolSets || linkedProtocolSets.length > 0)"
					class="mt-2 border-top text-center">
					<span class="fw-bold">Linked Sets</span>
					<button *ngIf="canLinkProtocolSets && linkedProtocolSets.length > 0" type="button"
						class="btn btn-outline-danger btn-sm mb-1 mt-1 ms-1" (click)="wipeLockedSets('protocol-data')" [disabled]="loading">
						<fa-icon [icon]="['fas', 'unlink']" size="sm"></fa-icon>
						Unlink Sets from this Key
					</button>

					<div *ngIf="linkedProtocolSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="linkedProtocolSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of linkedProtocolSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-secondary btn-sm w-100" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet" class="mt-2 border-top text-center">
					<span class="fw-bold">Session Sets</span>
					<button *ngIf="supportsLinkedSet && canLinkProtocolSets && sessionProtocolSets.length > 0" type="button"
						class="btn btn-outline-success btn-sm mb-1 mt-1 ms-1" (click)="pushToLockedSets('protocol-data')"
						[disabled]="loading">
						<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
						Link Session Sets to this Key
					</button>

					<div *ngIf="sessionProtocolSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="sessionProtocolSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of sessionProtocolSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-danger btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-data')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'minus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet && filterOutSelected(selectedProtocolSets).length > 0" class="mt-2 border-top text-center">
					<span class="fw-bold">Available Sets</span>
					<div class="row">
						<div *ngFor="let protocolSet of filterOutSelected(selectedProtocolSets)" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-success btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-data')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="chronoSetsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Protocol Sets (Time Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeChronoSetsModalButton">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleSetsPosition()">
						<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
						Place Protocol Sets {{ protocolSetsPosition === 'bottom' ? 'Before' : 'After' }} Other Data
					</button>
				</div>

				<div *ngIf="supportsLinkedSet && (canLinkProtocolSets || linkedProtocolSets.length > 0)"
					class="mt-2 border-top text-center">
					<span class="fw-bold">Linked Sets</span>
					<button *ngIf="canLinkProtocolSets && linkedChronoSets.length > 0" type="button"
						class="btn btn-outline-danger btn-sm mb-1 mt-1 ms-1" (click)="wipeLockedSets('protocol-time')" [disabled]="loading">
						<fa-icon [icon]="['fas', 'unlink']" size="sm"></fa-icon>
						Unlink Sets from this Key
					</button>

					<div *ngIf="linkedChronoSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="linkedChronoSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of linkedChronoSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-secondary btn-sm w-100" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet" class="mt-2 border-top text-center">
					<span class="fw-bold">Session Sets</span>
					<button *ngIf="supportsLinkedSet && canLinkProtocolSets && sessionChronoSets.length > 0" type="button"
						class="btn btn-outline-success btn-sm mb-1 mt-1 ms-1" (click)="pushToLockedSets('protocol-time')"
						[disabled]="loading">
						<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
						Link Session Sets to this Key
					</button>

					<div *ngIf="sessionChronoSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="sessionChronoSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of sessionChronoSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-danger btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-time')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'minus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet && filterOutSelected(selectedChronoSets).length > 0" class="mt-2 border-top text-center">
					<span class="fw-bold">Available Sets</span>
					<div class="row">
						<div *ngFor="let protocolSet of filterOutSelected(selectedChronoSets)" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-success btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-time')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div>
							</ng-template>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="usageSummaryModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Usage Summary Report by Host ID</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeUsageSummaryModalButton">

				</button>
			</div>

			<div class="modal-body">

				<form *ngIf="usageSummaryForm" [formGroup]="usageSummaryForm" autocomplete="off">

					<div class="form-group">
						<label for="dataTypes">Report Data Types</label>
						<ng-select [items]="usageSummaryDataTypeSelections" bindValue="value" bindLabel="label" id="dataTypes"
							formControlName="dataTypes" [multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="text-center mt-2">
						<button type="button" (click)="runKeyUsageSummaryReport()" class="btn btn-sm btn-outline-secondary"
							[disabled]="!usageSummaryForm.valid">
							<fa-icon icon="file-excel" size="sm"></fa-icon>
							Generate Report
						</button>
					</div>

					<div class="cp-form-padding">
						The report will include one tab/sheet for each data type. Each data type's tab/sheet will have a
						column for each month that data was reported and a row with the amount for that month for each
						activated host ID and a row for the key's overal usage.
					</div>
				</form>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="linkOfflineModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Link Offline License to Offline Key</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeLinkOfflineModal">

				</button>
			</div>

			<div class="modal-body">

				<form *ngIf="linkOfflineForm" [formGroup]="linkOfflineForm" autocomplete="off">

					<div class="form-group">
						<label for="linkKey">Key</label>
						<input type="text" id="linkKey" formControlName="linkKey" class="form-control" />
					</div>

					<div class="text-center mt-2">
						<button type="button" (click)="linkOfflineLicenseToKey()" class="btn btn-sm btn-outline-secondary"
							[disabled]="!linkOfflineForm.valid">
							<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
							Link License to Key
						</button>
					</div>
				</form>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="manualMarketplaceReportModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Manually Submit Marketplace Report</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeManualMarketplaceReportModal">

				</button>
			</div>

			<div class="modal-body">

				<form *ngIf="manualMarketplaceForm" [formGroup]="manualMarketplaceForm" autocomplete="off">
					<div class="form-group">
						<label for="amountGB">Amount to Report (GB)</label>
						<input type="text" id="amountGB" formControlName="amountGB" class="form-control" />
					</div>

					<div class="form-group">
						<label for="reportStart">Start of Report (yyyy/mm/dd hh:mm UTC)</label>
						<input type="text" id="reportStart" formControlName="reportStart" class="form-control" />
					</div>

					<div class="form-group">
						<label for="reportEnd">End of Report (yyyy/mm/dd hh:mm UTC)</label>
						<input type="text" id="reportEnd" formControlName="reportEnd" class="form-control" />
					</div>

					<div class="text-center mt-2">
						<button type="button" (click)="manualMarketplaceKeyReport()" class="btn btn-sm btn-outline-secondary"
							[disabled]="!manualMarketplaceForm.valid">
							<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
							Report Usage to Marketplace
						</button>
					</div>
				</form>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="protocolBillingCodesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Billing Codes (Data Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeProtocolBillingCodesModal">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleBillingCodeDisplay()">
						Toggle Showing Code vs. Label
					</button>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeProtocolBillingCode === -1}">
					<button *ngIf="activeProtocolBillingCode === -1" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== -1" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(-1)">
						Remove Filter
					</button>
					<span class="fw-bold">All usage data</span>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeProtocolBillingCode === 0}">
					<button *ngIf="activeProtocolBillingCode === 0" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(0)">
						Filter
					</button>
					<span class="fw-bold">Usage data with no billing code set</span>
				</div>

				<div *ngFor="let bc of usedProtocolBillingCodes" class="mt-1 mb-1 pt-1 pb-1 border-top"
					[ngClass]="{'table-primary text-white': activeProtocolBillingCode === bc.id}">
					<button *ngIf="activeProtocolBillingCode === bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(bc.id)">
						Filter
					</button>
					<span class="fw-bold">{{ makeBillingCodeLabel(bc) }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="chronoBillingCodesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Billing Codes (Time Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeChronoBillingCodesModal">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleBillingCodeDisplay()">
						Toggle Showing Code vs. Label
					</button>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeChronoBillingCode === -1}">
					<button *ngIf="activeChronoBillingCode === -1" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== -1" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(-1)">
						Remove Filter
					</button>
					<span class="fw-bold">All usage data</span>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeChronoBillingCode === 0}">
					<button *ngIf="activeChronoBillingCode === 0" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(0)">
						Filter
					</button>
					<span class="fw-bold">Usage data with no billing code set</span>
				</div>

				<div *ngFor="let bc of usedChronoBillingCodes" class="mt-1 mb-1 pt-1 pb-1 border-top"
					[ngClass]="{'table-primary text-white': activeChronoBillingCode === bc.id}">
					<button *ngIf="activeChronoBillingCode === bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(bc.id)">
						Filter
					</button>
					<span class="fw-bold">{{ makeBillingCodeLabel(bc) }}</span>
				</div>
			</div>
		</div>
	</div>
</div>