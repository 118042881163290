<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.mypackages" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Packages
				<app-loading-spinner *ngIf="loading || subscribing"></app-loading-spinner>
			</li>
		</ol>
	</nav>
</div>

<div class="page-wrapper">
	<div class="row">
		<div *ngIf="availablePackages.length !== 0" class="col-auto">
			<div class="cp-light-message">

				<div *ngIf="canAccessAllFiles" class="mb-1 pb-2 border-bottom">
					<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
					Since you're a staff member and your account has access to all Builds and
					Documents, there's likely no need for you to subscribe to any packages.
				</div>

				<div class="mb-2 clearfix">
					<button type="button" class="btn btn-outline-info btn-sm float-end" [disabled]="subscribing"
						(click)="subscribeToAllPackages()" ngbTooltip="Subscribe to all packages" placement="end">
						<fa-icon [icon]="['fas', 'plus']" size="sm" [spin]="subscribing"></fa-icon>
						Subscribe to All
					</button>

					You can subscribe to the following packages <fa-icon [icon]="['fas', 'question-circle']"
						[ngbTooltip]="info"></fa-icon>
				</div>

				<div *ngFor="let pkg of availablePackages" class='mt-2 pt-2 border-top'>
					<button type="button" class="btn btn-outline-info btn-sm me-1" [disabled]="subscribing"
						(click)="subscribeToPackage(pkg.id)" ngbTooltip="Subscribe to this package" placement="end">
						<fa-icon [icon]="['fas', 'plus']" size="sm" [spin]="subscribing"></fa-icon>
						Subscribe
					</button>
					<span class="fw-bold">
						{{ pkg.name }}
						<fa-icon *ngIf="pkg.key_templates && pkg.key_templates.length !== 0" [icon]="['fas', 'key']"
							size="sm" ngbTooltip="This Package lets you generate one or more types of license keys.">
						</fa-icon>
					</span>
					<span *ngIf="pkg.user_description && pkg.user_description !== ''" class="cp-pre-text">
						<br />{{ pkg.user_description }}
					</span>
				</div>
			</div>
		</div>

		<div *ngIf="myPackages.length !== 0" class="col-auto">
			<div class="cp-light-message">
				<div class="mb-2 clearfix">
					<button type="button" class="btn btn-outline-danger btn-sm float-end" [disabled]="subscribing"
						(click)="unsubscribeFromAllPackages()" ngbTooltip="Unsubscribe from all packages"
						placement="end">
						<fa-icon [icon]="['fas', 'minus']" size="sm" [spin]="subscribing"></fa-icon>
						Unsubscribe from All
					</button>

					You are subscribed to the following packages <fa-icon [icon]="['fas', 'question-circle']"
						[ngbTooltip]="info" placement="bottom"> </fa-icon>
				</div>

				<div *ngFor="let pkg of myPackages" class='mt-1 pt-1 border-top'>
					<button type="button" class="btn btn-outline-danger btn-sm me-1" [disabled]="subscribing"
						(click)="unsubscribeFromPackage(pkg.id)" ngbTooltip="Unsubscribe from this package"
						placement="end">
						<fa-icon [icon]="['fas', 'minus']" size="sm" [spin]="subscribing"></fa-icon>
						Unsubscribe
					</button>
					<span class="fw-bold">
						{{ pkg.name }}
					</span>
					<span *ngIf="pkg.key_templates && pkg.key_templates.length !== 0">
						<button type="button" class="btn btn-outline-info btn-sm me-1" [disabled]="subscribing"
							[routerLink]="['/' + appConstants.urls.mykeys]"
							ngbTooltip="This package lets you generate license keys" placement="end">
							<fa-icon [icon]="['fas', 'key']" size="sm"></fa-icon>
						</button>
					</span>

					<span *ngIf="pkg.user_description && pkg.user_description !== ''" class="cp-pre-text">
						<br />{{ pkg.user_description }}
					</span>
				</div>
			</div>
		</div>
	</div>
</div>