import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { DocumentsService } from '../documents.service';
import { ProductsService } from '../../products/products.service';
import { FilesService } from '../../files/files.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { PackagesService } from '../../packages/packages.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-document-form',
	templateUrl: './document-form.component.html',
	styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	document: Models.Document;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	availableFiles: Models.File[] = [];
	docFiles: Models.File[] = [];

	products: Models.Product[] = [];
	organizations: Models.Organization[] = [];
	groups: Models.OrganizationGroup[] = [];
	packages: Models.Package[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private filesService: FilesService,
		private organizationsService: OrganizationsService,
		private organizationGroupsService: OrganizationGroupsService,
		private packagesService: PackagesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.document = this.documentsService.getOne(this.id);
				if (!this.document || this.document == null || this.document.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.documentsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.document = new Models.Document(0, null, '', null, '', null, 1, 0, 0);
			}
		});
	}

	ngOnInit(): void {
		if (this.editMode) {
			this.listSubscription = this.documentsService.documents.subscribe(documents => {
				this.document = documents.find((document: Models.Document) => document.id === this.id);
				this.initForm();
			});
		} else {
			this.initForm();
		}
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	async initForm() {
		this.availableFiles = await this.filesService.fetchUnassignedFromDB();
		this.rebuildSubLists();

		this.organizations = this.organizationsService.getAll();
		this.groups = this.organizationGroupsService.getAll();
		this.packages = this.packagesService.getAll();

		const allProducts: Models.Product[] = this.productsService.getAll();
		this.products = [];
		for (const productType of AppConstants.productTypes)
			for (const product of allProducts)
				if (product.ptype === productType && ValidationTools.hasFlag(product, 'may_have_documents'))
					this.products.push(product);

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.document.is_enabled),
			is_private: new UntypedFormControl(this.document.is_private),
			can_open: new UntypedFormControl(this.document.can_open),
			product_id: new UntypedFormControl(this.document.product_id, [Validators.required]),
			label: new UntypedFormControl(this.document.label, [Validators.required]),
			link: new UntypedFormControl(this.document.link),
			file_id: new UntypedFormControl(this.document.file_id),
			org_ids: new UntypedFormControl(this.document.org_ids),
			group_ids: new UntypedFormControl(this.document.group_ids),
			package_ids: new UntypedFormControl(this.document.package_ids)
		});
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		if ((!this.theForm.value.link || this.theForm.value.link.trim() === '') && +this.theForm.value.file_id === 0)
			this.errors.push('You must provide a File or a Link.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		const preSaveOrgIds: number[] = this.document.org_ids.slice();
		const preSaveGroupIds: number[] = this.document.group_ids.slice();
		const preSavePkgIds: number[] = this.document.package_ids.slice();

		this.document.product_id = +this.theForm.value.product_id;
		this.document.is_enabled = +this.theForm.value.is_enabled;
		this.document.is_private = +this.theForm.value.is_private;
		this.document.can_open = +this.theForm.value.can_open;
		this.document.label = this.theForm.value.label;
		this.document.link = this.theForm.value.link;
		this.document.file_id = +this.theForm.value.file_id;
		this.document.org_ids = this.theForm.value.org_ids;
		this.document.group_ids = this.theForm.value.group_ids;
		this.document.package_ids = this.theForm.value.package_ids;

		try {
			let retDocument: Models.Document;
			if (this.editMode)
				retDocument = await this.documentsService.updateOne(this.document);
			else
				retDocument = await this.documentsService.addOne(this.document);

			if (retDocument) {
				const orgIDsToUpdate = [];
				// find orgs that have been added
				for (const orgID of this.document.org_ids)
					if (preSaveOrgIds.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				// find orgs that have been removed
				for (const orgID of preSaveOrgIds)
					if (this.document.org_ids.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				for (const orgID of orgIDsToUpdate)
					this.organizationsService.refreshOne(orgID);

				// ******************************************
				const groupIDsToUpdate = [];
				// find groups that have been added
				for (const groupID of this.document.group_ids)
					if (preSaveGroupIds.indexOf(groupID) === -1)
						groupIDsToUpdate.push(groupID);

				// find groups that have been removed
				for (const groupID of preSaveGroupIds)
					if (this.document.group_ids.indexOf(groupID) === -1)
						groupIDsToUpdate.push(groupID);

				for (const groupID of groupIDsToUpdate)
					this.organizationGroupsService.refreshOne(groupID);

				// ******************************************
				const pkgIDsToUpdate = [];
				// find pkgs that have been added
				for (const pkgID of this.document.package_ids)
					if (preSavePkgIds.indexOf(pkgID) === -1)
						pkgIDsToUpdate.push(pkgID);

				// find pkgs that have been removed
				for (const pkgID of preSavePkgIds)
					if (this.document.package_ids.indexOf(pkgID) === -1)
						pkgIDsToUpdate.push(pkgID);

				for (const pkgID of pkgIDsToUpdate)
					this.packagesService.refreshOne(pkgID);

				// ******************************************
				this.router.navigate([AppConstants.urls.documents + '/' + retDocument.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		if (this.editMode) {
			this.router.navigate(['..'], { relativeTo: this.route });
		} else {
			// this.router.navigate(['..'], {relativeTo: this.route});
			this.router.navigate([AppConstants.urls.documents]);
		}
	}

	onPrivateChange() {
		const isPrivate = +this.theForm.value.is_private;
		const orgIDs: number[] = this.theForm.value.org_ids;
		const groupIDs: number[] = this.theForm.value.group_ids;
		const packageIDs: number[] = this.theForm.value.package_ids;

		if (isPrivate === 0
			&& ((orgIDs && orgIDs.length > 0)
				|| (groupIDs && groupIDs.length > 0)
				|| (packageIDs && packageIDs.length > 0)))
			this.uiAlertsService.addMsg('If you are making this document public, do you still need to assign direct access?',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

	}

	async refreshFiles(justAvailable = true) {
		if (justAvailable)
			this.availableFiles = await this.filesService.fetchUnassignedFromDB();
		else
			this.filesService.refreshAll().subscribe(availableFiles => this.availableFiles = availableFiles);
		this.rebuildSubLists();
	}

	rebuildSubLists() {
		this.docFiles = [];
		for (const f of this.availableFiles) {
			const ext = TextTools.getExtension(f.name, true);
			if (AppConstants.docExtensions.indexOf(ext) !== -1) {
				this.docFiles.push(f);
			}
		}

		if (this.document.file_id && this.document.file_id !== 0) {
			const theFile = this.filesService.getOne(this.document.file_id);
			this.docFiles.push(theFile);
		}
	}

	get product_id() { return this.theForm.get('product_id'); }
	get label() { return this.theForm.get('label'); }

}
