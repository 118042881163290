import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

// import { ZenCustomersService } from '../../zen-customers/zen-customers.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { MarketplaceService } from '../marketplace.service';

import { MarketplaceLogsTableComponent } from 'client/app/components/shared/marketplace-logs-table/marketplace-logs-table.component';

@Component({
	selector: 'app-marketplace-logs',
	templateUrl: './marketplace-logs.component.html',
	styleUrls: ['./marketplace-logs.component.scss']
})
export class MarketplaceLogsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild(MarketplaceLogsTableComponent) marketPlaceLogsTable: MarketplaceLogsTableComponent = null;

	loading = true;
	refreshing = false;

	items: Models.MarketplaceUsageReport[] = [];
	itemsToShow: Models.MarketplaceUsageReport[] = [];

	// other stuff...
	maxMessage = '';
	showFilters = true;
	theForm: UntypedFormGroup;
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;

	constructor(
		private router: Router,
		private marketplaceService: MarketplaceService,
		private usersService: UsersService,
		private authService: AuthService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-marketplacelogs.settings'))
				settings = JSON.parse(localStorage.getItem('cp-marketplacelogs.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.notBefore != null || this.notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.initForm();
				this.loadLogs();
			} // if
		});

	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	initForm() {
		this.theForm = new UntypedFormGroup({
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-marketplacelogs.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	// ------------------------------------------------------------------------
	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		// this.logs = [];
		this.items = await this.marketplaceService.getMarketplaceLogs('', 0, startDate, endDate);
		if (this.items.length > AppConstants.maxLogsToFetch)
			this.items = this.items.slice(0, AppConstants.maxLogsToFetch);
		if (this.items && this.items.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		for (const item of this.items) {

		} // for

		this.loading = false;

		await MiscTools.delay(100);
		if (this.marketPlaceLogsTable)
			this.marketPlaceLogsTable.updateContent(this.items, 'cp-marketplace-logs', { showObject: true });

	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}
}
