import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { MyKeysService } from '../my-keys.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

@Component({
	selector: 'app-share-license-keys-form',
	templateUrl: './share-license-keys-form.component.html',
	styleUrls: ['./share-license-keys-form.component.scss']
})

export class ShareLicenseKeysFormComponent implements OnInit {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	maxKeysToAdd = 25;
	preSelectedKeyID = 0;

	user: Models.User;
	userKeys: Models.UserLicenseKey[] = [];
	// orgUsers: Models.User[] = [];
	userSelections: any[] = [];

	loading = true;
	saving = false;

	keyProducts: Models.LicenseProduct[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private licensingAdminService: LicensingAdminService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this.preSelectedKeyID = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	async initForm() {
		this.keyProducts = await this.licensingAdminService.getProducts();
		this.user = await this.mySettingsService.getUser();
		this.userKeys = await this.myKeysService.getUserKeys();
		const orgUsers: Models.User[] = await this.mySettingsService.getOrgUsers();

		this.userSelections = [];
		for (const orgUser of orgUsers) {
			if (orgUser.id !== this.user.id && orgUser.is_enabled === 1)
				this.userSelections.push({ id: orgUser.id, name: orgUser.name + ' (' + orgUser.email + ')' });
		}

		this.userKeys.sort((a, b) => (a.activation.product > b.activation.product || a.label.localeCompare(b.label) ? 1 : -1));

		this.theForm = new UntypedFormGroup({
			user_ids: new UntypedFormControl(null, [Validators.required]),
			notify: new UntypedFormControl(false)
		});

		for (const userKey of this.userKeys) {
			const checked = userKey.id === this.preSelectedKeyID;
			this.theForm.addControl('key_' + userKey.id, new UntypedFormControl(checked));
		}
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const notify = this.theForm.value.notify;
		const userIds = this.theForm.value.user_ids;
		const keyIds = [];
		for (const userKey of this.userKeys) {
			const val = this.theForm.value['key_' + userKey.id];
			if (val)
				keyIds.push(userKey.id);
		}

		if (userIds.length === 0)
			this.errors.push('You must choose at least one User to share with.');

		if (keyIds.length === 0)
			this.errors.push('You must choose at least one Key to share.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		if (this.errors.length === 0) {
			await this.myKeysService.shareKeysWithUsers(keyIds, userIds, notify);
			this.uiAlertsService.addMsg(keyIds.length + ' key(s) shared with ' + userIds.length + ' user(s).', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		this.saving = false;
		if (this.errors.length === 0) this.onCancel();
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	niceKeyProduct(product: string): string {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].basic_label;
	}

	get user_ids() { return this.theForm.get('user_ids'); }
}
