import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { PartnerAdminService } from '../partner-admin.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';

@Component({
	selector: 'app-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;

	loading = true;
	authUser: Models.AuthUser;

	// 'standard' view stuff
	id: number;
	partnership: Models.Partnership;
	canManage = true;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;
	adminLogs: Models.AdminLog[] = [];

	canAddJournals = false;
	journals: Models.JournalEntry[] = [];

	organization: Models.Organization;

	deleteForm: UntypedFormGroup;
	showDeleteMsg = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private partnerAdminService: PartnerAdminService,
		private usersService: UsersService,
		private organizationsService: OrganizationsService,
		private adminLogsService: AdminLogsService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.partnership = this.partnerAdminService.getOne(this.id);
			if (!this.partnership || this.partnership == null || this.partnership.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.partnerAdminService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		this.listSubscription = this.partnerAdminService.partnerships.subscribe(messages => {
			this.partnership = messages.find((message: Models.Partnership) => message.id === this.id);
		});

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.authUser = authUser;
				this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-partnerships'));
				this.canAddJournals = ValidationTools.checkAccess(authUser, 'add-journals');
				this.loadData();
			} //if
		});

		// changes
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();

	}

	async loadData() {
		this.loading = true;

		this.organization = this.organizationsService.getOne(this.partnership.org_id);

		this.addedByUser = null;
		if (this.partnership.added_by && this.partnership.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.partnership.added_by);

		this.editedByUser = null;
		if (this.partnership.edited_by && this.partnership.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.partnership.edited_by);

		this.adminLogs = await this.adminLogsService.getLogs(['partnership'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-partner-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.journals = await this.adminLogsService.getJournals('partnership', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);

		this.deleteForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;
	}

	async delete() {
		this.loading = true;

		let confirmation = '';
		if (this.deleteForm.value.confirmation) confirmation = this.deleteForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'delete') {
			this.showDeleteMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDeleteModalButton"))
			document.getElementById("closeDeleteModalButton").click();

		// Unsubscribe from list before delete or it will attempt to find now deleted item before navigation away
		if (this.listSubscription) this.listSubscription.unsubscribe();

		const result = await this.partnerAdminService.deleteOne(this.partnership.id);
		if (result) {
			this.uiAlertsService.addMsg('The partner has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.partneradmin]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this partnerhip?')) {
			const result = await this.partnerAdminService.toggleListed(this.partnership.id);
			if (result) {
				this.partnership = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this partnerhip?')) {
			const result = await this.partnerAdminService.toggleListed(this.partnership.id);
			if (result) {
				this.partnership = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}



}
