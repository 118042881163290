import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { ZenCustomersService } from '../../zen-customers/zen-customers.service';
import { LicensingService } from '../../licensing/licensing.service';
import { AuthService } from 'client/app/services/auth.service';
import { MarketplaceService } from '../marketplace.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-marketplace-info',
	templateUrl: './marketplace-info.component.html',
	styleUrls: ['./marketplace-info.component.scss']
})
export class MarketplaceInfoComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	private userSubscription: Subscription;

	marketPlaceZenCustomers: Models.ZenMasterCustomer[] = [];
	marketPlaceKeys: Models.LPActivation[] = [];

	azureSubscriptions: any[] = [];

	gcpAccounts: any[] = [];
	gcpEntitlements: any[] = [];

	showRawAzure: boolean = false;
	showRawGCP: boolean = false;
	loading: boolean = true;

	chopLength: number = 20;

	constructor(
		private router: Router,
		private zenCustomersService: ZenCustomersService,
		private marketplaceService: MarketplaceService,
		private uiAlertsService: UiAlertsService,
		private licensingService: LicensingService,
		private authService: AuthService) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {

			this.loadData();
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		const allZenCustomers = this.zenCustomersService.getAll();
		// filter out just the ones configured to report to marketplace
		this.marketPlaceZenCustomers = [];
		for (const zc of allZenCustomers) {
			if (zc.marketplace != null && zc.marketplace.marketplace !== ''
				&& zc.marketplace.accountIdentifier && zc.marketplace.accountIdentifier !== '') {
				this.marketPlaceZenCustomers.push(zc);

			} // if
		} // for

		// get all keys configured to report to marketplace
		const searchSettings: Models.KeySearchSettings = new Models.KeySearchSettings();
		searchSettings.specialFilter = ['marketplace-configured'];
		this.marketPlaceKeys = await this.licensingService.searchKeys(SharedLicenseTools.buildKeySearchArgs(searchSettings));
		this.azureSubscriptions = await this.marketplaceService.getAzureSubscriptions();
		if (this.azureSubscriptions) {
			this.azureSubscriptions.sort((a, b) => (a.created > b.created) ? 1 : -1);
			for (const azureSubscription of this.azureSubscriptions) {
				const matches: any[] = this.findMatchingObject('azure', this.grabLastPart(azureSubscription.id));
				if (matches && matches.length > 0) azureSubscription['matches'] = matches;
			} // for
		} // if

		this.gcpAccounts = await this.marketplaceService.getGCPAccounts();
		if (this.gcpAccounts) this.gcpAccounts.sort((a, b) => (a.createTime > b.createTime) ? 1 : -1);

		this.gcpEntitlements = await this.marketplaceService.getGCPEntitlements();
		if (this.gcpEntitlements) {
			this.gcpEntitlements.sort((a, b) => (a.createTime > b.createTime) ? 1 : -1);
			for (const gcpEntitlement of this.gcpEntitlements) {
				const matches: any[] = this.findMatchingObject('gcp', this.grabLastPart(gcpEntitlement.name));
				if (matches && matches.length > 0) gcpEntitlement['matches'] = matches;
			} // for
		} // IF

		this.loading = false;
	}

	// --------------------------------------------------------------------
	findMatchingObject(marketplace: string, accountIdentifier: string = '') {
		const matches: any[] = [];
		for (const zc of this.marketPlaceZenCustomers) {
			if (zc.marketplace.marketplace === marketplace && zc.marketplace.accountIdentifier === accountIdentifier) {
				matches.push({
					type: 'zen-master',
					name: zc.name,
					route: ['/' + AppConstants.urls.zencustomers, zc.id]
				});
			} // if
		} // for

		for (const key of this.marketPlaceKeys) {
			if (key.marketplace.marketplace === marketplace && key.marketplace.accountIdentifier === accountIdentifier) {
				matches.push({
					type: 'key',
					name: key.key,
					route: ['/' + AppConstants.urls.licensing, 'activation', key.id]
				});
			} // if
		} // for

		return matches;
	}

	// --------------------------------------------------------------------
	grabLastPart(item: string = '') {
		let lastPart: string = '';
		if (item) {
			const splitItem: string[] = item.split('/');
			if (splitItem.length > 0) lastPart = splitItem[splitItem.length - 1];
		} // if
		return lastPart;
	}

	// --------------------------------------------------------------------
	getAccountState(accountId: string = '') {
		let accountState: string = '';
		if (this.gcpAccounts) {
			const account = MiscTools.pickItem(this.gcpAccounts, 'name', accountId);
			if (account && account.state)
				accountState = account.state;
		} // if
		return accountState;
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}


	// getZENName(id: number) {
	// 	const idx = MiscTools.findIndex(this.zenCustomers, id);
	// 	if (idx !== -1)
	// 		return this.zenCustomers[idx].dns_prefix;
	// 	else
	// 		return '???-' + id;
	// }

	// getMarketPlaceProductName(log: Models.MarketplaceUsageReport) {
	// 	const key = log.marketplace + ':' + log.product_id;
	// 	if (!this.productCache[key]) {
	// 		const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', log.marketplace);
	// 		if (idx1 !== -1) {
	// 			const idx2 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections[idx1].selections, 'productCode', log.product_id);
	// 			if (idx2 !== -1)
	// 				this.productCache[key] = AppConstants.marketPlaceSelections[idx1].selections[idx2].label;
	// 		} // if
	// 	} // if

	// 	if (this.productCache[key])
	// 		return this.productCache[key]

	// 	return log.product_id;
	// }

}
