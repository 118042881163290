<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.myfilesharing" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">File Sharing</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				File Bundle
				<app-loading-spinner *ngIf="loading"></app-loading-spinner>
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="isStaff && bundle" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + appConstants.urls.bundles, bundle.id]"
					ngbTooltip="Switch to the Staff/Admin view of this bundle." placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					Staff View
				</button>

				<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)" [disabled]="loading">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh"
						placement="start"></fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>

			</div>
		</div>
	</div>
</div>

<div class="page-wrapper">

	<div *ngIf="bundle" class="cp-property-list">
		<div class="cp-property-block" *ngIf="bundle.information && bundle.information !== ''">
			<span class="cp-property-label">Information:</span>
			<span class="cp-property-value cp-pre-text">{{ bundle.information }}</span>
		</div>

		<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-send')">
			<span class="cp-property-label">From:</span>
			<span class="cp-property-value">
				{{ bundle.staff_name }}
			</span>
		</div>

		<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-receive')">
			<span class="cp-property-label">From:</span>
			<span class="cp-property-value" *ngIf="bundle.org_id !== 0">
				{{ bundle.org_name }}
			</span>
			<span class="cp-property-value" *ngIf="bundle.org_id === 0">
				{{ bundle.user_names.join(', ') }}
			</span>
		</div>

		<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-send')">
			<span class="cp-property-label">To:</span>
			<span class="cp-property-value" *ngIf="bundle.org_id !== 0">
				{{ bundle.org_name }}
			</span>
			<span class="cp-property-value" *ngIf="bundle.org_id === 0">
				{{ bundle.user_names.join(', ') }}
			</span>
		</div>

		<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-receive')">
			<span class="cp-property-label">To:</span>
			<span class="cp-property-value">
				{{ bundle.staff_name }}
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Added:</span>
			<span class="cp-property-value">
				{{ bundle.added_on | date: appConstants.pageDateTimeFmt }}
			</span>
		</div>

		<div *ngIf="bundle.notified_at" class="cp-property-block">
			<span class="cp-property-label">
				Notified
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="An e-mail notification was sent to the recipients of this bundle."
					placement="bottom-start">
				</fa-icon>:
			</span>
			<span class="cp-property-value">
				{{ bundle.notified_at | date: appConstants.pageDateTimeFmt }}
			</span>
		</div>

		<div *ngIf="bundle.alerted_at" class="cp-property-block">
			<span class="cp-property-label">
				Warning Sent
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="An e-mail notification was sent to the creator of this bundle warning them that the recpients have not downloaded and of the files."
					placement="bottom-start">
				</fa-icon>:
			</span>
			<span class="cp-property-value">
				{{ bundle.alerted_at | date: appConstants.pageDateTimeFmt }}
			</span>
		</div>

		<div class="cp-property-block">
			<span *ngIf="miscTools.hasExpired(bundle.lock_at)" class="cp-property-label">
				Locked
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Files cannot be added to this bundle after this." placement="bottom-start">
				</fa-icon>:

			</span>

			<span *ngIf="!miscTools.hasExpired(bundle.lock_at)" class="cp-property-label">
				Locks
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Files cannot be added to this bundle after this." placement="bottom-start">
				</fa-icon>:
			</span>

			<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(bundle.lock_at)}">
				{{ bundle.lock_at | date: appConstants.pageDateFmt }}
				({{ textTools.niceDaysText(bundle.lock_at) }})
			</span>
		</div>

		<div *ngIf="!bundle.deleted_at" class="cp-property-block">
			<span class="cp-property-label">
				Clean Up
				<fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="Files will be deleted at (or shortly after) this time." placement="bottom-start">
				</fa-icon>:
			</span>
			<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(bundle.delete_at)}">
				{{ bundle.delete_at | date: appConstants.pageDateFmt }}
				({{ textTools.niceDaysText(bundle.delete_at) }})
			</span>
		</div>

		<div *ngIf="bundle.deleted_at" class="cp-property-block">
			<span class="cp-property-label">
				Files Deleted
				<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Files were deleted at this time."
					placement="bottom-start">
				</fa-icon>:
			</span>
			<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(bundle.deleted_at)}">
				{{ bundle.deleted_at | date: appConstants.pageDateTimeFmt }}
				({{ textTools.niceDaysText(bundle.deleted_at) }})
			</span>
		</div>

		<div *ngIf="canNotify" class="cp-property-block">
			<button type="button" class="btn btn-sm btn-outline-primary" (click)="sendNotification('')"
				ngbTooltip="Send an e-mail notification to the recipients of this bundle." placement="bottom-start">
				<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
				Send Notification
			</button>
			<button type="button" class="btn btn-sm btn-outline-primary ms-1" (click)="sendNotification('cc')"
				ngbTooltip="Send an e-mail notification to the recipients of this bundle and CC to me."
				placement="bottom-start">
				<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
				Send Notification (CC Me)
			</button>
			<button type="button" class="btn btn-sm btn-outline-primary ms-1" (click)="sendNotification('bcc')"
				ngbTooltip="Send an e-mail notification to the recipients of this bundle and BCC to me."
				placement="bottom-start">
				<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
				Send Notification (BCC Me)
			</button>
		</div>
	</div>

	<div *ngIf="bundle && bundle.files" class="mt-2">
		File{{ bundle.files.length === 1 ? '' : 's'}}:
		<div *ngFor="let file of bundle.files" class="mt-1">
			<button *ngIf="canDownload" type="button" class="btn btn-sm btn-outline-primary me-1"
				(click)="openDownload(file.id)" ngbTooltip="Download this file" placement="start">
				<fa-icon [icon]="['fas', 'cloud-download-alt']"></fa-icon>
				Download
			</button>

			<button *ngIf="canDownload && !downloadLink" type="button" class="btn btn-sm btn-outline-warning me-1"
				(click)="grabDownloadInfo(file.id)"
				ngbTooltip="Command Line Interface options for downloading this file.  These options are available if you need to pull down this file to a system that doesn't have a web browswer UI."
				placement="bottom">
				<fa-icon [icon]="['fas', 'terminal']"></fa-icon>
				<!-- CLI -->
			</button>

			<span class="fw-bold">{{ file.name }}</span>
			<span class="ms-1" title="{{file.size | number }} bytes">{{ file.size | bytes: "1.0-0" }}</span>
			<span *ngIf="canAddFiles" ngbTooltip="Remove this file." placement="end" class="cp-pointer">
				<fa-icon (click)="removeFile(file.id)" [icon]="['fas', 'times-circle']" class="ms-1"></fa-icon>
			</span>
		</div>

		<div *ngIf="bundle.files.length === 0" class="font-italic">
			None
		</div>
	</div>

	<div *ngIf="!!downloadLink" class="alert alert-secondary">
		<div>
			<span class="cp-property-label">Expires</span>
			<span class="cp-property-value">in {{ downloadLink.expiresMinutes }} minutes
				({{ downloadLink.expires| date: "medium" }})</span>
		</div>

		<div *ngFor="let sampleCommand of downloadLink.sampleCommands">
			<hr />
			<span class="cp-property-value">
				<fa-icon class="text-info" [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert('')"
					[cbContent]="sampleCommand" ngbTooltip="Copy to Clipboard" placement="end"></fa-icon>
				{{ sampleCommand }}
			</span>
		</div>
		<hr />
		<button type="button" (click)="downloadLink = null" class="btn btn-outline-warning btn-sm mb-1">
			<fa-icon [icon]="['fas', 'times-circle']"></fa-icon> Close
		</button>
	</div>

	<button *ngIf="canAddFiles" type="button" class="btn btn-sm btn-outline-primary mt-1" routerLink="files">
		<fa-icon [icon]="['fas', 'plus']"></fa-icon>
		Add Files
	</button>
</div>