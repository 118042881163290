import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';

@Component({
	selector: 'app-protocol-set-list',
	templateUrl: './protocol-set-list.component.html',
	styleUrls: ['./protocol-set-list.component.scss']
})
export class ProtocolSetListComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	protocolSets: Models.ProtocolSet[] = [];

	dupilicateSetIds: number[] = [];

	canManage = false;

	constructor(
		private licensingAdminService: LicensingAdminService,
		private authService: AuthService) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-protocol-sets'));
			this.getData();
			// this.loadTables();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData() {
		this.protocolSets = await this.licensingAdminService.getProtocolSets();
		this.protocolSets.sort((a, b) => (a.name > b.name) ? 1 : -1);

		this.dupilicateSetIds = [];
		const setCounts: any = [];
		for (const protocolSet of this.protocolSets) {
			const key: string = protocolSet.protocolsArr.sort().join(';');
			if (!setCounts[key]) setCounts[key] = 0;
			setCounts[key]++;
		} // for

		for (const protocolSet of this.protocolSets) {
			const key: string = protocolSet.protocolsArr.sort().join(';');
			if (setCounts[key] > 1) this.dupilicateSetIds.push(protocolSet.id);
		} // for

		this.loading = false;
	}

}
