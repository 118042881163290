import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import { LicensingService } from '../licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UsersService } from '../../users/users.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { KeysTableComponent } from 'client/app/components/shared/keys-table/keys-table.component';

@Component({
	selector: 'app-activation-search',
	templateUrl: './activation-search.component.html',
	styleUrls: ['./activation-search.component.scss']
})
export class ActivationSearchComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	sharedLicenseTools = SharedLicenseTools;
	popOverTools = PopOverTools;
	miscTools = MiscTools;

	@ViewChild('keysTable1') keysTable1: KeysTableComponent = null;

	private userSubscription: Subscription;
	// showAdminButton = false;
	showAddButton = false;

	savedSearchIdArg: number = 0;

	activations: Models.LPActivation[];
	// keyWarnings = {};
	// keyMeterWarnings = {};

	// keyExpiries = {};
	// nonEnforcedWarnings = {};

	lpusers: Models.LPUser[] = [];
	protocolChoices: any[] = [];
	savedSearches: Models.SavedLicenseSearch[] = [];
	// techRepUsers: Models.User[] = [];
	// acctOwnerUsers: Models.User[] = [];

	allOrgs: Models.Organization[] = [];
	staffUsers: Models.User[] = [];
	staffUsersById: any = {};

	staffSelections: any[] = [];
	accountOwnerSelections: any[] = [];
	techRepSelections: any[] = [];

	featureChoices: any[] = [];

	loading = true;
	searching = false;
	showResults = false;
	showRecentMessage = false;
	showNoParamsMessage = false;
	showResetButton = false;

	expandWarnings = false;

	keyProducts: Models.LicenseProduct[] = [];

	// other stuff...
	maxMessage = '';

	theSearchForm: UntypedFormGroup;
	theSaveForm: UntypedFormGroup;
	saveSearchWarnings = [];

	formMode = 'searchForm'; // or saveForm or savedSearches

	usageDaySelections: any[] = [
		{ value: 0, label: 'No filter' },
		{ value: 7, label: 'In the last week' },
		{ value: 14, label: 'In the last 2 weeks' },
		{ value: 30, label: 'In the last 30 days' },
		{ value: 90, label: 'In the last 90 days' },
		{ value: 180, label: 'In the last 180 days' },
		{ value: 365, label: 'In the last 365 days' }
	];

	orgTypeSelections: any[] = [];

	orgSelections: any[] = [];
	billingCodeSelections: any[] = [];
	templateSelections: any[] = [];
	protocolSetSelections: any[] = [];

	commercialTypeSelections: any[] = [];

	searchOptions: Models.KeySearchSettings = new Models.KeySearchSettings();
	autoRun: boolean = false;
	advancedSearchMode = false;

	showSpecializedFilters = false;
	// specialCount: number = 0;

	deselectedSpecials: any[] = [];
	selectedSpecials: any[] = [];

	// showPopovers: boolean = false;

	advancedCount: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private organizationsService: OrganizationsService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService) {
		this.route.paramMap.subscribe(params => {
			if (params && params.get('savedSearchId'))
				this.savedSearchIdArg = +params.get('savedSearchId');
		});
	}

	// ************************************************************************************************************************
	ngOnInit(): void {
		// this.dtOptions = Object.assign({}, AppConstants.dtOptPersist);
		// this.dtOptions.order = [[0, 'desc']];

		if (localStorage.getItem('cp-licensingSearch.searchOptions')) {
			this.searchOptions = JSON.parse(localStorage.getItem('cp-licensingSearch.searchOptions'))
			this.autoRun = localStorage.getItem('CLEAN.licensingSearch.autoRun') && localStorage.getItem('CLEAN.licensingSearch.autoRun') === 'yes';
			if (this.autoRun)
				localStorage.setItem('CLEAN.licensingSearch.autoRun', 'no');
		}

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-licensingSearch.settings'))
				settings = JSON.parse(localStorage.getItem('cp-licensingSearch.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('expandWarnings')) this.expandWarnings = settings.expandWarnings;
		if (settingKeys.includes('advancedSearchMode')) this.advancedSearchMode = settings.advancedSearchMode;
		if (settingKeys.includes('showSpecializedFilters')) this.showSpecializedFilters = settings.showSpecializedFilters;

		if (!this.advancedSearchMode && this.hasAdvancedSearchParams())
			this.toggleAdvanced();

		// const hasSearchParams = this.hasSearchParams();
		// console.log('hasSearchParams=' + hasSearchParams);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.showAddButton = (authUser && ValidationTools.checkAccess(authUser, 'manage-keys'));
				this.initForms();
				this.showResetButton = this.hasSearchParams();
			} // if
		});
	}

	// ************************************************************************************************************************
	hasAdvancedSearchParams() {
		return (this.searchOptions.reportDays && this.searchOptions.reportDays > 0)
			|| (this.searchOptions.usageDays && this.searchOptions.usageDays > 0)
			|| (this.searchOptions.touchedDays && this.searchOptions.touchedDays > 0)
			|| (this.searchOptions.notTouchedDays && this.searchOptions.notTouchedDays > 0)
			|| (this.searchOptions.updatedDays && this.searchOptions.updatedDays > 0)
			|| (this.searchOptions.acctOwnerIDs && this.searchOptions.acctOwnerIDs.length > 0)
			|| (this.searchOptions.techRepIDs && this.searchOptions.techRepIDs.length > 0)
			|| (this.searchOptions.protocolsFilter && this.searchOptions.protocolsFilter.length > 0)
			|| (this.searchOptions.andBooleanKeyProperties && this.searchOptions.andBooleanKeyProperties.length > 0)
			|| (this.searchOptions.orBooleanKeyProperties && this.searchOptions.orBooleanKeyProperties.length > 0)
			|| (this.searchOptions.zcpTouchedBy && this.searchOptions.zcpTouchedBy.length > 0)
			|| (this.searchOptions.orgIDs && this.searchOptions.orgIDs.length > 0)
			|| (this.searchOptions.billingCodeFilter && this.searchOptions.billingCodeFilter.length > 0)
			|| (this.searchOptions.commercialTypeFilter && this.searchOptions.commercialTypeFilter.length > 0)
			|| (this.searchOptions.keyTemplateFilter && this.searchOptions.keyTemplateFilter.length > 0)
			|| (this.searchOptions.protocolSetFilter && this.searchOptions.protocolSetFilter.length > 0)
			;
	}

	// ************************************************************************************************************************
	hasSearchParams() {
		return (this.searchOptions.productsFilter && this.searchOptions.productsFilter.length > 0)
			|| (this.searchOptions.typesFilter && this.searchOptions.typesFilter.length > 0)
			|| (this.searchOptions.specialFilter && this.searchOptions.specialFilter.length > 0)
			|| (this.searchOptions.keyFilter && this.searchOptions.keyFilter !== '')
			|| (this.searchOptions.hostidFilter && this.searchOptions.hostidFilter !== '')
			|| (this.searchOptions.textFilter && this.searchOptions.textFilter !== '')
			|| (this.searchOptions.reportDays && this.searchOptions.reportDays > 0)
			|| (this.searchOptions.usageDays && this.searchOptions.usageDays > 0)
			|| (this.searchOptions.touchedDays && this.searchOptions.touchedDays > 0)
			|| (this.searchOptions.notTouchedDays && this.searchOptions.notTouchedDays > 0)
			|| (this.searchOptions.updatedDays && this.searchOptions.updatedDays > 0)
			|| (this.searchOptions.protocolsFilter && this.searchOptions.protocolsFilter.length > 0)
			|| (this.searchOptions.acctOwnerIDs && this.searchOptions.acctOwnerIDs.length > 0)
			|| (this.searchOptions.techRepIDs && this.searchOptions.techRepIDs.length > 0)
			|| (this.searchOptions.andBooleanKeyProperties && this.searchOptions.andBooleanKeyProperties.length > 0)
			|| (this.searchOptions.orBooleanKeyProperties && this.searchOptions.orBooleanKeyProperties.length > 0)
			|| (this.searchOptions.zcpTouchedBy && this.searchOptions.zcpTouchedBy.length > 0)
			|| (this.searchOptions.orgTypeFilter && this.searchOptions.orgTypeFilter.length > 0)
			|| (this.searchOptions.orgIDs && this.searchOptions.orgIDs.length > 0)
			|| (this.searchOptions.billingCodeFilter && this.searchOptions.billingCodeFilter.length > 0)
			|| (this.searchOptions.commercialTypeFilter && this.searchOptions.commercialTypeFilter.length > 0)
			|| (this.searchOptions.keyTemplateFilter && this.searchOptions.keyTemplateFilter.length > 0)
			|| (this.searchOptions.protocolSetFilter && this.searchOptions.protocolSetFilter.length > 0)
			;
	}

	// ************************************************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************************************************
	async initForms(justForm = false) {
		if (!justForm) {
			this.lpusers = await this.licensingService.getClassicLicenseUsers();
			this.protocolChoices = await this.licensingService.getProtocolChoices();
			// this.acctOwnerUsers = await this.licensingService.getAccountOwners();
			// this.techRepUsers = await this.licensingService.getTechReps();
			this.staffUsers = this.usersService.getForRole('staff');

			this.staffUsersById = {};
			for (const user of this.staffUsers)
				this.staffUsersById[user.id] = user;

			if (this.savedSearchIdArg && +this.savedSearchIdArg !== 0) {
				// get the saved search...
				const ss = await this.licensingService.getSavedSearchFromAll(this.savedSearchIdArg);
				if (ss) {
					this.searchOptions = SharedLicenseTools.parseKeySearchSettings(ss.parameters);
					if (!this.advancedSearchMode && this.hasAdvancedSearchParams())
						this.toggleAdvanced();
					this.autoRun = true;
					let msg = 'Running saved search';
					const idx = MiscTools.findIndex(this.staffUsers, ss.user_id);
					if (idx !== -1)
						msg += ' from ' + this.staffUsers[idx].name;
					msg += ' - "' + ss.name + '"';
					this.uiAlertsService.addMsg(msg, 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				} // if
			} // if

			this.savedSearches = await this.licensingService.getSavedSearches(false);
			this.keyProducts = await this.licensingAdminService.getProducts();

			const allKeyProperties = await this.licensingAdminService.getProperties();
			this.featureChoices = [];
			for (const prop of allKeyProperties)
				if (['boolean'].includes(prop.ptype) && prop.num_products > 0)
					this.featureChoices.push({ value: prop.name, label: prop.label + ' (Feature)' });

			for (const prop of allKeyProperties)
				if (['number'].includes(prop.ptype) && prop.num_products > 0)
					this.featureChoices.push({ value: prop.name, label: prop.label + ' (Limit)' });

			this.orgTypeSelections = [];
			this.orgTypeSelections.push({
				value: AppConstants.nullOrgTypeForKeySearch,
				label: 'Not Linked to an Organization'
			});
			for (const otype of AppConstants.organizationTypes)
				this.orgTypeSelections.push({
					value: otype,
					label: AppConstants.organizationTypeLabels[otype]
				});

			this.allOrgs = this.organizationsService.getAll();
			this.orgSelections = [];
			for (const org of this.allOrgs)
				this.orgSelections.push({
					value: org.id,
					label: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});

			this.staffSelections = [];
			for (const u of this.staffUsers)
				if (u.is_enabled === 1 && ValidationTools.checkAccess(u, 'manage-keys'))
					this.staffSelections.push({ id: u.id, name: u.name });

			for (const u of this.staffUsers)
				if (u.is_enabled === 1 && !ValidationTools.checkAccess(u, 'manage-keys'))
					this.staffSelections.push({ id: u.id, name: u.name });

			for (const u of this.staffUsers)
				if (u.is_enabled === 0)
					this.staffSelections.push({ id: u.id, name: u.name + '(disabled account)' });

			this.accountOwnerSelections = [];
			this.techRepSelections = [];
			for (const org of this.allOrgs) {
				if (org.salesforce_account_owner_id && org.salesforce_account_owner_id !== 0) {
					const idx1 = MiscTools.findIndex(this.accountOwnerSelections, org.salesforce_account_owner_id);
					if (idx1 === -1) {
						const idx2 = MiscTools.findIndex(this.staffUsers, org.salesforce_account_owner_id);
						if (idx2 !== -1)
							this.accountOwnerSelections.push({ id: this.staffUsers[idx2].id, name: this.staffUsers[idx2].name });
					} // if
				} // if

				if (org.salesforce_se_id && org.salesforce_se_id !== 0) {
					const idx1 = MiscTools.findIndex(this.techRepSelections, org.salesforce_se_id);
					if (idx1 === -1) {
						const idx2 = MiscTools.findIndex(this.staffUsers, org.salesforce_se_id);
						if (idx2 !== -1)
							this.techRepSelections.push({ id: this.staffUsers[idx2].id, name: this.staffUsers[idx2].name });
					} // if
				} // if
			} // for	

			this.accountOwnerSelections.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.techRepSelections.sort((a, b) => (a.name > b.name) ? 1 : -1);

			const allBillingCodes: Models.BillingCode[] = await this.licensingService.getBillingCodes();
			this.billingCodeSelections = [];
			for (const bc of allBillingCodes) {
				let orgName: string = '(' + bc.zcp_org_id + ')'
				const theOrg = MiscTools.pickItemById(this.allOrgs, bc.zcp_org_id);
				if (theOrg) orgName = '(' + theOrg.name + ')'

				this.billingCodeSelections.push({
					value: bc.billing_code,
					label: bc.billing_code + '-' + bc.label + orgName
				});
			} // for

			const allTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();
			this.templateSelections = [];
			for (const template of allTemplates)
				this.templateSelections.push({
					value: template.id,
					label: template.name
				});
			this.templateSelections.sort((a, b) => (a.label > b.label) ? 1 : -1);

			const allProtocolSets = await this.licensingAdminService.getProtocolSets();
			allProtocolSets.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.protocolSetSelections = [];
			for (const pSet of allProtocolSets)
				this.protocolSetSelections.push({
					value: pSet.id,
					label: pSet.name
				});

			this.commercialTypeSelections = [];
			let counter = 0;
			for (const keyCommercialType of AppConstants.keyCommercialTypes) {
				counter++;
				this.commercialTypeSelections.push({
					value: keyCommercialType.value,
					label: counter + '. ' + keyCommercialType.short_label + ' (' + keyCommercialType.acronym + ')'
				});
			} // for
		} // if

		this.theSearchForm = new UntypedFormGroup({
			productsFilter: new UntypedFormControl(this.searchOptions.productsFilter),
			typesFilter: new UntypedFormControl(this.searchOptions.typesFilter),
			keyFilter: new UntypedFormControl(this.searchOptions.keyFilter),
			hostidFilter: new UntypedFormControl(this.searchOptions.hostidFilter),
			textFilter: new UntypedFormControl(this.searchOptions.textFilter),
			reportDays: new UntypedFormControl(this.searchOptions.reportDays === 0 ? null : this.searchOptions.reportDays),
			usageDays: new UntypedFormControl(this.searchOptions.usageDays === 0 ? null : this.searchOptions.usageDays),
			touchedDays: new UntypedFormControl(this.searchOptions.touchedDays === 0 ? null : this.searchOptions.touchedDays),
			notTouchedDays: new UntypedFormControl(this.searchOptions.notTouchedDays === 0 ? null : this.searchOptions.notTouchedDays),
			updatedDays: new UntypedFormControl(this.searchOptions.updatedDays === 0 ? null : this.searchOptions.updatedDays),
			protocolsFilter: new UntypedFormControl(this.searchOptions.protocolsFilter),
			acctOwnerIDs: new UntypedFormControl(this.searchOptions.acctOwnerIDs),
			techRepIDs: new UntypedFormControl(this.searchOptions.techRepIDs),
			andBooleanKeyProperties: new UntypedFormControl(this.searchOptions.andBooleanKeyProperties),
			orBooleanKeyProperties: new UntypedFormControl(this.searchOptions.orBooleanKeyProperties),
			zcpTouchedBy: new UntypedFormControl(this.searchOptions.zcpTouchedBy),
			orgTypeFilter: new UntypedFormControl(this.searchOptions.orgTypeFilter),
			orgIDs: new UntypedFormControl(this.searchOptions.orgIDs),
			billingCodeFilter: new UntypedFormControl(this.searchOptions.billingCodeFilter),
			commercialTypeFilter: new UntypedFormControl(this.searchOptions.commercialTypeFilter),
			keyTemplateFilter: new UntypedFormControl(this.searchOptions.keyTemplateFilter),
			protocolSetFilter: new UntypedFormControl(this.searchOptions.protocolSetFilter)
		});

		// for (const o of AppConstants.specialKeySearches)
		// 	this.theSearchForm.addControl('special_' + o.value, new FormControl(this.searchOptions.specialFilter.includes(o.value)));

		this.selectedSpecials = [];
		this.deselectedSpecials = [];
		let counter = 0;
		for (const o of AppConstants.specialKeySearches) {
			counter++;
			o['order'] = counter;
			if (this.searchOptions.specialFilter.includes(o.value))
				this.selectedSpecials.push(o);
			else
				this.deselectedSpecials.push(o);
		} // for
		// this.specialCount = this.searchOptions.specialFilter.length;

		this.theSaveForm = new UntypedFormGroup({
			name: new UntypedFormControl('', [Validators.required])
		});

		this.updateAdvancedCount(false);

		this.loading = false;

		if (this.autoRun) {
			this.autoRun = false;
			this.doSearch();
		}
	}

	// ************************************************************************************************************************
	resetForm() {
		this.searchOptions = new Models.KeySearchSettings();

		this.showResetButton = false;
		this.initForms(true);
		this.saveParametersToLocalStorage();
	}

	// ************************************************************************************************************************
	saveParametersToLocalStorage() {
		localStorage.setItem('cp-licensingSearch.searchOptions', JSON.stringify(this.searchOptions));
	}

	// ************************************************************************************************************************
	grabParametersFromForm() {
		this.searchOptions.productsFilter = this.theSearchForm.value.productsFilter;
		this.searchOptions.typesFilter = this.theSearchForm.value.typesFilter;
		this.searchOptions.keyFilter = this.theSearchForm.value.keyFilter;

		this.searchOptions.hostidFilter = this.theSearchForm.value.hostidFilter;
		this.searchOptions.textFilter = this.theSearchForm.value.textFilter;
		this.searchOptions.orgTypeFilter = this.theSearchForm.value.orgTypeFilter;

		this.searchOptions.reportDays = +this.theSearchForm.value.reportDays;
		this.searchOptions.usageDays = +this.theSearchForm.value.usageDays;
		this.searchOptions.touchedDays = +this.theSearchForm.value.touchedDays;

		this.searchOptions.notTouchedDays = +this.theSearchForm.value.notTouchedDays;
		this.searchOptions.updatedDays = +this.theSearchForm.value.updatedDays;
		this.searchOptions.zcpTouchedBy = this.theSearchForm.value.zcpTouchedBy;

		this.searchOptions.keyTemplateFilter = this.theSearchForm.value.keyTemplateFilter;
		this.searchOptions.acctOwnerIDs = this.theSearchForm.value.acctOwnerIDs;
		this.searchOptions.techRepIDs = this.theSearchForm.value.techRepIDs;

		this.searchOptions.orgIDs = this.theSearchForm.value.orgIDs;
		this.searchOptions.billingCodeFilter = this.theSearchForm.value.billingCodeFilter;
		this.searchOptions.protocolsFilter = this.theSearchForm.value.protocolsFilter;

		this.searchOptions.andBooleanKeyProperties = this.theSearchForm.value.andBooleanKeyProperties;
		this.searchOptions.orBooleanKeyProperties = this.theSearchForm.value.orBooleanKeyProperties;
		this.searchOptions.commercialTypeFilter = this.theSearchForm.value.commercialTypeFilter;
		this.searchOptions.protocolSetFilter = this.theSearchForm.value.protocolSetFilter;

		this.searchOptions.specialFilter = [];
		for (const o of this.selectedSpecials)
			this.searchOptions.specialFilter.push(o.value);
	}

	// ************************************************************************************************************************
	updateAdvancedCount(grabForm: boolean = true) {
		if (grabForm) this.grabParametersFromForm();

		this.advancedCount = 0;
		if (!isNaN(this.searchOptions.reportDays) && this.searchOptions.reportDays > 0) this.advancedCount++;
		if (!isNaN(this.searchOptions.usageDays) && this.searchOptions.usageDays > 0) this.advancedCount++;
		if (!isNaN(this.searchOptions.touchedDays) && this.searchOptions.touchedDays > 0) this.advancedCount++;

		if (!isNaN(this.searchOptions.notTouchedDays) && this.searchOptions.notTouchedDays > 0) this.advancedCount++;
		if (!isNaN(this.searchOptions.updatedDays) && this.searchOptions.updatedDays > 0) this.advancedCount++;
		if (this.searchOptions.zcpTouchedBy != null && this.searchOptions.zcpTouchedBy.length > 0) this.advancedCount++;

		if (this.searchOptions.keyTemplateFilter != null && this.searchOptions.keyTemplateFilter.length > 0) this.advancedCount++;
		if (this.searchOptions.acctOwnerIDs != null && this.searchOptions.acctOwnerIDs.length > 0) this.advancedCount++;
		if (this.searchOptions.techRepIDs != null && this.searchOptions.techRepIDs.length > 0) this.advancedCount++;

		if (this.searchOptions.orgIDs != null && this.searchOptions.orgIDs.length > 0) this.advancedCount++;
		if (this.searchOptions.billingCodeFilter != null && this.searchOptions.billingCodeFilter.length > 0) this.advancedCount++;
		if (this.searchOptions.protocolsFilter != null && this.searchOptions.protocolsFilter.length > 0) this.advancedCount++;

		if (this.searchOptions.andBooleanKeyProperties != null && this.searchOptions.andBooleanKeyProperties.length > 0) this.advancedCount++;
		if (this.searchOptions.orBooleanKeyProperties != null && this.searchOptions.orBooleanKeyProperties.length > 0) this.advancedCount++;
		if (this.searchOptions.commercialTypeFilter != null && this.searchOptions.commercialTypeFilter.length > 0) this.advancedCount++;
		if (this.searchOptions.protocolSetFilter != null && this.searchOptions.protocolSetFilter.length > 0) this.advancedCount++;
	}

	// ************************************************************************************************************************
	async showSaveForm() {
		this.saveSearchWarnings = [];
		this.grabParametersFromForm();
		this.saveParametersToLocalStorage();

		if (!this.hasSearchParams()) {
			this.showNoParamsMessage = true;
			this.showResults = false;
			this.showResetButton = false;
			return;
		} else {
			this.showNoParamsMessage = false;
			this.showResetButton = true;
			this.formMode = 'saveForm';
		}
	}

	// ************************************************************************************************************************
	async saveSearch() {
		this.saveSearchWarnings = [];
		// try to save the search...
		const name = this.theSaveForm.value.name;
		const params = SharedLicenseTools.buildKeySearchArgs(this.searchOptions);

		// load fresh copy of saved searches
		this.savedSearches = await this.licensingService.getSavedSearches(true);

		// check other saved searches for dup name/params
		const idx1 = MiscTools.findIndexGeneric(this.savedSearches, 'name', name.trim());
		// const idx2 = MiscTools.findIndexGeneric(this.savedSearches, 'parameters', params.trim());
		const idx2 = -1;

		if (idx1 !== -1)
			this.saveSearchWarnings.push('That name has already been used for a saved search.');

		if (idx2 !== -1)
			this.saveSearchWarnings.push('You already have a saved search with those parameters.');

		if (this.saveSearchWarnings.length > 0)
			this.uiAlertsService.addMsgs(this.saveSearchWarnings, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

		if (idx1 === -1 && idx2 === -1) {
			const obj = new Models.SavedLicenseSearch(0, 0, name, params);
			const retObj = await this.licensingService.addSavedSearch(obj);

			this.savedSearches = await this.licensingService.getSavedSearches(false);
			this.formMode = 'savedSearches';
			this.theSaveForm = new UntypedFormGroup({
				name: new UntypedFormControl('', [Validators.required])
			});
		}
	}

	// ************************************************************************************************************************
	async runSavedSearchFromPicker() {
		const picker = document.getElementById('savedSearchPicker');
		if (picker) {
			let id: number = +picker['value'];
			if (isNaN(id)) id = 0;
			if (id !== 0) await this.runSavedSearch(id);
		}
	}
	// ************************************************************************************************************************
	async runSavedSearch(id: number) {
		const idx = MiscTools.findIndex(this.savedSearches, id);
		if (idx !== -1) {
			// console.log(this.searchOptions);
			// console.log(this.savedSearches[idx].parameters);
			this.searchOptions = SharedLicenseTools.parseKeySearchSettings(this.savedSearches[idx].parameters);
			// console.log(this.searchOptions);
			if (!this.advancedSearchMode && this.hasAdvancedSearchParams())
				this.toggleAdvanced();
			this.loading = true;
			this.initForms(true);
			this.formMode = 'searchForm';

			const msg = 'Running saved search - "' + this.savedSearches[idx].name + '"';
			this.uiAlertsService.addMsg(msg, 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			this.updateAdvancedCount(false);
			this.doSearch();
		}
	}

	// ************************************************************************************************************************
	async deleteSavedSearch(id: number) {
		if (confirm('Are you sure that you want to delete this saved search?')) {
			await this.licensingService.deleteSavedSearch(id);
			this.savedSearches = await this.licensingService.getSavedSearches(false);
			if (this.savedSearches.length === 0) this.formMode = 'searchForm';
			this.uiAlertsService.addMsg('The saved search has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// ************************************************************************************************************************
	async overwriteSavedSearch(id: number) {
		if (confirm('Are you sure that you want to overwrite this saved search?')) {
			this.saveSearchWarnings = [];
			const idx1 = MiscTools.findIndexGeneric(this.savedSearches, 'id', id);

			if (idx1 !== -1) {
				this.savedSearches[idx1].parameters = SharedLicenseTools.buildKeySearchArgs(this.searchOptions);
				const retObj = await this.licensingService.updateSavedSearch(id, this.savedSearches[idx1]);

				this.savedSearches = await this.licensingService.getSavedSearches(false);
				this.formMode = 'savedSearches';
				this.theSaveForm = new UntypedFormGroup({
					name: new UntypedFormControl('', [Validators.required])
				});
			}
		}
	}

	// ************************************************************************************************************************
	async doSearch() {
		this.grabParametersFromForm();
		this.saveParametersToLocalStorage();

		if (!this.hasSearchParams()) {
			this.showNoParamsMessage = true;
			this.showResults = false;
			this.showResetButton = false;
			return;
		} else {
			this.showNoParamsMessage = false;
			this.showResetButton = true;

			this.loadKeys(SharedLicenseTools.buildKeySearchArgs(this.searchOptions));
		}
	}

	// ************************************************************************************************************************
	async loadKeys(params: string) {
		this.searching = true;
		this.showRecentMessage = false;
		this.showResults = false;
		this.uiAlertsService.addMsg('Finding keys.', 'info', 'search_keys', false, AppConstants.standardMessageAutoCloseTimeSecs);

		this.activations = await this.licensingService.searchKeys(params);
		this.activations.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);
		let fetchCount: boolean = false;
		if (this.activations && this.activations.length > AppConstants.maxKeysToFetch) {
			this.activations.pop(); // remove the last one..
			this.maxMessage = 'Maximum search results (' + AppConstants.maxKeysToFetch.toLocaleString() + ') returned.';
			fetchCount = true;
		} else {
			this.maxMessage = '';
		} // if

		const ids: number[] = [];
		for (const item of this.activations) {
			ids.push(item.id);

			item['__niceProduct'] = this.niceKeyProduct(item.product);
			if (this.activations.length < AppConstants.maxKeysForPopovers)
				item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, this.allOrgs, this.staffUsers, false).join('\n');

			item['__last_touched_by'] = this.getLastTouchedName(item);
			item['__sales_engineer'] = this.getSEName(item);

			// __active_hostids
			// __user_label
			// __user_id
		} // for

		this.uiAlertsService.clearMsgByCode('search_keys');

		localStorage.setItem('CLEAN.licensingSearch.latestIDs', JSON.stringify(ids));

		// this.showPopovers = this.activations.length < AppConstants.maxKeysForPopovers;
		this.searching = false;
		this.showResults = true;

		if (fetchCount) {
			const fullCount = await this.licensingService.getKeySearchTotal(params);
			this.maxMessage = 'Maximum search results (' + AppConstants.maxKeysToFetch.toLocaleString() + ' of ' + fullCount.toLocaleString() + ') returned.';
		} // if

		if (this.keysTable1) {
			if (this.expandWarnings && !this.keysTable1.expandWarnings) this.keysTable1.toggleWarnings();

			this.keysTable1.updateContent(this.activations, 'cp-key-search',
				{
					staffMode: true,
					addPopovers: this.activations.length < AppConstants.maxKeysForPopovers,

					showStaffDelete: false,
					showEnabled: false,
					showUserLabel: false,
					showInfo: true,
					showOrganization: true,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: false,
					showNumUsers: true,
					showActiveCount: false,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: false,
					showLastTouched: true,
					showSalesEngineer: true,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if
	}

	// ************************************************************************************************************************
	saveAsExcel() {
		if (!this.hasSearchParams()) return;

		let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing;
		url += '?format=xlsx'
			+ '&' + SharedLicenseTools.buildKeySearchArgs(this.searchOptions);

		window.open(url, '_blank');
	}

	// ************************************************************************************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	// ************************************************************************************************************************
	saveSettings() {
		let settings: any = {
			expandWarnings: this.expandWarnings,
			advancedSearchMode: this.advancedSearchMode,
			showSpecializedFilters: this.showSpecializedFilters,
		};
		localStorage.setItem('cp-licensingSearch.settings', JSON.stringify(settings));
	}

	// ************************************************************************************************************************
	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;
		if (this.keysTable1) this.keysTable1.toggleWarnings();
		this.saveSettings();
	}

	// ************************************************************************************************************************
	toggleAdvanced() {
		this.advancedSearchMode = !this.advancedSearchMode;
		this.saveSettings();
	}

	// ************************************************************************************************************************
	toggleSpecialized() {
		this.showSpecializedFilters = !this.showSpecializedFilters;
		this.saveSettings();
	}

	// ************************************************************************************************************************
	getClassicUserName(id: number) {
		const idx = MiscTools.findIndex(this.lpusers, id);
		if (idx !== -1)
			return this.lpusers[idx].email.replace(/\./g, ' ');
		else
			return 'deleted user (id=' + id + ')';
	}

	// ************************************************************************************************************************
	getUserType(activation: Models.LPActivation) {
		// figure out if the key was ever touched by a cp user
		// or last touched by a lp user
		if ((activation.zcp_created_by && activation.zcp_created_by !== 0)
			|| (activation.zcp_updated_by && activation.zcp_updated_by !== 0))
			return 'zcp';
		else if (activation.user_id && activation.user_id !== 0)
			return 'lp';
		else
			return ''; // no user
	}

	// ************************************************************************************************************************
	getSEName(activation: Models.LPActivation) {
		if (activation.zcp_org_id && activation.zcp_org_id !== 0) {
			const theOrg = MiscTools.pickItem(this.allOrgs, 'id', activation.zcp_org_id);
			if (theOrg) return theOrg.salesforce_se;
		} // if
		return '';
	}

	// ************************************************************************************************************************
	getLastTouchedName(activation: Models.LPActivation) {
		const utype = this.getUserType(activation);
		if (utype === 'zcp') {
			let id = -1;
			if (activation.zcp_updated_by && activation.zcp_updated_by !== 0)
				id = activation.zcp_updated_by;
			else
				id = activation.zcp_created_by

			if (this.staffUsersById[id])
				return this.staffUsersById[id].name;
			else
				return 'Deleted CP User (id=' + id + ')';

		} else if (utype === 'lp') {
			const idx = MiscTools.findIndex(this.lpusers, activation.user_id);
			if (idx !== -1)
				return this.lpusers[idx].email.replace(/\./g, ' ');
			else
				return 'deleted LP user (id=' + activation.user_id + ')';

		} else {
			return '';
		}
	}

	// ************************************************************************************************************************
	getDateFromDateStruct(dateStruct: NgbDateStruct) {
		let theDate: Date = null;
		if (dateStruct) {
			theDate = new Date(dateStruct.year + '/' + dateStruct.month + '/' + dateStruct.day);
			if (isNaN(theDate.getTime())) theDate = null;
		}
		return theDate;
	}

	// ************************************************************************************************************************
	selectSpecialFilter(filter: string) {
		const idx = MiscTools.findIndexGeneric(this.deselectedSpecials, 'value', filter);
		if (idx !== -1) {
			const special = MiscTools.deepClone(this.deselectedSpecials[idx]);
			this.deselectedSpecials.splice(idx, 1);
			this.selectedSpecials.push(special);
		} // if
	}

	// ************************************************************************************************************************
	deselectSpecialFilter(filter: string) {
		const idx = MiscTools.findIndexGeneric(this.selectedSpecials, 'value', filter);
		if (idx !== -1) {
			const special = MiscTools.deepClone(this.selectedSpecials[idx]);
			this.selectedSpecials.splice(idx, 1);
			this.deselectedSpecials.push(special);
			this.deselectedSpecials.sort((a, b) => (a.order > b.order) ? 1 : -1);
		} // if
	}

	// ************************************************************************************************************************
	doAggregate() {
		let keyList = '';
		for (const act of this.activations)
			keyList += act.key + '\n';

		localStorage.setItem('cp-licensing.keysAndHostIds', keyList);
		this.router.navigate(['/' + AppConstants.urls.licensing, 'aggregate']);
	}

	get productsFilter() { return this.theSearchForm.get('productsFilter'); }
	get typesFilter() { return this.theSearchForm.get('typesFilter'); }
	get keyFilter() { return this.theSearchForm.get('keyFilter'); }
	get hostidFilter() { return this.theSearchForm.get('hostidFilter'); }
	get textFilter() { return this.theSearchForm.get('textFilter'); }
	get orgTypeFilter() { return this.theSearchForm.get('orgTypeFilter'); }

	get reportDays() { return this.theSearchForm.get('reportDays'); }
	get usageDays() { return this.theSearchForm.get('usageDays'); }
	get touchedDays() { return this.theSearchForm.get('touchedDays'); }

	get notTouchedDays() { return this.theSearchForm.get('notTouchedDays'); }
	get updatedDays() { return this.theSearchForm.get('updatedDays'); }
	get zcpTouchedBy() { return this.theSearchForm.get('zcpTouchedBy'); }

	get keyTemplateFilter() { return this.theSearchForm.get('keyTemplateFilter'); }
	get acctOwnerIDs() { return this.theSearchForm.get('acctOwnerIDs'); }
	get techRepIDs() { return this.theSearchForm.get('techRepIDs'); }

	get orgIDs() { return this.theSearchForm.get('orgIDs'); }
	get billingCodeFilter() { return this.theSearchForm.get('billingCodeFilter'); }
	get protocolsFilter() { return this.theSearchForm.get('protocolsFilter'); }

	get andBooleanKeyProperties() { return this.theSearchForm.get('andBooleanKeyProperties'); }
	get orBooleanKeyProperties() { return this.theSearchForm.get('orBooleanKeyProperties'); }
	get commercialTypeFilter() { return this.theSearchForm.get('commercialTypeFilter'); }
	get protocolSetFilter() { return this.theSearchForm.get('protocolSetFilter'); }

}
