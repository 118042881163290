<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="/organizations">Organizations</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Groups</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ group.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!group">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canSendNotifications" type="button" class="btn btn-outline-primary"
					[routerLink]="['notify']" ngbTooltip="Notify" placement="bottom-end">
					<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
				</button>

				<button *ngIf="canEdit && group.is_enabled === 1" type="button" class="btn btn-outline-warning"
					(click)="disable()"
					ngbTooltip="Disabling an group will prevent its members from downloading files it has access to"
					placement="bottom">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && group.is_enabled === 0" type="button" class="btn btn-outline-success"
					(click)="enable()" ngbTooltip="Enable" placement="start">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-pp-tab" aria-controls="nav-pp" data-bs-target="#nav-pp">
				<fa-icon [icon]="appConstants.sectionIcons.products" size="sm"></fa-icon>
				Products
				<span class="cp-tab-badge">
					{{ group.product_platforms.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showDirectTab" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-direct-tab" aria-controls="nav-direct" data-bs-target="#nav-direct">
				<fa-icon [icon]="['fas', 'bullseye-pointer']" size="sm"></fa-icon>
				Direct Access
				<span class="cp-tab-badge">
					{{ (builds.length + documents.length) | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-builds-tab" aria-controls="nav-downloads-builds"
				data-bs-target="#nav-downloads-builds" ngbTooltip="Software Builds Available to Members of this Group">
				SW
				<span *ngIf="!loading || buildDownloads.length > 0" class="cp-tab-badge">
					{{ buildDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-documents-tab" aria-controls="nav-downloads-documents"
				data-bs-target="#nav-downloads-documents" ngbTooltip="Documentation Available to Members of this Group">
				<fa-icon [icon]="appConstants.sectionIcons.mydocuments" size="sm"></fa-icon>
				Docs
				<span *ngIf="!loading || documentDownloads.length > 0" class="cp-tab-badge">
					{{ documentDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-admin-log-tab" aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>



	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!group" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="group.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled -
						Downloads available
					</span>
					<span *ngIf="group.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - Downloads
						not available
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ group.name }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!group.information && group.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ group.information }}</span>
				</div>

				<div *ngIf="managers && managers.length > 0" class="cp-property-block">
					<span class="cp-property-label">Manager(s):</span>
					<ul class="mb-0">
						<li *ngFor="let user of managers" class="cp-property-value">
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.users, user.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ user.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(user).join('\n')}}</div>
							</ng-template>

						</li>
					</ul>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ group.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="group.added_on !== group.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ group.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organizations.length !== 0">
					<span class="cp-property-label">Organizations:</span>
					<ul class="mb-0">
						<li *ngFor="let organization of organizations" class="cp-property-value">
							<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']"
								class="text-danger ms-1" ngbTooltip="Organization is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.organizations, organization.id]"
								[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ organization.name }}</a>
							<ng-template #organizationPopupContent>
								<div class="cp-popover-banner">{{ organization.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getOrganizationPopoverLines(organization).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!showDirectTab && builds.length !== 0">
					<span class="cp-property-label">Direct Access Builds:</span>
					<ul class="mb-0">
						<li *ngFor="let build of builds" class="cp-property-value">
							<fa-icon *ngIf="build.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="build.is_private === 0" [icon]="['fas', 'globe']" size="sm"
								class="text-success me-1" ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access">
							</fa-icon>
							<fa-icon *ngIf="build.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Build is not enabled"></fa-icon>

							<a [routerLink]="['/' + appConstants.urls.builds, build.id]"
								[ngbPopover]="buildPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ build.prod_name }} -
								{{ build.version }} <span *ngIf="build.label && build.label !== ''">({{ build.label
									}})</span></a>
							<ng-template #buildPopupContent>
								<div class="cp-popover-banner">{{ build.version }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBuildPopoverLines(build).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!showDirectTab && documents.length !== 0">
					<span class="cp-property-label">Direct Access Documents:</span>
					<ul class="mb-0">
						<li *ngFor="let document of documents" class="cp-property-value">
							<fa-icon *ngIf="document.is_private === 1" [icon]="['fas', 'lock']"
								class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="document.is_private === 0" [icon]="['fas', 'globe']" size="sm"
								class="text-success me-1" ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="document.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.documents, document.id]"
								[ngbPopover]="documentPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ document.label }}</a>
							<ng-template #documentPopupContent>
								<div class="cp-popover-banner">{{ document.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getDocumentPopoverLines(document).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-pp" role="tabpanel" aria-labelledby="nav-pp-tab">
			<div *ngIf="!!group && group.product_platforms.length === 0" class="cp-light-message">
				No Products/Platforms selected for this Organization.
			</div>

			<div *ngIf="!!group && group.product_platforms.length !== 0" class="cp-property-list">
				<div *ngFor="let productType of productTypesToShow">
					<strong>{{ appConstants.productTypeLabels[productType] }} Products</strong>
					<ul class="mb-0">
						<li *ngFor="let product of subListOfUsedProducts(productType)">
							<span class="fw-bold">{{ product.name }}</span><br />
							<span
								*ngFor="let pl of miscTools.sortPlatforms(allPlatforms, getPlatformIdsForProduct(product.id, group.product_platforms))">
								<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
								{{ pl.name }}&nbsp;
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-direct" role="tabpanel" aria-labelledby="nav-direct-tab">

			<div *ngIf="group" class="cp-light-message">
				The following Builds and/or Documents have been directly assigned to {{ group.name }}.
			</div>

			<div *ngIf="group" class="cp-property-list">
				<div class="cp-property-block" *ngIf="builds.length !== 0">
					<span class="cp-property-label">Direct Access Builds:</span>
					<ul class="mb-0">
						<li *ngFor="let build of builds" class="cp-property-value">
							<fa-icon *ngIf="build.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="build.is_private === 0" [icon]="['fas', 'globe']" size="sm"
								class="text-success me-1" ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="build.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Build is not enabled"></fa-icon>
							<fa-icon *ngIf="build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access">
							</fa-icon>

							<a [routerLink]="['/' + appConstants.urls.builds, build.id]"
								[ngbPopover]="buildPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ build.prod_name }} -
								{{ build.version }}
								<span *ngIf="build.label && build.label !== ''">({{ build.label }})</span></a>
							<ng-template #buildPopupContent>
								<div class="cp-popover-banner">{{ build.version }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBuildPopoverLines(build).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="documents.length !== 0">
					<span class="cp-property-label">Direct Access Documents:</span>
					<ul class="mb-0">
						<li *ngFor="let document of documents" class="cp-property-value">
							<fa-icon *ngIf="document.is_private === 1" [icon]="['fas', 'lock']"
								class="text-warning me-1" ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="document.is_private === 0" [icon]="['fas', 'globe']" size="sm"
								class="text-success me-1" ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="document.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.documents, document.id]"
								[ngbPopover]="documentPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ document.label }}</a>
							<ng-template #documentPopupContent>
								<div class="cp-popover-banner">{{ document.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getDocumentPopoverLines(document).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-downloads-builds" role="tabpanel" aria-labelledby="nav-downloads-builds-tab">
			<div *ngIf="!loading && (!buildDownloads || buildDownloads.length === 0)" class="cp-light-message">
				No software available for this Group.
				<span *ngIf="group.is_enabled === 0">
					The Group is disabled.
				</span>
				<span *ngIf="group.product_platforms.length === 0">
					The Group does not have access to any products & platforms.
				</span>
			</div>

			<app-downloadable-builds-table #dBuildsTable1></app-downloadable-builds-table>
		</div>

		<div class="tab-pane" id="nav-downloads-documents" role="tabpanel"
			aria-labelledby="nav-downloads-documents-tab">
			<div *ngIf="!loading && (!documentDownloads || documentDownloads.length === 0)" class="cp-light-message">
				No documentation available for this Group.
				<span *ngIf="group.is_enabled === 0">
					The Group is disabled.
				</span>
				<span *ngIf="group.product_platforms.length === 0">
					The Group does not have access to any products & platforms.
				</span>
			</div>

			<app-downloadable-documents-table #dDocsTable1></app-downloadable-documents-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!group && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Group.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Organization Group</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Organization Group, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control"
							required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Organization Group
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>