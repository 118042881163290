<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a [routerLink]="['/' + appConstants.urls.licensing]">Key Search</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Search Filters
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-primary me-2" [routerLink]="['/' + appConstants.urls.licensing]">
				<fa-icon [icon]="['fas', 'hand-point-left']" class="text-info"></fa-icon>
				<span class="cp-action-button-text">Back to Search</span>
			</button>
		</div>
	</div>
</div>


<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-basic-tab"
				aria-controls="nav-basic" data-bs-target="#nav-basic">
				Basic Filters
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-advanced-tab"
				aria-controls="nav-advanced" data-bs-target="#nav-advanced">
				Advanced Filters
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-special-tab"
				aria-controls="nav-special" data-bs-target="#nav-special">
				Special Filters
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-protocols-tab"
				aria-controls="nav-protocols" data-bs-target="#nav-protocols">
				Protocols
			</button>
		</li>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-basic" role="tabpanel" aria-labelledby="nav-basic-tab">
			<div class="cp-general-width-limit">
				<div class="mb-1">
					<div class="fw-bold">Product(s)</div>
					<div class="ms-2">
						Zixi Broadcaster (all three sub-products), Zixi Feeder, Zixi Receiver, etc.<br />
						Some legacy products (encoder_feeder, accept, metered_broadcaster, ramp_client ) are not
						included in filter list. Keys with these products can still be found using other filters.
					</div>
				</div>

				<div *ngIf="keyTypes && keyTypes.length > 0" class="mb-1">
					<div class="fw-bold">Type(s)</div>
					<div class="ms-2">
						{{ keyTypes.join(', ') }}
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Key(s)</div>
					<div class="ms-2">
						Supports multiple space-separated search terms.<br />
						Can be full or partial key.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Host ID(s)/IP(s)</div>
					<div class="ms-2">
						Supports multiple space-separated search terms.<br />
						Searches for keys that have activations with a matching host ID or IP.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Text – {{ appConstants.keyInfoFieldLabel }} & Notes</div>
					<div class="ms-2">
						Searches both the {{ appConstants.keyInfoFieldLabel }} (used to be labeled "Customer"), Notes
						and Commercial Notes
						fields for any instance of the word(s) specified.<br />
						Putting a quote (”) at the beginning and end will force an exact match and not break text into
						words.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Organization Type(s)</div>
					<div class="ms-2">
						If a license key is linked to a portal organization, filter results based on the
						organization’s type.
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-advanced" role="tabpanel" aria-labelledby="nav-advanced-tab">
			<div class="cp-general-width-limit">
				<div class="mb-1">
					<div class="fw-bold">Reported In The Last…</div>
					<div class="ms-2">
						Find Keys that either/or:<br />
						- Have reported in (zero or non-zero bytes) against a meter<br />
						- Have reported protocol (v13+) traffic (zero or non-zero bytes) in the last X days
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Used In The Last…</div>
					<div class="ms-2">
						Find Keys that either/or:<br />
						- Have reported usage (non-zero bytes) against a meter<br />
						- Have reported protocol (v13+) usage (non-zero bytes) in the last X days
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Touched In The Last…</div>
					<div class="ms-2">
						Find Keys that either/or:<br />
						- Have been added or updated in the last X days<br />
						- Have new activations in the last X days<br />
						- Have reported traffic (zero or non-zero bytes) against a meter<br />
						- Have reported protocol (v13+) traffic (zero or non-zero bytes) in the last X days
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Not Touched In The Last…</div>
					<div class="ms-2">
						Find Keys that:<br />
						- Have not been added or updated in the last X days<br />
						- No new activations in the last X days<br />
						- Have not reported traffic (zero or non-zero bytes) against a meter<br />
						- Have not reported protocol (v13+) traffic (zero or non-zero bytes) in the last X days
					</div>
				</div>

				<!-- <div class="mb-1">
					<div class="fw-bold">Last Touched By…</div>
					<div class="ms-2">
						Legacy users from the previous generation License Portal.<br />
						Keys record the user that either created the key or last edited the key.
					</div>
				</div> -->

				<div class="mb-1">
					<div class="fw-bold">Added/Edited in the Last...</div>
					<div class="ms-2">
						Find keys that were added or last edited in the last X days.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Added/Edited by...</div>
					<div class="ms-2">
						Find keys created and/or last edited by selected ZCP user(s).
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Key Template(s)</div>
					<div class="ms-2">
						What template (the basis for what the key can/cannot do) was used tcreate the key.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Account Owner(s)</div>
					<div class="ms-2">
						If a license key is linked to a portal organization, filter results based on the
						organization’s account owner (which is account owner from Salesforce).
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">SE/Tech-Rep(s)</div>
					<div class="ms-2">
						If a license key is linked to a portal organization, filter results based on the
						organization’s SE/Tech-Rep.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Organization(s)</div>
					<div class="ms-2">
						If a license key is linked to one of these portal organizations.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Billing Code(s)</div>
					<div class="ms-2">
						If a license has reported usage using one of these billing codes.
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Protocol Traffic Type</div>
					<div class="ms-2">
						Find Keys that have reported non-zero byte traffic of the protocol(s) selected (will only work
						with keys that have at least one Broadcaster v13 or later)
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Keys With All Of These Features</div>
					<div class="ms-2">
						Find Keys that have been configured with all selected features enabled.
					</div>
				</div>

				<!-- <div class="mb-1">
					<div class="fw-bold">Keys With All Of These Features</div>
					<div class="ms-2">
						Find Keys that have been configured with any (1 or more) selected features enabled.
					</div>
				</div> -->

				<div class="mb-1">
					<div class="fw-bold">Linked Protocol Set(s)</div>
					<div class="ms-2">
						Find keys that are linked to selected protocol set(s)
					</div>
				</div>

				<div class="mb-1">
					<div class="fw-bold">Commercial Type(s)</div>
					<div class="ms-2">
						Find Keys that have the selected commercial type(s) set.
					</div>
				</div>

			</div>
		</div>

		<div class="tab-pane" id="nav-special" role="tabpanel" aria-labelledby="nav-special-tab">
			<div class="cp-general-width-limit">
				<div *ngFor="let o of appConstants.specialKeySearches; let i = index" class="mb-1">
					<div class="fw-bold">{{ o.label }}</div>
					<div *ngIf="o.info && o.info !== ''" class="ms-2">
						{{ o.info }}
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-protocols" role="tabpanel" aria-labelledby="nav-protocols-tab">
			<div class="container">

				<div class="row">
					<div *ngFor="let protocolBlock of protocolBlocks; let i = index" class="col-auto">
						<div class="fw-bold cp-pre-text">
							{{ protocolBlock.label }}
						</div>
						<ul class="ps-4">
							<li *ngFor="let protocol of protocolBlock.protocols; let i = index">
								{{ protocol }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>