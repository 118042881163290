<div class="mt-2">
	<!-- <div class="text-center mb-1">
		<button *ngIf="showMeters && itemsToShow.length > 0" type="button" class="btn btn-outline-secondary btn-sm"
			(click)="toggleFirstDates()">
			Show {{ (showFirstDates ? 'Last' : 'First') }} Dates
		</button>
	</div> -->

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th class="text-center" [attr.colspan]="fieldsToShow.length - 5">
					</th>
					<th class="text-center" colspan="4">
						<button *ngIf="showMeters && itemsToShow.length > 0" type="button" class="btn btn-outline-secondary btn-sm"
							(click)="toggleFirstDates()">
							Show {{ (showFirstDates ? 'Last' : 'First') }} Dates
						</button>
					</th>
					<th class="text-center">
					</th>
				</tr>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"
							[ngClass]="cd.label?'ms-1':''"></fa-icon>
					</th>
				</tr>
			</thead>

			<tbody *ngIf="itemsToShow && itemsToShow.length !== 0">
				<tr *ngFor="let item of itemsToShow" class="cp-pointer">
					<td class="text-nowrap text-center" [attr.data-order]="textTools.formatDateTimeUTC(item.created_at)">
						{{ item.created_at| date: appConstants.tableDateFmt }}
					</td>

					<td *ngIf="!staffMode" class="text-center">
						<a *ngIf="!item['__label'] || item['__label'] === ''"
							[routerLink]="['/' + appConstants.urls.mykeys, key, 'host', item.hostid]" [ngbPopover]="popupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body" class="font-italic">
							Not Set</a>
						<a *ngIf="item['__label'] && item['__label'] !== ''"
							[routerLink]="['/' + appConstants.urls.mykeys, key, 'host', item.hostid]" [ngbPopover]="popupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ item['__label'] }}
						</a>
					</td>

					<td class="text-nowrap text-center cp-key">
						<a *ngIf="staffMode" [routerLink]="['/' + appConstants.urls.licensing, 'hostid', item.hostid, item.activation_id]"
							[ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ item.hostid }}
						</a>
						<a *ngIf="!staffMode" [routerLink]="['/' + appConstants.urls.mykeys, key, 'host', item.hostid]"
							[ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ item.hostid }}
						</a>

						<sup *ngIf="item['__counter']"
							ngbTooltip="This Host ID has been activated multiple times against this key. This number is the count of this instance.">
							{{ item['__counter'] }}
						</sup>
					</td>
					<td class="text-nowrap text-center">{{ item.ip }}</td>
					<td *ngIf="staffMode" class="text-nowrap text-center">
						<span *ngIf="item['__format']" [ngbTooltip]="item['__format'].toUpperCase()">
							{{ item['__formatAcronym'] }}
						</span>
					</td>
					<td *ngIf="staffMode" class="text-nowrap text-center">
						<span [ngbTooltip]="item['__meterNames']">
							{{ item['__meterInitials'] }}
						</span>
					</td>
					<td class="cp-icon-cell text-center">
						<fa-icon *ngIf="item.last_meter_report != null" [icon]="['fas', 'tachometer-alt']" size="sm" placement="start"
							[ngbTooltip]="item['__meterReporting']" [class]="item['__meterReportingClass']">
						</fa-icon>
					</td>
					<td class="cp-icon-cell text-center">
						<fa-icon *ngIf="item.last_protocol_report != null" [icon]="['fas', 'chart-line']" size="sm" placement="start"
							[ngbTooltip]="item['__protocolReporting']" [class]="item['__protocolReportingClass']">
						</fa-icon>
					</td>
					<td *ngIf="staffMode" class="cp-icon-cell text-center">
						<fa-icon *ngIf="item['__upgrade']" [icon]="['fas', 'exclamation-triangle']" size="sm" placement="start"
							[ngbTooltip]="upgradeIconPopup">
						</fa-icon>
					</td>
					<td *ngIf="staffMode" class="cp-icon-cell text-center">
						<fa-icon *ngIf="item['__penTest'] && appConstants.penTestCodes[item['__penTestCode']]"
							[icon]="appConstants.penTestCodes[item['__penTestCode']].icon" size="sm"
							[class]="appConstants.penTestCodes[item['__penTestCode']].css" placement="start"
							[ngbTooltip]="item['__penTestLabel']">
						</fa-icon>
					</td>
					<td *ngIf="!showMeters" class="text-center">n/a</td>
					<td *ngIf="!showMeters" class="text-center">n/a</td>
					<td *ngIf="!protocolReady" class="text-center">n/a</td>
					<td *ngIf="!protocolReady" class="text-center">n/a</td>
					<td *ngIf="!protocolReady" class="text-center">n/a</td>
					<td *ngIf="showMeters" class="text-nowrap text-center">
						{{ (showFirstDates ? item.first_meter_report : item.last_meter_report) | date:
						appConstants.tableDateFmt:'UTC'}}
					</td>
					<td *ngIf="showMeters" class="text-nowrap text-center">
						{{ (showFirstDates ? item.first_meter_usage : item.last_meter_usage) | date:
						appConstants.tableDateFmt:'UTC'}}
					</td>
					<td *ngIf="protocolReady" class="text-nowrap text-center">
						{{ (showFirstDates ? item.first_protocol_report : item.last_protocol_report) | date:
						appConstants.tableDateFmt:'UTC'}}
					</td>
					<td *ngIf="protocolReady" class="text-nowrap text-center">
						{{ (showFirstDates ? item.first_protocol_usage : item.last_protocol_usage) | date:
						appConstants.tableDateFmt:'UTC'}}
					</td>
					<td *ngIf="protocolReady" class="text-nowrap text-center">
						{{ item['__cleanVersion'] }}
					</td>

					<ng-template #popupContent>
						<div class="cp-popover-banner">{{ item.hostid }}</div>
						<div class="cp-pre-text">{{ popOverTools.getLicensePopoverLines(item).join('\n')}}</div>
					</ng-template>
				</tr>
			</tbody>
		</table>
	</div>
</div>