import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { OrganizationGroupsService } from '../organization-groups.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { ProductsService } from '../../products/products.service';
import { AuthService } from 'client/app/services/auth.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-organization-group-form',
	templateUrl: './organization-group-form.component.html',
	styleUrls: ['./organization-group-form.component.scss']
})
export class OrganizationGroupFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	group: Models.OrganizationGroup;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	organizations: Models.Organization[] = [];

	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	availableProductTypes = AppConstants.productTypes;

	staffUserSelections: any[] = [];
	canSetManagers = false;

	counter = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private organizationGroupsService: OrganizationGroupsService,
		private organizationsService: OrganizationsService,
		private productsService: ProductsService,
		private usersService: UsersService,
		private platformsService: PlatformsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.group = this.organizationGroupsService.getOne(this.id);
				if (!this.group || this.group == null || this.group.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.organizationGroupsService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.group = new Models.OrganizationGroup(0, '', '', '', 1);
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.canSetManagers = authUser && ValidationTools.checkAccess(authUser, 'manage-organization-groups');

			if (this.editMode) {
				this.listSubscription = this.organizationGroupsService.groups.subscribe(groups => {
					this.group = groups.find((group: Models.OrganizationGroup) => group.id === this.id);
					this.initForm();
				});
			} else {
				this.initForm();
			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();
		this.organizations = this.organizationsService.getAll();

		const staff: Models.User[] = this.usersService.getForRole(AppConstants.staffUserRole);
		this.staffUserSelections = [];
		for (const user of staff) {
			if (user.is_enabled === 1 || this.group.manager_ids.includes(user.id)) {
				let nameToShow = user.name;
				if (user.is_enabled === 0) nameToShow += ' (disabled)';
				this.staffUserSelections.push({ id: user.id, name: nameToShow });
			} // if
		} // staff

		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.group.is_enabled),
			name: new UntypedFormControl(this.group.name, [Validators.required]),
			information: new UntypedFormControl(this.group.information),
			org_ids: new UntypedFormControl(this.group.org_ids),
			manager_ids: new UntypedFormControl(this.group.manager_ids)
		});

		// add form controls for each of the check boxes...
		this.counter = 0;
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.group.product_platforms, product.id, platform.id) !== -1;
					if (checked) this.counter++;
					this.theForm.addControl('pp_' + product.id + '_' + platform.id, new UntypedFormControl(checked));
				}
			}
		}

		this.setUpChecks(this.group.gtype);
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const preSaveOrgIds: number[] = this.group.org_ids.slice();

		this.group.is_enabled = this.theForm.value.is_enabled;
		this.group.gtype = '';
		this.group.name = this.theForm.value.name;
		this.group.information = this.theForm.value.information;
		this.group.org_ids = this.theForm.value.org_ids;
		if (this.canSetManagers)
			this.group.manager_ids = this.theForm.value.manager_ids;

		const productPlatforms: Models.ProductPlatform[] = [];
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const val = this.theForm.value['pp_' + product.id + '_' + platform.id];
					if (val) {
						productPlatforms.push({ product_id: product.id, platform_id: platform.id });
					}
				}
			}
		}
		this.group.product_platforms = productPlatforms;

		try {
			let retOrg: Models.OrganizationGroup;
			if (this.editMode)
				retOrg = await this.organizationGroupsService.updateOne(this.group);
			else
				retOrg = await this.organizationGroupsService.addOne(this.group);

			if (retOrg) {
				const orgIDsToUpdate = [];
				// find orgs that have been added
				for (const orgID of this.group.org_ids)
					if (preSaveOrgIds.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				// find orgs that have been removed
				for (const orgID of preSaveOrgIds)
					if (this.group.org_ids.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				for (const orgID of orgIDsToUpdate)
					this.organizationsService.refreshOne(orgID);

				// ******************************************
				this.router.navigate([AppConstants.urls.organizationgroups + '/' + retOrg.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	onTypeChange(e, gtype) {
		this.setUpChecks(gtype);
	}

	setUpChecks(gtype) {
		for (const productType of AppConstants.productTypes) {
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const controlName = 'pp_' + product.id + '_' + platform.id;
					const val = this.theForm.value[controlName];
					// console.log(controlName + ' - ' + val);

					// uncheck and disable ones that aren't available...
					const idx = this.availableProductTypes.indexOf(productType);
					if (idx === -1) {
						this.theForm.controls[controlName].setValue(false);
						this.theForm.controls[controlName].disable();
					} else {
						this.theForm.controls[controlName].enable();
					}
				}
			}
		}
	}

	isChecked(productID: number, platformID: number): boolean {
		// this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
		const val = this.theForm.value['pp_' + productID + '_' + platformID];
		return val;
	}

	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (!ValidationTools.hasFlag(product, 'private_only') && product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}

	adjustCounter(e) {
		if (e.target.checked) {
			this.counter++;
		} else {
			this.counter--;
		}
	}

	checkAllForProductType(e, productType) {
		// console.log('productType=' + productType);
		// console.log(e.target.checked);
		for (const product of this.subListOfProducts(productType)) {
			this.setValueForProduct(product.id, e.target.checked);
		}
	}

	checkAllForProduct(e, productID) {
		this.setValueForProduct(productID, e.target.checked);
	}

	setValueForProduct(productID: number, newValue: boolean) {
		// console.log('productID=' + productID);
		// console.log(newValue);
		for (const platform of this.subListOfPlatforms(productID)) {
			const curVal = this.theForm.value['pp_' + productID + '_' + platform.id];
			if (curVal !== newValue) {
				if (newValue) {
					this.counter++;
				} else {
					this.counter--;
				}
				this.theForm.controls['pp_' + productID + '_' + platform.id].setValue(newValue);
			} // if
		}
	}

	// get gtype() { return this.theForm.get('gtype'); }
	get name() { return this.theForm.get('name'); }

}
