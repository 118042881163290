<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.products" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Products</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ product.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canSendNotifications" type="button" class="btn btn-outline-primary" [routerLink]="['notify']"
					ngbTooltip="Notify" placement="bottom-end">
					<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger" data-bs-toggle="modal"
					data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="builds.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-builds-tab"
				aria-controls="nav-builds" data-bs-target="#nav-builds">
				<fa-icon [icon]="appConstants.sectionIcons.builds" size="sm"></fa-icon>
				Builds
				<span *ngIf="!loading || builds.length > 0" class="cp-tab-badge">
					{{ builds.length | number}}
				</span>
			</button>
		</li>

		<li *ngIf="documents.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-documents-tab"
				aria-controls="nav-documents" data-bs-target="#nav-documents">
				<fa-icon [icon]="appConstants.sectionIcons.documents" size="sm"></fa-icon>
				Documents
				<span *ngIf="!loading || documents.length > 0" class="cp-tab-badge">
					{{ documents.length | number}}
				</span>
			</button>
		</li>

		<li *ngIf="organizations.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-organizations-tab" aria-controls="nav-organizations" data-bs-target="#nav-organizations">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" size="sm"></fa-icon>
				Organizations
				<span *ngIf="!loading || organizations.length > 0" class="cp-tab-badge">
					{{ organizations.length | number}}
				</span>
			</button>
		</li>

		<li *ngIf="packages.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-packages-tab"
				aria-controls="nav-packages" data-bs-target="#nav-packages">
				<fa-icon [icon]="appConstants.sectionIcons.packages" size="sm"></fa-icon>
				Packages
				<span *ngIf="!loading || packages.length > 0" class="cp-tab-badge">
					{{ packages.length | number}}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!product" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ product.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Filename Prefix (for auto-build):</span>
					<span class="cp-property-value">{{ product.filename_prefix }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">{{ appConstants.productTypeLabels[product.ptype] }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!product.information && product.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ product.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!product.download_build_description && product.download_build_description !== ''">
					<span class="cp-property-label">Download List Description (Build):</span>
					<span class="cp-property-value cp-pre-text">{{ product.download_build_description }}</span>
				</div>

				<div class="cp-property-block"
					*ngIf="!!product.download_document_description && product.download_document_description !== ''">
					<span class="cp-property-label">Download List Description (Document):</span>
					<span class="cp-property-value cp-pre-text">{{ product.download_document_description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ product.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="product.added_on !== product.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ product.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="flagsToShow.length !== 0">
					<span class="cp-property-label">Special Flags:</span>
					<ul class="mb-0">
						<li *ngFor="let flagToShow of flagsToShow" class="cp-property-value">
							{{ flagToShow }}
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="platforms.length !== 0">
					<span class="cp-property-label">Platforms</span>
					<ul class="mb-0">
						<li *ngFor="let item of platforms">
							<a *ngIf="canEdit" [routerLink]="['/' + appConstants.urls.platforms, item.id]">
								<fa-icon [icon]="miscTools.getPlatformIcon(item.name)"></fa-icon>
								{{ item.name }}
							</a>
							<span *ngIf="!canEdit">
								<fa-icon [icon]="miscTools.getPlatformIcon(item.name)"></fa-icon>
								{{ item.name }}
							</span>
						</li>
					</ul>
				</div>
			</div>


			<button type="button" class="btn btn-outline-secondary btn-sm me-2 mb-1" (click)="openDownloadsReport()"
				placement="bottom-start" ngbTooltip="Get full record of all downloads of all build related files for this product">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Downloads Report
			</button>

		</div>

		<div class="tab-pane show" id="nav-builds" role="tabpanel" aria-labelledby="nav-builds-tab">
			<div *ngIf="!loading && builds.length === 0" class="cp-light-message">
				No Builds for this Product.
			</div>

			<app-builds-table #buildsTable1></app-builds-table>
		</div>

		<div class="tab-pane show" id="nav-documents" role="tabpanel" aria-labelledby="nav-documents-tab">
			<div *ngIf="!loading && documents.length === 0" class="cp-light-message">
				No Documents for this Product.
			</div>

			<app-documents-table #documentsTable1></app-documents-table>

		</div>

		<div class="tab-pane show" id="nav-organizations" role="tabpanel" aria-labelledby="nav-organizations-tab">
			<div *ngIf="!loading && organizations.length === 0" class="cp-light-message">
				No Organizations with access to this Product.
			</div>

			<app-organizations-table #orgsTable1></app-organizations-table>
		</div>

		<div class="tab-pane show" id="nav-packages" role="tabpanel" aria-labelledby="nav-packages-tab">
			<div *ngIf="!loading && packages.length === 0" class="cp-light-message">
				No Packages with access to this Product.
			</div>

			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class="cp-icon-header">
							<fa-icon [icon]="['fas', 'power-off']" size="sm" placement="end" ngbTooltip="Enabled/Disabled">
							</fa-icon>
						</th>
						<th>Name</th>
						<th>Type</th>
						<th>Platforms</th>
						<th class="cp-icon-header">
							<fa-icon [icon]="appConstants.sectionIcons.users" size="sm" ngbTooltip="Subscribed Users" placement="start">
							</fa-icon>
						</th>
					</tr>
				</thead>

				<tbody *ngIf="!!packages && packages.length !== 0">
					<tr *ngFor="let item of packages" [routerLink]="['/' + appConstants.urls.packages, item.id]" class="cp-pointer">
						<td class="cp-icon-cell text-center">
							<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
								ngbTooltip="Enabled - Downloads available" placement="end">
							</fa-icon>
							<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
								ngbTooltip="Disabled - Downloads not available" placement="end"></fa-icon>
						</td>
						<td class="cp-chopped-column-long">
							<a [routerLink]="['/' + appConstants.urls.packages, item.id]">{{ item.name }}</a>
						</td>
						<td class="cp-chopped-column">{{ appConstants.packageTypeLabels[item.ptype] }}</td>
						<td>
							<span
								*ngFor="let pl of miscTools.sortPlatforms(platforms, getPlatformIdsForProduct(id, item.product_platforms))">
								<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
								{{ pl.name }}&nbsp;
							</span>
						</td>
						<td class="text-center">{{ item.num_users | number }}</td>
					</tr>
				</tbody>

			</table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!product && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Product.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>


	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Product</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Product, it, along with all related builds and documents, will be permanently
						deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Product
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>