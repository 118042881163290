<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.bundles" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Shared Files</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				File Bundle
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="bundle" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + appConstants.urls.myfilesharing, bundle.id]"
					ngbTooltip="View the bundle same way a non-staff user sees it." placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					<span class="cp-action-button-text">User View</span>
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']"
					ngbTooltip="Edit" placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
				</button>

				<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)" [disabled]="loading">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh"
						placement="start"></fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>

			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab"
				aria-selected="true" id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log">
				<fa-icon [icon]="appConstants.sectionIcons.downloadlogs" size="sm"></fa-icon>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-admin-log-tab" aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<div *ngIf="bundle" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Type of Bundle:</span>
					<span class="cp-property-value">{{ bundle.sub_type | titlecase }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Sharing Mode:</span>
					<span class="cp-property-value cp-pre-text">
						{{ miscTools.fetchLabel(appConstants.bundleTypes, bundle.btype) }}
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.information && bundle.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ bundle.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-send')">
					<span class="cp-property-label">From:</span>
					<span *ngIf="bundle.btype.startsWith('staff-')" class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.users, bundle.staff_id]">
							{{ bundle.staff_name }}</a>
					</span>
					<span *ngIf="bundle.btype.startsWith('group-')" class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.usergroups, bundle.staff_id]">
							{{ bundle.staff_name }}</a>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-receive')">
					<span class="cp-property-label">From:</span>
					<span class="cp-property-value" *ngIf="bundle.org_id !== 0">
						<a [routerLink]="['/' + appConstants.urls.organizations, bundle.org_id]">
							{{ bundle.org_name }}
						</a>
					</span>
					<span class="cp-property-value" *ngIf="bundle.org_id === 0">
						<span *ngFor="let user of users; let i = index">
							<a [routerLink]="['/' + appConstants.urls.users, user.id]">
								{{ user.name }} ({{ user.org_name }})</a><span *ngIf="i < users.length - 1">;</span>
						</span>
						<!-- {{ bundle.user_names.join(', ') }} -->
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-send')">
					<span class="cp-property-label">To:</span>
					<span class="cp-property-value" *ngIf="bundle.org_id !== 0">
						<a [routerLink]="['/' + appConstants.urls.organizations, bundle.org_id]">
							{{ bundle.org_name }}
						</a>
					</span>
					<span class="cp-property-value" *ngIf="bundle.org_id === 0">
						<span *ngFor="let user of users; let i = index">
							<a [routerLink]="['/' + appConstants.urls.users, user.id]">
								{{ user.name }} ({{ user.org_name }})</a><span *ngIf="i < users.length - 1">;</span>
						</span>
						<!-- {{ bundle.user_names.join(', ') }} -->
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.btype.endsWith('-receive')">
					<span class="cp-property-label">To:</span>
					<span *ngIf="bundle.btype.startsWith('staff-')" class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.users, bundle.staff_id]">
							{{ bundle.staff_name }}</a>
					</span>
					<span *ngIf="bundle.btype.startsWith('group-')" class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.usergroups, bundle.staff_id]">
							{{ bundle.staff_name }}</a>
					</span>
				</div>

				<div class="cp-property-block">
					<span *ngIf="miscTools.hasExpired(bundle.lock_at)" class="cp-property-label">
						Locked
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="Files cannot be added to this bundle after this." placement="bottom-start">
						</fa-icon>:

					</span>

					<span *ngIf="!miscTools.hasExpired(bundle.lock_at)" class="cp-property-label">
						Locks
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="Files cannot be added to this bundle after this." placement="bottom-start">
						</fa-icon>:
					</span>

					<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(bundle.lock_at)}">
						{{ bundle.lock_at | date: appConstants.pageDateTimeFmt }}
						({{ textTools.niceDaysText(bundle.lock_at) }})
					</span>
				</div>

				<div *ngIf="!bundle.deleted_at" class="cp-property-block">
					<span class="cp-property-label">
						Clean Up
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="Files will be deleted at (or shortly after) this time."
							placement="bottom-start">
						</fa-icon>:
					</span>
					<span class="cp-property-value" [ngClass]="{'text-danger': miscTools.hasExpired(bundle.delete_at)}">
						{{ bundle.delete_at | date: appConstants.pageDateTimeFmt }}
						({{ textTools.niceDaysText(bundle.delete_at) }})
					</span>
				</div>

				<div *ngIf="bundle.deleted_at" class="cp-property-block">
					<span class="cp-property-label">
						Files Deleted
						<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Files were deleted at this time."
							placement="bottom-start">
						</fa-icon>:
					</span>
					<span class="cp-property-value"
						[ngClass]="{'text-danger': miscTools.hasExpired(bundle.deleted_at)}">
						{{ bundle.deleted_at | date: appConstants.pageDateTimeFmt }}
						({{ textTools.niceDaysText(bundle.deleted_at) }})
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ bundle.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundle.added_on !== bundle.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ bundle.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div *ngIf="bundle.notified_at" class="cp-property-block">
					<span class="cp-property-label">
						Notified
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="An e-mail notification was sent to the recipients of this bundle."
							placement="bottom-start">
						</fa-icon>:
					</span>
					<span class="cp-property-value">
						{{ bundle.notified_at | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<div *ngIf="bundle.alerted_at" class="cp-property-block">
					<span class="cp-property-label">
						Warning Sent
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="An e-mail notification was sent to the creator of this bundle warning them that the recpients have not downloaded and of the files."
							placement="bottom-start">
						</fa-icon>:
					</span>
					<span class="cp-property-value">
						{{ bundle.alerted_at | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<hr />
				<div class="cp-property-block">
					<span class="cp-property-label">Files:</span>
					<div *ngFor="let file of bundle.files" class="mt-1">
						<span class="fw-bold">{{ file.name }}</span> {{ file.size | bytes: "1.0-0" }}
					</div>
					<div *ngIf="bundle.files.length === 0" class="font-italic">
						None
					</div>

					<button *ngIf="canAddFiles" type="button" class="btn btn-sm btn-outline-primary mt-1"
						[routerLink]="['/' + appConstants.urls.myfilesharing, this.id, 'files']">
						<fa-icon [icon]="['fas', 'plus']"></fa-icon>
						Add Files
					</button>

				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">

			<div *ngIf="!loading && downloadLogs.length === 0" class="cp-light-message">
				No download logs for this bundle.
			</div>

			<app-downloads-table #downloadsTable1></app-downloads-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && adminLogs.length === 0" class="cp-light-message">
				No system/change Logs for this bundle.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>

</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this File Bundle</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
					id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this File Bundle, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control"
							required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()"
							class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this File Bundle
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>