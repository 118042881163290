import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { UsersService } from 'client/app/components/app-sections/users/users.service';
import { AdminLogsService } from 'client/app/components/app-sections/admin-logs/admin-logs.service';

@Component({
	selector: 'app-journals-table',
	templateUrl: './journals-table.component.html',
	styleUrls: ['./journals-table.component.scss']
})
export class JournalsTableComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	/*
		pass in
			logs 
			storageKey
			options
			- showUserInfo : true
			- linkUser: false
	
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;
	items: Models.JournalEntry[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminLogsService: AdminLogsService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.JournalEntry[]) {
		this.loading = true;

		this.items = items;

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	// ------------------------------------------------------------------------
	canDeleteJournalEntry(journal: Models.JournalEntry): boolean {
		return this.authUser.id === journal.added_by || this.authUser.role === AppConstants.adminUserRole;
	}

	// ------------------------------------------------------------------------
	async deleteJournalEntry(journal: Models.JournalEntry) {
		if (confirm('Are you sure you want to delete this journal entry?')) {
			try {
				// this.saving = true;
				await this.adminLogsService.deleteJournal(journal.id);
				this.uiAlertsService.addMsg('The journal entry has been deleted.', 'success', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				const idx = MiscTools.findIndex(this.items, journal.id);
				if (idx !== -1) this.items.splice(idx, 1);
			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

}
