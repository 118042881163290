import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { OrganizationsService } from '../organizations.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-set-billing-code-admins-form',
	templateUrl: './set-billing-code-admins-form.component.html',
	styleUrls: ['./set-billing-code-admins-form.component.scss']
})
export class SetBillingCodeAdminsFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	id: number; // activation ID
	organization: Models.Organization;

	// 'standard' edit stuff

	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	orgUserSelections: any[] = [];

	usersWithAdminAccess: Models.User[] = [];

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private organizationsService: OrganizationsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});

	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;
			this.loadData();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		if (!ValidationTools.checkAccess(this.authUser, 'manage-billing-codes')) {
			this.onCancel();
			return;
		};

		this.organization = await this.organizationsService.getOne(this.id);
		if (!this.organization || this.organization.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		if (!this.organization.billing_code_admins) this.organization.billing_code_admins = [];

		const orgUsers: Models.User[] = this.usersService.getUsersForOrganization(this.id);
		this.orgUserSelections = [];
		for (const u of orgUsers)
			if (u.is_enabled === 1 || this.organization.billing_code_admins.includes(u.id))
				this.orgUserSelections.push({ id: u.id, name: u.name });

		this.theForm = new UntypedFormGroup({
			userIDs: new UntypedFormControl(this.organization.billing_code_admins, [])
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		const errors: string[] = [];

		let userIDs: number[] = this.theForm.value.userIDs;
		if (!userIDs) userIDs = [];

		// if (this.errors.length > 0) {
		// 	this.uiAlertsService.addMsg('Problems were found with this key.  See the \'Errors\' tab for more details,',
		// 		'error', 'no-edit', false, AppConstants.standardMessageAutoCloseTimeSecs);
		// 	this.saving = false;
		// 	return;
		// }

		if (errors.length > 0) {
			this.uiAlertsService.addMsgs(errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			await this.organizationsService.setBillingCodeAdmins(this.id, userIDs);
			this.onCancel();
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['/' + AppConstants.urls.organizations, this.id]);
	}

}
