import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { SystemMessagesService } from '../system-messages.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-system-message',
	templateUrl: './system-message.component.html',
	styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	miscTools = MiscTools;

	// 'standard' view stuff
	id: number;
	systemMessage: Models.SystemMessage;
	loading = true;
	canManage = true;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;
	choppedMessage = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private systemMessagesService: SystemMessagesService,
		private usersService: UsersService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.systemMessage = this.systemMessagesService.getOne(this.id);
			if (!this.systemMessage || this.systemMessage == null || this.systemMessage.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.choppedMessage = TextTools.chopString(this.systemMessage.message, 50);
				this.systemMessagesService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		this.listSubscription = this.systemMessagesService.messages.subscribe(messages => {
			this.systemMessage = messages.find((message: Models.SystemMessage) => message.id === this.id);
		});

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canManage = (authUser && ValidationTools.checkAccess(authUser, 'manage-system-messages'));
		});

		this.addedByUser = null;
		if (this.systemMessage.added_by && this.systemMessage.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.systemMessage.added_by);

		this.editedByUser = null;
		if (this.systemMessage.edited_by && this.systemMessage.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.systemMessage.edited_by);
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async delete() {
		if (confirm('Are you sure you want to delete this Message?')) {
			// Unsubscribe from list before delete or it will attempt to find now deleted item before navigation away
			if (this.listSubscription) this.listSubscription.unsubscribe();

			const result = await this.systemMessagesService.deleteOne(this.systemMessage.id);
			if (result) {
				this.uiAlertsService.addMsg('The message has been deleted.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.router.navigate([AppConstants.urls.systemmessages]);
			} else {
				return false;
			}
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Message?')) {
			const result = await this.systemMessagesService.toggleEnabled(this.systemMessage.id);
			if (result) {
				this.systemMessage = result;
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Message?')) {
			const result = await this.systemMessagesService.toggleEnabled(this.systemMessage.id);
			if (result) {
				this.systemMessage = result;
			} else {
				return false;
			}
		}
	}
}
