<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.systemmessages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Dashboard Messages
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-1" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Message</span>
			</button>

			<button *ngIf="canSendNotifications" type="button" class="btn btn-outline-primary me-1" [routerLink]="['notify']"
				ngbTooltip="Notify" placement="bottom-end">
				<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>

		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [ngClass]="{'text-danger': item.is_enabled === 0}">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success" ngbTooltip="Enabled"
						placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger" ngbTooltip="Disabled"
						placement="end"></fa-icon>
				</td>

				<td class="cp-icon-cell">
					<span *ngIf="item.start_at || item.end_at">
						<fa-icon *ngIf="inDisplayWindow(item)" [icon]="['fas', 'calendar-alt']" size="sm" class="text-success"
							placement="end" ngbTooltip="In window">
						</fa-icon>
						<fa-icon *ngIf="!inDisplayWindow(item)" [icon]="['fas', 'calendar-alt']" size="sm" class="text-danger"
							placement="end" ngbTooltip="Before or after window">
						</fa-icon>
					</span>
				</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.url !== ''" [icon]="['fas', 'link']" size="sm" class="text-success" placement="end"
						ngbTooltip="Has 'More Info' Link">
					</fa-icon>
				</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.staff_only === 1" [icon]="['fas', 'lock']" size="sm" class="text-warning" placement="end"
						ngbTooltip="Staff Only">
					</fa-icon>
				</td>

				<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.added_on)">
					{{ item.added_on | date: appConstants.tableDateFmt }}
				</td>
				<td class="text-nowrap" [ngClass]="{'text-danger': !miscTools.hasExpired(item.start_at) }">
					{{ item.start_at | date: appConstants.tableDateFmt }}
				</td>
				<td class="text-nowrap" [ngClass]="{'text-danger': miscTools.hasExpired(item.end_at) }">
					{{ item.end_at | date: appConstants.tableDateFmt }}
				</td>

				<td><a [routerLink]="[item.id]">{{ chopMessage(item) }}</a></td>
			</tr>
		</tbody>
	</table>
</div>