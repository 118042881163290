<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Users</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ user.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!user">
				<button *ngIf="canEdit && user.is_enabled === 1 && user.use_sso === 0" type="button" class="btn btn-outline-primary"
					(click)="updatePasswordResetToken()" ngbTooltip="This will send the user a new password reset e-mail"
					placement="bottom-end">
					<fa-icon [icon]="['fas', 'passport']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Reset Password</span>
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canManageAccess" type="button" class="btn btn-outline-primary" [routerLink]="['access']" ngbTooltip="Access"
					placement="bottom-end">
					<fa-icon icon="user-lock" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Access</span> -->
				</button>

				<button *ngIf="canEdit && user.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					ngbTooltip="Disabling a user will prevent them from logging in" placement="bottom-end">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && user.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="bottom-end">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm && user.is_deleted === 0" type="button" class="btn btn-outline-danger"
					data-bs-toggle="modal" data-bs-target="#deleteModal" ngbTooltip="Delete" placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>

				<button *ngIf="canDelete && user.is_deleted === 1" type="button" class="btn btn-outline-success" (click)="recover()">
					Recover
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="user.notifications || canSetupSalesEngineerNotifications || canSetupAccountOwnerNotifications" class="nav-item"
			role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-notifications-tab" aria-controls="nav-notifications" data-bs-target="#nav-notifications"
				ngbTooltip="Notification Settings">
				<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
				Notification
			</button>
		</li>

		<li *ngIf="techRepOrgs.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-techrep-tab"
				aria-controls="nav-techrep" data-bs-target="#nav-techrep" ngbTooltip="Organizations linked as SE/Tech-Rep">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" size="sm"></fa-icon>
				SE/Tech-Rep
				<span *ngIf="!loading || techRepOrgs.length > 0" class="cp-tab-badge">
					{{ techRepOrgs.length | number }}
				</span>
			</button>
		</li>
		<li *ngIf="accountOwnerOrgs.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-accountowner-tab" aria-controls="nav-accountowner" data-bs-target="#nav-accountowner"
				ngbTooltip="Organizations linked as Account Owner">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" size="sm"></fa-icon>
				Acct. Owner
				<span *ngIf="!loading || accountOwnerOrgs.length > 0" class="cp-tab-badge">
					{{ accountOwnerOrgs.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-keys-tab"
				aria-controls="nav-keys" data-bs-target="#nav-keys"
				ngbTooltip="License Keys shared with this that are enabled and haven't expired">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Keys
				<span *ngIf="!loading || userKeys.length > 0" class="cp-tab-badge">
					{{ userKeys.length | number }}
				</span>
			</button>
		</li>
		<li *ngIf="userKeys2.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-keys-obsolete-tab" aria-controls="nav-keys-obsolete" data-bs-target="#nav-keys-obsolete"
				ngbTooltip="License Keys shared with this that are disabled and/or have expired">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Keys<sup class="me-1">2</sup>
				<span *ngIf="!loading || userKeys2.length > 0" class="cp-tab-badge">
					{{ userKeys2.length | number }}
				</span>
			</button>
		</li>
		<li *ngIf="userHostIdLabels && userHostIdLabels.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-hostids-tab"
				aria-controls="nav-hostids" data-bs-target="#nav-hostids" ngbTooltip="Host ID Labels">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Host IDs
				<span *ngIf="!loading || userHostIdLabels.length > 0" class="cp-tab-badge">
					{{ userHostIdLabels.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-builds-tab" aria-controls="nav-downloads-builds" data-bs-target="#nav-downloads-builds"
				ngbTooltip="Software Builds Available to this User">
				<fa-icon [icon]="appConstants.sectionIcons.mybuilds" size="sm"></fa-icon>
				SW
				<span *ngIf="!hasAccessToAllBuildsAndDocs && (!loading || buildDownloads.length > 0)" class="cp-tab-badge">
					{{ buildDownloads.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-documents-tab" aria-controls="nav-downloads-documents" data-bs-target="#nav-downloads-documents"
				ngbTooltip="Documentation Available to this User">
				<fa-icon [icon]="appConstants.sectionIcons.mydocuments" size="sm"></fa-icon>
				Docs
				<span *ngIf="!hasAccessToAllBuildsAndDocs && (!loading || documentDownloads.length > 0)" class="cp-tab-badge">
					{{ documentDownloads.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-logins-tab"
				aria-controls="nav-logins" data-bs-target="#nav-logins" ngbTooltip="Login History">
				<fa-icon [icon]="appConstants.sectionIcons.loginlogs" size="sm"></fa-icon>
				<!-- Logins -->
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log" ngbTooltip="Download History">
				<fa-icon [icon]="appConstants.sectionIcons.downloadlogs" size="sm"></fa-icon>
				<!-- Downloads -->
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-link-log-tab"
				aria-controls="nav-link-log" data-bs-target="#nav-link-log" ngbTooltip="Link Click History">
				<fa-icon [icon]="appConstants.sectionIcons.linklogs" size="sm"></fa-icon>
				<!-- Clicks -->
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-activity-log-tab" aria-controls="nav-activity-log" data-bs-target="#nav-activity-log"
				ngbTooltip="User's Portal Activity">
				<fa-icon [icon]="['fas', 'cog']" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-notify-log-tab"
				aria-controls="nav-notify-log" data-bs-target="#nav-notify-log" ngbTooltip="Notification History">
				<fa-icon [icon]="appConstants.sectionIcons.emailqueue" size="sm"></fa-icon>
				<!-- Notifications -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal" ngbTooltip="Journal Entries">
				<fa-icon [icon]="appConstants.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log" ngbTooltip="History/Change Log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div *ngIf="!!user && onboardingStatus && onboardingStatus.length !== 0" class="cp-warning-message ms-3 mt-2 mb-0">
		<div *ngFor="let item of onboardingStatus">
			<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
			{{ item }}
		</div>
	</div>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<!-- <div *ngIf="cleanupInfo" class="cp-info-message cp-pre-text">
				{{ cleanupInfo | json }}
				
			</div> -->
			<div *ngIf="!!user && cleanupWarning !== ''" class="cp-warning-message cp-pre-text">
				{{ cleanupWarning }}
			</div>

			<div *ngIf="!!user && possibleIssues && possibleIssues.length !== 0" class="cp-info-message">
				Possible Issues: <fa-icon [icon]="['fas', 'question-circle']"
					ngbTooltip="In come cases, these items/issues may be intentional based on cultural norms." placement="end"></fa-icon>

				<div *ngFor="let item of possibleIssues">
					<!-- <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon> -->
					{{ item }}
				</div>
			</div>

			<div *ngIf="!!user" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="user.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled - login
						permitted
					</span>
					<span *ngIf="user.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - login not
						permitted
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ user.name }}</span>
				</div>

				<div class="cp-property-block" *ngIf="organization">
					<span class="cp-property-label">Organization:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.organizations, organization.id]" [ngbPopover]="organizationPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ organization.name }}
						</a> ({{ appConstants.organizationTypeLabels[organization.otype] }})
						<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
							ngbTooltip="Organization is not enabled"></fa-icon>

						<ng-template #organizationPopupContent>
							<div class="cp-popover-banner">{{ organization.name }}</div>
							<div class="cp-pre-text">{{
								popOverTools.getOrganizationPopoverLines(organization).join('\n')}}</div>
						</ng-template>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!organization && potentialOrgs.length > 0">
					<span class="cp-property-label">Possible Organization(s)
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="Based on the user's e-mail domain, they might be part of one of these organizations. If you want to link this user to one of these organizations, edit the user and select the organization."
							placement="bottom-start">
						</fa-icon>:
					</span>
					<span class="cp-property-value">
						<ul class='mb-0'>
							<li *ngFor="let possOrg of potentialOrgs">
								<a [routerLink]="['/' + appConstants.urls.organizations, possOrg.id]"
									[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
									container="body">
									{{ possOrg.name }}
								</a> ({{ appConstants.organizationTypeLabels[possOrg.otype] }})
								<fa-icon *ngIf="possOrg.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
									ngbTooltip="Organization is not enabled"></fa-icon>

								<ng-template #organizationPopupContent>
									<div class="cp-popover-banner">{{ possOrg.name }}</div>
									<div class="cp-pre-text">{{
										popOverTools.getOrganizationPopoverLines(possOrg).join('\n')}}</div>
								</ng-template>
							</li>
						</ul>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Role:</span>
					<span class="cp-property-value">
						<fa-icon [icon]="appConstants.userRoleIcons[user.role]"></fa-icon>
						{{ appConstants.userRoleLabels[user.role] }}
					</span>
					<div *ngIf="canPromote">
						<button type="button" class="btn btn-outline-info btn-sm me-1" (click)="promoteBasicToStaff()">
							Promote to Staff
						</button>
					</div>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">E-Mail:</span>
					<span class="cp-property-value">
						<a href="mailto:{{ user.email }}">{{ user.email }}</a>
						&nbsp;<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(user.email)"
							[cbContent]="user.email" ngbTooltip="Copy to Clipboard"></fa-icon></span>
				</div>

				<div class="cp-property-block" *ngIf="user.use_sso === 1">
					<span class="cp-property-label">Uses SSO:</span>
					<span class="cp-property-value">Yes</span>
				</div>

				<div class="cp-property-block" *ngIf="!!user.title && user.title !== ''">
					<span class="cp-property-label">Title:</span>
					<span class="cp-property-value">{{ user.title }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!user.information && user.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ user.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="salesforceContactID !== '' || (canEdit && showLinkToSalesforce)">
					<span class="cp-property-label">Salesforce Contact:</span>
					<span *ngIf="salesforceContactID !== ''" class="cp-property-value">
						<a *ngIf="salesForceUrl !== ''" target="_blank"
							href="{{ salesForceUrl }}/lightning/r/Contact/{{ salesforceContactID }}/view">
							{{ salesforceContactID }}
						</a>
						<!-- <button *ngIf="salesforceContactInfoBlock === ''" type="button"
							class="btn btn-outline-info btn-sm m-1" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</button> -->

						<span *ngIf="salesforceContactInfoBlock === ''" class="text-success cp-pointer" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</span>

						<div *ngIf="salesforceContactInfoBlock !== ''"
							class="border border-dark p-2 ms-4 me-4 mb-2 cp-pre-text cp-general-width-limit">
							<span class="float-end cp-pointer">
								&nbsp; <fa-icon (click)="salesforceContactInfoBlock = ''" [icon]="['fas', 'times-circle']"></fa-icon>
							</span>
							{{ salesforceContactInfoBlock }}
						</div>

					</span>
					<button *ngIf="showLinkToSalesforce" type="button" class="btn btn-outline-info btn-sm mb-1"
						(click)="linkToSalesForce()">
						<fa-icon [icon]="['fas', 'link']" class=''></fa-icon> Link Contact
					</button>
				</div>

				<div class="cp-property-block" *ngIf="salesforceLeadID !== '' && salesforceContactID === ''">
					<span class="cp-property-label">Salesforce Lead:</span>
					<span class="cp-property-value">
						<a *ngIf="salesForceUrl !== ''" target="_blank"
							href="{{ salesForceUrl }}/lightning/r/Lead/{{ salesforceLeadID }}/view">
							{{ salesforceLeadID }}
						</a>

						<!-- <button *ngIf="salesforceLeadInfoBlock === ''" type="button"
							class="btn btn-outline-info btn-sm m-1" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</button> -->

						<span *ngIf="salesforceLeadInfoBlock === ''" class="text-success cp-pointer" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</span>

						<div *ngIf="salesforceLeadInfoBlock !== ''"
							class="border border-dark p-2 ms-4 me-4 mb-2 cp-pre-text cp-general-width-limit">
							<span class="float-end cp-pointer">
								&nbsp; <fa-icon (click)="salesforceLeadInfoBlock = ''" [icon]="['fas', 'times-circle']">
								</fa-icon>
							</span>
							{{ salesforceLeadInfoBlock }}
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.last_login">
					<span class="cp-property-label">Last Login:</span>
					<span class="cp-property-value">
						{{ user.last_login | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block" *ngIf="showWelcome && canEdit">
					<span class="cp-property-label">Last Login:</span>
					<span class="cp-property-value">
						<button type="button" class="btn btn-outline-info btn-sm" (click)="sendWelcomeMessage()">
							<fa-icon [icon]="['fas', 'envelope']" class=''></fa-icon> Send Welcome E-Mail
						</button>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.last_download">
					<span class="cp-property-label">Last Download:</span>
					<span class="cp-property-value">
						{{ user.last_download | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.reset_password_expires">
					<span class="cp-property-label">Password Reset Token Expiry:</span>
					<span class="cp-property-value">
						{{ user.reset_password_expires | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="showExpiredPasswordToken" class="text-warning">EXPIRED</span>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ user.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.added_on !== user.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ user.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="flagsToShow.length !== 0">
					<span class="cp-property-label">Special Flags:</span>
					<ul class="mb-0">
						<li *ngFor="let flagToShow of flagsToShow" class="cp-property-value">
							{{ flagToShow }}
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="managerGroups && managerGroups.length !== 0">
					<span class="cp-property-label">User Groups (manager):</span>
					<ul class='mb-0'>
						<li *ngFor="let grp of managerGroups" class="cp-property-value">
							<fa-icon *ngIf="grp.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.usergroups, grp.id]">{{ grp.name }}</a>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="userGroups && userGroups.length !== 0">
					<span class="cp-property-label">User Groups (member):</span>
					<ul class='mb-0'>
						<li *ngFor="let grp of userGroups" class="cp-property-value">
							<fa-icon *ngIf="grp.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.usergroups, grp.id]">{{ grp.name }}</a>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="packages && packages.length !== 0">
					<span class="cp-property-label">Packages:</span>
					<span class="cp-property-value">
						<ul class='mb-0'>
							<li *ngFor="let pkg of packages">
								<fa-icon *ngIf="pkg.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
									ngbTooltip="Package is not enabled"></fa-icon>
								<a [routerLink]="['/' + appConstants.urls.packages, pkg.id]">{{ pkg.name }}</a>
							</li>
						</ul>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.directAccessTags && user.directAccessTags.length !== 0">
					<span class="cp-property-label">
						Access (assigned):
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showAccessTags = !showAccessTags">
							<fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
							{{ (showAccessTags ? 'Hide' : 'Show') }} Access - {{ user.directAccessTags.length }}
						</button>
					</span>
					<span *ngIf="showAccessTags" class="cp-property-value">
						<div *ngFor="let grouping of getGroupings(assignedTagGroupings)" class='ms-4'>
							{{ grouping }}
							<ul class='mb-0'>
								<li *ngFor="let tag of assignedTagGroupings[grouping]">
									{{ appConstants.accessControlTags[tag] }}
								</li>
							</ul>
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.roleAccessTags && user.roleAccessTags.length !== 0">
					<span class="cp-property-label">
						Access (role):
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showAccessTags = !showAccessTags">
							<fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
							{{ (showAccessTags ? 'Hide' : 'Show') }} Access - {{ user.roleAccessTags.length }}
						</button>
					</span>
					<span *ngIf="showAccessTags" class="cp-property-value">
						<div *ngFor="let grouping of getGroupings(roleTagGroupings)" class='ms-4'>
							{{ grouping }}
							<ul class='mb-0'>
								<li *ngFor="let tag of roleTagGroupings[grouping]">
									{{ appConstants.accessControlTags[tag] }}
								</li>
							</ul>
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="user.groupAccessTags && user.groupAccessTags.length !== 0">
					<span class="cp-property-label">
						Access (group):
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showAccessTags = !showAccessTags">
							<fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
							{{ (showAccessTags ? 'Hide' : 'Show') }} Access - {{ user.groupAccessTags.length }}
						</button>
					</span>
					<span *ngIf="showAccessTags" class="cp-property-value">
						<div *ngFor="let grouping of getGroupings(userGroupTagGroupings)" class='ms-4'>
							{{ grouping }}
							<ul class='mb-0'>
								<li *ngFor="let tag of userGroupTagGroupings[grouping]">
									{{ appConstants.accessControlTags[tag] }}
								</li>
							</ul>
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="userTemplates && userTemplates.length !== 0">
					<span class="cp-property-label">
						License Key Templates (user):
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showUserTemplates = !showUserTemplates">
							<fa-icon [icon]="appConstants.sectionIcons.licensingadmin"></fa-icon>
							{{ (showUserTemplates ? 'Hide' : 'Show') }} Templates - {{ userTemplates.length }}
						</button>
					</span>
					<span *ngIf="showUserTemplates" class="cp-property-value">
						<div *ngFor="let template of userTemplates" class='ms-4'>
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'templates', template.id]"
								[ngbPopover]="templatePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">
								{{ template.name }}
							</a>
							<ng-template #templatePopupContent>
								<div class="cp-popover-banner">{{ template.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
							</ng-template>
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="groupTemplates && groupTemplates.length !== 0">
					<span class="cp-property-label">
						License Key Templates (group):
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showGroupTemplates = !showGroupTemplates">
							<fa-icon [icon]="appConstants.sectionIcons.licensingadmin"></fa-icon>
							{{ (showGroupTemplates ? 'Hide' : 'Show') }} Templates - {{ groupTemplates.length }}
						</button>
					</span>
					<span *ngIf="showGroupTemplates" class="cp-property-value">
						<div *ngFor="let template of groupTemplates" class='ms-4'>
							<a [routerLink]="['/' + appConstants.urls.licensingadmin, 'templates', template.id]"
								[ngbPopover]="templatePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">
								{{ template.name }}
							</a>
							<ng-template #templatePopupContent>
								<div class="cp-popover-banner">{{ template.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div>
							</ng-template>
						</div>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">
						Mail Notification Settings:
					</span>
					<span *ngIf="user.notifications" class="cp-property-value">
						Setup - See Notifications tab for details.
					</span>
					<span *ngIf="!user.notifications" class="cp-property-value">
						This is user has not setup e-mail notifications.
						<button *ngIf="canManageNotifications && user.last_login" type="button" class="btn btn-outline-primary btn-sm"
							(click)="setNotificationsToDefaults()">
							<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
							Setup with Default Configuration
						</button>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="savedSearches && savedSearches.length !== 0">
					<span class="cp-property-label">
						Saved License Key Searches:
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showSavedSearches = !showSavedSearches">
							<fa-icon [icon]="appConstants.sectionIcons.licensing"></fa-icon>
							{{ (showSavedSearches ? 'Hide' : 'Show') }} Searches - {{ savedSearches.length }}
						</button>
					</span>
					<span *ngIf="showSavedSearches" class="cp-property-value">
						<ul class='mb-0'>
							<li *ngFor="let item of savedSearches">
								<a [routerLink]="['/' + appConstants.urls.licensing, 'saved', item.id]">
									{{ item.name }}
								</a>
								<!-- <a href="javascript:void(0)" (click)="runSavedSearch(item.parameters)">
									{{ item.name }}</a> -->
							</li>
						</ul>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="bundles && bundles.length !== 0">
					<span class="cp-property-label">
						Active File Sharing Bundles:
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showBundles = !showBundles">
							<fa-icon [icon]="appConstants.sectionIcons.myfilesharing"></fa-icon>
							{{ (showBundles ? 'Hide' : 'Show') }} Bundles - {{ bundles.length }}
						</button>
					</span>
					<span *ngIf="showBundles" class="cp-property-value">
						<ul class='mb-0'>
							<li *ngFor="let item of bundles">
								<a [routerLink]="['/' + appConstants.urls.myfilesharing, item.id]">
									{{ item.added_on | date: appConstants.pageDateTimeFmt }}
									<span *ngIf="item.information && item.information !== ''">
										: {{ item.information }}
									</span>
								</a><br />
								<span *ngIf="item.btype.endsWith('-send')">
									From : {{ item.staff_name }}<br />
									<span *ngIf="item.org_id !== 0">
										To : {{ item.org_name }}
									</span>
									<span *ngIf="item.org_id === 0">
										To : {{ item.user_names.join(', ') }}
									</span>
								</span>

								<span *ngIf="item.btype.endsWith('-receive')">
									<span *ngIf="item.org_id !== 0">
										From : {{ item.org_name }}
									</span>
									<span *ngIf="item.org_id === 0">
										From : {{ item.user_names.join(', ') }}
									</span>
									<br />
									To : {{ item.staff_name }}
								</span>
								<br />{{ item.files.length }} file(s)<br />
							</li>
						</ul>
					</span>
				</div>
			</div>

			<div *ngIf="zenStaffAccessLevel && zenStaffAccessLevel !== ''" class="cp-property-list">
				<span class="cp-property-label">
					ZEN Master Support Access:
				</span>
				<span class="cp-property-value">{{ zenStaffAccessLabel }}</span>

				<div *ngIf="!loading && zenStaffSettableLevels && zenStaffSettableLevels.length > 0">
					<!-- <fa-icon [icon]="['fas', 'user-lock']"></fa-icon> -->
					<button *ngFor="let zenStaffSettableLevel of zenStaffSettableLevels" type="button"
						class="btn btn-outline-info btn-sm me-1" (click)="changeZenSupportLevel(zenStaffSettableLevel.level)">
						Change to <strong>{{ zenStaffSettableLevel.label }}</strong>
					</button>
				</div>
			</div>

			<div *ngIf="!!user && regPropsToShow.length > 0" class="cp-property-list">
				<div class="fw-bold">User Registration Information</div>

				<div *ngFor="let prop of regPropsToShow" class="cp-property-block">
					<span class="cp-property-label" style="text-transform: capitalize">
						{{ prop.label }}:
					</span>
					<span class="cp-property-value">{{ prop.value }}</span>
				</div>
			</div>

			<div *ngIf="canImpersonate">
				<button type="button" class="btn btn-outline-primary" (click)="impersonate()">
					Impersonate
				</button>
			</div>
		</div>

		<div class="tab-pane" id="nav-notifications" role="tabpanel" aria-labelledby="nav-notifications-tab">

			<div *ngIf="canSetupSalesEngineerNotifications || canSetupAccountOwnerNotifications" class="cp-property-block">
				<button *ngIf="canSetupSalesEngineerNotifications" type="button" class="btn btn-outline-primary btn-sm me-1"
					(click)="setupUserForSalesEngineerNotifications()"
					ngbTooltip="Create a saved license key search for keys where this user is the key's organization's sales engineer and the key may have an issue (expiring soon, near/over meter max/projected) then setup this user to receive that saved search as an e-mail notification.">
					<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
					Setup Sales Engineer Key Notifications
				</button>

				<button *ngIf="canSetupAccountOwnerNotifications" type="button" class="btn btn-outline-primary btn-sm"
					(click)="setupUserForAccountOwnerNotifications()"
					ngbTooltip="Create a saved license key search for keys where this user is the key's organization's account owner and the key may have an issue (expiring soon, near/over meter max/projected) then setup this user to receive that saved search as an e-mail notification.">
					<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
					Setup Account Owner Key Notifications
				</button>
			</div>


			<div *ngIf="user.notifications">
				<button *ngIf="canManageNotifications" type="button" class="btn btn-outline-primary btn-sm me-1"
					[routerLink]="['notifications']" placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					Edit User's Notification Settings
				</button>

				<button *ngIf="canManageNotifications" type="button" class="btn btn-outline-danger btn-sm"
					(click)="unsubscribeFromAllNotifications()">
					<fa-icon [icon]="['fas', 'ban']"></fa-icon>
					Unsubscribe from All
				</button>

				<div>Types of Notifications</div>

				<div class="ms-4">

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.autoSubscribe }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="autoSubscribePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.autoSubscribe != null" class="cp-property-value">
							{{ user.notifications.autoSubscribe.toString() | titlecase }}
						</span>
						<ng-template #autoSubscribePopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.autoSubscribe }}</div>
							<div class="cp-pre-text">{{ appConstants.notificationPropInfo.autoSubscribe }}
							</div>
						</ng-template>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveGenerallMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveGenerallMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveGenerallMessages != null" class="cp-property-value">
							{{ user.notifications.receiveGenerallMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveGenerallMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveGenerallMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveGenerallMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveBuildMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveBuildMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveBuildMessages != null" class="cp-property-value">
							{{ user.notifications.receiveBuildMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveBuildMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveBuildMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveBuildMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2"
						*ngIf="user.notifications.receiveBuildMessages && user.notifications.includedBuildProductIDs.length > 0">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.includedBuildProductIDs }}:
						</span>
						<span class="cp-property-value">
							{{ showProductNames(user.notifications.includedBuildProductIDs) }}
						</span>
					</div>

					<div class="cp-property-block ms-2"
						*ngIf="user.notifications.receiveBuildMessages && user.notifications.excludedBuildProductIDs.length > 0">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.excludedBuildProductIDs }}:
						</span>
						<span class="cp-property-value">
							{{ showProductNames(user.notifications.excludedBuildProductIDs) }}
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveDocumentMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveDocumentMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveDocumentMessages != null" class="cp-property-value">
							{{ user.notifications.receiveDocumentMessages.toString() | titlecase}}
						</span>
						<ng-template #receiveDocumentMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveDocumentMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveDocumentMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2"
						*ngIf="user.notifications.receiveDocumentMessages && user.notifications.includedDocumentProductIDs.length > 0">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.includedDocumentProductIDs }}:
						</span>
						<span class="cp-property-value">
							{{ showProductNames(user.notifications.includedDocumentProductIDs) }}
						</span>
					</div>

					<div class="cp-property-block ms-2"
						*ngIf="user.notifications.receiveDocumentMessages && user.notifications.excludedDocumentProductIDs.length > 0">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.excludedDocumentProductIDs }}:
						</span>
						<span class="cp-property-value">
							{{ showProductNames(user.notifications.excludedDocumentProductIDs) }}
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveKeyExpirationMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyExpirationMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveKeyExpirationMessages != null" class="cp-property-value">
							{{ user.notifications.receiveKeyExpirationMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveKeyExpirationMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveKeyExpirationMessages
								}}</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveKeyExpirationMessages }}
							</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.receiveKeyExpirationMessages">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.expirationDays }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.expirationDays }} days before expiration
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveKeyUsageMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveKeyUsageMessages != null" class="cp-property-value">
							{{ user.notifications.receiveKeyUsageMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveKeyUsageMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveKeyUsageMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveKeyUsageMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.receiveKeyUsageMessages">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.usagePercentage }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.usagePercentage }}%
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveProjectedKeyUsageMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProjectedKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveProjectedKeyUsageMessages != null" class="cp-property-value">
							{{ user.notifications.receiveProjectedKeyUsageMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveProjectedKeyUsageMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveProjectedKeyUsageMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveProjectedKeyUsageMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.receiveProjectedKeyUsageMessages">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.projectedPercentage }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.projectedPercentage }}%
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveProtocolKeyUsageMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveProtocolKeyUsageMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveProtocolKeyUsageMessages != null" class="cp-property-value">
							{{ user.notifications.receiveProtocolKeyUsageMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveProtocolKeyUsageMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveProtocolKeyUsageMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveProtocolKeyUsageMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.receiveProtocolKeyUsageMessages">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.protocolPercentage }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.protocolPercentage }}%
						</span>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveOfflineHostIDsMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveOfflineHostIDsMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveOfflineHostIDsMessages != null" class="cp-property-value">
							{{ user.notifications.receiveOfflineHostIDsMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveOfflineHostIDsMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveOfflineHostIDsMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveOfflineHostIDsMessages }}
							</div>
						</ng-template>
					</div>

					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.receiveKeysReportMessages }}:
							<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="receiveKeysReportMessagesPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							</fa-icon>
						</span>
						<span *ngIf="user.notifications.receiveKeysReportMessages != null" class="cp-property-value">
							{{ user.notifications.receiveKeysReportMessages.toString() | titlecase }}
						</span>
						<ng-template #receiveKeysReportMessagesPopupContent>
							<div class="cp-popover-banner">{{
								appConstants.notificationPropLabels.receiveKeysReportMessages }}
							</div>
							<div class="cp-pre-text">{{
								appConstants.notificationPropInfo.receiveKeysReportMessages }}</div>
						</ng-template>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.receiveKeysReportMessages">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.keysReportFrequency }}:
						</span>
						<span class="cp-property-value">
							{{ miscTools.fetchLabel(appConstants.keysReportFrequencySelections,
							user.notifications.keysReportFrequency) }}
						</span>
					</div>
				</div>

				<div>Timing</div>

				<div class="ms-4">
					<div class="cp-property-block">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.deliveryMode }}:
						</span>
						<span class="cp-property-value">
							{{ miscTools.fetchLabel(appConstants.notificationDeliveryModes,
							user.notifications.deliveryMode) }}
						</span>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.deliveryMode === 'window'">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.startHour }}:
						</span>
						<span class="cp-property-value">
							{{ textTools.niceHour(user.notifications.startHour) }}
						</span>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.deliveryMode === 'window'">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.endHour }}:
						</span>
						<span class="cp-property-value">
							{{ textTools.niceHour(user.notifications.endHour) }}
						</span>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.timezone">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.timezone }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.timezone }}
						</span>
					</div>

					<div class="cp-property-block ms-2" *ngIf="user.notifications.daysOfWeek && user.notifications.daysOfWeek.length > 0">
						<span class="cp-property-label">
							{{ appConstants.notificationPropLabels.daysOfWeek }}:
						</span>
						<span class="cp-property-value">
							{{ user.notifications.daysOfWeek.join(', ') }}
						</span>
					</div>
				</div>

				<div *ngIf="isStaff">
					<div>Staff Settings</div>

					<div class="ms-4">
						<div class="cp-property-block">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.skipEmptyReports }}:
							</span>
							<span *ngIf="user.notifications.skipEmptyReports != null" class="cp-property-value">
								{{ user.notifications.skipEmptyReports.toString() | titlecase }}
							</span>
						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.receiveStaffKeyReportMessages }}:
							</span>
							<span *ngIf="user.notifications.receiveStaffKeyReportMessages != null" class="cp-property-value">
								{{ user.notifications.receiveStaffKeyReportMessages.toString() | titlecase
								}}
							</span>
						</div>

						<div class="cp-property-block ms-2"
							*ngIf="user.notifications.receiveStaffKeyReportMessages && user.notifications.keyReportSavedSearches.length > 0">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.keyReportSavedSearches }}:
							</span>
							<span class="cp-property-value">
								{{ showSavedSearchNames(user.notifications.keyReportSavedSearches) }}
							</span>
						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.receiveStaffZENMasterReportMessages
								}}:
							</span>
							<span *ngIf="user.notifications.receiveStaffZENMasterReportMessages != null" class="cp-property-value">
								{{ user.notifications.receiveStaffZENMasterReportMessages.toString() |
								titlecase
								}}
							</span>
						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.receiveStaffOrgMeterReportMessages
								}}:
							</span>
							<span *ngIf="user.notifications.receiveStaffOrgMeterReportMessages != null" class="cp-property-value">
								{{ user.notifications.receiveStaffOrgMeterReportMessages.toString() |
								titlecase
								}}
							</span>
						</div>

						<div class="cp-property-block ms-2"
							*ngIf="user.notifications.receiveStaffOrgMeterReportMessages && user.notifications.orgMeterReportOrgIDs.length > 0">
							<span class="cp-property-label">
								{{ appConstants.notificationPropLabels.orgMeterReportOrgIDs }}:
							</span>
							<span class="cp-property-value">
								<ul>
									<li *ngFor="let staffMeterReportOrg of this.staffMeterReportOrgs">
										{{ staffMeterReportOrg.name }}
									</li>
								</ul>
							</span>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="tab-pane" id="nav-downloads-builds" role="tabpanel" aria-labelledby="nav-downloads-builds-tab">
			<div *ngIf="!hasAccessToAllBuildsAndDocs && !loading && (!buildDownloads || buildDownloads.length === 0)"
				class="cp-light-message">
				No software available for {{ user.name }}.
				<span *ngIf="user.is_enabled === 0">
					Their account is disabled.
				</span>
			</div>

			<div *ngIf="user.is_enabled === 1 && hasAccessToAllBuildsAndDocs" class="cp-light-message">
				This user can download any build or document.
			</div>

			<app-downloadable-builds-table #dBuildsTable1></app-downloadable-builds-table>
		</div>

		<div class="tab-pane" id="nav-downloads-documents" role="tabpanel" aria-labelledby="nav-downloads-documents-tab">
			<div *ngIf="!hasAccessToAllBuildsAndDocs && !loading && (!documentDownloads || documentDownloads.length === 0)"
				class="cp-light-message">
				No documentation available for {{ user.name }}.
				<span *ngIf="user.is_enabled === 0">
					Their account is disabled.
				</span>
			</div>

			<div *ngIf="user.is_enabled === 1 && hasAccessToAllBuildsAndDocs" class="cp-light-message">
				This user can download any build or document.
			</div>

			<app-downloadable-documents-table #dDocsTable1></app-downloadable-documents-table>
		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">

			<div *ngIf="!loading && downloadLogs.length === 0" class="cp-light-message">
				No download logs for {{ user.name }}.
			</div>

			<button *ngIf="!loading && downloadLogs.length !== 0" type="button" (click)="runDownloadsReport()"
				class="btn btn-sm btn-outline-secondary mb-1">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate Report
			</button>

			<app-downloads-table #downloadsTable1></app-downloads-table>
		</div>

		<div class="tab-pane" id="nav-techrep" role="tabpanel" aria-labelledby="nav-techrep-tab">

			<div *ngIf="user" class="cp-light-message">
				{{ user.name }} has been assigned as an SE/Tech-Rep for the following Organizations.
			</div>

			<app-organizations-table #orgsTable1></app-organizations-table>
		</div>

		<div class="tab-pane" id="nav-accountowner" role="tabpanel" aria-labelledby="nav-accountowner-tab">
			<div *ngIf="user" class="cp-light-message">
				{{ user.name }} has been assigned as the Account Owner (via Salesforce) for the following Organizations.
			</div>

			<app-organizations-table #orgsTable2></app-organizations-table>
		</div>

		<div class="tab-pane" id="nav-logins" role="tabpanel" aria-labelledby="nav-logins-tab">

			<div *ngIf="!loading && loginHistory.length === 0" class="cp-light-message">
				No logins for {{ user.name }}.
			</div>

			<app-logins-table #loginsTable1></app-logins-table>
		</div>

		<div class="tab-pane" id="nav-link-log" role="tabpanel" aria-labelledby="nav-link-log-tab">

			<div *ngIf="!loading && linkLogs.length === 0" class="cp-light-message">
				No click logs for {{ user.name }}.
			</div>

			<app-clicks-table #clicksTable1></app-clicks-table>
		</div>

		<div class="tab-pane" id="nav-hostids" role="tabpanel" aria-labelledby="nav-hostids-tab">

			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>Host ID</th>
						<th>User's Label</th>
						<th>Set</th>
					</tr>
				</thead>
				<tbody *ngIf="!!userHostIdLabels && userHostIdLabels.length !== 0">
					<tr *ngFor="let item of userHostIdLabels">
						<td>
							<a [routerLink]="['/' + appConstants.urls.licensing, 'hostid', item.hostid]">
								{{ item.hostid }}
							</a>
						</td>
						<td>{{ item.label }}</td>
						<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.added_on)">
							{{ item.added_on | date: appConstants.tableDateTimeFmt }}
						</td>
					</tr>
				</tbody>
			</table>

		</div>

		<div class="tab-pane" id="nav-keys" role="tabpanel" aria-labelledby="nav-keys-tab">

			<div *ngIf="!loading && userKeys.length === 0" class="cp-light-message">
				{{ user.name }} does't have any keys that are aren't disabled and/or expired and
				attached/registered/shared with their user account.
			</div>

			<div *ngIf="userKeys2.length !== 0" class="cp-light-message">
				{{ user.name }} has {{ userKeys2.length }} key(s) that are disabled and/or expired and
				attached/registered/shared with their user account. They are listed under the Keys<sup>2</sup> tab.
			</div>

			<div class="row mb-2">
				<div class="col-7">
					<button *ngIf="canEdit && !!user" type="button" class="btn btn-outline-secondary btn-sm me-1" routerLink="add-keys"
						ngbTooltip="" placement="bottom">
						<fa-icon [icon]="['fas', 'layer-plus']" size="sm"></fa-icon>
						Register Keys
					</button>

					<!-- <button *ngIf="(canEdit || id === authUser.id) && showBxProtocolStats" type="button" (click)="hideProtocolStats()"
						class="btn btn-outline-warning btn-sm me-1"
						ngbTooltip="Removes protocol stats when viewing keys/host IDs via the green 'License Keys' section"
						placement="bottom">
						<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
						Hide Protocol Stats From User
					</button>

					<button *ngIf="(canEdit || id === authUser.id) && !showBxProtocolStats" type="button" (click)="showProtocolStats()"
						class="btn btn-outline-success btn-sm me-1"
						ngbTooltip="Exposes protocol stats when viewing keys/host IDs via the green 'License Keys' section"
						placement="bottom">
						<fa-icon [icon]="['fas', 'layer-plus']" size="sm"></fa-icon>
						Show Protocol Stats To User
					</button> -->

					<!-- <button *ngIf="!!user && userKeys.length !== 0" type="button"
						class="btn btn-outline-secondary btn-sm me-1" (click)="emailUserKeysReport()"
						[ngbTooltip]="'E-Mail me a keys report for this user (including usage data).' + (!showBxProtocolStats ? ' It will NOT include the protocol data because the user is not configured for that.' : '')"
						placement="bottom">
						<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
						E-Mail User's Keys Report
					</button> -->

					<button *ngIf="!!user && userKeys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="openUserKeysReport('meter-data')"
						ngbTooltip="Generate a report with meter usage data across all keys for this user" placement="bottom">
						<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
						Meter Report
					</button>

					<button *ngIf="!!user && userKeys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="openUserKeysReport('protocol-data')"
						ngbTooltip="Generate a report with protocol usage data across all keys for this user" placement="bottom">
						<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
						Protocol Report
					</button>
				</div>

				<div *ngIf="userKeys.length !== 0" class="col-5 text-end">
					<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm me-1" type="button" (click)="toggleWarnings()"
						ngbTooltip="Hide Full Warnings" placement="start">
						<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Warnings
					</button>
					<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm me-1" type="button" (click)="toggleWarnings()"
						ngbTooltip="Show Full Warnings" placement="start">
						<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Warnings
					</button>
				</div>
			</div>

			<!-- <div *ngIf="!showBxProtocolStats" class="cp-warning-message">
				This user is NOT currently configured to be able to see licensing protocol usage statistics.
			</div>

			<div *ngIf="showBxProtocolStats" class="cp-info-message">
				This user is able to see licensing protocol usage statistics.
			</div> -->

			<div *ngIf="otherOrgKeys.length > 0" class="mb-1">
				There are {{ otherOrgKeys.length | number}} enabled and unexpired keys attached to {{ organization.name
				}} that aren't shared with {{ user.name }}:
				<button *ngIf="showShareableKeys" class="btn btn-outline-secondary btn-sm me-1" type="button"
					(click)="showShareableKeys = !showShareableKeys" ngbTooltip="Hide other keys" placement="start">
					<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon>
				</button>
				<button *ngIf="!showShareableKeys" class="btn btn-outline-secondary btn-sm me-1" type="button"
					(click)="showShareableKeys = !showShareableKeys" ngbTooltip="Show other keys" placement="start">
					<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon>
				</button>

				<div *ngIf="showShareableKeys">
					<div *ngFor="let k of otherOrgKeys" class="mb-1 ms-2">
						<button *ngIf="!nowSharedKeys.includes(k.id)" type="button" class="btn btn-outline-info btn-sm"
							(click)="shareKeyWithUser(k)" ngbTooltip="Share key with this user so they can view information about it"
							[disabled]="loading">
							<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
						</button>

						<fa-icon *ngIf="nowSharedKeys.includes(k.id)" [icon]="['fas', 'check']" class="text-success">
						</fa-icon>

						<a [routerLink]="['/' + appConstants.urls.licensing, 'activation', k.id]" [ngbPopover]="keyPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ k.key }}
						</a>
						-
						{{ textTools.capitalizeFirstLetter(k.type) }} {{ niceKeyProduct(k.product) }}
						- {{ k.info }}
						<ng-template #keyPopupContent>
							<div class="cp-popover-banner">{{ k.key }}</div>
							<div class="cp-pre-text">{{ popOverTools.getKeyPopoverLines(k, keyProducts, [], [],
								false).join('\n') }}
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<app-keys-table #keysTable1 [parentApi]="getParentMethod()"></app-keys-table>
		</div>

		<div class="tab-pane" id="nav-keys-obsolete" role="tabpanel" aria-labelledby="nav-keys-obsolete-tab">
			<div class="row mb-2">

				<div class="text-end">
					<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm me-1" type="button" (click)="toggleWarnings()"
						ngbTooltip="Hide Full Warnings" placement="start">
						<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Warnings
					</button>
					<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm me-1" type="button" (click)="toggleWarnings()"
						ngbTooltip="Show Full Warnings" placement="start">
						<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Warnings
					</button>
				</div>
			</div>

			<app-keys-table #keysTable2 [parentApi]="getParentMethod()"></app-keys-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!user && adminLogsOnUser.length === 0)" class="cp-light-message">
				No system/change Logs for {{ user.name }}.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-activity-log" role="tabpanel" aria-labelledby="nav-activity-log-tab">
			<div *ngIf="!loading && (!!user && adminLogsByUser.length === 0)" class="cp-light-message">
				No activity Logs for {{ user.name }}.
			</div>

			<app-logs-table #logsTable2 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this User.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + appConstants.urls.adminlogs, 'journal', 'user', id]">
				<fa-icon [icon]="appConstants.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>

		<div class="tab-pane" id="nav-notify-log" role="tabpanel" aria-labelledby="nav-notify-log-tab">
			<div *ngIf="!loading && (!!user && notifyLogs.length === 0)" class="cp-light-message">
				No Notification Logs for {{ user.name }}.
			</div>

			<app-notifications-table #notificationsTable1></app-notifications-table>
		</div>
	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this User</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this User, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this User
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>