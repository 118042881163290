<div class="navigation-container" [ngClass]="{ isMobile: isMobile }" *ngIf="(isOpen && isMobile) || !isMobile">
	<div class="navigation">
		<nav role="navigation" *ngIf="loggedIn">
			<ul class="nav flex-column">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'/' + appConstants.urls.dashboard" routerLinkActive="active">
						<fa-icon class="cp-shared-nav-item" [icon]="appConstants.sectionIcons.dashboard"
							[fixedWidth]="true"></fa-icon>Dashboard
					</a>
				</li>

				<li class="nav-item" *ngFor="let navItem of navItems; let i = index">
					<a *ngIf="navItem.link.router !== ''" class="nav-link" [routerLink]="'/' + navItem.link.router"
						routerLinkActive="active">
						<span>
							<fa-icon *ngIf="navItem.link.icon.length != 0" [icon]="navItem.link.icon"
								[fixedWidth]="true" [class]="navItem.link.iconClass"></fa-icon>{{ navItem.link.name }}
						</span>
					</a>

					<!-- old way -->
					<div *ngIf="!navItem.dropDownMode && navItem.sublinks && navItem.sublinks.length !== 0">
						<div class="nav-link hasSublinks" (click)="toggleCollapse(navItem)">
							<fa-icon *ngIf="navItem.link.icon.length != 0" [icon]="navItem.link.icon"
								[fixedWidth]="true" [class]="navItem.link.iconClass">
							</fa-icon>{{ navItem.link.name }} {{ navItem.dropDownMode ?'y':'n'}}

							<fa-icon *ngIf="navItem.isCollapsed" class="float-end" icon="angle-down"
								size="lg"></fa-icon>
							<fa-icon *ngIf="!navItem.isCollapsed" class="float-end" icon="angle-up" size="lg"></fa-icon>
						</div>

						<ul class="nav sublink" [ngStyle]="{'display': navItem.isCollapsed ? 'none' : '' }">
							<li class="nav-item" *ngFor="let sublink of navItem.sublinks">
								<a class="nav-link" [routerLink]="'/' + sublink.router" routerLinkActive="active">
									<span class="ms-2">
										<fa-icon *ngIf="sublink.icon.length != 0" [icon]="sublink.icon"
											[fixedWidth]="true" [class]="sublink.iconClass">
										</fa-icon>{{ sublink.name }}
									</span>
								</a>
							</li>
						</ul>
					</div>

					<!-- new way -->
					<div *ngIf="navItem.dropDownMode && navItem.sublinks && navItem.sublinks.length !== 0">
						<div class="nav-link hasSublinks cp-nav-dropdown-toggle cp-pointer" [id]="'dropMenu' + i"
							data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<fa-icon *ngIf="navItem.link.icon.length != 0" [icon]="navItem.link.icon"
								[fixedWidth]="true" [class]="navItem.link.iconClass">
							</fa-icon>{{ navItem.link.name }}
						</div>

						<ul class="dropdown-menu border-0" [attr.aria-labelledby]="'dropMenu' + i">
							<li class="nav-item" *ngFor="let sublink of navItem.sublinks">
								<a class="nav-link" [routerLink]="'/' + sublink.router" routerLinkActive="active">
									<span class="ms-2">
										<fa-icon *ngIf="sublink.icon.length != 0" [icon]="sublink.icon"
											[fixedWidth]="true" [class]="sublink.iconClass">
										</fa-icon>{{ sublink.name }}
									</span>
								</a>
							</li>
						</ul>
					</div>
				</li>

				<li class="nav-item">
					<a class="nav-link" [href]="appConstants.supportURL" target='_blank'>
						<span>
							<fa-icon class="cp-shared-nav-item" [icon]="['fas', 'life-ring']" [fixedWidth]="true">
							</fa-icon>Need Help?
						</span>
					</a>
				</li>

				<li class="nav-item">
					<div class="mt-1 text-center">
						<a [href]="appConstants.corpUrl" target='_blank' class="me-2">
							<fa-icon [icon]="['fas', 'info-circle']" [fixedWidth]="true"></fa-icon>
						</a>
						<a [href]="appConstants.twitterUrl" target='_blank' class="me-2">
							<fa-icon [icon]="['fab', 'twitter']" [fixedWidth]="true"></fa-icon>
						</a>
						<a [href]="appConstants.linkedInUrl" target='_blank' class="me-2">
							<fa-icon [icon]="['fab', 'linkedin']" [fixedWidth]="true"></fa-icon>
						</a>
					</div>
				</li>
			</ul>
		</nav>
	</div>
</div>