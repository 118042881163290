<div *ngIf="activation" class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" (click)="onCancel()">Key Search</a>
			</li>
			<li class="breadcrumb-item">
				{{ activation.key }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Marketplace
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div class="form-group">
			<label>Marketplace to Report Against</label>
			<div class="form-check" *ngFor="let marketPlaceSelection of appConstants.marketPlaceSelections">
				<input class="form-check-input" type="radio" formControlName="marketplace" name="marketplace"
					[id]="'marketplace_' + marketPlaceSelection.value" [value]="marketPlaceSelection.value"
					(change)="onMarketplaceChange(true)" />
				<label class="form-check-label" [for]="'marketplace_' + marketPlaceSelection.value">
					<fa-icon *ngIf="marketPlaceSelection.icon != null" [icon]="marketPlaceSelection.icon" size="sm">
					</fa-icon>
					{{ marketPlaceSelection.label }}
				</label>
			</div>
		</div>

		<div *ngIf="marketPlaceProductSelections.length > 0" class="form-group">
			<label for="accountIdentifier">{{ marketPlaceAccountIdLabel }}</label>
			<input type="text" id="accountIdentifier" formControlName="accountIdentifier" class="form-control" />
		</div>

		<div *ngIf="marketPlaceProductSelections.length > 0" class="form-group">
			<label for="productIdentifiers">Marketplace Product(s)</label>
			<ng-select [items]="marketPlaceProductSelections" bindValue="value" bindLabel="label" id="productIdentifiers"
				formControlName="productIdentifiers" [multiple]="true" [closeOnSelect]="false">
			</ng-select>
		</div>

		<div *ngIf="marketPlaceProductSelections.length > 0 && protocolSetSelections.length > 0" class="form-group">
			<label for="protocolSetId">Protocol Set to Report to Marketplace</label>
			<ng-select [items]="protocolSetSelections" bindValue="id" bindLabel="name" id="protocolSetId" formControlName="protocolSetId"
				[multiple]="false">
			</ng-select>
		</div>

		<!-- <div class="form-group mb-2 small">* Required</div>

		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
			</button>
		</div>

		<div class="cp-form-padding"></div>
 -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="appConstants.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="appConstants.actionIcons.cancel"></fa-icon> Cancel
			</button>

			<span class="gj-75 ps-2">* Required</span>
		</div>
	</div>
</form>