import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-downloadable-builds-table',
	templateUrl: './downloadable-builds-table.component.html',
	styleUrls: ['./downloadable-builds-table.component.scss']
})
export class DownloadableBuildsTableComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			logs 
			storageKey
			options
			- showUserInfo : true
			- showObjectInfo: true
			- linkObject: true
			- linkUser: false
	
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	items: Models.DownloadableBuild[] = [];
	itemsToShow: Models.DownloadableBuild[] = [];

	columnDefs: any[] = [
		{
			field: '__product',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: '__version',
			type: 'baseText',
			sortType: 'text',
			label: 'Version',
			toolTip: null,
			icon: null
		}, {
			field: '__platforms',
			type: 'baseText',
			sortType: 'text',
			label: 'Platforms',
			toolTip: null,
			icon: null
		}, {
			field: '__added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.DownloadableBuild[], storageKey: string) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		this.fieldsToShow = [];
		this.fieldsToShow.push('__product');
		this.fieldsToShow.push('__version');
		this.fieldsToShow.push('__platforms');
		this.fieldsToShow.push('__added_on');

		for (const item of this.items) {
			item['__product'] = item.build.prod_name;
			item['__version'] = item.build.version;
			if (item.build.label && item.build.label !== '') item['__version'] += ' ' + item.build.label;
			item['__platforms'] = '';
			for (const pl of MiscTools.sortPlatforms(item.platforms, this.getPlatformIDsFromPlatformFiles(item.build.platform_files)))
				item['__platforms'] += pl.name + ' ';
			item['__added_on'] = item.build.added_on;
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, '__product', 'asc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {

		const tmpList: Models.DownloadableBuild[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item['__product'].toLowerCase().includes(w)
						|| item['__version'].toLowerCase().includes(w)
						|| item['__platforms'].toLowerCase().includes(w)
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
