import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { DownloadLogsService } from './download-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../users/users.service';

import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';

@Component({
	selector: 'app-download-logs',
	templateUrl: './download-logs.component.html',
	styleUrls: ['./download-logs.component.scss']
})
export class DownloadLogsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;

	logs: Models.DownloadLog[];
	loading = true;
	refreshing = false;

	// other stuff...
	maxMessage = '';
	showFilters = true;
	theForm: UntypedFormGroup;

	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	role = '';
	users: Models.User[] = [];

	constructor(
		private router: Router,
		private downloadLogsService: DownloadLogsService,
		private usersService: UsersService,
		private authService: AuthService) { }

	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-downloadlogs.settings'))
				settings = JSON.parse(localStorage.getItem('cp-downloadlogs.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.notBefore != null || this.notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser)
				this.role = authUser.role;
		});

		this.loadUsers();
		this.initForm();
		this.loadLogs();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-downloadlogs.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		this.logs = await this.downloadLogsService.getLogs(startDate, endDate);

		if (this.logs && this.logs.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		this.loading = false;

		await MiscTools.delay(100);
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.logs, 'cp-download-logs', { showUserInfo: true, linkUser: true });
	}

	async loadUsers() {
		this.users = this.usersService.getAll();
	}

	routeRow(id: number) {
		const r = this.getRoute(id);
		if (r.length > 0)
			this.router.navigate(r);
	}

	getRoute(id: number) {
		const idx = MiscTools.findIndex(this.users, id);
		if (idx !== -1)
			return ['/' + AppConstants.urls.users, id];
		else
			return [];
	}
}
