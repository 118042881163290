import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';

import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { ProductsService } from '../../products/products.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-licensing-product-form',
	templateUrl: './licensing-product-form.component.html',
	styleUrls: ['./licensing-product-form.component.scss']
})
export class LicensingProductFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	id: number;
	product: Models.LicenseProduct;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	products: Models.Product[] = [];
	meterProductSelections: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private productsService: ProductsService,
		private licensingAdminService: LicensingAdminService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	async initForm() {
		this.products = this.productsService.getAll();

		this.meterProductSelections = [];
		for (const mp of AppConstants.meterProducts)
			this.meterProductSelections.push({ value: mp, label: AppConstants.meterTypeObjects[mp].label + '(' + mp + ')' });

		if (this.id && this.id !== 0) {
			this.product = await this.licensingAdminService.getProduct(this.id);
			if (!this.product || this.product == null || this.product.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			} // if
			this.editMode = true;
		} else {
			this.product = new Models.LicenseProduct(0, '', '', '', '', 0, 0, '', 0);
		}

		let meterProducts: string[] = null;
		if (this.product.meter_products && this.product.meter_products !== '')
			meterProducts = this.product.meter_products.split(',');

		this.theForm = new UntypedFormGroup({
			label: new UntypedFormControl(this.product.label, [Validators.required]),
			basic_label: new UntypedFormControl(this.product.basic_label, [Validators.required]),
			name: new UntypedFormControl(this.product.name, [Validators.required]),
			description: new UntypedFormControl(this.product.description),
			show_meters: new UntypedFormControl(this.product.show_meters, [Validators.required]),
			show_protocols: new UntypedFormControl(this.product.show_protocols, [Validators.required]),
			meter_products: new UntypedFormControl(meterProducts),
			build_product_id: new UntypedFormControl(this.product.build_product_id, [Validators.required]),

			license_filename: new UntypedFormControl(this.product.license_filename),
			min_version: new UntypedFormControl(this.product.min_version),
			fulfillment_version: new UntypedFormControl(this.product.fulfillment_version),
			fulfillment_product: new UntypedFormControl(this.product.fulfillment_product),
			include_refresh: new UntypedFormControl(this.product.include_refresh),

		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.product.label = this.theForm.value.label;
		this.product.basic_label = this.theForm.value.basic_label;
		this.product.name = this.theForm.value.name;
		this.product.description = this.theForm.value.description;
		this.product.show_meters = this.theForm.value.show_meters;
		this.product.show_protocols = this.theForm.value.show_protocols;
		this.product.meter_products = this.theForm.value.meter_products;
		this.product.build_product_id = this.theForm.value.build_product_id;

		this.product.license_filename = this.theForm.value.license_filename;
		this.product.min_version = this.theForm.value.min_version;
		this.product.fulfillment_version = this.theForm.value.fulfillment_version;
		this.product.fulfillment_product = this.theForm.value.fulfillment_product;
		this.product.include_refresh = this.theForm.value.include_refresh;

		try {
			let retProduct: Models.LicenseProduct;
			if (this.editMode)
				retProduct = await this.licensingAdminService.updateProduct(this.product);
			else
				retProduct = await this.licensingAdminService.addProduct(this.product);

			if (retProduct) {
				this.router.navigate([AppConstants.urls.licensingadmin + '/products/' + retProduct.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	// get message() { return this.theForm.get('message'); }

}
