import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { ZenCustomersService } from 'client/app/components/app-sections/zen-customers/zen-customers.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-marketplace-logs-table',
	templateUrl: './marketplace-logs-table.component.html',
	styleUrls: ['./marketplace-logs-table.component.scss']
})
export class MarketplaceLogsTableComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	popOverTools = PopOverTools;

	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			users 
			storageKey
			options
			- showOrganization : true
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showObject: boolean = true;

	items: Models.MarketplaceUsageReport[] = [];
	itemsToShow: Models.MarketplaceUsageReport[] = [];

	productCache: any = {};
	zenCustomers: Models.ZenMasterCustomer[] = [];

	columnDefs: any[] = [
		{
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Date/Time',
			toolTip: null,
			icon: null
		}, {
			field: 'object_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Object Type',
			toolTip: null,
			icon: null
		}, {
			field: 'object_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Object',
			toolTip: null,
			icon: null
		}, {
			field: 'marketplace',
			type: 'baseText',
			sortType: 'text',
			label: 'Mkt.',
			toolTip: null,
			icon: null
		}, {
			field: 'customer_id',
			type: 'baseText',
			sortType: 'text',
			label: 'Customer/Acct ID',
			toolTip: null,
			icon: null
		}, {
			field: '__product',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: 'dimension_api_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Dimension',
			toolTip: null,
			icon: null
		}, {
			field: 'report_start',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Usage Day',
			toolTip: null,
			icon: null
		}, {
			field: 'reported',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'Reported\n(GB or Hours)',
			toolTip: null,
			icon: null
		}, {
			field: 'receipt',
			type: 'baseText',
			sortType: 'text',
			label: 'Receipt',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private zenCustomersService: ZenCustomersService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.zenCustomers = this.zenCustomersService.getAll();

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.MarketplaceUsageReport[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showObject')) this.showObject = options.showObject;

		this.fieldsToShow = [];
		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			if (this.showObject || !['object_type', 'object_name'].includes(cd.field))
				this.fieldsToShow.push(cd.field);

		for (const item of this.items) {
			item['__product'] = this.getMarketPlaceProductName(item);

			// item['__upgrade'] = SharedLicenseTools.licenseNeedsUpgrade(item);
			// item['__cleanVersion'] = TextTools.cleanVersion(item.last_protocol_bx_version)
		} // for


		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'added_on', 'desc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	getMarketPlaceProductName(log: Models.MarketplaceUsageReport) {
		const key = log.marketplace + ':' + log.product_id;
		if (!this.productCache[key]) {
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', log.marketplace);
			if (idx1 !== -1) {
				const idx2 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections[idx1].selections, 'productCode', log.product_id);
				if (idx2 !== -1)
					this.productCache[key] = AppConstants.marketPlaceSelections[idx1].selections[idx2].label;
			} // if
		} // if

		if (this.productCache[key])
			return this.productCache[key]

		return log.product_id;
	}

	// ------------------------------------------------------------------------
	getZENName(id: number) {
		const idx = MiscTools.findIndex(this.zenCustomers, id);
		if (idx !== -1)
			return this.zenCustomers[idx].dns_prefix;
		else
			return '???-' + id;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.MarketplaceUsageReport[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.object_type.toLowerCase().includes(w)
						|| item.object_name.toLowerCase().includes(w)
						|| item.marketplace.toLowerCase().includes(w)
						|| item.customer_id.toLowerCase().includes(w)
						|| item.dimension_api_name.toLowerCase().includes(w)
						|| item.receipt.toLowerCase().includes(w)
						|| (item['__product'] && item['__product'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}


	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
