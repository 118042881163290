import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import BuildInfo from 'appshared/build-info';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-change-log',
	templateUrl: './change-log.component.html',
	styleUrls: ['./change-log.component.scss']
})

export class ChangeLogComponent implements OnInit {
	appConstants = AppConstants;

	private userSubscription: Subscription;
	private authUser: Models.AuthUser;

	lastBuild: Date = new Date('2020/01/01');

	canImpersonate = false;
	impersonateEnabled = false;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		if (BuildInfo.buildDateTime) {
			this.lastBuild = new Date(BuildInfo.buildDateTime);
			if (isNaN(this.lastBuild.getTime())) this.lastBuild = null;
		}

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			if (!authUser || !ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole)) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			}

			this.impersonateEnabled = this.usersService.isImpersonateEnabled();
			this.canImpersonate = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	enableImpersonate() {
		this.usersService.enableImpersonate();
		this.impersonateEnabled = true;
		this.uiAlertsService.addMsg('Impersonate enabled.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
	}

}
