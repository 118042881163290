<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.quizzes" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Quizzes + Polls</a>
			</li>
			<li *ngIf="quiz" class="breadcrumb-item active" aria-current="page">
				{{ quiz.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!quiz">
				<button type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button type="button" class="btn btn-outline-primary" [routerLink]="['sort']" ngbTooltip="Re-order questions"
					placement="start">
					<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
				</button>

				<button *ngIf="canAdd" type="button" class="btn btn-outline-primary" [routerLink]="['copy']" ngbTooltip="Copy/Clone"
					placement="start">
					<fa-icon icon="clone" size="sm"></fa-icon>
				</button>

				<button *ngIf="deleteForm" type="button" class="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#deleteModal"
					ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info/Permissions
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-questions-tab"
				aria-controls="nav-questions" data-bs-target="#nav-questions">
				<fa-icon [icon]="appConstants.sectionIcons.quizzes" size="sm"></fa-icon>
				Questions
				<span *ngIf="!loading || (quiz && quiz.config.questions.length > 0)" class="cp-tab-badge">
					{{ quiz.config.questions.length | number}}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-all-responses-tab" aria-controls="nav-all-responses" data-bs-target="#nav-all-responses">
				<fa-icon [icon]="['fas', 'check']" size="sm"></fa-icon>
				Responses
				<span *ngIf="!loading || (respondants && respondants.length > 0)" class="cp-tab-badge">
					{{ respondants.length | number}}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">
			<div *ngIf="quiz" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ quiz.name }}</span>
				</div>

				<div *ngIf="quiz.description !== ''" class="cp-property-block">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ quiz.description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">
						{{ miscTools.fetchLabel(appConstants.quizTypeSelections, quiz.config.quizType) }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span class="cp-property-value">
						{{ miscTools.fetchLabel(appConstants.quizStatusSelections, quiz.status) }}
					</span>
				</div>

				<div *ngIf="quiz.config.adminIds && quiz.config.adminIds.length > 0" class="cp-property-block">
					<span class="cp-property-label">Admin Delegates:</span>
					<ul class='mb-0'>
						<li *ngFor="let user of adminUsers" class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.users, user.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ user.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(user).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">All Staff Can Participate:</span>
					<span class="cp-property-value">{{ niceBoolean2(quiz.config.allStaff) }}</span>
				</div>

				<div *ngIf="quiz.config.userIds && quiz.config.userIds.length > 0" class="cp-property-block">
					<span class="cp-property-label">Participants (Users):</span>
					<ul class='mb-0'>
						<li *ngFor="let user of users" class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.users, user.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ user.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(user).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="quiz.config.groupIds && quiz.config.groupIds.length > 0" class="cp-property-block">
					<span class="cp-property-label">Participants (Groups):</span>
					<ul class="mb-0">
						<li *ngFor="let grp of groups" class="cp-property-value">
							<a [routerLink]="['/' + appConstants.urls.usergroups, grp.id]">{{ grp.name }}</a>
							<fa-icon *ngIf="grp.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
						</li>
					</ul>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ quiz.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="quiz.added_on !== quiz.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ quiz.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>
			</div>

			<div *ngIf="canTakeQuiz">
				<button class="btn btn-primary me-1" type="button" [routerLink]="['/' + appConstants.urls.myquizzes, this.id, 'take']">
					<fa-icon [icon]="['fas', 'play-circle']"></fa-icon>
					Take the {{ miscTools.fetchLabel(appConstants.quizTypeSelections, quiz.config.quizType, 'value',
					'shortLabel') }}
				</button>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-questions" aria-labelledby="nav-questions-tab">
			<div *ngIf="quiz && quiz.config.questions && quiz.config.questions.length > 0">

				<div *ngIf="!showAllQuestions" class="cp-property-list">
					<button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = true"
						ngbTooltip="Show All Question">
						Show All
					</button>

					<button *ngFor="let q of quiz.config.questions; let i = index" class="btn mb-1 me-1" type="button"
						(click)="currentQuestion = i"
						[ngClass]=" { 'btn-outline-primary': currentQuestion !== i, 'btn-primary': currentQuestion === i  }">
						{{ (i + 1) }}
					</button>
				</div>

				<div *ngIf="showAllQuestions" class="cp-property-list">
					<button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = false" ngbTooltip="Add Question">
						Show One At A Time
					</button>
				</div>

				<div *ngFor="let q of quiz.config.questions; let i = index" class="cp-property-list"
					[hidden]="!showAllQuestions && currentQuestion !== i">
					<!-- <div>
						Question #{{ (i + 1) }}
						- {{ miscTools.fetchLabel(appConstants.quizAnswerTypeSelections, q.answerType, 'value',
						'shortLabel') }}
					</div> -->

					<!-- <div class="cp-property-block">
						<span class="cp-property-label">Answer Type:</span>
						<span class="cp-property-value">{{ miscTools.fetchLabel(appConstants.quizAnswerTypeSelections,
							q.answerType, 'value', 'shortLabel') }}</span>
					</div> -->

					<div class="cp-property-block">
						<span class="cp-property-label">Question #{{ (i + 1) }} -
							{{ miscTools.fetchLabel(appConstants.quizAnswerTypeSelections, q.answerType, 'value',
							'shortLabel') }}:
						</span>
						<div class="cp-property-value cp-pre-text">
							{{ q.text }}
							<fa-icon *ngIf="q.moreInfo && q.moreInfo !== ''" [icon]="['fas', 'question-circle']" class="ms-1"
								placement="end" [ngbTooltip]="q.moreInfo">
							</fa-icon>
						</div>
					</div>

					<!-- <div *ngIf="q.moreInfo && q.moreInfo !== ''" class="cp-property-block">
						<span class="cp-property-label">Extra Information:</span>
						<div class="cp-property-value cp-pre-text">{{ q.moreInfo }}</div>
					</div> -->

					<!-- choices if Answer Type !== text -->
					<div *ngIf="q.answerType !== 'text' && q.choices && q.choices.length > 0" class="ms-2 mb-1 p-2 border">
						Choices
						<ul class="mb-0">
							<li *ngFor="let c of q.choices">
								{{ c }}
							</li>
						</ul>
					</div>

					<!-- answers -->
					<div *ngIf="q.answers && q.answers.length > 0" class="ms-2 p-2 border">
						Answer(s)
						<ul class="mb-0">
							<li *ngFor="let a of q.answers" class="cp-pre-text">
								{{ a }}
							</li>
						</ul>
					</div>

					<div *ngIf="q.explanation && q.explanation !== ''" class="ms-2 cp-property-block">
						<span class="cp-property-label">Explanation:</span>
						<div class="cp-property-value cp-pre-text">{{ q.explanation }}</div>
					</div>

					<div *ngIf="respondants && respondants.length > 0" class="m2-1 p-2">
						Response Summary
						<table class="cp-std-table cp-auto-width mt-1 border">
							<thead>
								<tr>
									<th class="border-start text-center">#</th>
									<th class="border-start text-center">%</th>
									<th class="border-start">Response</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let af of answerFrequencies[q.id]; let j = index">
									<td class="border-start text-end">{{ af.count | number }}</td>
									<td class="border-start text-end">
										{{ np(af.count, respondants.length) }}
									</td>
									<td class="border-start">{{ af.answer }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-all-responses" aria-labelledby="nav-all-responses-tab">
			<div *ngIf="quiz && quiz.config.questions && quiz.config.questions.length > 0">

				<button class="btn btn-primary me-1 mb-1" type="button" (click)="reloadResults()">
					<fa-icon icon="sync-alt" size="sm" [spin]="loadingResults" placement="start"></fa-icon>
					Get Latest Results
				</button>

				<button *ngIf="respondants && respondants.length > 0" class="btn btn-primary me-1 mb-1" type="button"
					(click)="overviewMode='checks-basic'" [disabled]="overviewMode === 'checks-basic'">
					<fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon>
					Responded
				</button>

				<button *ngIf="respondants && respondants.length > 0 && hasAnswers" class="btn btn-primary me-1 mb-1" type="button"
					(click)="overviewMode='checks-color'" [disabled]="overviewMode === 'checks-color'">
					<fa-icon [icon]="['fas', 'check']"></fa-icon>
					Right/Wrong
				</button>

				<button *ngIf="respondants && respondants.length > 0" class="btn btn-primary me-1 mb-1" type="button"
					(click)="overviewMode='answers'" [disabled]="overviewMode === 'answers'">
					<fa-icon [icon]="['fas', 'comment-dots']"></fa-icon>
					Responses
				</button>

				<table *ngIf="respondants && respondants.length > 0"
					class="table table-sm table-hover table-striped cp-auto-width mt-2 border">
					<thead>
						<tr>
							<th class="border-start">Respondant</th>
							<th>
								<fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
							</th>
							<th *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center">
								<span [ngbTooltip]="q.text">
									Q. {{ (i + 1) }}
								</span>
							</th>
							<th *ngIf="overviewMode === 'checks-basic'" class="border-start text-center">
								<fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon>
							</th>
							<th *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center">
								<fa-icon [icon]="['fas', 'check']"></fa-icon>
							</th>
							<th *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center">
								<fa-icon [icon]="['fas', 'times']"></fa-icon>
							</th>
							<th class="border-start text-center">
								<span ngbTooltip="No Response">NR</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let r of respondants; let j = index">
							<td class="border-start">
								{{ (j + 1) }}. {{ r.name }}
							</td>
							<td>
								<fa-icon [icon]="['fas', 'trash-alt']" class="text-danger" (click)="userIdToDelete = r.id"
									data-bs-toggle="modal" data-bs-target="#deleteResponseModal"
									ngbTooltip="Remove this person's response(s)"></fa-icon>
							</td>
							<td *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center">
								<span *ngIf="overviewMode === 'checks-basic'">
									<fa-icon *ngIf="processedResults[q.id + '-' + r.id].answer !== ''" [icon]="['fas', 'thumbs-up']"
										ngbTooltip="The respondant provided an answer">
									</fa-icon>
								</span>
								<span *ngIf="overviewMode === 'checks-color'">
									<fa-icon *ngIf="processedResults[q.id + '-' + r.id].isCorrect" [icon]="['fas', 'check']"
										class="text-success" ngbTooltip="The respondant provided a correct answer">
									</fa-icon>
									<fa-icon
										*ngIf="processedResults[q.id + '-' + r.id].answer !== '' && !processedResults[q.id + '-' + r.id].isCorrect"
										[icon]="['fas', 'times']" class="text-danger"
										ngbTooltip="The respondant provided an incorrect answer">
									</fa-icon>
								</span>
								<span *ngIf="overviewMode === 'answers'"
									[ngClass]="{ 'text-success': hasAnswers && processedResults[q.id + '-' + r.id].isCorrect, 'text-danger': hasAnswers && !processedResults[q.id + '-' + r.id].isCorrect }">
									{{ processedResults[q.id + '-' + r.id].answer }}
								</span>
							</td>

							<td *ngIf="overviewMode === 'checks-basic'" class="border-start text-center">
								{{ getRespondantCount(r.id, 'answered') }}
								({{np(getRespondantCount(r.id, 'answered'), quiz.config.questions.length)}}%)
							</td>
							<td *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)"
								class="border-start text-center text-success">
								{{ getRespondantCount(r.id, 'correct') }}
								({{np(getRespondantCount(r.id, 'correct'), quiz.config.questions.length)}}%)
							</td>
							<td *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)"
								class="border-start text-center text-danger">
								{{ getRespondantCount(r.id, 'incorrect') }}
								({{np(getRespondantCount(r.id, 'incorrect'), quiz.config.questions.length)}}%)
							</td>
							<td class="border-start text-center">
								{{ getRespondantCount(r.id, 'no-response') }}
								({{np(getRespondantCount(r.id, 'no-response'), quiz.config.questions.length)}}%)
							</td>
						</tr>

						<tr *ngIf="overviewMode === 'checks-basic'">
							<th class="border-start">
								Response
							</th>
							<th></th>
							<th *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center">
								{{ getQuestionCount(q.id, 'answered') }}<br />
								({{np(getQuestionCount(q.id, 'answered'), respondants.length)}}%)
							</th>
							<th *ngIf="overviewMode === 'checks-basic'" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th class="border-start text-center"></th>
						</tr>

						<tr *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)">
							<th class="border-start">
								# Correct
							</th>
							<th></th>
							<th *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center text-success">
								{{ getQuestionCount(q.id, 'correct') }}<br />
								({{np(getQuestionCount(q.id, 'correct'), respondants.length)}}%)
							</th>
							<th *ngIf="overviewMode === 'checks-basic'" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th class="border-start text-center"></th>
						</tr>

						<tr *ngIf="hasAnswers && ['checks-color', 'answers'].includes(overviewMode)">
							<th class="border-start">
								# Incorrect
							</th>
							<th></th>
							<th *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center text-danger">
								{{ getQuestionCount(q.id, 'incorrect') }}<br />
								({{np(getQuestionCount(q.id, 'incorrect'), respondants.length)}}%)
							</th>
							<th *ngIf="overviewMode === 'checks-basic'" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th class="border-start text-center"></th>
						</tr>

						<tr>
							<th class="border-start">
								No Response
							</th>
							<th></th>
							<th *ngFor="let q of quiz.config.questions; let i = index" class="border-start text-center">
								{{ getQuestionCount(q.id, 'no-response') }}<br />
								({{np(getQuestionCount(q.id, 'no-response'), respondants.length)}}%)
							</th>
							<th *ngIf="overviewMode === 'checks-basic'" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th *ngIf="['checks-color', 'answers'].includes(overviewMode)" class="border-start text-center"></th>
							<th class="border-start text-center"></th>
						</tr>
					</tbody>
				</table>


			</div>
		</div>

		<!-- <div class="tab-pane" role="tabpanel" id="nav-frequencies" aria-labelledby="nav-frequencies-tab">
			<div
				*ngIf="quiz && quiz.config.questions && quiz.config.questions.length > 0 && respondants && respondants.length > 0">

				<div *ngFor="let q of quiz.config.questions; let i = index" class="cp-pre-text">
					<div>{{ (i + 1) }}. {{ q.text }}</div>
					<table class="cp-std-table cp-auto-width mt-1 border">
						<thead>
							<tr>
								<th class="border-start text-center">#</th>
								<th class="border-start text-center">%</th>
								<th class="border-start">Response</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let af of answerFrequencies[q.id]; let j = index">
								<td class="border-start text-end">{{ af.count | number }}</td>
								<td class="border-start text-end">
									{{ np(af.count, respondants.length) }}
								</td>
								<td class="border-start">{{ af.answer }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div> -->

		<div class="tab-pane" role="tabpanel" id="nav-admin-log" aria-labelledby="nav-admin-log-tab">
			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<div class="modal" id="deleteResponseModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete Responses</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteResponseModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteResponseForm" [formGroup]="deleteResponseForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete <span class="fw-bold">{{ getUsername(userIdToDelete) }}'s</span>
						responses, they will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button type="button" [disabled]="loading" (click)="deleteResponse()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Person's Responses
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Quiz</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Quiz, it will be permanently deleted along with any answers that have provide
						and it cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button type="button" [disabled]="loading" (click)="delete()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Quiz
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>